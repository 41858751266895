<div class="date-picker">
  <div class="datepiker-wrp">
    <span (click)="showHide()">
      Date <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/calendar-icon.png" />
    </span>
    <div class="searchoverlay" [ngClass]="showdropdown ? 'visible' : ''" (click)="showdropdown = !showdropdown"></div>
    <div class="drop-down" [ngClass]="showdropdown ? 'visible' : ''">
      <div class="nav-left">
        <span (click)="createClicked()" value="CreatedOn" [ngClass]="createdDate ? 'white-bg' : ''"> Created On</span>
        <span (click)="updateClicked()" [ngClass]="updatedDate ? 'white-bg' : ''"> Updated On</span>
      </div>
      <ul class="inr-dropdown" [ngClass]="(createdDate ? 'visible' : '') + (updatedDate ? 'visible' : '')">
        <li *ngFor="let date of dateArray" (click)="dateFilterHandler(dateText, date)">
          {{ date }}
          <span>
            <!-- {{ 0 }} -->
          </span>
        </li>
        <li>
          <mat-form-field>
            <input
              [(ngModel)]="frmDate"
              matInput
              [matDatepicker]="picker"
              (dateChange)="addEvent()"
              placeholder="From Date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <span class="fullwidth-click" (click)="picker.open()"></span>
            <span *ngIf="errmsg != ''">Valid date is required</span>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field>
            <input
              [(ngModel)]="toDate"
              #picker1="ngModel"
              matInput
              [matDatepicker]="picker1"
              (dateChange)="addEvent()"
              placeholder="To Date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <span class="fullwidth-click" (click)="picker1.open()"></span>
            <span *ngIf="errmsg != ''">Valid date is required</span>
          </mat-form-field>
          <button class="btn" type="submit" (click)="applyFilter(frmDate, toDate)">Apply</button>
        </li>
      </ul>
    </div>
  </div>
</div>
