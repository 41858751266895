import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
// import { SalesChannelModel } from '../../models/sales-channel.model';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { SalesChannelModel } from 'src/app/filter-widget-new/models/sales-channel.model';
import { FilterHelperServiceNew } from 'src/app/filter-widget-new/service/filter-helper.service';

@Component({
  selector: 'app-export-sales-channel-filter',
  templateUrl: './export-sales-channel-filter.component.html',
  styleUrls: ['./export-sales-channel-filter.component.scss']
})
export class ExportSalesChannelFilterComponent implements OnInit {
  @Output() getSalesChannelFilterData: EventEmitter<any> = new EventEmitter();
  // @Input() filterFormData;
  // @Input() query;

  salesChannelFilterFormData: SalesChannelModel;
  salesChannelData: any = [];
  salesChannelForm: FormGroup;
  @ViewChild('allSelected') private allSelected: MatOption;

  constructor(public filterHelperService: FilterHelperServiceNew, private fb: FormBuilder) {}

  ngOnInit() {
    this.salesChannelData = localStorage.salesChannel
      ? this.sortData(JSON.parse(localStorage.salesChannel), 'channelLabel')
      : [];
    this.salesChannelData = this.salesChannelData.filter(data => data.externalChannelId != null);
    this.salesChannelForm = this.fb.group({
      salesChannelData: ''
    });
  }

  displayOnHover(check, data) {
    if (check) {
      let obj = this.salesChannelData.find(val => val.externalChannelId === data[0]);
      return obj.channelLabel + this.filterHelperService.getFacilityLabel(obj.fulfillmentChannels);
    }
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.salesChannelForm.controls.salesChannelData.patchValue([
        ...this.salesChannelData.map(item => item.externalChannelId),
        0
      ]);
    } else {
      this.salesChannelForm.controls.salesChannelData.patchValue([]);
    }
    this.onSubmitDataForm();
  }

  /**@description Method to Sort array Data Alphabetically Based on provided key */
  public sortData(data, sortingBasedOnKey: string) {
    return data.sort((a, b) => {
      return a[sortingBasedOnKey] > b[sortingBasedOnKey] ? 1 : -1;
    });
  }

  /**@description - Method to Submit Sales Channel Filter*/
  onSubmitDataForm() {
    let extChannelId = [];
    let displayDetails = [];
    // let details = [];
    this.salesChannelForm.value.salesChannelData.forEach(x => {
      extChannelId.push(x);
      let obj = this.salesChannelData.find(obj => obj.externalChannelId == x);
      if (obj)
        displayDetails.push(obj.channelLabel + this.filterHelperService.getFacilityLabel(obj.fulfillmentChannels));
    });
    if (extChannelId.includes(0)) {
      let index = extChannelId.indexOf(0);
      extChannelId.splice(index, 1);
    }
    this.salesChannelFilterFormData = {
      isAvailableOn: [],
      isNotAvailableOn: [],
      salesChannel: extChannelId,
      displayKey: 'Sales Channel: ' + displayDetails,
      condition: 'is'
    };
    this.getSalesChannelFilterData.emit(this.salesChannelFilterFormData);
  }

  toggleApplyButton(isSelected) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      this.onSubmitDataForm();
      return false;
    }
    if (this.salesChannelForm.controls.salesChannelData.value.length == this.salesChannelData.length)
      this.allSelected.select();
    this.onSubmitDataForm();
  }
}
