import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  GetAllOrderSegmentCount,
  GetNewSegmentsData,
  GetNoScanReturnFiltersData,
  GetNoScanReturnSegmentCount,
  SaveSegment,
  SegmentActionTypes
} from '../actions/segment.actions';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { NewSegmentService } from 'src/app/shared/services/segment-with-count/new-segment.service';
import { SegmentCountService } from 'src/app/shared/services/segment-with-count/segment-count.service';
import { operators } from 'rxjs-compat';
import { NoScanReturnService } from 'src/app/no-scan-return/no-scan-return.service';
import { AuthService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class NewSegmentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{ segmentState }>,
    private newSegmentService: NewSegmentService,
    private segmentCountService: SegmentCountService,
    private noScanReturnService: NoScanReturnService,
    private auth: AuthService
  ) {}

  @Effect()
  getSegmentsData$: Observable<any> = this.actions$.pipe(
    ofType<GetNewSegmentsData>(SegmentActionTypes.GET_NEW_SEGMENTS_DATA),
    concatMap(action => {
      return this.newSegmentService.getSegmentsData().pipe(
        map(data => ({
          type: SegmentActionTypes.GET_NEW_SEGMENTS_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SegmentActionTypes.GET_NEW_SEGMENTS_DATA_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getAllOrdersCount$: Observable<any> = this.actions$.pipe(
    ofType<GetAllOrderSegmentCount>(SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA),
    concatMap(action => {
      return this.segmentCountService.getAllOrderSegmentCount({ filterQuery: action.payload.filterQuery }).pipe(
        map(data => ({
          type: SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA_SUCCESS,
          payload: { data, operation: action.payload.operation, countData: action.payload.countData }
        })),
        catchError(error =>
          of({
            type: SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  saveSegment$: Observable<Action> = this.actions$.pipe(
    ofType<SaveSegment>(SegmentActionTypes.SAVE_SEGMENT),
    mergeMap(action => {
      return this.newSegmentService.saveSegment(action.payload).pipe(
        map(data => ({
          type: SegmentActionTypes.SAVE_SEGMENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SegmentActionTypes.SAVE_SEGMENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  deleteSegment$: Observable<Action> = this.actions$.pipe(
    ofType<SaveSegment>(SegmentActionTypes.DELETE_SEGMENT),
    mergeMap(action => {
      return this.newSegmentService.deleteSegment(action.payload).pipe(
        map(data => ({
          type: SegmentActionTypes.DELETE_SEGMENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SegmentActionTypes.DELETE_SEGMENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  updateSegment$: Observable<Action> = this.actions$.pipe(
    ofType<SaveSegment>(SegmentActionTypes.EDIT_SEGMENT),
    mergeMap(action => {
      return this.newSegmentService.editSegment(action.payload).pipe(
        map(data => ({
          type: SegmentActionTypes.EDIT_SEGMENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SegmentActionTypes.EDIT_SEGMENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getNoScanReturnFiltersDate$: Observable<Action> = this.actions$.pipe(
    ofType<GetNoScanReturnFiltersData>(SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS),
    mergeMap(action => {
      return this.noScanReturnService.getNoScanReturnFiltersData().pipe(
        map((data: any) => ({
          type: SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS_SUCCESS,
          payload: data.items
        })),
        catchError(error =>
          of({
            type: SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getGetNoScanReturnCount$: Observable<any> = this.actions$.pipe(
    ofType<GetNoScanReturnSegmentCount>(SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA),
    concatMap(action => {
      return this.noScanReturnService
        .getAllReturnSegmentCount({ filterQuery: action.payload.filterQuery, location: this.auth.getFlexSlug() })
        .pipe(
          map(data => ({
            type: SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA_SUCCESS,
            payload: { data, operation: action.payload.operation, countData: action.payload.countData }
          })),
          catchError(error =>
            of({
              type: SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA_FAILURE,
              payload: error['error']
            })
          )
        );
    })
  );
}
