import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tooltip],[appAutoPositionTooltip]'
})
export class TooltipDirective {
  @Input('tooltip') tooltipTitle: string;
  @Input() placement: string;
  @Input() delay: number;
  @Input() tooltipWidth: number;
  tooltip: HTMLElement;
  //  tooltip
  offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  @HostListener('click') click() {
    if (this.tooltip) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle) // textNode
    );

    this.renderer.appendChild(document.body, this.tooltip);
    // this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    // delay
    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);

    // width
    this.renderer.setStyle(this.tooltip, 'max-width', `${this.tooltipWidth}px`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    // tooltip
    const tooltipPos = this.tooltip.getBoundingClientRect();

    // window scroll top
    // getBoundingClientRect
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    //
    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  // @HostListener('mouseenter') onmouseenter() {
  //   const tooltip1 = this.el.nativeElement.querySelector('.tooltip_seg');
  //   const viewportWidth = window.innerWidth;
  //   const viewportHeight = window.innerHeight;
  //   const parentLi = this.el.nativeElement.closest('button');
  //   const rect = parentLi.getBoundingClientRect();

  //   // if (rect.right > 50) {
  //   //   this.renderer.addClass(tooltip1, 'left');
  //   //   this.renderer.removeClass(tooltip1, 'right');
  //   //   this.renderer.removeClass(tooltip1, 'top');
  //   // } else {
  //   //   this.renderer.removeClass(tooltip1, 'left');
  //   // }

  //   if (rect.left < 61) {
  //     this.renderer.addClass(tooltip1, 'right');
  //     this.renderer.removeClass(tooltip1, 'left');
  //   } else {
  //     this.renderer.removeClass(tooltip1, 'right');
  //   }
  // }

  @HostListener('mouseenter') onmouseEnter() {
    const tooltip = this.el.nativeElement.querySelector('.tooltipdiv');
    if (!tooltip) return;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const parentLi = this.el.nativeElement.closest('button');
    const rect = parentLi.getBoundingClientRect();
    // Check if the parent li element is within 34px from the rightmost edge of the screen
    if (viewportWidth - rect.right < 85) {
      this.renderer.addClass(tooltip, 'left');
      this.renderer.removeClass(tooltip, 'right');
      this.renderer.removeClass(tooltip, 'top');
    } else {
      this.renderer.removeClass(tooltip, 'left');
    }

    // Check if the parent li element is within 34px from the leftmost edge of the screen
    if (rect.left < 35) {
      this.renderer.addClass(tooltip, 'right');
      this.renderer.removeClass(tooltip, 'left');
      this.renderer.removeClass(tooltip, 'top');
    } else {
      this.renderer.removeClass(tooltip, 'right');
    }

    //Check if the parent li element goes out of screen vertically
    if (rect.bottom + 10 > viewportHeight) {
      this.renderer.addClass(tooltip, 'top');
      this.renderer.removeClass(tooltip, 'left');
      this.renderer.removeClass(tooltip, 'right');
    } else {
      this.renderer.removeClass(tooltip, 'top');
    }
  }
}
