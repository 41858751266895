import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConvertToCsvService {
  constructor() {}
  // downloadFile(data, filename = 'data') {
  //   let csvData = this.ConvertToCSV(data);
  //   let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  //   let dwldLink = document.createElement('a');
  //   let url = URL.createObjectURL(blob);
  //   let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  //   if (isSafariBrowser) {
  //     //if Safari open in new window to save file with random filename.
  //     dwldLink.setAttribute('target', '_blank');
  //   }
  //   console.log("url",url)
  //   dwldLink.setAttribute('href', `data:attachment/csv;charset=utf-8,${url}`);
  //   dwldLink.setAttribute('download', filename + '.csv');
  //   dwldLink.style.visibility = 'hidden';
  //   document.body.appendChild(dwldLink);
  //   dwldLink.click();
  //   console.log("dwldLink",dwldLink)
  //   document.body.removeChild(dwldLink);
  // }

  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  downloadFile(data, filename = 'data') {
    let a = document.createElement('a');
    let csv = this.ConvertToCSV(data);
    if (window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
        type: 'text/csv;charset=utf-8;'
      });
      navigator.msSaveBlob(blob, `${filename}.csv`);
    } else {
      a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
      a.target = '_blank';
      a.download = `${filename}.csv`;
      document.body.appendChild(a);
      a.click();
    }
  }
}
