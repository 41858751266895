<div class="select-sales-channel">
  <form [formGroup]="portalForm">
    <mat-form-field
      class="select-team-member empty-label-show dropdown-override"
      [ngClass]="portalFormData?.portal && portalFormData.portal.length ? 'add-bg-color' : ''"
    >
      <mat-select
        placeholder="Portal"
        [multiple]="true"
        name="portal"
        disableOptionCentering
        panelClass="custom-select-ui sales-mrg filter-option order"
        formControlName="portalData"
        #normalSalesChannel
        floatlLabel="never"
      >
        <mat-select-trigger *ngIf="portalFormData?.portal && portalFormData.portal.length">
          Portal: {{ displayOnHover(1, portalFormData?.portal) }}
          <ng-container *ngIf="portalFormData?.portal && portalFormData.portal.length > 1">
            + {{ portalFormData.portal.length - 1 }} more
          </ng-container>
        </mat-select-trigger>
        <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">
          <!-- [indeterminate]="portalFormData?.portal ? portalFormData.portal.length != salesChannelData.length : false" -->
          All
        </mat-option>
        <mat-option
          *ngFor="let portal of portalData"
          [value]="portal.externalPortalId"
          #matOption
          (click)="toggleApplyButton(matOption.selected)"
        >
          {{ portal.portalName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
