import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { CheckAppAuthorization } from './store/actions/installed-app-authorization.action';
import { AppAuthorizationState } from './store/state/installed-app-authorization.state';
import * as fromSelector from './store/selectors/installed-app-authorization.selector';
import { InstalledAppAuthorizationService } from './service/installed-app-authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-installed-app-authorization',
  templateUrl: './installed-app-authorization.component.html',
  styleUrls: ['./installed-app-authorization.component.scss']
})
export class InstalledAppAuthorizationComponent implements OnInit {
  redirectUrl: string;
  isLoading: boolean = true;
  appAuthorizationData: any;

  constructor(
    private router: Router,
    private store: Store<{ appAuthorization: AppAuthorizationState }>,
    private installedAppService: InstalledAppAuthorizationService
  ) {}

  ngOnInit() {
    let routerUrl = this.router.url;
    this.redirectUrl = `${environment.DOMAIN}${routerUrl}`;
    this.installedAppService.checkAppAuthorization(this.redirectUrl).subscribe((response: any) => {
      this.isLoading = false;
      if (response && response.appConnectionUrl) {
        window.open(response.appConnectionUrl, '_self');
      } else if (response && response.id && response.installationId) {
        this.appAuthorizationData = response;
        let appDetailsParams = { connectionId: this.appAuthorizationData.id };
        this.router.navigate(['/installed-app-list/detail/', this.appAuthorizationData.installationId], {
          queryParams: appDetailsParams
        });
      }
      error => {
        this.isLoading = false;
      };
    });
  }
}
