import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-serve-error-page',
  templateUrl: './serve-error-page.component.html',
  styleUrls: ['./serve-error-page.component.scss']
})
export class ServeErrorPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
