<section class="popup-page">
  <!-- <button class="page-close">
    <img src="https://ik.imagekit.io/2gwij97w0o/single_page/x.svg?updatedAt=1683022822807" />
  </button> -->
  <div class="page-inner">
    <div class="page-content-center">
      <img
        src="https://ik.imagekit.io/2gwij97w0o/single_page/image-404.svg?updatedAt=1683026382191"
        class="image-404"
      />
      <h1>Page not found</h1>
      <p>
        The link you clicked may be broken or the page may<br />
        have been removed or renamed.
      </p>
    </div>
  </div>
</section>
