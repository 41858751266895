import { Action } from '@ngrx/store';

export enum ActivityMainActionTypes {
  SHOW_PROGRESS_SPINNER = '[ACTIVITY_MAIN] Show progress spinner',
  HIDE_PROGRESS_SPINNER = '[ACTIVITY_MAIN] Hide progress spinner',

  GET_PORTAL = '[ACTIVITY MAIN] Get Portal Details',
  GET_PORTAL_SUCCESS = '[ACTIVITY MAIN] Get Portal Details Success',
  GET_PORTAL_FAILURE = '[ACTIVITY MAIN] Get Portal Details Failure'

  // GET_CHANNELS_LIST = '[ACTIVITY MAIN] Get Channels Details',
  // GET_CHANNELS_LIST_SUCCESS = '[ACTIVITY MAIN] Get Channels Details Success',
  // GET_CHANNELS_LIST_FAILURE = '[ACTIVITY MAIN] Get Channels Details Failure'
}
export class ShowProgressSpinner implements Action {
  readonly type = ActivityMainActionTypes.SHOW_PROGRESS_SPINNER;
  constructor() {}
}
export class HideProgressSpinner implements Action {
  readonly type = ActivityMainActionTypes.HIDE_PROGRESS_SPINNER;
  constructor() {}
}
export class GetPortalDetails implements Action {
  readonly type = ActivityMainActionTypes.GET_PORTAL;
  constructor() {}
}
export class GetPortalDetailsSuccess implements Action {
  readonly type = ActivityMainActionTypes.GET_PORTAL_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPortalDetailsFailure implements Action {
  readonly type = ActivityMainActionTypes.GET_PORTAL_FAILURE;
  constructor(public payload: any) {}
}
// export class GetChannelsDetails implements Action {
//   readonly type = ActivityMainActionTypes.GET_CHANNELS_LIST;
// }
// export class GetChannelsDetailsSuccess implements Action {
//   readonly type = ActivityMainActionTypes.GET_CHANNELS_LIST_SUCCESS;
//   constructor(public payload: any) {}
// }
// export class GetChannelsDetailsFailure implements Action {
//   readonly type = ActivityMainActionTypes.GET_CHANNELS_LIST_FAILURE;
//   constructor(public payload: any) {}
// }
export type ActivityMainActions =
  | ShowProgressSpinner
  | HideProgressSpinner
  | GetPortalDetails
  | GetPortalDetailsSuccess
  | GetPortalDetailsFailure;
// | GetChannelsDetails
// | GetChannelsDetailsSuccess
// | GetChannelsDetailsFailure;
