import { orderroutingActions } from './order-routing.actions';
import * as fromAction from './order-routing.actions';
export interface orderoutingState {
  ruleListData: any;
  ruleLoad: boolean;
  ruleError: string;
  ruleLoading: boolean;
}

export const initialState: orderoutingState = {
  ruleListData: [],
  ruleLoad: false,
  ruleError: '',
  ruleLoading: false
};

export function orderroutingReducer(state = initialState, action: orderroutingActions): orderoutingState {
  switch (action.type) {
    case fromAction.ruleList: {
      return { ...state, ruleLoading: true, ruleError: '', ruleLoad: false };
    }

    case fromAction.ruleListSuccess: {
      return { ...state, ruleLoading: false, ruleListData: action['payload'], ruleLoad: true, ruleError: '' };
    }

    case fromAction.ruleListError: {
      return { ...state, ruleLoading: false, ruleListData: [], ruleLoad: true, ruleError: 'Please try again' };
    }

    case fromAction.ruleSave: {
      return { ...state, ruleLoading: true, ruleError: '' };
    }

    case fromAction.ruleSaveSuccess: {
      return { ...state, ruleLoading: false, ruleListData: action['payload'], ruleLoad: true, ruleError: '' };
    }

    case fromAction.ruleSaveError: {
      return { ...state, ruleLoading: false, ruleLoad: true, ruleError: action['payload']['message'] };
    }

    case fromAction.ruleDelete: {
      return { ...state, ruleLoading: true, ruleError: '' };
    }

    case fromAction.ruleDeleteSuccess: {
      return { ...state, ruleLoading: false, ruleListData: action['payload'], ruleLoad: true, ruleError: '' };
    }

    case fromAction.ruleDeleteError: {
      return { ...state, ruleLoading: false, ruleLoad: true, ruleError: 'Please try again' };
    }

    default: {
      return state;
    }
  }
}

export const getRuleList = (state: orderoutingState) => state.ruleListData;
export const getLoaderstart = (state: orderoutingState) => state.ruleLoading;
export const getError = (state: orderoutingState) => state.ruleError;
export const getstate = (state: orderoutingState) => state;
