import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrdersState } from 'src/app/orderV2/store/state/orders.state';
import { ReplaySubject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromSelector from 'src/app/orderV2/store/selectors/orders.selectors';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
@Component({
  selector: 'app-order-matching',
  templateUrl: './order-matching.component.html',
  styleUrls: ['./order-matching.component.scss']
})
export class OrderMatchingComponent implements OnInit {
  @Input() orderExport: any;
  objectKeys = Object.keys;

  salesChannelData: any = [];
  enrolledFCData: any = [];

  constructor(private filterHelperService: FilterHelperService, private store: Store<{ ordersState: OrdersState }>) {
    this.salesChannelData = JSON.parse(localStorage.salesChannel) ? JSON.parse(localStorage.salesChannel) : [];
    let fullfillmentCenterData = JSON.parse(localStorage.fulfillmentCenter);
    this.enrolledFCData = fullfillmentCenterData
      ? fullfillmentCenterData.filter(data => data['enrollmentStatus'] == 'ENROLLED')
      : [];
  }

  ngOnInit() {}

  /** @description Maintain sequence of object keys */
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
