<div class="innerpopup">
  <a class="close" (click)="onNoClick()"
    ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
  /></a>
  <h2>Are you sure?</h2>
  <p>You are about to delete 1 record. This can’t be undone.</p>
  <div class="btn-block">
    <a class="cancle-btn"> Cancel </a>
    <a class="delete-btn"> Delete </a>
  </div>
</div>
