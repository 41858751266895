import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PusherService } from 'src/app/download-widget/service/pusher.service';
import { AlertService, AuthService } from 'src/app/shared/services';
import { ExportTemplate, GetTemplate, ScheduleReport } from '../../../store/actions/orders.action';
import * as fromSelectorV2 from '../../../store/selectors/orders.selectors';
import { OrdersState } from '../../../store/state/orders.state';
// import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
import { KeyValue } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { SaveTemplateExportComponent } from 'src/app/shared/component/export/save-template-export/save-template-export.component';
import { TemplateEditExportComponent } from 'src/app/shared/component/export/template-edit-export/template-edit-export.component';
import { PusherScheduleService } from 'src/app/shared/services/pusher-schedule.service';
import { NEW_ORDERS_V2_PARAM, RETURN_NO_SCAN_PARAM } from 'src/app/constants/routes';

@Component({
  selector: 'app-export-orders',
  templateUrl: './export-orders.component.html',
  styleUrls: ['./export-orders.component.scss']
})
export class ExportOrdersComponent implements OnInit {
  exportTemplateSubscription$: Subscription;
  getTemplateSubscription$: Subscription;
  orderExport = new FormControl();
  reportExport = new FormControl();
  isOrderExportStep: boolean = false;
  isReportExportStep: boolean = true;
  isOrderCreatedRang: boolean = false;
  isScheduleReport: boolean = false;
  isSelectFields: boolean = false;
  isTemplateSaved: boolean = false;
  isTemplateDeleted: boolean = false;
  isNotClrDtFilters: boolean = true;
  loader: boolean = false;
  columnSelected: any = {};
  templateList: any;
  orderCreatedRangeForm: FormGroup;
  jobType: any;
  selectAllOption: any = {};
  salesChannelData: any = [];
  enrolledFCData: any = [];
  templateSelected: any;
  removeDateFilters = [
    'invoiceDTO.invoiceDate',
    'orderDateDetailsDTO.orderDate',
    'orderReceivedOn',
    'paymentDTO.paidDate',
    'paymentDTO.refundDate',
    'returnCompletedOn',
    'returnDeliveredOn',
    'returnTrackingDto.pickedOn',
    'shipmentDTO.cancelledOn',
    'shipmentDTO.dispatchedOnFilter',
    'shipmentDTO.readyToShipOn',
    'shipmentTrackingDTO.deliveredOn',
    'returnCreatedOn',
    'returnReceivedOn',
    'orderDeliveredTimestamp',
    'orderHandoverDoneTimestamp',
    'orderCancelledTimestamp',
    'returns.returnDetails.createdOnTimestamp',
    'returns.returnDetails.receivedOnTimestamp',
    'returns.returnDetails.completedOnTimestamp',
    'firstRTSTimestamp',
    'shipmentDetails.invoiceDate',
    'orderDetails.orderDate',
    'orderItemLostDetails.lostMarkedOnTimestamp'
  ];
  emailIds = [
    'deepesh@eshopbox.com',
    'ajeetkumar@eshopbox.com',
    'varun.kumar@eshopbox.com',
    'manas@eshopbox.com',
    'akshata.choulwar@eshopbox.com',
    'varun.mittal@eshopbox.com',
    'rita.saini@eshopbox.com',
    'abhinav.p@eshopbox.com'
  ];
  selDateFilters: any;
  selectedData: any;
  isSelDateErr: boolean = false;
  objectKeys = Object.keys;
  accountslug: string;
  isChecked = false;
  VirtualkitJobType: string;
  isFilterScreen: boolean = false;
  externalNavigation: boolean;
  NEW_ORDERS_V2_PARAM = NEW_ORDERS_V2_PARAM;
  isSelectFieldsDirect: boolean = false;
  isMatchingFilter: boolean;

  constructor(
    private _route: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store<{ ordersState: OrdersState }>,
    private pusherSrv: PusherService,
    private pusherSchedule: PusherScheduleService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<ExportOrdersComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { prevDate: any; filterType: any; currentFilter: any; type?: any },
    private authService: AuthService,
    private router: Router
  ) {
    this.salesChannelData = localStorage.salesChannel ? JSON.parse(localStorage.salesChannel) : [];
    let fullfillmentCenterData = localStorage.fulfillmentCenter ? JSON.parse(localStorage.fulfillmentCenter) : [];
    this.enrolledFCData = fullfillmentCenterData
      ? fullfillmentCenterData.filter(data => data['enrollmentStatus'] == 'ENROLLED')
      : [];
  }

  ngOnInit() {
    this.accountslug = this.authService.getAccountSlug();
    const currentPath = window.location.pathname;
    this.externalNavigation =
      currentPath == '/order-V2/export/draft-returns' || this.data.type === 'draft_return_report' ? true : false;
    this.getTemplateList();
    // Add class to HTML tag
    document.querySelector('html').classList.add('order-expo');
  }

  /** @description Method Will Trigger Once Date is Valid */
  public validOrderRangeEvent(event) {
    this.orderCreatedRangeForm = event;
    this.isReportExportStep = true;
    this.isOrderExportStep = this.isSelectFields = this.isScheduleReport = this.isOrderCreatedRang = false;
  }

  /** @description Activate Second Step */
  public onOrderExportChange(val) {
    this.isOrderExportStep = this.isReportExportStep = this.isScheduleReport = false;
    this.selDateFilters = {};
    if (val === `Orders matching your filters`) {
      this.isOrderCreatedRang = false;
      this.isSelectFields = true;
    } else {
      this.isOrderCreatedRang = true;
      this.isNotClrDtFilters = true;
      this.isFilterScreen = true;
      this.isSelectFields = false;
    }
  }

  /** @description Store Value of Checkbox Select All and Single Select  */
  public onReportExportChange(data) {
    if (data?.jobType == 'sale_order_report_v2' && this.isChecked) {
      this.VirtualkitJobType = 'sale_order_report_virtual_kit';
    } else {
      this.VirtualkitJobType = '';
      this.isChecked = false;
    }
    this.columnSelected = {};
    this.defaultSelectedCols(data);
    // this.activateColumns();
    this.selDateFilters = {};
    if (
      data &&
      [
        'draft_return_report',
        'incorrect_return_item_report',
        'bq_sale_order_payments_report',
        'all_returns_virtual_kit',
        'all_draft_return'
      ].includes(data?.jobType)
    ) {
      this.isOrderCreatedRang = true;
      this.isNotClrDtFilters = true;
      this.isFilterScreen = true;
      this.isOrderExportStep = this.isSelectFields = this.isScheduleReport = this.isReportExportStep = false;
    } else this.orderExportStep();
    // Section scroll top Code
    // setTimeout(() => {
    //   let element = document.querySelector('#gotoTop1');
    //   element.scrollIntoView({ behavior: 'smooth' });
    // }, 10);
  }

  public defaultSelectedCols(data) {
    this.selectedData = _.cloneDeep(data);
    this.templateList.data.forEach(val => {
      if (val.id === data.id) {
        val = _.cloneDeep(data);
        this.templateSelected = { ...val };
        this.jobType = val.jobType;
        for (let key of Object.keys(val.columnStatus)) {
          let selected: boolean = true;
          val.columnStatus[key].forEach(status => {
            if (status.checked) {
              if (!(key in this.columnSelected)) this.columnSelected[key] = [];
              this.columnSelected[key].push(status.key);
            } else selected = false;
          });
          this.selectAllOption[key] = selected;
        }
      }
    });
  }
  /** @description Activate Report Export Step */
  public orderExportStep() {
    this.isOrderExportStep = true;
    this.isSelectFields = this.isScheduleReport = this.isReportExportStep = this.isOrderCreatedRang = false;
  }
  /** @description Activate Column Selection Step */
  public activateColumns() {
    this.isSelectFields = true;
    this.isScheduleReport = this.isOrderExportStep = this.isReportExportStep = this.isOrderCreatedRang = false;
  }

  /** @description Activate Order Range Form */
  public activateOrderRange() {
    this.selectAllOption = {};
    this.columnSelected = {};
    // this.orderCreatedRangeForm.reset();
    // this.getTemplateList();
    this.defaultSelectedCols(this.selectedData);
    this.isOrderCreatedRang = true;
    this.isScheduleReport = this.isOrderExportStep = this.isReportExportStep = this.isSelectFields = false;
  }

  selDateFilterEvt(req) {
    this.selDateFilters = { ...req };
    if (this.selDateFilters && this.selDateFilters.salesChannel && this.selDateFilters.salesChannel.value.length == 0) {
      delete this.selDateFilters.salesChannel;
    }
    if (this.selDateFilters && this.selDateFilters.portal && this.selDateFilters.portal.value.length == 0) {
      delete this.selDateFilters.portal;
    }
    if (Object.keys(req).length > 0 || this.jobType == 'draft_return_report') {
      if (
        ((this.jobType == 'incorrect_return_item_report' || this.jobType == 'bq_sale_order_payments_report') &&
          Object.keys(req).length == 1 &&
          Object.keys(req).includes('salesChannel')) ||
        (this.jobType == 'draft_return_report' &&
          !this.selDateFilters?.returnCompletedOn &&
          !this.selDateFilters?.returnReceivedOn)
      ) {
        this.isSelDateErr = true;
        setTimeout(() => {
          this.isSelDateErr = false;
        }, 5000);
      } else {
        this.isSelDateErr = false;
        this.isFilterScreen = false;
        this.isSelectFields = true;
        let elem = document.querySelector('.filter-scroll');
        elem.scrollIntoView({ behavior: 'smooth' });
        this.isScheduleReport = this.isOrderExportStep = this.isReportExportStep = this.isOrderCreatedRang = false;
      }
    } else {
      this.isSelDateErr = true;
      setTimeout(() => {
        this.isSelDateErr = false;
      }, 5000);
    }
  }

  onReturnSelect() {
    let object;
    if (this.data['isDraft'] && this.data['isDraft'] == 'is-Yes') {
      object = this.templateList.data[0];
    } else if (this.data['isDraft'] !== 'is-Yes' || this.data['isDraft'] == undefined) {
      object = this.templateList.data[1];
    }
    this.defaultSelectedCols(object);
    this.isMatchingFilter = true;
    this.isSelDateErr = false;
    this.isFilterScreen = false;
    this.isSelectFieldsDirect = true;
    let elem = document.querySelector('.filter-scroll');
    elem.scrollIntoView({ behavior: 'smooth' });
    this.isScheduleReport = this.isOrderExportStep = this.isReportExportStep = this.isOrderCreatedRang = false;
  }

  /** @description Activate Schedule Selection Step */
  public scheduleStep() {
    // Section scroll top Code
    let elem = document.querySelector('.order-export');
    elem.scrollIntoView({ behavior: 'smooth' });
    this.isScheduleReport = true;
    this.isSelectFields = this.isOrderExportStep = this.isReportExportStep = this.isOrderCreatedRang = false;
    // Section scroll top Code
    setTimeout(() => {
      let element = document.querySelector('#gotoTop2');
      element.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  /** @description Length Of Selected Columns */
  public selectedColsLength() {
    let colLength = 0;
    for (let key of Object.keys(this.columnSelected)) {
      colLength += this.columnSelected[key].length;
    }
    return colLength;
  }

  /** @description Activate Order Export Step */
  public activateOrderExportStep() {
    this.selectAllOption = {};
    this.columnSelected = {};
    this.isNotClrDtFilters = false;
    if (this.orderCreatedRangeForm) this.orderCreatedRangeForm.reset();
    // this.getTemplateList();
    this.defaultSelectedCols(this.selectedData);
    // this.reportExport.setValue('');
    this.isOrderExportStep = true;
    this.isSelectFields = this.isScheduleReport = this.isReportExportStep = this.isOrderCreatedRang = false;
  }

  /** @description Activate Report Export Step */
  public activateReportExportStep() {
    this.selectAllOption = {};
    this.columnSelected = {};
    this.isNotClrDtFilters = false;
    // this.getTemplateList();
    this.defaultSelectedCols(this.selectedData);
    this.orderExport.setValue('');
    this.isReportExportStep = true;
    this.isSelectFields = this.isScheduleReport = this.isOrderExportStep = this.isOrderCreatedRang = false;
  }

  /** @description Method To Get Selected Columns */
  public checkBoxStatusEvent(event) {
    this.columnSelected = event;
  }

  /** @description Order Export Request Body */
  public exportOrder() {
    const query = this._route.snapshot.queryParamMap.get('query');
    // if (!this.data.currentFilter.details.orderReceivedOn) {
    //   // Get today's date
    //   const today = new Date();
    //   // Calculate the date 30 days ago
    //   const last30thDay = new Date();
    //   last30thDay.setDate(today.getDate() - 29);
    //   this.data.currentFilter.details['orderReceivedOn'] = {
    //     condition: 'is between',
    //     displayKey: 'Order Created in Eshopbox',
    //     value: [moment(last30thDay).format('YYYY-MM-DD'), moment(today).format('YYYY-MM-DD')]
    //   };
    // }
    let requestBody = {
      jobType: this.VirtualkitJobType ? this.VirtualkitJobType : this.jobType
    };
    if (requestBody['jobType'] == 'bq_sale_order_payments_report') {
      requestBody['accountSlug'] = this.accountslug;
    }
    const accountSlug = localStorage.getItem('workspace');
    if (accountSlug != 'eshop' && this.jobType == 'draft_return_report') {
      this.columnSelected['Draft return items'] = this.columnSelected['Draft return items'].filter(
        x => x != 'Brand' && x != 'Workspace'
      );
      this.columnSelected['Draft return shipment'] = this.columnSelected['Draft return shipment'].filter(
        x => x != 'Selling Party'
      );
      requestBody['columnsExport'] = this.columnSelected;
    } else requestBody['columnsExport'] = this.columnSelected;
    requestBody['exportFilters'] = {};
    if (this.orderExport.value === 'Orders matching your filters') {
      if (!query) {
        requestBody['exportFilters'] = {
          ...this.data.currentFilter.details,
          filter: {
            report_type: 'orders_matching_filter',
            query: query
          }
        };
      } else {
        requestBody['exportFilters'] = {
          filter: {
            report_type: 'orders_matching_filter',
            query: query
          }
        };
      }
    } else if (this.orderExport.value === 'All orders' || this.jobType == 'all_returns_virtual_kit') {
      requestBody['exportFilters'] = {
        ...this.selDateFilters,
        filter: {
          report_type: 'all_order'
        }
      };
    } else {
      requestBody['exportFilters'] = {
        ...this.selDateFilters
      };
    }
    if (this.data.type == 'no-scan-return' && this.isSelectFieldsDirect && this.data['matchFilters']) {
      this.data['matchFilters']['sellingParty'] = {
        condition: 'is',
        displayKey: `Selling party: ${this.data['workspace']}`,
        value: this.data['workspace']
      };
      this.data['matchFilters']['warehouses'] = {
        condition: 'is',
        displayKey: `warehouses: ${this.data['warehouse']}`,
        value: [this.data['warehouse']]
      };
      let matchFilters = this.data['matchFilters'];
      requestBody['exportFilters'] = {
        ...requestBody['exportFilters'],
        ...matchFilters
      };
    }

    if (this.accountslug == 'tcns' || this.accountslug == 'tcnspc') {
      const filtercolom = Object.keys(requestBody['columnsExport'])
        .filter(key => key != 'Customer')
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: requestBody['columnsExport'][key] });
        }, {});
      requestBody['columnsExport'] = filtercolom;
      var columnstOrderFilter = requestBody['columnsExport']['Order']
        ? requestBody['columnsExport']['Order'].filter(x => x !== 'Invoice Url')
        : '';
      if (columnstOrderFilter) requestBody['columnsExport']['Order'] = columnstOrderFilter;
    }
    //console.log('requestBody', requestBody);
    this.exportOrderApiCall(requestBody);
    this.VirtualkitJobType = '';
    this.isChecked = false;
  }

  /** @description Order Export Api Call */
  public exportOrderApiCall(requestBody) {
    const filterRequestBody = requestBody;
    if (!['all_draft_return', 'draft_return_report', 'all_returns_virtual_kit'].includes(filterRequestBody.jobType)) {
      delete filterRequestBody.exportFilters.sellingParty;
    }
    // if (filterRequestBody.jobType !== 'draft_return_report' || filterRequestBody.jobType !== 'draft_return_report') {
    //   delete filterRequestBody.exportFilters.sellingParty;
    // }

    this.loader = true;
    this.store.dispatch(new ExportTemplate(filterRequestBody));
    this.exportTemplateSubscription$ = this.store
      .pipe(select(fromSelectorV2.exportTemplateData))
      .subscribe(response => {
        this.loader = false;
        this.closeDialog(response);
      });
  }

  /** @description Schedule Call */
  public scheduleApiCall(requestBody) {
    requestBody['columnsExport'] = this.columnSelected;
    requestBody['jobType'] = this.VirtualkitJobType ? this.VirtualkitJobType : this.jobType;
    if (
      requestBody &&
      requestBody.filter &&
      requestBody.filter.salesChannel &&
      requestBody.filter.salesChannel.value.length == 0
    ) {
      delete requestBody.filter.salesChannel;
    }
    if (requestBody && requestBody.filter && requestBody.filter.portal && requestBody.filter.portal.value.length == 0) {
      delete requestBody.filter.portal;
    }
    // requestBody['filter'] = { ...this.data.currentFilter.details };
    if (
      ['draft_return_report', 'incorrect_return_item_report', 'bq_sale_order_payments_report'].includes(this.jobType)
    ) {
      requestBody['filter'] = { ...requestBody['filter'] };
    } else {
      requestBody['filter'] = {};
      requestBody['filter'] = { ...this.data.currentFilter.details };
    }
    if (Object.keys(requestBody['filter']).length > 0) {
      Object.keys(requestBody['filter']).forEach((key, index) => {
        if (this.removeDateFilters.includes(key)) delete requestBody['filter'][key];
      });
    }
    if (requestBody['jobType'] == 'bq_sale_order_payments_report') {
      requestBody['accountSlug'] = this.accountslug;
    }
    this.store.dispatch(new ScheduleReport(requestBody));
    this.exportTemplateSubscription$ = this.store
      .pipe(select(fromSelectorV2.scheduleTemplateData))
      .subscribe(response => {
        if (response && response.scheduleTemplateData && !response.scheduleTemplateData.error) {
          this.dialogRef.close({ event: 'close', data: response });
        }
      });
  }

  /** @description Get Template Data */
  public getTemplateList() {
    this.loader = true;
    //If eshop email user show Payment rec report
    let jobTypeString = '';
    if (this.externalNavigation) {
      jobTypeString = 'draft_return_report';
    } else if (localStorage.getItem('email').includes('@eshopbox.com')) {
      if (this.data && this.data?.type == 'no-scan-return') jobTypeString = 'all_returns_virtual_kit,all_draft_return';
      else
        jobTypeString =
          'shipment_report,payments,sale_order_report_v2,draft_return_report,bq_sale_order_payments_report';
    } else if (this.data && this.data?.type == 'no-scan-return') {
      // jobType - all_draft_return - All draft return
      // jobType - all_returns_virtual_kit - All return
      jobTypeString = 'all_returns_virtual_kit,all_draft_return';
    } else {
      jobTypeString = 'shipment_report,payments,sale_order_report_v2,draft_return_report';
    }
    if (this.emailIds.includes(localStorage.getItem('email'))) this.store.dispatch(new GetTemplate(jobTypeString));
    else this.store.dispatch(new GetTemplate(jobTypeString));
    this.getTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.templateData)).subscribe(response => {
      if (response && response.templateDataLoaded) {
        this.loader = false;
        let salesIndex = response.templateData.data.findIndex(data => data.id === '257');
        // console.log("salesIndex",salesIndex)
        // let salesDetails = response.templateData.data[salesIndex];
        // console.log("salesDetails",salesDetails)
        // response.templateData.data.splice(salesIndex, 1);
        // response.templateData.data.splice(0, 0, salesDetails);
        this.templateList = _.cloneDeep(response.templateData);

        const filteredData = [];
        var filteredTemplate = this.templateList.data.filter(x => x !== undefined);
        for (let data of filteredTemplate) {
          if (data?.jobType == 'payments') {
            let columnStatusMap = {
              Order: data.columnStatus['Order'],
              Product: data.columnStatus['Product'],
              Quantity: data.columnStatus['Quantity'],
              Pricing: data.columnStatus['Pricing']
              // 'Customer Info': data.columnStatus['Customer Info'],
              // 'Product Info': data.columnStatus['Product Info'],
              // Timeline: data.columnStatus['Timeline'],
              // 'Quantity Info': data.columnStatus['Quantity Info'],
              // Payment: data.columnStatus['Payment'],
              // Fees: data.columnStatus['Fees']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else if (data.jobType == 'sale_order_report_v2') {
            let columnStatusMap = {
              Order: data.columnStatus['Order'],
              'Shipping provider': data.columnStatus['Shipping provider']
            };
            if ('Cancellation' in data.columnStatus)
              columnStatusMap['Cancellation'] = data.columnStatus['Cancellation'];
            columnStatusMap['Customer'] = data.columnStatus['Customer'];
            columnStatusMap['Failed delivery'] = data.columnStatus['Failed delivery'];
            columnStatusMap['Incident'] = data.columnStatus['Incident'];
            columnStatusMap['Failed pickup'] = data.columnStatus['Failed pickup'];
            columnStatusMap['Product'] = data.columnStatus['Product'];
            columnStatusMap['Pricing'] = data.columnStatus['Pricing'];
            columnStatusMap['Return'] = data.columnStatus['Return'];
            columnStatusMap['Status'] = data.columnStatus['Status'];
            columnStatusMap['Timeline'] = data.columnStatus['Timeline'];
            columnStatusMap['Payment details'] = data.columnStatus['Payment details'];
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else if (data?.jobType == 'draft_return_report') {
            let columnStatusMap = {
              'Draft return shipment': data.columnStatus['Draft return shipment'],
              'Draft return items': data.columnStatus['Draft return items']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else if (data?.jobType == 'incorrect_return_item_report') {
            let columnStatusMap = {
              'Incorrect return item details': data.columnStatus['Incorrect return item details'],
              'Order details': data.columnStatus['Order details']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else if (data?.jobType == 'bq_sale_order_payments_report') {
            let columnStatusMap = {
              Order: data.columnStatus['Order'],
              Pricing: data.columnStatus['Pricing'],
              Product: data.columnStatus['Product'],
              Quantity: data.columnStatus['Quantity']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else if (data.jobType == 'all_draft_return') {
            let columnStatusMap = {
              'Draft return shipment': data.columnStatus['Draft return shipment'],
              'Draft return items': data.columnStatus['Draft return items']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else if (data.jobType == 'all_returns_virtual_kit') {
            let columnStatusMap = {
              Order: data.columnStatus['Order'],
              Product: data.columnStatus['Product'],
              Return: data.columnStatus['Return'],
              'Shipping provider': data.columnStatus['Shipping provider'],
              Status: data.columnStatus['Status'],
              Timeline: data.columnStatus['Timeline']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          } else {
            let columnStatusMap = {
              Order: data.columnStatus['Order'],
              Customer: data.columnStatus['Customer'],
              Product: data.columnStatus['Product'],
              Pricing: data.columnStatus['Pricing'],
              'Shipping provider': data.columnStatus['Shipping provider'],
              Timeline: data.columnStatus['Timeline'],
              'Fulfillment and tracking Status': data.columnStatus['Fulfillment and tracking Status'],
              Return: data.columnStatus['Return']
            };
            data['columnStatus'] = { ...columnStatusMap };
            filteredData.push(data);
          }
        }
        this.templateList.data = filteredData;
        if (this.externalNavigation) {
          const draftReturn = this.templateList.data.find(x => x.jobType === 'draft_return_report');
          this.reportExport.setValue(draftReturn.template);
          this.onReportExportChange(draftReturn);
        }
      }
    });
  }

  /** @description Save Template */
  public saveTemplate() {
    const dialogRef = this.dialog.open(SaveTemplateExportComponent, {
      width: '615px',
      panelClass: 'custom-modalbox',
      data: { jobType: this.jobType, columnsExport: this.columnSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['event'] == 'close') {
        if (result.data && result.data.saveTemplate && !result.data['saveTemplate']['error']) {
          this.isTemplateSaved = true;
          this.isTemplateDeleted = false;
          setTimeout(() => {
            this.isTemplateSaved = false;
          }, 15000);
          this.getTemplateList();
        }
        this.getTemplateSubscription$.unsubscribe();
      }
    });
  }

  /** @description Edit Template */
  public orderEdit(data) {
    const dialogRef = this.dialog.open(TemplateEditExportComponent, {
      width: '615px',
      panelClass: 'custom-modalbox',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['event'] == 'close') {
        if (result.data && result.data.deleteTemplateData && !result.data['deleteTemplateData']['error']) {
          this.isTemplateDeleted = true;
          this.isTemplateSaved = false;
          setTimeout(() => {
            this.isTemplateDeleted = false;
          }, 15000);
        } else if (result.data && result.data.editTemplateData) {
          this.isTemplateSaved = true;
          this.isTemplateDeleted = false;
          setTimeout(() => {
            this.isTemplateSaved = false;
          }, 15000);
        }
        this.getTemplateSubscription$.unsubscribe();
        this.getTemplateList();
      }
    });
  }

  toggleCheckbox(event: Event, data) {
    event.preventDefault();
    this.reportExport.setValue(data.template);
    if (this.isChecked) {
      this.VirtualkitJobType = 'sale_order_report_virtual_kit';
      // this.onReportExportChange(data);
    }
  }

  /** @description Show template save success message */
  public closeTemplateSaved(toggle?) {
    if (toggle) this.isTemplateDeleted = false;
    this.isTemplateSaved = false;
  }

  /** @description Close error message */
  closeErr() {
    this.isSelDateErr = false;
  }

  /** @description Maintain sequence of object keys */
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  closeDialog(response?) {
    if (this.externalNavigation && window.location.pathname == '/order-V2/export/draft-returns') {
      this.router.navigate(['/order-V2/list'], { queryParams: NEW_ORDERS_V2_PARAM });
    } else {
      if (window.location.pathname == '/process-no-scan-return/receive-return-list') {
        RETURN_NO_SCAN_PARAM.location = this.authService.getFlexSlug();
        this.router.navigate(['/process-no-scan-return/receive-return-list'], { queryParams: RETURN_NO_SCAN_PARAM });
      }
    }
    this.dialogRef.close({ event: 'close', data: response ? response : '' });
  }

  ngOnDestroy() {
    if (this.getTemplateSubscription$) this.getTemplateSubscription$.unsubscribe();
    if (this.exportTemplateSubscription$) this.exportTemplateSubscription$.unsubscribe();

    // Add class to HTML tag
    document.querySelector('html').classList.remove('order-expo');
  }
}
