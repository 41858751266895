import {
  Directive,
  Input,
  ElementRef,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EventEmitter,
  OnChanges,
  OnInit,
  OnDestroy,
  HostListener,
  Output,
  Inject,
  Optional
} from '@angular/core';
import { TooltipComponent } from './tooltip/tooltip.component';
import {
  backwardCompatibilityOptions,
  defaultOptions,
  TooltipOptions,
  TooltipOptionsService
} from './tooltip-options.service';

@Directive({
  selector: '[reusableTooltip]',
  exportAs: 'tooltip'
})
export class ReusableDirective implements OnChanges, OnInit, OnDestroy {
  @Input() options: any = {};
  @Input('tooltip') tooltipValue: string;
  @Input() placement: string;
  @Input() contentType: string = 'string';
  @Input() zIndex: number;
  @Input() animationDuration: number;
  @Input() tooltipClass: string;
  @Input() maxWidth: string;
  @Input() showDelay: number;
  @Input() hideDelay: number;
  @Input() hideDelayAfterClick: number;
  @Input() trigger: string;
  @Input() display: boolean = true;
  @Input() displayTouchscreen: boolean = true;
  @Input() shadow: boolean;
  @Input() theme: string;
  @Input() offset: number;
  @Input() width: string;
  @Input() id: string;
  @Input() pointerEvents: boolean;
  @Input() position: string;

  @Output() events: EventEmitter<any> = new EventEmitter();

  private componentRef: any;
  private createTimeoutId: any;
  private showTimeoutId: any;
  private hideTimeoutId: any;
  private destroyTimeoutId: any;
  private componentSubscribe: any;

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Optional() @Inject(TooltipOptionsService) private initOptions: TooltipOptions
  ) {
    this.options = this.initOptions || defaultOptions;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: any): void {
    this.initOptions = this.renameProperties(this.initOptions);
    let properties = this.getProperties(changes);
    // properties = this.renameProperties(properties);
    this.applyOptionsDefault(defaultOptions, properties);
  }

  private renameProperties(options: any): any {
    for (const key in options) {
      if (backwardCompatibilityOptions[key]) {
        options[backwardCompatibilityOptions[key]] = options[key];
        delete options[key];
      }
    }
    return options;
  }

  private getProperties(changes: any): any {
    const properties: any = {};
    for (const propName in changes) {
      if (propName !== 'options' && propName !== 'tooltipValue') {
        properties[propName] = changes[propName].currentValue;
      }
      if (propName === 'options') {
        properties[propName] = changes[propName].currentValue;
      }
    }
    return properties;
  }

  private applyOptionsDefault(defaultOptions: any, properties: any): void {
    this.options = Object.assign({}, defaultOptions, this.initOptions || {}, properties);
  }

  ngOnDestroy(): void {
    this.destroyTooltip({ fast: true });
    if (this.componentSubscribe) {
      this.componentSubscribe.unsubscribe();
    }
  }

  private getElementPosition() {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }

  private createTooltip(): void {
    this.clearTimeouts();
    const position = this.getElementPosition();
    this.createTimeoutId = setTimeout(() => {
      this.appendComponentToBody(TooltipComponent);
    }, this.getShowDelay());
    this.showTimeoutId = setTimeout(() => {
      this.showTooltipElem();
    }, this.getShowDelay());
  }

  private destroyTooltip(options: { fast?: boolean } = { fast: false }): void {
    this.clearTimeouts();
    if (!this.isTooltipDestroyed()) {
      this.hideTimeoutId = setTimeout(
        () => {
          this.hideTooltip();
        },
        options.fast ? 0 : this.getHideDelay()
      );
      this.destroyTimeoutId = setTimeout(
        () => {
          if (!this.componentRef || this.isTooltipDestroyed()) {
            return;
          }
          this.appRef.detachView(this.componentRef.hostView);
          this.componentRef.destroy();
          this.events.emit({ type: 'hidden', position: this.tooltipPosition });
        },
        options.fast ? 0 : this.destroyDelay
      );
    }
  }

  private appendComponentToBody(component: any): void {
    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    this.componentRef.instance.data = {
      value: this.tooltipValue,
      element: this.elementRef.nativeElement,
      elementPosition: this.tooltipPosition,
      options: this.options
    };
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = this.componentRef.hostView.rootNodes[0];
    document.body.appendChild(domElem);
    this.componentSubscribe = this.componentRef.instance.events.subscribe((event: any) => {
      this.handleEvents(event);
    });
  }

  private showTooltipElem(): void {
    this.clearTimeouts();
    this.componentRef.instance.show = true;
    this.events.emit({ type: 'show', position: this.tooltipPosition });
  }

  private hideTooltip(): void {
    if (!this.componentRef || this.isTooltipDestroyed()) {
      return;
    }
    this.componentRef.instance.show = false;
    this.events.emit({ type: 'hide', position: this.tooltipPosition });
  }

  private isTooltipDestroyed(): boolean {
    return this.componentRef && this.componentRef.hostView.destroyed;
  }

  private getShowDelay(): number {
    return this.options['showDelay'] || this.showDelay;
  }

  private getHideDelay(): number {
    const hideDelay = this.options['hideDelay'] || this.hideDelay;
    const hideDelayTouchscreen = this.options['hideDelayTouchscreen'];
    return this.isTouchScreen() ? hideDelayTouchscreen : hideDelay;
  }

  private isTouchScreen(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  private clearTimeouts(): void {
    clearTimeout(this.createTimeoutId);
    clearTimeout(this.showTimeoutId);
    clearTimeout(this.hideTimeoutId);
    clearTimeout(this.destroyTimeoutId);
  }

  @HostListener('mouseenter')
  @HostListener('focusin')
  onMouseEnter(): void {
    if (this.shouldDisplayOnHover()) {
      this.show();
    }
  }

  @HostListener('mouseleave')
  @HostListener('focusout')
  onMouseLeave(): void {
    if (this.options['trigger'] === 'hover') {
      this.hide();
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.shouldDisplayOnClick()) {
      this.show();
      this.hideTimeoutId = setTimeout(() => this.hide(), this.options['hideDelayAfterClick']);
    }
  }

  private shouldDisplayOnHover(): boolean {
    if (this.options['display'] === false) return false;
    if (this.options['displayTouchscreen'] === false && this.isTouchScreen()) return false;
    return this.options['trigger'] === 'hover';
  }

  private shouldDisplayOnClick(): boolean {
    if (this.options['display'] === false) return false;
    if (this.options['displayTouchscreen'] === false && this.isTouchScreen()) return false;
    return this.options['trigger'] === 'click';
  }

  private show(): void {
    if (!this.tooltipValue) return;
    if (!this.componentRef || this.isTooltipDestroyed()) {
      this.createTooltip();
    } else {
      this.showTooltipElem();
    }
  }

  private hide(): void {
    this.destroyTooltip();
  }

  private handleEvents(event: any): void {
    if (event.type === 'shown') {
      this.events.emit({ type: 'shown', position: this.tooltipPosition });
    } else if (event.type === 'mouseenter') {
      clearTimeout(this.hideTimeoutId);
      clearTimeout(this.destroyTimeoutId);
    } else if (event.type === 'mouseleave') {
      this.hide();
    }
  }

  get tooltipPosition(): string {
    return this.options['position'] || this.position || this.elementRef.nativeElement.getBoundingClientRect();
  }

  private get destroyDelay(): number {
    return this.options['destroyDelay'] || Number(this.getHideDelay()) + Number(this.options['animationDuration']);
  }
}
