import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as wdActions from './weight-discrypancy.actions';
// import { getRuleListALL, getShippingState } from './shipping-mode.selector';
// import { getRuleList } from './shipping-mode.reducer';
import { Store, createSelector, select } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
// import { ShippingModeService } from '../services/shipping-mode.service';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/app.state';
// import * as fromReducer from './shipping-mode.reducer';
import { getWdListALL } from './weight-discrypancy.selector';
import { wdState } from './weight-discrypancy.reducer';
import { WeightDiscrypancyService } from '../service/weight-discrypancy.service';

@Injectable()
export class weightDiscrypancyEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private wdsvc: WeightDiscrypancyService) {}
  @Effect()
  getList$: Observable<any> = this.actions$.pipe(
    ofType(wdActions.weightDiscrypancyList),
    withLatestFrom(this.store.select(getWdListALL)),
    mergeMap(([actions, list]) => {
      if (!list.length || (actions['payload'] != undefined && actions['payload']['force'])) {
        return this.wdsvc.getWeightDiscripancy(actions['payload']).pipe(
          switchMap((data: any) => {
            data.isSmartSegment = actions['payload']['isSmartSegment'];
            return [new wdActions.weightDiscrypancyListSuccessAction(data)];
          }),
          catchError((data: any) => {
            return [new wdActions.weightDiscrypancyListErrorAction(data)];
          })
        );
      } else {
        return of(wdActions.dummyAction);
      }
    })
  );

  @Effect()
  save$: Observable<any> = this.actions$.pipe(
    ofType(wdActions.weightDiscrypancySave),
    mergeMap(actions => {
      return this.wdsvc.putApi(actions['payload']).pipe(
        switchMap((data: any) => {
          this.wdsvc.alert('success', data['message'] || 'discrypancy updated successfully');
          data.tabstatus = actions['payload']['tabstatus'];
          return [new wdActions.weightDiscrypancySaveSuccessAction(data)];
        }),
        catchError((data: any) => {
          this.wdsvc.alert('error', data['message'] || 'please try again');
          return [new wdActions.weightDiscrypancySaveErrorAction(data.error.error)];
        })
      );
    })
  );

  //   @Effect()
  //   getList$: Observable<any> = this.actions$.pipe(
  //     ofType<shippingActions>(ruleList),
  //     switchMap(action => {
  //       return this.shippingService.getAllRules().pipe(
  //         map((user: any) => {
  //           return { type: ruleListSuccess, payload: user.shippingRules };
  //         }),
  //         catchError(error => of(dummyAction))
  //       );
  //     })
  //   );
}
