<div class="schedule-order">
  <ng-container *ngIf="loader || appsLoaded">
    <div class="web-loader">
      <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
    </div>
  </ng-container>
  <div class="step-block active || appsLoaded" *ngIf="!loader">
    <h2>Schedule report</h2>
    <form [formGroup]="scheduleForm">
      <div class="sehedule-form">
        <div class="form-field" id="targetUp">
          <label>Report name</label>
          <input type="text" placeholder="Enter report name" formControlName="reportName" required />
          <mat-error
            class="error-message mrg-tp-em"
            *ngIf="
              submitted &&
              scheduleForm.controls['reportName'].errors &&
              scheduleForm.controls['reportName'].errors.required
            "
          >
            This field is required
          </mat-error>
        </div>
        <div class="form-field interval-wrp">
          <label class="heading-label">When do you want the report to be sent to your email</label>
          <p class="label-des">
            Select the interval for your schedule report. Data in the schedule report will be calculated as per the
            interval day and time selected by you
          </p>
          <div class="time-wrap">
            <div class="set-timeline">
              <span class="label-txt"> Interval </span>
              <mat-form-field class="select-team-member">
                <mat-label> Week Day </mat-label>
                <mat-select disableOptionCentering formControlName="every" panelClass="custom-select-ui order-exp">
                  <ng-container *ngFor="let e of interval.every">
                    <mat-option value="{{ e }}"> Every {{ e }} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="mid-txt mrg"
              *ngIf="scheduleForm.value.every === 'week' || scheduleForm.value.every === 'month'"
            >
              <p>on the</p>
            </div>
            <div class="set-day" *ngIf="scheduleForm.value.every === 'week' || scheduleForm.value.every === 'month'">
              <span class="label-txt"> Day </span>
              <mat-form-field class="select-team-member">
                <mat-label> Day </mat-label>
                <mat-select disableOptionCentering formControlName="day" panelClass="custom-select-ui order-exp">
                  <ng-container *ngIf="scheduleForm.value.every === 'week'">
                    <ng-container *ngFor="let e of interval.week">
                      <mat-option value="{{ e }}"> {{ e }} </mat-option>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="scheduleForm.value.every === 'month'">
                    <ng-container *ngFor="let e of interval.month">
                      <mat-option value="{{ e }}"> {{ e }} </mat-option>
                    </ng-container>
                    <mat-option value="lastDay">Last day</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mid-txt">
              <p>at</p>
            </div>
            <div class="hours">
              <span class="label-txt"> Hours </span>
              <mat-form-field class="select-team-member select_custom">
                <mat-label> 09 </mat-label>
                <mat-select
                  disableOptionCentering
                  formControlName="hour"
                  panelClass="custom-select-ui order-exp order-exp-select"
                >
                  <ng-container *ngFor="let h of interval.hour">
                    <mat-option value="{{ h }}"> {{ h }} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mid-txt">
              <p>:</p>
            </div>
            <div class="min">
              <span class="label-txt"> Minutes </span>
              <mat-form-field class="select-team-member select_custom">
                <mat-label> 00 </mat-label>
                <mat-select
                  disableOptionCentering
                  formControlName="minutes"
                  panelClass="custom-select-ui order-exp order-exp-select"
                >
                  <ng-container *ngFor="let m of interval.minutes">
                    <mat-option value="{{ m }}"> {{ m }} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="periods">
              <span class="label-txt"> AM/PM </span>
              <mat-form-field class="select-team-member select_custom">
                <mat-label> AM </mat-label>
                <mat-select
                  disableOptionCentering
                  formControlName="format"
                  panelClass="custom-select-ui order-exp order-exp-select"
                >
                  <ng-container *ngFor="let f of interval.format">
                    <mat-option value="{{ f }}"> {{ f }} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- For order export -->
        <div class="form-field date-rng" *ngIf="['sale_order_report', 'shipment_report', 'payments'].includes(jobType)">
          <label> Date range</label>
          <p class="label-des">Select atleast one of the date range</p>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Placed on channel </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="placedOnChannel">
              <mat-select-trigger> Placed on channel: {{ placedOnChannel }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('orderDateDetailsDTO.orderDate', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Created in Eshopbox </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="createdInEshopbox">
              <mat-select-trigger> Created in Eshopbox: {{ createdInEshopbox }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('orderDateDetailsDTO.orderReceivedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Invoiced on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="invoicedOn">
              <mat-select-trigger> Invoiced on: {{ invoicedOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('invoiceDTO.invoiceDate', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field> -->
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Fulfilled on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="fullFilledOn">
              <mat-select-trigger> Fulfilled on: {{ fullFilledOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('shipmentDTO.dispatchedOnFilter', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Delivered on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="deliveredOn">
              <mat-select-trigger> Delivered on: {{ deliveredOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('ShipmentTracking.deliveredDate', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Cancelled on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="cancelledOn">
              <mat-select-trigger> Cancelled on: {{ cancelledOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('shipmentDTO.cancelledOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Return received on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnReceivedOn">
              <mat-select-trigger> Return received on: {{ returnReceivedOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('returnReceivedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Return completed on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnCompletedOn">
              <mat-select-trigger> Return completed on: {{ returnCompletedOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('returnCompletedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Ready to ship on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="readyToShipOn">
              <mat-select-trigger> Ready to ship on: {{ readyToShipOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('shipmentDTO.readyToShipOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member empty-label-show">
            <mat-label> Return created on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnCreatedOn">
              <mat-select-trigger> Return created on: {{ returnCreatedOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDate('returnCreatedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- V2 -->
        <div class="form-field date-rng" *ngIf="['sale_order_report_v2'].includes(jobType)">
          <label> Date range</label>
          <p class="label-des">Select atleast one of the date range</p>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="placedOnChannel ? (placedOnChannel === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Placed on channel </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="placedOnChannel">
              <mat-select-trigger>
                Placed on channel {{ placedOnChannel === 'None' ? '' : ': ' + placedOnChannel }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderDetails.orderDate', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderDetails.orderDate', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="createdInEshopbox ? (createdInEshopbox === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Created in Eshopbox </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="createdInEshopbox">
              <mat-select-trigger>
                Created in Eshopbox {{ createdInEshopbox === 'None' ? '' : ': ' + createdInEshopbox }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderReceivedOn', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderReceivedOn', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="invoicedOn ? (invoicedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Invoiced on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="invoicedOn">
              <mat-select-trigger>
                Invoiced on {{ invoicedOn === 'None' ? '' : ': ' + invoicedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('shipmentDetails.invoiceDate', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('shipmentDetails.invoiceDate', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field> -->
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="readyToShipOn ? (readyToShipOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Ready to ship on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="readyToShipOn">
              <mat-select-trigger>
                Ready to ship on {{ readyToShipOn === 'None' ? '' : ': ' + readyToShipOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderReadyToShipTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderReadyToShipTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="fullFilledOn ? (fullFilledOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Order handover on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="fullFilledOn">
              <mat-select-trigger>
                Order handover on {{ fullFilledOn === 'None' ? '' : ': ' + fullFilledOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderHandoverDoneTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderHandoverDoneTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="deliveredOn ? (deliveredOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Delivered on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="deliveredOn">
              <mat-select-trigger>
                Delivered on {{ deliveredOn === 'None' ? '' : ': ' + deliveredOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderDeliveredTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderDeliveredTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="cancelledOn ? (cancelledOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Cancelled on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="cancelledOn">
              <mat-select-trigger>
                Cancelled on {{ cancelledOn === 'None' ? '' : ': ' + cancelledOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderCancelledTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderCancelledTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnCreatedOn ? (returnCreatedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return created on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnCreatedOn">
              <mat-select-trigger>
                Return created on {{ returnCreatedOn === 'None' ? '' : ': ' + returnCreatedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('returns.returnDetails.createdOnTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('returns.returnDetails.createdOnTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnReceivedOn ? (returnReceivedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return received on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnReceivedOn">
              <mat-select-trigger>
                Return received on {{ returnReceivedOn === 'None' ? '' : ': ' + returnReceivedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('returns.returnDetails.receivedOnTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('returns.returnDetails.receivedOnTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnCompletedOn ? (returnCompletedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return completed on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnCompletedOn">
              <mat-select-trigger>
                Return completed on {{ returnCompletedOn === 'None' ? '' : ': ' + returnCompletedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('returns.returnDetails.completedOnTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('returns.returnDetails.completedOnTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="updatedOrderDateOn ? (updatedOrderDateOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Order last updated on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="updatedOrderDateOn">
              <mat-select-trigger>
                Order last updated on {{ updatedOrderDateOn === 'None' ? '' : ': ' + updatedOrderDateOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-option
                    value="{{ d.key }}"
                    (click)="getDate('orderLastUpdatedAtTimestamp', d)"
                    *ngIf="d.key != 'Last 90 Days'"
                  >
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-option value="{{ d.key }}" (click)="getDate('orderLastUpdatedAtTimestamp', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- V2 -->

        <!-- For incorrect return order export -->
        <div class="form-field date-rng" *ngIf="['incorrect_return_item_report'].includes(jobType)">
          <label> Date range</label>
          <p class="label-des">Select atleast one of the date range</p>
          <app-export-sales-channel-filter
            (getSalesChannelFilterData)="getSalesChannelFilterData($event)"
            class="export-sales-channel-filter"
          >
          </app-export-sales-channel-filter>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="placedOnChannel ? (placedOnChannel === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Order placed on channel </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="placedOnChannel">
              <mat-select-trigger>
                Order placed on channel {{ placedOnChannel === 'None' ? '' : ': ' + placedOnChannel }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('orderDate', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="createdInEshopbox ? (createdInEshopbox === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Order created in Eshopbox </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="createdInEshopbox">
              <mat-select-trigger>
                Order created in Eshopbox {{ createdInEshopbox === 'None' ? '' : ': ' + createdInEshopbox }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('orderReceivedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnReceivedOn ? (returnReceivedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return received on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnReceivedOn">
              <mat-select-trigger>
                Return received on {{ returnReceivedOn === 'None' ? '' : ': ' + returnReceivedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('returnReceivedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnCompletedOn ? (returnCompletedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return completed on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnCompletedOn">
              <mat-select-trigger>
                Return completed on {{ returnCompletedOn === 'None' ? '' : ': ' + returnCompletedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('returnCompletedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- For draft return order export -->
        <div class="form-field date-rng" *ngIf="['draft_return_report'].includes(jobType)">
          <label> Date range</label>

          <mat-form-field
            class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
            [ngClass]="scheduleForm.get('qcStatusCtrl').value ? 'add-bg-color' : ''"
          >
            <!-- <mat-label> Location </mat-label> -->
            <mat-select
              placeholder="QC Status"
              [multiple]="true"
              disableOptionCentering
              floatlLabel="never"
              [disabled]="query ? true : false"
              panelClass="custom-select-ui order-exp order-export-select"
              formControlName="qcStatusCtrl"
            >
              <mat-select-trigger *ngIf="scheduleForm.get('qcStatusCtrl').value">
                QC Status:<ng-container *ngIf="scheduleForm.get('qcStatusCtrl').value.length == 1">
                  {{ scheduleForm.get('qcStatusCtrl').value[0] == 'received' ? 'QC Pending' : 'QC Completed' }}
                </ng-container>
                <ng-container *ngIf="scheduleForm.get('qcStatusCtrl').value.length == 2">
                  {{ scheduleForm.get('qcStatusCtrl').value[0] == 'received' ? 'QC Pending' : 'QC Completed' }},
                  {{ scheduleForm.get('qcStatusCtrl').value[1] == 'received' ? 'QC Pending' : 'QC Completed' }}
                </ng-container>
              </mat-select-trigger>
              <mat-option value="received">
                QC Pending
                <div class="filter-dropdown">
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
                  <div class="dropdown ">
                    <p>
                      Return not created in Eshopbox. However, it has been received at the fulfilment centre and is
                      currently pending for quality check.
                    </p>
                  </div>
                </div>
              </mat-option>
              <mat-option value="complete">
                QC Completed
                <div class="filter-dropdown">
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
                  <div class="dropdown ">
                    <p>
                      Return not created in Eshopbox. However, it has been received at the fulfilment centre and the
                      quality check is completed.
                    </p>
                  </div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnReceivedOn ? (returnReceivedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return received on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="returnReceivedOn">
              <mat-select-trigger>
                Return received on {{ returnReceivedOn === 'None' ? '' : ': ' + returnReceivedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('returnReceivedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="returnCompletedOn ? (returnCompletedOn === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Return completed on </mat-label>
            <mat-select
              disableOptionCentering
              panelClass="custom-select-ui order-exp"
              [matTooltip]="
                scheduleForm.get('qcStatusCtrl').value == 'received'
                  ? 'For QC status pending, return completed on cannot be selected.'
                  : ''
              "
              [disabled]="scheduleForm.get('qcStatusCtrl').value == 'received'"
              [(value)]="returnCompletedOn"
            >
              <mat-select-trigger>
                Return completed on {{ returnCompletedOn === 'None' ? '' : ': ' + returnCompletedOn }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('returnCompletedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <app-export-portal-filter (getPortalData)="getPortalFilterData($event)" class="export-sales-channel-filter">
          </app-export-portal-filter>

          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="scheduleForm.get('locationFilterFormData').value ? 'add-bg-color' : ''"
          >
            <mat-label> Location </mat-label>
            <mat-select
              [multiple]="true"
              formControlName="locationFilterFormData"
              disableOptionCentering
              panelClass="custom-select-ui order-exp"
              [(value)]="locationFilterFormData"
            >
              <mat-select-trigger *ngIf="scheduleForm.get('locationFilterFormData').value">
                Location: {{ displayLocation(1, scheduleForm.get('locationFilterFormData').value) }}
                <ng-container
                  *ngIf="
                    scheduleForm.value.locationFilterFormData && scheduleForm.value.locationFilterFormData.length > 1
                  "
                >
                  + {{ scheduleForm.value.locationFilterFormData.length - 1 }} more
                </ng-container>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                name="search"
                placeholder="Search"
                #input
                class="search-input"
                onfocus="this.placeholder=''"
                onblur="this.placeholder='Search'"
              />
              <mat-option #allSelected (click)="toggleAllLoaction()" [value]="0">
                <!-- [indeterminate]="portalFormData?.portal ? portalFormData.portal.length != salesChannelData.length : false" -->
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let fullfillmentCenter of filteredEnrolledFCData | searchDropDownFilter: input.value:'facilityLabel'
                "
                [value]="fullfillmentCenter.warehouseId"
                #matOption
              >
                {{ fullfillmentCenter.facilityLabel }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="accountslug == 'eshop'">
            <mat-form-field
              class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
              [ngClass]="scheduleForm.get('sellingParty').value ? 'add-bg-color' : ''"
            >
              <mat-select
                placeholder="Selling party"
                [multiple]="false"
                disableOptionCentering
                panelClass="custom-select-ui location-mrg filter-option order locationFilter"
                floatlLabel="never"
                formControlName="sellingParty"
              >
                <input
                  matInput
                  type="text"
                  placeholder="Search"
                  #workspaceinput
                  class="search-input"
                  [(ngModel)]="searchWorkspace"
                  [ngModelOptions]="{ standalone: true }"
                  (keydown)="$event.keyCode == 32 ? $event.stopPropagation() : ''"
                  name="searchWorkspace"
                />

                <mat-option
                  *ngFor="let workspace of accountLists | searchDropDownFilter: workspaceinput.value:'accountName'"
                  [value]="workspace.accountSlug"
                  #matOption
                >
                  {{ workspace.accountName }} - {{ workspace.accountSlug }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!--  -->
        </div>
        <!-- For inward and recall consignment export -->
        <div class="form-field date-rng" *ngIf="jobType == 'inward_consignment' || jobType == 'recall_consignment'">
          <label> Date range</label>
          <p class="label-des">Select atleast one of the date range</p>
          <mat-form-field class="select-team-member">
            <mat-label> Created on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="createdOn">
              <mat-select-trigger> Created on: {{ createdOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDateCons('createdOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <!-- Future scope -->
          <!-- <mat-form-field class="select-team-member">
          <mat-label> Scheduled on </mat-label>
          <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="scheduleOn">
            <mat-select-trigger> Scheduled on: {{ scheduleOn }} </mat-select-trigger>
            <ng-container *ngFor="let d of interval.dateRangeCons">
              <mat-option value="{{ d.key }}" (click)="getDateCons('scheduleOn', d)"> {{ d.key }} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field> -->
          <mat-form-field class="select-team-member">
            <mat-label> Scheduled for </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="scheduled_for">
              <mat-select-trigger> Scheduled for: {{ scheduled_for }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDateCons('scheduled_for', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member">
            <mat-label> Processing initiated on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="extCreatedAt">
              <mat-select-trigger> Processing initiated on: {{ extCreatedAt }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDateCons('extCreatedAt', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="jobType == 'inward_consignment'">
            <mat-form-field class="select-team-member">
              <mat-label> Inward on </mat-label>
              <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="inwardOn">
                <mat-select-trigger> Inward on: {{ inwardOn }} </mat-select-trigger>
                <ng-container *ngFor="let d of interval.dateRangeCons">
                  <mat-option value="{{ d.key }}" (click)="getDateCons('inwardOn', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="select-team-member">
              <mat-label> Delivered on </mat-label>
              <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="deliveredOn">
                <mat-select-trigger> Delivered on: {{ deliveredOn }} </mat-select-trigger>
                <ng-container *ngFor="let d of interval.dateRangeCons">
                  <mat-option value="{{ d.key }}" (click)="getDateCons('deliveredOn', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="select-team-member">
              <mat-label> Completed on </mat-label>
              <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="completed_at">
                <mat-select-trigger> Completed on: {{ completed_at }} </mat-select-trigger>
                <ng-container *ngFor="let d of interval.dateRangeCons">
                  <mat-option value="{{ d.key }}" (click)="getDateCons('completed_at', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="jobType == 'recall_consignment'">
            <mat-form-field class="select-team-member">
              <mat-label> Packed on </mat-label>
              <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="packed_On">
                <mat-select-trigger> Packed on: {{ packed_On }} </mat-select-trigger>
                <ng-container *ngFor="let d of interval.dateRangeCons">
                  <mat-option value="{{ d.key }}" (click)="getDateCons('packed_On', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="select-team-member">
              <mat-label> Dispatched on </mat-label>
              <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="dispatched_On">
                <mat-select-trigger> Dispatched on: {{ dispatched_On }} </mat-select-trigger>
                <ng-container *ngFor="let d of interval.dateRangeCons">
                  <mat-option value="{{ d.key }}" (click)="getDateCons('dispatched_On', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="select-team-member">
              <mat-label> Recall on </mat-label>
              <mat-select disableOptionCentering panelClass="custom-select-ui order" [(value)]="recall_On">
                <mat-select-trigger> Recall on: {{ recall_On }} </mat-select-trigger>
                <ng-container *ngFor="let d of interval.dateRangeCons">
                  <mat-option value="{{ d.key }}" (click)="getDateCons('recall_On', d)">
                    {{ d.key }}
                    <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <!-- For Product export -->
        <div
          class="form-field date-rng"
          *ngIf="
            jobType == 'product' ||
            jobType == 'product_batch_inventory' ||
            jobType == 'sales_channel_inventory_update_logs' ||
            jobType == 'inventory_ledger_summary_report'
          "
        >
          <label> Date range (optional)</label>
          <!-- <label *ngIf="jobType == 'sales_channel_inventory_update_logs'"> Date range (optional)</label> -->
          <p class="label-des">Select atleast one of the date range</p>
          <mat-form-field class="select-team-member" *ngIf="jobType != 'sales_channel_inventory_update_logs'">
            <mat-label> Created on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="createdOn">
              <mat-select-trigger> Created on: {{ createdOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDateProduct('createdOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-team-member" *ngIf="jobType != 'inventory_ledger_summary_report'">
            <mat-label> Updated on </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="updatedOn">
              <mat-select-trigger> Updated on: {{ updatedOn }} </mat-select-trigger>
              <ng-container *ngFor="let d of interval.dateRangeCons">
                <mat-option value="{{ d.key }}" (click)="getDateProduct('updatedOn', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Filters for Payment Reconciliation Report -->
        <div class="form-field date-rng" *ngIf="['bq_sale_order_payments_report'].includes(jobType)">
          <label> Date range</label>
          <p class="label-des">Specify date range</p>
          <mat-form-field
            class="select-team-member empty-label-show"
            [ngClass]="placedOnChannel ? (placedOnChannel === 'None' ? '' : 'add-bg-color') : ''"
          >
            <mat-label> Order date </mat-label>
            <mat-select disableOptionCentering panelClass="custom-select-ui order-exp" [(value)]="placedOnChannel">
              <mat-select-trigger>
                Order date {{ placedOnChannel === 'None' ? '' : ': ' + placedOnChannel }}
              </mat-select-trigger>
              <ng-container *ngFor="let d of interval.paymentRecReportDateRange">
                <mat-option value="{{ d.key }}" (click)="getDate('paymentOrderDate', d)">
                  {{ d.key }}
                  <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <app-export-sales-channel-filter
            (getSalesChannelFilterData)="getSalesChannelFilterData($event)"
            class="export-sales-channel-filter"
          >
          </app-export-sales-channel-filter>
        </div>

        <div class="form-field date-rng">
          <label> Send this report to</label>
          <mat-form-field class="select-team-member multi-team-select empty-label-show">
            <mat-label>Select team member</mat-label>
            <mat-select
              disableOptionCentering
              formControlName="members"
              panelClass="custom-select-ui multi-sel report-option"
              multiple
            >
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let m of scheduleForm.value.members" [removable]="true" (removed)="onRemove(m)">
                    {{ m?.firstName + ' ' + (m?.lastName != null ? m?.lastName : '') }}
                    <mat-icon matChipRemove>
                      <img
                        src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/chip-close.svg?updatedAt=1632382517887"
                      />
                    </mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="'no matching key found'"
                  [formControl]="searchSelectBox"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let m of filteredKeyList | async" [value]="m">
                <span class="name"> {{ m?.firstName + ' ' + (m?.lastName != null ? m?.lastName : '') }} </span> ({{
                  m?.email
                }})
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            class="error-message"
            *ngIf="
              submitted && scheduleForm.controls['members'].errors && scheduleForm.controls['members'].errors.required
            "
          >
            This field is required
          </mat-error>
        </div>
        <!-- Export To Ftp Section -->
        <ng-container *ngIf="activeFtpAppsConnections && activeFtpAppsConnections.length > 0">
          <div class="export-checkbox">
            <label class="custom-checkbox-export"
              >Export to FTP
              <input (change)="isCheckExportToFtp($event.target.checked)" type="checkbox" />
              <span class="checkmark"></span>
            </label>
          </div>
          <ng-container *ngIf="isSendReportToFTP">
            <div class="form-field date-rng">
              <label>FTP server</label>
              <mat-form-field class="select-team-member multi-team-select empty-label-show ftp-input">
                <mat-label>Select FTP server</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-select-ui multi-sel report-option"
                  formControlName="ftpConnectionId"
                >
                  <mat-option
                    *ngFor="let ftpConnections of activeFtpAppsConnections"
                    value="{{ ftpConnections.connectionId }}"
                  >
                    <span>{{ ftpConnections?.hostname || ftpConnections?.connectionName }}</span>
                  </mat-option>
                </mat-select>
                <mat-error
                  class="error-message mrg-tp-em"
                  *ngIf="
                    submitted &&
                    scheduleForm.controls['ftpConnectionId'].errors &&
                    scheduleForm.controls['ftpConnectionId'].errors.required
                  "
                >
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-field directory-path">
              <label>Directory path</label>
              <input
                type="text"
                placeholder="Enter directory path (eg. /Eshopbox/kapaskraft/saleorder/)"
                formControlName="ftpDirectoryPath"
              />
              <mat-error
                class="error-message mrg-tp-em"
                *ngIf="
                  submitted &&
                  scheduleForm.controls['ftpDirectoryPath'].errors &&
                  scheduleForm.controls['ftpDirectoryPath'].errors.required
                "
              >
                This field is required
              </mat-error>
              <div class="note-text">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info.svg" alt="" />
                <p>
                  Please note the file format exported will be a .csv file. The timestamp will be postponed to the file
                  name after an underscore separator. (eg. sale_order_report_1232124252.csv)
                </p>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="btn-wrp mg-12">
          <button
            class="btn primary-btn no-icon"
            [class.primary-btn-loader]="loaderSchedule"
            [disabled]="
              ((requestBody.dateRange | json) == '{}' &&
                jobType != 'product' &&
                jobType != 'draft_return_report' &&
                jobType != 'product_batch_inventory' &&
                jobType != 'sales_channel_inventory_update_logs') ||
              (scheduleForm.value.every.toLowerCase() != 'day' && !scheduleForm.value.day)
            "
            (click)="scheduleReport()"
          >
            Schedule report
            <!-- <span class="schedule-report-active-tooltip">
              Please select at least one of the date range to proceed
            </span> -->
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-container *ngIf="isSelDateErr">
  <div class="export-succ">
    <img
      class="check-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/alert-triangle.svg"
    />
    Atleast one date range filter is mandatory
    <img
      class="cross-ic"
      (click)="closeErr()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>
