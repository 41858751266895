import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'draft-confirmation-dialog',
  templateUrl: './draft-confirmation-dialog.component.html',
  styleUrls: ['./draft-confirmation-dialog.component.css']
})
export class DraftConfirmationDialogComponent implements OnInit {
  onDiscardClick = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<DraftConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  cancelDiscardOK(action): void {
    if (action == 'discard') {
      this.onDiscardClick.emit(action);
    } else if (action == 'cancel') {
      this.dialogRef.close(false);
    } else if (action == 'ok') {
      this.dialogRef.close(false);
    }
  }

  holdResume(): void {
    this.dialogRef.close(true);
  }

  closeModel(isClosed?: boolean) {
    this.dialogRef.close(null);
  }

  //when user click on Discard then get confirmation of Discard in true
  discard() {
    this.dialogRef.close(true);
  }
}
