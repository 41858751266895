<div class="dialog-ui">
  <h1 mat-dialog-title>
    Import records by CSV
    <img
      (click)="onNoClick()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg"
    />
  </h1>
  <div mat-dialog-content>
    <div class="contain">
      <div class="file-upload">
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/upload-icon.png" />
        <div class="add-file">
          <button>
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/add-icon.png" /> Add File
          </button>
        </div>
        <p>or drop files to upload</p>
      </div>
      <div class="info-txt">
        <table class="table-style" cellspacing="0" cellpadding="0">
          <thead>
            <th>Fields</th>
            <th>Description</th>
            <th>Possible Values</th>
          </thead>
          <tbody>
            <tr>
              <td>SKU</td>
              <td>The sku (Stock Keeping Unit) is a unique name for your product.</td>
              <td>Alphanumeric and can have special characters.</td>
            </tr>
            <tr>
              <td>Product group code</td>
              <td>It us used for grouping of multiple SKUs</td>
              <td>Alphanumeric and can have special characters.</td>
            </tr>
            <tr>
              <td>EAN</td>
              <td>International Article Number (or European Article Number) for the product.</td>
              <td>Numeric</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">
                <a>
                  <img
                    width="11px"
                    src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-download-red.png"
                  />
                  Download template
                </a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="secondary-btn" mat-button (click)="onNoClick()">Cancel</button>
    <button class="pramary-btn" mat-button>Upload and Continue</button>
  </div>
</div>
