import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, SharedService } from 'src/app/shared/services';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-web-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  alertMessageCollection: Array<any> = [];
  subscription: Subscription;
  showLoader: boolean = false;
  autoClose: boolean = false;
  type: any;
  link: string;
  message: any;
  showCross: boolean = true;
  // buildingBlockWorkspaces = [];

  constructor(
    private alertService: AlertService,
    public authService: AuthService,
    // private snackBar: MatSnackBar,
    private sharedService: SharedService
  ) {
    // if (localStorage.getItem('accountList')) {
    //   let accountList = JSON.parse(localStorage.getItem('accountList')).filter(
    //     list => list['workspaceType'] == 'warehouse' || list['workspaceType'] != 'client'
    //   );
    //   accountList.forEach(account => this.buildingBlockWorkspaces.push(account['accountSlug']));
    // }
  }

  ngOnInit() {
    this.sharedService.loaderListener.subscribe(data => {
      this.showLoader = data.status;
    });

    this.subscription = this.alertService.alertMessageState.subscribe(newState => {
      this.addMessage(
        newState.show,
        newState.type,
        newState.content,
        newState.duration,
        newState.showCross,
        newState.link
      );
    });
  }

  addMessage(status: boolean, type: string, message: string, duration?: number, showCross?: boolean, link?: string) {
    this.type = type;
    this.message = message;
    this.autoClose = true;
    this.showCross = showCross !== undefined ? showCross : true;
    this.link = link;
    setTimeout(
      () => {
        this.autoClose = false;
      },
      // duration || this.buildingBlockWorkspaces.includes(localStorage.getItem('workspace')) ? 10000 : 3000
      duration || (window.location.pathname.includes('building-blocks') ? 10000 : 3000)
    );

    // const config = new MatSnackBarConfig();
    // config.panelClass = type === 'error' ? ['error-snackbar'] : ['success-snackbar'];
    // config.duration = duration || 3000;
    // this.snackBar.open(message, '', config);
  }

  closeNotification() {
    this.autoClose = false;
    document.getElementById('alert_div').style.display = 'none';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
