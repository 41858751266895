import * as moment from 'moment';
import {
  CONSIGNMENT_DEFAULT_PARAM_LIST,
  DEFAULT_RECALL_LIST_PARAMETERS,
  DEFAULT_REMOVALS_LIST_PARAMETERS
} from '../shared/constants/consignment.constant';
import { PRODUCTS_DEFAULT_PARAMS_LIST } from '../shared/constants/products.constant';

export const ROUTES = {
  ON_SIGNUP_SUCCESS: '/auth/signin-success',
  ON_SIGNIN_COMPLETE: '/auth/signin-complete',
  CREATE_ACCOUNT: '/auth/create-account',
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  UNDER_REVIEW: 'review',
  DASHBOARD: '/dashboard',
  INSTALLED_APP_LIST: '/installed-app-list',
  // DASHBOARD_OVERVIEW: '/dashboard',
  SETTINGS: '/settings',
  REGISTRATION_WORKSPACE: '/registration/workspace',
  REGISTRATION_COMPANY: '/registration/company',
  REGISTRATION_WAREHOUSE: '/registration/warehouse',
  REGISTRATION_TEAM: '/registration/team',
  REGISTRATION_BRAND: '/registration/brand',
  SUBSCRIPTION_FC: '/fc/subscribe',
  SUBSCRIPTION_CHANNELS: '/channel/subscribe',
  TRACK_FC: '/fc/track',
  TRACK_CHANNELS: '/channel/track',
  EDIT_CHANNEL: '/channel/edit-channel',
  FC_ADD_LOCATION: '/fc/add-location',
  FC_EDIT_LOCATION: '/fc/edit-location',
  FC_HOME: '/fc/home',
  FC_ENABLE: '/fc/enable-fc',
  FC_DASHBOARD: '/fc',
  CHANNELS_HOME: '/channel/home',
  CHANNELS_DASHBOARD: '/channel',

  ADD_BRAND: '/settings/add-brand',
  EDIT_BRAND: '/settings/edit-brand/',
  CREATE_PACKAGING: '/settings/create-package',
  ADD_TEAM_MEMBER: '/settings/add-member',
  EDIT_TEAM_MEMBER: '/settings/edit-member/',
  // ADD_WAREHOUSE: '/settings/add-warehouse',
  // EDIT_WAREHOUSE: '/settings/edit-warehouse/',
  SETTINGS_BRAND: '/settings/brand',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_BATCHTRACKING: '/settings/batch-tracking',
  SETTINGS_SERIAL_NUMBER: '/settings/serial-number',
  SETTING_TAX: '/settings/tax-e-invoice',
  SETTING_TAX_EINVOICE: '/settings/enable-e-invoice',
  SETTING_EINVOICE: '/settings/e-invoice',
  SETTING_ACTIVITIES_LOG: '/settings/activities-log',
  SETTINGS_TEAM: '/settings/team',
  SETTINGS_FEES: '/settings/fees',
  SETTINGS_ADD_BANK_ACCOUNT: '/settings/add-bank-details',
  SETTINGS_BANK_ACCOUNT: '/settings/bank-details',
  SETTINGS_WAREHOUSE: '/settings/warehouse',
  SETTINGS_PACKAGING: '/settings/packaging',
  SETTINGS_IMPORT: '/settings/packaging-import',
  EDIT_PACKAGING: '/settings/edit-packaging-form',
  VERIFY_PACKAGING: '/settings/verify-form-packaging',
  SETTINGS_PACKAGING_LIST: '/settings/packaging-material-list',
  SETTINGS_PACKAGING_LIST_ADMIN: '/settings/packaging-list-admin',
  INVITATION_SIGNUP: '/invite/create-account',
  INVITATION_SIGNIN: '/invite/login',
  CONSIGNMENT_LIST: '/consignment/list',
  SETTINGS_TRANSACTIONRULES: '/settings/payment-terms',
  SETTINGS_PROPERTY_LIST: '/settings/property/list',
  ADD_TRANSACTIONRULE: '/settings/payment-terms-add',
  EDIT_TRANSACTIONRULE: '/settings/payment-terms-edit/',
  RECONCILIATION: '/reconciliation',
  INVOICES_LIST: '/reconciliation/invoice',
  INVOICES_LIST_ALL: '/reconciliation/invoice/all',
  INVOICES_LIST_PAID: '/reconciliation/invoice/paid',
  INVOICES_LIST_REFUND: '/reconciliation/invoice/refund',
  INVOICES_LIST_PARTIAL: '/reconciliation/invoice/partial',
  EDIT_FEES: '/settings/edit-fees/',
  ADD_FEES: '/settings/add-fees',
  ADD_FEE_RULE: '/settings/add-fees-rule-bckp/',
  EDIT_REVISE_FEES: '/settings/edit-revise-fees/',
  FEE_DETAIL: '/settings/fees-details/',
  EXPENSES_LIST: '/reconciliation/invoice/expenses-list',
  PREVIOUS_PAYMENTS: '/reconciliation/payments/previous-payments',
  COD_PAYOUTS: '/reconciliation/payments/cod/payout',
  FINANCE: '/reconciliation/payments/finance',
  FINANCE_IMPORT_OPTION: '/reconciliation/payments/import-payment-option',
  FINANCE_IMPORT: '/reconciliation/payments/import-payment-status',
  COD_ORDER: '/reconciliation/payments/cod/order',
  STATEMENTS: '/reconciliation/payments/statement',
  IMPORT_PRODUCT_VERIFICATTION: '/product/import-product-verification',
  ORDER_SETTLEMENTS: '/reconciliation/payments/order-settlements',
  PAYMENT_DETAILS: '/reconciliation/payments/payment-details',
  // Commented for new quick-start-guide
  // SETUP_OVERVIEW: '/setup/overview',
  // SETUP: 'setup',
  DASHBOARD_URL: '/dashboard/overview',
  PRODUCT_LIST: '/product/list',
  ACTIVITIES: '/activities',
  NOTIFICATION_SETTING: '/notification-setting',
  ADD_PAYOUT_UPLOAD_REPORT: '/reconciliation/payments/add-payout/upload-report',
  ALL_ORDERS: 'order-V2/list',
  PAYMENTS_LIST_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50,
    active: 1,
    sortOrder: 'desc'
  },
  PREVIOUS_PAYMENTS_LIST_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50
  },
  PAYMENTS_PAYOUTS: {
    page: 1,
    perPage: 50
  },
  STATEMENTS_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50
  },
  INVOICES_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50
    // invoiceDateFrom: moment()
    //   .subtract(30, 'days')
    //   .format('YYYY-MM-DD'),
    // invoiceDateTo: moment().format('YYYY-MM-DD')
  },
  INVOICES_AWAITING_PAYMENT_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50,
    //status: 'PARTIAL',
    status: 'UNPAID'
  },
  INVOICES_PAID_PARAMS: {
    page: 1,
    perPage: 50,
    status: 'PAID'
    // invoiceDateFrom: moment()
    //   .subtract(30, 'days')
    //   .format('YYYY-MM-DD'),
    // invoiceDateTo: moment().format('YYYY-MM-DD')
  },
  INVOICES_AWAITING_PAYMENT_PARAMS: {
    page: 1,
    perPage: 50,
    //    status: 'PARTIAL',
    status: 'UNPAID'
    // invoiceDateFrom: moment()
    //   .subtract(30, 'days')
    //   .format('YYYY-MM-DD'),
    // invoiceDateTo: moment().format('YYYY-MM-DD'
  },
  INVOICES_REFUND_PARAMS: {
    page: 1,
    perPage: 50,
    status: 'REFUND'
    // invoiceDateFrom: moment()
    //   .subtract(30, 'days')
    //   .format('YYYY-MM-DD'),
    // invoiceDateTo: moment().format('YYYY-MM-DD')
  },
  EXPENSE_LIST_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50,
    expenseStatus: 'All'
  },
  EXPENSE_LIST_REFUND_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50,
    expenseStatus: 'Reconciled'
  },
  EXPENSE_LIST_DISPUTED_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50,
    expenseStatus: 'Disputed'
  },
  EXPENSE_LIST_FAILED_DEFAULT_PARAMS: {
    page: 1,
    perPage: 50,
    status: 'Failed',
    expenseStatus: 'Failed'
  },
  ORDER_SETTLEMENTS_PARAMS: {
    page: 1,
    perPage: 50
  },
  PRODUCT_LIST_SELLABLE_PARAMS: {
    // sortBy: 'product_service',
    // page: 0,
    // hitsPerPage: 50
  },
  PRODUCT_LIST_NON_SELLABLE_PARAMS: {
    // sortBy: 'product_service',
    // page: 0,
    // hitsPerPage: 50
    // type:'nonsellable'
  },
  PRODUCT_LIST_INWARD_PARAMS: {
    // sortBy: 'product_service',
    // page: 0,
    // hitsPerPage: 50
  },
  PRODUCT_LIST_OUTWARD_PARAMS: {
    // sortBy: 'product_service',
    // page: 0,
    // hitsPerPage: 50
  },
  PRODUCT_LIST_NOT_STOCK_PARAMS: {
    // sortBy: 'product_service',
    // page: 0,
    // hitsPerPage: 50
  },
  FDR_DASHBOARD_URL: '/fdr-dashboard/overview',
  RISK_SCORE_SETTING_URL: '/settings/risk-score',
  ON_TIME_RTS: '/report/ontimeRts',
  // FDR_DASHBOARD_URL: '/fdr-dashboard/overview',
  PINCODE_SERVICEABILITY: '/tool/pincode',
  INCIDENT_MANEGE: '/tool/incidents',
  TICKETS: '/tickets/view-all-tickets',
  VIEW_TICKET: '/tickets/view-ticket',
  ASK_FOR_ASSISTANCE: '/tickets/ask-for-assistance',
  SHIPPING_MODE_URL: '/settings/shipping-mode'
};
export const ADMIN_EMAIL_ACCESS = [
  'muskanbaranwal@eshopbox.com',
  'nikhil.bari@eshopbox.com',
  'anuj.chaudhary@eshopbox.com',
  'mituj@eshopbox.com',
  'aishwarya@eshopbox.com',
  'siva.arava@qualizeal.com',
  'nishtha@eshopbox.com',
  'qualizeal@eshopbox.com',
  'opss@eshopbox.com',
  'jatin.madhok@eshopbox.com',
  'siddharthmalyan@eshopbox.com',
  'nagender.chauhan@eshopbox.com',
  'rashmi.maurya@eshopbox.com'
];

export const WAIVER_EMAIL_ACCESS = [
  'mituj@eshopbox.com',
  'mayur.karwa@eshopbox.com',
  'nikhil.bari@eshopbox.com',
  'tawseef@eshopbox.com',
  'ankush.karwa@eshopbox.com',
  'nagender.chauhan@eshopbox.com',
  'kapil.lohia@eshopbox.com',
  'ashutosh.gaur@eshopbox.com',
  'muskanbaranwal@eshopbox.com',
  'anuj.chaudhary@eshopbox.com'
];

export const NEW_ORDERS_V2_PARAM = {
  selectedView: 'Pending$to$pack',
  currentOrderStatus: 'is-New, Processing',
  page: 0,
  perPage: 50,
  index: 'prod',
  orderReceivedOn: `last30days-${new Date(
    moment()
      .subtract(29, 'days')
      .startOf('day')
      .valueOf()
  ).valueOf()}TO${new Date(
    moment()
      .endOf('day')
      .valueOf()
  ).valueOf()}`
};

export const RETURN_NO_SCAN_PARAM = {
  selectedView: 'IS|Awaiting pickup',
  page: 1,
  perPage: 10,
  location: '',
  status: 'is-Awaiting pickup',
  returnCreatedOn: `last30days-${new Date(
    moment()
      .subtract(29, 'days')
      .startOf('day')

      .valueOf()
  ).valueOf()}TO${new Date(
    moment()
      .endOf('day')
      .valueOf()
  ).valueOf()}`
};

const ALL_APPS_FILTER = {
  filter: 'allApps'
};

const CUSTOMER_NOTIFICATION_PARAM = {
  openCustomerNotifiactions: true
};
const LOCATIONSETTINGLIST = {
  WORKSPACE_CREATION: 'Workspace creation',
  ESHOPBOX_FC_ENABLED: 'Eshopbox FC Enabled',
  SKU_LEVEL: 'Sku level',
  ITEM_LEVEL: 'Item level',
  DO_NOT_MANAGE_INVENTORY: 'No inventory'
};

const FEES_PARAM = {
  page: 1,
  perPage: 50,
  orderBy: 'desc'
};

const claim_param = {
  page: 1,
  perPage: 30,
  createdStartDate: moment()
    .subtract(30, 'day')
    .format('YYYY-MM-DD'),
  createdEndDate: moment()
    .add(0, 'day')
    .format('YYYY-MM-DD')
};
/*

*/
export const ROUTESDATA = {
  /* >>>>>>>>>>>>>>>>>>
    Definition of the below object keys
    type: view/protected || type: view can be accessed by user with view permission but type protected can route can be only accessed by user with manage permission
    URL: Path of the route
    fullPath: This is used for child routes
    scope: This defines the RBAC scope of route https://docs.google.com/spreadsheets/d/1cCsPbKRWdImYWPfmm5vDQwZl4hozF3BJKf8Ts5nGdwI/edit?usp=sharing
    queryParams: For query param of the route
    name: This is used to show the label on UI navigation menu
    scopeLabel: Scope label to show in access denined tooltip and landing page.
    visibileFor: This can be used to check if route should be visible for any specific workspace. Accepted Values : 'all' || 'any workspace accountslug'
    hiddenInWorkspace: If needs to hide route from the workspaces || Add the accountslug in the form of string
    showInLocation: If needs to hide the route in any self-fulfilment location || Add the accountslug in the form of string
    showInMobile: If route should be accessable in mobile device
    hasAccess: This key value will be updated on the load of workspace with the RBAC scope of the user.
    urlType: Identify if the route is of workspace or flex. Accepted Values : 'WORKSPACE' || 'FLEX'
    parent: This is used to put the routes in the group as per the header structure, Dropdown
    sortKey: For order of the routes in dropdown
   <<<<<<<<<<<<<<<<< */

  /* >>>>>>>>>>>>>>>>>>
    IF ANY ROUTE DOESN'T BELONGS TO ANY SCOPE KEEP THE (scope: '' and hasAccess: false) TO EXCLUDE FROM RBAC
  <<<<<<<<<<<<<<<<< */

  inventory: [
    // All products starts >>
    {
      type: 'view',
      URL: '/product/list/',
      fullPath: '/product/list/',
      scope: 'products',
      queryParams: PRODUCTS_DEFAULT_PARAMS_LIST,
      name: 'All products',
      scopeLabel: 'all products',
      visibileFor: ['client'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'All products',
      sortKey: 1
    },
    // All products ends <<

    // Consignments starts >>
    {
      type: 'view',
      URL: '/consignment/list',
      fullPath: '/consignment/list',
      scope: 'inward_consignments',
      queryParams: CONSIGNMENT_DEFAULT_PARAM_LIST,
      name: 'All consignments',
      scopeLabel: 'all consignments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Consignment',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: '/consignment/create-consignment',
      fullPath: '/consignment/create-consignment',
      scope: 'inward_consignments',
      queryParams: '',
      name: 'Create',
      scopeLabel: 'all consignments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Consignment',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'grn',
      fullPath: '/building-blocks/view-record-list?handleBar=grn',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Receive',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Consignment',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'inwardConsignment',
      fullPath: '/building-blocks/view-record-list?handleBar=inwardConsignment',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Close',
      scopeLabel: 'all consignments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Consignment',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: '/consignment/create-consignment',
      fullPath: '/consignment/create-consignment',
      scope: 'inward_consignments',
      queryParams: '',
      name: 'Send inventory to Eshopbox FC',
      scopeLabel: 'all consignments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Consignment',
      sortKey: 5
    },
    // Consignments ends <<

    // Manage inventory starts >>
    {
      type: 'protected',
      URL: 'inventory',
      fullPath: '/building-blocks/view-record-list?handleBar=inventory',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Inventory snapshot',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'putaways',
      fullPath: '/building-blocks/view-record-list?handleBar=putaways',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Putaway inventory',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'shelfTransfer',
      fullPath: '/building-blocks/view-record-list?handleBar=shelfTransfer',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Shelf transfer',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'foundInventory',
      fullPath: '/building-blocks/view-record-list?handleBar=foundInventory',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Mark found',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: 'searchItemBarcode',
      fullPath: '/building-blocks/view-record-list?handleBar=searchItemBarcode',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Search product',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 5
    },
    {
      type: 'protected',
      URL: 'stockTransferManifest',
      fullPath: '/building-blocks/view-record-list?handleBar=stockTransferManifest',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Stock transfer manifest',
      scopeLabel: 'self fulfilment',
      visibileFor: [],
      hiddenInWorkspace: [],
      showInLocation: [
        'testingggurmukhsku',
        'testinggdelhitestfc',
        'gurgaonfc',
        'gurgaonfc_2',
        'ymum',
        'mumbaifc',
        'mumbaivelocityfc_2',
        'alternateworkflowitemlevel',
        'alternateworkflowskudevice'
      ],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL,
        LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
      ],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 6
    },
    {
      type: 'protected',
      URL: 'unloadConsignment',
      fullPath: '/building-blocks/view-record-list?handleBar=unloadConsignment',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Unload consignment',
      scopeLabel: 'self fulfilment',
      visibileFor: [],
      hiddenInWorkspace: [],
      showInLocation: [
        'testingggurmukhskunew',
        'testinggdelhitestfcnew',
        'fggn',
        'esb013fc',
        'xmum',
        'yggn',
        'alternateworkflowitemlevelnew',
        'alternateworkflowskudevicenew'
      ],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL,
        LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
      ],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Manage inventory',
      sortKey: 7
    },
    // Manage inventory ends <<

    // Remove inactive inventory starts >>
    {
      type: 'view',
      URL: '/consignment/recall/list',
      fullPath: '/consignment/recall/list',
      scope: 'removals',
      queryParams: DEFAULT_REMOVALS_LIST_PARAMETERS,
      name: 'All removals',
      scopeLabel: 'all removals',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Remove inactive inventory',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'recallBoxes',
      fullPath: '/building-blocks/view-record-list?handleBar=recallBoxes',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Pack',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Remove inactive inventory',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'recallConsignment',
      fullPath: '/building-blocks/view-record-list?handleBar=recallConsignment',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Ship ',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Remove inactive inventory',
      sortKey: 3
    },
    // Remove inactive inventory ends <<

    // Product Verification starts >>
    {
      type: 'view',
      URL: '/product/product-verification',
      fullPath: '/product/product-verification',
      scope: 'products',
      queryParams: '',
      name: 'Product Verification',
      scopeLabel: 'product Verification',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Product Verification',
      sortKey: 1
    }
    // Product Verification ends <<
  ],
  orders: [
    // All orders starts >>
    {
      type: 'view',
      URL: '/order-V2/list',
      fullPath: '/order-V2/list',
      scope: 'orders',
      queryParams: NEW_ORDERS_V2_PARAM,
      name: 'All orders',
      scopeLabel: 'all orders',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'All orders',
      sortKey: 1
    },
    // All orders ends <<

    // All B2B orders starts >>
    {
      type: 'view',
      URL: '/consignment/recall/list',
      fullPath: '/consignment/recall/list',
      scope: 'bulk_orders',
      queryParams: DEFAULT_RECALL_LIST_PARAMETERS,
      name: 'All B2B orders',
      scopeLabel: 'all B2B orders',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'All B2B orders',
      sortKey: 2
    },
    // All B2B orders ends <<

    // Process orders starts >>
    {
      type: 'protected',
      URL: 'shipment',
      fullPath: '/building-blocks/view-record-list?handleBar=shipment',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Create picklist',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Process order',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'picklist',
      fullPath: '/building-blocks/view-record-list?handleBar=picklist',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Pick and pack',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process order',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'manifests',
      fullPath: '/building-blocks/view-record-list?handleBar=manifests',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Ship',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process order',
      sortKey: 3
    },
    // Process orders ends <<

    // Process returns starts >>
    {
      type: 'protected',
      URL: 'receiveReturn2',
      fullPath: '/building-blocks/view-record-list?handleBar=receiveReturn2',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Receive',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process return',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'receivedReturnShipments2',
      fullPath: '/building-blocks/view-record-list?handleBar=receivedReturnShipments2',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Perform QC',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process return',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'ajioReturnLR',
      fullPath: '/building-blocks/view-record-list?handleBar=ajioReturnLR',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Return gatepass',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process return',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'refusedShipmentsManifest',
      fullPath: '/building-blocks/view-record-list?handleBar=refusedShipmentsManifest',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Refuse',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process return',
      sortKey: 4
    },
    // TCNS store returns >> (ONLY FOR ESHOPBOX FC) || STARTS
    {
      type: 'protected',
      URL: 'unloadStoreReturns',
      fullPath: '/building-blocks/view-record-list?handleBar=unloadStoreReturns',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Unload store returns',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: ['fggn', 'testfc'],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'TCNS store returns',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'receiveStoreReturns',
      fullPath: '/building-blocks/view-record-list?handleBar=receiveStoreReturns',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Receive store returns',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: ['fggn', 'testfc'],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'TCNS store returns',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'refurbishStoreReturns',
      fullPath: '/building-blocks/view-record-list?handleBar=refurbishStoreReturns',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Refurbish store returns',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: ['fggn', 'testfc'],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'TCNS store returns',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'handoverStoreReturns',
      fullPath: '/building-blocks/view-record-list?handleBar=handoverStoreReturns',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Handover store returns',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: ['fggn', 'testfc'],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'TCNS store returns',
      sortKey: 4
    },
    // TCNS store returns >> (ONLY FOR ESHOPBOX FC) || ENDS

    // Process returns ends <<

    // Process B2B order starts >>
    {
      type: 'protected',
      URL: 'recallConsignmentSellable',
      fullPath: '/building-blocks/view-record-list?handleBar=recallConsignmentSellable',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Confirm',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process B2B order',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'shipmentsB2B',
      fullPath: '/building-blocks/view-record-list?handleBar=shipmentsB2B',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Create picklist',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process B2B order',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'picklistB2B',
      fullPath: '/building-blocks/view-record-list?handleBar=picklistB2B',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Pick',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process B2B order',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'recallSellableBox',
      fullPath: '/building-blocks/view-record-list?handleBar=recallSellableBox',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Pack',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process B2B order',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: 'recallManifest',
      fullPath: '/building-blocks/view-record-list?handleBar=recallManifest',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Ship',
      scopeLabel: 'self fulfilment',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: true,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Process B2B order',
      sortKey: 5
    }
    // Process B2B order ends <<
  ],
  payments: [
    // Website starts >>
    {
      type: 'view',
      URL: '/reconciliation/payments/cod/payout',
      fullPath: '/reconciliation/payments/cod/payout',
      scope: 'payments',
      queryParams: ROUTES.PAYMENTS_PAYOUTS,
      name: 'Cash On Delivery',
      accessDeniedroute: 'payments_cod',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'WORKSPACE',
      parent: 'Website',
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      sortKey: 1
    },
    // {
    //   type: 'protected',
    //   URL: '',
    //   fullPath: '',
    //   scope: 'payments',
    //   queryParams: '',
    //   name: 'Prepaid',
    //   scopeLabel: 'payments',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   showInLocation: [],
    //   showInMobile: false,
    //   hasAccess: false,
    //   urlType: 'WORKSPACE',
    //   parent: 'Website',
    //   requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
    //   isVisible: true,
    //   sortKey: 2
    // },
    // Website ends <<

    // Marketplace starts >>
    {
      type: 'view',
      fullPath: '/reconciliation/payments/overview',
      URL: '/reconciliation/payments/overview',
      scope: 'payments',
      queryParams: '',
      name: 'Overview',
      scopeLabel: 'payments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      urlType: 'WORKSPACE',
      parent: 'Marketplace',
      isVisible: true,
      sortKey: 1
    },
    {
      type: 'protected',
      URL: '/reconciliation/payments/order-settlements',
      fullPath: '/reconciliation/payments/order-settlements',
      queryParams: ROUTES.ORDER_SETTLEMENTS_PARAMS,
      scope: 'payments',
      name: 'Order settlements',
      scopeLabel: 'payments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Marketplace',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: '/reconciliation/payments/previous-payments',
      fullPath: '/reconciliation/payments/previous-payments',
      scope: 'payments',
      queryParams: ROUTES.PREVIOUS_PAYMENTS_LIST_DEFAULT_PARAMS,
      name: 'Previous payments',
      scopeLabel: 'payments',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      isVisible: true,
      urlType: 'WORKSPACE',
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      parent: 'Marketplace',
      sortKey: 3
    }
    // {
    //   type: 'protected',
    //   URL: '',
    //   fullPath: '',
    //   scope: 'payments',
    //   queryParams: '',
    //   name: 'Add payments',
    //   scopeLabel: 'payments',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   showInLocation: [],
    //   showInMobile: true,
    //   hasAccess: false,
    //   urlType: 'WORKSPACE',
    //   parent: 'Marketplace',
    //   requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
    //   isVisible: true,
    //   sortKey: 4
    // },
    // {
    //   type: 'protected',
    //   URL: '',
    //   fullPath: '',
    //   scope: 'payments',
    //   queryParams: '',
    //   name: 'Bill to Eshopbox',
    //   scopeLabel: 'payments',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   showInLocation: [],
    //   showInMobile: false,
    //   hasAccess: false,
    //   urlType: 'WORKSPACE',
    //   parent: 'Marketplace',
    //   requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
    //   isVisible: true,
    //   sortKey: 5
    // }
    // Marketplace ends <<

    // Old code - Should be removed once marketplace feature is completed
    // Payments starts >>
    // {
    //   type: 'protected',
    //   URL: '/reconciliation/payments/previous-payments',
    //   fullPath: '/reconciliation/payments/previous-payments',
    //   scope: 'payments',
    //   queryParams: ROUTES.PAYMENTS_LIST_DEFAULT_PARAMS,
    //   name: 'Payments',
    //   scopeLabel: 'payments',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   showInLocation: [],
    //   showInMobile: false,
    //   hasAccess: false,
    //   urlType: 'WORKSPACE',
    //   parent: 'Payments',
    //   sortKey: 1
    // },
    // {
    //   type: 'protected',
    //   URL: '/reconciliation/payments/cod/payout',
    //   fullPath: '/reconciliation/payments/cod/payout',
    //   scope: 'payments',
    //   queryParams: ROUTES.PAYMENTS_PAYOUTS,
    //   name: 'Cash On Delivery',
    //   accessDeniedroute: 'payments_cod',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   hiddenInLocation: [],
    //   showInMobile: false,
    //   hasAccess: false,
    //   urlType: 'WORKSPACE',
    //   group: '',
    //   parent: 'Payments',
    //   sortKey: 2
    // }
    // Payments ends <<
  ],
  finance: [
    {
      type: 'view',
      URL: '/reconciliation/payments/finance',
      fullPath: '/reconciliation/payments/finance',
      scope: 'payments',
      queryParams: ROUTES.PAYMENTS_PAYOUTS,
      name: 'Finance',
      accessDeniedroute: 'payments_cod',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'WORKSPACE',
      parent: 'Finance',
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      sortKey: 1
    }
  ],
  reports: [
    // reports starts >>
    {
      type: 'protected',
      URL: '/dashboard/overview',
      fullPath: '/dashboard/overview',
      scope: 'reports',
      queryParams: '',
      name: 'Home',
      scopeLabel: 'reports',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'dashboard',
      sortKey: 0
    },
    // {
    //   type: 'protected',
    //   URL: '/fdr-dashboard/overview',
    //   fullPath: '/fdr-dashboard/overview',
    //   scope: 'reports',
    //   queryParams: '',
    //   name: 'Failed delivery report',
    //   scopeLabel: 'reports',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   showInLocation: [],
    //   showInMobile: false,
    //   hasAccess: false,
    //   urlType: 'WORKSPACE',
    //   parent: 'Reports',
    //   sortKey: 1
    // },
    {
      type: 'protected',
      URL: '/reports/time-to-deliver',
      fullPath: '/reports/time-to-deliver',
      scope: 'reports',
      queryParams: '',
      name: 'Time to deliver',
      scopeLabel: 'reports',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Reports',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: '/reports/on-time-delivery',
      fullPath: '/reports/on-time-delivery',
      scope: 'reports',
      queryParams: '',
      name: 'On-time delivery',
      scopeLabel: 'reports',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: '/reports/failed-delivery',
      fullPath: '/reports/failed-delivery',
      scope: 'reports',
      queryParams: '',
      name: 'Failed delivery',
      scopeLabel: 'reports',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: '/reports/exception-rate',
      fullPath: '/reports/exception-rate',
      scope: 'reports',
      queryParams: '',
      name: 'Exception rate',
      scopeLabel: 'reports',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 5
    },
    {
      type: 'protected',
      URL: '/reports/on-time-rts',
      fullPath: '/reports/on-time-rts',
      scope: 'reports',
      queryParams: '',
      scopeLabel: 'reports',
      name: 'On time ready to ship',
      accessDeniedroute: 'on_time_ready_to_ship',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: '/reports/regional-utilization',
      fullPath: '/reports/regional-utilization',
      scope: 'reports',
      queryParams: '',
      name: 'Regional utilization',
      accessDeniedroute: 'regional_utilization',
      scopeLabel: 'reports',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 6
    },
    {
      type: 'protected',
      URL: '/reports/on-time-handover',
      fullPath: '/reports/on-time-handover',
      scope: 'reports',
      queryParams: '',
      name: 'On time handover',
      accessDeniedroute: 'on_time_handover',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 8
    },
    {
      type: 'protected',
      URL: '/reports/notification-dashboard',
      fullPath: '/reports/notification-dashboard',
      scope: 'reports',
      queryParams: '',
      name: 'Notifications',
      accessDeniedroute: 'notifications',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 7
    },
    {
      type: 'protected',
      URL: '/reports/on-time-delivery',
      fullPath: '/reports/on-time-delivery',
      scope: 'reports',
      queryParams: '',
      name: 'On time delivery',
      accessDeniedroute: 'on-time-delivery',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      isHidden: false,
      urlType: 'WORKSPACE',
      group: '',
      parent: 'Reports',
      sortKey: 2
    },

    // Fulfilment reports >> (ONLY FOR ESHOPBOX FC) || STARTS
    // Inventory report >> (ONLY FOR ESHOP FC)
    {
      type: 'protected',
      URL: 'traceability',
      fullPath: '/building-blocks/view-record-list?handleBar=traceability',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Workspace traceability',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'batchDetails',
      fullPath: '/building-blocks/view-record-list?handleBar=batchDetails',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Batch details',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'receivedProducts',
      fullPath: '/building-blocks/view-record-list?handleBar=receivedProducts',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'GRN items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'qcProducts',
      fullPath: '/building-blocks/view-record-list?handleBar=qcProducts',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'QC items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: 'overageItem',
      fullPath: '/building-blocks/view-record-list?handleBar=overageItem',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Overage items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 5
    },
    {
      type: 'protected',
      URL: 'overageItem',
      fullPath: '/building-blocks/view-record-list?handleBar=overageItem',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Overage items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 5
    },
    {
      type: 'protected',
      URL: 'transferOut',
      fullPath: '/building-blocks/view-record-list?handleBar=transferOut',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Shelf transfer items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 6
    },
    {
      type: 'protected',
      URL: 'markFoundData',
      fullPath: '/building-blocks/view-record-list?handleBar=markFoundData',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Mark found items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Inventory report',
      sortKey: 7
    },
    // Orders report >> (ONLY FOR ESHOP FC)
    {
      type: 'protected',
      URL: 'pickedItems',
      fullPath: '/building-blocks/view-record-list?handleBar=pickedItems',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Picked items',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Order report',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'pickedItemsB2B',
      fullPath: '/building-blocks/view-record-list?handleBar=pickedItemsB2B',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Picked items (Bulk orders)',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Order report',
      sortKey: 2
    },
    // Returns report >> (ONLY FOR ESHOP FC)
    {
      type: 'protected',
      URL: 'qcIdentifiedItems',
      fullPath: '/building-blocks/view-record-list?handleBar=qcIdentifiedItems',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Return items matched with order',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Return report',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'qcUnidentifiedItemsMatchedWithProducts',
      fullPath: '/building-blocks/view-record-list?handleBar=qcUnidentifiedItemsMatchedWithProducts',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Return incorrect items same brand',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Return report',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'qcUnknownItems',
      fullPath: '/building-blocks/view-record-list?handleBar=qcUnknownItems',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Return incorrect items other brand',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Return report',
      sortKey: 3
    },
    {
      type: 'protected',
      URL: 'receivedStoreReturnItems',
      fullPath: '/building-blocks/view-record-list?handleBar=receivedStoreReturnItems',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Return store returns items (TCNS)',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: ['fggn', 'testfc'],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Return report',
      sortKey: 4
    },
    {
      type: 'protected',
      URL: 'refurbishStoreReturnsItems',
      fullPath: '/building-blocks/view-record-list?handleBar=refurbishStoreReturnsItems',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Refurbished store returns items (TCNS)',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: ['fggn', 'testfc'],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Return report',
      sortKey: 5
    },
    {
      type: 'protected',
      URL: 'refusedShipments',
      fullPath: '/building-blocks/view-record-list?handleBar=refusedShipments',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Refused shipments',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Return report',
      sortKey: 6
    },
    // Stock transfer report >> (ONLY FOR ESHOP FC)
    {
      type: 'protected',
      URL: 'inventoryAdjustment',
      fullPath: '/building-blocks/view-record-list?handleBar=inventoryAdjustment',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Inventory Report',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [
        'testingggurmukhsku',
        'testinggdelhitestfc',
        'gurgaonfc',
        'gurgaonfc_2',
        'ymum',
        'mumbaifc',
        'mumbaivelocityfc_2',
        'alternateworkflowitemlevel',
        'alternateworkflowskudevice',
        'testingggurmukhskunew',
        'testinggdelhitestfcnew',
        'fggn',
        'esb013fc',
        'xmum',
        'yggn',
        'alternateworkflowitemlevelnew',
        'alternateworkflowskudevicenew'
      ],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Stock transfer',
      sortKey: 1
    },
    {
      type: 'protected',
      URL: 'stockTransferBoxes',
      fullPath: '/building-blocks/view-record-list?handleBar=stockTransferBoxes',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Stock transfer boxes',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [
        'testingggurmukhsku',
        'testinggdelhitestfc',
        'gurgaonfc',
        'gurgaonfc_2',
        'ymum',
        'mumbaifc',
        'mumbaivelocityfc_2',
        'alternateworkflowitemlevel',
        'alternateworkflowskudevice'
      ],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Stock transfer',
      sortKey: 2
    },
    {
      type: 'protected',
      URL: 'boxDetails',
      fullPath: '/building-blocks/view-record-list?handleBar=boxDetails',
      scope: 'self_fulfilment',
      queryParams: '',
      name: 'Unload consignment',
      scopeLabel: 'self fulfilment',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [
        'testingggurmukhskunew',
        'testinggdelhitestfcnew',
        'fggn',
        'esb013fc',
        'xmum',
        'yggn',
        'alternateworkflowitemlevelnew',
        'alternateworkflowskudevicenew'
      ],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Stock transfer',
      sortKey: 3
    }
    // Fulfilment reports >> (ONLY FOR ESHOPBOX FC) || ENDS
  ],
  // reports ends <<
  // claims starts >>
  claims: [
    {
      type: 'view',
      URL: '/settings/weight-discrepancy',
      fullPath: '/settings/weight-discrepancy',
      scope: 'weight_discrepancy',
      queryParams: '',
      name: 'Weight discrepancy',
      scopeLabel: 'Weight discrepancy',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      isVisible: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL,
        LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
      ],
      urlType: 'WORKSPACE',
      parent: 'claims',
      subParent: 'SHIPPING',
      sortKey: 2,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/weightdiscrepancy.svg'
    },
    {
      type: 'view',
      URL: '/claims/listing',
      fullPath: '/claims/listing',
      scope: '',
      queryParams: claim_param,
      name: 'Claims',
      scopeLabel: 'Claims',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      isVisible: true,
      requiredLocationSetting: [],
      urlType: 'WORKSPACE',
      parent: 'claims',
      subParent: 'SHIPPING',
      sortKey: 1,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/weightdiscrepancy.svg'
    }
  ],
  // claims ends <<

  // Apps portal starts >>
  apps: [
    {
      type: 'view',
      URL: '/installed-app-list/view',
      fullPath: '/installed-app-list/view',
      scope: 'apps',
      queryParams: ALL_APPS_FILTER,
      name: 'Apps',
      scopeLabel: 'apps',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'WORKSPACE',
      parent: 'Apps',
      sortKey: 1
    }
  ],
  // Apps portal ends <<

  //Activity-log
  activity: [
    {
      type: 'view',
      URL: '/settings/activities-log',
      fullPath: '/settings/activities-log',
      scope: '',
      queryParams: '',
      name: 'Activities',
      scopeLabel: '',
      visibileFor: ['all'],
      hiddenInWorkspace: [''],
      showInLocation: [],
      showInMobile: false,
      hasAccess: true,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      // parent: 'Settings',
      sortKey: 1,
      icon:
        'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/weight_disc/activity.svg?updatedAt=1711638880469'
    }
  ],
  // Setting starts >>
  setting: [
    {
      type: 'view',
      URL: '/settings/account',
      fullPath: '/settings/account',
      scope: 'account',
      queryParams: '',
      name: 'Account',
      scopeLabel: 'account and brand',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'GENERAL',
      sortKey: 1,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/account.svg?updatedAt=1704279005806'
    },
    {
      type: 'view',
      URL: '/settings/brand',
      fullPath: '/settings/brand',
      scope: 'account',
      queryParams: '',
      name: 'Brand',
      scopeLabel: 'account and brand',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'GENERAL',
      sortKey: 2,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/brand.svg?updatedAt=1704279137747'
    },
    {
      type: 'view',
      URL: '/settings/business-details',
      fullPath: '/settings/business-details',
      scope: 'payment_settings',
      queryParams: '',
      name: 'Business details (KYC)',
      scopeLabel: 'payment settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'GENERAL',
      sortKey: 3,
      icon:
        'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/business-details.svg?updatedAt=1704279325862'
    },
    {
      type: 'view',
      URL: '/settings/property/list',
      fullPath: '/settings/property/list',
      scope: 'custom_fields',
      queryParams: '',
      name: 'Custom fields',
      scopeLabel: 'custom fields',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'GENERAL',
      sortKey: 4,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/custom-fields.svg?updatedAt=1704279414733'
    },

    {
      type: 'view',
      URL: '/settings/team',
      fullPath: '/settings/team',
      scope: 'teams',
      queryParams: '',
      name: 'Team',
      scopeLabel: 'team',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'USER & LOCATIONS',
      sortKey: 5,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/team.svg?updatedAt=1704279478200'
    },
    {
      type: 'view',
      URL: '/fc/home',
      fullPath: '/fc/home',
      scope: 'locations',
      queryParams: '',
      name: 'Locations',
      scopeLabel: 'locations',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'USER & LOCATIONS',
      sortKey: 6,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/location.svg?updatedAt=1704279588391'
    },
    //activities-log
    // {
    //   type: 'protected',
    //   URL: '/settings/activities-log',
    //   fullPath: '/settings/activities-log',
    //   scope: '',
    //   queryParams: '',
    //   name: 'Activities',
    //   scopeLabel: '',
    //   visibileFor: ['all'],
    //   hiddenInWorkspace: [],
    //   showInLocation: [],
    //   showInMobile: false,
    //   hasAccess: true,
    //   urlType: 'WORKSPACE',
    //   parent: 'Settings',
    //   sortKey: 1
    // },
    // for self fulfillment settings page
    {
      type: 'protected',
      URL: '/building-blocks/self-fulfilment-settings',
      fullPath: '/building-blocks/self-fulfilment-settings',
      scope: 'flex',
      queryParams: '',
      name: 'Self fulfilment settings',
      scopeLabel: 'self fulfilment settings',
      visibileFor: ['all'],
      hiddenInWorkspace: ['eshop'],
      showInLocation: [],
      showInMobile: false,
      hasAccess: true,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL,
        LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
      ],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Settings',
      subParent: 'USER & LOCATIONS',
      sortKey: 7,
      icon:
        'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/self-fulfilment.svg?updatedAt=1704279655676'
    },
    // Assets
    {
      type: 'protected',
      URL: 'shelves',
      fullPath: '/building-blocks/view-record-list?handleBar=shelves',
      scope: 'assets',
      queryParams: '',
      name: 'Shelfs',
      scopeLabel: 'assets',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.SKU_LEVEL, LOCATIONSETTINGLIST.ITEM_LEVEL],
      isVisible: false,
      urlType: 'FLEX',
      parent: 'Assets',
      subParent: 'USER & LOCATIONS',
      sortKey: 8,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/assets.svg?updatedAt=1704279727509'
    },
    {
      type: 'protected',
      URL: 'toteForConsignment',
      fullPath: '/building-blocks/view-record-list?handleBar=toteForConsignment',
      scope: 'assets',
      queryParams: '',
      name: 'Totes',
      scopeLabel: 'assets',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Assets',
      subParent: 'USER & LOCATIONS',
      sortKey: 9,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/assets.svg?updatedAt=1704279727509'
    },
    {
      type: 'protected',
      URL: 'pigeonHole',
      fullPath: '/building-blocks/view-record-list?handleBar=pigeonHole',
      scope: 'assets',
      queryParams: '',
      name: 'Pigeon hole',
      scopeLabel: 'assets',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Assets',
      subParent: 'USER & LOCATIONS',
      sortKey: 10,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/assets.svg?updatedAt=1704279727509'
    },
    {
      type: 'protected',
      URL: 'stagingArea',
      fullPath: '/building-blocks/view-record-list?handleBar=stagingArea',
      scope: 'assets',
      queryParams: '',
      name: 'Staging area',
      scopeLabel: 'assets',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      urlType: 'FLEX',
      parent: 'Assets',
      subParent: 'USER & LOCATIONS',
      sortKey: 11,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/assets.svg?updatedAt=1704279727509'
    },
    {
      type: 'view',
      URL: '/customer-portal',
      fullPath: '/customer-portal',
      scope: 'customer_portal',
      queryParams: '',
      name: 'Customer portal',
      scopeLabel: 'customer portal',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 12,
      icon:
        'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/customer-portals.svg?updatedAt=1704279778807'
    },
    {
      type: 'view',
      URL: '/settings/shipping-mode',
      fullPath: '/settings/shipping-mode',
      scope: 'shipping_mode',
      queryParams: '',
      name: 'Shipping mode',
      scopeLabel: 'shipping mode',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 13,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/shipping-mode.svg?updatedAt=1704279832657'
    },
    {
      type: 'view',
      URL: '/settings/risk-score',
      fullPath: '/settings/risk-score',
      scope: 'risk_score',
      queryParams: '',
      name: 'Risk score',
      scopeLabel: 'risk score',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 14,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/risk-scores.svg?updatedAt=1704279884048'
    },
    {
      type: 'view',
      URL: '/settings/packaging-material-list',
      fullPath: '/settings/packaging-material-list',
      scope: 'packaging_settings',
      queryParams: '',
      name: 'Packaging material',
      scopeLabel: 'packaging_settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [''],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 14,
      icon: 'https://ik.imagekit.io/2gwij97w0o/Packaging/Packaging.svg?updatedAt=1710486244199'
    },
    {
      type: 'view',
      URL: '/settings/packaging-list-admin',
      fullPath: '/settings/packaging-list-admin',
      scope: '',
      queryParams: '',
      name: 'Packaging verification',
      scopeLabel: '',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 15,
      icon: 'https://ik.imagekit.io/2gwij97w0o/Packaging/package%20verification%201.svg?updatedAt=1710487015291'
    },
    {
      type: 'view',
      URL: '/settings/risk-score',
      fullPath: '/settings/risk-score',
      scope: 'risk_score',
      queryParams: '',
      name: 'Risk score',
      scopeLabel: 'risk score',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 14,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/risk-scores.svg?updatedAt=1704279884048'
    },
    {
      type: 'view',
      URL: '/tool/pincode',
      fullPath: '/tool/pincode',
      scope: '',
      queryParams: '',
      name: 'Pincode serviceability',
      scopeLabel: '',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: true,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 15,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/pincode-icon.svg?updatedAt=1704279937540'
    },
    // order routing
    {
      type: 'view',
      URL: '/settings/order-routing',
      fullPath: '/settings/order-routing',
      scope: '',
      queryParams: '',
      name: 'Order routing',
      scopeLabel: '',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 26,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/Order_routing.svg'
    },
    // Incident management
    {
      type: 'view',
      URL: ROUTES.INCIDENT_MANEGE,
      fullPath: ROUTES.INCIDENT_MANEGE,
      scope: 'incident_management',
      queryParams: '',
      name: 'Incident management',
      scopeLabel: '',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      isVisible: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL,
        LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
      ],
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 16,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/Incident_management.svg'
    },
    {
      type: 'view',
      URL: '/settings/weight-waiver',
      fullPath: '/settings/weight-waiver',
      scope: '',
      queryParams: '',
      name: 'Weight waiver',
      scopeLabel: '',
      visibileFor: ['eshop'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      isVisible: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 21,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/shipping-mode.svg?updatedAt=1704279832657'
    },
    {
      type: 'view',
      URL: '/tool/ratecalculator',
      fullPath: '/tool/ratecalculator',
      scope: '',
      queryParams: '',
      name: 'Rate Calculator',
      scopeLabel: '',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: true,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'SHIPPING',
      sortKey: 28,
      icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/rate_calculator.svg'
    },
    {
      type: 'view',
      URL: '/customer-portal',
      fullPath: '/customer-portal',
      scope: 'customer_portal',
      queryParams: CUSTOMER_NOTIFICATION_PARAM,
      name: 'Customer notifications',
      scopeLabel: 'customer portal',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: true,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'NOTIFICATIONS',
      sortKey: 17,
      icon:
        'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/customer-notification.svg?updatedAt=1704279997893'
    },
    // Workspace Notification
    {
      type: 'view',
      URL: '/notification-setting',
      fullPath: '/notification-setting',
      scope: '',
      queryParams: '',
      name: 'Team notifications',
      scopeLabel: '',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: true,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'NOTIFICATIONS',
      sortKey: 18,
      icon:
        'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/customer-notification.svg?updatedAt=1704279997893'
    },
    {
      type: 'view',
      URL: '/settings/batch-tracking',
      fullPath: '/settings/batch-tracking',
      scope: 'inventory_settings',
      queryParams: '',
      name: 'Batch tracking',
      scopeLabel: 'inventory settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'INVENTORY',
      sortKey: 19,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/batch-tracking.svg?updatedAt=1704280109438'
    },
    {
      type: 'view',
      URL: '/settings/serial-number',
      fullPath: '/settings/serial-number',
      scope: 'inventory_settings',
      queryParams: '',
      name: 'Serial number scan',
      scopeLabel: 'inventory settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [
        LOCATIONSETTINGLIST.ESHOPBOX_FC_ENABLED,
        LOCATIONSETTINGLIST.SKU_LEVEL,
        LOCATIONSETTINGLIST.ITEM_LEVEL
      ],
      isVisible: false,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'INVENTORY',
      sortKey: 20,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/serial-scan.svg?updatedAt=1704280173029'
    },
    // Tax setting
    {
      type: 'protected',
      URL: '/settings/tax-e-invoice',
      fullPath: '/settings/tax-e-invoice',
      scope: 'e_invoicing',
      queryParams: '',
      name: 'E-invoicing',
      scopeLabel: 'e-invoicing',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Tax settings',
      subParent: 'FINANCE',
      sortKey: 21,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/e-invoicing.svg?updatedAt=1704280230025'
    },
    {
      type: 'view',
      URL: '/settings/add-bank-details',
      fullPath: '/settings/add-bank-details',
      scope: 'payment_settings',
      queryParams: '',
      name: 'Bank account details',
      scopeLabel: 'payment settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'FINANCE',
      sortKey: 7,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/bank-account.svg?updatedAt=1704280296759'
    },
    {
      type: 'view',
      URL: '/settings/fees',
      fullPath: '/settings/fees',
      scope: 'payment_settings',
      queryParams: FEES_PARAM,
      name: 'Fees',
      scopeLabel: 'payment settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      sortKey: 23,
      subParent: 'FINANCE',
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/fees.svg?updatedAt=1704280337842'
    },
    {
      type: 'view',
      URL: '/settings/payment-terms',
      fullPath: '/settings/payment-terms',
      scope: 'payment_settings',
      queryParams: '',
      name: 'Payment terms',
      scopeLabel: 'payment settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'FINANCE',
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/payment-terms.svg?updatedAt=1704280384804',
      sortKey: 4
    },
    {
      type: 'view',
      URL: '/settings/cash-on-delivery-payment-terms',
      fullPath: '/settings/cash-on-delivery-payment-terms',
      scope: 'payment_settings',
      queryParams: '',
      name: 'COD payment terms',
      scopeLabel: 'payment settings',
      visibileFor: ['all'],
      hiddenInWorkspace: [],
      showInLocation: [],
      showInMobile: false,
      hasAccess: false,
      requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
      isVisible: true,
      urlType: 'WORKSPACE',
      parent: 'Settings',
      subParent: 'FINANCE',
      sortKey: 22,
      icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/payment-terms.svg?updatedAt=1704280384804'
    }
  ]
};

// SUB NAVIGATION ITEMS FOR FLEX NAVIGATION ITEM

// Manage inventory
export const MANAGE_INVENTORY = {
  type: 'protected',
  URL: 'inventory',
  fullPath: '/building-blocks/view-record-list?handleBar=inventory',
  scope: 'self_fulfilment',
  queryParams: '',
  name: 'Manage inventory',
  scopeLabel: 'self fulfilment',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: false,
  hasAccess: false,
  requiredLocationSetting: [LOCATIONSETTINGLIST.ITEM_LEVEL, LOCATIONSETTINGLIST.SKU_LEVEL],
  isVisible: false,
  urlType: 'FLEX',
  parent: 'Manage inventory',
  sortKey: 1
};

export const PRODUCT_VERICATION_FLEX = {
  type: 'view',
  URL: '/product/product-verification',
  fullPath: '/product/product-verification',
  scope: '',
  queryParams: '',
  name: 'Product Verification',
  scopeLabel: '',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: false,
  hasAccess: false,
  requiredLocationSetting: [LOCATIONSETTINGLIST.WORKSPACE_CREATION],
  isVisible: true,
  urlType: 'WORKSPACE',
  parent: 'Product Verification',
  sortKey: 1
};

// Process orders
export const CREATE_PICKLIST = {
  type: 'protected',
  URL: 'shipment',
  fullPath: '/building-blocks/view-record-list?handleBar=shipment',
  scope: 'self_fulfilment',
  queryParams: '',
  name: 'Process order',
  scopeLabel: 'self fulfilment',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: false,
  hasAccess: false,
  requiredLocationSetting: [
    LOCATIONSETTINGLIST.ITEM_LEVEL,
    LOCATIONSETTINGLIST.SKU_LEVEL,
    LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
  ],
  isVisible: false,
  urlType: 'FLEX',
  parent: 'Process order',
  sortKey: 1
};

// Process return
export const PROCESS_RETURN = {
  type: 'protected',
  URL: 'receiveReturn2',
  fullPath: '/building-blocks/view-record-list?handleBar=receiveReturn2',
  scope: 'self_fulfilment',
  queryParams: '',
  name: 'Process return',
  scopeLabel: 'self fulfilment',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: true,
  hasAccess: false,
  requiredLocationSetting: [
    LOCATIONSETTINGLIST.ITEM_LEVEL,
    LOCATIONSETTINGLIST.SKU_LEVEL,
    LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
  ],
  isVisible: false,
  urlType: 'FLEX',
  parent: 'Process return',
  sortKey: 1
};

// Process no scan return
export const PROCESS_NO_SCAN_RETURN = {
  type: 'protected',
  URL: '/process-no-scan-return/receive-return-list',
  fullPath: '/process-no-scan-return/receive-return-list',
  scope: 'self_fulfilment',
  queryParams: '',
  name: 'Process return',
  scopeLabel: 'self fulfilment',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: true,
  hasAccess: false,
  requiredLocationSetting: [
    LOCATIONSETTINGLIST.ITEM_LEVEL,
    LOCATIONSETTINGLIST.SKU_LEVEL,
    LOCATIONSETTINGLIST.DO_NOT_MANAGE_INVENTORY
  ],
  isVisible: false,
  urlType: 'FLEX',
  parent: 'Process return',
  sortKey: 1
};

// Process B2B order
export const PROCESS_B2B_ORDERS = {
  type: 'protected',
  URL: 'recallConsignmentSellable',
  fullPath: '/building-blocks/view-record-list?handleBar=recallConsignmentSellable',
  scope: 'self_fulfilment',
  queryParams: '',
  name: 'Process B2B order',
  scopeLabel: 'self fulfilment',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: true,
  hasAccess: false,
  requiredLocationSetting: [LOCATIONSETTINGLIST.ITEM_LEVEL, LOCATIONSETTINGLIST.SKU_LEVEL],
  isVisible: false,
  urlType: 'FLEX',
  parent: 'Process B2B order',
  sortKey: 1
};

// Assets
export const ASSETS = {
  type: 'protected',
  URL: 'shelves',
  fullPath: '/building-blocks/view-record-list?handleBar=shelves',
  scope: 'assets',
  queryParams: '',
  name: 'Assets',
  scopeLabel: 'assets',
  visibileFor: ['all'],
  hiddenInWorkspace: [],
  showInLocation: [],
  showInMobile: false,
  hasAccess: false,
  requiredLocationSetting: [LOCATIONSETTINGLIST.ITEM_LEVEL, LOCATIONSETTINGLIST.SKU_LEVEL],
  isVisible: false,
  urlType: 'FLEX',
  parent: 'Assets',
  sortKey: 1,
  subParent: 'USER & LOCATIONS',
  icon: 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/assets.svg?updatedAt=1704279727509'
};

// SUB NAVIGATION ITEMS FOR FLEX NAVIGATION ITEM

// SHORTCUTS ADDED ON FLEX BUILDING BLOCKS
export const FLEXSHORTCUTS = [
  {
    handleBar: 'itemsForPutaway',
    shortCutName: 'Putaway items',
    showShortCutOn: 'putaways',
    pickingMethod: 'all',
    appearonRight: true
  },
  {
    handleBar: 'orderItems',
    shortCutName: 'Order items',
    showShortCutOn: 'shipment',
    pickingMethod: 'deviceBased',
    appearonRight: true
  },
  {
    handleBar: 'orderItemsB2B',
    shortCutName: 'Order items B2B',
    showShortCutOn: 'shipmentsB2B',
    pickingMethod: 'all',
    appearonRight: true
  },
  {
    handleBar: 'inventoryAdjustment',
    shortCutName: 'Adjustments',
    showShortCutOn: 'inventory',
    pickingMethod: 'all',
    appearonRight: true
  },
  {
    handleBar: 'itemBarcodes',
    shortCutName: 'Item barcodes',
    showShortCutOn: 'grn',
    pickingMethod: 'all',
    appearonRight: false
  },
  {
    handleBar: 'manifests',
    shortCutName: 'Manifest',
    showShortCutOn: 'shipment',
    pickingMethod: 'all',
    appearonRight: false
  }
];

// LEARNMORE LINKS FOR BUILDING BLOCKS
export const LEARNMORELINK = [
  {
    handleBar: 'recallConsignmentSellable',
    text: 'Learn more',
    label: 'Confirm B2B orders',
    link: 'https://help.eshopbox.com/en/articles/8498657-confirming-b2b-orders',
    articleId: '8498657'
  },
  {
    handleBar: 'shipmentsB2B',
    text: 'Learn more',
    label: 'B2B Shipments',
    link: 'https://help.eshopbox.com/en/articles/8332773-picking-b2b-order-items',
    articleId: '8332773'
  },
  {
    handleBar: 'picklistB2B',
    text: 'Learn more',
    label: 'B2B picklists',
    link: 'https://help.eshopbox.com/en/articles/8332773-picking-b2b-order-items',
    articleId: '8332773'
  },
  {
    handleBar: 'recallSellableBox',
    text: 'Learn more',
    label: 'B2B boxes',
    link: 'https://help.eshopbox.com/en/articles/8332774-packing-b2b-orders',
    articleId: '8332774'
  },
  {
    handleBar: 'recallManifest',
    text: 'Learn more',
    label: 'B2B Manifests',
    link: 'https://help.eshopbox.com/en/articles/8056667-manifesting-b2b-orders',
    articleId: '8056667'
  },
  {
    handleBar: 'shelves',
    text: 'Learn more',
    label: 'Shelfs',
    link: 'https://help.eshopbox.com/en/articles/8493796-setting-up-assets-at-your-fulfilment-location',
    articleId: '8493796'
  },
  {
    handleBar: 'toteForConsignment',
    text: 'Learn more',
    label: 'Totes',
    link: 'https://help.eshopbox.com/en/articles/8493796-setting-up-assets-at-your-fulfilment-location',
    articleId: '8493796'
  },
  {
    handleBar: 'pigeonHole',
    text: 'Learn more',
    label: 'Pigeon Hole',
    link: 'https://help.eshopbox.com/en/articles/8493796-setting-up-assets-at-your-fulfilment-location',
    articleId: '8493796'
  },
  {
    handleBar: 'stagingArea',
    text: 'Learn more',
    label: 'Staging Area',
    link: 'https://help.eshopbox.com/en/articles/8493796-setting-up-assets-at-your-fulfilment-location',
    articleId: '8493796'
  },
  {
    handleBar: 'inwardConsignment',
    text: 'Learn more',
    label: 'Close consignment',
    link: 'https://help.eshopbox.com/en/articles/8312338-receiving-items-of-a-consignment',
    articleId: '8312338'
  },
  {
    handleBar: 'grn',
    text: 'Learn more',
    label: 'Goods receipt notes (GRN)',
    link: 'https://help.eshopbox.com/en/articles/8312338-receiving-items-of-a-consignment',
    articleId: '8312338'
  },
  {
    handleBar: 'picklist',
    text: 'Learn more',
    label: 'Picklists',
    link: 'https://help.eshopbox.com/en/articles/8056652-packing-orders',
    articleId: '8056652'
  },
  {
    handleBar: 'manifests',
    text: 'Learn more',
    label: 'Manifests',
    link: 'https://help.eshopbox.com/en/articles/8312395-manifesting-orders',
    articleId: '8312395'
  }
];
