import { Component, Input, EventEmitter, Output, OnChanges, OnInit } from '@angular/core';
import { ConvertToCsvService } from '../../services/convert-to-csv.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-download-template',
  templateUrl: './download-template.component.html',
  styleUrls: ['./download-template.component.scss']
})
export class DownloadTemplateComponent implements OnInit {
  @Input() downloadFor: string;
  @Input() templateLink: any;
  @Input() headers: any;
  @Input() body: any;
  @Input() templateLinkLoader: boolean = true;
  @Input() fileName: any = 'file';
  @Output() customFieldPop = new EventEmitter();
  @Input() href: any = '';
  @Input() accountBatchingTemplate;
  @Input() selectedImportTypeData;
  @Input() packaging;
  FailedDeliveryForm: FormGroup;
  @Output() failedDeliveryactiontype = new EventEmitter();
  templateLinkNew: string;
  faileddeliverycheck: boolean;
  routeData;
  baseproduct: boolean;
  virtualkitproduct: boolean;
  // packaging:boolean;
  kitproduct: boolean;
  merge_product: any;
  constructor(private csrv: ConvertToCsvService, private fb: FormBuilder, private actRoute: ActivatedRoute) {}
  ngOnInit() {
    console.log('templateLink', this.templateLink, this.fileName);
    this.FailedDeliveryForm = this.fb.group({
      failedDeliveryaction: ['']
    });

    this.actRoute.queryParams.subscribe(res => {
      this.routeData = res;
      console.log('res', this.routeData);
      if (this.routeData['type'] == 'BASE') {
        this.baseproduct = true;
      } else if (this.routeData['type'] == 'VIRTUAL_COMBO') {
        this.virtualkitproduct = true;
      } else if (this.routeData['type'] == 'KIT') {
        this.kitproduct = true;
      } else if (this.routeData['type'] == 'MERGE_PRODUCT') {
        this.merge_product = true;
      }
    });

    if (this.selectedImportTypeData && this.selectedImportTypeData.importType == 'faileddelivery') {
      this.faileddeliverycheck = true;
    } else {
      this.faileddeliverycheck = false;
    }
  }
  showPopUp() {
    this.customFieldPop.emit(true);
  }

  deliveryevent(e) {
    if (e.isUserInput) {
      this.failedDeliveryactiontype.emit(e.source.value);
      if (e.source.value == 'rto') {
        this.templateLinkNew =
          'https://storage.googleapis.com/eshopbox-client-portal-prod.appspot.com/template/Initiate_rto_template.csv';
      } else if (e.source.value == 'oda_update') {
        this.templateLinkNew =
          'https://storage.googleapis.com/eshopbox-client-portal-prod.appspot.com/template/Update_pickup_details.csv';
      } else {
        this.templateLinkNew =
          'https://storage.googleapis.com/eshopbox-client-portal-prod.appspot.com/template/Reattempt_delivery.csv';
      }
    }
  }
  download() {
    this.csrv.downloadFile(this.templateLink, this.fileName);
    // console.log("checkurl",this.templateLink,this.fileName)
  }

  isArray(): boolean {
    let flag: boolean = true;
    if (this.templateLink == undefined) {
      flag = true;
    } else {
      flag = Array.isArray(this.templateLink);
    }
    return flag;
  }
}
