import { createSelector } from '@ngrx/store';
import * as fromReducer from './order-routing.reducer';
import { AppState } from 'src/app/app.state';

export const getorderroutingState = (state: AppState) => state.orderRoutingState;
export const getRuleListALL = createSelector(getorderroutingState, fromReducer.getRuleList);
export const getLoader = createSelector(getorderroutingState, fromReducer.getLoaderstart);
export const getError = createSelector(getorderroutingState, fromReducer.getError);
export const getstate = createSelector(getorderroutingState, fromReducer.getstate);
export const getItemById = id =>
  createSelector(getRuleListALL, allItems => {
    if (allItems) {
      return allItems.find(item => {
        return item.id === id;
      });
    } else {
      return {};
    }
  });
