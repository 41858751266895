import { Action } from '@ngrx/store';
import { Verticals, Country } from '../registration.state';

export enum RegistrationActionTypes {
    GET_VERTICALS = '[Brand Registration Page] Get Vertical Details',
    GET_VERTICALS_SUCCESS = '[Fetch vertical success] Get Vertical Details Success',
    GET_VERTICALS_FAILURE = '[Fetch vertical failure] Get Vertical Details Failure',
    GET_COUNTRIES = '[Registration Page] Get Country List',
    GET_COUNTRIES_SUCCESS = '[Fetch countries Success] Get Country List Success',
    GET_COUNTRIES_FAILURE = '[Fetch countries failure] Get Country List Failure'
}

export class GetVerticalsAction implements Action {
    readonly type = RegistrationActionTypes.GET_VERTICALS;
}

export class GetVerticalsSuccessAction implements Action {
    readonly type = RegistrationActionTypes.GET_VERTICALS_SUCCESS;

    constructor(public payload: Verticals[]) {
    }
}

export class GetVerticalsFailureAction implements Action {
    readonly type = RegistrationActionTypes.GET_VERTICALS_FAILURE;
}

export class GetCountriesAction implements Action {
    readonly type = RegistrationActionTypes.GET_COUNTRIES;
}

export class GetCountriesSuccessAction implements Action {
    readonly type = RegistrationActionTypes.GET_COUNTRIES_SUCCESS;

    constructor(public payload: Country[]) {
    }
}

export class GetCountriesFailureAction implements Action {
    readonly type = RegistrationActionTypes.GET_COUNTRIES_FAILURE;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type RegistrationUnion =
    | GetVerticalsAction
    | GetVerticalsSuccessAction
    | GetVerticalsFailureAction
    | GetCountriesAction
    | GetCountriesSuccessAction
    | GetCountriesFailureAction;
