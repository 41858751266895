import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help-tooltip',
  templateUrl: 'help-tooltip.component.html',
  styleUrls: ['./help-tooltip.component.scss']
})
export class HelpTooltipComponent {
  @Input() description: string;
  @Input() topic1: string;
  @Input() topic1link: string;
  @Input() topic2: string;
  @Input() topic2link: string;
  @Input() id: any;
  openIntercom(e) {
    e.preventDefault();
    (window as any).Intercom('showArticle', this.id);
  }
}
