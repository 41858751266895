import { Pipe, PipeTransform } from '@angular/core';
import { statusColors } from '../../records/model/chipBgColorsModel';
@Pipe({
  name: 'statusColor'
})
export class statusColorPipe implements PipeTransform {
  transform(status: any): any {
    let color;
    if (typeof status === 'string') {
      color = statusColors.find(m => m.status == status);
    } else if (typeof status === 'object') {
      color = statusColors.find(m => m.status == status.value);
    }
    return color ? color.chipColor : '#FFFFFF';
  }
}
