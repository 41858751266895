<ng-container *ngIf="importProcessing">
  <div class="import-notification">
    <div class="loader"></div>
    <h2>Your import is in progress...</h2>
    <p>Once completed, you can view import status in Files in side navigation here</p>
    <img
      class="close-btn"
      (click)="closeImport()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>

<ng-container *ngIf="exportProcessing">
  <div class="import-notification for-order">
    <div class="loader"></div>
    <h2>Your export is in the queue...</h2>
    <p>
      Once it is ready for download, we will share the file<br />
      over your email. It usually takes 5 to 10 minutes<br /><br />
      The report will be available in
      <img class="file-icon" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/files.svg" />
      <span class="file-text">files</span> in the sidebar<br />
      for 72 hours.
    </p>
    <img
      class="close-btn"
      (click)="closeExport()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>

<ng-container *ngIf="scheduleProcessing">
  <div class="import-notification for-order">
    <div class="loader"></div>
    <h2>Your scheduled report is in the queue...</h2>
    <p>
      Once the schedule report is ready, we will send it to you<br />
      and your team on set interval over email. Your report will<br />
      also be available in
      <img class="file-icon" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/files.svg" />
      <span class="file-text">files</span> for 72 hrs.<br /><br />
      You can further manage schedule in files in side navigation
    </p>
    <img
      class="close-btn"
      (click)="closeSchedule()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>

<ng-container *ngIf="exportCompleted">
  <div class="export-succ">
    <img
      class="check-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/check-circle-green.svg"
    />
    Export is successful! We're sending the CSV to your email address.
    <img
      class="cross-ic"
      (click)="closeExportCompleted()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>
