export class FilterModel {
  filterKey: any;
  filterCondition: any;
  filterValue: any;
  from: any;
  to: any;
  fromDate: any;
  toDate: any;
  fromTime: any;
  toTime: any;
  time: any;
  allSelected?: any;
}

export const SkipPicking = {
  primaryFilters: [
    'salesChannelName',
    'displayStatus',
    'paymentMethod',
    'shippingProvider',
    'Shipmentcreated at',
    'expectedFulfillmentBy',
    'expectedHandOverDate',
    'shipmentDispatchedAt',
    'cancelledAt'
  ],
  filterFields: [
    'labelStatus',
    'manifestStatus',
    'orderItemIds.scannableIdentifier',
    'bulkLabelsId',
    'trackingID',
    'manifestID',
    'riskScore'
  ]
};
