import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { isNullOrUndefined } from 'src/app/records/utils/common-util';
import { ActivatedRoute, Router } from '@angular/router';
import { DraftConfirmationDialogComponent } from 'src/app/records/components/draft-confirmation-dialog/draft-confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { draftStatus } from 'src/app/records/model/draftModel';
import { DataStoreService } from 'src/app/shared/services/data-store.service';
import _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DraftService {
  private accountslug: string;
  public draftId: any;
  public draftDetails: any;
  public isActionOpenInOtherTab: boolean = false;
  public draftActionStatus: string = '';
  public snackbarRef: any;
  public draftLoader: boolean = false;
  public isPageRefreshed: boolean;
  public draftUpdateStatus: any = undefined;
  public isDraftUpdateAndFail: boolean = false;
  public setDraftDetailsSubscription = new Subject();
  public isLoadingShowForSubmitFail: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    public dataStoreService: DataStoreService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.accountslug = this.authService.getAccountSlug();
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  //Initial Request To Check Draft Status if exixt or not
  draftStatus(payload) {
    const url = `buildingblock/api/v1/draft`;
    return this.http.post(url, payload);
  }

  draftStatusAsync(payload) {
    const url = `buildingblock/api/v1/draft`;
    return this.http
      .post(url, payload)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  //get draft on refreshing the page with draft id and session id
  getDraftAsync(draftId, sessionId) {
    const url = `buildingblock/api/v1/draft/` + draftId + '/' + sessionId;
    return this.http
      .get(url)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  //Request To Update Draft
  updateDraft(payload) {
    const url = 'buildingblock/api/v1/draft/' + this.draftId;
    return this.http.put(url, payload);
  }

  //Request To Update Draft Async
  updateDraftAsync(payload) {
    const url = `buildingblock/api/v1/draft/` + this.draftId;
    return this.http
      .put(url, payload)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  //update draft status inProgress Aync
  updateDraftStatusInProgressAsync(payload, draftId) {
    const url = 'buildingblock/api/v1/draft/' + draftId + '/status/IN_PROGRESS';
    return this.http
      .put(url, payload)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  //Request To Discard Draft
  discardDraft(DraftMongoId, draftSessionId) {
    const url = 'buildingblock/api/v1/draft/' + DraftMongoId + '/' + draftSessionId;
    return this.http.delete(url);
  }

  //redirection of the page accordingly draft status getting by record-list- table and return statusOfDraft to make draft in progress status
  draftDetailStatus(
    draftData,
    dialog,
    bbHandlebar,
    snackbar,
    actionHandleBar,
    isFailureMsgShow,
    isAutoMoveToNextAction?
  ): string {
    this.isPageRefreshed = false;
    let isFormRunnerPage: boolean = false;
    let isRecordListPage: boolean = false;
    let currentURL = window.location.href;
    if (!bbHandlebar) {
      this.route.queryParams.subscribe(async param => {
        if (param) {
          bbHandlebar = param['handleBar'];
        }
      });
    }
    if (currentURL.includes('view-record-list')) {
      isFormRunnerPage = false;
      isRecordListPage = true;
    } else if (currentURL.includes('form-runner')) {
      isFormRunnerPage = true;
      isRecordListPage = false;
    }
    if (draftData.responseStatus.status == draftStatus.draftUpdateFail) {
      //200 - normal flow no message
      sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
      this.isDraftUpdateAndFail = true;
      this.draftUpdateStatus = 'Fail';
    } else if (draftData.responseStatus.status == draftStatus.draftCreated && draftData.failureRemark) {
      sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
      if (draftData.failureRemark && isFailureMsgShow) {
        this.snackbarRef = this.snackbar.open(draftData.failureRemark, 'X', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar-draft'],
          duration: 3000
        });
      }
    } else if (draftData.responseStatus.status == draftStatus.draftCreated) {
      sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
      if ((!isFormRunnerPage && isRecordListPage) || isAutoMoveToNextAction) {
        this.dataStoreService.openFormRunner(bbHandlebar, actionHandleBar, draftData.recordId, draftData.id);
      }
      this.draftActionStatus = 'draftCreated';
    } else if (draftData.responseStatus.status == draftStatus.inProgress) {
      // Action is in progress by same user
      if (draftData.startedBy == localStorage.getItem('email')) {
        this.confirmationOfInProgressBySameUserDialog(
          dialog,
          isFormRunnerPage,
          isRecordListPage,
          draftData,
          bbHandlebar,
          actionHandleBar,
          draftData.recordId
        );
        this.draftActionStatus = 'InProgressBySameUser';
      } else {
        //Action is in-progress by another user
        this.confirmationOfInProgressByOtherUserDialog(
          dialog,
          isFormRunnerPage,
          isRecordListPage,
          draftData,
          bbHandlebar,
          actionHandleBar,
          draftData.recordId
        );
        this.draftActionStatus = 'InProgressByOtherUser';
      }
    } else if (draftData.responseStatus.status == draftStatus.onHold) {
      if (draftData.startedBy == localStorage.getItem('email')) {
        //Action is hold by same user
        this.draftActionStatus = 'onHoldBySameUser';
        if (!isFormRunnerPage && isRecordListPage) {
          this.dataStoreService.openFormRunner(bbHandlebar, actionHandleBar, draftData.recordId, draftData.id);
        }
      } else {
        //Action is hold by another user
        this.confirmationOfHoldByOtherUserDialog(
          dialog,
          isFormRunnerPage,
          isRecordListPage,
          draftData,
          bbHandlebar,
          actionHandleBar,
          draftData.recordId
        );
        this.draftActionStatus = 'OnHoldByOtherUser';
      }
    } else if (draftData.responseStatus.status == draftStatus.allReadyInUse) {
      sessionStorage.removeItem('draftDetails');
      sessionStorage.removeItem('draftSessionId');
      this.dataStoreService.navigateToRecordList(bbHandlebar);
      snackbar.open(draftData.responseStatus.msg, 'X', {
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['error-snackbar']
      });
    } else if (draftData.responseStatus.status == 'inSubmittionState') {
      sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
      let draftDialogRef = dialog.open(DraftConfirmationDialogComponent, {
        panelClass: ['back-confirm-dialog', 'holding'],
        data: {
          title: 'Resume action?',
          description1: '<p class="resume_text">Please wait, submitting is in progress... ',
          holdBtn: { Text: 'Hold', isShow: false },
          discardBtn: { Text: 'Discard', isShow: false },
          discardTrueBtn: { Text: 'Discard', isShow: false, discardTrue: false },
          resumeBtn: { Text: 'Resume', isShow: false },
          okBtn: { Text: 'Ok', isShow: false },
          cancelBtn: { Text: 'Cancel', isShow: false },
          isSubmitBtnClick: true
        }
      });
      draftDialogRef.afterClosed().subscribe(draftResult => {});
      // setTimeout(() => {
      this.getDraftById(draftData.id, draftData.session, dialog, false);
      // }, 3000);
    }
    return this.draftActionStatus;
  }

  async getDraftById(draftId, sessionId, dialog, isSubmitClick) {
    await this.getDraftAsync(draftId, sessionId).then(
      async (draftData: any) => {
        let draftDetails = sessionStorage.getItem('draftDetails')
          ? JSON.parse(sessionStorage.getItem('draftDetails'))
          : null;
        const isEqual = _.isEqual(draftDetails?.record, draftData?.record);
        if (!isEqual) {
          this.isLoadingShowForSubmitFail = true;
          this.snackbarRef = this.snackbar.open(
            'Unable to load data due to internet connectivity issue. Please wait, while we update the data.',
            'X',
            {
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['error-snackbar'],
              duration: 3000
            }
          );

          sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
          if (isSubmitClick) {
            this.setDraftDetailsSubscription.next(draftData);
          }
          setTimeout(() => {
            this.isLoadingShowForSubmitFail = false;
          }, 3000);
          // this.snackbarRef.dismiss();
        }
        this.draftDetailStatus(
          draftData,
          dialog,
          draftData.buildingBlock,
          this.snackbar,
          draftData.actionHandleBar,
          isEqual
        );
      },
      fail => {
        let errorMessage = fail.message;
        this.isLoadingShowForSubmitFail = false;
        this.dataStoreService.navigateToRecordList(this.dataStoreService.currentAction.buildingBlock);
        this.snackbarRef = this.snackbar.open(errorMessage, 'X', {
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error-snackbar']
        });
      }
    );
  }

  confirmationOfInProgressByOtherUserDialog(
    dialog,
    isFormRunnerPage,
    isRecordListPage,
    draftData,
    bbHandlebar,
    actionHandleBar,
    recordId
  ) {
    let draftDialogRef = dialog.open(DraftConfirmationDialogComponent, {
      panelClass: ['back-confirm-dialog', 'holding'],
      data: {
        title: 'Action is in progress',
        description1:
          '<p class="action_text">This action is already in progress by  <b>' +
          draftData.startedBy +
          '</b>. </p>' +
          '<p class="action_text">If you resume here, then this action will be closed from the other window. <b>',
        holdBtn: { Text: 'Hold', isShow: false },
        discardBtn: { Text: 'Discard', isShow: false },
        discardTrueBtn: { Text: 'Discard', isShow: false, discardTrue: false },
        resumeBtn: { Text: 'Resume', isShow: true },
        okBtn: { Text: 'Ok', isShow: false },
        cancelBtn: { Text: 'Cancel', isShow: true },
        isSubmitBtnClick: false
      }
    });
    draftDialogRef.afterClosed().subscribe(draftResult => {
      if (draftResult) {
        this.draftActionStatus = 'Ok';
        if (!isFormRunnerPage && isRecordListPage) {
          this.dataStoreService.openFormRunner(bbHandlebar, actionHandleBar, draftData.recordId, draftData.id);
        }
      } else {
        dialog.closeAll();
        if (isFormRunnerPage && !isRecordListPage) {
          sessionStorage.removeItem('draftDetails');
          sessionStorage.removeItem('draftSessionId');
          this.dataStoreService.navigateToRecordList(bbHandlebar);
        }
      }
    });
  }

  confirmationOfInProgressBySameUserDialog(
    dialog,
    isFormRunnerPage,
    isRecordListPage,
    draftData,
    bbHandlebar,
    actionHandleBar,
    recordId
  ) {
    let draftDialogRef = dialog.open(DraftConfirmationDialogComponent, {
      panelClass: ['back-confirm-dialog', 'holding'],
      data: {
        title: 'Resume action?',
        description1:
          '<p class="action_text">This action seems to be already open in another window. If you resume here, then this action will be closed from the other window.</p>',
        // '<p class="resume_text">Do you want to resume this action?</p>',
        holdBtn: { Text: 'Hold', isShow: false },
        discardBtn: { Text: 'Discard', isShow: false },
        discardTrueBtn: { Text: 'Discard', isShow: false, discardTrue: false },
        resumeBtn: { Text: 'Resume', isShow: true },
        okBtn: { Text: 'Ok', isShow: false },
        cancelBtn: { Text: 'Cancel', isShow: true },
        isSubmitBtnClick: false
      }
    });
    draftDialogRef.afterClosed().subscribe(draftResult => {
      if (draftResult) {
        this.draftActionStatus = 'Ok';
        if (!isFormRunnerPage && isRecordListPage) {
          this.dataStoreService.openFormRunner(bbHandlebar, actionHandleBar, draftData.recordId, draftData.id);
        }
      } else {
        dialog.closeAll();
        if (isFormRunnerPage && !isRecordListPage) {
          sessionStorage.removeItem('draftDetails');
          sessionStorage.removeItem('draftSessionId');
          this.dataStoreService.navigateToRecordList(bbHandlebar);
        }
      }
    });
  }

  confirmationOfHoldByOtherUserDialog(
    dialog,
    isFormRunnerPage,
    isRecordListPage,
    draftData,
    bbHandlebar,
    actionHandleBar,
    recordId
  ) {
    let draftDialogRef = dialog.open(DraftConfirmationDialogComponent, {
      panelClass: ['back-confirm-dialog', 'holding'],
      data: {
        title: 'Resume action?',
        description1:
          '<p class="resume_text">This action was put on hold by  ' +
          '<b>' +
          draftData.startedBy +
          '</b>. </p>' +
          '<p class="resume_text">Do you want to resume this action?</p>',
        holdBtn: { Text: 'Hold', isShow: false },
        discardBtn: { Text: 'Discard', isShow: false },
        discardTrueBtn: { Text: 'Discard', isShow: false, discardTrue: false },
        resumeBtn: { Text: 'Resume', isShow: true },
        okBtn: { Text: 'Ok', isShow: false },
        cancelBtn: { Text: 'Cancel', isShow: true },
        isSubmitBtnClick: false
      }
    });

    draftDialogRef.afterClosed().subscribe(draftResult => {
      if (draftResult) {
        this.draftActionStatus = 'Ok';
        if (!isFormRunnerPage && isRecordListPage) {
          this.dataStoreService.openFormRunner(bbHandlebar, actionHandleBar, draftData.recordId, draftData.id);
        }
      } else {
        dialog.closeAll();
        if (isFormRunnerPage && !isRecordListPage) {
          sessionStorage.removeItem('draftDetails');
          sessionStorage.removeItem('draftSessionId');
          this.dataStoreService.navigateToRecordList(bbHandlebar);
        }
      }
    });
  }

  //save all special handling details to session stroage for draft feature
  setSpclHandlingDataToSSForDraft(handleBar, value) {
    let draftDetails = sessionStorage.getItem('draftDetails');
    if (draftDetails) {
      let draftDataFromSS = JSON.parse(draftDetails);
      if (draftDataFromSS.record == undefined) draftDataFromSS.record = {};
      if (!draftDataFromSS.record['spclHandlingDetails']) draftDataFromSS.record['spclHandlingDetails'] = {};
      draftDataFromSS.record['spclHandlingDetails'][handleBar] = value;
      sessionStorage.setItem('draftDetails', JSON.stringify(draftDataFromSS));
      let currentAction = this.dataStoreService.currentAction;
      let payload = {
        record: draftDataFromSS.record,
        recordId: this.dataStoreService.selectedRecordFromRecordList.id,
        actionHandleBar: currentAction.handleBar,
        buildingBlockHandleBar: currentAction.buildingBlock,
        status: 'IN_PROGRESS',
        id: draftDataFromSS.id,
        session: draftDataFromSS.session
      };
      this.updateDraft(payload).subscribe(
        async (draftData: any) => {},
        (error: any) => {
          if (error.status == 409) {
            sessionStorage.removeItem('draftDetails');
            sessionStorage.removeItem('draftSessionId');
            this.dataStoreService.navigateToRecordList(this.dataStoreService.currentAction.buildingBlock);
          }
        }
      );
    }
  }

  //set fields details to session storage for draft & on refresh page
  async setFieldDataToDraft(record, action) {
    if (this.isDraftUpdateAndFail) this.draftUpdateStatus = 'Saving';
    let draftDetails = JSON.parse(sessionStorage.getItem('draftDetails'));

    if (draftDetails.record == undefined) draftDetails.record = {};
    let value = '';
    if (
      ((record.hasMultipleValues || record.fieldType == 'REFERENCE' || record.fieldType == 'ATTACHMENT') &&
        record.value.length > 0) ||
      (!record.hasMultipleValues &&
        record.fieldType != 'REFERENCE' &&
        record.fieldType != 'ATTACHMENT' &&
        !isNullOrUndefined(record.value) &&
        record.value != '') ||
      record.handleBar == 'orderIdSkipped'
    ) {
      value =
        !record.hasMultipleValues && (record.fieldType == 'REFERENCE' || record.fieldType == 'ATTACHMENT')
          ? record.value[0]
          : record.value;
      draftDetails.record[record.handleBar] = value;
    } else {
      delete draftDetails.record[record.handleBar];
    }
    draftDetails.record['lastVisitedField_handleBar'] = this.dataStoreService.currentField.handleBar;
    sessionStorage.setItem('draftDetails', JSON.stringify(draftDetails));

    if (record.fieldType == 'REFERENCE' && record.hasMultipleValues) {
      let currentAction = this.dataStoreService.currentAction;
      let payload = {
        record: draftDetails.record,
        recordId: this.dataStoreService.selectedRecordFromRecordList.id,
        actionHandleBar: currentAction.handleBar,
        buildingBlockHandleBar: currentAction.buildingBlock,
        status: 'IN_PROGRESS',
        id: this.draftId,
        session: sessionStorage.getItem('draftSessionId') ? JSON.parse(sessionStorage.getItem('draftSessionId')) : null
      };
      await this.updateDraftAsync(payload).then(
        async (draftData: any) => {
          sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
          if (this.isDraftUpdateAndFail) this.draftUpdateStatus = 'Saved';
        },
        (error: any) => {
          let errorMessage = error.message;
          if (this.isDraftUpdateAndFail) this.draftUpdateStatus = 'Fail';
          if (error.status == 409) {
            sessionStorage.removeItem('draftDetails');
            sessionStorage.removeItem('draftSessionId');
            this.dataStoreService.navigateToRecordList(this.dataStoreService.currentAction.buildingBlock);
          }
          this.snackbarRef = this.snackbar.open(errorMessage, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error-snackbar']
          });
        }
      );
    }
  }

  //update draft data only there will be no loader or any other user acknowledgment will be shown
  async updateDraftRecordOnly(value) {
    let draftDetails;
    if (sessionStorage.getItem('draftDetails')) draftDetails = JSON.parse(sessionStorage.getItem('draftDetails'));

    let payload = {
      record: draftDetails.record,
      recordId: this.dataStoreService.selectedRecordFromRecordList.id,
      actionHandleBar: this.dataStoreService.currentAction.handleBar,
      buildingBlockHandleBar: this.dataStoreService.currentAction.buildingBlock,
      status: 'IN_PROGRESS',
      id: this.draftId,
      session: sessionStorage.getItem('draftSessionId') ? JSON.parse(sessionStorage.getItem('draftSessionId')) : null
    };
    if (this.isDraftUpdateAndFail) this.draftUpdateStatus = 'Saving';
    if (value) {
      await this.updateDraftAsync(payload).then(
        async draftData => {
          if (draftData) {
            sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
          }
        },
        fail => {
          let errorMessage = fail.message;
          if (fail.status == 409) {
            this.router.navigate(['/building-blocks/view-record-list'], {
              queryParams: {
                handleBar: this.dataStoreService.currentAction.buildingBlock,
                location: this.authService.getFlexSlug()
              }
            });
          }
          this.snackbarRef = this.snackbar.open(errorMessage, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error-snackbar']
          });
        }
      );
    } else {
      this.updateDraft(payload).subscribe(
        async (draftData: any) => {
          if (draftData) {
            sessionStorage.setItem('draftDetails', JSON.stringify(draftData));
            if (this.isDraftUpdateAndFail) this.draftUpdateStatus = 'Saved';
          }
        },
        error => {
          if (this.isDraftUpdateAndFail) this.draftUpdateStatus == 'Fail';
          let errorMessage = error.message;
          if (error.status == 409) {
            this.router.navigate(['/building-blocks/view-record-list'], {
              queryParams: {
                handleBar: this.dataStoreService.currentAction.buildingBlock,
                location: this.authService.getFlexSlug()
              }
            });
          }
          // this.snackbarRef = this.snackbar.open(errorMessage, 'X', {
          //   verticalPosition: 'top',
          //   horizontalPosition: 'center',
          //   panelClass: ['error-snackbar']
          // });
        }
      );
    }
  }

  // Export Job
  createExportJob(exportJobPayload) {
    return this.http.post('platform/api/v1/export-job', exportJobPayload);
  }
}
