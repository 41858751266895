import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Convert camelCase to words separated by spaces
    let result = value.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalize the first letter of each word except for the last word
    let words = result
      .split(' ')
      .map((word, index, array) =>
        index === array.length - 1 && array.length != 1 ? word.toLowerCase() : this.capitalize(word)
      );

    return words.join(' ');
  }

  private capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}
