import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceAfterComma'
})
export class SpaceAfterComma implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return value.toString().replace(/,/g, ', ');
    } else {
      return '';
    }
  }
}
