import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'transformDate'
})
export class TransformDatePipe implements PipeTransform {
  transform(value: any, format?: any): any {
    if (!value) {
      return '';
    }
    return moment(value).format(format);
  }
}
