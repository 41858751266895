import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InstalledAppAuthorizationService } from '../../service/installed-app-authorization.service';
import { AppAuthorizationActionTypes, CheckAppAuthorization } from '../actions/installed-app-authorization.action';

@Injectable()
export class AppAuthorizationEffects {
  constructor(private appAuthorizationService: InstalledAppAuthorizationService, private actions$: Actions) {}

  @Effect()
  checkAppAuthorization$: Observable<Action> = this.actions$.pipe(
    ofType<CheckAppAuthorization>(AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION),
    mergeMap(action => {
      return this.appAuthorizationService.checkAppAuthorization(action.payload).pipe(
        map(data => ({
          type: AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
}
