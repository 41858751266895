import { Action } from '@ngrx/store';

export enum AppInstallActionTypes {
  GET_APP_SCOPE = '[APP INSTALL] Get App Scope',
  GET_APP_SCOPE_SUCCESS = '[APP INSTALL] Get App Scope Success',
  GET_APP_SCOPE_FAILURE = '[APP INSTALL] Get App Scope Failure',
  GET_APP_DETAILS = '[APP INSTALL] Get App Details',
  GET_APP_DETAILS_SUCCESS = '[APP INSTALL] Get App Details Success',
  GET_APP_DETAILS_FAILURE = '[APP INSTALL] Get App Details Failure',
  GET_APP_INSTALL_CONSENT = '[APP INSTALL] Get App Install Consent',
  GET_APP_INSTALL_CONSENT_SUCCESS = '[APP INSTALL] Get App Install Consent Success',
  GET_APP_INSTALL_CONSENT_FAILURE = '[APP INSTALL] Get App Install Consent Failure'
}

export class GetAppScope implements Action {
  readonly type = AppInstallActionTypes.GET_APP_SCOPE;
  constructor(public payload: any) {}
}
export class GetAppScopeSuccess implements Action {
  readonly type = AppInstallActionTypes.GET_APP_SCOPE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetAppScopeFailure implements Action {
  readonly type = AppInstallActionTypes.GET_APP_SCOPE_FAILURE;
  constructor(public payload: any) {}
}

export class GetAppDetails implements Action {
  readonly type = AppInstallActionTypes.GET_APP_DETAILS;
  constructor(public payload: any) {}
}
export class GetAppDetailsSuccess implements Action {
  readonly type = AppInstallActionTypes.GET_APP_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetAppDetailsFailure implements Action {
  readonly type = AppInstallActionTypes.GET_APP_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetAppInstallConsent implements Action {
  readonly type = AppInstallActionTypes.GET_APP_INSTALL_CONSENT;
  constructor(public payload = null) {}
}
export class GetAppInstallConsentSuccess implements Action {
  readonly type = AppInstallActionTypes.GET_APP_INSTALL_CONSENT_SUCCESS;
  constructor(public payload: any) {}
}
export class GetAppInstallConsentFailure implements Action {
  readonly type = AppInstallActionTypes.GET_APP_INSTALL_CONSENT_FAILURE;
  constructor(public payload: any) {}
}

export type AppInstallActions =
  | GetAppScope
  | GetAppScopeSuccess
  | GetAppScopeFailure
  | GetAppDetails
  | GetAppDetailsSuccess
  | GetAppDetailsFailure
  | GetAppInstallConsent
  | GetAppInstallConsentSuccess
  | GetAppInstallConsentFailure;
