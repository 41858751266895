import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  dummyAction,
  ruleDelete,
  ruleDeleteErrorAction,
  ruleDeleteSuccessAction,
  ruleList,
  ruleListErrorAction,
  ruleListSuccess,
  ruleListSuccessAction,
  ruleSave,
  ruleSaveErrorAction,
  ruleSaveSuccessAction,
  shippingActions
} from './shipping-mode.actions';
import { getRuleListALL, getShippingState } from './shipping-mode.selector';
import { getRuleList } from './shipping-mode.reducer';
import { Store, createSelector, select } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ShippingModeService } from '../services/shipping-mode.service';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/app.state';
import * as fromReducer from './shipping-mode.reducer';

@Injectable()
export class shippingModeEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private shippingService: ShippingModeService // private _projectTabService: ProjectTabService, // private _newProjectService: NewProjectService, // private _projectTimelineTabService: ProjectTimelineTabService, // private _projectDetailTabService: ProjectDetailTabService
  ) {}
  @Effect()
  getList$: Observable<any> = this.actions$.pipe(
    ofType(ruleList),
    withLatestFrom(this.store.select(getRuleListALL)),
    mergeMap(([actions, list]) => {
      // this.store.pipe(select(getRuleListALL)).subscribe(res => {
      //   // console.log(res, 'resssss');
      // });
      if (!list.length || (actions['payload'] != undefined && actions['payload']['force'])) {
        return this.shippingService.getAllRules().pipe(
          switchMap((data: any) => {
            return [new ruleListSuccessAction(data.shippingRules)];
          }),
          catchError((data: any) => {
            return [new ruleListErrorAction(data)];
          })
        );
      } else {
        return of(dummyAction);
      }
    })
  );

  @Effect()
  save$: Observable<any> = this.actions$.pipe(
    ofType(ruleSave),
    mergeMap(actions => {
      // return  [new ruleSaveSuccessAction('')];
      return this.shippingService.saveRule(actions['payload']).pipe(
        switchMap((data: any) => {
          return [new ruleSaveSuccessAction(data.shippingRules)];
        }),
        catchError((data: any) => {
          // debugger;
          return [new ruleSaveErrorAction(data.error.error)];
        })
      );
    })
  );

  @Effect()
  delete$: Observable<any> = this.actions$.pipe(
    ofType(ruleDelete),
    mergeMap(actions => {
      // return  [new ruleSaveSuccessAction('')];
      return this.shippingService.deleteRule(actions['payload']).pipe(
        switchMap((data: any) => {
          return [new ruleDeleteSuccessAction(data.shippingRules)];
        }),
        catchError((data: any) => {
          return [new ruleDeleteErrorAction(data)];
        })
      );
    })
  );

  //   @Effect()
  //   getList$: Observable<any> = this.actions$.pipe(
  //     ofType<shippingActions>(ruleList),
  //     switchMap(action => {
  //       return this.shippingService.getAllRules().pipe(
  //         map((user: any) => {
  //           return { type: ruleListSuccess, payload: user.shippingRules };
  //         }),
  //         catchError(error => of(dummyAction))
  //       );
  //     })
  //   );
}
