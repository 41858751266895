import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInstallationService } from '../service/app-install.service';

@Component({
  selector: 'app-install-app-page',
  templateUrl: './install-app-page.component.html',
  styleUrls: ['./install-app-page.component.scss']
})
export class InstallPageComponent implements OnInit {
  clientId: any;
  response_type: any;
  redirectUri: any;
  scope: any;
  systemState: any;
  scopeData: any = [];
  appData: any = [];
  queryParamsData: any;
  redirectUrl: any;
  isLoading: boolean;
  systemCode: any;
  constructor(
    private route: ActivatedRoute,
    private appInstallService: AppInstallationService,
    private router: Router
  ) {
    this.route.queryParams.subscribe(data => {
      if (data) {
        this.queryParamsData = data;
        this.systemState = data['state'];
        this.systemCode = data['code'];
      }
    });
    this.route.params.subscribe(data => {
      this.clientId = data.cilentId;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.getAppAuthorizeRedirection();
  }

  getAppAuthorizeRedirection() {
    let params = { state: this.systemState, code: this.systemCode };
    this.appInstallService.getAppInstalledPage(this.clientId, params).subscribe(data => {
      if (data && data['responseUrl']) {
        this.isLoading = false;
        let url = data['responseUrl'];
        window.open(url, '_self');
      }

      // this.redirectUrl = data;
      // this.redirectUri = this.redirectUrl.responseUrl;

      // var url = new URL(this.redirectUri);
      // var appId = url.searchParams.get('appId');
      // var urlPathSplit = this.redirectUri.split('/');
      // var appInstallId = urlPathSplit[5].split('?');
      // var urlPath = urlPathSplit[3] + '/' + urlPathSplit[4] + '/' + appInstallId[0];
      // this.router.navigate([urlPath]);
    });
  }
}
