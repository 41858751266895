import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input, textarea, [sanitizeInput]'
})
export class SanitizeInputDirective {
  constructor(private el: ElementRef) {}

  private disallowedTags: string[] = ['<script>', '</script>', '<a>', '</a>', '<html>', '</html>'];

  /*
    @description : this function listens to input and textarea and removes disallowed tags.
  */
  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    let inputValue: string = this.el.nativeElement.value;
    this.disallowedTags.forEach(tag => {
      const regex = new RegExp(tag, 'gi');
      inputValue = inputValue.replace(regex, '');
    });
    this.el.nativeElement.value = inputValue;
  }
}
