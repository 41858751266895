import { Action } from '@ngrx/store';

export enum OrdersActionTypes {
  SHOW_PROGRESS_SPINNER = '[Orders] Show progress spinner V2',
  HIDE_PROGRESS_SPINNER = '[Orders] Hide progress spinner V2',

  GET_ORDERS_LIST = '[Orders] Get Orders V2 List',
  GET_ORDERS_LIST_SUCCESS = '[Orders] Get Orders V2 List Success',
  GET_ORDERS_LIST_FAILURE = '[Orders] Get Orders V2 List Failure',

  GET_PORTALS_LIST = '[Orders] Get Portal V2 List',
  GET_PORTALS_LIST_SUCCESS = '[Orders] Get Portal V2 List Success',
  GET_PORTALS_LIST_FAILURE = '[Orders] Get Portal V2 List Failure',

  GET_TEMPLATE = '[Orders] Get Template V2',
  GET_TEMPLATE_SUCCESS = '[Orders] Get Template Success V2',
  GET_TEMPLATE_FAILURE = '[Orders] Get Template Failure V2',

  SAVE_TEMPLATE = '[Orders] Save Template V2',
  SAVE_TEMPLATE_SUCCESS = '[Orders] Save Template Success V2',
  SAVE_TEMPLATE_FAILURE = '[Orders] Save Template Failure V2',

  EDIT_TEMPLATE = '[Orders] Edit Template V2',
  EDIT_TEMPLATE_SUCCESS = '[Orders] Edit Template Success V2',
  EDIT_TEMPLATE_FAILURE = '[Orders] Edit Template Failure V2',

  DELETE_TEMPLATE = '[Orders] Delete Template Data V2',
  DELETE_TEMPLATE_SUCCESS = '[Orders] Delete Template Data Success V2',
  DELETE_TEMPLATE_FAILURE = '[Orders] Delete Template Data Failure V2',

  EXPORT_TEMPLATE = '[Orders] Export Template V2',
  EXPORT_TEMPLATE_SUCCESS = '[Orders] Export Template Success V2',
  EXPORT_TEMPLATE_FAILURE = '[Orders] Export Template Failure V2',

  SCHEDULE_REPORT = '[Orders] Schedule Report V2',
  SCHEDULE_REPORT_SUCCESS = '[Orders] Schedule Report Success V2',
  SCHEDULE_REPORT_FAILURE = '[Orders] Schedule Report Failure V2',

  GET_MEMBER_LIST = '[Orders] Memeber List V2',
  GET_MEMBER_LIST_SUCCESS = '[Orders] Memeber List Success V2',
  GET_MEMBER_LIST_FAILURE = '[Orders] Memeber List Failure V2',

  CREATE_IMPORT = '[Orders] Create Import V2',
  CREATE_IMPORT_SUCCESS = '[Order] Create Import Success V2',
  CREATE_IMPORT_FAILURE = '[Order] Create Import Failure V2',

  /*Temporary Change*/
  DOCUMENT_UPLOAD_STATUS = '[Order] DOCUMENT UPLOAD STATUS V2 ',
  DOCUMENT_UPLOAD_STATUS_SUCCESS = '[Order] DOCUMENT UPLOAD STATUS SUCCESS V2',
  DOCUMENT_UPLOAD_STATUS_FAILURE = '[Order] DOCUMENT UPLOAD STATUS FAILURE V2',

  CREATE_BULK_RETURN = '[Return] CREATE BULK RETURN V2',
  CREATE_BULK_RETURN_SUCCESS = '[Return] CREATE BULK RETURN SUCCESS V2',
  CREATE_BULK_RETURN_FAILURE = '[Return] CREATE BULK RETURN FAILURE V2',

  // order cancellation starts
  CREATE_ORDER_CANCELLATION = '[Orders] Create Order Cancellation V2',
  CREATE_ORDER_CANCELLATION_SUCCESS = '[Order] Create Order Cancellation Success V2',
  CREATE_ORDER_CANCELLATION_FAILURE = '[Order] Create Order Cancellation Failure V2',

  CUSTOMER_PORTAL_POE_SETTINGS = '[Orders] Customer Portal Poe Settings V2',
  CUSTOMER_PORTAL_POE_SETTINGS_SUCCESS = '[Order] Customer Portal Poe Settings Success V2',
  CUSTOMER_PORTAL_POE_SETTINGS_FAILURE = '[Order] Customer Portal Poe Settings Failure V2',
  // order cancellation ends

  UPDATE_ORDER_STATUS_CANCELLED = '[Orders] Update Order Status Cancelled V2',
  UPDATE_ORDER_CANCELLED = '[Orders] Update Order Cancelled V2'
}

export class GetOrdersList implements Action {
  readonly type = OrdersActionTypes.GET_ORDERS_LIST;
  constructor(public payload: any) {}
}

export class GetOrdersListSuccess implements Action {
  readonly type = OrdersActionTypes.GET_ORDERS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOrdersListFailure implements Action {
  readonly type = OrdersActionTypes.GET_ORDERS_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetPortalsList implements Action {
  readonly type = OrdersActionTypes.GET_PORTALS_LIST;
  constructor() {}
}

export class GetPortalsListSuccess implements Action {
  readonly type = OrdersActionTypes.GET_PORTALS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPortalsListFailure implements Action {
  readonly type = OrdersActionTypes.GET_PORTALS_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class ShowProgressSpinner implements Action {
  readonly type = OrdersActionTypes.SHOW_PROGRESS_SPINNER;
}
export class HideProgressSpinner implements Action {
  readonly type = OrdersActionTypes.HIDE_PROGRESS_SPINNER;
}

export class GetTemplate implements Action {
  readonly type = OrdersActionTypes.GET_TEMPLATE;
  constructor(public payload: any) {}
}

export class GetTemplateSuccess implements Action {
  readonly type = OrdersActionTypes.GET_TEMPLATE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTemplateFailure implements Action {
  readonly type = OrdersActionTypes.GET_TEMPLATE_FAILURE;
  constructor(public payload: any) {}
}

export class SaveTemplateV2 implements Action {
  readonly type = OrdersActionTypes.SAVE_TEMPLATE;
  constructor(public payload: any) {}
}

export class SaveTemplateSuccess implements Action {
  readonly type = OrdersActionTypes.SAVE_TEMPLATE_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveTemplateFailure implements Action {
  readonly type = OrdersActionTypes.SAVE_TEMPLATE_FAILURE;
  constructor(public payload: any) {}
}

export class EditTemplateV2 implements Action {
  readonly type = OrdersActionTypes.EDIT_TEMPLATE;
  constructor(public payload: any) {}
}

export class EditTemplateSuccess implements Action {
  readonly type = OrdersActionTypes.EDIT_TEMPLATE_SUCCESS;
  constructor(public payload: any) {}
}

export class EditTemplateFailure implements Action {
  readonly type = OrdersActionTypes.EDIT_TEMPLATE_FAILURE;
  constructor(public payload: any) {}
}

export class DeleteTemplateV2 implements Action {
  readonly type = OrdersActionTypes.DELETE_TEMPLATE;
  constructor(public payload: any) {}
}

export class DeleteTemplateSuccess implements Action {
  readonly type = OrdersActionTypes.DELETE_TEMPLATE_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteTemplateFailure implements Action {
  readonly type = OrdersActionTypes.DELETE_TEMPLATE_FAILURE;
  constructor(public payload: any) {}
}

export class ExportTemplate implements Action {
  readonly type = OrdersActionTypes.EXPORT_TEMPLATE;
  constructor(public payload: any) {}
}

export class ExportTemplateSuccess implements Action {
  readonly type = OrdersActionTypes.EXPORT_TEMPLATE_SUCCESS;
  constructor(public payload: any) {}
}

export class ExportTemplateFailure implements Action {
  readonly type = OrdersActionTypes.EXPORT_TEMPLATE_FAILURE;
  constructor(public payload: any) {}
}

export class ScheduleReport implements Action {
  readonly type = OrdersActionTypes.SCHEDULE_REPORT;
  constructor(public payload: any) {}
}

export class ScheduleReportSuccess implements Action {
  readonly type = OrdersActionTypes.SCHEDULE_REPORT_SUCCESS;
  constructor(public payload: any) {}
}

export class ScheduleReportFailure implements Action {
  readonly type = OrdersActionTypes.SCHEDULE_REPORT_FAILURE;
  constructor(public payload: any) {}
}

export class GetMemberListV2 implements Action {
  readonly type = OrdersActionTypes.GET_MEMBER_LIST;
  constructor(public payload: any) {}
}

export class GetMemberListSuccess implements Action {
  readonly type = OrdersActionTypes.GET_MEMBER_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetMemberListFailure implements Action {
  readonly type = OrdersActionTypes.GET_MEMBER_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class CreateImport implements Action {
  readonly type = OrdersActionTypes.CREATE_IMPORT;
  constructor(public payload: any) {}
}

export class CreateImportSuccess implements Action {
  readonly type = OrdersActionTypes.CREATE_IMPORT_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateImportFailure implements Action {
  readonly type = OrdersActionTypes.CREATE_IMPORT_FAILURE;
  constructor(public payload: any) {}
}

/*Temporary Changes*/
export class SheetStatusCheck implements Action {
  readonly type = OrdersActionTypes.DOCUMENT_UPLOAD_STATUS;
  constructor(public payload: any) {}
}
export class SheetStatusSuccess implements Action {
  readonly type = OrdersActionTypes.DOCUMENT_UPLOAD_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class SheetStatusFailure implements Action {
  readonly type = OrdersActionTypes.DOCUMENT_UPLOAD_STATUS_FAILURE;
  constructor(public payload: any) {}
}
export class CreateBulkReturn implements Action {
  readonly type = OrdersActionTypes.CREATE_BULK_RETURN;
  constructor(public payload: any) {}
}
export class CreateBulkReturnSuccess implements Action {
  readonly type = OrdersActionTypes.CREATE_BULK_RETURN_SUCCESS;
  constructor(public payload: any) {}
}
export class CreateBulkReturnFailure implements Action {
  readonly type = OrdersActionTypes.CREATE_BULK_RETURN_FAILURE;
  constructor(public payload: any) {}
}

// Order cancellation starts
export class CreateOrderCancellation implements Action {
  readonly type = OrdersActionTypes.CREATE_ORDER_CANCELLATION;
  constructor(public payload: any) {}
}
export class CreateOrderCancellationSuccess implements Action {
  readonly type = OrdersActionTypes.CREATE_ORDER_CANCELLATION_SUCCESS;
  constructor(public payload: any) {}
}
export class CreateOrderCancellationFailure implements Action {
  readonly type = OrdersActionTypes.CREATE_ORDER_CANCELLATION_FAILURE;
  constructor(public payload: any) {}
}

export class CustomerPortalPoeSettings implements Action {
  readonly type = OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS;
  constructor() {}
}
export class CustomerPortalPoeSettingsSuccess implements Action {
  readonly type = OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}
export class CustomerPortalPoeSettingsFailure implements Action {
  readonly type = OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}
// Order cancellation starts

export class UpdateOrderStatusCancelled implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_STATUS_CANCELLED;
  constructor(public payload: any) {}
}

export class UpdateOrderCancelled implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_CANCELLED;
  constructor(public payload: any) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type OrdersActions =
  | GetOrdersList
  | GetOrdersListSuccess
  | GetOrdersListFailure
  | GetPortalsList
  | GetPortalsListSuccess
  | GetPortalsListFailure
  | ShowProgressSpinner
  | HideProgressSpinner
  | GetTemplate
  | GetTemplateFailure
  | GetTemplateSuccess
  | SaveTemplateV2
  | SaveTemplateSuccess
  | SaveTemplateFailure
  | EditTemplateV2
  | EditTemplateFailure
  | EditTemplateSuccess
  | DeleteTemplateV2
  | DeleteTemplateFailure
  | DeleteTemplateSuccess
  | ExportTemplate
  | ExportTemplateSuccess
  | ExportTemplateFailure
  | ScheduleReport
  | ScheduleReportSuccess
  | ScheduleReportFailure
  | GetMemberListV2
  | GetMemberListSuccess
  | GetMemberListFailure
  | CreateImport
  | CreateImportSuccess
  | CreateImportFailure
  /*Temporary Change*/
  | SheetStatusCheck
  | SheetStatusSuccess
  | SheetStatusFailure
  | CreateBulkReturn
  | CreateBulkReturnSuccess
  | CreateBulkReturnFailure
  // order cancellation starts
  | CreateOrderCancellation
  | CreateOrderCancellationSuccess
  | CreateOrderCancellationFailure
  | CustomerPortalPoeSettings
  | CustomerPortalPoeSettingsSuccess
  | CustomerPortalPoeSettingsFailure
  // order cancellation starts
  | UpdateOrderStatusCancelled
  | UpdateOrderCancelled;
