import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchInDashoboardFilter'
})
export class SearchInDashoboardFilterPipe implements PipeTransform {
  transform(items: any[], searchText?: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    if (searchText) {
      return items.filter(a => a.viewValue.toLowerCase().includes(searchText.toLowerCase()));
    }
  }
}
