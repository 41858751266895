import { Component, OnInit, Output, EventEmitter, Input, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
  @Output() otp = new EventEmitter();
  @Output() isError = new EventEmitter();
  @Input() error: boolean = false;
  key: any = '';
  form: FormGroup;
  otpInputs = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;

  constructor() {
    this.form = this.toFormGroup(this.otpInputs);
  }

  ngOnInit() {}

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedOTP = clipboardData.getData('text');
    // split clipboard text into single characters
    if (isNaN(pastedOTP)) return;
    const data = pastedOTP.split('');
    // find all other text inputs
    [].forEach.call(document.querySelectorAll('input[type=text]'), (node, index) => {
      // And set input value to the relative character
      if (data[index]) node.value = data[index];
    });
    if (data.length === 6) this.otp.emit(pastedOTP);
  }

  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyupEvent(event: any, index: number) {
    if (!this.form.value['input' + (index + 1)] && !(event.keyCode === 8 || event.which === 8)) {
      return;
    }
    let currentPosition = index;
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      currentPosition = index + 1;
    } else if (event.keyCode === 8 && event.which === 8) {
      currentPosition = index - 1;
    } else if (!this.form.valid) {
      this.isError.emit({ isError: false });
    }
    if (currentPosition > -1 && currentPosition < this.otpInputs.length) {
      this.rows._results[currentPosition].nativeElement.focus();
    }
    if (this.form.valid) this.extractOTP();
  }

  extractOTP() {
    const otpObj = this.form.value;
    let userOtp = '';
    for (const val in otpObj) {
      userOtp += otpObj[val];
    }
    this.otp.emit(userOtp);
  }
}
