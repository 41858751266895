<form [formGroup]="form">
  <div class="otp-block">
    <ng-container *ngFor="let input of otpInputs; index as i">
      <input
        #formRow
        type="text"
        (paste)="onPaste($event)"
        formControlName="{{ input }}"
        class="otp-input"
        [ngClass]="{ 'otp-invalid': error }"
        maxlength="1"
        (keyup)="keyupEvent($event, i)"
        onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 0"
      />
    </ng-container>
  </div>
</form>
