<span class="header-help">
  <img
    src="https://ik.imagekit.io/2gwij97w0o/top_nav/help-circle.svg?updatedAt=1691990778513"
    class="header-help-icon"
  />
  <div
    class="theme-help-tooltip"
    [ngClass]="{
      '': description || (topic1 && topic2),
      'single-topic': (topic1 !== '' || topic2 !== '') && !(description || (topic1 && topic2))
    }"
  >
    <p *ngIf="description">{{ description }}</p>
    <a *ngIf="topic1link" (click)="openIntercom($event)"
      ><img src="https://ik.imagekit.io/2gwij97w0o/top_nav/read-more.svg?updatedAt=1691990835944" />{{ topic1 }}</a
    >
    <a *ngIf="topic2link" href="{{ topic2link }}" target="_blank"
      ><img src="https://ik.imagekit.io/2gwij97w0o/top_nav/read-more.svg?updatedAt=1691990835944" />{{ topic2 }}</a
    >
  </div>
</span>
