import { MetaReducer, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppState } from './app.state';
import * as fromAuth from './auth/reducers/auth.reducer';
import * as fromConsignment from './consignment/reducers/consignment.reducer';
import * as fromRegistration from './registration/reducers/registration.reducer';
import * as fromFc from './fc/store/reducers/fc.reducers';
import * as fromChannels from './channels/store/reducers/channels.reducer';
import * as fromInvoice from './invoices/reducers/invoice.reducer';
import * as fromSettings from './settings/store/reducers/settings.reducer';
import * as fromPayments from './payments/store/reducers/payments.reducers';
import * as fromAppInstall from './app-installation/store/reducers/app-install.reducer';
import * as fromShipping from './settings/components/shipping-mode/store/shipping-mode.reducer';
import * as fromBB from '../app/building-blocks/settingStore/reducers/buildingblock.reducers';
import * as fromSegment from './shared/component/segment-with-count/store/reducers/segment.reducers';
import * as fromOR from './settings/components/order-routing/store/order-routing.reducer';
import * as fromWD from 'src/app/settings/components/weight-discrepancy/pipe-service-store/store/weight-discrypancy.reducer';

import { AuthActionTypes } from './auth/actions/auth.actions';

export function defaultReducer<T>(state: T) {
  return state;
}
export const initialReducerMap = {
  auth: fromAuth.AuthReducer,
  consignment: fromConsignment.ConsignmentReducer,
  registration: fromRegistration.RegistrationReducer,
  // fc: fromFc.FcReducer,
  payments: fromPayments.PaymentsReducer,
  channels: fromChannels.ChannelReducer,
  invoice: fromInvoice.InvoiceReducer,
  settings: fromSettings.SettingsReducer,
  appInstallState: fromAppInstall.AppInstallReducer,
  shippingState: fromShipping.shippingModuleReducer,
  buildingBlockState: fromBB.BuildingBlockReducer,
  orderRoutingState: fromOR.orderroutingReducer,
  wdState: fromWD.weightDiscrypancyReducer,
  segmentState: fromSegment.SegmentReducer
} as ActionReducerMap<AppState>;

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['registration', 'fc'],
    rehydrate: true
  })(reducer);
}

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === AuthActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, clearState];
