import * as ChannelsActionTypes from '../actions/channels.action';
import { ChannelsState } from '../state/channels.state';

export const initialState: ChannelsState = {
  channelsList: [],
  channelsListLoaded: false,
  channelsListLoading: false,
  portalsList: [],
  portalsListLoaded: false,
  portalsListLoading: false,

  uniqueChannelListForAddTransactionRule: [],
  uniqueChannelListForAddTransactionRuleLoading: false,
  uniqueChannelListForAddTransactionRuleLoaded: false,
  fullFillmentCenters: null
};

export function ChannelReducer(state = initialState, action: ChannelsActionTypes.ChannelsUnion): ChannelsState {
  switch (action.type) {
    case ChannelsActionTypes.ChannelsActionTypes.GET_CHANNELS_LIST: {
      return {
        ...state,
        channelsListLoaded: false,
        channelsListLoading: true
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_CHANNELS_LIST_SUCCESS: {
      return {
        ...state,
        channelsListLoaded: true,
        channelsListLoading: false,
        channelsList: action.payload
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_CHANNELS_LIST_FAILURE: {
      return {
        ...state,
        channelsListLoaded: false,
        channelsListLoading: false
      };
    }

    /**
     * Uinque Channel List For Add Transaction Rule
     */
    case ChannelsActionTypes.ChannelsActionTypes.GET_UNIQUE_CHANNELS_LIST: {
      return {
        ...state,
        uniqueChannelListForAddTransactionRuleLoaded: false,
        uniqueChannelListForAddTransactionRuleLoading: true
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_UNIQUE_CHANNELS_LIST_SUCCESS: {
      return {
        ...state,
        uniqueChannelListForAddTransactionRuleLoaded: true,
        uniqueChannelListForAddTransactionRuleLoading: false,
        uniqueChannelListForAddTransactionRule: action.payload
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_UNIQUE_CHANNELS_LIST_FAILURE: {
      return {
        ...state,
        uniqueChannelListForAddTransactionRuleLoaded: false,
        uniqueChannelListForAddTransactionRuleLoading: false,
        uniqueChannelListForAddTransactionRule: []
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_PORTALS_LIST: {
      return {
        ...state,
        portalsListLoaded: false,
        portalsListLoading: true
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_PORTALS_LIST_SUCCESS: {
      return {
        ...state,
        portalsListLoaded: true,
        portalsListLoading: false,
        portalsList: action.payload
      };
    }

    case ChannelsActionTypes.ChannelsActionTypes.GET_PORTALS_LIST_FAILURE: {
      return {
        ...state,
        portalsListLoaded: true,
        portalsListLoading: false
      };
    }
    case ChannelsActionTypes.ChannelsActionTypes.GET_FC_LIST: {
      return {
        ...state
      };
    }
    case ChannelsActionTypes.ChannelsActionTypes.GET_FC_LIST_SUCCESS: {
      return {
        ...state,
        fullFillmentCenters: action.payload
      };
    }
    case ChannelsActionTypes.ChannelsActionTypes.GET_FC_LIST_FAILURE: {
      return {
        ...state,
        fullFillmentCenters: action.payload
      };
    }
    case ChannelsActionTypes.ChannelsActionTypes.RESET_STATE: {
      return Object.assign({}, initialState);
    }
    default: {
      return state;
    }
  }
}

export const getChannelListLoaded = (state: ChannelsState) => state.channelsListLoaded;
export const getChannelListLoading = (state: ChannelsState) => state.channelsListLoading;
export const getChannelList = (state: ChannelsState) => state.channelsList;
export const getUniqueChannelListForAddTransactionRuleLoaded = (state: ChannelsState) =>
  state.uniqueChannelListForAddTransactionRuleLoaded;
export const getUniqueChannelListForAddTransactionRuleLoading = (state: ChannelsState) =>
  state.uniqueChannelListForAddTransactionRuleLoading;
export const getUniqueChannelListForAddTransactionRule = (state: ChannelsState) =>
  state.uniqueChannelListForAddTransactionRule;
export const getPortalsListLoaded = (state: ChannelsState) => state.portalsListLoaded;
export const getPortalsListLoading = (state: ChannelsState) => state.portalsListLoading;
export const getPortalsList = (state: ChannelsState) => state.portalsList;
export const getFullFillmentCenters = (state: ChannelsState) => state.fullFillmentCenters;
