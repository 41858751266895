import * as registration from '../actions/registration.actions';
import { RegistrationState } from '../registration.state';

export const initialState: RegistrationState = {
  verticalsloaded: false,
  verticalsloading: false,
  countriesLoaded: false,
  countriesLoading: false,
  verticals: [],
  countries: []
};

export function RegistrationReducer(
  state = initialState,
  action: registration.RegistrationUnion
): RegistrationState {
  switch (action.type) {
    case registration.RegistrationActionTypes.GET_VERTICALS: {
      return {
        ...state,
        verticalsloaded: false,
        verticalsloading: true
      };
    }

    case registration.RegistrationActionTypes.GET_VERTICALS_SUCCESS: {
      return {
        ...state,
        verticalsloaded: true,
        verticalsloading: false,
        verticals: action.payload
      };
    }

    case registration.RegistrationActionTypes.GET_VERTICALS_FAILURE: {
      return {
        ...state,
        verticalsloaded: true,
        verticalsloading: false,
      };
    }

    case registration.RegistrationActionTypes.GET_COUNTRIES: {
      return {
        ...state,
        verticalsloaded: false,
        verticalsloading: true
      };
    }

    case registration.RegistrationActionTypes.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countriesLoaded: true,
        countriesLoading: false,
        countries: action.payload
      };
    }

    case registration.RegistrationActionTypes.GET_COUNTRIES_FAILURE: {
      return {
        ...state,
        countriesLoaded: true,
        countriesLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getVerticalsLoaded = (state: RegistrationState) => state.verticalsloaded;
export const getVerticalsLoading = (state: RegistrationState) => state.verticalsloading;
export const getVerticals = (state: RegistrationState) => state.verticals;
export const getCountriesLoaded = (state: RegistrationState) => state.countriesLoaded;
export const getCountriesLoading = (state: RegistrationState) => state.countriesLoading;
export const getCountries = (state: RegistrationState) => state.countries;
