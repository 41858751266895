import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services';
import { ExportOrdersComponent } from '../order-exportV2/containersV2/export-ordersV2/export-orders.component';
import { MatDialog } from '@angular/material/dialog';
import { NEW_ORDERS_V2_PARAM } from 'src/app/constants/routes';

@Injectable({
  providedIn: 'root'
})
export class OrdersServiceV2 {
  orderInfoTrackingStatus = new Subject();
  ordersTabToActivitiesTab = new Subject<any>();
  public orderRefresh = new Subject<boolean>();
  ninetyDaysCnt: any;
  needAttentionFilterFormData: any;
  needAttentionFilterFormData1: any;
  outOfStock: string;
  partiallyOrder: string;
  itemNotFound: string;
  allunfulfillable: string;
  HttpRequest: string;
  orderLoader = new Subject<boolean>();
  orderLocationLoader = new Subject<any>();
  orderCount = new Subject<number>();
  snackbarRef: any;
  orderSplitLoader = new Subject<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    public dialog: MatDialog
  ) {}

  getOrdersData(requestBody) {
    const apiurl = 'v2/orders/search';
    return this.http.get(apiurl, { params: requestBody });
  }

  getFiltersData() {
    // Old all order page filters API
    // const apiurl = 'v2/orders/filter';
    // Old all order page filters API
    const apiurl = 'v2/orders/filtersNew';
    return this.http.get(apiurl);
  }

  public sortData(data) {
    return data.sort((a, b) => {
      return a > b ? 1 : -1;
    });
  }

  /**@description - Helper Method To Get Shipment Weight Unit based on Integration Type*/
  public getShipmentWeightUnit(integrationType) {
    let shipmentWeightUnit = 'Kg';
    if (integrationType) {
      ['0', '1', '2', '3'].includes(integrationType) ? (shipmentWeightUnit = 'Kg') : (shipmentWeightUnit = 'gm');
    }
    return shipmentWeightUnit;
  }

  public getDimensionUnit(integrationType) {
    let dimensionUnit = 'cm';
    if (integrationType) {
      ['0', '1', '2', '3', '4', '6', '7', '11'].includes(integrationType)
        ? (dimensionUnit = 'cm')
        : (dimensionUnit = 'mm');
    }
    return dimensionUnit;
  }

  /**@description Method to Navigate the User to Orders Page*/
  public navigateToOrder() {
    this.router.navigate(['/order-V2/list'], { queryParams: NEW_ORDERS_V2_PARAM });
  }

  createImport(queryParams) {
    const apiUrl = environment.PLATFORM_API_URL + '/import-job';
    return this.http.post(apiUrl, queryParams);
  }

  uploadinvoices(queryParams) {
    const apiUrl = 'v1/invoices';
    return this.http.post(apiUrl, queryParams);
  }

  newLabel(queryParams) {
    let header = new HttpHeaders().set('ProxyHost', this.authService.getAccountSlug() + '' + environment.BASE_DOMAIN);
    const apiUrl =
      'https://' + this.authService.getAccountSlug() + '' + environment.BASE_DOMAIN + '/shipping/api/v1/newLabel';
    return this.http.post(apiUrl, queryParams, { headers: header });
  }

  /*Temporary Changes*/
  getBulkUploadDocumentStatus(payload) {
    const url = environment.PLATFORM_API_URL + `/import-job/${payload}`;
    return this.http.get(url);
  }
  createBulkReturn(payload) {
    const apiUrl = environment.PLATFORM_API_URL + '/import-job';
    return this.http.post(apiUrl, payload);
  }
  getPoeData() {
    const apiUrl = '/customer-portal/api/v1/poe-setting';
    return this.http.get(apiUrl);
  }
  sendOrderCancellationData(payload: any) {
    const apiUrl = '/order-item/cancel';
    return this.http.post(apiUrl, payload);
  }

  /**@description get countdown time for tracking details*/
  getCountDownForTrackingDetailsDelivered(fulfilledDate, trackingDate) {
    fulfilledDate = moment(fulfilledDate).valueOf();
    trackingDate = moment(trackingDate).valueOf();
    let data = this.getRTSCountDown(fulfilledDate, trackingDate);
    return data;
  }

  /**@description Method To Get count down string of RTS to show on HTML*/
  getRTSCountDown(firstDate, secondDate) {
    let finalValue = '';
    if (firstDate && secondDate) {
      const timeDifference = this.getTimeDifference(firstDate, secondDate);
      finalValue = this.calculateDaysHours(timeDifference);
    }
    return finalValue;
  }

  /**@description Method To Get difference between 2 dates*/
  getTimeDifference(date1, date2) {
    let timeDifference = new Date(Number(date1)).getTime() - new Date(Number(date2)).getTime();
    timeDifference = timeDifference < 0 ? timeDifference * -1 : timeDifference;
    return timeDifference;
  }

  /**@description Method To Get days/hours/minutes/seconds from milliseconds*/
  calculateDaysHours(milliSeconds) {
    if (milliSeconds != 0) {
      var day, hour, minute, seconds;
      seconds = Math.floor(milliSeconds / 1000);
      minute = Math.floor(seconds / 60);
      seconds = seconds % 60;
      hour = Math.floor(minute / 60);
      minute = minute % 60;
      day = Math.floor(hour / 24);
      hour = hour % 24;
      var daysString = day != 0 ? (daysString = day == 1 ? day + ' day' : day + ' days') : '';
      var hoursString = hour != 0 ? (hoursString = hour == 1 ? hour + ' hr' : hour + ' hrs') : '';
      var minutesString = minute != 0 ? (minutesString = minute == 1 ? minute + ' min' : minute + ' mins') : '';
      var secondsString = seconds != 0 ? (secondsString = seconds == 1 ? seconds + ' sec' : seconds + ' seconds') : '';
      if (daysString != '') {
        return hoursString === '' ? daysString : daysString + ', ' + hoursString;
      } else if (daysString === '' && hoursString != '') {
        return minutesString === '' ? hoursString : hoursString + ', ' + minutesString;
      } else return minutesString;
    } else {
      return '';
    }
  }

  /**@description Common Method to check if track button should be visible or not*/
  checkIfRedirectToPoe(ordersData, allSalesChannelData) {
    if (ordersData && ordersData.orderDetails && ordersData.orderDetails.saleChannel) {
      const salesChannel = allSalesChannelData.filter(
        salesChannel => salesChannel.externalChannelId === ordersData.orderDetails.saleChannel
      );
      if (
        salesChannel &&
        salesChannel.length > 0 &&
        ((salesChannel[0].origin && salesChannel[0].origin != '') ||
          (salesChannel[0].channelSlug && salesChannel[0].channelSlug != ''))
      )
        return true;
      else return false;
    }
  }

  redirectToPOE(isTrackLink, ordersData, authToken, proxyHost, trackJourney, index, isReAttempt, isupdateAddress?) {
    const email = localStorage.getItem('email');
    let isDeliveryFailed: boolean = false;
    let isCancelPickup: boolean = false;
    let isCancelItem: boolean = false;
    let isReturnInitiated: boolean = false;
    let isReAttemptAction: boolean = isReAttempt;
    const key = 'redirect';
    let filters = window.location.href.split('?')[1];
    const firstIndex = filters.indexOf('&lastViewedItem');

    if (firstIndex > 0) {
      const subStr = filters.substr(firstIndex, filters.length);
      filters = filters.replace(subStr, '');
    }
    let encodedString = btoa(
      `${ordersData.customerOrderNumber}##${ordersData.orderItemDetails.orderItemSku}##${ordersData.customerDetails.billingContactPhone}`
    );
    encodedString = encodedString.split('=').join('');

    if (trackJourney === 'return') {
      if (!isTrackLink) {
        if (ordersData && ordersData.returnStatus) {
          if (ordersData.returnStatus.toLowerCase() === 'pickup cancelled') isReturnInitiated = true;
        }

        if (ordersData.returnStatus) {
          if (ordersData.returnStatus.toLowerCase() === 'awaiting pickup') isCancelPickup = true;
        }
      }
    }

    if (trackJourney === 'forward') {
      if (!isTrackLink) {
        if (ordersData && ordersData.orderStatus) {
          if (
            ordersData.orderStatus.toLowerCase() === 'on the way' &&
            ordersData.shipmentTrackingDetails.status.toLowerCase() === 'failed_delivery'
          )
            isDeliveryFailed = true;

          if (ordersData.orderStatus.toLowerCase() === 'delivered') isReturnInitiated = true;
        }
      }
    }

    if (trackJourney === 'listing') {
      if (!isTrackLink) {
        if (ordersData && ordersData.returnStatus) {
          if (ordersData.returnStatus.toLowerCase() === 'pickup cancelled') isReturnInitiated = true;
        }
        if (ordersData.returnStatus) {
          if (ordersData.returnStatus.toLowerCase() === 'awaiting pickup') isCancelPickup = true;
        }

        if (!isReturnInitiated && !isCancelPickup) {
          if (ordersData && ordersData.orderStatus) {
            if (
              ordersData.orderStatus.toLowerCase() === 'on the way' &&
              ordersData.shipmentTrackingDetails.status.toLowerCase() === 'failed_delivery'
            )
              isDeliveryFailed = true;

            if (ordersData.orderStatus.toLowerCase() === 'delivered') isReturnInitiated = true;
          }
        }
      }
    }

    if (isPlatformBrowser(this.platform)) {
      let salesChannel = JSON.parse(localStorage.allSalesChannel);
      let object = salesChannel.filter(x => x.externalChannelId === ordersData.orderDetails.saleChannel);
      if (object && object[0].origin) {
        const trackOrderUrl = 'https://' + object[0].origin + '/';
        window.open(
          `${trackOrderUrl}${encodedString}` +
            '?authToken=' +
            authToken +
            '&proxyHost=' +
            proxyHost +
            '&email=' +
            email +
            '&key=' +
            key +
            '&orderItemId=' +
            ordersData.orderItemID +
            '&lastViewedItem=' +
            index +
            '&isDeliveryFailed=' +
            isDeliveryFailed +
            '&isCancelPickup=' +
            isCancelPickup +
            '&isCancelItem=' +
            isCancelItem +
            '&isReturnInitiated=' +
            isReturnInitiated +
            '&isReAttemptAction=' +
            isReAttemptAction +
            '&filters=' +
            encodeURIComponent(filters) +
            '&action=' +
            isupdateAddress,
          '_blank'
        );
      } else {
        const slug = object[0]?.channelSlug ? object[0].channelSlug : this.authService.getAccountSlug();
        if (environment.production) {
          if (environment.BASE_DOMAIN == '.auperator.co') {
            this.HttpRequest = 'https://';
          } else {
            this.HttpRequest = 'https://';
          }
          window.open(
            this.HttpRequest +
              slug +
              environment.CUSTOMER_PORTAL_DOMAIN +
              `${encodedString}` +
              '?authToken=' +
              authToken +
              '&proxyHost=' +
              proxyHost +
              '&email=' +
              email +
              '&key=' +
              key +
              '&orderItemId=' +
              ordersData.orderItemID +
              '&lastViewedItem=' +
              index +
              '&isDeliveryFailed=' +
              isDeliveryFailed +
              '&isCancelPickup=' +
              isCancelPickup +
              '&isCancelItem=' +
              isCancelItem +
              '&isReturnInitiated=' +
              isReturnInitiated +
              '&isReAttemptAction=' +
              isReAttemptAction +
              '&filters=' +
              encodeURIComponent(filters) +
              '&action=' +
              isupdateAddress,
            '_blank'
          );
        } else {
          window.open(
            'https://' +
              slug +
              environment.CUSTOMER_PORTAL_DOMAIN +
              `${encodedString}` +
              '?authToken=' +
              authToken +
              isupdateAddress +
              '&proxyHost=' +
              proxyHost +
              '&email=' +
              email +
              '&key=' +
              key +
              '&orderItemId=' +
              ordersData.orderItemID +
              '&lastViewedItem=' +
              index +
              '&isDeliveryFailed=' +
              isDeliveryFailed +
              '&isCancelPickup=' +
              isCancelPickup +
              '&isCancelItem=' +
              isCancelItem +
              '&isReturnInitiated=' +
              isReturnInitiated +
              '&isReAttemptAction=' +
              isReAttemptAction +
              '&filters=' +
              encodeURIComponent(filters) +
              '&action=' +
              isupdateAddress,
            '_blank'
          );
        }
      }
    }
  }

  downLoadInvoice(fileUrl) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(fileUrl, { headers: headers, responseType: 'blob' });
  }
  unHoldOrder(payload) {
    // console.log(payload);
    const apiUrl = 'v1/orders/mark-unhold/bulk';
    return this.http.post(apiUrl, payload);
  }
  public getRefersh(): Observable<any> {
    return this.orderRefresh.asObservable();
  }

  public setRefersh(message: any) {
    return this.orderRefresh.next(message);
  }

  public getLoader(): Observable<boolean> {
    return this.orderLoader.asObservable();
  }

  public setLoader(message: boolean) {
    return this.orderLoader.next(message);
  }

  public getUnHoldCount(): Observable<number> {
    return this.orderCount.asObservable();
  }

  public setUnHoldCount(message: number) {
    return this.orderCount.next(message);
  }

  public removeMsg() {
    if (this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
  }

  public setMsg(msg, type) {
    this.snackbarRef = this.snackbar.open(msg, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['error-snackbar-draft', 'newshadow', type],
      duration: 1500
    });
  }

  getChangeOrderData(id) {
    const apiurl = 'v2/orders/search?page=0&perPage=50&index=prod&filter=vendorOrderNumber:' + encodeURIComponent(id);
    return this.http.get(apiurl);
  }

  public getLocationLoader(): Observable<any> {
    return this.orderLocationLoader.asObservable();
  }

  public setLocationLoader(message: any) {
    return this.orderLocationLoader.next(message);
  }

  saveSplitOrder(obj) {
    const apiurl = 'v1/splitOrder';
    return this.http.post(apiurl, obj);
  }

  public getSplitLoader(): Observable<any> {
    return this.orderSplitLoader.asObservable();
  }

  public setSplitLoader(message: any) {
    return this.orderSplitLoader.next(message);
  }

  /**@desciption Open Draft Return Export Dialog */
  public openDraftReturnDialog(externalNavigation) {
    const type = externalNavigation
      ? ''
      : { prevDate: '', filterType: '', currentFilter: '', type: 'draft_return_report' };
    setTimeout(() => {
      const dialogRef = this.dialog.open(ExportOrdersComponent, {
        panelClass: 'custom-modalbox-full',
        data: type
      });
    });
  }
}
