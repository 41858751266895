import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, throwError } from 'rxjs';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as mime from 'mime';
import { map } from 'rxjs/operators';
import { ToastNotificationsService } from './toast-notifications.service';

const client = filestack.init(environment.FILESTACK_APIKEY);

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isCreateConsignmentClickEnable: boolean = true;
  constructor(private http: HttpClient, private toastNotificationService: ToastNotificationsService) {}

  private count = new BehaviorSubject<any>('');
  storedCount = this.count.asObservable();
  private queryParams = new BehaviorSubject<any>('');
  storedQP = this.queryParams.asObservable();
  private warehouse = new BehaviorSubject<any>('');
  updateWarehouse = this.warehouse.asObservable();

  loaderSource = new Subject<{ status: boolean }>();
  loaderListener = this.loaderSource.asObservable();
  navHeader; //For Showing Nav Header in Product List Page While Redirection From Dashboard
  // bbDetails;

  showHideLoader(status: boolean) {
    this.loaderSource.next({ status: status });
  }

  /**
   * @author Saher Shaukat
   * @description marks form as touched
   * @param {FormGroup} formGroup formgroup to be marked as touched
   */
  markFormGroupTouched(formGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  // uploadDocument(acceptedFormats) {
  //   return client.pick({
  //     maxFiles: 1,
  //     //  accept: ['.pdf', '.jpg', '.png', '.jpeg', '.txt', '.xls', 'xlsx', '.csv'], // formats
  //     accept: acceptedFormats, // formats

  //     uploadInBackground: false,
  //     startUploadingWhenMaxFilesReached: true,
  //     fromSources: ['local_file_system', 'imagesearch', 'googledrive', 'dropbox'],
  //     onOpen: () => {},
  //     rejectOnCancel: true,
  //     storeTo: {
  //       location: 'gcs',
  //       path: '/import_jobs/'
  //     }
  //   });
  // }
  handleUploadFile(uploadedFiles, supportedTypes, bucketName, maxFileSize = 5) {
    const uploadedFileSizeInMb = uploadedFiles.size / (1024 * 1024);
    if (uploadedFileSizeInMb > maxFileSize) {
      return throwError(`Maximum file size is ${maxFileSize} MB`);
    }

    if (!supportedTypes.includes(uploadedFiles.type)) {
      return throwError('File format is not supported. ' + supportedTypes[supportedTypes.length - 1]);
    }
    return this.uploadFile(uploadedFiles, bucketName);
  }

  handleUploadFileBB(uploadedFiles, supportedFormats, bucketName, maxFileSize = 10) {
    const uploadedFileSizeInMb = uploadedFiles.size / (1024 * 1024);
    if (uploadedFileSizeInMb > maxFileSize) {
      return throwError(`Maximum file size is ${maxFileSize} MB`);
    }
    if (!this.isUploadedFileValid(uploadedFiles.name, supportedFormats)) {
      const fileFormatsString = supportedFormats.join(', ');
      return throwError(`File Format is not supported, supported file formats are ${fileFormatsString}`);
    }
    return this.uploadFile(uploadedFiles, bucketName);
  }

  isUploadedFileValid(fileName, supportedFormats) {
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex === -1) {
      return false;
    }
    const extension = fileName.substring(lastIndex + 1).toLowerCase();
    const isSupported = supportedFormats.some(format => format.toLowerCase() === extension.toLowerCase());
    if (!isSupported) {
      return false;
    }
    return true;
  }

  uploadFile(uploadedFiles, bucketName) {
    const formData = new FormData();
    formData.append('files', uploadedFiles);
    formData.append('filename', uploadedFiles.name);
    formData.append('mimetype', uploadedFiles.type);
    formData.append('size', uploadedFiles.size);
    formData.append('store_path', bucketName);

    let url;
    if (environment.API_URL.includes('myeshopbox')) {
      url = 'exports/api/esb/v1/upload-template';
    } else {
      url = 'exports-staging/api/esb/v1/upload-template';
    }
    return this.http.post(url, formData).pipe(
      map(response => {
        if (response && response['Error']) {
          this.toastNotificationService.showErrorToast(response['Error']);
          return null;
        }
        return response;
      })
    );
  }

  beforeUnload() {
    window.onbeforeunload = function(event) {
      event.returnValue = 'Reloading might cause loss of Data.';
    };
  }

  updateCounts(data) {
    this.count.next(data);
  }

  updateQueryParams(data) {
    this.queryParams.next(data);
  }

  updateWareHouseDetails(data) {
    this.warehouse.next(data);
  }

  getFileMetaData(fileKey) {
    return client.metadata(fileKey);
  }

  getFileMetaDataCustom(data) {
    let mimetype;
    let response = data.split('/').pop();
    let fileName = response;
    const imageExt = response.includes('?')
      ? response
          .split('.')
          .pop()
          .split('?')[0]
      : response.split('.').pop();
    mimetype = mime.getType(imageExt);
    let metaDataObject = { fileName: fileName, fileData: data, fileType: mimetype };
    return metaDataObject;
  }
}
