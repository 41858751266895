import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-export-date-range',
  templateUrl: './export-date-range.component.html',
  styleUrls: ['./export-date-range.component.scss']
})
export class ExportDateRangeComponent implements OnInit {
  max: any = new Date();
  min: any = new Date(
    moment()
      .subtract(179, 'days')
      .startOf('day')
      .valueOf()
  );
  @Output() validOrderRange = new EventEmitter();
  orderCreatedRangeForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createOrderRangeForm();
  }

  /** @description Order Range Form */
  public createOrderRangeForm() {
    this.orderCreatedRangeForm = this.formBuilder.group(
      {
        startDate: ['', Validators.required],
        endDate: ['', [Validators.required]]
      },
      { validator: this.checkDates }
    );
    this.orderCreatedRangeForm.statusChanges.subscribe(res => {
      if (res === 'VALID') {
        this.validOrderRange.emit(this.orderCreatedRangeForm);
      }
    });
  }

  /** @description Validate Start and End Date */
  public checkDates(group: FormGroup) {
    if (group.controls.endDate.value) {
      const diffTime = Math.abs(group.controls.endDate.value - group.controls.startDate.value);
      if (group.controls.startDate.value > group.controls.endDate.value) return { notValidStartDate: true };
      else if (Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 180) return { notValid: true };
      else return null;
    }
    return null;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
