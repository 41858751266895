import { Pipe, PipeTransform } from '@angular/core';
import { BuildingBlocksService } from 'src/app/building-blocks/service/building-blocks.service';
import { isArray, isNullOrUndefined } from './common-util';
import * as moment from 'moment';

@Pipe({
  name: 'formatDateHasTime'
})
export class FormatDateHasTimePipe implements PipeTransform {
  constructor(private bbLocalStorageService: BuildingBlocksService) {}

  transform(dateTime, buildingBlockHandleBar, handleBar): unknown {
    if (isNullOrUndefined(dateTime) || dateTime == '') {
      return '-';
    }
    dateTime = isArray(dateTime) && dateTime.length > 1 ? dateTime[dateTime.length - 1] : dateTime;
    let fieldHandleBar = this.isDateLookUp(handleBar, buildingBlockHandleBar);
    // feature that wherever we get hasTime = true in DATE_PICKER field show time with date
    let bbFieldHandleBar = this.bbLocalStorageService
      .getAllFieldsOfBuildingBlock(buildingBlockHandleBar)
      .filter(x => x.handleBar == fieldHandleBar);

    var utc = moment.utc(dateTime).toDate();
    if (
      bbFieldHandleBar.length > 0 &&
      ((!isNullOrUndefined(bbFieldHandleBar[0].validations.hasTime) && bbFieldHandleBar[0].validations.hasTime) ||
        fieldHandleBar.includes(' at'))
    ) {
      var local = moment(utc)
        .local()
        .format('DD-MMM-YY hh:mm A');
      return local;
    } else {
      var local = moment(utc)
        .local()
        .format('DD-MMM-YY');
      return local;
    }
  }

  isDateLookUp(fieldHandleBar, bbHandleBar) {
    let fieldData = this.bbLocalStorageService.getFieldWithID(fieldHandleBar, bbHandleBar);
    if (fieldData['advanceFieldType'] === 'LOOK_UP') {
      return this.isDateLookUp(fieldData['validations']['lookUpFromField'], fieldData['validations']['referenceSlug']);
    } else {
      return fieldData['handleBar'];
    }
  }
}
