import { Action } from '@ngrx/store';

export enum InstallAppListActionsTypes {
  GET_INSTALL_APP_LIST = '[INSTALL APP LIST] GET INSTALL APP LIST',
  GET_INSTALL_APP_LIST_SUCCESS = '[INSTALL APP LIST] GET INSTALL APP LIST SUCCESS',
  GET_INSTALL_APP_LIST_FAILURE = '[INSTALL APP LIST] GET INSTALL APP LIST FAILURE',
  GET_INSTALL_APP_LIST_DETAIL = '[INSTALL APP LIST] GET INSTALL APP LIST DETAIL',
  GET_INSTALL_APP_LIST_DETAIL_SUCCESS = '[INSTALL APP LIST] GET INSTALL APP LIST DETAIL SUCCESS',
  GET_INSTALL_APP_LIST_DETAIL_FAILURE = '[INSTALL APP LIST] GET INSTALL APP LIST DETAIL FAILURE',
  GET_INSTALL_APP_LIST_SEARCH = '[INSTALL APP LIST] GET INSTALL APP LIST SEARCH',
  UNINSTALL_APP = '[INSTALL APP LIST] UNINSTALL APP',
  UNINSTALL_APP_SUCCESS = '[INSTALL APP LIST] UNINSTALL APP SUCCESS',
  UNINSTALL_APP_FAILURE = '[INSTALL APP LIST] UNINSTALL APP SUCCESS',
  SHOW_PROGRESS_SPINNER = '[INSTALL APP LIST] Show progress spinner',
  HIDE_PROGRESS_SPINNER = '[INSTALL APP LIST] Hide progress spinner',
  GET_CONNECTION_BY_ID_DETAIL = '[INSTALL APP LIST] GET CONNECTION BY ID DETAIL',
  GET_CONNECTION_BY_ID_DETAIL__SUCCESS = '[INSTALL APP LIST] GET CONNECTION BY ID DETAIL SUCCESS',
  GET_CONNECTION_BY_ID_DETAIL__FAILURE = '[INSTALL APP LIST] GET CONNECTION BY ID DETAIL FAILURE',
  GET_FULLFILMENT_CENTER_LIST = '[FULLFILMENT CENTER LIST] GET FULLFILMENT LIST',
  GET_FULLFILMENT_CENTER_LIST_SUCCESS = '[FULLFILMENT CENTER LIST] GET FULLFILMENT LIST SUCCESS',
  GET_FULLFILMENT_CENTER_LIST_FAILURE = '[FULLFILMENT CENTER LIST] GET FULLFILMENT LIST FAILURE',
  GET_CHANNEL_LIST = '[CHANNEL LIST] GET CHANNEL LIST',
  GET_CHANNEL_LIST_SUCCESS = '[CHANNEL LIST] GET CHANNEL LIST SUCCESS',

  GET_CHANNEL_LIST_FAILURE = '[CHANNEL LIST] GET CHANNEL LIST FAILURE',
  GET_FIELD_PAGE_LIST = '[FIELD PAGE LIST] GET FIELD PAGE LIST',
  GET_FIELD_PAGE_LIST_SUCCESS = '[FIELD PAGE LIST] GET FIELD PAGE LIST SUCCESS',
  GET_FIELD_PAGE_LIST_FAILURE = '[FIELD PAGE LIST] GET FIELD PAGE LIST FAILURE',

  GET_CHANNEL_LIST_LIST_FAILURE = '[CHANNEL LIST] GET CHANNEL LIST FAILURE',
  GET_CONTENTFULL_BY_ID_DETAIL = '[INSTALL APP LIST] GET CONTENTFULL CONNECTION BY ID DETAIL',
  GET_CONTENTFULL_BY_ID_DETAIL__SUCCESS = '[INSTALL APP LIST] GET CONTENTFULL BY ID DETAIL SUCCESS',
  GET_CONTENTFULL_BY_ID_DETAIL__FAILURE = '[INSTALL APP LIST] GET CONTENTFULL BY ID DETAIL FAILURE',
  GET_STEPLIST_DETAIL = '[INSTALL APP LIST] GET STEPLIST DETAIL',
  GET_STEPLIST_DETAIL__SUCCESS = '[INSTALL APP LIST] GET STEPLIST DETAIL SUCCESS',
  GET_STEPLIST_DETAIL__FAILURE = '[INSTALL APP LIST] GET STEPLIST DETAIL FAILURE',
  GET_DISCONNECT_BY_ID_DETAIL = '[INSTALL APP LIST] GET DISCONNECT BY ID DETAIL',
  GET_DISCONNECT_BY_ID_DETAIL_SUCCESS = '[INSTALL APP LIST] GET DISCONNECT BY ID DETAIL SUCCESS',
  GET_DISCONNECT_BY_ID_DETAIL_FAILURE = '[INSTALL APP LIST] GET DISCONNECT BY ID DETAIL FAILURE',

  GET_CATOGRY_COUNT_LIST = '[CATOGRY COUNT LIST] GET CATOGRY COUNT LIST',
  GET_CATOGRY_COUNT_LIST_SUCCESS = '[CATOGRY COUNT LIST] GET CATOGRY COUNT LIST SUCCESS',
  GET_CATOGRY_COUNT_LIST_FAILURE = '[CATOGRY COUNT LIST] GET CATOGRY COUNT LIST FAILURE',

  GET_NYKAA_PRODUCT_SYNC = '[NYKAA PRODUCT SYNC LIST] GET NYKAA PRODUCT SYNC LIST',
  GET_NYKAA_PRODUCT_SYNC_SUCCESS = '[NYKAA PRODUCT SYNC LIST] GET NYKAA PRODUCT SYNC LIST SUCCESS',
  GET_NYKAA_PRODUCT_SYNC_FAILURE = '[NYKAA PRODUCT SYNC  LIST] GET NYKAA PRODUCT SYNC LIST FAILURE'
}

//INSTALL APP LIST
export class GetInstallAppList implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST;
  constructor(public payload: any = null) {}
}
export class GetInstallAppListSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetInstallAppListFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetInstallAppListDetail implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL;
  constructor(public listId: string) {}
}
export class GetInstallAppListDetailSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL_SUCCESS;
  constructor(public payload: any) {}
}
export class GetInstallAppListDetailFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL_FAILURE;
  constructor(public payload: any) {}
}
export class UpdateInstalledAppListSearch implements Action {
  readonly type = InstallAppListActionsTypes.GET_INSTALL_APP_LIST_SEARCH;
  constructor(public payload: string) {}
}
export class UninstallApp implements Action {
  readonly type = InstallAppListActionsTypes.UNINSTALL_APP;
  constructor(public appInstallationId: any, public title: string) {}
}
export class UninstallAppSuccess implements Action {
  readonly type = InstallAppListActionsTypes.UNINSTALL_APP_SUCCESS;
  constructor(public payload: any) {}
}
export class UninstallAppFailure implements Action {
  readonly type = InstallAppListActionsTypes.UNINSTALL_APP_FAILURE;
  constructor(public payload: any) {}
}
export class ShowProgressSpinner implements Action {
  readonly type = InstallAppListActionsTypes.SHOW_PROGRESS_SPINNER;
  constructor() {}
}
export class HideProgressSpinner implements Action {
  readonly type = InstallAppListActionsTypes.HIDE_PROGRESS_SPINNER;
  constructor() {}
}
// Get Connection By Id
export class GetConnectionByIdDetail implements Action {
  readonly type = InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL;
  constructor(public payload: any) {}
}
export class GetConnectionByIdDetailSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL__SUCCESS;
  constructor(public payload: any) {}
}
export class GetConnectionByIdDetailFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL__FAILURE;
  constructor(public payload: any) {}
}
// Get Contentful Detils By Id
export class GetContentByIdDetail implements Action {
  readonly type = InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL;
  constructor(public payload: any) {}
}
export class GetContentByIdDetailSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL__SUCCESS;
  constructor(public payload: any) {}
}
export class GetContentByIdDetailFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL__FAILURE;
  constructor(public payload: any) {}
}

// Get Step List
export class GetStepListdDetail implements Action {
  readonly type = InstallAppListActionsTypes.GET_STEPLIST_DETAIL;
  constructor(public payload: any) {}
}
export class GetStepListdDetailSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_STEPLIST_DETAIL__SUCCESS;
  constructor(public payload: any) {}
}
export class GetStepListdDetailFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_STEPLIST_DETAIL__FAILURE;
  constructor(public payload: any) {}
}

//Get fullfilment channels
export class GetFullfilmnetCenterList implements Action {
  readonly type = InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST;
  constructor(public payload: any) {}
}
export class GetFullfilmnetCenterListSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetFullfilmnetCenterListFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetChannelListById implements Action {
  readonly type = InstallAppListActionsTypes.GET_CHANNEL_LIST;
  constructor(public payload: any) {}
}
export class GetChannelListByIdSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_CHANNEL_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetChannelListByIdFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_CHANNEL_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetFieldPageList implements Action {
  readonly type = InstallAppListActionsTypes.GET_FIELD_PAGE_LIST;
  constructor(public payload: any) {}
}
export class GetFieldPageListSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_FIELD_PAGE_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetFieldPageListFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_FIELD_PAGE_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetDisconnectByIdDetail implements Action {
  readonly type = InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL;
  constructor(public payload: any, public payloadDetail: any) {}
}
export class GetDisconnectByIdDetailSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL_SUCCESS;
  constructor(public payload: any) {}
}
export class GetDisconnectByIdDetailFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL_FAILURE;
  constructor(public payload: any) {}
}
export class GetCatogryCountList implements Action {
  readonly type = InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST;
  constructor(public payload: any) {}
}
export class GetCatogryCountListSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetCatogryCountListFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetNykaaProductSync implements Action {
  readonly type = InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC;
  constructor() {}
}
export class GetNykaaProductSyncSuccess implements Action {
  readonly type = InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC_SUCCESS;
  constructor(public payload: any) {}
}
export class GetNykaaProductSyncFailure implements Action {
  readonly type = InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC_FAILURE;
  constructor(public payload: any) {}
}
export type InstalledAppsActions =
  | GetInstallAppList
  | GetInstallAppListSuccess
  | GetInstallAppListFailure
  | GetInstallAppListDetail
  | GetInstallAppListDetailSuccess
  | GetInstallAppListDetailFailure
  | UpdateInstalledAppListSearch
  | UninstallApp
  | UninstallAppSuccess
  | UninstallAppFailure
  | ShowProgressSpinner
  | HideProgressSpinner
  | GetConnectionByIdDetail
  | GetConnectionByIdDetailSuccess
  | GetConnectionByIdDetailFailure
  | GetFullfilmnetCenterList
  | GetFullfilmnetCenterListFailure
  | GetFullfilmnetCenterListSuccess
  | GetChannelListById
  | GetChannelListByIdSuccess
  | GetChannelListByIdFailure
  | GetFieldPageList
  | GetFieldPageListSuccess
  | GetFieldPageListFailure
  | GetContentByIdDetail
  | GetContentByIdDetailSuccess
  | GetContentByIdDetailFailure
  | GetStepListdDetail
  | GetStepListdDetailSuccess
  | GetStepListdDetailFailure
  | GetDisconnectByIdDetail
  | GetDisconnectByIdDetailSuccess
  | GetDisconnectByIdDetailFailure
  | GetCatogryCountList
  | GetCatogryCountListSuccess
  | GetCatogryCountListFailure
  | GetNykaaProductSync
  | GetNykaaProductSyncSuccess
  | GetNykaaProductSyncFailure;
