import { Component, OnInit, OnDestroy, Renderer2, HostListener, EventEmitter, Output } from '@angular/core';
import {
  ROUTES,
  ROUTESDATA,
  ASSETS,
  CREATE_PICKLIST,
  MANAGE_INVENTORY,
  PROCESS_B2B_ORDERS,
  PROCESS_RETURN,
  PROCESS_NO_SCAN_RETURN,
  RETURN_NO_SCAN_PARAM
} from 'src/app/constants/routes';
import {
  AuthActionTypes,
  GetActivityToken,
  GetSalesChannelData,
  GetCurrentLoggedInUserData,
  GetOrdersFiltersV2Data
} from 'src/app/auth/actions/auth.actions';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
import { User, UserAccountInfo } from 'src/app/auth/auth.state';
import { AlertService, AuthService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingblockService } from '../../shared/services/buildingblock.service';
import { LocalStorageChangeDetectionService } from '../../shared/services/detect-local-storage-change.service';
import { Intercom } from 'ng-intercom';
import { FilterPusherService } from 'src/app/filter-widget-new/service/filter-pusher.service';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
import * as moment from 'moment';
import { ProductService } from 'src/app/shared/services/product.service';
import { BuildingBlocksService } from 'src/app/building-blocks/service/building-blocks.service';
import { MatDialog } from '@angular/material/dialog';
import { getCurrentLoggedInUserData } from 'src/app/auth/reducers/auth.selectors';
import { isArray } from 'src/app/records/utils/common-util';
import { CookieService } from 'ngx-cookie-service';
import { ZohoService } from 'src/app/tickets/services/zoho-service.service';
import { OrdersServiceV2 } from 'src/app/orderV2/serviceV2/orders.service';
import { DataStoreService } from 'src/app/shared/services/data-store.service';
import { GetWorkspaceSettingsData } from 'src/app/settings/store/actions/settings.actions';
import { getWorkspaceSettings } from 'src/app/settings/store/selectors/settings.selector';
import { environment } from 'src/environments/environment';
import { FilterLocationAccessPipe } from 'src/app/shared/pipes/location-access.pipe';
import { B2B_ORDERS_WORKSPACES, B2B_PAGE_ROUTES } from 'src/app/new-b2b-orders/constants/b2b-orders.constant';
import { NewSegmentService } from 'src/app/shared/services/segment-with-count/new-segment.service';
import { InstallAppListState } from 'src/app/apps-automation/store/state/install-app-list.state';

import * as fromSelector1 from '../../../app/apps-automation/store/selectors/install-app-list.selector';
import { GetInstallAppList } from 'src/app/apps-automation/store/actions/install-app-list.action';
import { getInstalledAppsListLoaded } from '../../../app/apps-automation/store/selectors/install-app-list.selector';
import * as returnFromSelector from 'src/app/shared/component/segment-with-count/store/selectors/segment.selectors';
import { GetNoScanReturnFiltersData } from 'src/app/shared/component/segment-with-count/store/actions/segment.actions';
@Component({
  selector: 'top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  @Output() openSidebarMenu: any = new EventEmitter();
  ROUTES = ROUTES;
  NEWROUTES = ROUTESDATA;
  ASSETS = ASSETS;
  CREATE_PICKLIST = CREATE_PICKLIST;
  MANAGE_INVENTORY = MANAGE_INVENTORY;
  PROCESS_B2B_ORDERS = PROCESS_B2B_ORDERS;
  PROCESS_RETURN = PROCESS_RETURN;
  PROCESS_NO_SCAN_RETURN = PROCESS_NO_SCAN_RETURN;
  userSubscription$: Subscription;
  accountSubscription$: Subscription;
  user: User;
  accountDetails: UserAccountInfo;
  buildingBlockDetails: any;
  buildingBlockName: string;
  selectedBuildingBlock: any;
  bbHandleBar: any;
  bbDetails: any;
  buildingBlocksName: any;
  filesMenuClicked: boolean = false;
  workspaceStatus: any;
  currentWorkspace: string = '';
  enterMouse: boolean;
  tempVar: any;
  fulfillmentCenterData: any = [];
  salesChannelSubscription: Subscription;
  filtersSubscription: Subscription;
  productFilterSubscription$: Subscription;
  customfieldSubscription$: Subscription;
  dropdownValuesSubscription$: Subscription;
  ordersFilterSubscription$: Subscription;
  ordersFacetsSusbcription$: Subscription;
  callSelectedFcSubscription$: Subscription;
  tempArr = [];
  currentRoute: string;
  isLoader: boolean = false;

  //loader for bb sidebar
  flexAccountSlug: any;
  accountList = [];
  clientWorkspaceList = [];
  allAccountList: any;
  hasLocation: boolean = false;
  hasLocationAccess: boolean = false;
  accountLocationSubscription$: Subscription;
  loggedInUserSubscription$: Subscription;
  loggedInUserData: any;
  searchWorkspace: string;
  workspaceName: string;
  hasWorkspaceAccess: boolean;
  settingDropdownMenu: boolean = true;
  flexError: boolean;

  //new
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  isMatMenu3Open = false;
  prevButtonTrigger;
  isMatMenu3Close = false;
  userEmail: any;
  showItemBarcodeBB: any;
  id: any;
  worksapceSettingData$: Subscription;
  workflowSettings: any;
  count: number = 0;
  segmentSegragationArray: string[] = ['To pack', 'To manifest', 'On hold', 'All'];
  selectedHandleBar: any;
  loadingSetting: boolean;
  private CHANNEL = environment.pusher_workflow_settings.pusher_workflow_settings_channel;
  private EVENT = environment.pusher_workflow_settings.pusher_workflow_settings_event;
  private pusher: any;
  hideNewPaymentMenu = ['vitaminplanet'];
  myInterval: any;
  iscalled: boolean;
  createB2BOrderWorkspaces = B2B_ORDERS_WORKSPACES;
  openNewB2bOrderComponent: boolean;
  b2bRouteLink = B2B_PAGE_ROUTES.ALL_B2B_ORDERS;
  b2bParams = { page: 1, per_page: 10 };
  itemToOpen: any;
  isEshopUser: boolean;
  showConsignmentDropdown = false;
  showRemoveInventoryDropdown = false;
  showSelfFulfiment = false;
  filteredLocations: any;
  public workSpaceReportMenu: any = [];
  isAppDisconneted: boolean = false;
  showStockTransferReports: any = [
    'testingggurmukhsku',
    'testinggdelhitestfc',
    'gurgaonfc',
    'gurgaonfc_2',
    'ymum',
    'mumbaifc',
    'mumbaivelocityfc_2',
    'alternateworkflowitemlevel',
    'alternateworkflowskudevice',
    'testingggurmukhskunew',
    'testinggdelhitestfcnew',
    'fggn',
    'esb013fc',
    'xmum',
    'yggn',
    'alternateworkflowitemlevelnew',
    'alternateworkflowskudevicenew'
  ];
  noScanReturnFilterSubscription$: Subscription;

  constructor(
    private store: Store<{ auth }>,
    private settingStore: Store<{ settings }>,
    public authService: AuthService,
    private router: Router,
    private buildingblockService: BuildingblockService,
    private lsd: LocalStorageChangeDetectionService,
    private intercom: Intercom,
    private filterPusherService: FilterPusherService,
    private filterHelperService: FilterHelperService,
    private productService: ProductService,
    private bbLocalStorageService: BuildingBlocksService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private cookieService: CookieService,
    private zohoService: ZohoService,
    private ordersService: OrdersServiceV2,
    private route: ActivatedRoute,
    private dataStoreService: DataStoreService,
    private locationAccessPipe: FilterLocationAccessPipe,
    private newSegmentService: NewSegmentService,
    private storeApps: Store<{ installedAppList: InstallAppListState }>
  ) {
    const accountSlugFrmParam = this.route.snapshot.queryParams['location'];
    if (accountSlugFrmParam && !this.authService.getFlexSlug()) this.authService.setFlexSlug(accountSlugFrmParam, true);
    this.workspaceStatus = localStorage.getItem('workspaceStatus');
    this.currentRoute = this.router.url;
    this.getAccountAndWarehouseData();
    this.getProductFiltersData();
    this.getCustomFields();
    this.getOrdersFiltersData();
    this.getNoScanReturnFiltersData();
    this.getActivityToken();
    this.getSalesChannelData();
    this.pusher = this.authService.pusher;
    let channel = this.pusher.subscribe(this.CHANNEL);
    channel.bind(this.EVENT, response => {
      for (let status in response) {
        if (
          status == 'WORKFLOW SETTINGS UPDATED' &&
          this.authService.getFlexSlug() == response['WORKFLOW SETTINGS UPDATED']['accountSlug']
        ) {
          this.settingStore.dispatch(new GetWorkspaceSettingsData());
        }
      }
    });
    this.workSpaceReportMenu = this.sortReportMenu();
  }

  //On Scroll hide tooltip from navigation
  tooltipShow = false;
  @HostListener('document:wheel', ['$event'])
  scrollHandler(event) {
    if (this.tooltipShow) {
      this.tooltipShow = false;
    } else {
      this.tooltipShow = true;
      setTimeout(() => {
        this.tooltipShow = false;
      }, 250);
    }
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
    this.isMatMenu3Close = false;
  }

  menu2Leave(trigger1, trigger2) {
    setTimeout(() => {
      if (!this.isMatMenu3Open) {
        if (this.isMatMenu2Open) {
          trigger1.closeMenu();
          this.isMatMenuOpen = false;
          this.isMatMenu2Open = false;
          this.enteredButton = false;
        } else {
          this.isMatMenu2Open = false;
          trigger2.closeMenu();
        }
      }
    }, 100);
  }

  menu3enter() {
    this.isMatMenu3Open = true;
    this.isMatMenu3Close = true;
  }

  menu3Leave(trigger1, trigger2, trigger3) {
    setTimeout(() => {
      this.isMatMenu3Open = false;
      trigger3.closeMenu();

      if (this.isMatMenu3Close) {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
        this.isMatMenuOpen = false;
        trigger1.closeMenu();
      }
    }, 100);
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  ngOnInit() {
    this.newSegmentService.fetchAllSegment();
    this.getFeildConnectionData();
    this.store.dispatch({ type: AuthActionTypes.GET_USER_PROFILE });
    this.currentWorkspace = this.authService.getAccountSlug();
    this.workspaceName = this.authService.getAccountName();
    this.userSubscription$ = this.store.select(fromSelector.getCurrentUser).subscribe((result: any) => {
      if (result) {
        //Canny User log
        (window as any).Canny('identify', {
          appID: environment.canny.comapnyId,
          user: {
            email: result.email,
            name: result.firstName + ' ' + result.lastName,
            id: result.id,
            created: result.createdAt
          }
        });
      }
      if (result && result?.hashHmac) {
        this.userEmail = result['email'].toLowerCase();
        this.lsd.setItem('email', this.userEmail);
        this.lsd.setItem('workspace', this.authService.getAccountSlug());
        this.lsd.setItem('workspaceName', this.workspaceName);
        this.lsd.setItem(
          'fullName',
          `${result['firstName'] ? result['firstName'] : ''} ${result['lastName'] ? result['lastName'] : ''}`
        );
        this.user = result;
        if (this.userSubscription$) this.userSubscription$.unsubscribe();
      }
    });
    this.isEshopUser = localStorage.getItem('email') ? localStorage.getItem('email').includes('@eshopbox.com') : false;
    // this.accountSubscription$ = this.store.select(fromSelector.getCurrentAccount).subscribe(result => {
    //   if (result) {
    //     this.accountDetails = result;
    //   }
    // });
    let zohoContact = this.cookieService.get('zohoContact');
    let zohoAccount = this.cookieService.get('zohoAccount');
    if (!zohoContact) {
      this.zohoService.setZohoContactToCookie();
      this.zohoService.setZohoAccountToCookie(this.currentWorkspace);
      setTimeout(() => {
        this.zohoService.updateContactwithCurrentAccount();
      }, 10000);
    } else if (!zohoAccount || this.authService.getAccountSlug() !== JSON.parse(zohoAccount).accountName) {
      this.zohoService.setZohoAccountToCookie(this.currentWorkspace);
    }
    this.id = setInterval(() => {
      const iframe = document.getElementById('canny-changelog-iframe');
      if (iframe) {
        iframe.addEventListener('mouseout', function(event) {
          iframe.style.display = 'none';
        });
        clearInterval(this.id);
      }
    }, 5000);
    this.openNewB2bOrderComponent = this.createB2BOrderWorkspaces.includes(this.currentWorkspace);
    this.callSelectedFcSubscription$ = this.buildingblockService.getCallSelectedFC().subscribe((url: any) => {
      if (url) {
        this.selectedFC(url);
      }
    });
    this.authService.getWorkspaceList().subscribe((ws: any) => {
      if (ws?.data && ws?.data.length > 0) {
        this.filteredLocations = ws.data.filter(
          x => x.workspaceType == 'warehouse' && x.parentClientWorkspaceSlug === this.currentWorkspace
        );
        this.showHide(this.NEWROUTES, 1);
        [
          this.ASSETS,
          this.CREATE_PICKLIST,
          this.MANAGE_INVENTORY,
          this.PROCESS_B2B_ORDERS,
          this.PROCESS_RETURN
        ].forEach(obj => {
          this.showHide(obj, 2);
        });
      }
    });
    this.store.pipe(select(fromSelector.getAccountList)).subscribe(async result => {
      this.filteredLocations = result.accountListData?.data.filter(
        x => x.workspaceType == 'warehouse' && x.parentClientWorkspaceSlug === this.currentWorkspace
      );
      this.showHide(this.NEWROUTES, 1);
      [this.ASSETS, this.CREATE_PICKLIST, this.MANAGE_INVENTORY, this.PROCESS_B2B_ORDERS, this.PROCESS_RETURN].forEach(
        obj => {
          this.showHide(obj, 2);
        }
      );
    });
  }

  async getFeildConnectionData(): Promise<any> {
    return new Promise<void>(resolve => {
      this.storeApps.pipe(select(fromSelector1.getInstalledAppsList)).subscribe(res => {
        if (res && res.data) {
          res.data.forEach(app => {
            if (app.appInstallationId && Array.isArray(app.connections)) {
              app.connections.forEach(connection => {
                if (connection.isActive === 'inactive') {
                  this.isAppDisconneted = true;
                }
              });
            }
          });
        } else {
          // if (!res.error && (!res || !res.getInstalledAppsListLoading)) {
          //   this.store.dispatch(new GetInstallAppList());
          // }
          if (!getInstalledAppsListLoaded && !res) {
            this.store.dispatch(new GetInstallAppList());
          }
        }
      });
    });
  }

  menuOpen;

  onClickMenuOpenEvent() {
    this.menuOpen = true;
  }
  onHoverMenuOpenEvent() {
    this.menuOpen = false;
  }

  openSideMenu(routeDetails) {
    this.openSidebarMenu.emit(true);
    if (routeDetails.urlType == 'FLEX') {
      this.selectedFC(routeDetails.URL);
    } else {
      this.router.navigate([routeDetails.URL], { queryParams: routeDetails.queryParams });
    }
  }

  getLoggedInUserDetails() {
    this.loggedInUserSubscription$ = this.store.pipe(select(getCurrentLoggedInUserData)).subscribe(response => {
      if (response?.currentUser && isNaN(response?.currentUser)) {
        this.loggedInUserData = response.currentUser;
        this.authoriseUser();
        // if (this.loggedInUserSubscription$) this.loggedInUserSubscription$.unsubscribe();
      } else {
        if (this.authService.getAuthToken() && !response.currentUser && !response.loading && !response.loaded) {
          this.store.dispatch(new GetCurrentLoggedInUserData());
        }
      }
    });
  }

  setAccountDetails(accountList) {
    const accountSlug = this.authService.getAccountSlug();
    if (accountList.length > 0)
      this.accountList = accountList.filter(
        x => x.parentClientWorkspaceSlug && x.parentClientWorkspaceSlug == accountSlug
      );
    this.flexAccountSlug = this.authService.getFlexSlug();
    this.allAccountList = accountList;
    this.assignDefaultLocation();
    this.clientWorkspaceList = this.allAccountList.filter(
      acc => acc['userAccountMappingStatus'] !== '2' && (!acc['workspaceType'] || acc['workspaceType'] == 'client')
    );
    if (!this.workspaceName) {
      const currentWorkspaceObj = this.allAccountList.find(
        acc => acc['accountSlug'] == this.authService.getAccountSlug()
      );
      if (currentWorkspaceObj) this.workspaceName = currentWorkspaceObj.workspace;
    }
    const condition = (element: any) => element.accountSlug === accountSlug;
    const index = this.clientWorkspaceList.findIndex(condition);
    if (index !== -1) {
      const element = this.clientWorkspaceList.splice(index, 1)[0];
      this.clientWorkspaceList.unshift(element);
    }
  }

  /**@description Method to Get Activity Token From Backend */
  public getActivityToken() {
    this.store.dispatch(new GetActivityToken());
    this.store.pipe(select(fromSelector.getActivityToken)).subscribe(token => {
      if (token && token['getStream']) {
        this.authService.setGetStreamToken(token['getStream']['getStreamToken']);
      }
    });
  }

  public onLogout() {
    this.authService.logout();
  }

  public setBuildingBlocksDetailsToLSArrangeSidebar() {
    this.loadingSetting = true;
    this.iscalled = true;
    this.settingStore.dispatch(new GetWorkspaceSettingsData());
    this.worksapceSettingData$ = this.settingStore.pipe(select(getWorkspaceSettings)).subscribe(response => {
      if (response?.getWorkspaceSettings && response.getWorkspaceSettingsLoaded) {
        if (this.worksapceSettingData$) this.worksapceSettingData$.unsubscribe();
        this.workflowSettings = response.getWorkspaceSettings;
        this.filterPusherService.getSegmentData('flex');
        this.buildingblockService.getCoreBuildingBlockDeatils().subscribe(coreData => {
          if (coreData?.data.length != 0) {
            this.buildingblockService.getCustomGroupedBulidingBlocks().subscribe(groupdata => {
              if (groupdata?.data.length != 0) {
                this.tempVar = groupdata.data;
                this.makeBuildingBlockListSequence();
                this.storeBuildingBlockData(groupdata, coreData.data);
                this.buildingblockService.setUpdatedBBDetails(true);
                this.loadingSetting = false;
                if (this.selectedHandleBar) this.selectedFC(this.selectedHandleBar);
              } else this.locationDataNotFound('Setting');
            });
          } else {
            this.locationDataNotFound('Setting');
          }
        });
      } else if (response?.getError && response.getWorkspaceSettingsLoaded) {
        this.loadingSetting = false;
      }
    });
  }

  async onWorkspaceSelection(workspace) {
    this.settingDropdownMenu = false;
    this.isLoader = true;
    let isFc: string;
    // For eshopbox user we'll get workspace permission when user select the workspace
    const isEshopOrOwner = this.authService.isEshopOrOwner();
    if (isEshopOrOwner) {
      try {
        isFc = this.checkFlexSlug(workspace.accountSlug);
        await this.authService.checkSessionEshopUser(workspace.accountSlug, isFc, true);
      } catch (error) {
        this.isLoader = false;
        return;
      }
    } else if (workspace.userAccountMappingStatus == '0') {
      await this.authService.checkSession();
    }
    if (!isEshopOrOwner) isFc = this.checkFlexSlug(workspace.accountSlug);
    this.authService.setIsBatching(workspace.isBatching);
    // Check if zoho account exists else create new account
    this.authService.setWorkspace(workspace.accountSlug);
    this.authService.setWorkspaceName(workspace.workspace);
    this.authService.setRiskscore(workspace.isRiskScoreEnabled);
    this.authService.setRiskscoreHours(workspace.releaseHighRiskOrderAfter);
    localStorage.removeItem('BuildingBlockDetails');
    this.authService.loadInitialModule(true, false, isFc);
    this.openNewB2bOrderComponent = this.createB2BOrderWorkspaces.includes(workspace);
    this.isLoader = false;
    this.zohoService.setZohoAccountToCookie(workspace.accountSlug);
  }

  checkFlexSlug(accountSlug) {
    const accountlist = this.allAccountList.filter(x => x.parentClientWorkspaceSlug == accountSlug);
    if (accountlist.length > 0) {
      // this.authService.setFlexSlug(accountlist[0].accountSlug);
      return accountlist[0].accountSlug;
    } else {
      sessionStorage.removeItem('flexAccountSlug');
      return false;
    }
  }

  assignDefaultLocation() {
    const accountlist = this.allAccountList.filter(
      x => x.parentClientWorkspaceSlug == this.authService.getAccountSlug()
    );
    if (accountlist.length > 0) {
      const currentFlexSlug = sessionStorage.getItem('flexAccountSlug');
      let checkFlexSlug: any;
      if (currentFlexSlug) checkFlexSlug = accountlist.find(x => x.accountSlug == currentFlexSlug);
      if (!checkFlexSlug || !currentFlexSlug) {
        this.authService.setFlexSlug(accountlist[0].accountSlug, true);
      } else {
        const locationFromURL = this.authService.getFlexSlug();
        if (currentFlexSlug && currentFlexSlug != locationFromURL) this.authService.setFlexSlug(locationFromURL, true);
      }
      if (this.authService.getFlexSlug() && this.count == 0 && !this.iscalled) {
        this.count = 1;
        this.setBuildingBlocksDetailsToLSArrangeSidebar();
      }
    } else {
      sessionStorage.removeItem('flexAccountSlug');
      return false;
    }
  }

  async selectedFC(bbHandleBar) {
    if (!this.workflowSettings && !this.loadingSetting) {
      this.locationDataNotFound('Location setting', true);
      return;
    }
    if (bbHandleBar == '/building-blocks/self-fulfilment-settings') {
      this.router.navigate([bbHandleBar], { queryParams: { location: this.authService.getFlexSlug() } });
      return;
    }
    const buildingBlockDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
    if (this.flexError) {
      this.locationDataNotFound('Setting', true);
      return;
    }
    if (this.workflowSettings && (this.buildingBlockDetails || buildingBlockDetails)) {
      if (this.buildingBlockDetails) {
        const details = this.buildingBlockDetails.filter(x => x.handleBar === bbHandleBar)[0];
        this.getSelectedBuildingBlock(details ? details : bbHandleBar);
      } else {
        const details = buildingBlockDetails.filter(x => x.handleBar === bbHandleBar)[0];
        this.getSelectedBuildingBlock(details ? details : bbHandleBar);
      }
    } else {
      this.isLoader = true;
      if (this.loadingSetting) {
        this.selectedHandleBar = bbHandleBar;
      } else if (!this.iscalled) {
        this.setBuildingBlocksDetailsToLSArrangeSidebar();
      }
    }
    this.buildingblockService.setCallSelectedFC(null);
  }

  public storeBuildingBlockData(groupdata, data) {
    let ar = [];
    groupdata.data.forEach(x => {
      if (x.buildingBlock != undefined && x.buildingBlock != null) {
        x.buildingBlock.forEach(y => ar.push(y));
      }
    });
    ar = [...ar, ...data];
    this.buildingBlockDetails = ar;
    localStorage.setItem('BuildingBlockDetails', JSON.stringify(ar));
    this.bbLocalStorageService.buildingBlocksDetails = ar;
    this.flexError = false;
  }

  public makeBuildingBlockListSequence() {
    if (this.buildingBlockDetails) {
      this.buildingBlockDetails.forEach((x, index) => {
        if (x.buildingBlock != undefined && x.buildingBlock != null)
          x.buildingBlock.forEach((y, index1) => {
            this.buildingBlockDetails[index]['buildingBlock'][index1].name =
              y.name.charAt(0).toUpperCase() + y.name.slice(1);
          });
      });

      this.buildingBlockDetails.sort((x, y) => {
        return x.sortKey - y.sortKey;
      });
    }
  }

  /***@description Get Fulfillment Center Data From Backend and store in Loacl Storage*/
  public getFulfillmentCenterData(fulfillmentCenterData) {
    this.fulfillmentCenterData = fulfillmentCenterData
      ? fulfillmentCenterData.filter(
          data => data['enrollmentStatus'] == 'ENROLLED' && data['facilityStatus'] == '1' && data['status'] != 2
        )
      : [];
    this.lsd.setItem('fulfillmentCenter', JSON.stringify(this.fulfillmentCenterData));
    this.lsd.setItem('warehouseList', JSON.stringify(fulfillmentCenterData));
    this.lsd.setItem(
      'archivedLocation',
      JSON.stringify(
        fulfillmentCenterData.filter(
          data => data['enrollmentStatus'] == 'ENROLLED' && data['facilityStatus'] == '1' && data['status'] == 2
        )
      )
    );
    const accountSlug = this.authService.getAccountSlug();
    let ffcArray = this.fulfillmentCenterData?.filter(ffc => ffc.parentClientWorkspaceSlug == accountSlug);

    if (ffcArray && ffcArray.length > 0) {
      this.hasLocation = true;
    } else {
      this.hasLocation = false;
    }
  }

  getAccountAndWarehouseData() {
    this.accountLocationSubscription$ = this.authService
      .getAccountAndWarehouseList()
      .subscribe(([accountListData, fulfillmentCenterData]) => {
        if (
          (isArray(accountListData) || accountListData?.accountListLoaded) &&
          (isArray(fulfillmentCenterData) || fulfillmentCenterData?.fulfillmentCenterLoaded)
        ) {
          const accountList = isArray(accountListData) ? accountListData : accountListData?.accountListData?.data;
          if (!fulfillmentCenterData.error) {
            const warehouseList = isArray(fulfillmentCenterData)
              ? fulfillmentCenterData
              : fulfillmentCenterData?.fulfillmentCenterData?.data;
            this.getFulfillmentCenterData(warehouseList);
          }
          this.setAccountDetails(accountList);
          this.getLoggedInUserDetails();
          if (this.accountLocationSubscription$) this.accountLocationSubscription$.unsubscribe();
        }
      });
  }

  /*** @description Get Sales Channel Data From Backend and store in Loacl Storage*/
  public getSalesChannelData() {
    this.store.dispatch(new GetSalesChannelData());
    this.salesChannelSubscription = this.store.pipe(select(fromSelector.getSalesChannelData)).subscribe(response => {
      if (response && response['data']) {
        this.convertSalesChannelData(response['data']);
      }
    });
  }

  /*** @description Helper Method To Convert Sales Channel Data*/
  public convertSalesChannelData(salesChannelData) {
    salesChannelData.forEach(salesChannel => {
      this.fulfillmentCenterData.forEach(fullfilmentCenter => {
        if (salesChannel.fulfillmentChannels.length > 0) {
          if (salesChannel.fulfillmentChannels[0].warehouseId == fullfilmentCenter.warehouseId) {
            salesChannel['warehouseName'] = fullfilmentCenter.warehouseName;
          }
        }
      });
    });
    localStorage.setItem('allSalesChannel', JSON.stringify(salesChannelData));
    salesChannelData = salesChannelData
      ? salesChannelData.filter(data => {
          if (data['channelStatus'] != '2') {
            if (data['integrationType'] == 0) {
              if (data['enrollmentStatus'] == 'ACTIVE') {
                return data;
              }
            } else {
              if (data['enrollmentStatus'] == 'ACTIVE' || data['enrollmentStatus'] == 'IN PROCESS') {
                return data;
              }
            }
          }
        })
      : [];
    this.lsd.setItem('salesChannel', JSON.stringify(salesChannelData));
    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }
    // if (this.salesChannelSubscription) {
    //   this.salesChannelSubscription.unsubscribe();
    // }
  }

  /**@description Get Product Filters Data and Store in Local Storage*/
  public getProductFiltersData() {
    this.productFilterSubscription$ = this.productService.getFilters().subscribe(response => {
      if (response) {
        let filtersData = response['items'];
        let filterTypeDropdownKeys: any = this.getFilterTypeDropDownKeys(filtersData);
        if (filterTypeDropdownKeys.length > 0) {
          this.dropdownValuesSubscription$ = this.productService
            .getDropDownValues(filterTypeDropdownKeys)
            .subscribe(response => {
              if (response) {
                let facetsData = response['facets'] ? response['facets'] : {};
                localStorage.setItem('totalProducts', response['nbHits']);
                Object.keys(response['facets']).length > 0
                  ? localStorage.setItem('draftProducts', response['facets']['status']['DRAFT'])
                  : localStorage.setItem('draftProducts', '0');
                this.convertFiltersData(filtersData, facetsData);
              }
            });
        }
      }
    });
  }

  /**@description Get Product Filters Data and Store in Local Storage*/
  public getCustomFields() {
    this.customfieldSubscription$ = this.productService.getCustomfield().subscribe(response => {
      if (response['data']?.length > 0) {
        localStorage.setItem('customField', JSON.stringify(response['data'].filter(k => k.isArchieved != true)));
      }
    });
  }

  /**@description Get Product Facets from backend*/
  public getFilterTypeDropDownKeys(filtersData) {
    let filterTypeDropdownKeys = ['inventoryUpdateFailures'];
    filtersData.filter(filterData => {
      if (filterData['fieldType'].toLowerCase() == 'dropdown') filterTypeDropdownKeys.push(filterData['key']);
    });
    return filterTypeDropdownKeys;
  }

  /**@description Helper Method to Convert Product Filter Data*/
  public convertFiltersData(filtersData, facetsData) {
    let updatedfiltersData = filtersData;
    for (let facetKey in facetsData) {
      updatedfiltersData.forEach(filters => {
        if (filters['key'].toLowerCase() == facetKey.toLowerCase()) {
          filters['value'] = Object.keys(facetsData[facetKey]);
        }
        if (filters['key'].toLowerCase() == 'inventoryage') {
          filters['value'] = ['age0To30', 'age30To60', 'age60To90', 'age90plus'];
        }
      });
      if (facetKey == 'inventoryUpdateFailures') {
        localStorage.setItem(
          'inventoryUpdateFailures',
          JSON.stringify(Object.keys(facetsData['inventoryUpdateFailures']))
        );
      }
    }
    localStorage.setItem('productFilters', JSON.stringify(updatedfiltersData));
    if (this.productFilterSubscription$) this.productFilterSubscription$.unsubscribe();
    if (this.dropdownValuesSubscription$) this.dropdownValuesSubscription$.unsubscribe();
  }

  /**@description Method to get orders filter data*/
  public getOrdersFiltersData() {
    // if (this.currentRoute && this.currentRoute.includes('/order')) {
    let storeSelector: string, currentService: any, version: any;
    // if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
    storeSelector = 'getOrdersFiltersDataV2';
    this.store.dispatch(new GetOrdersFiltersV2Data());
    currentService = this.ordersService;
    // version = 'V2';
    // } else {
    //   storeSelector = 'getOrdersFiltersData';
    //   this.store.dispatch(new GetOrdersFiltersData());
    //   currentService = this.ordersService;
    //   version = 'V1';
    // }
    this.ordersFilterSubscription$ = this.store.pipe(select(fromSelector[storeSelector])).subscribe((response: any) => {
      if (response) {
        let orderFilterData = response['items'];
        const ordersPayload = this.generateOrdersFacetsPayload();
        this.ordersFacetsSusbcription$ = currentService.getOrdersData(ordersPayload).subscribe((response: any) => {
          if (response && Object.keys(response).length > 0) {
            // alert("Data received");
            currentService.ninetyDaysCnt = response['total'];
            const facetsData = response['facets'];
            this.convertOrdersFilterData(orderFilterData, facetsData);
            // alert("Action completed");
          }
        });
      }
    });
    // }
  }

  /**@description Method to generate payload for fetching orders facets */
  public generateOrdersFacetsPayload() {
    const fromDate = new Date(
      moment()
        .subtract(90, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();
    const toDate = new Date().setHours(23, 59, 59).valueOf();
    let filters;
    // if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
    filters = `(orderReceivedOn>=${fromDate})AND(orderReceivedOn<=${toDate})`;
    // } else {
    //   filters = `(orderDateDetailsDTO.orderReceivedOn>=${fromDate})AND(orderDateDetailsDTO.orderReceivedOn<=${toDate})`;
    // }
    const ordersPayload: any = { page: 0, perPage: 50, index: 'prod', filter: filters };
    return ordersPayload;
  }

  /**@description Helper Method to Convert Orders Filter Data */
  public convertOrdersFilterData(orderFilterData, facets) {
    orderFilterData.forEach(filterData => {
      const fieldType = filterData.fieldType.toLowerCase();
      const filterKey = filterData.key;
      const filterOptions = filterData['options'] || [];

      if (fieldType === 'dropdown' && filterKey === 'currentOrderStatus') {
        if (facets) {
          const orderFilterValue = facets['currentOrderStatus'] ? Object.keys(facets['currentOrderStatus']) : [];
          const combinedOptions = new Set([...orderFilterValue, ...filterOptions]);
          filterData['options'] = this.ordersService.sortData([...combinedOptions]) || [];
        }
      } else if (fieldType === 'dropdown' && filterKey !== 'orderStatus') {
        const filterValue = facets && facets.hasOwnProperty(filterKey) ? Object.keys(facets[filterKey]) : [];
        const combinedOptions = new Map();

        // Add filterValue items first, ensuring case insensitivity
        filterValue.forEach(value => {
          combinedOptions.set(value.toLowerCase(), value);
        });

        // Add filterOptions items, only if they aren't already present in the map
        filterOptions.forEach(option => {
          const lowerCaseOption = option.toLowerCase();
          if (!combinedOptions.has(lowerCaseOption)) {
            combinedOptions.set(lowerCaseOption, option);
          }
        });
        // Convert the map values back to a Set if needed
        const resultSet = new Set(combinedOptions.values());
        filterData['options'] = this.ordersService.sortData([...resultSet]) || [];

        if (!facets || !facets.hasOwnProperty(filterKey)) {
          this.tempArr.push(filterData);
        }
      } else if (fieldType === 'daterange') {
        filterData['options'] = '';
      }
    });

    // this.tempArr.forEach(ele => {
    //   const index = orderFilterData.findIndex(x => x.key === ele.key);
    //   if (index !== -1) {
    //     orderFilterData.splice(index, 1);
    //   }
    // });

    const sortedFiltersData = orderFilterData.sort((a, b) => {
      return a['displayKey'] > b['displayKey'] ? 1 : -1;
    });

    localStorage.setItem('orderFiltersV2', JSON.stringify(sortedFiltersData || orderFilterData));

    if (this.ordersFilterSubscription$) this.ordersFilterSubscription$.unsubscribe();
    if (this.ordersFacetsSusbcription$) this.ordersFacetsSusbcription$.unsubscribe();
  }

  public getSelectedBuildingBlock(details) {
    // this.buildingBlockDetails = JSON.parse(JSON.stringify(this.tempVar));
    // if (!details) {
    //   this.isLoader = false;
    //   this.alertService.showError('The page cannot be loaded. Please report a bug.', 4000);
    //   return;
    // }
    this.bbDetails = details;
    this.bbHandleBar = details?.handleBar ? details?.handleBar : details;
    //if qc method is no-scan then return process will be redirect to no-scan listing page
    if (this.workflowSettings?.returnQcInfo?.qcMethod === 'noScanBased' && this.bbHandleBar === 'receiveReturn2') {
      RETURN_NO_SCAN_PARAM.location = this.flexAccountSlug;
      this.router.navigate(['/process-no-scan-return/receive-return-list'], { queryParams: RETURN_NO_SCAN_PARAM });
      // this.router.navigate(['/process-no-scan-return/receive-return-list'], {
      //   queryParams: { location: this.authService.getFlexSlug() }
      // });
      return;
    }
    this.makeBuildingBlockListSequence();
    this.filterHelperService.isCalledFromSidebar = true;
    let selectSegment;
    var segmentsData = JSON.parse(localStorage.getItem('segmentsData'));
    var allBBSegments = segmentsData.filter(segment => {
      return segment[0] === 'BuildingBlocks';
    })[0];
    let allSegmentsforSelectedBuildingBlock: any = [];
    if (allBBSegments) {
      allSegmentsforSelectedBuildingBlock = allBBSegments[1].filter(segment => {
        return segment['buildingBlock'] === this.bbHandleBar;
      });
    }

    this.isLoader = false;
    if (allSegmentsforSelectedBuildingBlock.length > 0) {
      if (this.bbHandleBar == 'shipment' && this.workflowSettings.pickingInfo.pickingMethod == 'skipPicking') {
        let segregatedobj = allSegmentsforSelectedBuildingBlock.filter(obj => {
          return this.segmentSegragationArray.includes(obj.name);
        });
        selectSegment = segregatedobj.filter(
          seg => seg['buildingBlock'] === this.bbHandleBar && seg['filterQuery'] !== ''
        )[0]['filterQuery'];
        this.dataStoreService.navigateToRecordList(this.bbHandleBar, selectSegment);
      } else {
        selectSegment = allSegmentsforSelectedBuildingBlock.filter(
          seg => seg['buildingBlock'] === this.bbHandleBar && seg['filterQuery'] !== ''
        )[0]['filterQuery'];
        this.dataStoreService.navigateToRecordList(this.bbHandleBar, selectSegment);
      }
    } else {
      this.dataStoreService.navigateToRecordList(this.bbHandleBar);
    }
  }

  public importmenu() {
    this.filesMenuClicked = true;
  }

  public filesMenuUnclicked(e) {
    this.filesMenuClicked = false;
    this.enterMouse = false;
  }

  public submitIssue() {
    (window as any).showCollectorDialog();
  }
  public loadIntercom() {
    this.intercom.show();
  }
  ngAfterViewInit() {
    (window as any).Canny('initChangelog', {
      appID: environment.canny.comapnyId,
      position: 'bottom',
      align: 'right',
      theme: 'light' // options: light [default], dark, auto
    });
  }

  ngOnDestroy() {
    if (this.userSubscription$) this.userSubscription$.unsubscribe();
    if (this.accountSubscription$) this.accountSubscription$.unsubscribe();
    if (this.customfieldSubscription$) this.customfieldSubscription$.unsubscribe();
    if (this.accountLocationSubscription$) this.accountLocationSubscription$.unsubscribe();
    if (this.loggedInUserSubscription$) this.loggedInUserSubscription$.unsubscribe();
    if (this.callSelectedFcSubscription$) this.callSelectedFcSubscription$.unsubscribe();
    (window as any).Canny('closeChangelog');
  }

  authoriseUser() {
    const flexAccountSlug: any = this.authService.getFlexSlug();
    if (this.loggedInUserData && Object.keys(this.loggedInUserData.fc_workspace_permissions).length == 0) {
      this.hasLocationAccess = false;
    } else {
      if (!flexAccountSlug || flexAccountSlug == this.authService.getAccountSlug()) {
        const accountSlug = Object.keys(this.loggedInUserData.fc_workspace_permissions)[0];
        this.authService.setFlexSlug(accountSlug, true);
      }

      this.hasLocationAccess = true;
    }

    if (this.loggedInUserData) {
      const segmentData = localStorage.getItem('segmentsData');
      if (!segmentData && !this.hasWorkspaceAccess) {
        for (const key in this.loggedInUserData['workspace_permissions']) {
          if (this.loggedInUserData['workspace_permissions'][key].isEnabled == '1') {
            this.filterPusherService.getSegmentData('workspace');
            this.hasWorkspaceAccess = true;
            break;
          }
        }
      }
      for (const routeCategory in this.NEWROUTES) {
        if (this.NEWROUTES.hasOwnProperty(routeCategory)) {
          const routes = this.NEWROUTES[routeCategory];
          routes.forEach((route, index) => {
            if (route.scope && route.urlType === 'WORKSPACE' && route.scope != 'flex') {
              this.NEWROUTES[routeCategory][index].hasAccess =
                this.loggedInUserData.workspace_permissions[`manage:${route.scope}`].isEnabled &&
                this.loggedInUserData.workspace_permissions[`manage:${route.scope}`].isEnabled == '1'
                  ? true
                  : false;
            } else if (!flexAccountSlug && route.urlType === 'FLEX') {
              this.NEWROUTES[routeCategory][index].hasAccess = false;
              return;
            } else if (
              route.scope == 'assets' &&
              Object.keys(this.loggedInUserData.fc_workspace_permissions).length != 0
            ) {
              const isAccess =
                this.loggedInUserData.fc_workspace_permissions[flexAccountSlug] &&
                this.loggedInUserData.fc_workspace_permissions[flexAccountSlug]['manage:assets'].isEnabled &&
                this.loggedInUserData.fc_workspace_permissions[flexAccountSlug]['manage:assets'].isEnabled == '1'
                  ? true
                  : false;
              this.NEWROUTES[routeCategory][index].hasAccess = isAccess;
            } else if (flexAccountSlug && Object.keys(this.loggedInUserData.fc_workspace_permissions).length != 0) {
              this.NEWROUTES[routeCategory][index].hasAccess = true;
              this.hasLocation = true;
              const bbDetails = localStorage.getItem('BuildingBlockDetails');
              if (!bbDetails && !this.iscalled) {
                this.setBuildingBlocksDetailsToLSArrangeSidebar();
              }
              return;
            }
          });
        }
      }

      // for seprate menu items
      if (flexAccountSlug && Object.keys(this.loggedInUserData.fc_workspace_permissions).length != 0) {
        this.CREATE_PICKLIST.hasAccess = true;
        this.MANAGE_INVENTORY.hasAccess = true;
        this.PROCESS_B2B_ORDERS.hasAccess = true;
        this.PROCESS_RETURN.hasAccess = true;
        const isAccess =
          this.loggedInUserData.fc_workspace_permissions[flexAccountSlug] &&
          this.loggedInUserData.fc_workspace_permissions[flexAccountSlug]['manage:assets'].isEnabled &&
          this.loggedInUserData.fc_workspace_permissions[flexAccountSlug]['manage:assets'].isEnabled == '1'
            ? true
            : false;
        this.ASSETS.hasAccess = isAccess;
      }
    }

    const accountSlug = this.authService.getAccountSlug();
    for (let i = 0; i < this.NEWROUTES['setting'].length; i++) {
      let item = this.NEWROUTES['setting'][i];
      if (item.hasAccess && !item.hiddenInWorkspace.includes(accountSlug)) {
        this.itemToOpen = item;
        break;
      }
    }
    this.buildingblockService.setLocationData(this.hasLocation, this.hasLocationAccess);
  }
  public redirectToCanny() {
    this.authService.redirectToCannyInternally('workspace');
  }

  locationDataNotFound(text: string, showError?) {
    if (showError)
      this.alertService.showError(
        `${text} not found for location: ${this.authService.getFlexSlug()}, Please raise a bug.`
      );
    this.isLoader = false;
    this.flexError = true;
  }
  trackByWorkspaceId(index: number, workspace: any): any {
    return workspace.accountslug; // Replace 'id' with the unique identifier property in your workspace object
  }
  mouse(cases: boolean) {
    const iframe = document.getElementById('canny-changelog-iframe');
    const el = document.getElementById('canny-changelog');
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    iframe.style.left = (rect.left + scrollLeft - 310).toString() + 'px';
    // Fires whenever mouse enter the element

    if (cases) {
      iframe.style.display = 'block';
    } else {
      //iframe.style.display = 'none';
      iframe.addEventListener('mouseover', function(event) {
        // Update mouse current status
        iframe.style.display = 'block';
      });
      iframe.style.display = 'none';
    }
  }
  removeChildFromIframe() {
    // Get a reference to the iframe
    const iframe = window.frames['intercom-messenger-frame'];

    // Check if the iframe is accessible and its content window is available
    if (iframe) {
      // Access the iframe's document
      const iframeDoc = iframe.document;
      // Find the element you want to remove in the iframe's document
      //const elementToRemove = iframeDoc.querySelector('elementIdToRemove');
      const elementToRemove = iframeDoc.querySelector('[aria-controls="spaces-messages"]');
      if (elementToRemove) {
        // Remove the element from the iframe's document
        elementToRemove.parentNode.removeChild(elementToRemove);
      }
    }
  }
  openBot(e) {
    e.preventDefault();
    (window as any).Intercom('showSpace', 'home');
    (window as any).Intercom('onShow', () => {
      setInterval(() => {
        this.removeChildFromIframe();
      }, 500);
    });
  }
  showHide(obj, type) {
    if (this.currentWorkspace !== 'eshop') {
      let fcList = JSON.parse(localStorage.getItem('fulfillmentCenter'));
      switch (type) {
        case 1:
          Object.keys(obj).forEach(key => {
            obj[key].forEach(route => {
              if (route.requiredLocationSetting) {
                const requiredLocationSetting = route.requiredLocationSetting;
                for (let i = 0; i < requiredLocationSetting.length; i++) {
                  let setting = requiredLocationSetting[i];
                  if (setting === 'Workspace creation') {
                    route.isVisible = true;
                    break;
                  } else if (setting === 'Eshopbox FC Enabled') {
                    const result = fcList?.find(fc => {
                      return fc.parentClientWorkspaceSlug === 'eshop' && fc.enrollmentStatus === 'ENROLLED';
                    });
                    if (result) {
                      route.isVisible = true;
                      break;
                    }
                  } else if (setting === 'Sku level') {
                    const result = this.filteredLocations?.find(fc => {
                      return fc.fcTraceability === 'sku' && fc.flexStatus === '1';
                    });
                    if (result) {
                      route.isVisible = true;
                      break;
                    }
                  } else if (setting === 'Item level') {
                    const result = this.filteredLocations?.find(fc => {
                      return fc.fcTraceability === 'item' && fc.flexStatus === '1';
                    });
                    if (result) {
                      route.isVisible = true;
                      break;
                    }
                  } else if (setting === 'No inventory') {
                    const result = this.filteredLocations?.find(fc => {
                      return fc.fcTraceability === 'no_inventory' && fc.flexStatus === '1';
                    });
                    if (result) {
                      route.isVisible = true;
                      break;
                    }
                  }
                  route.isVisible = false;
                }
              } else {
                route.isVisible = false;
              }
            });
          });
          break;
        case 2:
          if (obj.requiredLocationSetting) {
            const requiredLocationSetting = obj.requiredLocationSetting;
            for (let i = 0; i < requiredLocationSetting.length; i++) {
              let setting = requiredLocationSetting[i];
              if (setting === 'Workspace creation') {
                obj.isVisible = true;
                break;
              } else if (setting === 'Eshopbox FC Enabled') {
                const result = fcList.find(fc => {
                  return fc.parentClientWorkspaceSlug === 'eshop' && fc.enrollmentStatus === 'ENROLLED';
                });
                if (result) {
                  obj.isVisible = true;
                  break;
                }
              } else if (setting === 'Sku level') {
                const result = this.filteredLocations?.find(fc => {
                  return fc.fcTraceability === 'sku' && fc.flexStatus === '1';
                });
                if (result) {
                  obj.isVisible = true;
                  break;
                }
              } else if (setting === 'Item level') {
                const result = this.filteredLocations?.find(fc => {
                  return fc.fcTraceability === 'item' && fc.flexStatus === '1';
                });
                if (result) {
                  obj.isVisible = true;
                  break;
                }
              } else if (setting === 'No inventory') {
                const result = this.filteredLocations?.find(fc => {
                  return fc.fcTraceability === 'no_inventory' && fc.flexStatus === '1';
                });
                if (result) {
                  obj.isVisible = true;
                  break;
                }
              }
              obj.isVisible = false;
            }
          } else {
            obj.isVisible = false;
          }
      }
      for (let i = 0; i < this.NEWROUTES['inventory'].length; i++) {
        const item = this.NEWROUTES['inventory'][i];
        if (item.parent === 'Consignment' && item.isVisible === true) {
          this.showConsignmentDropdown = true;
          break;
        }
        if (i === this.NEWROUTES['inventory'].length - 1) {
          this.showConsignmentDropdown = false;
        }
      }
      for (let i = 0; i < this.NEWROUTES['inventory'].length; i++) {
        const item = this.NEWROUTES['inventory'][i];
        if (item.parent === 'Remove inactive inventory' && item.isVisible == true) {
          this.showRemoveInventoryDropdown = true;
          break;
        }
        if (i === this.NEWROUTES['inventory'].length - 1) {
          this.showRemoveInventoryDropdown = false;
        }
      }
      [this.CREATE_PICKLIST, this.PROCESS_B2B_ORDERS, this.PROCESS_RETURN].forEach(obj => {
        if (obj.isVisible) {
          this.showSelfFulfiment = true;
        } else {
          this.showSelfFulfiment = false;
        }
      });
    } else {
      switch (type) {
        case 1:
          Object.keys(obj).forEach(key => {
            obj[key].forEach(route => {
              route.isVisible = true;
            });
          });
          this.showConsignmentDropdown = true;
          this.showRemoveInventoryDropdown = true;
          this.showSelfFulfiment = true;
          break;
        case 2:
          obj.isVisible = true;
          this.showConsignmentDropdown = true;
          this.showRemoveInventoryDropdown = true;
          this.showSelfFulfiment = true;
      }
    }
  }

  sortReportMenu() {
    let a = this.NEWROUTES.reports.filter(f => f.parent == 'Reports' && f.name != 'Home');
    if (a && a.length > 0) {
      a.sort((a, b) => a.sortKey - b.sortKey);
    }
    return a;
  }
  routeToClaims() {
    this.router.navigate(['/claims/listing'], {
      queryParams: {
        page: 1,
        perPage: 30,
        createdStartDate: moment()
          .subtract(30, 'day')
          .format('YYYY-MM-DD'),
        createdEndDate: moment()
          .add(0, 'day')
          .format('YYYY-MM-DD')
      }
    });
  }

  /**@description Method to get no scan return filter data*/
  public getNoScanReturnFiltersData() {
    this.store.dispatch(new GetNoScanReturnFiltersData());
    this.noScanReturnFilterSubscription$ = this.store
      .pipe(select(returnFromSelector.getNoScanReturnFiltersData))
      .subscribe((response: any) => {
        if (response && response.loaded && response?.filters.length > 0) {
          localStorage.setItem('noScanReturnFiltersData', JSON.stringify(response.filters));
          if (this.noScanReturnFilterSubscription$) this.noScanReturnFilterSubscription$.unsubscribe();
        }
      });
  }
}
