import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertMessageSubject = new Subject<{
    show: boolean;
    type: string;
    content: string;
    duration?: number;
    showCross?: boolean;
    link?: string;
  }>();
  alertMessageState = this.alertMessageSubject.asObservable();

  constructor() {}

  showError(content: string, duration?: number, showCross?: boolean) {
    this.alertMessageSubject.next({
      show: true,
      type: 'error',
      content: content,
      duration: duration,
      showCross: showCross
    });
  }

  showErrorWithLink(content: string, link?: string, duration?: number, showCross?: boolean) {
    this.alertMessageSubject.next({
      show: true,
      type: 'error-link',
      content: content,
      duration: duration,
      showCross: showCross,
      link: link
    });
  }

  showSuccess(content: string, duration?: number, showCross?: boolean) {
    this.alertMessageSubject.next({
      show: true,
      type: 'success',
      content: content,
      duration: duration,
      showCross: showCross
    });
  }

  showWarning(content: string, duration?: number, showCross?: boolean) {
    this.alertMessageSubject.next({
      show: true,
      type: 'warning',
      content: content,
      duration: duration,
      showCross: showCross
    });
  }

  showInfo(content: string, duration?: number, showCross?: boolean) {
    this.alertMessageSubject.next({
      show: true,
      type: 'info',
      content: content,
      duration: duration,
      showCross: showCross
    });
  }
}
