import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFilter'
})
export class TruncateFilterPipe implements PipeTransform {
  transform(filterValue: string[], maxVisibleItems: number = 1, maxLength: number = 20): string {
    if (filterValue.length <= maxVisibleItems) {
      return filterValue.map(value => this.truncateString(value, maxLength)).join(', ');
    }

    const visibleFilters = filterValue
      .slice(0, maxVisibleItems)
      .map(value => this.truncateString(value, maxLength))
      .join(', ');
    const remainingCount = filterValue.length - maxVisibleItems;
    return `${visibleFilters} + ${remainingCount} more`;
  }

  private truncateString(str: string, maxLength: number): string {
    return str.length > maxLength ? str.substr(0, maxLength) + '...' : str;
  }
}
