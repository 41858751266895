import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PusherScheduleService {
  CHANNEL = environment.pusher_schedule.pusher_schedule_channel;
  EVENT = environment.pusher_schedule.pusher_schedule_event;
  pusher: any;
  channel: any;
  scheduleJobProcessing: Subject<any> = new Subject<any>();

  constructor(private authService: AuthService) {
    this.pusher = this.authService.pusher;
    this.channel = this.pusher.subscribe(this.CHANNEL);
    this.channel.bind(this.EVENT, response => {
      for (let status in response) {
        if (status == 'EXPORT JOB SCHEDULE PROCESSING') {
          this.scheduleJobProcessing.next(response);
        }
      }
    });
  }

  getScheduleJobProcessingFeed(): Observable<any> {
    return this.scheduleJobProcessing.asObservable();
  }
}
