export const compareObjToTerm = (
  object: any,
  term: string,
  excludes: Array<string> = null,
  innerObject?: string,
  searchTrue?: boolean
) => {
  if (!term && !innerObject) {
    return true;
  }
  if (object) {
    for (const property in object) {
      if (property && object[property]) {
        if (!excludes || !excludes.includes(property)) {
          const index = safeToString(object[property].toString())
            .toUpperCase()
            .indexOf(term.toUpperCase());
          if (index > -1) {
            return true;
          }
        }

        if (!!innerObject && property === innerObject && !searchTrue) {
          for (const prop in object[property]) {
            const index = safeToString(object[property][prop].toString())
              .toUpperCase()
              .indexOf(term.toUpperCase());
            if (index > -1) {
              return true;
            }
          }
        }
      }
    }
  }

  return false;
};

export const safeToString = (a: any) => {
  return a === null || a === undefined ? '' : a.toString();
};
