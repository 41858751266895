import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { OrdersState } from 'src/app/orderV2/store/state/orders.state';
import * as fromSelector from 'src/app/orderV2/store/selectors/orders.selectors';

@Component({
  selector: 'app-export-columns',
  templateUrl: './export-columns.component.html',
  styleUrls: ['../order-schedule/order-schedule.component.scss']
})
export class ExportColumnsComponent implements OnInit {
  @Input() templateSelected: any;
  @Input() selectAllOption: any;
  @Input() columnSelected: any;
  @Output() checkBoxStatus = new EventEmitter();
  getTemplateSubscription$: Subscription;
  templateList: any;
  accountSlug: any;

  objectKeys = Object.keys;

  constructor(private store: Store<{ ordersState: OrdersState }>) {
    this.accountSlug = localStorage.getItem('workspace');
  }

  ngOnInit() {
    this.getTemplateSubscription$ = this.store.pipe(select(fromSelector.templateData)).subscribe(response => {
      if (response && response.templateDataLoaded) {
        this.templateList = response.templateData;
      }
    });
  }

  /** @description Avoid default sort behaviour of keyvalue */
  returnZero() {
    return 0;
  }

  /** @description Select All Checkbox Selection */
  public toggleParent(event: any, group: any) {
    if (event.checked) {
      this.columnSelected[group.key] = [];
      group.value.forEach(val => {
        this.columnSelected[group.key].push(val.key);
      });
      this.selectAllOption[group.key] = true;
      this.toggleParentTempList(event, group);
    } else {
      this.selectAllOption[group.key] = false;
      this.toggleParentTempList(event, group);
      delete this.columnSelected[group.key];
    }
    this.checkBoxStatus.emit(this.columnSelected);
  }

  /** @description Modifiy Template List Response For Select All */
  public toggleParentTempList(event, group) {
    this.templateSelected.columnStatus[group.key].forEach(status => {
      if (event.checked) status.checked = true;
      else status.checked = false;
    });
  }

  /** @description Specific Checkbox Clicked */
  public toggleSelection(event: any, name: any, group: any) {
    if (event.checked) {
      if (!(group.key in this.columnSelected)) this.columnSelected[group.key] = [];
      this.columnSelected[group.key].push(name.key);
      this.toggleSelectAll(event, name, group);
    } else {
      this.toggleSelectAll(event, name, group);
      this.columnSelected[group.key] = this.columnSelected[group.key].filter(
        deselectedName => deselectedName != name.key
      );
      if (this.columnSelected[group.key].length === 0) delete this.columnSelected[group.key];
    }
    this.checkBoxStatus.emit(this.columnSelected);
  }

  /** @description Select All And Specific Checkbox Selection */
  public toggleSelectAll(event, name, group) {
    this.templateSelected.columnStatus[group.key].forEach(status => {
      if (!event.checked) {
        if (status.key === name.key) status.checked = false;
      } else {
        if (status.key === name.key) status.checked = true;
      }
    });
    let selected: boolean = true;
    this.templateSelected.columnStatus[group.key].forEach(status => {
      if (!status.checked) selected = false;
    });
    this.selectAllOption[group.key] = selected;
  }

  /** @description Select All Checkbox min Selection */
  someComplete(item): boolean {
    return item.value.filter(t => t.checked).length > 0 && !this.selectAllOption[item.key];
  }

  /** @description Toggle Parent Event For Product*/
  toggleParentEvent(event) {
    this.toggleParent(event.$event, event.item);
  }

  /** @description Toggle Selection Event For Product*/
  toggleSelectionEvent(event) {
    this.toggleSelection(event.$event, event.f, event.item);
  }

  ngOnDestroy() {
    if (this.getTemplateSubscription$) this.getTemplateSubscription$.unsubscribe();
  }

  accountSlugCheck() {
    if (
      this.accountSlug == 'plix' ||
      this.accountSlug == 'vikramtea' ||
      this.accountSlug == 'teknodome' ||
      this.accountSlug == 'mrright' ||
      this.accountSlug == 'pidilite' ||
      this.accountSlug == 'tab' ||
      this.accountSlug == 'testing_brand_party' ||
      this.accountSlug == 'ydiconsumer' ||
      this.accountSlug == 'quickstarttestvarun' ||
      this.accountSlug == 'factory_rush'
    ) {
      return true;
    } else {
      return false;
    }
  }
}
