import { Action, createAction } from '@ngrx/store';

export const ruleList = '[order routing component] list';
export const ruleListSuccess = '[order routing component] success';
export const ruleListError = '[order routing component] Error';
export const ruleSave = '[create order routing component] save';
export const ruleSaveSuccess = '[create order routing component] save success';
export const ruleSaveError = '[create order routing component] Error';
export const ruleDelete = '[delete order routing component] delete';
export const ruleDeleteSuccess = '[delete order routing component] delete success';
export const ruleDeleteError = '[delete order routing component] Error';
export const dummyAction = '[dummy Action]';

export class ruleListRequestAction implements Action {
  readonly type = ruleList;
}

export class ruleListSuccessAction implements Action {
  readonly type = ruleListSuccess;
  constructor(readonly payload: any) {}
}

export class ruleListErrorAction implements Action {
  readonly type = ruleListError;
  constructor(readonly payload: any) {}
}

export class ruleSaveAction implements Action {
  readonly type = ruleSave;
  constructor(readonly payload: any) {}
}

export class ruleSaveSuccessAction implements Action {
  readonly type = ruleSaveSuccess;
  constructor(readonly payload: any) {}
}
export class ruleSaveErrorAction implements Action {
  readonly type = ruleSaveError;
  constructor(readonly payload: any) {}
}
export class ruleDeleteAction implements Action {
  readonly type = ruleDelete;
  constructor(readonly payload: any) {}
}

export class ruleDeleteSuccessAction implements Action {
  readonly type = ruleDeleteSuccess;
  constructor(readonly payload: any) {}
}
export class ruleDeleteErrorAction implements Action {
  readonly type = ruleDeleteError;
  constructor(readonly payload: any) {}
}

export type orderroutingActions =
  | ruleListRequestAction
  | ruleListSuccessAction
  | ruleSaveAction
  | ruleSaveSuccessAction
  | ruleDeleteAction
  | ruleDeleteSuccessAction
  | ruleListErrorAction
  | ruleDeleteErrorAction
  | ruleSaveErrorAction;
