import * as PaymentsActions from '../actions/payments.action';
import { PaymentsState } from '../state/payments.state';

export const initialState: PaymentsState = {
  prevPaymentsList: [],
  prevPaymentListLoaded: false,
  prevPaymentListLoading: false,

  transactionDetails: null,
  transactionDetailsLoading: false,
  transactionDetailsLoaded: false,
  templateData: {
    loading: true,
    data: null
  },

  savePaymentDocument: null,
  savePaymentDocumentLoading: false,
  savePaymentDocumentLoaded: false,

  saveStandardPaymentDocument: null,
  saveStandardPaymentDocumentLoading: false,
  saveStandardPaymentDocumentLoaded: false,

  addPayoutDetails: {
    loading: true,
    data: null
  },
  portalData: {
    data: null,
    loading: false
  },
  codOrderSummary: {
    data: null,
    loading: false
  },
  codOrderList: {
    data: null,
    loading: false
  },
  codPayoutSummary: {
    data: null,
    loading: false
  },
  codPayoutList: {
    data: null,
    loading: false
  },
  codFinanceList: {
    data: null,
    loading: false
  },
  clientList: {
    data: null,
    loading: false
  },
  memberList: {
    data: null,
    loading: false
  },
  clientAdjustmentSetting: {
    data: null,
    loading: false
  },
  codExportTemplate: {
    data: null,
    loading: false
  },
  clientBankDetails: {
    data: null,
    loading: false
  },
  orderSettlementsList: {
    data: null,
    loading: false
  },
  orderTransactionDetails: {
    data: null,
    loading: false
  },
  previousPaymentsList: {
    data: null,
    loading: false
  },
  orderSummary: {
    data: null,
    loading: false
  },
  paymentDetailList: {
    data: null,
    loading: false
  },
  paymentDetailSummary: {
    data: null,
    loading: false
  },
  orderSettlementsStatusCount: {
    data: null,
    loading: false
  },
  views: {
    data: null,
    loading: false
  }
};

export function PaymentsReducer(state = initialState, action: PaymentsActions.PaymentsUnion): PaymentsState {
  switch (action.type) {
    case PaymentsActions.PaymentsActionTypes.GET_PREV_PAYMENTS_LIST: {
      return {
        ...state,
        prevPaymentListLoaded: false,
        prevPaymentListLoading: true
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_PREV_PAYMENTS_LIST_SUCCESS: {
      return {
        ...state,
        prevPaymentListLoaded: true,
        prevPaymentListLoading: false,
        prevPaymentsList: action.payload
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_PREV_PAYMENTS_LIST_FAILURE: {
      return {
        ...state,
        prevPaymentListLoaded: true,
        prevPaymentListLoading: false
      };
    }

    case PaymentsActions.PaymentsActionTypes.CHECK_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: null,
        transactionDetailsLoading: true,
        transactionDetailsLoaded: false
      };
    }

    case PaymentsActions.PaymentsActionTypes.CHECK_TRANSACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        transactionDetailsLoading: false,
        transactionDetailsLoaded: true,
        transactionDetails: action.payload
      };
    }

    case PaymentsActions.PaymentsActionTypes.CHECK_TRANSACTION_DETAILS_FAILURE: {
      return {
        ...state,
        transactionDetailsLoading: false,
        transactionDetailsLoaded: false
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_TEMPLATE_LIST: {
      return {
        ...state,
        templateData: {
          loading: true,
          data: null
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        templateData: {
          loading: false,
          data: action.payload
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_TEMPLATE_LIST_FAILURE: {
      return {
        ...state,
        templateData: {
          loading: false,
          data: action.payload
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.SAVE_PAYMENT_BOOKING: {
      return {
        ...state,
        savePaymentDocumentLoading: true,
        savePaymentDocumentLoaded: false
      };
    }

    case PaymentsActions.PaymentsActionTypes.SAVE_PAYMENT_BOOKING_SUCCESS: {
      return {
        ...state,
        savePaymentDocumentLoading: false,
        savePaymentDocumentLoaded: true,
        savePaymentDocument: action.payload
      };
    }

    case PaymentsActions.PaymentsActionTypes.SAVE_PAYMENT_BOOKING_FAILURE: {
      return {
        ...state,
        savePaymentDocumentLoading: false,
        savePaymentDocumentLoaded: true
      };
    }

    case PaymentsActions.PaymentsActionTypes.SAVE_STANDARD_PAYMENT_BOOKING: {
      return {
        ...state,
        saveStandardPaymentDocumentLoading: true,
        saveStandardPaymentDocumentLoaded: false
      };
    }

    case PaymentsActions.PaymentsActionTypes.SAVE_STANDARD_PAYMENT_BOOKING_SUCCESS: {
      return {
        ...state,
        saveStandardPaymentDocumentLoading: false,
        saveStandardPaymentDocumentLoaded: true,
        saveStandardPaymentDocument: action.payload
      };
    }

    case PaymentsActions.PaymentsActionTypes.SAVE_STANDARD_PAYMENT_BOOKING_FAILURE: {
      return {
        ...state,
        saveStandardPaymentDocumentLoading: false,
        saveStandardPaymentDocumentLoaded: true
      };
    }

    case PaymentsActions.PaymentsActionTypes.ADD_PAYOUT_DETAILS: {
      return {
        ...state,
        addPayoutDetails: {
          loading: true,
          data: null
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.ADD_PAYOUT_DETAILS_SUCCESS: {
      return {
        ...state,
        addPayoutDetails: {
          loading: false,
          data: action.payload
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.ADD_PAYOUT_DETAILS_FAILURE: {
      return {
        ...state,
        addPayoutDetails: {
          loading: false,
          data: action.payload
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_PORTALS_LIST: {
      return {
        ...state,
        portalData: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PORTALS_LIST_SUCCESS: {
      return {
        ...state,
        portalData: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PORTALS_LIST_FAILURE: {
      return {
        ...state,
        portalData: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_SUMMARY: {
      return {
        ...state,
        codOrderSummary: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_SUMMARY_SUCCESS: {
      return {
        ...state,
        codOrderSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_SUMMARY_FAILURE: {
      return {
        ...state,
        codOrderSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_PAYOUT_SUMMARY: {
      return {
        ...state,
        codPayoutSummary: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_PAYOUT_SUMMARY_SUCCESS: {
      return {
        ...state,
        codPayoutSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_PAYOUT_SUMMARY_FAILURE: {
      return {
        ...state,
        codPayoutSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_ORDER_LIST: {
      return {
        ...state,
        codOrderList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        codOrderList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_ORDER_LIST_FAILURE: {
      return {
        ...state,
        codOrderList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_PAYOUT_LIST: {
      return {
        ...state,
        codPayoutList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_PAYOUT_LIST_SUCCESS: {
      return {
        ...state,
        codPayoutList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_PAYOUT_LIST_FAILURE: {
      return {
        ...state,
        codPayoutList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_FINANCE_LIST: {
      return {
        ...state,
        codFinanceList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_FINANCE_LIST_SUCCESS: {
      return {
        ...state,
        codFinanceList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_FINANCE_LIST_FAILURE: {
      return {
        ...state,
        codFinanceList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_LIST: {
      return {
        ...state,
        clientList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        clientList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        clientList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_MEMBER_LIST: {
      return {
        ...state,
        memberList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        memberList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_EXPORT_TEMPLATE: {
      return {
        ...state,
        codExportTemplate: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_EXPORT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        codExportTemplate: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_COD_EXPORT_TEMPLATE_FAILURE: {
      return {
        ...state,
        codExportTemplate: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_ADJUSTMENT_SETTING: {
      return {
        ...state,
        clientAdjustmentSetting: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_ADJUSTMENT_SETTING_SUCCESS: {
      return {
        ...state,
        clientAdjustmentSetting: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_ADJUSTMENT_SETTING_FAILURE: {
      return {
        ...state,
        clientAdjustmentSetting: {
          data: action.payload,
          loading: false
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_BANK_DETAILS: {
      return {
        ...state,
        clientBankDetails: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_BANK_DETAILS_SUCCESS: {
      return {
        ...state,
        clientBankDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_CLIENT_BANK_DETAILS_FAILURE: {
      return {
        ...state,
        clientBankDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SETTLEMENTS_LIST: {
      return {
        ...state,
        orderSettlementsList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SETTLEMENTS_LIST_SUCCESS: {
      return {
        ...state,
        orderSettlementsList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SETTLEMENTS_LIST_FAILURE: {
      return {
        ...state,
        orderSettlementsList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_TRANSACTION_DETAILS: {
      return {
        ...state,
        orderTransactionDetails: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_TRANSACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        orderTransactionDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_TRANSACTION_DETAILS_FAILURE: {
      return {
        ...state,
        orderTransactionDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PREVIOUS_PAYMENTS_LIST: {
      return {
        ...state,
        previousPaymentsList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PREVIOUS_PAYMENTS_LIST_SUCCESS: {
      return {
        ...state,
        previousPaymentsList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PREVIOUS_PAYMENTS_LIST_FAILURE: {
      return {
        ...state,
        previousPaymentsList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SUMMARY: {
      return {
        ...state,
        orderSummary: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SUMMARY_SUCCESS: {
      return {
        ...state,
        orderSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SUMMARY_FAILURE: {
      return {
        ...state,
        orderSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PAYMENT_DETAIL_LIST: {
      return {
        ...state,
        paymentDetailList: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PAYMENT_DETAIL_LIST_SUCCESS: {
      return {
        ...state,
        paymentDetailList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PAYMENT_DETAIL_LIST_FAILURE: {
      return {
        ...state,
        paymentDetailList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PAYMENT_DETAIL_SUMMARY: {
      return {
        ...state,
        paymentDetailSummary: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PAYMENT_DETAIL_SUMMARY_SUCCESS: {
      return {
        ...state,
        paymentDetailSummary: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_PAYMENT_DETAIL_SUMMARY_FAILURE: {
      return {
        ...state,
        paymentDetailSummary: {
          data: action.payload,
          loading: false
        }
      };
    }

    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SETTLEMENTS_STATUS_COUNT: {
      return {
        ...state,
        orderSettlementsStatusCount: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SETTLEMENTS_STATUS_COUNT_SUCCESS: {
      return {
        ...state,
        orderSettlementsStatusCount: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_ORDER_SETTLEMENTS_STATUS_COUNT_FAILURE: {
      return {
        ...state,
        orderSettlementsStatusCount: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_VIEWS: {
      return {
        ...state,
        views: {
          data: null,
          loading: true
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_VIEWS_SUCCESS: {
      return {
        ...state,
        views: {
          data: action.payload,
          loading: false
        }
      };
    }
    case PaymentsActions.PaymentsActionTypes.GET_VIEWS_FAILURE: {
      return {
        ...state,
        views: {
          data: action.payload,
          loading: false
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getPrevPaymentListLoaded = (state: PaymentsState) => state.prevPaymentListLoaded;
export const getPrevPaymentListLoading = (state: PaymentsState) => state.prevPaymentListLoading;
export const getPrevPaymentsList = (state: PaymentsState) => state.prevPaymentsList;

export const getTransactionDetails = (state: PaymentsState) => state.transactionDetails;
export const getTransactionDetailsLoading = (state: PaymentsState) => state.transactionDetailsLoading;
export const getTransactionDetailsLoaded = (state: PaymentsState) => state.transactionDetailsLoaded;
export const getTemplateData = (state: PaymentsState) => state.templateData;

export const savePaymentDocumentLoaded = (state: PaymentsState) => state.savePaymentDocumentLoaded;
export const savePaymentDocumentLoading = (state: PaymentsState) => state.savePaymentDocumentLoading;
export const savePaymentDocument = (state: PaymentsState) => state.savePaymentDocument;

export const saveStandardPaymentDocumentLoaded = (state: PaymentsState) => state.prevPaymentListLoaded;
export const saveStandardPaymentDocumentLoading = (state: PaymentsState) => state.prevPaymentListLoading;
export const saveStandardPaymentDocument = (state: PaymentsState) => state.prevPaymentsList;

export const payoutDetails = (state: PaymentsState) => state.addPayoutDetails;
export const getPortalsData = (state: PaymentsState) => state.portalData;
export const getOrderSummary = (state: PaymentsState) => state.codOrderSummary;
export const getOrderList = (state: PaymentsState) => state.codOrderList;
export const getPayoutList = (state: PaymentsState) => state.codPayoutList;
export const getPayoutSummary = (state: PaymentsState) => state.codPayoutSummary;
export const getFinancePayoutList = (state: PaymentsState) => state.codFinanceList;
export const getClientList = (state: PaymentsState) => state.clientList;
export const getMemberList = (state: PaymentsState) => state.memberList;
export const getClientAdjustmentSetting = (state: PaymentsState) => state.clientAdjustmentSetting;
export const getCodTemplate = (state: PaymentsState) => state.codExportTemplate;
export const getClientBankDetails = (state: PaymentsState) => state.clientBankDetails;
export const getOrderSettlementsList = (state: PaymentsState) => state.orderSettlementsList;
export const getOrderTransactionDetails = (state: PaymentsState) => state.orderTransactionDetails;
export const getPreviousPaymentsList = (state: PaymentsState) => state.previousPaymentsList;
export const getOrderSettlementSummary = (state: PaymentsState) => state.orderSummary;
export const getPaymentDetailList = (state: PaymentsState) => state.paymentDetailList;
export const getPaymentDetailSummary = (state: PaymentsState) => state.paymentDetailSummary;
export const getOrderSettlementStatusCount = (state: PaymentsState) => state.orderSettlementsStatusCount;
export const getViews = (state: PaymentsState) => state.views;
