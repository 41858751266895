import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containSpecification'
})
export class ContainSpecificationPipe implements PipeTransform {
  transform(keyword: string, array: string[] | null): boolean {
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    return array.some(value => value.includes(keyword));
  }
}
