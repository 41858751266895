import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDropDownFilter'
})
export class searchDropDownFilter implements PipeTransform {
  transform(items: any[], searchText?: string, filterKey: string = '', filterKey2?: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    } else {
      searchText = searchText.toLocaleLowerCase();
      if (filterKey === '') {
        // Array of strings
        return items.filter(it => {
          return it?.toLocaleLowerCase().includes(searchText);
        });
      } else if (filterKey !== '') {
        // Arrays of Objects
        return items.filter(it => {
          if (Array.isArray(it)) {
            const result = it[0][`${filterKey}`]?.toLocaleLowerCase().includes(searchText);
            if (!result && filterKey2) {
              return it[0][`${filterKey2}`]?.toLocaleLowerCase().includes(searchText);
            } else {
              return result;
            }
          } else {
            const result = it[`${filterKey}`]?.toLocaleLowerCase().includes(searchText);
            if (!result && filterKey2) {
              return it[`${filterKey2}`]?.toLocaleLowerCase().includes(searchText);
            } else {
              return result;
            }
          }
        });
      } else {
        console.log('Unhandled Case in Search Filter');
      }
    }
  }
}
