import { Action } from '@ngrx/store';
export enum PaymentsActionTypes {
  GET_PREV_PAYMENTS_LIST = '[Prev Payments Page] Get Previous Payments Details',
  GET_PREV_PAYMENTS_LIST_SUCCESS = '[Prev Payments Page success] Get Previous Payments Details Success',
  GET_PREV_PAYMENTS_LIST_FAILURE = '[Prev Payments Page failure] Get Previous Payments Details Failure',
  CHECK_TRANSACTION_DETAILS = '[Prev Payments Page failure] Check Transaction Details',
  CHECK_TRANSACTION_DETAILS_SUCCESS = '[Prev Payments Page failure] Check Transaction Details Success',
  CHECK_TRANSACTION_DETAILS_FAILURE = '[Prev Payments Page failure] Check Transaction Details Failure',

  GET_TEMPLATE_LIST = '[ADD PAYOUT] Get Template List',
  GET_TEMPLATE_LIST_SUCCESS = '[ADD PAYOUT] Get Template List Success',
  GET_TEMPLATE_LIST_FAILURE = '[ADD PAYOUT] Get Template List Failure',
  SAVE_PAYMENT_BOOKING = '[ADD PAYOUT] Save Booking Details',
  SAVE_PAYMENT_BOOKING_SUCCESS = '[ADD PAYOUT] Save Booking Details Success',
  SAVE_PAYMENT_BOOKING_FAILURE = '[ADD PAYOUT] Save Booking Details Failure',
  SAVE_STANDARD_PAYMENT_BOOKING = '[ADD PAYOUT] Save Standard Booking Details',
  SAVE_STANDARD_PAYMENT_BOOKING_SUCCESS = '[ADD PAYOUT] Save Standard Booking Details Success',
  SAVE_STANDARD_PAYMENT_BOOKING_FAILURE = '[ADD PAYOUT] Save Standard Booking Details Failure',
  ADD_PAYOUT_DETAILS = '[ADD PAYOUT] Add Payout Details',
  ADD_PAYOUT_DETAILS_SUCCESS = '[ADD PAYOUT] Add Payout Details Success',
  ADD_PAYOUT_DETAILS_FAILURE = '[ADD PAYOUT] Add Payout Details Failure',
  GET_PORTALS_LIST = '[Portals] Get Portals List',
  GET_PORTALS_LIST_SUCCESS = '[Portals] Get Portals List Success',
  GET_PORTALS_LIST_FAILURE = '[Portals] Get Portals List Failure',
  GET_COD_SUMMARY = '[Summary] Get Summary Details',
  GET_COD_SUMMARY_SUCCESS = '[Summary] Get Summary Details Success',
  GET_COD_SUMMARY_FAILURE = '[Summary] Get Summary Details Failure',
  GET_COD_PAYOUT_SUMMARY = '[Payout Summary] Get Summary Details',
  GET_COD_PAYOUT_SUMMARY_SUCCESS = '[Payout Summary] Get Summary Details Success',
  GET_COD_PAYOUT_SUMMARY_FAILURE = '[Payout Summary] Get Summary Details Failure',
  GET_COD_ORDER_LIST = '[Cod Order] Get Order Details',
  GET_COD_ORDER_LIST_SUCCESS = '[Cod Order] Get Order Details Success',
  GET_COD_ORDER_LIST_FAILURE = '[Cod Order] Get Order Details Failure',
  GET_COD_PAYOUT_LIST = '[Cod Payout] Get Payout Details',
  GET_COD_PAYOUT_LIST_SUCCESS = '[Cod Payout] Get Payout Details Success',
  GET_COD_PAYOUT_LIST_FAILURE = '[Cod Payout] Get Payout Details Failure',
  GET_COD_FINANCE_LIST = '[Cod Finance Payout] Get Finance Payout Details',
  GET_COD_FINANCE_LIST_SUCCESS = '[Cod Finance Payout] Get Finance Payout Details Success',
  GET_COD_FINANCE_LIST_FAILURE = '[Cod Finance Payout] Get Finance Payout Details Failure',
  GET_COD_MEMBER_LIST = '[Cod Member list] Get Member List',
  GET_COD_MEMBER_LIST_SUCCESS = '[Cod Member list] Get Member List Success',
  GET_COD_MEMBER_LIST_FAILURE = '[Cod Member list] Get Member List Failure',
  GET_CLIENT_LIST = '[Client List] Get Client List Details',
  GET_CLIENT_LIST_SUCCESS = '[Client List] Get Client List Success',
  GET_CLIENT_LIST_FAILURE = '[Client List] Get Client List Failure',
  GET_CLIENT_ADJUSTMENT_SETTING = '[Adjustment Setting] Get Client Adjustment Setting',
  GET_CLIENT_ADJUSTMENT_SETTING_SUCCESS = '[Adjustment Setting] Get Client Adjustment Setting Success',
  GET_CLIENT_ADJUSTMENT_SETTING_FAILURE = '[Adjustment Setting] Get Client Adjustment Setting Failure',
  GET_COD_EXPORT_TEMPLATE = '[COD TEMPLATE] COD Export Template',
  GET_COD_EXPORT_TEMPLATE_SUCCESS = '[COD TEMPLATE] COD Export Template Success',
  GET_COD_EXPORT_TEMPLATE_FAILURE = '[COD TEMPLATE] COD Export Template Failure',
  GET_CLIENT_BANK_DETAILS = '[Bank Details] Get Client Bank Details',
  GET_CLIENT_BANK_DETAILS_SUCCESS = '[Bank Details] Get Client Bank Details Success',
  GET_CLIENT_BANK_DETAILS_FAILURE = '[Bank Details] Get Client Bank Details Failure',
  GET_ORDER_SETTLEMENTS_LIST = '[Order Settlements] Get Order Settlements List',
  GET_ORDER_SETTLEMENTS_LIST_SUCCESS = '[Order Settlements] Get Order Settlements List Success',
  GET_ORDER_SETTLEMENTS_LIST_FAILURE = '[Order Settlements] Get Order Settlements List Failure',
  GET_TRANSACTION_DETAILS = '[Transaction Details] Get Transaction Details',
  GET_TRANSACTION_DETAILS_SUCCESS = '[Transaction Details] Get Transaction Details Success',
  GET_TRANSACTION_DETAILS_FAILURE = '[Transaction Details] Get Transaction Details Failure',
  GET_PREVIOUS_PAYMENTS_LIST = '[Previous Payments] Get Previous Payments List',
  GET_PREVIOUS_PAYMENTS_LIST_SUCCESS = '[Previous Payments] Get Previous Payments List Success',
  GET_PREVIOUS_PAYMENTS_LIST_FAILURE = '[Previous Payments] Get Previous Payments List Failure',
  GET_ORDER_SUMMARY = '[Order Summary] Get Order Summary',
  GET_ORDER_SUMMARY_SUCCESS = '[Order Summary] Get Order Summary Success',
  GET_ORDER_SUMMARY_FAILURE = '[Order Summary] Get Order Summary Failure',
  GET_PAYMENT_DETAIL_LIST = '[Payment detail] Get Payment Details List',
  GET_PAYMENT_DETAIL_LIST_SUCCESS = '[Payment detail] Get Payment Details List Success',
  GET_PAYMENT_DETAIL_LIST_FAILURE = '[Payment detail] Get Payment Details List Failure',
  GET_PAYMENT_DETAIL_SUMMARY = '[Payment detail] Get Payment Detail Summary',
  GET_PAYMENT_DETAIL_SUMMARY_SUCCESS = '[Payment detail] Get Payment Detail Summary Success',
  GET_PAYMENT_DETAIL_SUMMARY_FAILURE = '[Payment detail] Get Payment Detail Summary Failure',
  GET_ORDER_SETTLEMENTS_STATUS_COUNT = '[Order Settlements] Get Order Settlement Status Count',
  GET_ORDER_SETTLEMENTS_STATUS_COUNT_SUCCESS = '[Order Settlements] Get Order Settlement Status Count Success',
  GET_ORDER_SETTLEMENTS_STATUS_COUNT_FAILURE = '[Order Settlements] Get Order Settlement Status Count Failure',
  GET_VIEWS = '[Views] Get Views',
  GET_VIEWS_SUCCESS = '[Views] Get Views Success',
  GET_VIEWS_FAILURE = '[Views] Get Views Failure'
}

export class GetPrevPaymentListAction implements Action {
  readonly type = PaymentsActionTypes.GET_PREV_PAYMENTS_LIST;
  constructor(public payload: {}) {}
}
export class GetPrevPaymentListSuccessAction implements Action {
  readonly type = PaymentsActionTypes.GET_PREV_PAYMENTS_LIST_SUCCESS;

  constructor(public payload: any) {}
}
export class GetPrevPaymentListFailureAction implements Action {
  readonly type = PaymentsActionTypes.GET_PREV_PAYMENTS_LIST_FAILURE;
}
export class CheckTransactionDetails implements Action {
  readonly type = PaymentsActionTypes.CHECK_TRANSACTION_DETAILS;
  constructor(public payload: any) {}
}
export class CheckTransactionDetailsSuccess implements Action {
  readonly type = PaymentsActionTypes.CHECK_TRANSACTION_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class CheckTransactionDetailsFailure implements Action {
  readonly type = PaymentsActionTypes.CHECK_TRANSACTION_DETAILS_FAILURE;
  constructor(public payload: any) {}
}
export class GetTemplateList implements Action {
  readonly type = PaymentsActionTypes.GET_TEMPLATE_LIST;
  constructor(public payload: any) {}
}
export class GetTemplateListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_TEMPLATE_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetTemplateListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_TEMPLATE_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class SavePaymentBooking implements Action {
  readonly type = PaymentsActionTypes.SAVE_PAYMENT_BOOKING;
  constructor(public payload: any) {}
}
export class SavePaymentBookingSuccess implements Action {
  readonly type = PaymentsActionTypes.SAVE_PAYMENT_BOOKING_SUCCESS;
  constructor(public payload: any) {}
}
export class SavePaymentBookingFailure implements Action {
  readonly type = PaymentsActionTypes.SAVE_PAYMENT_BOOKING_FAILURE;
  constructor(public payload: any) {}
}

export class SaveStandardPaymentBooking implements Action {
  readonly type = PaymentsActionTypes.SAVE_STANDARD_PAYMENT_BOOKING;
  constructor(public payload: any) {}
}
export class SaveStandardPaymentBookingSuccess implements Action {
  readonly type = PaymentsActionTypes.SAVE_STANDARD_PAYMENT_BOOKING_SUCCESS;
  constructor(public payload: any) {}
}
export class SaveStandardPaymentBookingFailure implements Action {
  readonly type = PaymentsActionTypes.SAVE_STANDARD_PAYMENT_BOOKING_FAILURE;
  constructor(public payload: any) {}
}

export class AddPayoutDetails implements Action {
  readonly type = PaymentsActionTypes.ADD_PAYOUT_DETAILS;
  constructor(public payload: any) {}
}
export class AddPayoutDetailsSuccess implements Action {
  readonly type = PaymentsActionTypes.ADD_PAYOUT_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class AddPayoutDetailsFailure implements Action {
  readonly type = PaymentsActionTypes.ADD_PAYOUT_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetPortalsList implements Action {
  readonly type = PaymentsActionTypes.GET_PORTALS_LIST;
  constructor() {}
}
export class GetPortalsSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_PORTALS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPortalsFailure implements Action {
  readonly type = PaymentsActionTypes.GET_PORTALS_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetCodSummary implements Action {
  readonly type = PaymentsActionTypes.GET_COD_SUMMARY;
  constructor(public payload: any) {}
}

export class GetCodSummarySuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_SUMMARY_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodSummaryFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_SUMMARY_FAILURE;
  constructor(public payload: any) {}
}
export class GetCodPayoutSummary implements Action {
  readonly type = PaymentsActionTypes.GET_COD_PAYOUT_SUMMARY;
  constructor() {}
}

export class GetCodPayoutSummarySuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_PAYOUT_SUMMARY_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodPayoutSummaryFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_PAYOUT_SUMMARY_FAILURE;
  constructor(public payload: any) {}
}
export class GetCodOrderList implements Action {
  readonly type = PaymentsActionTypes.GET_COD_ORDER_LIST;
  constructor(public payload: any) {}
}

export class GetCodOrderListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_ORDER_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodOrderListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_ORDER_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetCodPayoutList implements Action {
  readonly type = PaymentsActionTypes.GET_COD_PAYOUT_LIST;
  constructor(public payload: any) {}
}

export class GetCodPayoutListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_PAYOUT_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodPayoutListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_PAYOUT_LIST_FAILURE;
  constructor(public payload: any) {}
}

//Actions to fetch Finance List
export class GetCodFinancePayoutList implements Action {
  readonly type = PaymentsActionTypes.GET_COD_FINANCE_LIST;
  constructor(public payload: any) {}
}

export class GetCodFinancePayoutListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_FINANCE_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodFinancePayoutListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_FINANCE_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetClientList implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_LIST;
  constructor() {}
}

export class GetClientListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetClientListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetMemberList implements Action {
  readonly type = PaymentsActionTypes.GET_COD_MEMBER_LIST;
  constructor() {}
}

export class GetMemberListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_MEMBER_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetMemberListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_MEMBER_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetCodExportTemplate implements Action {
  readonly type = PaymentsActionTypes.GET_COD_EXPORT_TEMPLATE;
  constructor(public payload: any) {}
}

export class GetCodExportTemplateSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_COD_EXPORT_TEMPLATE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodExportTemplateFailure implements Action {
  readonly type = PaymentsActionTypes.GET_COD_EXPORT_TEMPLATE_FAILURE;
  constructor(public payload: any) {}
}
export class GetClientAdjustmentSetting implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_ADJUSTMENT_SETTING;
  constructor(public payload: any) {}
}

export class GetClientAdjustmentSettingSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_ADJUSTMENT_SETTING_SUCCESS;
  constructor(public payload: any) {}
}

export class GetClientAdjustmentSettingFailure implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_ADJUSTMENT_SETTING_FAILURE;
  constructor(public payload: any) {}
}

export class GetClientBankDetails implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_BANK_DETAILS;
  constructor(public payload: any) {}
}

export class GetClientBankDetailsSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_BANK_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetClientBankDetailsFailure implements Action {
  readonly type = PaymentsActionTypes.GET_CLIENT_BANK_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

//Order Settlements
export class GetOrderSettlementsList implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SETTLEMENTS_LIST;
  constructor(public payload: any) {}
}

export class GetOrderSettlementsListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SETTLEMENTS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOrderSettlementsListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SETTLEMENTS_LIST_FAILURE;
  constructor(public payload: any) {}
}

//Transaction Details
export class GetTransactionDetails implements Action {
  readonly type = PaymentsActionTypes.GET_TRANSACTION_DETAILS;
  constructor(public payload: any) {}
}

export class GetTransactionDetailsSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_TRANSACTION_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTransactionDetailsFailure implements Action {
  readonly type = PaymentsActionTypes.GET_TRANSACTION_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetPreviousPaymentsList implements Action {
  readonly type = PaymentsActionTypes.GET_PREVIOUS_PAYMENTS_LIST;
  constructor(public payload: any) {}
}

export class GetPreviousPaymentsListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_PREVIOUS_PAYMENTS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPreviousPaymentsListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_PREVIOUS_PAYMENTS_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetOrderSummary implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SUMMARY;
  constructor(public payload: any) {}
}

export class GetOrderSummarySuccess implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SUMMARY_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOrderSummaryFailure implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SUMMARY_FAILURE;
  constructor(public payload: any) {}
}

export class GetPaymentDetailsList implements Action {
  readonly type = PaymentsActionTypes.GET_PAYMENT_DETAIL_LIST;
  constructor(public payload: any) {}
}

export class GetPaymentDetailsListSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_PAYMENT_DETAIL_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPaymentDetailsListFailure implements Action {
  readonly type = PaymentsActionTypes.GET_PAYMENT_DETAIL_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetPaymentDetailsSummary implements Action {
  readonly type = PaymentsActionTypes.GET_PAYMENT_DETAIL_SUMMARY;
  constructor(public payload: any) {}
}

export class GetPaymentDetailsSummarySuccess implements Action {
  readonly type = PaymentsActionTypes.GET_PAYMENT_DETAIL_SUMMARY_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPaymentDetailsSummaryFailure implements Action {
  readonly type = PaymentsActionTypes.GET_PAYMENT_DETAIL_SUMMARY_FAILURE;
  constructor(public payload: any) {}
}

export class GetOrderSettlementStatusCount implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SETTLEMENTS_STATUS_COUNT;
  constructor(public payload: any) {}
}

export class GetOrderSettlementStatusCountSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SETTLEMENTS_STATUS_COUNT_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOrderSettlementStatusCountFailure implements Action {
  readonly type = PaymentsActionTypes.GET_ORDER_SETTLEMENTS_STATUS_COUNT_FAILURE;
  constructor(public payload: any) {}
}

export class GetViews implements Action {
  readonly type = PaymentsActionTypes.GET_VIEWS;
  constructor() {}
}

export class GetViewsSuccess implements Action {
  readonly type = PaymentsActionTypes.GET_VIEWS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetViewsFailure implements Action {
  readonly type = PaymentsActionTypes.GET_VIEWS_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type PaymentsUnion =
  | GetPrevPaymentListAction
  | GetPrevPaymentListSuccessAction
  | GetPrevPaymentListFailureAction
  | CheckTransactionDetails
  | CheckTransactionDetailsSuccess
  | CheckTransactionDetailsFailure
  | GetTemplateList
  | GetTemplateListSuccess
  | GetTemplateListFailure
  | SavePaymentBooking
  | SavePaymentBookingSuccess
  | SavePaymentBookingFailure
  | SaveStandardPaymentBooking
  | SaveStandardPaymentBookingSuccess
  | SaveStandardPaymentBookingFailure
  | AddPayoutDetails
  | AddPayoutDetailsSuccess
  | AddPayoutDetailsFailure
  | GetPortalsList
  | GetPortalsSuccess
  | GetPortalsFailure
  | GetCodSummary
  | GetCodSummarySuccess
  | GetCodSummaryFailure
  | GetCodOrderList
  | GetCodOrderListSuccess
  | GetCodOrderListFailure
  | GetCodPayoutSummary
  | GetCodPayoutSummarySuccess
  | GetCodPayoutSummaryFailure
  | GetCodPayoutList
  | GetCodPayoutListSuccess
  | GetCodPayoutListFailure
  | GetCodFinancePayoutList
  | GetCodFinancePayoutListSuccess
  | GetCodFinancePayoutListFailure
  | GetClientList
  | GetClientListSuccess
  | GetClientListFailure
  | GetMemberList
  | GetMemberListSuccess
  | GetMemberListFailure
  | GetClientAdjustmentSetting
  | GetClientAdjustmentSettingSuccess
  | GetClientAdjustmentSettingFailure
  | GetCodExportTemplate
  | GetCodExportTemplateSuccess
  | GetCodExportTemplateFailure
  | GetClientBankDetails
  | GetClientBankDetailsSuccess
  | GetClientBankDetailsFailure
  | GetOrderSettlementsList
  | GetOrderSettlementsListSuccess
  | GetOrderSettlementsListFailure
  | GetTransactionDetails
  | GetTransactionDetailsSuccess
  | GetTransactionDetailsFailure
  | GetPreviousPaymentsList
  | GetPreviousPaymentsListSuccess
  | GetPreviousPaymentsListFailure
  | GetOrderSummary
  | GetOrderSummarySuccess
  | GetOrderSummaryFailure
  | GetPaymentDetailsList
  | GetPaymentDetailsListFailure
  | GetPaymentDetailsListSuccess
  | GetPaymentDetailsSummary
  | GetPaymentDetailsSummarySuccess
  | GetPaymentDetailsSummaryFailure
  | GetOrderSettlementStatusCount
  | GetOrderSettlementStatusCountSuccess
  | GetOrderSettlementStatusCountFailure
  | GetViews
  | GetViewsSuccess
  | GetViewsFailure;
