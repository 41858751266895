import { Action } from '@ngrx/store';
export enum ChannelsActionTypes {
  GET_CHANNELS_LIST = '[Channels Page] Get Channels Details',
  GET_CHANNELS_LIST_SUCCESS = '[Channels success] Get Channels Success',
  GET_CHANNELS_LIST_FAILURE = '[Channels failure] Get Channels Failure',

  GET_UNIQUE_CHANNELS_LIST = '[Channels List for ADD Transaction Rule] Get Channels Details',
  GET_UNIQUE_CHANNELS_LIST_SUCCESS = '[Channels List Success for ADD Transaction Rule] Get Channels Success',
  GET_UNIQUE_CHANNELS_LIST_FAILURE = '[Channels List Failure for ADD Transaction Rule] Get Channels Failure',

  GET_PORTALS_LIST = '[Channels Page] Get Portal Details',
  GET_PORTALS_LIST_SUCCESS = '[Channels success] Get Portal Success',
  GET_PORTALS_LIST_FAILURE = '[Channels failure] Get Portal Failure',

  RESET_STATE = '[Channels Module] Reset state to initial values',

  GET_FC_LIST = '[Channel] Get FC List',
  GET_FC_LIST_SUCCESS = '[Channel] Get FC List Success',
  GET_FC_LIST_FAILURE = '[Channel] Get FC List Failure'
}

export class GetChannelsAction implements Action {
  readonly type = ChannelsActionTypes.GET_CHANNELS_LIST;
}

export class GetChannelsSuccessAction implements Action {
  readonly type = ChannelsActionTypes.GET_CHANNELS_LIST_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetChannelsFailureAction implements Action {
  readonly type = ChannelsActionTypes.GET_CHANNELS_LIST_FAILURE;
}

/**
 * Get unique channel list require in add new transaction rules
 */
export class GetChannelListForADDTransctionRuleAction implements Action {
  readonly type = ChannelsActionTypes.GET_UNIQUE_CHANNELS_LIST;
}

export class GetChannelListForADDTransctionRuleSuccessAction implements Action {
  readonly type = ChannelsActionTypes.GET_UNIQUE_CHANNELS_LIST_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetChannelListForADDTransctionRuleFailureAction implements Action {
  readonly type = ChannelsActionTypes.GET_UNIQUE_CHANNELS_LIST_FAILURE;
}

export class GetPortalsAction implements Action {
  readonly type = ChannelsActionTypes.GET_PORTALS_LIST;
}

export class GetPortalsSuccessAction implements Action {
  readonly type = ChannelsActionTypes.GET_PORTALS_LIST_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetPortalsFailureAction implements Action {
  readonly type = ChannelsActionTypes.GET_PORTALS_LIST_FAILURE;
}
export class ResetFcStateAction implements Action {
  readonly type = ChannelsActionTypes.RESET_STATE;
}

export class GetFullfillmentCenterList implements Action {
  readonly type = ChannelsActionTypes.GET_FC_LIST;
  constructor() {}
}
export class GetFullfillmentCenterListSuccess implements Action {
  readonly type = ChannelsActionTypes.GET_FC_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetFullfillmentCenterListFailure implements Action {
  readonly type = ChannelsActionTypes.GET_FC_LIST_FAILURE;
  constructor(public payload: any) {}
}
/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type ChannelsUnion =
  | GetChannelsAction
  | GetChannelsSuccessAction
  | GetChannelsFailureAction
  | GetPortalsAction
  | GetPortalsSuccessAction
  | GetPortalsFailureAction
  | ResetFcStateAction
  | GetChannelListForADDTransctionRuleAction
  | GetChannelListForADDTransctionRuleSuccessAction
  | GetChannelListForADDTransctionRuleFailureAction
  | GetFullfillmentCenterList
  | GetFullfillmentCenterListSuccess
  | GetFullfillmentCenterListFailure;
