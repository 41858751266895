import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/shared/services';
import { ReplaySubject, Subscription } from 'rxjs';
import { EditScheduled } from 'src/app/auth/actions/auth.actions';
import { select } from '@ngrx/store';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
import { memberList } from '../../orderV2/store/selectors/orders.selectors';
import { OrdersState } from 'src/app/orderV2/store/state/orders.state';
@Component({
  selector: 'app-edit-scheduled',
  templateUrl: './edit-scheduled.component.html',
  styleUrls: ['./edit-scheduled.component.scss']
})
export class EditScheduledComponent implements OnInit {
  scheduleForm: FormGroup;
  loaderEdit: boolean = false;
  editScheduleSubscription$: Subscription;
  getMemberListSubscription$: Subscription;
  submitted: boolean = false;
  membersList = [];
  searchSelectBox = new FormControl();
  public filteredKeyList: ReplaySubject<any> = new ReplaySubject<any>(1);
  interval = {
    every: ['day', 'week', 'month'],
    week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    month: Array.from({ length: 29 }, (_, i) => i + 1),
    hour: Array.from({ length: 12 }, (_, i) => i + 1).map(res => (res < 10 ? '0' + res : res)),
    minutes: ['00', '15', '30', '45'],
    format: ['AM', 'PM']
  };
  constructor(
    private store: Store<any>,
    private orderStore: Store<{ ordersState: OrdersState }>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { data: any },
    private dialogRef: MatDialogRef<EditScheduledComponent>
  ) {}

  ngOnInit() {
    this.createEditScheduleForm();
    this.getTeamMembers();
    this.searchSelectBox.valueChanges.subscribe(() => {
      this.filterKeys();
    });
  }

  /** @description Schedule Form */
  public createEditScheduleForm() {
    this.scheduleForm = this.formBuilder.group({
      reportName: ['', Validators.required],
      members: ['', Validators.required],
      every: [''],
      day: [''],
      hour: [''],
      minutes: [''],
      format: ['']
    });
  }

  /** @description Patch Form Values */
  public patchData() {
    let arr;
    let memberObj = [];
    arr = this.data['interval']['time'].split(/:| /);
    if (this.data['interval'] != 'day') {
      this.scheduleForm.patchValue({
        day: this.data['interval']['day']
      });
    }
    memberObj = this.membersList.filter(val => {
      if (this.data['reportDelivery'] && this.data['reportDelivery'].includes(val.email)) return val;
    });
    this.scheduleForm.patchValue({
      reportName: this.data['report'],
      members: memberObj,
      every: this.data['interval']['every'],
      hour: arr[0],
      minutes: arr[1],
      format: arr[2]
    });
  }

  /** @description Reset Day Field on Interval Changes */
  resetDay() {
    this.scheduleForm.get('day').reset();
  }

  /** @description Member List */
  public getTeamMembers() {
    this.getMemberListSubscription$ = this.orderStore.pipe(select(memberList)).subscribe(response => {
      if (response && response.memberList) {
        this.membersList = response.memberList;
        if (this.membersList) {
          this.membersList.sort((a, b) => {
            if (a.firstName && b.firstName) {
              if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
              else if (b.firstName.toLowerCase() > a.firstName.toLowerCase()) return -1;
              else return 0;
            }
          });
          this.patchData();
        }
        this.filteredKeyList.next(this.membersList);
      }
    });
  }

  /** @description Sort List */
  public filterKeys() {
    if (!this.membersList) return;
    let search = this.searchSelectBox.value;
    if (!search) {
      this.filteredKeyList.next(this.membersList);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredKeyList.next(
      this.membersList.filter(obj => {
        if (obj && obj.firstName && obj.lastName)
          return obj.firstName.toLowerCase().indexOf(search) > -1 || obj.lastName.toLowerCase().indexOf(search) > -1;
        else if (obj && obj.firstName) return obj.firstName.toLowerCase().indexOf(search) > -1;
        else if (obj && obj.lastName) return obj.lastName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  /** @description Remove Email */
  public onRemove(member) {
    const members = this.scheduleForm.value.members as string[];
    this.scheduleForm.value.members.forEach(m => {
      if (member.email == m.email) {
        const index = members.indexOf(m);
        members.splice(index, 1);
      }
    });
    this.scheduleForm.get('members').setValue(members);
  }

  /** @description Edit Schedule */
  public editSchedule() {
    this.submitted = true;
    if (this.scheduleForm.valid) {
      this.loaderEdit = true;
      let membersArr = [];
      this.scheduleForm.value.members.forEach(m => {
        membersArr.push(m.email);
      });
      let requestBody = {
        id: this.data['id'],
        report: this.scheduleForm.value.reportName,
        reportDelivery: membersArr,
        interval: {
          every: this.scheduleForm.value.every.toLowerCase()
        }
      };
      if (requestBody['interval']['every'] === 'week') {
        requestBody['interval']['day'] = this.scheduleForm.value.day.toLowerCase();
      } else if (requestBody['interval']['every'] === 'month') {
        requestBody['interval']['day'] = this.scheduleForm.value.day;
      }
      let time = `${this.scheduleForm.value.hour}:${
        this.scheduleForm.value.minutes
      } ${this.scheduleForm.value.format.toUpperCase()}`;
      requestBody['interval']['time'] = time;
      this.store.dispatch(new EditScheduled(requestBody));
      this.editScheduleSubscription$ = this.store.pipe(select(fromSelector.editSchedule)).subscribe(response => {
        if (response && response['report']) {
          this.loaderEdit = false;
          this.alertService.showSuccess(`Your ${response['report']} Schedule Updated Successfully`);
          this.dialogRef.close({ event: 'close', data: response });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.getMemberListSubscription$) this.getMemberListSubscription$.unsubscribe();
    if (this.editScheduleSubscription$) this.editScheduleSubscription$.unsubscribe();
  }
}
