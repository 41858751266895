import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertObjectToIterator'
})
export class ConvertObjectToIteratorPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return value ? Object.keys(value) : '';
  }
}
