import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { isNullOrUndefined } from 'src/app/records/utils/common-util';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RecordsService {
  private accountslug: string;
  public manageOrderIdState: Number = 1;
  recallBoxesFilterOperator: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.accountslug = this.authService.getAccountSlug();
  }
  public changecheckbox: EventEmitter<any> = new EventEmitter();
  public setCheckBoxData(value) {
    this.changecheckbox.emit(value);
  }

  actiondetails(payload) {
    const url = `${payload.handleBar}/action`;
    return this.http.get(url);
  }

  addRecord(payload) {
    // {
    //   "productName":"Shirt",
    //   "portal": [
    //     "amazon",
    //     "flipkart"
    //   ],
    //   "brand":"montecarlo",
    //   "size": [
    //     "L",
    //     "XL",
    //     "XXL"
    //   ],
    //   "imageIcon":"cloud.appspot.com/bucket1/image.png",
    //   "isCOD":true,
    //   "address":{
    //       "hno": 220,
    //       "street": "professor colony",
    //       "city": "ambala",
    //       "country": "India"
    //     }
    // }
    const url = `${payload.handleBar}/${payload.actionHandleBar}/record`;
    return this.http.post(url, payload.body);
  }

  createRecord(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/${payload.actionHandleBar}/record`;
    return this.http.post(url, payload.body);
  }

  createChildRecord(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/${payload.actionHandleBar}/childRecord`;
    return this.http.post(url, payload.body);
  }

  createBulkRecord(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/${payload.actionHandleBar}/bulkRecords`;
    return this.http.post(url, payload.body);
  }

  createAutoGeneratedID(payload, isBulk = false, quantity = 0) {
    const url = `buildingblock/api/v1/${payload.handleBar}/${payload.fieldHandleBar}/autoGenerateID${
      isBulk ? '?isBulk=true&quantity=' + quantity : ''
    }`;
    return this.http
      .post(url, payload)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  updateRecord(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/${payload.actionHandleBar}/record/${payload.id}`;
    return this.http.put(url, payload.body);
  }

  updateRecordAsync(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/${payload.actionHandleBar}/record/${payload.id}`;
    return this.http
      .put(url, payload.body)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  editRecord(payload) {
    //   {
    //     "productName": "Shirt",
    //     "portal": [
    //         "amazon",
    //         "flipkart"
    //     ],
    //     "brand": "montecarlo",
    //     "size": [
    //         "L",
    //         "XL",
    //         "XXL"
    //     ],
    //     "imageIcon": "cloud.appspot.com/bucket1/image.png",
    //     "isCOD": true,
    //     "address": {
    //         "hno": 220,
    //         "street": "professor colony",
    //         "city": "ambala",
    //         "country": "India"
    //     }
    // }
    const url = `${payload.handleBar}/${payload.actionHandleBar}/record/${payload.id}`;
    return this.http.put(url, payload.body);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {} || '';
  }

  private extractDataBlob(res: Blob) {
    let body = res;
    return body || {};
  }

  private extractDataJSON(res: JSON) {
    let body = res;
    return body || '';
  }

  /*
  NOTE: return type is 'Promise'
  */

  viewSingleRecord(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/record/${payload.id}`;
    return this.http
      .get(url)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }
  callApiForBatchDetails(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/record/?${payload.batchDetailsIds
      .map(id => `id=|IS|${id}`)
      .join('&')}`;
    const headers = {
      headers: new HttpHeaders().set('filterOperator', 'OR')
    };
    return this.http.get(url, headers).toPromise();
  }

  listOfRecords(payload) {
    const url = `buildingblock/api/v1/${payload.handleBar}/record`;
    return this.http.get(url);
  }

  listOfRecordsPerPages(
    question,
    page = 1,
    par_page = 10,
    searchfield = '',
    searchvalue = '',
    staticFilter = '',
    dynamicFilter = '',
    sortBy = '',
    sortOrder = ''
  ) {
    let filterOperator = 'OR';
    let url = this.getURLwithFilter(
      question,
      page,
      par_page,
      searchfield,
      searchvalue,
      staticFilter,
      dynamicFilter,
      sortBy,
      sortOrder,
      filterOperator
    );

    let filter = question.validations.filter;
    if (filter) {
      filterOperator =
        filter.staticFilter && filter.staticFilter.filterOperator
          ? filter.staticFilter.filterOperator
          : filter.dynamicFilter && filter.dynamicFilter.filterOperator
          ? filter.dynamicFilter.filterOperator
          : 'OR';
    }
    if (
      question.handleBar == 'workspace' &&
      question.action == 'createBox' &&
      question.buildingBlock == 'recallBoxes' &&
      this.recallBoxesFilterOperator
    ) {
      filterOperator = this.recallBoxesFilterOperator;
    }
    const headers = {
      headers: new HttpHeaders().set('filterOperator', filterOperator)
    };

    return this.http.get(url, headers);
  }

  listOfRecordsPerPagesAsync(
    question,
    page = 1,
    par_page = 10,
    searchfield = '',
    searchvalue = '',
    staticFilter = '',
    dynamicFilter = '',
    sortBy = '',
    sortOrder = ''
  ) {
    let filterOperator = 'OR';
    let url = this.getURLwithFilter(
      question,
      page,
      par_page,
      searchfield,
      searchvalue,
      staticFilter,
      dynamicFilter,
      sortBy,
      sortOrder,
      filterOperator
    );

    let filter = question.validations.filter;
    if (filter) {
      filterOperator =
        filter.staticFilter && filter.staticFilter.filterOperator
          ? filter.staticFilter.filterOperator
          : filter.dynamicFilter && filter.dynamicFilter.filterOperator
          ? filter.dynamicFilter.filterOperator
          : 'OR';
    }

    const headers = {
      headers: new HttpHeaders().set('filterOperator', filterOperator)
    };

    return this.http
      .get(url, headers)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getURLwithFilter(
    question,
    page = 1,
    par_page = 10,
    searchfield = '',
    searchvalue = '',
    staticFilter = '',
    dynamicFilter = '',
    sortBy = '',
    sortOrder = '',
    filterOperator = ''
  ) {
    let url = `buildingblock/api/v1/${question.validations.referenceSlug}/record?page=${page}&per_page=${par_page}`;
    let filter = question.validations.filter;
    if (filter) {
      filterOperator =
        filter.staticFilter && filter.staticFilter.filterOperator
          ? filter.staticFilter.filterOperator
          : filter.dynamicFilter && filter.dynamicFilter.filterOperator
          ? filter.dynamicFilter.filterOperator
          : 'OR';
    }

    if (!isNullOrUndefined(searchvalue) && searchvalue != '') {
      url = url + `&${searchfield}`;
    }
    if (!isNullOrUndefined(staticFilter) && staticFilter != '') {
      url = url + `&${staticFilter}`;
    }
    if (!isNullOrUndefined(dynamicFilter) && dynamicFilter != '') {
      url = url + `&${dynamicFilter}`;
    }
    if (!isNullOrUndefined(sortBy) && sortBy != '') {
      url = url + `&${sortBy}&${sortOrder}`;
    }
    return url;
  }

  deleteRecord(payload) {
    let url = `buildingblock/api/v1/${payload.handleBar}/record?id=${payload.id}`;
    if (payload.session)
      url = `buildingblock/api/v1/${payload.handleBar}/record?id=${payload.id}&session=${payload.session}`;
    return this.http.delete(url);
  }
  validateRecord(payload) {
    //   {
    //     "records": [
    //         [
    //             {
    //                 "handleBar": "color",
    //                 "value": "red"
    //             },
    //             {
    //                 "handleBar": "size",
    //                 "value": "M"
    //             },
    //             {
    //                 "handleBar": "title",
    //                 "value": "shelf"
    //             }
    //         ],
    //         [
    //             {
    //                 "handleBar": "color",
    //                 "value": "red"
    //             },
    //             {
    //                 "handleBar": "size",
    //                 "value": "M"
    //             },
    //             {
    //                 "handleBar": "title",
    //                 "value": "shelf"
    //             }
    //         ]
    //     ]
    // }
    const url = `${payload.handleBar}/validate-record`;
    return this.http.post(url, payload.body);
  }

  /*====== call order API to print invoice and Shipping Label ======*/
  generateInvoiceURL(recallConsignmentNumber, workspace) {
    // const headers = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json')
    // };
    let url;
    if (environment.API_URL.includes('myeshopbox')) {
      url = `https://eshopbox-client-portal-prod.appspot.com/_ah/api/esb/v1/recall-consignment/${encodeURIComponent(
        recallConsignmentNumber
      )}`;
    } else {
      url = `https://eshopbox-portal-dev.appspot.com/_ah/api/esb/v1/recall-consignment/${encodeURIComponent(
        recallConsignmentNumber
      )}`;
    }
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('ProxyHost', workspace)
    };
    return this.http
      .get(url, headers)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  /*====== call order API to print invoice and Shipping Label ======*/
  // generateOrderLabel(payload) {
  //   const headers = {
  //     headers: new HttpHeaders().set('Content-Type', 'application/json')
  //   };
  //   let url;
  //   if (environment.API_URL.includes('myeshopbox')) {
  //     url = `https://${localStorage.getItem('workspace')}.myeshopbox.com/api/unicommerce/orders/labels`;
  //   } else {
  //     url = `https://eshopbox-wms-api-staging-dot-eshopbox-2018.el.r.appspot.com/api/unicommerce/orders/labels`;
  //   }
  //   return this.http
  //     .post(url, payload.data, headers)
  //     .pipe(
  //       map(this.extractData),
  //       tap(data => {})
  //     )
  //     .toPromise();
  // }
  // printInvoiceandShippingLabelPDF(payload) {
  //   const headers = new HttpHeaders().set('Content-Type', 'application/pdf');
  //   let url;
  //   if (environment.API_URL.includes('myeshopbox')) {
  //     url = `https://${localStorage.getItem('workspace')}.myeshopbox.com/api/unicommerce/orders/labels?orderItemIds=${
  //       payload.orderItem
  //     }&externalShipmentId=${payload.externalShipmentId}`;
  //   } else {
  //     url = `https://eshopbox-wms-api-staging-dot-eshopbox-2018.el.r.appspot.com/api/unicommerce/orders/labels?orderItemIds=${payload.orderItem}&externalShipmentId=${payload.externalShipmentId}`;
  //   }
  //   return this.http
  //     .get(url, { headers, responseType: 'blob' })
  //     .pipe(
  //       map(this.extractDataBlob),
  //       tap(data => Blob)
  //     )
  //     .toPromise();
  // }
  // printInvoiceandShippingLabel(payload) {
  //   let url;
  //   if (environment.API_URL.includes('myeshopbox')) {
  //     url = `https://${localStorage.getItem('workspace')}.myeshopbox.com/api/unicommerce/courierDetails?orderItemIds=${
  //       payload.orderItem
  //     }&externalShipmentId=${payload.externalShipmentId}`;
  //   } else {
  //     url = `https://eshopbox-wms-api-staging-dot-eshopbox-2018.el.r.appspot.com/api/unicommerce/courierDetails?orderItemIds=${payload.orderItem}&externalShipmentId=${payload.externalShipmentId}`;
  //   }
  //   return this.http
  //     .get(url)
  //     .pipe(
  //       map(this.extractData),
  //       tap(data => {})
  //     )
  //     .toPromise();
  // }

  /*====== call order API to print invoice and Shipping Label | Print invoice API changes in V1 ======*/
  generateOrderLabelV1(workspace, payload) {
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    let url;
    const accountSlug = this.authService.getAccountSlug();
    const flexAccountSlug = this.authService.getFlexSlug();
    if (environment.API_URL.includes('myeshopbox')) {
      // prod
      if (accountSlug === 'beco_blr' || workspace === 'beco_blr') {
        url = `https://${flexAccountSlug}.myeshopbox.com/compute/wms-api/api/auperator/orders/labels`;
      } else url = `https://${flexAccountSlug}.myeshopbox.com/api/auperator/orders/labels`;
    } else {
      //staging
      if (accountSlug === 'benetton-india' || workspace === 'benetton-india') {
        url = `https://${flexAccountSlug}.auperator.co/compute/wms-api/api/auperator/orders/labels`;
      } else url = `https://${flexAccountSlug}.auperator.co/api/auperator/orders/labels`;
    }
    return this.http
      .post(url, payload.data, headers)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }
  /*====== call order API to print invoice and Shipping Label ends======*/

  // Get consignment details for batching
  getConsignmentData(consignmentNumber, esin) {
    const base = environment.API_URL.includes('myeshopbox')
      ? 'https://backend-dot-eshopbox-client-portal-prod.appspot.com/'
      : 'https://backend-dot-eshopbox-portal-dev.appspot.com/';
    const URL = `${base}_ah/api/esb/v1/consignment/grnItems?page=1&perPage=10&consignmentNumber=${consignmentNumber}&skuCode=${esin}`;
    return this.http.get(URL);
  }

  getSpecialPackingInstructionDeatils() {
    this.listOfRecords({ handleBar: 'specialPackingInstruction' }).subscribe((data: any) => {
      if (data.data.length > 0) {
        localStorage.setItem('PackingInstructions', JSON.stringify(data.data));
      }
    });
  }
  generateLabel(payload) {
    let url = `buildingblock/api/v1/generateLabels`;
    return this.http.post(url, payload);
  }
  printLabel(payload) {
    let url = `buildingblock/api/v1/printLabels`;
    return this.http.post(url, payload);
  }
  bulkManifest(payload) {
    let url = `buildingblock/api/v1/manifests/createManifest/bulkRecords`;
    return this.http.post(url, payload);
  }
  getPackageList(accountSlug, listingPage) {
    let url = `https://${accountSlug}${environment.BASE_DOMAIN}/product-engine/api/v1/user/package`;
    return this.http
      .get(url, { params: { listingPage: listingPage } })
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }
  shippingModeList(accountSlug) {
    let url = `https://${accountSlug}${environment.BASE_DOMAIN}/api/v1/ruleCombination`;
    return this.http
      .get(url)
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  // getAllRecordsSkuAndBarcode(filterObj) {
  //   let url = environment.BUILDINGBLOCK_API_URL + 'orderItem/pick';
  //   if (filterObj) {
  //     url += `?${filterObj}`;
  //   }
  //   return this.http.get(url);
  // }
  unexpectedStatus(payload) {
    const url = `/buildingblock/api/v1/unexpectedReceiving`;
    return this.http.post(url, payload);
  }
}
