import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    let parsedNumber: number;
    if (value == null) {
      return '0';
    }
    if (typeof value == 'string') {
      parsedNumber = parseFloat(value.replace(/,/g, ''));
    } else {
      parsedNumber = value;
    }

    if (Math.abs(parsedNumber) >= 10000000) {
      return this.formatValue(parsedNumber, 10000000, 'CR');
    } else if (Math.abs(parsedNumber) >= 100000) {
      return this.formatValue(parsedNumber, 100000, 'L');
    } else if (Math.abs(parsedNumber) >= 1000) {
      return this.formatValue(parsedNumber, 1000, 'K');
    } else {
      return parsedNumber.toString();
    }
  }

  private formatValue(value: number, divisor: number, suffix: string): string {
    const result = value / divisor;
    const decimalPart = (result % 1) * 100;

    if (decimalPart === 0) {
      return Math.floor(result).toFixed(0) + suffix;
    } else {
      return this.getDecimalValues(value, divisor) + suffix;
    }
  }

  private getDecimalValues(value: number, divisor: number) {
    let result = value / divisor;

    if (divisor == 1000) {
      return (Math.floor(result * 10) / 10).toString();
    } else if (divisor == 100000) {
      return (Math.floor(result * 100) / 100).toString();
    } else if (divisor == 10000000) {
      return (Math.floor(result * 1000) / 1000).toString();
    }
  }
}
