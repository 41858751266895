import { Component, Input, OnInit } from '@angular/core';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';

@Component({
  selector: 'app-export-info-icon',
  templateUrl: './export-info-icon.component.html',
  styleUrls: ['./export-info-icon.component.scss']
})
export class ExportInfoIconComponent implements OnInit {
  @Input() productExport: any;
  salesChannelData: any;
  enrolledFCData: any;

  constructor(private filterHelperService: FilterHelperService) {
    this.salesChannelData = JSON.parse(localStorage.salesChannel) ? JSON.parse(localStorage.salesChannel) : [];
    let fullfillmentCenterData = JSON.parse(localStorage.fulfillmentCenter);
    this.enrolledFCData = fullfillmentCenterData
      ? fullfillmentCenterData.filter(data => data['enrollmentStatus'] == 'ENROLLED')
      : [];
  }

  ngOnInit() {}
}
