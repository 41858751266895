<header
  class="mainsidebar mobile_element buildingBlocksSidebar"
  [ngClass]="(fullwidth ? 'collapsed' : 'expand no-shadow') + ' ' + (enterMouse ? 'expand' : '') + ' '"
>
  <div (click)="collapsemenu()" data-tooltip="Collapse" *ngIf="collapsenav" class="overlay_back"></div>
  <button class="menu-button hide-on-mob" (click)="collapsemenu()" *ngIf="collapsenav" data-tooltip="Collapse">
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </button>
  <button class="menu-button" id="menu_button" (click)="expandmenu()" *ngIf="expandnav" data-tooltip="Expand">
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/menu.svg" />
  </button>
  <div [ngClass]="workspaceStatus == '1' ? 'no-top-one' : ''" class="menu-wrp">
    <app-loader-ui *ngIf="loadingWorkspace"></app-loader-ui>
    <!-- <div class="collapse-expand-menu">
       <a class="toggle-menu collapse tooltip" (click)="collapsemenu()" *ngIf="checkcollapsenav()"
        data-tooltip="Collapse">
        <img class="img1" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/expend-icon.png" />
        <img class="img2" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/expend-hover.png" />
      </a>
      <a class="toggle-menu expand tooltip" (click)="expandmenu()" *ngIf="checkexpandnav()" data-tooltip="Expand">
        <img
          class="img1"
          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/collapes-expend-icon.png"
        />
        <img
          class="img2"
          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/collapes-hover.png"
        />
      </a>
    </div> -->
    <div class="choose-workspace nb-choose-workspace">
      <div class="left-workspace">
        <a class="eshop-logo"
          ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg" alt=""
        /></a>
        <div class="workspace">
          <div class="select-fullfilment">
            <ul class="display-on-mob" [ngClass]="{ 'mb-choose-center': accountList?.length > 1 }">
              <li>
                <ng-container *ngIf="accountList?.length > 1; else oneWorkspace">
                  <a
                    (click)="showDropdown = !showDropdown"
                    class="select-item"
                    [ngClass]="showDropdown ? 'rotate-icon' : ''"
                  >
                    <span>
                      {{ currentFacilityLabel }}
                    </span>
                  </a>
                  <div
                    (click)="showDropdown = !showDropdown"
                    class="searchoverlay mb-searchoverlay"
                    [ngClass]="showDropdown ? 'visible' : ''"
                  ></div>
                  <ul *ngIf="showDropdown" [class.show-dropdown]="showDropdown">
                    <div class="header-switch">
                      <h2>Switch location</h2>
                      <button class="close-switch" (click)="showDropdown = !showDropdown"></button>
                    </div>
                    <div class="search-location">
                      <input
                        type="text"
                        [(ngModel)]="searchFc"
                        name="searchLocation"
                        placeholder="Search location"
                        class="switch-location-input"
                        *ngIf="accountList.length > 3"
                      />
                    </div>
                    <div class="search-account-list">
                      <ng-container *ngFor="let w of accountList | searchFilter: searchFc:'searchFC'">
                        <li
                          (click)="selectedFC(w.accountSlug)"
                          [class.active]="w?.facilityLabel === currentFacilityLabel"
                        >
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg"
                            alt=""
                          />
                          <p>
                            {{ w?.facilityLabel }}
                          </p>
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/red-tick-icon.svg"
                            class="mb-tick-icon"
                            alt=""
                          />
                        </li>
                      </ng-container>
                    </div>
                  </ul>
                </ng-container>
                <ng-template #oneWorkspace>
                  <a class="select-item remove-after">
                    <span>
                      {{ currentFacilityLabel }}
                    </span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <button
        class="menu-button close-button display-on-mob"
        (click)="collapsemenu()"
        *ngIf="collapsenav"
        data-tooltip="Collapse"
      >
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
      </button>
    </div>
    <div class="navbar nav-no-icon">
      <mat-accordion class="mat-expansion-demo-width">
        <div>
          <div class="menu-header-label">Consignments</div>
          <a class="nav-item" [class.activeUrl]="toggleClass('grn')" (click)="getSelectedBuildingBlock('grn')"
            ><span class="item-name">Receive consignment </span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('inwardConsignment')"
            (click)="getSelectedBuildingBlock('inwardConsignment')"
            ><span class="item-name">Close consignment </span></a
          >
        </div>
        <div>
          <div class="menu-header-label">Manage inventory</div>
          <a class="nav-item" [class.activeUrl]="toggleClass('putaways')" (click)="getSelectedBuildingBlock('putaways')"
            ><span class="item-name">Putaway inventory</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('shelfTransfer')"
            (click)="getSelectedBuildingBlock('shelfTransfer')"
            ><span class="item-name">Shelf transfer</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('foundInventory')"
            (click)="getSelectedBuildingBlock('foundInventory')"
            ><span class="item-name">Mark found</span></a
          >
          <a
            *ngIf="currentWorkspace === 'eshop'"
            class="nav-item"
            [class.activeUrl]="toggleClass('searchItemBarcode')"
            (click)="getSelectedBuildingBlock('searchItemBarcode')"
            ><span class="item-name">Search product</span></a
          >
        </div>
        <div>
          <div class="menu-header-label">Remove inactive inventory</div>
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('recallBoxes')"
            (click)="getSelectedBuildingBlock('recallBoxes')"
            ><span class="item-name">Pack</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('recallConsignment')"
            (click)="getSelectedBuildingBlock('recallConsignment')"
            ><span class="item-name">Ship</span></a
          >
        </div>
        <div>
          <div class="menu-header-label">Process orders</div>
          <a class="nav-item" [class.activeUrl]="toggleClass('shipment')" (click)="getSelectedBuildingBlock('shipment')"
            ><span class="item-name">Create picklist</span></a
          >
          <a class="nav-item" [class.activeUrl]="toggleClass('picklist')" (click)="getSelectedBuildingBlock('picklist')"
            ><span class="item-name">Pick and pack</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('manifests')"
            (click)="getSelectedBuildingBlock('manifests')"
            ><span class="item-name">Ship</span></a
          >
        </div>
        <div>
          <div class="menu-header-label">Process returns</div>
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('receiveReturn2')"
            (click)="getSelectedBuildingBlock('receiveReturn2')"
            ><span class="item-name">Receive</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('receivedReturnShipments2')"
            (click)="getSelectedBuildingBlock('receivedReturnShipments2')"
            ><span class="item-name">Perform QC</span></a
          >
          <a
            *ngIf="currentWorkspace === 'eshop'"
            class="nav-item"
            [class.activeUrl]="toggleClass('ajioReturnLR')"
            (click)="getSelectedBuildingBlock('ajioReturnLR')"
            ><span class="item-name">Return gatepass</span></a
          >
          <a
            *ngIf="currentWorkspace === 'eshop'"
            class="nav-item"
            [class.activeUrl]="toggleClass('refusedShipments')"
            (click)="getSelectedBuildingBlock('refusedShipments')"
            ><span class="item-name">Refuse</span></a
          >
        </div>
        <div>
          <div class="menu-header-label">Process B2B orders</div>
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('recallConsignmentSellable')"
            (click)="getSelectedBuildingBlock('recallConsignmentSellable')"
            ><span class="item-name">Confirm</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('shipmentsB2B')"
            (click)="getSelectedBuildingBlock('shipmentsB2B')"
            ><span class="item-name">Create picklist</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('picklistB2B')"
            (click)="getSelectedBuildingBlock('picklistB2B')"
            ><span class="item-name">Pick</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('recallSellableBox')"
            (click)="getSelectedBuildingBlock('recallSellableBox')"
            ><span class="item-name">Pack</span></a
          >
          <a
            class="nav-item"
            [class.activeUrl]="toggleClass('recallManifest')"
            (click)="getSelectedBuildingBlock('recallManifest')"
            ><span class="item-name">Ship</span></a
          >
        </div>
      </mat-accordion>
    </div>

    <!-- Mobile Auth and Switch FC-->
    <div
      class="slidebar-switch-fc-container-overlay"
      [ngClass]="switchWorkspaceOpen ? 'open' : ''"
      (click)="switchWorkspaceOpen = !switchWorkspaceOpen"
    ></div>
    <div class="slidebar-switch-fc-container open" *ngIf="switchWorkspaceOpen">
      <ul class="active" [ngClass]="activeSearch ? 'clicked' : ''">
        <div class="search-header">
          <h2>Switch workspace</h2>
          <button class="close-switch-fc" (click)="switchWorkspaceOpen = !switchWorkspaceOpen"></button>
        </div>
        <div class="search-location">
          <input
            type="text"
            [(ngModel)]="searchWorkspace"
            name="searchWorkspace"
            placeholder="Search workspace"
            *ngIf="clientWorkspaceWithFCList.length > 3"
            (click)="focusInput($event)"
          />
        </div>
        <div class="search-list">
          <ng-container
            *ngFor="
              let workspace of clientWorkspaceWithFCList | searchFilter: searchWorkspace:'workspace';
              let in = index
            "
          >
            <ng-container *ngIf="in < 51">
              <ng-container [ngSwitch]="workspace.status">
                <!-- Account under review -->
                <ng-container *ngSwitchCase="'0'">
                  <li>
                    <span class="list-ellipsis-name">{{ workspace?.workspace }} </span>
                    <span class="user-status under-review">Under review</span>
                  </li>
                </ng-container>

                <!--Workspace is Inactive -->
                <ng-container *ngSwitchCase="'2'">
                  <li disabled>
                    <span class="list-ellipsis-name">{{ workspace?.workspace }} </span>
                    <span class="user-status inactive">Inactive</span>
                  </li>
                </ng-container>

                <!--Workspace is Active -->
                <ng-container *ngSwitchDefault>
                  <li
                    (click)="onWorkspaceSelection(workspace)"
                    [disableRipple]="true"
                    [ngClass]="authService.getAccountSlug() == workspace.accountSlug ? 'active' : ''"
                  >
                    {{ workspace?.workspace }}
                  </li>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ul>
    </div>
    <div
      class="slidebar-auth-container-overlay"
      [ngClass]="authOpen ? 'open' : ''"
      (click)="authOpen = !authOpen"
    ></div>
    <div class="slidebar-auth-container" [ngClass]="authOpen ? 'open' : ''">
      <div class="slidebar-auth">
        <div class="nameblock">
          <div class="userimg" (click)="authOpen = !authOpen">
            <div class="user-avatar">
              {{ user?.firstName ? (user?.firstName)[0] : '' }}{{ user?.lastName ? (user?.lastName)[0] : '' }}
            </div>
          </div>
          <div class="username" (click)="authOpen = !authOpen">
            <h2>
              {{ user?.firstName }} {{ user?.lastName }}
              <span> {{ workspaceName }} </span>
            </h2>
          </div>
          <div class="arrow-right">
            <button class="close-auth" (click)="authOpen = !authOpen"></button>
            <img
              class="arrow"
              src="https://ik.imagekit.io/2gwij97w0o/chevron-right.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1678182228894"
            />
          </div>
        </div>
        <!-- <div class="shitch-fc-button-single" *ngIf="clientWorkspaceWithFCList.length == 1">
          <div class="inside-fc">
            <p>{{ workspaceName }}</p>
          </div>
        </div> -->
        <div
          *ngIf="clientWorkspaceWithFCList.length > 1"
          class="shitch-fc-button"
          (click)="switchWorkspaceOpen = !switchWorkspaceOpen"
        >
          <div class="inside-fc">
            <p>{{ workspaceName }}</p>
          </div>
          <img
            src="https://ik.imagekit.io/2gwij97w0o/chevron-right.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1678182228894"
          />
        </div>
        <div class="logout-button" (click)="onLogout()">
          <img
            src="https://ik.imagekit.io/2gwij97w0o/power-icon-small.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1678183955253"
            alt=""
          />
          <div class="inside-logout">
            <p>Logout</p>
            <span>{{ user?.email }}</span>
          </div>
        </div>
      </div>
      <!-- Mobile Auth and Switch FC-->
    </div>
    <!-- Mobile Auth and Switch FC-->
  </div>
</header>
