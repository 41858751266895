import { Action } from '@ngrx/store';

export enum AppAuthorizationActionTypes {
  CHECK_APP_AUTHORIZATION = '[APP AUTHORIZATION] Check App Authorization',
  CHECK_APP_AUTHORIZATION_SUCCESS = '[APP AUTHORIZATION] Check App Authorization Success',
  CHECK_APP_AUTHORIZATION_FAILURE = '[APP AUTHORIZATION] Check App Authorization Failure'
}

export class CheckAppAuthorization implements Action {
  readonly type = AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION;
  constructor(public payload: any) {}
}

export class CheckAppAuthorizationSuccess implements Action {
  readonly type = AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION_SUCCESS;
  constructor(public payload: any) {}
}

export class CheckAppAuthorizationFailure implements Action {
  readonly type = AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION_FAILURE;
  constructor(public payload: any) {}
}

export type AppAuthorizationActions =
  | CheckAppAuthorization
  | CheckAppAuthorizationSuccess
  | CheckAppAuthorizationFailure;
