import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReconnectNotificationService {
  private localStorageKey = 'reconnect_notification_dismissed';
  constructor() {
    this.isDismissed = JSON.parse(localStorage.getItem(this.localStorageKey)) || false;
  }
  private isDismissed: boolean = false;

  dismissNotification(): void {
    this.isDismissed = true;
    localStorage.setItem(this.localStorageKey, JSON.stringify(true));
  }

  shouldShowNotification(): boolean {
    return !this.isDismissed;
  }
}
