import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SettingsService } from '../../../shared/services/settings.service';
import { UserService } from 'src/app/shared/services';

import {
  SettingsActionTypes,
  AddFeeRuleAction,
  AddFeeRuleSuccessAction,
  GetFeeRuleAction,
  EditFeeRuleAction,
  GetFeeListAction,
  GetFeeDetailsAction,
  AddFeeCorrectionAction,
  ResetAddBulkFeeRuleAction,
  GetPropertyDetailsAction,
  GetTeamMembersData,
  getEInvoicingStatus,
  GetWorkspaceSettingsData,
  UpdateWorkspaceSettingsData,
  GetBankDetailsList,
  GetBankDetails,
  GetBusinessDetails
} from '../actions/settings.actions';
import { ROUTES } from 'src/app/constants/routes';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService,
    private router: Router,
    private userService: UserService
  ) {}

  @Effect()
  addFees$: Observable<any> = this.actions$.pipe(
    ofType<AddFeeRuleAction>(SettingsActionTypes.ADD_FEE_RULE),
    switchMap(action => {
      return this.settingsService.addFeeRule(action.payload).pipe(
        map(user => {
          return { type: SettingsActionTypes.ADD_FEE_RULE_SUCCESS, payload: user };
        }),
        catchError(error => of({ type: SettingsActionTypes.ADD_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  resetAddBulkFess$: Observable<any> = this.actions$.pipe(
    ofType<ResetAddBulkFeeRuleAction>(SettingsActionTypes.RESET_ADD_BULK_FEE_RULE_SUCCESS),
    tap(data => {
      return { type: SettingsActionTypes.RESET_ADD_BULK_FEE_RULE, payload: {} };
    })
  );

  @Effect({ dispatch: false })
  addFeesSuccess$ = this.actions$.pipe(
    ofType<AddFeeRuleSuccessAction>(SettingsActionTypes.ADD_FEE_RULE_SUCCESS),
    tap(data => {
      if (this.router.url == '/settings/add-fees-rule' && data && data['payload']) {
        this.router.navigate([
          '../settings/fees-details',
          data['payload']['feeRuleDto']['portalId'],
          data['payload']['feeRuleDto']['feeId'],
          data['payload']['feeRuleDto']['version']
        ]);
      } else if (data && data['payload']) {
        this.router.navigate([
          '../settings/upload-fees',
          data['payload']['portalId'],
          data['payload']['feeId'],
          data['payload']['feePortalId'],
          data['payload']['version']
        ]);
        // ['../../../../upload-fees', portalId, feeId, feePortalId]
      } else {
        this.router.navigate([ROUTES.SETTINGS_FEES]);
      }
    })
  );

  /*/!* error fee rule error handling *!/

  @Effect({ dispatch: false })
  addFeesFailure$ = this.actions$.pipe(
    ofType<AddFeeRuleFailureAction>(SettingsActionTypes.ADD_FEE_RULE_FAILURE),
    tap(data => {
    })
  );
*/
  @Effect()
  addBulkFess: Observable<any> = this.actions$.pipe(
    ofType<AddFeeRuleAction>(SettingsActionTypes.ADD_BULK_FEE_RULE),
    switchMap(action => {
      return this.settingsService.uploadFileInBulk(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.ADD_BULK_FEE_RULE_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.ADD_BULK_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  addFeesCorrection: Observable<any> = this.actions$.pipe(
    ofType<AddFeeCorrectionAction>(SettingsActionTypes.ADD_FEE_CORRECTION),
    switchMap(action => {
      return this.settingsService.uploadFileInFeesCorrection(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.ADD_FEE_CORRECTION_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.ADD_BULK_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getBulkFess: Observable<any> = this.actions$.pipe(
    ofType<AddFeeRuleAction>(SettingsActionTypes.GET_BULK_FEE_RULE),
    switchMap(action => {
      return this.settingsService.getUploadFileInBulk(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.GET_BULK_FEE_RULE_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.GET_BULK_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  cloneFess: Observable<any> = this.actions$.pipe(
    ofType<AddFeeRuleAction>(SettingsActionTypes.CLONE_FEE_RULE),
    switchMap(action => {
      return this.settingsService.cloneFee(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.CLONE_FEE_RULE_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.CLONE_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getPortalsList$: Observable<any> = this.actions$.pipe(
    ofType<GetFeeRuleAction>(SettingsActionTypes.GET_FEE_RULE),
    switchMap(action => {
      return this.settingsService.getFeeRuleById(action.payload).pipe(
        map(data => {
          if (data) {
            for (let i = 0; i < data['fee'].length; i++) {
              if (
                typeof data['fee'][i]['partialPercentageValue'] != 'undefined' &&
                data['fee'][i]['partialPercentageValue'] == 0
              ) {
                data['fee'][i]['partialPercentageValue'] = '';
              }
            }
          }
          return { type: SettingsActionTypes.GET_FEE_RULE_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.GET_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  editFees$: Observable<any> = this.actions$.pipe(
    ofType<EditFeeRuleAction>(SettingsActionTypes.EDIT_FEE_RULE),
    switchMap(action => {
      return this.settingsService.editFeeRule(action.payload).pipe(
        map(data => {
          if (data && data['payload']) {
            this.router.navigate([
              '../settings/fees-details',
              data['payload']['portalId'],
              data['payload']['feeId'],
              data['payload']['version']
            ]);
          } else {
            this.router.navigate([ROUTES.SETTINGS_FEES]);
          }
          return { type: SettingsActionTypes.EDIT_FEE_RULE_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.EDIT_FEE_RULE_FAILURE, payload: error }))
      );
    })
  );

  // @Effect()
  // editFeesSuccess$ = this.actions$.pipe(
  //   ofType<EditFeeRuleSuccessAction>(SettingsActionTypes.EDIT_FEE_RULE_SUCCESS),
  //   tap(data => {
  //     if (data && data['payload']) {
  //       this.router.navigate([
  //         '../settings/fees-details',
  //         data['payload']['portalId'],
  //         data['payload']['feeId'],
  //         data['payload']['version']
  //       ]);
  //     } else {
  //       this.router.navigate([ROUTES.SETTINGS_FEES]);
  //     }
  //   })
  // );

  @Effect()
  getFeeList$: Observable<any> = this.actions$.pipe(
    ofType<GetFeeListAction>(SettingsActionTypes.GET_FEE_LIST),
    switchMap(action => {
      return this.settingsService.getFeeList(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.GET_FEE_LIST_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.GET_FEE_LIST_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getFeeDetails$: Observable<any> = this.actions$.pipe(
    ofType<GetFeeDetailsAction>(SettingsActionTypes.GET_FEE_DETAILS),
    switchMap(action => {
      return this.settingsService.getFeeDetailsList(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.GET_FEE_DETAILS_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.GET_FEE_DETAILS_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getPropertyDetails$: Observable<any> = this.actions$.pipe(
    ofType<GetPropertyDetailsAction>(SettingsActionTypes.GET_PROPERTY_DETAILS),
    switchMap(action => {
      return this.settingsService.getPropertyDetails(action.payload).pipe(
        map(data => {
          return { type: SettingsActionTypes.GET_PROPERTY_DETAILS_SUCCESS, payload: data };
        }),
        catchError(error => of({ type: SettingsActionTypes.GET_PROPERTY_DETAILS_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getTeamMembersData$: Observable<Action> = this.actions$.pipe(
    ofType<GetTeamMembersData>(SettingsActionTypes.GET_TEAM_MEMBERS_DATA),
    mergeMap(action => {
      return this.userService.getTeamMemberList().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_TEAM_MEMBERS_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_TEAM_MEMBERS_DATA_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getBankDetailsList$: Observable<Action> = this.actions$.pipe(
    ofType<GetBankDetailsList>(SettingsActionTypes.GET_BANK_DETAILS_LIST),
    mergeMap(action => {
      return this.userService.getBankDetailsList().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_BANK_DETAILS_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_BANK_DETAILS_LIST_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getBankDetails$: Observable<Action> = this.actions$.pipe(
    ofType<GetBankDetails>(SettingsActionTypes.GET_BANK_DETAILS),
    mergeMap(action => {
      return this.userService.getBankDetails().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_BANK_DETAILS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_BANK_DETAILS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getBusinessDetails$: Observable<Action> = this.actions$.pipe(
    ofType<GetBusinessDetails>(SettingsActionTypes.GET_BUSINESS_DETAILS),
    mergeMap(action => {
      return this.userService.getBusinessDetails().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_BUSINESS_DETAILS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_BUSINESS_DETAILS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getEInvoicingStatus$: Observable<Action> = this.actions$.pipe(
    ofType<getEInvoicingStatus>(SettingsActionTypes.GET_EINVOICE_STATUS),
    mergeMap(action => {
      return this.settingsService.getEInvoicingStatus().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_EINVOICE_STATUS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_EINVOICE_STATUS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getWorkspaceSettingsData$: Observable<Action> = this.actions$.pipe(
    ofType<GetWorkspaceSettingsData>(SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA),
    mergeMap(action => {
      return this.settingsService.getWorkspaceSettings().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA_FAILURE,
            payload: error
          })
        )
      );
    })
  );
  @Effect()
  updateWorkspaceSettingsData$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateWorkspaceSettingsData>(SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA),
    mergeMap(action => {
      return this.settingsService.updateWorkspaceSettings(action.payload).pipe(
        map(data => ({
          type: SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getCodPaymentTermDetails$: Observable<Action> = this.actions$.pipe(
    ofType<GetBusinessDetails>(SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS),
    mergeMap(action => {
      return this.userService.getCodPaymentTermDetails().pipe(
        map(data => ({
          type: SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS_FAILURE,
            payload: error
          })
        )
      );
    })
  );
}
