<div class="page-502-wrp">
  <h2>Error 502</h2>
  <h4>Bad gateway</h4>
  <div class="gray-bg">
    <ul>
      <li>
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/working-icon.png" />
        <span> You </span>
        <h3>Browser</h3>
        <h4>Working</h4>
      </li>
      <li><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/compar-icon.png" /></li>
      <li>
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-icon.png" />
        <span> Eshopbox.com </span>
        <h3>Host</h3>
        <h4 class="error">Error</h4>
      </li>
      <div class="clear"></div>
    </ul>
  </div>
  <div class="info-txt">
    <div class="col-6">
      <h3>What happened?</h3>
      <p>The web server reported bad gateway error.</p>
    </div>
    <div class="col-6">
      <h3>What can you do?</h3>
      <p>Please try again in a few minutes.</p>
    </div>
    <div class="clear"></div>
  </div>
</div>
