import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { getCurrentUser } from '../../auth/reducers/auth.selectors';
@Injectable({
  providedIn: 'root'
})
export class ZohoService {
  baseUrl = environment.zohoBaseURL;
  // accountId = 816291000001331001;
  // AuthorizationToken = 'Zoho-oauthtoken 1000.0b7c253834839264b599f7488d55a565.6df7443c3a717cfa1584879c9a8eb138 ';
  // orgId = '800183637';
  portalId = 'edbsncad354472025348b782c22fa42dc45fd49520424c8a5df79c214e92af853fb86';
  zohoContactAPICalled: number = 0;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private cookieService: CookieService,
    private store: Store<{ auth }>
  ) {}

  headers = new HttpHeaders({
    Authorization: `Bearer ${this.auth.getAuthToken()}`
  });
  createTicket(data) {
    const apiurl = `ticket`;
    return this.http.post(this.baseUrl + apiurl, data, { headers: this.headers });
  }
  uploadFile(data) {
    const apiurl = `upload`;
    return this.http.post(this.baseUrl + apiurl, data, { headers: this.headers });
  }

  getTicketData(id) {
    const apiurl = `tickets/${id}`;

    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }
  getAllTickets(params?) {
    const objString = '?' + new URLSearchParams(params).toString();

    const apiurl = `tickets/search${objString}`;

    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }
  getAllConversations(ticketId) {
    const apiurl = `tickets/${ticketId}/conversations`;

    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }

  searchAllContacts(email) {
    const apiurl = `contacts/search?email=${email}`;

    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }
  searchAllAccounts(workspaceName) {
    const apiurl = `accounts/search?accountName=${workspaceName}`;

    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }

  createContact(data) {
    const apiurl = `contact`;
    return this.http.post(this.baseUrl + apiurl, data, { headers: this.headers });
  }
  createAccount(data) {
    const apiurl = `account`;
    return this.http.post(this.baseUrl + apiurl, data, { headers: this.headers });
  }

  updateContact(id, data) {
    const apiurl = `contacts/${id}`;
    return this.http.put(this.baseUrl + apiurl, data, { headers: this.headers });
  }
  getThread(ticketId, threadId) {
    const apiurl = `tickets/${ticketId}/threads/${threadId}`;
    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }
  getComment(ticketId, commentId) {
    const apiurl = `tickets/${ticketId}/comments/${commentId}`;
    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }
  getFile(params) {
    const objString = '?' + new URLSearchParams(params).toString();
    const apiurl = `download${objString}`;
    return this.http.get(this.baseUrl + apiurl, { headers: this.headers });
  }
  setZohoContactToCookie() {
    this.store.pipe(select(getCurrentUser)).subscribe((result: any) => {
      if (result) {
        this.zohoContactCheck(result.email, result.lastName, result.firstName, result.phone);
      }
    });
  }
  zohoContactCheck(email: string, lastName: string, firstName: string, mobile: string) {
    if (this.zohoContactAPICalled === 0) {
      this.zohoContactAPICalled = 1;
      this.searchAllContacts(email).subscribe((data: any) => {
        if (data) {
          let zohoContactDetails = data.data[0];
          this.cookieService.set(
            'zohoContact',
            JSON.stringify(zohoContactDetails.id),
            3,
            '/',
            environment.COOKIE_DOMAIN,
            false,
            'Lax'
          );

          //localStorage.setItem('zohoContact', JSON.stringify(zohoContactDetails));
        } else {
          if (!lastName) {
            lastName = ' ';
          }
          this.createContact({
            lastName: lastName,
            email: email,
            firstName: firstName,
            mobile: mobile
          }).subscribe((data: any) => {
            let zohoContactDetails = data;
            this.cookieService.set(
              'zohoContact',
              JSON.stringify(zohoContactDetails.id),
              3,
              '/',
              environment.COOKIE_DOMAIN,
              false,
              'Lax'
            );
          });
        }
      });
    }
  }
  setZohoAccountToCookie(slug) {
    this.searchAllAccounts(slug).subscribe((data: any) => {
      if (data) {
        let zohoAccountDetails = data.data[0];
        this.cookieService.set(
          'zohoAccount',
          JSON.stringify({ id: zohoAccountDetails.id, accountName: zohoAccountDetails.accountName }),
          3,
          '/',
          environment.COOKIE_DOMAIN,
          false,
          'Lax'
        );
        let zohoContact = JSON.parse(this.cookieService.get('zohoContact'));
        this.updateContact(zohoContact, { accountId: zohoAccountDetails.id }).subscribe((data: any) => {
          console.log(data);
        });
      } else {
        this.createAccount({
          accountName: slug
        }).subscribe((data: any) => {
          let zohoAccountDetails = data;
          this.cookieService.set(
            'zohoAccount',
            JSON.stringify({ id: zohoAccountDetails.id, accountName: zohoAccountDetails.accountName }),
            3,
            '/',
            environment.COOKIE_DOMAIN,
            false,
            'Lax'
          );
          let zohoContact = JSON.parse(this.cookieService.get('zohoContact'));
          this.updateContact(zohoContact, { accountId: zohoAccountDetails.id }).subscribe((data: any) => {
            console.log(data);
          });
        });
      }
    });
  }
  updateContactwithCurrentAccount() {
    let zohoContact = JSON.parse(this.cookieService.get('zohoContact'));
    let zohoAccount = JSON.parse(this.cookieService.get('zohoAccount'));

    this.updateContact(zohoContact, { accountId: zohoAccount.id }).subscribe((data: any) => {
      console.log(data);
    });
  }
  createThread(data, ticketId) {
    const apiurl = `tickets/${ticketId}/comment`;
    const zohoPortalToken = this.cookieService.get('zohoPortal');
    return this.http.post(this.baseUrl + apiurl, data, {
      headers: this.headers
    });
  }
}
