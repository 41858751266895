export const ERROR_MESSAGES = {
  GET_ACCOUNT: {
    '101': 'Something went wrong, please try after some time.'
  },
  CREATE_ACCOUNT: {
    '101': 'Invalid user',
    '105': 'Something went wrong, please try after some time.'
  },
  GET_ACCOUNT_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  UPDATE_ACCOUNT: {
    '101': 'User role is invalid',
    '102': 'Something went wrong, please try after some time.',
    '103': 'User role is invalid',
    '104': 'Something went wrong, please try after some time.'
  },
  GET_PROFILE: {
    '101': 'Something went wrong, please try after some time.'
  },
  GET_USER_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  CHECK_EMAIL: {
    '101': 'Something went wrong, please try after some time.'
  },
  GET_BRAND_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  GET_BRAND: {
    '101': 'Something went wrong, please try after some time.'
  },
  CREATE_BRAND: {
    '102': 'Something went wrong, please try after some time.',
    '101': 'Brand <brandName> already exist. Try another.'
  },
  UPDATE_BRAND: {
    '101': 'Brand <brandName> does not exist.',
    '102': 'Something went wrong, please try after some time.'
  },
  CREATE_USER_GROUP: {
    '101': "You don't have access to create group.",
    '102': 'This group already exists'
  },
  GET_USER_GROUP_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  ADD_MEMBER_TO_GROUP: {
    '101': "User don't have access to this account",
    '102': 'User is already added in this group',
    '103': "User don't have access to this account",
    '104': "User don't have access to this account",
    '105': "You don't have access to add members is the group",
    '106': 'Something went wrong, please try after some time.'
  },
  SIGNUP: {
    '102': 'Email address <email> is already registered',
    '103': 'Something went wrong, please try after some time.'
  },
  GET_PARTY_LIST: {
    '101': 'Invalid party type',
    '102': 'Something went wrong, please try after some time.'
  },
  GET_PARTY_BY_ID: {
    '101': 'Something went wrong, please try after some time.'
  },
  CREATE_PARTY: {
    '101': 'Please provide required details',
    '102': 'Please provide required details',
    '103': 'Please provide required details',
    '104': 'Please provide required details',
    '105': 'Please provide required details',
    '106': 'Please provide required details',
    '107': 'Please provide required details',
    '108': 'Please provide required details',
    '109': 'Warehouse id <refPartyId> already exist for your account',
    '110': 'Incorrect address provided: city and pincode are invalid',
    '111': 'Incorrect address provided: State does not exist in this country',
    '112': 'Incorrect address provided: State does not exist',
    '113': 'Incorrect GSTIN',
    '114': 'GSTIN state code does not match the state provided you. Please change GSTIN or state.',
    '115': 'Something went wrong, please try after some time.'
  },
  UPDATE_PARTY: {
    '101': 'Invalid party <warehouseName>',
    '102': 'Something went wrong, please try after some time.'
  },
  GET_VERTICAL_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  INVITE_MEMBER: {
    '101': 'Please provide required details',
    '102': 'Please provide required details',
    '103': '',
    '104': '',
    '105': 'This email address is already invited',
    '106': 'Something went wrong, please try after some time.'
  },
  UPDATE_USER_INVITATION: {
    '101': '',
    '102': 'Something went wrong, please try after some time.',
    '103': 'Something went wrong, please try after some time.',
    '104': 'Invalid action, you cannot update your status or role.'
  },
  GET_INVITATION_BY_ID: {
    '101': 'Something went wrong, please try after some time.',
    '102': 'Something went wrong, please try after some time.'
  },
  GET_FC_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  GET_FC_WAREHOUSE: {
    '101': 'Something went wrong, please try after some time.'
  },
  FC_EXTERNAL_WMS_ACCOUNT: {
    '101': 'Domain <slugName> already exist.',
    '102': 'Something went wrong, please try after some time.',
    '103': 'Something went wrong, please try after some time.',
    '104': 'Something went wrong, please try after some time.'
  },
  POST_CREATE_SUBSCRIPTION: {
    '101': 'FC <warehouseName> is already enrolled. Please select another FC',
    '102': 'Invalid fulfillment center selected',
    '103': 'Facility <facilityCode> already exist.',
    '104': 'FC <warehouseName> is already enrolled. Please select another FC',
    '105': 'Something went wrong, please try after some time.',
    '106': 'Something went wrong, please try after some time.',
    '107': 'Something went wrong, please try after some time.'
  },
  PORTALS_LIST: {
    '102': 'Something went wrong, please try after some time.'
  },
  INTEGRATION_MODEL_LIST: {
    '101': 'Something went wrong, please try after some time.',

    '102': 'Something went wrong, please try after some time.'
  },

  GET_CHANNEL_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  GET_CHANNEL_BY_ID: {
    '101': 'Something went wrong, please try after some time.'
  },
  EDIT_CHANNEL: {
    '101': 'Something went wrong, please try after some time.',
    '102': 'Something went wrong, please try after some time.',
    '103': 'Fulfilment center deactivation request cannot be processed as creation request pending.'
  },
  CREATE_SALES_CHANNEL_ACCOUNT: {
    '101': 'Channel account already exists',
    '102': 'Something went wrong, please try after some time.'
  },
  ADDED_CHANNEL_ACCOUNT_LIST_: {
    '101': 'Something went wrong, please try after some time.',
    '102': 'Something went wrong, please try after some time.'
  },

  CREATE_SALES_CHANNEL: {
    '101': 'Integration model missing, Please select suitable integration model',
    '102': 'Integration model missing, Please select suitable integration model',
    '103': 'Integration model missing, Please select suitable integration model',
    '104': 'Integration model missing, Please select suitable integration model',
    '105': 'Integration model missing, Please select suitable integration model',
    '106': 'Eshopbox Sales channel does not exist for selected warehouse.',
    '107': 'Something went wrong, please try after some time.'
  },

  CREATE_TRANSACTIONRULES: {
    '102': 'Something went wrong, please try after some time.',
    '503': 'Payment terms <transactionRuleName> already exist with this channelId. Try another.'
  },
  UPDATE_TRANSACTIONRULES: {
    '101': 'Payment terms <transactionRuleName> does not exist.',
    '102': 'Something went wrong, please try after some time.'
  },
  GET_INVOICE_LIST: {
    '101': 'Something went wrong, please try after some time.'
  },
  GET_EXPENSES_LIST: {
    '101': 'Something went wrong, please try after some time.'
  }
};

export const GLOBAL_ERROR_MESSAGE = [
  /* add fee rule errors */
  { code: 501, msg: 'Portal is Already mapped with the selected Fee' },
  { code: 502, msg: 'Fee already exists with this name' },
  { code: 503, msg: 'Wrong fee charged type' },
  { code: 504, msg: 'Portal id must not be null or blank.' },
  { code: 505, msg: 'Fee Name must not be null or blank.' },
  { code: 506, msg: 'Enter either fee name id or fee type name.' },
  { code: 507, msg: 'Tax percentage must not be null or blank.' },
  { code: 508, msg: 'Enter event for fee charged and reverse cases.' },
  { code: 509, msg: 'Event subtype can not be null for returned.' },
  { code: 510, msg: 'Valid from must not be null or blank.' },
  { code: 511, msg: 'Fee rule must not be empty.' },
  { code: 512, msg: 'Fee value cannot be empty.' },
  { code: 513, msg: 'Fee value cannot be blank.' },
  { code: 514, msg: 'Fee min range can not be null.' },
  { code: 515, msg: 'Fee value type can not be null.' },
  { code: 516, msg: 'Fee partition range type can not be null.' },
  { code: 517, msg: 'Fee partition range can not be null.' },
  { code: 518, msg: 'Fee applicable on cannot be blank.' },
  { code: 519, msg: 'Fee value cannot be blank.' },
  { code: 520, msg: 'Brand values are not in correct format' },
  { code: 521, msg: 'Category values are not in correct format' },

  /* end add fee rule errors */

  { code: 522, msg: 'There is already an entry exists corresponding to given channelId ?' },
  { code: 523, msg: 'Fee Rule not recieved from FE.' },
  { code: 524, msg: 'Fee does not exist .Rules cannot be added.' },
  { code: 103, msg: 'The domain name already exists. Please choose a new one.' }
  // { "code" : , "msg": "" },
];
