import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DateModel } from '../models/date.model';
import { FilterMainModel } from '../models/filter-main.model';
import { FilterModel } from '../models/filter.model';
import { LocationModel } from '../models/location.model';
import { SalesChannelModel } from '../models/sales-channel.model';
@Injectable({
  providedIn: 'root'
})
export class FilterHelperService {
  isCalledFromSidebar: boolean = true;
  resetMatPagination = new Subject();

  filters: any = [
    { key: 'today', displayName: 'Today' },
    { key: 'yesterday', displayName: 'Yesterday' },
    { key: 'last_7_day', displayName: 'Last 7 days' },
    { key: 'last_30_day', displayName: 'Last 30 days' },
    { key: 'last_90_day', displayName: 'Last 90 days' },
    // { key: 'this_week', displayName: 'This week' },
    // { key: 'last_week', displayName: 'Last week' },
    { key: 'this_month', displayName: 'This month' },
    { key: 'last_month', displayName: 'Last month' }
  ];

  /**@description - Method to Convert Main Filter Model into Filter String*/
  public manipulateData(filterFormData, moduleType) {
    let finalStirng: string = '';
    for (let filter in filterFormData) {
      switch (filter) {
        case 'filters':
          finalStirng += this.convertFilterString(filterFormData[filter], moduleType);
          break;
        case 'location':
          finalStirng += this.convertLocationString(filterFormData[filter]);
          break;
        case 'salesChannel':
          finalStirng += this.convertSalesChannelString(filterFormData[filter]);
          break;
        case 'date':
          finalStirng += this.convertDateData(filterFormData[filter], moduleType);
      }
    }
    return finalStirng.slice(0, finalStirng.lastIndexOf('AND'));
  }

  convertDateData(dateData, moduleType) {
    let final: string = '';
    if (Object.keys(dateData).length > 0) {
      final += `(${this.getAlgoliaDateFilters(
        moduleType,
        dateData.filterKey,
        '',
        dateData.filterCondition,
        dateData.fromDate,
        dateData.toDate
      )})AND`;
    }
    return final;
  }

  /**@description Method To Convert More Filters to Filter String*/
  public convertFilterString(filtersData, moduleType) {
    let final: string = '';
    filtersData.forEach(y => {
      final +=
        '(' +
        this.forNotCondition(y['filterCondition'], y['filterKey']['fieldType']) +
        ' ' +
        this.multipleValues(
          y['filterValue'],
          y['filterKey']['fieldType'],
          y['filterKey']['key'],
          y['filterCondition'],
          y['from'],
          y['to'],
          y['fromDate'],
          y['toDate'],
          moduleType
        ) +
        ')' +
        'AND';
    });
    return final;
  }

  /**@description Method To Convert Sales Channel To Filter String*/
  public convertSalesChannelString(salesChannelData) {
    let final: string = '';
    for (let salesChannel in salesChannelData) {
      if (salesChannelData[salesChannel].length > 0) {
        const condition = ['isAvailableOn', 'salesChannel', 'needAttention'].includes(salesChannel) ? 'is' : 'is not';
        const salesChannelKey =
          salesChannel == 'salesChannel'
            ? 'portalDTO.saleChannel'
            : salesChannel == 'needAttention'
            ? 'inventoryUpdateFailures'
            : 'availableOn';
        final +=
          '(' +
          this.forNotCondition(condition, 'string') +
          ' ' +
          this.forSalesChannel(condition, salesChannelData[salesChannel], salesChannelKey) +
          ')' +
          'AND';
      }
    }
    return final;
  }

  /**@description Helper Method To Convert Sales Channel To Filter String*/
  public forSalesChannel(condition, value, key) {
    let final: string = '';
    let index: any;
    if (condition == 'is') {
      value.forEach(x => (final += `${key}:'${x}' OR `));
      index = final.lastIndexOf('OR');
    } else {
      value.forEach(x => (final += `${key}:'${x}' OR NOT `));
      index = final.lastIndexOf('OR NOT');
    }
    return final.slice(0, index);
  }

  /**@description Method To Convert Location Filters To Filter String*/
  public convertLocationString(locationData) {
    let final: string = '';
    for (let location in locationData) {
      if (locationData[location].length > 0) {
        switch (location) {
          case 'isInStock':
          case 'isOutOfStock':
            final += '' + this.forLocation(location, locationData[location]) + '' + 'AND';
            break;
          case 'hassellableInventory':
          case 'hasNonsellableInventory':
          case 'location':
            final += '(' + this.forLocation(location, locationData[location]) + ')' + 'AND';
            break;
        }
      }
    }
    return final;
  }

  /**@description Helper Method To Convert Location Filters To Filter String*/
  public forLocation(type, value) {
    let final: string = '';
    let index: any;
    switch (type) {
      case 'hassellableInventory':
        value.forEach(locationData => (final += `stock.sellableFC: '${locationData}' OR `));
        index = final.lastIndexOf('OR');
        break;
      case 'hasNonsellableInventory':
        value.forEach(locationData => (final += `stock.nonSellableFC: '${locationData}' OR `));
        index = final.lastIndexOf('OR');
        break;
      case 'isInStock':
        value.forEach(
          locationData =>
            (final += `(stock.sellableFC: '${locationData}' OR stock.nonSellableFC: '${locationData}' OR stock.inwardProgressFC: '${locationData}' OR stock.outwardProgressFC: '${locationData}') AND`)
        );
        index = final.lastIndexOf('AND');
        break;
      case 'isOutOfStock':
        value.forEach(
          locationData =>
            (final += `(NOT stock.sellableFC: '${locationData}' AND NOT stock.nonSellableFC: '${locationData}' AND NOT stock.inwardProgressFC: '${locationData}' AND NOT stock.outwardProgressFC: '${locationData}') AND`)
        );
        index = final.lastIndexOf('AND');
        break;
      case 'location':
        value.forEach(locationData => (final += `warehouseDTO.wareHouseCode: '${locationData}' OR `));
        index = final.lastIndexOf('OR');
        break;
    }
    return final.slice(0, index);
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public multipleValues(value, type, key, condition, from?, to?, fromDate?, toDate?, moduleType?) {
    let final: any = '';
    if (type.toLowerCase() === 'range') {
      final = `'${key}'${from ? ':' + from : ''}${this.forColonOrOperator(condition, type)}${to}`;
    } else if (type.toLowerCase() === 'daterange') {
      final = this.getAlgoliaDateFilters(moduleType, key, type, condition, fromDate, toDate);
    } else if (typeof value === 'string') {
      if (moduleType == 'Order-Return' && key == 'customerOrderNumber')
        final = `'${key}'${this.forColonOrOperator(
          condition,
          type
        )}${value})OR('vendorOrderNumber'${this.forColonOrOperator(condition, type)}${value}`;
      else final = `'${key}'${this.forColonOrOperator(condition, type)}${value}`;
    } else {
      let idx: any;
      if (condition == 'is') {
        value.forEach((x, i) => {
          if (moduleType == 'Order-Return' && key == 'orderStatus') {
            if (x.trim().toLowerCase() == 'fulfilled') {
              final += `'${key}':'${x}')OR('orderStatus':Shipped ${
                value.length > 1 && i != value.length - 1 ? ')' : ''
              } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
            } else if (x.trim().toLowerCase() == 'upcoming return') {
              final += `'returnStatus':'${x}')OR('returnStatus':Expected ${
                value.length > 1 && i != value.length - 1 ? ')' : ''
              } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
            } else if (x.trim().toLowerCase() == 'return completed') {
              final += `'returnStatus':'${x}')OR('returnStatus':Complete ${
                value.length > 1 && i != value.length - 1 ? ')' : ''
              } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
            } else {
              final += `'${key}':'${x}')OR('returnStatus':'${x}' ${
                value.length > 1 && i != value.length - 1 ? ')' : ''
              } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
            }
          } else if (
            moduleType == 'Order-Return' &&
            key == 'paymentDTO.status' &&
            x.trim().toLowerCase() == 'awaiting payment'
          ) {
            final += `'${key}':'${x}')AND(NOT 'orderStatus':NEW)AND(NOT 'orderStatus':PROCESSING ${
              value.length > 1 && i != value.length - 1 ? ')' : ''
            } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
          } else {
            if (key == 'inventoryAge') {
              final += `${key}.${x}:true OR `;
            } else {
              final += `'${key}':'${x}' OR `;
            }
          }
        });
        idx = final.lastIndexOf('OR');
      } else {
        value.forEach((x, i) => {
          if (moduleType == 'Order-Return' && key == 'orderStatus')
            final += `'${key}':'${x}' )OR(NOT 'returnStatus':'${x}' ${
              value.length > 1 && i != value.length - 1 ? ')' : ''
            } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}NOT`;
          else {
            if (key == 'inventoryAge') {
              final += `'${key}.${x}':true OR NOT`;
            } else {
              final += `'${key}':'${x}' OR NOT`;
            }
          }
        });
        idx = final.lastIndexOf('OR NOT');
      }
      return final.slice(0, idx);
    }
    return final;
  }

  /**@description Method For Handle Date Filters*/
  public getAlgoliaDateFilters(moduleType, key, type, condition, fromDate?, toDate?) {
    switch (moduleType) {
      case 'Inventory':
      case 'Draft': {
        let appliedDate = condition == 'is' ? fromDate : toDate;
        let final = `${key}${this.forColonOrOperator(condition, type)}${new Date(fromDate)
          .setHours(0, 0, 0, 0)
          .valueOf()} TO ${new Date(appliedDate).setHours(23, 59, 59).valueOf()} `;
        return final;
      }
      case 'Order-Return': {
        let appliedDate = condition == 'is' ? fromDate : toDate;
        let final = `${key}${'>='}${new Date(fromDate).setHours(0, 0, 0, 0).valueOf()})AND(${key}${'<='}${new Date(
          appliedDate
        )
          .setHours(23, 59, 59)
          .valueOf()}`;
        return final;
      }
    }
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public condition(str, type) {
    let final;
    if (type.toLowerCase() === 'range') {
      final = this.forIfCondition(str);
    } else {
      final = this.forElseCondition(str);
    }
    return final;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forColonOrOperator(condition, type) {
    let operator;
    if (type.toLowerCase() === 'range') {
      operator = this.forIfCondition(condition);
    } else {
      operator = ':';
    }
    return operator;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forElseCondition(str) {
    let forElse;
    switch (str) {
      case 'is':
        forElse = 'OR';
        break;
      case 'is not':
        forElse = 'NOT';
        break;
      case 'contains':
        forElse = 'OR';
        break;
      case 'doesnot contains':
        forElse = 'NOT';
        break;
      default:
        forElse = 'OR';
    }
    return forElse;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forNotCondition(condition, type) {
    let final;
    if (type.toLowerCase() === 'range' || type.toLowerCase() === 'daterange') {
      final = '';
    } else {
      if (condition === 'is not') {
        final = 'NOT';
      } else {
        final = '';
      }
    }
    return final;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forIfCondition(str) {
    let forIf;
    switch (str) {
      case 'is':
        forIf = '=';
        break;
      case 'is not':
        forIf = '!=';
        break;
      case 'is greater than':
        forIf = '>';
        break;
      case 'is less than':
        forIf = '<';
        break;
      case 'is greater than or equal to':
        forIf = '>=';
        break;
      case 'is less than or equal to':
        forIf = '<=';
        break;
      case 'is between':
        forIf = ' TO ';
        break;
      default:
        forIf = ':';
    }
    return forIf;
  }

  /**@description - Method To Convert Filter Query Params to Filter Object*/
  public convertQueryParamToFilterObject(queryParams, filterArr) {
    let filterMain: FilterMainModel = new FilterMainModel();
    let filterModelData: FilterModel[] = [];
    let salesChannelData: SalesChannelModel = new SalesChannelModel();
    let locationData: LocationModel = new LocationModel();
    let dateData: DateModel = new DateModel();
    for (let filterKey in queryParams) {
      if (['isAvailableOn', 'isNotAvailableOn', 'salesChannel', 'needAttention'].includes(filterKey)) {
        salesChannelData[filterKey] = queryParams[filterKey].split(',');
      } else if (
        ['hassellableInventory', 'hasNonsellableInventory', 'isInStock', 'isOutOfStock', 'location'].includes(filterKey)
      ) {
        locationData[filterKey] = queryParams[filterKey].split(',');
      } else if (filterKey == 'date') {
        dateData.fromDate = new Date(+queryParams[filterKey].split('TO')[0].split('-')[1]);
        dateData.toDate = new Date(+queryParams[filterKey].split('TO')[1]);
        dateData.conditionType = queryParams[filterKey].split('-')[0];
        dateData.filterCondition = 'is-between';
        dateData.filterKey = 'orderDateDetailsDTO.orderReceivedOn';
      } else {
        filterArr.forEach(filters => {
          let filterModel: FilterModel = new FilterModel();
          if (filterKey == filters['key']) {
            filterModel.filterKey = filters;
            filterModel.filterCondition = this.getFilterCondition(queryParams[filterKey].split('-')[0]);
            if (filters.fieldType.toLowerCase() != 'range' && filters.fieldType.toLowerCase() != 'daterange') {
              filterModel.filterValue =
                filters.fieldType.toLowerCase() != 'dropdown'
                  ? queryParams[filterKey].split(/-(.+)/)[1]
                  : queryParams[filterKey].split('-')[1].split(',');
            } else if (filters.fieldType.toLowerCase() == 'range') {
              if (filterModel.filterCondition != 'is between') {
                filterModel.to = queryParams[filterKey].split('-')[1];
              } else if (filterModel.filterCondition == 'is between') {
                const fromfilterValue = queryParams[filterKey].split('TO')[0].split('-')[1];
                const tofilterValue = queryParams[filterKey].split('TO')[1];
                filterModel.from = fromfilterValue;
                filterModel.to = tofilterValue;
              }
            } else if (filters.fieldType.toLowerCase() == 'daterange') {
              if (filterModel.filterCondition == 'is') {
                filterModel.fromDate = new Date(+queryParams[filterKey].split('-')[1]);
              } else {
                const fromfilterValue = new Date(+queryParams[filterKey].split('TO')[0].split('-')[1]);
                const tofilterValue = new Date(+queryParams[filterKey].split('TO')[1]);
                filterModel.fromDate = fromfilterValue;
                filterModel.toDate = tofilterValue;
              }
            }
            filterModelData.push(filterModel);
          }
        });
      }
    }
    filterMain.filters = filterModelData;
    filterMain.salesChannel = salesChannelData;
    filterMain.location = locationData;
    filterMain.date = dateData;
    return filterMain;
  }

  /**@description - Method To Get Filter Condition based on Condition Provided in Query Params*/
  getFilterCondition(paramsFilterCondition) {
    let originalFilterCondition = '';
    switch (paramsFilterCondition) {
      case 'is':
        return (originalFilterCondition = 'is');
      case 'not':
        return (originalFilterCondition = 'is not');
      case 'greater':
        return (originalFilterCondition = 'is greater than');
      case 'less':
        return (originalFilterCondition = 'is less than');
      case 'greaterThan':
        return (originalFilterCondition = 'is greater than or equal to');
      case 'lessThan':
        return (originalFilterCondition = 'is less than or equal to');
      case 'between':
        return (originalFilterCondition = 'is between');
    }
  }

  /**@description - Method To Get Inventory Segments Query Params*/
  public getInventorySegmentsQueryParams(segmentName, segmentFilters) {
    let filterQuery: any = {
      selectedView: segmentName.replaceAll(' ', '$'),
      sortBy: 'product_service',
      page: 0,
      hitsPerPage: 50
    };
    return (filterQuery = { ...filterQuery, ...segmentFilters });
  }

  /**@description - Method To Get Drafts Segments Query Params*/
  public getDraftsSegmentsQueryParams(segmentName, segmentFilters) {
    let filterQuery: any = {
      moduleStatus: 'DRAFT',
      selectedView: segmentName.replaceAll(' ', '$'),
      sortBy: 'product_service',
      page: 0,
      hitsPerPage: 50
    };
    return (filterQuery = { ...filterQuery, ...segmentFilters });
  }

  /**@description - Method To Get Order Return Segments Query Params*/
  public getOrderReturnSegmentsQueryParams(segmentName, segmentFilters) {
    let filterQuery: any = {
      selectedView: segmentName.replaceAll(' ', '$'),
      page: 0,
      perPage: 50,
      index: 'prod'
    };
    const fromDate = new Date(
      moment()
        .subtract(['All returns', 'Returning', 'Returned'].includes(segmentName) ? 90 : 30, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();
    const toDate = new Date().setHours(23, 59, 59).valueOf();
    switch (segmentName) {
      case 'Fulfilled':
      case 'Pending to fulfill':
      case 'Cancelled':
        segmentFilters.date = `last_30_day-${fromDate}TO${toDate}`;
        break;
      case 'All returns':
      case 'Returned':
      case 'Returning':
        segmentFilters.date = `last_90_day-${fromDate}TO${toDate}`;
        break;
    }
    return (filterQuery = { ...filterQuery, ...segmentFilters });
  }

  getFacilityLabel(fulfillmentChannels) {
    if (fulfillmentChannels) {
      let facilityLabel = '';
      if (fulfillmentChannels.length == 1) {
        fulfillmentChannels.forEach(channelData => {
          let index = channelData.warehouseName.indexOf('(');
          let finalLabel = channelData.warehouseName.slice(index - 1);
          facilityLabel += finalLabel;
        });
      }
      return facilityLabel;
    }
  }

  /**@description Method to Rename Filter Keys*/
  public modifyObjectKeys(filterObject, existingValue, newValue) {
    for (let filterKey in filterObject) {
      if (filterKey.includes('.') || filterKey.includes('$')) {
        let updatedKey = filterKey.replace(existingValue, newValue);
        filterObject[updatedKey] = filterObject[filterKey];
        delete filterObject[filterKey];
      }
    }
    return filterObject;
  }

  checkFilterObject(filterFormData) {
    if (
      Object.keys(filterFormData.filters).length == 0 &&
      filterFormData.location.location.length == 0 &&
      filterFormData.salesChannel.salesChannel.length == 0 &&
      'date' in filterFormData &&
      filterFormData.date.conditionType === 'last_30_day'
    )
      return true;
    else return false;
  }

  /**@description - Method to get Display Value for Additional Filters*/
  public getFilterValue(filterValue, filterType, salesChannelData, enrolledFCData) {
    let finalValue: any = [];
    switch (filterType) {
      case 'salesChannel':
      case 'saleChannel':
        filterValue.forEach(filterData => {
          salesChannelData.forEach(salesChannel => {
            if (salesChannel.externalChannelId == filterData || salesChannel.channelLabel == filterData) {
              let facilityLabel = this.getFacilityLabel(salesChannel.fulfillmentChannels);
              let finalSalesChannel = `${salesChannel.channelLabel} ${facilityLabel ? facilityLabel : ''}`;
              finalValue.push(finalSalesChannel);
            }
          });
        });
        return finalValue;
      case 'location':
      case 'wareHouseCode':
        filterValue.forEach(filterData => {
          enrolledFCData.forEach(location => {
            if (location.externalWarehouseId == filterData) {
              finalValue.push(location.warehouseName);
            }
          });
        });
        return finalValue;
      default:
        return filterValue;
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  saveSegmentInLS(savedSegmentData, segmentModule) {
    const newAddedSegment = JSON.parse(localStorage.getItem('segmentsData'));
    if (newAddedSegment) {
      const values = Object.values(newAddedSegment);
      const targetArray = values.find(x => x[0] == segmentModule)[1];
      const index = targetArray.length - 1;
      targetArray.splice(index - 1, 0, savedSegmentData); // Insert at second last position
      localStorage.setItem('segmentsData', JSON.stringify(newAddedSegment));
    }
  }

  updateSegmentInLS(updatedSegmentData, segmentModule) {
    const existingSegments = JSON.parse(localStorage.getItem('segmentsData'));
    if (existingSegments) {
      const values = Object.values(existingSegments);
      const targetArray = values.find(x => x[0] == segmentModule)[1];
      const index = targetArray.findIndex(item => item.id === updatedSegmentData.id);
      if (index !== -1) {
        targetArray[index] = updatedSegmentData; // Update existing value
        localStorage.setItem('segmentsData', JSON.stringify(existingSegments));
      }
    }
  }

  removeSegmentInLS(segmentIdToRemove, segmentModule) {
    const existingSegments = JSON.parse(localStorage.getItem('segmentsData'));
    if (existingSegments) {
      const values = Object.values(existingSegments);
      const targetArray = values.find(x => x[0] == segmentModule)[1];
      const index = targetArray.findIndex(item => item.id === segmentIdToRemove);
      if (index !== -1) {
        targetArray.splice(index, 1); // Remove the value
        localStorage.setItem('segmentsData', JSON.stringify(existingSegments));
      }
    }
  }

  addZIndexClass() {
    this.addCSSClass('menu-zindex');
    this.addCSSClass('new-filter-dropdown');
  }

  removeZIndexClass() {
    this.removeCSSClass('menu-zindex');
    this.removeCSSClass('new-filter-dropdown');
  }

  addCSSClass(className: string) {
    document.querySelector('body').classList.add(className);
  }

  removeCSSClass(className: string) {
    document.querySelector('body').classList.remove(className);
  }
}
