import { Component, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
// import * as filestack from 'filestack-js';
// import { PickerOptions } from 'filestack-js/build/main/lib/picker';
import { environment } from 'src/environments/environment';
import * as UPLOAD_FILE_CONSTANTS from 'src/app/shared/constants/upload-files.constant';
import { AlertService, SharedService } from '../../services';

// const client = filestack.init(environment.FILESTACK_APIKEY);
@Component({
  selector: 'app-upload-sheet',
  templateUrl: './upload-sheet.component.html',
  styleUrls: ['./upload-sheet.component.scss']
})
export class UploadSheetComponent implements OnChanges {
  @Input() acceptedTypes: any = ['.csv'];
  @Input() uploadStatus: any;
  @Output() savedFileUrl = new EventEmitter();
  @Output() uploadBtnClicked = new EventEmitter();
  @Input() loader: boolean = false;
  @Output() uploadNewSheetClicked = new EventEmitter();
  @Input() status: any;
  @Input() bucketName: any;
  @Output() fileDeleted = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  UPLOAD_FILE_CONSTANTS = UPLOAD_FILE_CONSTANTS;

  uploadedFileDetail: any;
  error: any;
  step: any = 1;

  constructor(private sharedService: SharedService, private alertService: AlertService) {}

  ngOnChanges() {
    if (this.uploadStatus) {
      this.apiSuccessOrFailure();
    }
  }

  // uploadFile() {
  //   const fileStackOption: PickerOptions = {
  //     maxFiles: 1,
  //     accept: ['.csv', '.xls', '.xlsx'], //, '.xls', '.xlsx'
  //     uploadInBackground: false,
  //     startUploadingWhenMaxFilesReached: true,
  //     fromSources: ['local_file_system', 'imagesearch', 'googledrive', 'dropbox'],
  //     onOpen: () => {},
  //     dropPane: {},
  //     storeTo: {
  //       location: 'gcs',
  //       path: '/import_jobs/'
  //     },
  //     onUploadDone: (response: any) => {
  //       console.log('response from filestack', response);
  //       this.savedFileUrl.emit(response.filesUploaded[0]);
  //       this.uploadedFileDetail = response.filesUploaded[0];
  //       this.error = undefined;
  //       this.step = 2;
  //     }
  //   };
  //   client.picker(fileStackOption).open();
  //   // client
  //   //   .pick({
  //   //     maxFiles: 1,
  //   //     accept: this.acceptedTypes,
  //   //     uploadInBackground: false,
  //   //     startUploadingWhenMaxFilesReached: true,
  //   //     fromSources: ['local_file_system', 'imagesearch', 'googledrive', 'dropbox'],
  //   //     onOpen: () => {},
  //   //     rejectOnCancel: true,
  //   //     storeTo: {
  //   //       location: 'gcs',
  //   //       path: '/import_jobs/'
  //   //     }
  //   //   })
  //   //   .then(result => {
  //   //     this.savedFileUrl.emit(result.filesUploaded[0]);
  //   //     this.uploadedFileDetail = result.filesUploaded[0];
  //   //     this.error = undefined;
  //   //     this.step = 2;
  //   //   })
  //   //   .catch(error => {
  //   //     console.log('File upload url');
  //   //     console.log('ERROR====>', error);
  //   //   });
  // }

  openFileInput() {
    // Triggers click event on the input element
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.sharedService
      .handleUploadFile(file, UPLOAD_FILE_CONSTANTS.SUPPORTED_FILE_TYPES.csvsXls, this.bucketName)
      .subscribe(
        (data: any) => {
          // if file uploaded successfully
          if (data) {
            this.uploadedFileDetail = { filename: file.name, url: data[file.name] };
            this.savedFileUrl.emit(this.uploadedFileDetail);
            this.error = undefined;
            this.step = 2;
          }
        },
        error => {
          if (error) {
            this.alertService.showError(error);
          }
        }
      );
  }

  deleteFile() {
    this.uploadedFileDetail = undefined;
    this.step = 1;
    this.fileDeleted.emit(true);
  }

  callAPI() {
    if (!this.uploadedFileDetail) {
      return (this.error = 'noSheet');
    }
    this.uploadBtnClicked.emit(true);
  }

  apiSuccessOrFailure() {
    this.step = 3;
  }

  uploadNewSheet() {
    this.uploadNewSheetClicked.emit(true);
  }
}
