<div class="field-details-form-wrap">
  <div class="field-details-form">
    <div class="set-head">
      <div class="head-left">
        <span (click)="goBack()">
          {{ buildingBlockHandleBar | titlecase }}
        </span>
        <h1>
          <ng-container *ngIf="mode === 'ADD'; else EditText">
            New {{ fieldTypeDisplay | titlecase }} Field
          </ng-container>
          <ng-template #EditText> Edit {{ fieldTypeDisplay | titlecase }} </ng-template>
        </h1>
      </div>
      <div class="head-right">
        <div class="head-right">
          <a class="btn" (click)="ShowHideButton()">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/correct-ic.png" /> Save and
            close
          </a>
        </div>
      </div>
    </div>

    <div class="detail-form-ui">
      <div class="field-container">
        <form class="example-form" [formGroup]="fieldDetails">
          <!-- Title field -->
          <mat-form-field>
            <span class="hovertext">
              <b>
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
              </b>
              <span> Info Text </span>
            </span>
            <input matInput formControlName="title" placeholder="Title" #inputTitle />
            <mat-error *ngIf="fieldDetails.get('title').hasError('required')">
              Title is Required
            </mat-error>
          </mat-form-field>

          <!-- Handlebar Field -->
          <mat-form-field class="handlebar">
            <span class="hovertext">
              <b>
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
              </b>
              <span> Info Text </span>
            </span>
            <input matInput formControlName="handleBar" placeholder="Handlebar" #inputHandlebar />
            <mat-error *ngIf="fieldDetails.get('handleBar').hasError('required')">
              Handlebar is Required
            </mat-error>
            <mat-error *ngIf="fieldDetails.get('handleBar').hasError('pattern')">
              Invalid Handlebar: It only accepts alphanumerics and underscore
            </mat-error>
          </mat-form-field>

          <!-- Help text Field -->
          <mat-form-field class="help-txt">
            <span class="hovertext">
              <b>
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
              </b>
              <span> Info Text </span>
            </span>
            <input matInput formControlName="helpText" placeholder="Help text" #inputHelptext />
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>
