import { Action } from '@ngrx/store';
import { Product } from '../../../shared/models/product.model';

export enum ProductActionsTypes {
  SHOW_PROGRESS_SPINNER = '[PRODUCT] Show progress spinner',
  HIDE_PROGRESS_SPINNER = '[PRODUCT] Hide progress spinner',

  GET_PRODUCT_LIST = '[PRODUCT] GET PRODUCT LIST',
  GET_PRODUCT_LIST_SUCCESS = '[PRODUCT] GET PRODUCT LIST SUCCESS',
  GET_PRODUCT_LIST_FAILURE = '[PRODUCT] GET PRODUCT LIST FAILURE',

  GET_DRAFT_PRODUCT_LIST = '[PRODUCT] GET DRAFT PRODUCT LIST',
  GET_DRAFT_PRODUCT_LIST_SUCCESS = '[PRODUCT] GET DRAFT PRODUCT LIST SUCCESS',
  GET_DRAFT_PRODUCT_LIST_FAILURE = '[PRODUCT] GET DRAFT PRODUCT LIST FAILURE',

  GET_PROPERTY_LIST = '[PRODUCT] GET PROPERTY LIST',
  GET_PROPERTY_LIST_SUCCESS = '[PRODUCT] GET PROPERTY LIST SUCCESS',
  GET_PROPERTY_LIST_FAILURE = '[PRODUCT] GET PROPERTY LIST FAILURE',

  CREATE_PRODUCT = '[PRODUCT ] CREATE PRODUCT',
  CREATE_PRODUCT_SUCCESS = '[PRODUCT] CREATE PRODUCT SUCCESS',
  CREATE_PRODUCT_FAILURE = '[PRODUCT] CREATE PRODUCT FAILURE',

  EDIT_PRODUCT = '[PRODUCT] EDIT PRODUCT',
  EDIT_PRODUCT_SUCCESS = '[PRODUCT] EDIT PRODUCT SUCCESS',
  EDIT_PRODUCT_FAILURE = '[PRODUCT] EDIT PRODUCT FAILURE',

  SKU_CHECK = '[PRODUCT] GET CHECK RESPONSE',
  SKU_CHECK_SUCCESS = '[PRODUCT] GET CHECK RESPONSE SUCCESS',
  SKU_CHECK_FAILURE = '[PRODUCT] GET CHECK RESPONSE FAILURE',

  GET_BRAND = '[PRODUCT] GET BRANDS',
  GET_BRAND_SUCCESS = '[PRODUCT] GET BRAND SUCCESS',
  GET_BRAND_FAILURE = '[PRODUCT] GET BRAND FAILURE',

  CREATE_BULK_PRODUCT = '[PRODUCT] CREATE  BULK PRODUCT',
  CREATE_BULK_PRODUCT_SUCCESS = '[PRODUCT] CREATE  BULK PRODUCT SUCCESS',
  CREATE_BULK_PRODUCT_FAILURE = '[PRODUCT] CREATE  BULK PRODUCT FAILURE',

  DOCUMENT_UPLOAD_STATUS = '[PRODUCT] DOCUMENT UPLOAD STATUS',
  DOCUMENT_UPLOAD_STATUS_SUCCESS = '[PRODUCT] DOCUMENT UPLOAD STATUS SUCCESS',
  DOCUMENT_UPLOAD_STATUS_FAILURE = '[PRODUCT] DOCUMENT UPLOAD STATUS FAILURE',

  // EXPORT JOB
  EXPORT_JOB = '[PRODUCT] EXPORT JOB',
  EXPORT_JOB_SUCCESS = '[PRODUCT] EXPORT JOB SUCCESS',
  EXPORT_JOB_FAILURE = '[PRODUCT] EXPORT JOB FAILURE',

  //GET FILTERS
  GET_FILTERS = '[PRODUCT] GET FILTERS',
  GET_FILTERS_SUCCESS = '[PRODUCT] GET FILTERS SUCCESS',
  GET_FILTERS_FAILURE = '[PRODUCT] GET FILTERS FAILURE',

  // GET INVENTORY DETAILS
  GET_INVENTORY = '[PRODUCT] GET INVENTORY',
  GET_INVENTORY_SUCCESS = '[PRODUCT] GET INVENTORY SUCCESS',
  GET_INVENTORY_FAILURE = '[PRODUCT] GET INVENTORY FAILURE',

  // GET WAREHOUSE LIST
  GET_WAREHOUSE = '[PRODUCT] GET WAREHOUSE',
  GET_WAREHOUSE_SUCCESS = '[PRODUCT] GET WAREHOUSE SUCCESS',
  GET_WAREHOUSE_FAILURE = '[PRODUCT] GET WAREHOUSE FAILURE',

  // // MAP LISTINGS
  // CREATE_LISTINGS = '[PRODUCT] CREATE LISTINGS',
  // CREATE_LISTINGS_SUCCESS = '[PRODUCT] CREATE LISTINGS SUCCESS',
  // CREATE_LISTINGS_FAILURE = '[PRODUCT] CREATE LISTINGS FAILURE',

  // Channel List
  CHANNEL_LIST = '[PRODUCT] CHANNEL LIST',
  CHANNEL_LIST_SUCCESS = '[PRODUCT] CHANNEL LIST SUCCESS',
  CHANNEL_LIST_FAILURE = '[PRODUCT] CHANNEL LIST FAILURE',

  //  Product Availability
  PRODUCT_AVAILABILITY = '[PRODUCT] PRODUCT AVAILABILITY',
  PRODUCT_AVAILABILITY_SUCCESS = '[PRODUCT] PRODUCT AVAILABILITY SUCCESS',
  PRODUCT_AVAILABILITY_FAILURE = '[PRODUCT] PRODUCT AVAILABILITY FAILURE',

  // Get Portal Details
  GET_PORTALS_LIST = '[Channels Page] Get Portal Details',
  GET_PORTALS_LIST_SUCCESS = '[Channels success] Get Portal Success',
  GET_PORTALS_LIST_FAILURE = '[Channels failure] Get Portal Failure',
  // GET FROM PRODUCTS DETAILS
  GET_FROM_PRODUCT = '[Product ] Get From Product Details',
  GET_FROM_PRODUCT_SUCCESS = '[Product ] Get From Product Success',
  GET_FROM_PRODUCT_FAILURE = '[ Product ] Get From Product Failure',
  // GET TO PRODUCT DETAILS
  GET_TO_PRODUCT = '[ Product ] Get To Product Details',
  GET_TO_PRODUCT_SUCCESS = '[ Product ] Get To Product Success',
  GET_TO_PRODUCT_FAILURE = '[ Product ] Get To Product Failure',
  // GET TO PRODUCT DETAILS
  GET_SUGGESTION_PRODUCT = '[ Product ] Get Suggestion Product Details',
  GET_SUGGESTION_PRODUCT_SUCCESS = '[ Product ] Get Suggestion Product Success',
  GET_SUGGESTION_PRODUCT_FAILURE = '[ Product ] Get Suggestion Product Failure',
  // MERGE PRODUCTS
  MERGE_PRODUCTS = '[PRODUCT] Merge Product',
  MERGE_PRODUCTS_SUCCESS = '[PRODUCT] Merge Product Success',
  MERGE_PRODUCTS_FAILURE = '[PRODUCT] Merge Product Failure',
  // DELETE DRAFT PRODUCT
  DELETE_DRAFT_PRODUCTS = '[PRODUCT] Delete Draft Product',
  DELETE_DRAFT_PRODUCTS_SUCCESS = '[PRODUCT] Delete Draft Product Success',
  DELETE_DRAFT_PRODUCTS_FAILURE = '[PRODUCT] Delete Draft Product Failure',
  // Get Last Inventory Update
  GET_LAST_INVENTORY_UPDATE = '[PRODUCT] Get last inventory update',
  GET_LAST_INVENTORY_UPDATE_SUCCCESS = '[PRODUCT] Get last inventory update success',
  GET_LAST_INVENTORY_UPDATE_FAILURE = '[PRODUCT] Get last inventory update failure',
  // Get Inventory Update Logs
  GET_INVENTORY_UPDATE_LOGS = '[PRODUCT] Get  inventory update logs',
  GET_INVENTORY_UPDATE_LOGS_SUCCCESS = '[PRODUCT] Get  inventory update logs success',
  GET_INVENTORY_UPDATE_LOGS_FAILURE = '[PRODUCT] Get  inventory update logs failure',

  // GET STOCK AGEING DETAILS
  GET_STOCK_AGEING = '[PRODUCT] GET STOCK AGEING',
  GET_STOCK_AGEING_SUCCESS = '[PRODUCT] GET STOCK AGEING SUCCESS',
  GET_STOCK_AGEING_FAILURE = '[PRODUCT] GET STOCK AGEING FAILURE',

  // INVENTORY LIST ESIN
  GET_INVENTORY_LIST = '[PRODUCT] GET INVENTORY LIST',
  GET_INVENTORY_LIST_SUCCESS = '[PRODUCT] GET INVENTORY LIST SUCCESS',
  GET_INVENTORY_LIST_FAILURE = '[PRODUCT] GET INVENTORY LIST FAILURE'
}

//PRODUCT LIST
export class GetProductList implements Action {
  readonly type = ProductActionsTypes.GET_PRODUCT_LIST;
  constructor(public payload: any) {}
}
export class GetProductListSuccess implements Action {
  readonly type = ProductActionsTypes.GET_PRODUCT_LIST_SUCCESS;
  constructor(public payload: Product[]) {}
}
export class GetProductListFailure implements Action {
  readonly type = ProductActionsTypes.GET_PRODUCT_LIST_FAILURE;
  constructor(public payload: Product[]) {}
}

//DRAFT LIST
export class GetDraftProductList implements Action {
  readonly type = ProductActionsTypes.GET_DRAFT_PRODUCT_LIST;
  constructor(public payload: any) {}
}
export class GetDraftProductListSuccess implements Action {
  readonly type = ProductActionsTypes.GET_DRAFT_PRODUCT_LIST_SUCCESS;
  constructor(public payload: Product[]) {}
}
export class GetDraftProductListFailure implements Action {
  readonly type = ProductActionsTypes.GET_DRAFT_PRODUCT_LIST_FAILURE;
  constructor(public payload: Product[]) {}
}
//PROPERTY LIST
export class GetPropertyList implements Action {
  readonly type = ProductActionsTypes.GET_PROPERTY_LIST;
  constructor(public payload: any) {}
}
export class GetPropertyListSuccess implements Action {
  readonly type = ProductActionsTypes.GET_PROPERTY_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPropertyListFailure implements Action {
  readonly type = ProductActionsTypes.GET_PROPERTY_LIST_FAILURE;
  constructor(public payload: any) {}
}
//CREATE PRODUCT
export class CreateProduct implements Action {
  readonly type = ProductActionsTypes.CREATE_PRODUCT;
  constructor(public payload: any) {}
}
export class CreateProductSuccess implements Action {
  readonly type = ProductActionsTypes.CREATE_PRODUCT_SUCCESS;
  constructor(public payload: any) {}
}
export class CreateProductFailure implements Action {
  readonly type = ProductActionsTypes.CREATE_PRODUCT_FAILURE;
  constructor(public payload: any) {}
}
//CREATE BULK PRODUCT
export class CreateBulkProduct implements Action {
  readonly type = ProductActionsTypes.CREATE_BULK_PRODUCT;
  constructor(public payload: any) {}
}
export class CreateBulkProductSuccess implements Action {
  readonly type = ProductActionsTypes.CREATE_BULK_PRODUCT_SUCCESS;
  constructor(public payload: any) {}
}
export class CreateBulkProductFailure implements Action {
  readonly type = ProductActionsTypes.CREATE_BULK_PRODUCT_FAILURE;
  constructor(public payload: any) {}
}
//EDIT PRODUCT
export class EditProduct implements Action {
  readonly type = ProductActionsTypes.EDIT_PRODUCT;
  constructor(public payload: any) {}
}
export class EditProductSuccess implements Action {
  readonly type = ProductActionsTypes.EDIT_PRODUCT_SUCCESS;
  constructor(public payload: any) {}
}
export class EditProductFailure implements Action {
  readonly type = ProductActionsTypes.EDIT_PRODUCT_FAILURE;
  constructor(public payload: any) {}
}
//SKU CHECK
export class SKUCheck implements Action {
  readonly type = ProductActionsTypes.SKU_CHECK;
  constructor(public payload: any) {}
}
export class SKUCheckSuccess implements Action {
  readonly type = ProductActionsTypes.SKU_CHECK_SUCCESS;
  constructor(public payload: any) {}
}
export class SKUCheckFailure implements Action {
  readonly type = ProductActionsTypes.SKU_CHECK_FAILURE;
  constructor(public payload: any) {}
}
// GET BRAND
export class GetBrand implements Action {
  readonly type = ProductActionsTypes.GET_BRAND;
  constructor(public payload: any) {}
}
export class GetBrandSuccess implements Action {
  readonly type = ProductActionsTypes.GET_BRAND_SUCCESS;
  constructor(public payload: any) {}
}
export class GetBrandFailure implements Action {
  readonly type = ProductActionsTypes.GET_BRAND_FAILURE;
  constructor(public payload: any) {}
}
// DOCUMENT UPLOAD STATUS
export class SheetStatusCheck implements Action {
  readonly type = ProductActionsTypes.DOCUMENT_UPLOAD_STATUS;
  constructor(public payload: any) {}
}
export class SheetStatusSuccess implements Action {
  readonly type = ProductActionsTypes.DOCUMENT_UPLOAD_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class SheetStatusFailure implements Action {
  readonly type = ProductActionsTypes.DOCUMENT_UPLOAD_STATUS_FAILURE;
  constructor(public payload: any) {}
}
// GET INVENTORY DETAILS
export class GetInventory implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY;
  constructor(public payload: any) {}
}
export class GetInventorySuccess implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_SUCCESS;
  constructor(public payload: any) {}
}
export class GetInventoryFailure implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_FAILURE;
  constructor(public payload: any) {}
}
// GET FILTERS
export class GetFilters implements Action {
  readonly type = ProductActionsTypes.GET_FILTERS;
  constructor() {}
}
export class GetFiltersSuccess implements Action {
  readonly type = ProductActionsTypes.GET_FILTERS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetFiltersFailure implements Action {
  readonly type = ProductActionsTypes.GET_FILTERS_FAILURE;
  constructor(public payload: any) {}
}
//  CREATE PRODUCT EXPORT JOB
export class ExportJob implements Action {
  readonly type = ProductActionsTypes.EXPORT_JOB;
  constructor(public payload: any) {}
}
export class ExportJobSuccess implements Action {
  readonly type = ProductActionsTypes.EXPORT_JOB_SUCCESS;
  constructor(public payload: any) {}
}
export class ExportJobFailure implements Action {
  readonly type = ProductActionsTypes.EXPORT_JOB_FAILURE;
  constructor(public payload: any) {}
}

//WAREHOUSE LIST
export class GetWarehouseList implements Action {
  readonly type = ProductActionsTypes.GET_WAREHOUSE;
  constructor(public payload: any) {}
}
export class GetWarehouseSuccess implements Action {
  readonly type = ProductActionsTypes.GET_WAREHOUSE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetWarehouseFailure implements Action {
  readonly type = ProductActionsTypes.GET_WAREHOUSE_FAILURE;
  constructor(public payload: any) {}
}
// //CREATE LISTINGS
// export class CreateListings implements Action {
//   readonly type = ProductActionsTypes.CREATE_LISTINGS;
//   constructor(public payload: any) {}
// }
// export class CreateListingsSuccess implements Action {
//   readonly type = ProductActionsTypes.CREATE_LISTINGS_SUCCESS;
//   constructor(public payload: any) {}
// }
// export class CreateListingsFailure implements Action {
//   readonly type = ProductActionsTypes.CREATE_LISTINGS_FAILURE;
//   constructor(public payload: any) {}
// }

//CHANNEL LISTINGS
export class ChannelList implements Action {
  readonly type = ProductActionsTypes.CHANNEL_LIST;
  constructor(public payload: any) {}
}
export class ChannelListSuccess implements Action {
  readonly type = ProductActionsTypes.CHANNEL_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class ChannelListFailure implements Action {
  readonly type = ProductActionsTypes.CHANNEL_LIST_FAILURE;
  constructor(public payload: any) {}
}
// PRODUCT AVAILABILITY
export class ProductAvailability implements Action {
  readonly type = ProductActionsTypes.PRODUCT_AVAILABILITY;
  constructor(public payload: any) {}
}
export class ProductAvailabilitySuccess implements Action {
  readonly type = ProductActionsTypes.PRODUCT_AVAILABILITY_SUCCESS;
  constructor(public payload: any) {}
}
export class ProductAvailabilityFailure implements Action {
  readonly type = ProductActionsTypes.PRODUCT_AVAILABILITY_FAILURE;
  constructor(public payload: any) {}
}
// Get Portal Details
export class GetPortalsAction implements Action {
  readonly type = ProductActionsTypes.GET_PORTALS_LIST;
}

export class GetPortalsSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_PORTALS_LIST_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetPortalsFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_PORTALS_LIST_FAILURE;
}
// GET FROM PRODUCTS
export class GetFromProductAction implements Action {
  readonly type = ProductActionsTypes.GET_FROM_PRODUCT;
}

export class GetFromProductSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_FROM_PRODUCT_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetFromProductFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_FROM_PRODUCT_FAILURE;
}
// GET TO PRODUCTS
export class GetToProductAction implements Action {
  readonly type = ProductActionsTypes.GET_TO_PRODUCT;
}

export class GetToProductSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_TO_PRODUCT_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetToProductFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_TO_PRODUCT_FAILURE;
}

// GET TO  SUGGESTION PRODUCTS
export class GetSuggestionProductAction implements Action {
  readonly type = ProductActionsTypes.GET_SUGGESTION_PRODUCT;
}

export class GetSuggestionProductSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_SUGGESTION_PRODUCT_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetSuggestionProductFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_SUGGESTION_PRODUCT_FAILURE;

  constructor(public payload: any[]) {}
}

// MERGE PRODUCT
export class MergeProductAction implements Action {
  readonly type = ProductActionsTypes.MERGE_PRODUCTS;
}

export class MergeProductSuccessAction implements Action {
  readonly type = ProductActionsTypes.MERGE_PRODUCTS_SUCCESS;

  constructor(public payload: any[]) {}
}

export class MergeProductFailureAction implements Action {
  readonly type = ProductActionsTypes.MERGE_PRODUCTS_FAILURE;

  constructor(public payload: any[]) {}
}
// DELETE DRAFT PRODUCT
export class DeleteDraftProductAction implements Action {
  readonly type = ProductActionsTypes.DELETE_DRAFT_PRODUCTS;
  constructor(public payload: any) {}
}

export class DeleteDraftProductSuccessAction implements Action {
  readonly type = ProductActionsTypes.DELETE_DRAFT_PRODUCTS_SUCCESS;
  constructor(public payload: any[]) {}
}

export class DeleteDraftProductFailureAction implements Action {
  readonly type = ProductActionsTypes.DELETE_DRAFT_PRODUCTS_FAILURE;
  constructor(public payload: any) {}
}

export class ShowProgressSpinner implements Action {
  readonly type = ProductActionsTypes.SHOW_PROGRESS_SPINNER;
}
export class HideProgressSpinner implements Action {
  readonly type = ProductActionsTypes.HIDE_PROGRESS_SPINNER;
}
//  Inventory last update
export class InventoryLastUpdateAction implements Action {
  readonly type = ProductActionsTypes.GET_LAST_INVENTORY_UPDATE;
  constructor(public payload: any) {}
}
export class InventoryLastUpdateSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_LAST_INVENTORY_UPDATE_SUCCCESS;
  constructor(public payload: any) {}
}
export class InventoryLastUpdateFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_LAST_INVENTORY_UPDATE_FAILURE;
  constructor(public payload: any) {}
}
//  Inventory update logs
export class InventoryUpdateLogsAction implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS;
  constructor(public payload: any) {}
}
export class InventoryUpdateLogsSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS_SUCCCESS;
  constructor(public payload: any) {}
}
export class InventoryUpdateLogsFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS_FAILURE;
  constructor(public payload: any) {}
}

// Stock Ageing
export class GetStockAgeingAction implements Action {
  readonly type = ProductActionsTypes.GET_STOCK_AGEING;
  constructor(public payload: any) {}
}

export class GetStockAgeingSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_STOCK_AGEING_SUCCESS;
  constructor(public payload: any[]) {}
}

export class GetStockAgeingFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_STOCK_AGEING_FAILURE;
  constructor(public payload: any) {}
}

//

// Stock Ageing
export class GetInventorySTockList implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_LIST;
  constructor(public payload: any) {}
}

export class GetInventorySTockListSuccessAction implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_LIST_SUCCESS;
  constructor(public payload: any[]) {}
}

export class GetInventorySTockListFailureAction implements Action {
  readonly type = ProductActionsTypes.GET_INVENTORY_LIST_FAILURE;
  constructor(public payload: any) {}
}

export type ProductActionsList =
  | GetProductList
  | GetProductListSuccess
  | GetProductListFailure
  | GetDraftProductList
  | GetDraftProductListSuccess
  | GetDraftProductListFailure
  | CreateProduct
  | CreateProductSuccess
  | CreateProductFailure
  | EditProduct
  | EditProductSuccess
  | EditProductFailure
  | SKUCheck
  | SKUCheckSuccess
  | SKUCheckFailure
  | GetBrand
  | GetBrandFailure
  | GetBrandSuccess
  | GetPropertyList
  | GetPropertyListFailure
  | GetPropertyListSuccess
  | CreateBulkProduct
  | CreateBulkProductSuccess
  | CreateBulkProductFailure
  | SheetStatusCheck
  | SheetStatusFailure
  | SheetStatusSuccess
  | ExportJob
  | ExportJobSuccess
  | ExportJobFailure
  | GetFilters
  | GetFiltersFailure
  | GetFiltersSuccess
  | GetInventory
  | GetInventorySuccess
  | GetInventoryFailure
  | GetWarehouseFailure
  | GetWarehouseSuccess
  | GetWarehouseList
  | ChannelList
  | ChannelListFailure
  | ChannelListSuccess
  | ProductAvailability
  | ProductAvailabilitySuccess
  | ProductAvailabilityFailure
  | GetPortalsAction
  | GetPortalsSuccessAction
  | GetPortalsFailureAction
  | GetFromProductAction
  | GetFromProductFailureAction
  | GetFromProductSuccessAction
  | GetToProductAction
  | GetToProductFailureAction
  | GetToProductSuccessAction
  | GetSuggestionProductAction
  | GetSuggestionProductFailureAction
  | GetSuggestionProductSuccessAction
  | MergeProductAction
  | MergeProductFailureAction
  | MergeProductSuccessAction
  | ShowProgressSpinner
  | HideProgressSpinner
  | InventoryLastUpdateAction
  | InventoryLastUpdateSuccessAction
  | InventoryLastUpdateFailureAction
  | DeleteDraftProductAction
  | DeleteDraftProductFailureAction
  | DeleteDraftProductSuccessAction
  | InventoryUpdateLogsAction
  | InventoryUpdateLogsFailureAction
  | InventoryUpdateLogsSuccessAction
  | GetStockAgeingAction
  | GetStockAgeingFailureAction
  | GetStockAgeingSuccessAction
  | GetInventorySTockList
  | GetInventorySTockListSuccessAction
  | GetInventorySTockListFailureAction;
