import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromSelectorV2 from 'src/app/orderV2/store/selectors/orders.selectors';
import { Subscription } from 'rxjs';
import { EditTemplateV2, DeleteTemplateV2 } from 'src/app/orderV2/store/actions/orders.action';
import { AlertService } from 'src/app/shared/services';
import { ConfirmationDeletePopupComponent } from '../confirmation-delete-popup/confirmation-delete-popup.component';
@Component({
  selector: 'app-template-edit-export',
  templateUrl: './template-edit-export.component.html',
  styleUrls: ['./template-edit-export.component.scss']
})
export class TemplateEditExportComponent implements OnInit {
  public currentRoute: string = '';
  editTemplateForm: FormGroup;
  editTemplateSubscription$: Subscription;
  deleteTemplateSubscription$: Subscription;
  loaderEdit: boolean = false;
  loaderDelete: boolean = false;
  isTemplateSaved: boolean = false;
  isTemplateDeleted: boolean = false;
  constructor(
    private store: Store<any>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { data: any },
    private dialogRef: MatDialogRef<TemplateEditExportComponent>
  ) {}

  ngOnInit() {
    this.createEditTemplateForm();
    this.patchData();
  }

  /** @description Save Template Form */
  public createEditTemplateForm() {
    this.editTemplateForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', [Validators.required]]
    });
  }

  /** @description Patch Form Values */
  public patchData() {
    this.editTemplateForm.patchValue({
      name: this.data['template'],
      description: this.data['description']
    });
  }

  /** @description Edit Template */
  public editTemplate() {
    this.loaderEdit = true;
    const body = {
      id: this.data['id'],
      template: this.editTemplateForm.value.name,
      description: this.editTemplateForm.value.description
    };

    if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
      this.store.dispatch(new EditTemplateV2(body));
      this.editTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.editTemplateData)).subscribe(response => {
        if (response && response['editTemplateData']) {
          this.loaderEdit = false;
          this.dialogRef.close({ event: 'close', data: response });
        }
      });
    } else {
      this.store.dispatch(new EditTemplateV2(body));
      this.editTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.editTemplateData)).subscribe(response => {
        if (response && response['editTemplateData']) {
          this.loaderEdit = false;
          this.dialogRef.close({ event: 'close', data: response });
        }
      });
    }
  }

  /** @description Delete Template */
  public deleteTemplate() {
    this.loaderDelete = true;
    this.currentRoute = this.router.url;
    if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
      this.store.dispatch(new DeleteTemplateV2(this.data['id']));
      this.deleteTemplateSubscription$ = this.store
        .pipe(select(fromSelectorV2.deleteTemplateData))
        .subscribe(response => {
          if (response) this.loaderDelete = false;
          if (response && response['deleteTemplateData'] && !response['deleteTemplateData']['error']) {
            this.dialogRef.close({ event: 'close', data: response });
          }
        });
    } else {
      this.store.dispatch(new DeleteTemplateV2(this.data['id']));
      this.deleteTemplateSubscription$ = this.store
        .pipe(select(fromSelectorV2.deleteTemplateData))
        .subscribe(response => {
          if (response) this.loaderDelete = false;
          if (response && response['deleteTemplateData'] && !response['deleteTemplateData']['error']) {
            this.dialogRef.close({ event: 'close', data: response });
          }
        });
    }
  }

  /** @description Delete Template */
  deleteConfirmationDialog() {
    let dialogRef = this.dialog.open(ConfirmationDeletePopupComponent, {
      panelClass: 'confirmation-delete-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.deleteTemplate();
    });
  }

  /** @description Show template success message */
  public closeTemplateSaved() {
    this.isTemplateSaved = false;
  }

  /** @description Show template deleted success message */
  public closeTemplateDeleted() {
    this.isTemplateDeleted = false;
  }

  ngOnDestroy() {
    if (this.editTemplateSubscription$) this.editTemplateSubscription$.unsubscribe();
    if (this.deleteTemplateSubscription$) this.deleteTemplateSubscription$.unsubscribe();
  }
}
