import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-ui',
  templateUrl: './loader-ui.component.html',
  styleUrls: ['./loader-ui.component.scss']
})
export class LoaderUiComponent implements OnInit {
  constructor() {}
  @Input() loaderText: string;

  ngOnInit(): void {}
}
