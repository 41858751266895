import { Injectable } from '@angular/core';
import { CanActivate, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services';
import { ROUTES } from '../constants/routes';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private store: Store<AppState>) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(route);
  }

  checkLogin(route?): boolean {
    const returnUrl = window.location.href;
    const slugFromStorage = this.auth.getAccountSlug() ? this.auth.getAccountSlug().toLowerCase() : null;
    const slugFromUrl = window.location.hostname.split('.')[0];
    if (!environment.production) {
      if (this.auth.isLoggedIn && slugFromStorage) {
        return true;
      }
      this.auth.setReturnUrl(returnUrl);
      this.router.navigate([ROUTES.LOGIN]);
      return false;
    } else {
      if (
        this.auth.isLoggedIn &&
        slugFromStorage &&
        slugFromUrl.toLowerCase() === (slugFromStorage || '').toLowerCase()
      ) {
        return true;
      }
      if (
        this.auth.isLoggedIn &&
        slugFromStorage &&
        slugFromUrl.toLowerCase() !== (slugFromStorage || '').toLowerCase()
      ) {
        if (slugFromUrl === 'auth') {
          const modifiedUrl = returnUrl.replace('auth', slugFromStorage);
          window.location.href = modifiedUrl;
          return true;
        } else {
          this.auth.deleteItemFromCookie(this.auth.WORKSPACE_KEY);
          this.auth.deleteItemFromCookie(this.auth.WAREHOUSE_KEY);
          this.auth.setReturnUrl(returnUrl);
          this.goToAccountListPage();
          return false;
        }
      }

      if (this.auth.isLoggedIn && !slugFromStorage) {
        if (this.checkUserHasAccessToAccount(slugFromUrl)) {
          this.auth.setWorkspace(slugFromUrl);
          this.auth.setReturnUrl(returnUrl);
          this.router.navigate([ROUTES.ON_SIGNIN_COMPLETE]);
          return false;
        }
        this.goToAccountListPage();
        return false;
      }
      this.auth.setReturnUrl(returnUrl);
      window.location.href = 'https://' + environment.DOMAIN + ROUTES.LOGIN;
      return false;
    }
  }

  goToAccountListPage() {
    !environment.production
      ? this.router.navigate([ROUTES.ON_SIGNIN_COMPLETE])
      : (window.location.href = 'https://' + environment.DOMAIN + ROUTES.ON_SIGNIN_COMPLETE);
  }

  checkUserHasAccessToAccount(accountSlug) {
    const accountList: any = this.auth.getAccountLists();
    var hasAccess = false;
    if (accountList) {
      for (let i = 0; i < accountList.length; i++) {
        if (accountList[i].toLowerCase() === accountSlug.toLowerCase()) {
          hasAccess = true;
          break;
        }
      }
    }
    return hasAccess;
  }
}
