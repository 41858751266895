import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strFormatter'
})
export class StringFormatterPipe implements PipeTransform {
  transform(str: any): any {
    if (str && typeof str == 'string' && str !== null) {
      // return str.replace(/(_|-)/g, ' ');
      str.replace('_', ' ');
      str = str.replace(/_/g, ' ');
      return str;
    } else {
      return '';
    }
  }
}
