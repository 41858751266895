import { ProductState } from '../state/product.state';
import { ProductActionsTypes } from '../actions/product.action';
import * as ProductActions from '../actions/product.action';

export const initialState: ProductState = {
  productListLoaded: false,
  productListLoading: false,
  products: null,
  drafts: null,
  draftListLoaded: false,
  draftListLoading: false,
  createProduct: null,
  creatingProduct: false,
  createdProduct: false,
  editProduct: null,
  editedProduct: false,
  editingProduct: false,
  skuCheck: null,
  skuChecked: false,
  skuChecking: false,
  brand: null,
  brandLoaded: false,
  brandLoading: false,
  exportJob: null,
  property: null,
  propertyListLoaded: false,
  propertyListLoading: false,
  createBulkProduct: null,
  creatingBulkProduct: false,
  createdBulkProduct: false,
  documentStatus: null,
  documentStatusChecked: false,
  documentStatusChecking: false,
  filters: null,
  filtersLoaded: false,
  filtersLoading: false,
  inventoryDetails: null,
  inventoryLoaded: false,
  inventoryLoading: false,
  warehouseList: null,
  warehouseLoaded: false,
  warehouseLoading: false,
  channelList: null,
  channelLoaded: false,
  channelLoading: false,
  productAvailability: null,
  productAvailabilityLoaded: false,
  productAvailabilityLoading: false,
  portalsList: [],
  portalsListLoaded: false,
  portalsListLoading: false,
  fromProductList: null,
  fromProductListLoaded: false,
  fromProductListLoading: false,
  toProductList: null,
  toProductListLoaded: false,
  toProductListLoading: false,
  toSuggetionProductList: null,
  toSuggetionProductListLoading: false,
  toSuggetionProductListLoaded: false,
  mergeProduct: null,
  mergeProductLoaded: false,
  mergeProductLoading: false,
  resolverLoader: false,

  inventoryLastUpdate: null,
  inventoryLastUpdateLoaded: false,
  inventoryLastUpdateLoading: false,

  inventoryUpdateLogs: null,
  inventoryUpdateLogsLoaded: false,
  inventoryUpdateLogsLoading: false,

  deleteDraftLoaded: false,
  deleteDraftLoading: false,
  deleteDraftProduct: null,

  stockAgeing: null,
  stockAgeingLoaded: false,
  stockAgeingLoading: false,

  inventorySTockList: null,
  inventorySTockListLoaded: false,
  inventorySTockListLoading: false
};

export function ProductReducer(state = initialState, action: ProductActions.ProductActionsList): ProductState {
  switch (action.type) {
    //GET PRODUCT LIST
    case ProductActionsTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        productListLoading: true,
        productListLoaded: false,
        products: null
      };
    case ProductActionsTypes.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productListLoading: true,
        productListLoaded: true,
        products: action.payload
      };
    case ProductActionsTypes.GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        productListLoading: false,
        productListLoaded: false,
        products: action.payload
      };
    case ProductActionsTypes.GET_DRAFT_PRODUCT_LIST:
      return {
        ...state,
        draftListLoading: true,
        draftListLoaded: false,
        drafts: null
      };
    case ProductActionsTypes.GET_DRAFT_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        draftListLoading: true,
        draftListLoaded: true,
        drafts: action.payload
      };
    case ProductActionsTypes.GET_DRAFT_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        draftListLoading: false,
        draftListLoaded: false,
        drafts: action.payload
      };
    //GET PROPERTY LIST
    case ProductActionsTypes.GET_PROPERTY_LIST:
      return {
        ...state,
        propertyListLoading: true,
        propertyListLoaded: false,
        property: null
      };
    case ProductActionsTypes.GET_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        propertyListLoading: true,
        propertyListLoaded: true,
        property: action.payload
      };
    case ProductActionsTypes.GET_PROPERTY_LIST_FAILURE:
      return {
        ...state,
        propertyListLoading: false,
        propertyListLoaded: false,
        property: action.payload
      };
    //CREATE PRODUCT
    case ProductActionsTypes.CREATE_PRODUCT:
      return {
        ...state,
        createdProduct: false,
        creatingProduct: true
      };
    case ProductActionsTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        createProduct: action.payload,
        creatingProduct: true,
        createdProduct: true
      };
    case ProductActionsTypes.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        createdProduct: false,
        creatingProduct: false,
        createProduct: action.payload
      };
    //CREATE BULK PRODUCT
    case ProductActionsTypes.CREATE_BULK_PRODUCT:
      return {
        ...state,
        creatingBulkProduct: true,
        createdBulkProduct: false
      };
    case ProductActionsTypes.CREATE_BULK_PRODUCT_SUCCESS:
      return {
        ...state,
        createBulkProduct: action.payload,
        creatingBulkProduct: true,
        createdBulkProduct: true
      };
    case ProductActionsTypes.CREATE_BULK_PRODUCT_FAILURE:
      return {
        ...state,
        createdBulkProduct: false,
        creatingBulkProduct: false
      };
    // GET INVENTORY DETAILS
    case ProductActionsTypes.GET_INVENTORY:
      return {
        ...state,
        inventoryLoading: true,
        inventoryLoaded: false
      };
    case ProductActionsTypes.GET_INVENTORY_SUCCESS:
      return {
        ...state,
        inventoryDetails: action.payload,
        inventoryLoading: true,
        inventoryLoaded: true
      };
    case ProductActionsTypes.GET_INVENTORY_FAILURE:
      return {
        ...state,
        inventoryLoaded: false,
        inventoryLoading: false
      };
    //EDIT PRODUCT
    case ProductActionsTypes.EDIT_PRODUCT:
      return {
        ...state,
        editedProduct: false,
        editingProduct: true,
        editProduct: null
      };
    case ProductActionsTypes.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        editProduct: action.payload,
        editingProduct: false,
        editedProduct: true
      };
    case ProductActionsTypes.EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        editProduct: action.payload,
        editedProduct: false,
        editingProduct: false
      };
    //SKU CHECK
    case ProductActionsTypes.SKU_CHECK:
      return {
        ...state,
        skuChecking: true,
        skuChecked: false,
        skuCheck: null
      };
    case ProductActionsTypes.SKU_CHECK_SUCCESS:
      return {
        ...state,
        skuChecking: true,
        skuChecked: true,
        skuCheck: action.payload
      };
    case ProductActionsTypes.SKU_CHECK_FAILURE:
      return {
        ...state,
        skuChecked: false,
        skuChecking: false,
        skuCheck: action.payload
      };
    //DOCUMENT STATUS CHECK
    case ProductActionsTypes.DOCUMENT_UPLOAD_STATUS:
      return {
        ...state,
        documentStatusChecking: true,
        documentStatusChecked: false,
        documentStatus: action.payload
      };
    case ProductActionsTypes.DOCUMENT_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        documentStatusChecking: true,
        documentStatusChecked: true,
        documentStatus: action.payload
      };
    case ProductActionsTypes.DOCUMENT_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        documentStatusChecked: false,
        documentStatusChecking: false
      };
    //GET BRAND
    case ProductActionsTypes.GET_BRAND:
      return {
        ...state,
        brandLoaded: false,
        brandLoading: true,
        brand: null
      };
    case ProductActionsTypes.GET_BRAND_SUCCESS:
      return {
        ...state,
        brandLoaded: true,
        brandLoading: false,
        brand: action.payload
      };
    case ProductActionsTypes.GET_BRAND_FAILURE:
      return {
        ...state,
        productListLoading: false,
        productListLoaded: false,
        brand: action.payload
      };
    //EXPORT JOB
    case ProductActionsTypes.EXPORT_JOB:
      return {
        ...state,
        exportJob: action.payload
      };
    case ProductActionsTypes.EXPORT_JOB_SUCCESS:
      return {
        ...state,
        exportJob: action.payload
      };
    case ProductActionsTypes.EXPORT_JOB_FAILURE:
      return {
        ...state
      };
    // FILTERS
    case ProductActionsTypes.GET_FILTERS:
      return {
        ...state,
        filtersLoading: true,
        filtersLoaded: false,
        filters: null
      };
    case ProductActionsTypes.GET_FILTERS_SUCCESS:
      return {
        ...state,
        filtersLoading: true,
        filtersLoaded: true,
        filters: action.payload
      };
    case ProductActionsTypes.GET_FILTERS_FAILURE:
      return {
        ...state,
        filtersLoading: false,
        filtersLoaded: false,
        filters: action.payload
      };
    //GET Warehouse LIST
    case ProductActionsTypes.GET_WAREHOUSE:
      return {
        ...state,
        warehouseLoading: true,
        warehouseLoaded: false,
        warehouseList: null
      };
    case ProductActionsTypes.GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseLoading: true,
        warehouseLoaded: true,
        warehouseList: action.payload
      };
    case ProductActionsTypes.GET_WAREHOUSE_FAILURE:
      return {
        ...state,
        warehouseLoading: false,
        warehouseLoaded: false,
        warehouseList: action.payload
      };
    // //CREATE LISTINGS
    // case ProductActionsTypes.CREATE_LISTINGS:
    //   return {
    //     ...state,
    //     creatingListings: true,
    //     createdListings: false,
    //     createListigs: null
    //   };
    // case ProductActionsTypes.CREATE_LISTINGS_SUCCESS:
    //   return {
    //     ...state,
    //     creatingListings: true,
    //     createdListings: true,
    //     createListigs: action.payload
    //   };
    // case ProductActionsTypes.CREATE_LISTINGS_FAILURE:
    //   return {
    //     ...state,
    //     creatingListings: false,
    //     createdListings: false,
    //     createListigs: action.payload
    //   };
    //CHANNEL LIST
    case ProductActionsTypes.CHANNEL_LIST:
      return {
        ...state,
        channelLoading: true,
        channelLoaded: false,
        channelList: null
      };
    case ProductActionsTypes.CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        channelLoading: true,
        channelLoaded: true,
        channelList: action.payload
      };
    case ProductActionsTypes.CHANNEL_LIST_FAILURE:
      return {
        ...state,
        channelLoading: false,
        channelLoaded: false,
        channelList: action.payload
      };
    //PRODUCT AVAILABILITY
    case ProductActionsTypes.PRODUCT_AVAILABILITY:
      return {
        ...state,
        productAvailabilityLoading: true,
        productAvailabilityLoaded: false,
        productAvailability: null
      };
    case ProductActionsTypes.PRODUCT_AVAILABILITY_SUCCESS:
      return {
        ...state,
        productAvailabilityLoading: true,
        productAvailabilityLoaded: true,
        productAvailability: action.payload
      };
    case ProductActionsTypes.PRODUCT_AVAILABILITY_FAILURE:
      return {
        ...state,
        productAvailabilityLoading: false,
        productAvailabilityLoaded: false,
        productAvailability: action.payload
      };

    // Get Portal List
    case ProductActionsTypes.GET_PORTALS_LIST: {
      return {
        ...state,
        portalsListLoaded: false,
        portalsListLoading: true
      };
    }

    case ProductActionsTypes.GET_PORTALS_LIST_SUCCESS: {
      return {
        ...state,
        portalsListLoaded: true,
        portalsListLoading: false,
        portalsList: action.payload
      };
    }

    case ProductActionsTypes.GET_PORTALS_LIST_FAILURE: {
      return {
        ...state,
        portalsListLoaded: true,
        portalsListLoading: false
      };
    }
    // GET FROM PRODUCT LIST
    case ProductActionsTypes.GET_FROM_PRODUCT: {
      return {
        ...state,
        fromProductListLoaded: false,
        fromProductListLoading: true,
        fromProductList: null
      };
    }

    case ProductActionsTypes.GET_FROM_PRODUCT_SUCCESS: {
      return {
        ...state,
        fromProductListLoaded: true,
        fromProductListLoading: false,
        fromProductList: action.payload
      };
    }

    case ProductActionsTypes.GET_FROM_PRODUCT_FAILURE: {
      return {
        ...state,
        fromProductListLoaded: true,
        fromProductListLoading: false
      };
    }
    // GET TO PRODUCT LIST
    case ProductActionsTypes.GET_TO_PRODUCT: {
      return {
        ...state,
        toProductListLoaded: false,
        toProductListLoading: true,
        toProductList: null
      };
    }

    case ProductActionsTypes.GET_TO_PRODUCT_SUCCESS: {
      return {
        ...state,
        toProductListLoaded: true,
        toProductListLoading: false,
        toProductList: action.payload
      };
    }

    case ProductActionsTypes.GET_TO_PRODUCT_FAILURE: {
      return {
        ...state,
        toProductListLoaded: true,
        toProductListLoading: false
      };
    }

    // GET TO SUGGESTION PRODUCT LIST
    case ProductActionsTypes.GET_SUGGESTION_PRODUCT: {
      return {
        ...state,
        toSuggetionProductListLoaded: false,
        toSuggetionProductListLoading: true,
        toSuggetionProductList: null
      };
    }

    case ProductActionsTypes.GET_SUGGESTION_PRODUCT_SUCCESS: {
      return {
        ...state,
        toSuggetionProductListLoaded: true,
        toSuggetionProductListLoading: false,
        toSuggetionProductList: action.payload
      };
    }

    case ProductActionsTypes.GET_SUGGESTION_PRODUCT_FAILURE: {
      return {
        ...state,
        toSuggetionProductListLoaded: true,
        toSuggetionProductListLoading: false,
        toSuggetionProductList: action.payload
      };
    }
    //Merge product
    case ProductActionsTypes.MERGE_PRODUCTS: {
      return {
        ...state,
        mergeProductLoaded: false,
        mergeProductLoading: true,
        mergeProduct: null
      };
    }

    case ProductActionsTypes.MERGE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        mergeProductLoaded: true,
        mergeProductLoading: false,
        mergeProduct: action.payload
      };
    }

    case ProductActionsTypes.MERGE_PRODUCTS_FAILURE: {
      return {
        ...state,
        mergeProductLoaded: true,
        mergeProductLoading: false,
        mergeProduct: action.payload
      };
    }
    // INVENTORY Last UPDATE
    case ProductActionsTypes.GET_LAST_INVENTORY_UPDATE: {
      return {
        ...state,
        inventoryLastUpdateLoaded: false,
        inventoryLastUpdateLoading: true,
        inventoryLastUpdate: null
      };
    }

    case ProductActionsTypes.GET_LAST_INVENTORY_UPDATE_SUCCCESS: {
      return {
        ...state,
        inventoryLastUpdateLoaded: true,
        inventoryLastUpdateLoading: false,
        inventoryLastUpdate: action.payload
      };
    }

    case ProductActionsTypes.GET_LAST_INVENTORY_UPDATE_FAILURE: {
      return {
        ...state,
        inventoryLastUpdateLoaded: true,
        inventoryLastUpdateLoading: false,
        inventoryLastUpdate: action.payload
      };
    }
    // INVENTORY UPDATE Logs
    case ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS: {
      return {
        ...state,
        inventoryUpdateLogsLoaded: false,
        inventoryUpdateLogsLoading: true,
        inventoryUpdateLogs: null
      };
    }

    case ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS_SUCCCESS: {
      return {
        ...state,
        inventoryUpdateLogsLoaded: true,
        inventoryUpdateLogsLoading: false,
        inventoryUpdateLogs: action.payload
      };
    }

    case ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS_FAILURE: {
      return {
        ...state,
        inventoryUpdateLogsLoaded: true,
        inventoryUpdateLogsLoading: false,
        inventoryUpdateLogs: action.payload
      };
    }
    case ProductActionsTypes.SHOW_PROGRESS_SPINNER:
      return {
        ...state,
        resolverLoader: true
      };
    case ProductActionsTypes.HIDE_PROGRESS_SPINNER:
      return {
        ...state,
        resolverLoader: false
      };

    //Delete draft Product
    case ProductActionsTypes.DELETE_DRAFT_PRODUCTS:
      return {
        ...state,
        deleteDraftLoading: true,
        deleteDraftLoaded: false,
        deleteDraftProduct: null
      };
    case ProductActionsTypes.DELETE_DRAFT_PRODUCTS_SUCCESS:
      return {
        ...state,
        deleteDraftLoading: true,
        deleteDraftLoaded: true,
        deleteDraftProduct: action.payload
      };
    case ProductActionsTypes.DELETE_DRAFT_PRODUCTS_FAILURE:
      return {
        ...state,
        deleteDraftLoading: false,
        deleteDraftLoaded: false,
        deleteDraftProduct: action.payload
      };

    //Stock Ageing
    case ProductActionsTypes.GET_STOCK_AGEING:
      return {
        ...state,
        stockAgeingLoading: true,
        stockAgeingLoaded: false,
        stockAgeing: null
      };
    case ProductActionsTypes.GET_STOCK_AGEING_SUCCESS:
      return {
        ...state,
        stockAgeingLoading: true,
        stockAgeingLoaded: true,
        stockAgeing: action.payload
      };
    case ProductActionsTypes.GET_STOCK_AGEING_FAILURE:
      return {
        ...state,
        stockAgeingLoading: false,
        stockAgeingLoaded: false,
        stockAgeing: action.payload
      };

    //  stock inventory list
    case ProductActionsTypes.GET_INVENTORY_LIST:
      return {
        ...state,
        inventorySTockListLoading: true,
        inventorySTockListLoaded: false,
        inventorySTockList: null
      };
    case ProductActionsTypes.GET_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        inventorySTockListLoading: false,
        inventorySTockListLoaded: true,
        inventorySTockList: action.payload
      };
    case ProductActionsTypes.GET_INVENTORY_LIST_FAILURE:
      return {
        ...state,
        inventorySTockListLoading: false,
        inventorySTockListLoaded: true,
        inventorySTockList: action.payload
      };

    default: {
      return state;
    }
  }
}

//PRODUCT LIST
export const getProductList = (state: ProductState) => state.products;
export const getProductListLoaded = (state: ProductState) => state.productListLoaded;
export const getProductListLoading = (state: ProductState) => state.productListLoading;
//DRAFT LIST
export const getDraftProductList = (state: ProductState) => state.drafts;
export const getDraftProductListLoaded = (state: ProductState) => state.draftListLoaded;
export const getDraftProductListLoading = (state: ProductState) => state.draftListLoading;
//PROPERTY LIST
export const getPropertyList = (state: ProductState) => state.property;
export const getPropertyListLoaded = (state: ProductState) => state.propertyListLoaded;
export const getPropertyListLoading = (state: ProductState) => state.propertyListLoading;
//CREATE PRODUCT
export const getCreatedProduct = (state: ProductState) => state.createProduct;
export const getCreatingProductStatus = (state: ProductState) => state.creatingProduct;
export const getCreatedProductStatus = (state: ProductState) => state.createdProduct;
// CREATE BULK PRODUCT
export const getCreatedBulkProduct = (state: ProductState) => state.createBulkProduct;
export const getCreatingBulkProductStatus = (state: ProductState) => state.creatingBulkProduct;
export const getCreatedBulkProductStatus = (state: ProductState) => state.createdBulkProduct;
//EDIT PRODUCT
export const getEditedProduct = (state: ProductState) => state.editProduct;
export const getEditingProductStatus = (state: ProductState) => state.editingProduct;
export const getEditedProductStatus = (state: ProductState) => state.editedProduct;
//SKU CHECK
export const getSKUCheckProduct = (state: ProductState) => state.skuCheck;
export const getSKUCheckedProduct = (state: ProductState) => state.skuChecked;
export const getSKUCheckingProduct = (state: ProductState) => state.skuChecking;
//DOCUMENT STATUS CHECK
export const getDocumentStatus = (state: ProductState) => state.documentStatus;
export const getDocumentStatusChecked = (state: ProductState) => state.documentStatusChecked;
export const getDocumentStatusChecking = (state: ProductState) => state.documentStatusChecking;
//GET BRAND
export const getBrands = (state: ProductState) => state.brand;
export const getBrandsLoading = (state: ProductState) => state.brandLoading;
export const getBrandsLoaded = (state: ProductState) => state.brandLoaded;

export const getExportJobObj = (state: ProductState) => state.exportJob;
// FILTERS
export const getFilters = (state: ProductState) => state.filters;
export const getFiltersLoaded = (state: ProductState) => state.filtersLoaded;
export const getFiltersLoading = (state: ProductState) => state.filtersLoading;
// INVENTORY DETAILS
export const getInventoryDetails = (state: ProductState) => state.inventoryDetails;
export const inventoryLoaded = (state: ProductState) => state.inventoryLoaded;
export const inventoryLoading = (state: ProductState) => state.inventoryLoading;
// WAREHOIUSE DETAILS
export const getWarehouseList = (state: ProductState) => state.warehouseList;
export const warehouseLoaded = (state: ProductState) => state.warehouseLoaded;
export const warehouseLoading = (state: ProductState) => state.warehouseLoading;
// // CREATE LISTINGS
// export const createListings = (state: ProductState) => state.createListigs;
// export const createdListings = (state: ProductState) => state.createdListings;
// export const creatingListigs = (state: ProductState) => state.creatingListings;
// CHANNEL LIST
export const channelList = (state: ProductState) => state.channelList;
export const channelLoaded = (state: ProductState) => state.channelLoaded;
export const channelLoading = (state: ProductState) => state.channelLoading;
// Product Availability
export const productAvailability = (state: ProductState) => state.productAvailability;
export const productAvailabilityLoaded = (state: ProductState) => state.productAvailabilityLoaded;
export const productAvailabilityLoading = (state: ProductState) => state.productAvailabilityLoading;
// Get Portal Details
export const getPortalsListLoaded = (state: ProductState) => state.portalsListLoaded;
export const getPortalsListLoading = (state: ProductState) => state.portalsListLoading;
export const getPortalsList = (state: ProductState) => state.portalsList;
// GET FROM PRODUCT LIST
export const getFromProductListLoaded = (state: ProductState) => state.fromProductListLoaded;
export const getFromProductListLoading = (state: ProductState) => state.fromProductListLoading;
export const getFromProductList = (state: ProductState) => state.fromProductList;
// GET TO PRODUCT LIST
export const getToProductListLoaded = (state: ProductState) => state.toProductListLoaded;
export const getToProductListLoading = (state: ProductState) => state.toProductListLoading;
export const getToProductList = (state: ProductState) => state.toProductList;
// GET TO SUGGETION PRODUCT LIST
export const getSuggetionProductListLoaded = (state: ProductState) => state.toSuggetionProductListLoaded;
export const getSuggetionProductListLoading = (state: ProductState) => state.toSuggetionProductListLoading;
export const getSuggetionProductList = (state: ProductState) => state.toSuggetionProductList;
// MERGE PRODICT
export const mergeProductLoaded = (state: ProductState) => state.mergeProductLoaded;
export const mergeProductLoading = (state: ProductState) => state.mergeProductLoading;
export const mergeProduct = (state: ProductState) => state.mergeProduct;
// Inventory Update Last
export const inventoryLastUpdateLoaded = (state: ProductState) => state.inventoryLastUpdateLoaded;
export const inventoryLastUpdateLoading = (state: ProductState) => state.inventoryLastUpdateLoading;
export const inventoryLastUpdate = (state: ProductState) => state.inventoryLastUpdate;
// Inventory Update Logs
export const inventoryUpdateLogsLoaded = (state: ProductState) => state.inventoryUpdateLogsLoaded;
export const inventoryUpdateLogsLoading = (state: ProductState) => state.inventoryUpdateLogsLoading;
export const inventoryUpdateLogs = (state: ProductState) => state.inventoryUpdateLogs;

// Delete Darft PRODICT
export const deleteDraftProductLoaded = (state: ProductState) => state.deleteDraftLoaded;
export const deleteDraftProductLoading = (state: ProductState) => state.deleteDraftLoading;
export const deleteDraftProduct = (state: ProductState) => state.deleteDraftProduct;

// Stock Ageing
export const stockAgeingLoaded = (state: ProductState) => state.stockAgeingLoaded;
export const stockAgeingLoading = (state: ProductState) => state.stockAgeingLoading;
export const stockAgeing = (state: ProductState) => state.stockAgeing;

export const inventorySTockListLoaded = (state: ProductState) => state.inventorySTockListLoaded;
export const inventorySTockListLoading = (state: ProductState) => state.inventorySTockListLoading;
export const inventorySTockList = (state: ProductState) => state.inventorySTockList;

export const getResolverLoader = (state: ProductState) => state.resolverLoader;
