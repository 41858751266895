import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-segment-with-count',
  templateUrl: './segment-with-count.component.html',
  styleUrls: ['./segment-with-count.component.scss']
})
export class SegmentWithCountComponent implements OnInit {
  @Input() buildingBlock: string;
  @Input() filtersArray: [];
  @Output() emitSearchKey = new EventEmitter<any>();
  @Output() emitFilterForm = new EventEmitter<any>();
  private _queryFilterData = new BehaviorSubject<any>(null);

  @Input()
  set queryFilterData(value: any) {
    this._queryFilterData.next(value);
  }

  get queryFilterData() {
    return this._queryFilterData.getValue();
  }

  constructor() {}

  ngOnInit(): void {}

  searchInput(event) {
    this.emitSearchKey.emit(event);
  }

  emitFilterData(event) {
    this.emitFilterForm.emit(event);
  }
}
