/**
 * API Route List
 */
export const API_ROUTE_LIST: any = {
  WAREHOUSE_LIST: '/warehouse?enrollmentStatus=ENROLLED&status= 1',

  RECALL_CONSIGNMENT: '/recall-consignment',
  RECALL_CONSIGNMENT_UPDATE: '/recall-consignment/',
  RECALL_CONSIGNMENT_LIST: '/recall-consignment',
  GET_RECALL_AVAILABLE_TIMESLOTS: 'v1/recall-consignment/timeSlotAvailability?warehouseId=',
  //RECALL_GET_GRN_REPORT: 'v1/recall-consignment/grnItems/',
  RECALL_GET_GRN_REPORT: 'v1/recall-consignment/gatepassItems/',
  CREATE_RECALL_CONSIGNMENT_EXPORT_JOB: '/export-job',
  SET_UP: 'platform/api/v1/setup',
  SEllS_SUMMARY: 'v1/dashboard/sales',
  // ORDER_RETURN_SUMMARY: '/api/v1/searchOrderReturnIndexData',
  ORDER_RETURN_SUMMARY: 'v2/orders/search',
  INVENTORY_SUMMARY: 'v1/inventorySummary',
  CONSIGNMENT_SUMMARY: 'v1/dashboard/consignment',
  CHANNEL: 'v1/dashboard/sales/channel',
  MARK_DO_LATER: 'platform/api/v1/markDoLater',
  CREATE_EXPORT_JOB_ORDER_RETURN: '/export-job',
  APP_AUTHORIZE: '/api/v1/appinstall/consent'
};
