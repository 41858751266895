import { Action, createAction } from '@ngrx/store';

export const ruleList = '[rulelist component] list';
export const ruleListSuccess = '[rulelist component] success';
export const ruleListError = '[rulelist component] Error';
export const ruleSave = '[createrule component] save';
export const ruleSaveSuccess = '[createrule component] save success';
export const ruleSaveError = '[createrule component] Error';
export const ruleDelete = '[deleterule component] delete';
export const ruleDeleteSuccess = '[deleterule component] delete success';
export const ruleDeleteError = '[deleterule component] Error';
export const dummyAction = '[dummy Action]';

export class ruleListRequestAction implements Action {
  readonly type = ruleList;
  // constructor(readonly payload:any) {}
}

export class ruleListSuccessAction implements Action {
  readonly type = ruleListSuccess;
  constructor(readonly payload: any) {}
}

export class ruleListErrorAction implements Action {
  readonly type = ruleListError;
  constructor(readonly payload: any) {}
}

export class ruleSaveAction implements Action {
  readonly type = ruleSave;
  constructor(readonly payload: any) {}
}

export class ruleSaveSuccessAction implements Action {
  readonly type = ruleSaveSuccess;
  constructor(readonly payload: any) {}
}
export class ruleSaveErrorAction implements Action {
  readonly type = ruleSaveError;
  constructor(readonly payload: any) {}
}
export class ruleDeleteAction implements Action {
  readonly type = ruleDelete;
  constructor(readonly payload: any) {}
}

export class ruleDeleteSuccessAction implements Action {
  readonly type = ruleDeleteSuccess;
  constructor(readonly payload: any) {}
}
export class ruleDeleteErrorAction implements Action {
  readonly type = ruleDeleteError;
  constructor(readonly payload: any) {}
}

export type shippingActions =
  | ruleListRequestAction
  | ruleListSuccessAction
  | ruleSaveAction
  | ruleSaveSuccessAction
  | ruleDeleteAction
  | ruleDeleteSuccessAction
  | ruleListErrorAction
  | ruleDeleteErrorAction
  | ruleSaveErrorAction;

// export class ruleListSuccessAction {
//     readonly type = ruleListSuccess;
//     constructor(readonly payload:any) {}
// }
