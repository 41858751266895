import { createSelector } from '@ngrx/store';
import * as fromSegment from '../reducers/segment.reducers';
import { AppState } from 'src/app/app.state';

const getSegmentState = (state: AppState) => state.segmentState;

export const getSegmentsData = createSelector(getSegmentState, fromSegment.getSegmentsData);
export const getAllOrdersCountData = createSelector(getSegmentState, fromSegment.getAllOrdersCountData);
export const getSavedSegmentDetails = createSelector(getSegmentState, fromSegment.getSavedSegmentDetails);
export const getEditSegmentDetails = createSelector(getSegmentState, fromSegment.getEditSegmentDetails);
export const getDeleteSegmentDetails = createSelector(getSegmentState, fromSegment.getDeleteSegmentDetails);
export const getNoScanReturnFiltersData = createSelector(getSegmentState, fromSegment.getNoScanReturnFiltersData);
export const getNoScanReturnSegmentCountData = createSelector(
  getSegmentState,
  fromSegment.getNoScanReturnSegmentCountData
);
