import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationsService {
  constructor(private snackbar: MatSnackBar) {}

  showSuccessToast(message) {
    const successSnackbarRef = this.snackbar.open(message, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['new-success-notification'],
      duration: 2000
    });
    successSnackbarRef.onAction().subscribe(() => {
      this.onSnackBarAction(successSnackbarRef);
    });
  }

  showErrorToast(message) {
    const errorSnackbarRef = this.snackbar.open(message, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['new-error-notification'],
      duration: 2000
    });

    errorSnackbarRef.onAction().subscribe(() => {
      this.onSnackBarAction(errorSnackbarRef);
    });
  }

  onSnackBarAction(snackbarRef: MatSnackBarRef<any>) {
    snackbarRef.dismiss();
  }

  showFailureToast(message) {
    const failureSnackbarRef = this.snackbar.open(message, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['new-failed-notification'],
      duration: 2000
    });
    failureSnackbarRef.onAction().subscribe(() => {
      this.onSnackBarAction(failureSnackbarRef);
    });
  }
}
