import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login-to-desktop',
  templateUrl: './login-to-desktop.component.html',
  styleUrls: ['./login-to-desktop.component.scss']
})
export class LoginToDesktopComponent implements OnInit {
  numberOfWorkspace: number;
  hasFlex: any;
  showDropdown: boolean;
  type: any;
  accountList = [];
  currentFacilityLabel: any;
  searchFc: string;
  selectedOptionFC: any;
  currentWorkspace: string;
  routeSubscription: any;

  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.url.subscribe(() => {
      this.type = this.route.snapshot.params['type'];
    });
    this.numberOfWorkspace = this.authService.getNumberOfWorkspaces();
    this.hasFlex = this.authService.getFlexSlug();
    if (this.type === 'skipPicking') {
      this.selectedOptionFC = this.authService.getFlexSlug() ? this.authService.getFlexSlug() : '';
      this.currentWorkspace = this.authService.getAccountSlug();
      this.getCurrentWorkspaceFCs();
    }
  }

  logOut() {
    this.authService.logout();
  }

  switchWorkspace() {
    this.authService.switchWorkspace();
  }

  goBack() {
    this.authService.gotoPicklistBB();
  }

  expandmenu() {
    /* if (document.getElementsByClassName('filter-dropdown open').length > 0)
      document.getElementsByClassName('filter-dropdown open')[0]['style'].display = 'none'; */
    localStorage.setItem('collapsenav', 'true');
    localStorage.setItem('fullwidth', 'false');
    localStorage.setItem('expandnav', 'false');
  }

  checkexpandnav() {
    let flag = 0;
    if (localStorage.getItem('expandnav') && localStorage.getItem('expandnav') === 'true') {
      flag = 1;
    }
    return flag === 1 ? true : false;
  }

  async getCurrentWorkspaceFCs() {
    const accountList: any = await this.authService.getAccountLists();
    this.setAccountDetails(accountList);
  }

  async setAccountDetails(accountList) {
    const flexAccountSlug = this.authService.getFlexSlug();
    this.accountList = accountList?.filter(
      x => x.parentClientWorkspaceSlug && x.parentClientWorkspaceSlug == this.currentWorkspace
    );
    this.currentFacilityLabel =
      this.accountList?.find(acc => acc.accountSlug === flexAccountSlug)?.facilityLabel ?? null;
  }
  async selectedFC(flexAccountSlug) {
    if (this.selectedOptionFC == flexAccountSlug) return false;
    await this.authService.setFlexSlug(flexAccountSlug, true);
    this.authService.loadInitialModule(true, true, flexAccountSlug);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
