<div class="select-sales-channel">
  <form [formGroup]="salesChannelForm">
    <mat-form-field
      class="select-team-member empty-label-show dropdown-override"
      [ngClass]="
        salesChannelFilterFormData?.salesChannel && salesChannelFilterFormData.salesChannel.length ? 'add-bg-color' : ''
      "
    >
      <mat-select
        placeholder="Sales Channel"
        [multiple]="true"
        name="salesChannel"
        disableOptionCentering
        panelClass="custom-select-ui sales-mrg filter-option order"
        formControlName="salesChannelData"
        #normalSalesChannel
        floatlLabel="never"
      >
        <mat-select-trigger
          *ngIf="salesChannelFilterFormData?.salesChannel && salesChannelFilterFormData.salesChannel.length"
        >
          Sales Channel: {{ displayOnHover(1, salesChannelFilterFormData?.salesChannel) }}
          <ng-container
            *ngIf="salesChannelFilterFormData?.salesChannel && salesChannelFilterFormData.salesChannel.length > 1"
          >
            + {{ salesChannelFilterFormData.salesChannel.length - 1 }} more
          </ng-container>
        </mat-select-trigger>
        <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">
          <!-- [indeterminate]="salesChannelFilterFormData?.salesChannel ? salesChannelFilterFormData.salesChannel.length != salesChannelData.length : false" -->
          All
        </mat-option>
        <mat-option
          *ngFor="let salesChannel of salesChannelData"
          [value]="salesChannel.externalChannelId"
          #matOption
          (click)="toggleApplyButton(matOption.selected)"
        >
          {{ salesChannel.channelLabel }}
          {{ filterHelperService.getFacilityLabel(salesChannel.fulfillmentChannels) }}
          <span class="hovertext" *ngIf="salesChannel.accountSlug == 'eshop'">
            <b
              ><img
                tooltip="You are seeing this because you have subscribed to a sales channel in which Eshopbox is selling party."
                placement="right"
                tooltipWidth="235"
                src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg?updatedAt=1621857296536"
            /></b>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
