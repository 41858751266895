<section class="popup-page white">
  <!-- <a class="page-brand-icon" href="/dashboard/overview"
    ><img src="https://ik.imagekit.io/2gwij97w0o/eshopbox-logo.svg"
  /></a>
  <button class="page-close">
    <img src="https://ik.imagekit.io/2gwij97w0o/single_page/x.svg?updatedAt=1683022822807" />
  </button> -->
  <div class="page-inner">
    <div class="page-content box">
      <img
        src="https://ik.imagekit.io/2gwij97w0o/single_page/something-went-wrong.svg?updatedAt=1692598368756"
        class="top-image"
      />
      <h1>Something went wrong</h1>
      <p>We can’t process your request at the moment. Please try again later.</p>
      <button class="retry" (click)="retry()">
        <img src="https://ik.imagekit.io/2gwij97w0o/single_page/retry.svg?updatedAt=1692599176057" />
        Retry
      </button>
    </div>
  </div>
</section>
