import { Component, OnInit } from '@angular/core';
import { SocketService } from '../../shared/services/socket.service';
import { Subscription } from 'rxjs';
import { PusherService } from 'src/app/download-widget/service/pusher.service';
import { PusherScheduleService } from 'src/app/shared/services/pusher-schedule.service';
@Component({
  selector: 'app-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  importProcessing: any = false;
  importSubscription$: Subscription;
  exportSubscription: Subscription;
  scheduleSubscription: Subscription;
  exportCompletedSubscription: Subscription;
  exportProcessing: any = false;
  scheduleProcessing: any = false;
  exportCompleted: any = false;

  constructor(
    private socSrv: SocketService,
    private puserSrv: PusherService,
    private schedulePusher: PusherScheduleService
  ) {}

  ngOnInit() {
    this.importSubscription$ = this.socSrv.getImportJobFeed().subscribe(res => {
      for (let status in res) {
        if (
          status === 'IMPORT JOB PROCESSING' &&
          res['IMPORT JOB PROCESSING']['accountSlug'] === localStorage.getItem('workspace') &&
          res['IMPORT JOB PROCESSING']['responseData']['userName'] === localStorage.getItem('email')
        ) {
          this.importProcessing = true;
        }
      }
      setTimeout(() => {
        this.importProcessing = false;
      }, 30000);
    });

    this.exportSubscription = this.puserSrv.getExportJobProcessingFeed().subscribe(res => {
      for (let status in res) {
        if (
          status === 'EXPORT JOB PROCESSING' &&
          res['EXPORT JOB PROCESSING']['accountSlug'] === localStorage.getItem('workspace') &&
          res['EXPORT JOB PROCESSING']['responseData']['userName'] === localStorage.getItem('email')
        ) {
          this.exportProcessing = true;
        }
      }
      setTimeout(() => {
        this.exportProcessing = false;
      }, 30000);
    });

    this.exportCompletedSubscription = this.puserSrv.getExportJobCompletedFeed().subscribe(res => {
      for (let status in res) {
        if (
          status === 'EXPORT JOB COMPLETED' &&
          res['EXPORT JOB COMPLETED']['accountSlug'] === localStorage.getItem('workspace') &&
          res['EXPORT JOB COMPLETED']['responseData']['userName'] === localStorage.getItem('email')
        ) {
          this.exportCompleted = true;
          this.closeExport();
        }
      }
      setTimeout(() => {
        this.exportCompleted = false;
      }, 30000);
    });

    this.scheduleSubscription = this.schedulePusher.getScheduleJobProcessingFeed().subscribe(res => {
      for (let status in res) {
        if (
          status === 'EXPORT JOB SCHEDULE PROCESSING' &&
          res['EXPORT JOB SCHEDULE PROCESSING']['accountSlug'] === localStorage.getItem('workspace') &&
          res['EXPORT JOB SCHEDULE PROCESSING']['actor'] === localStorage.getItem('email')
        ) {
          this.scheduleProcessing = true;
        }
      }
      setTimeout(() => {
        this.scheduleProcessing = false;
      }, 30000);
    });
  }

  closeImport() {
    this.importProcessing = false;
  }

  closeExport() {
    this.exportProcessing = false;
  }

  closeSchedule() {
    this.scheduleProcessing = false;
  }

  closeExportCompleted() {
    this.exportCompleted = false;
  }
}
