// import { createReducer, on } from '@ngrx/store';
import { M } from '@angular/cdk/keycodes';
import * as wdAction from './weight-discrypancy.actions';
// export const initialState;
export interface wdState {
  wdData: any;
  wdLoad: boolean;
  wdError: string;
  wdLoading: boolean;
}

export const initialState: wdState = {
  wdData: [],
  wdLoad: false,
  wdError: '',
  wdLoading: false
};

export function weightDiscrypancyReducer(state = initialState, action: wdAction.weightDiscrypancyActions): wdState {
  switch (action.type) {
    case wdAction.weightDiscrypancyList: {
      if (action['payload']['isSmartSegment'] == true) {
        return { ...state, wdLoading: true, wdData: [], wdError: '', wdLoad: false };
      } else {
        return { ...state, wdLoading: true, wdData: { ...state.wdData, data: [] }, wdError: '', wdLoad: false };
      }
    }

    case wdAction.weightDiscrypancyListSuccess: {
      if (action['payload']['isSmartSegment'].toString() == 'true') {
        delete action['payload']['isSmartSegment'];
        return { ...state, wdLoading: false, wdData: action['payload'], wdLoad: false, wdError: '' };
      } else {
        return {
          ...state,
          wdLoading: false,
          wdData: {
            ...state.wdData,
            data: action['payload']['data'],
            newCount: state.wdData.newCount || 0,
            disputedCount: state.wdData.disputedCount || 0,
            lostCount: state.wdData.lostCount || 0,
            notDisputedCount: state.wdData.notDisputedCount || 0,
            wonCount: state.wdData.wonCount || 0,
            escalatedCount: state.wdData.escalatedCount || 0,
            paginationCount: action['payload']['total'] || 0
          },
          wdLoad: false,
          wdError: ''
        };
      }
    }

    case wdAction.weightDiscrypancyListError: {
      return { ...state, wdLoading: false, wdData: [], wdLoad: false, wdError: 'Please try again' };
    }

    case wdAction.weightDiscrypancySave: {
      return { ...state, wdLoading: false, wdError: '', wdLoad: true };
    }

    case wdAction.weightDiscrypancySaveSuccess: {
      let a = [...state.wdData.data];
      let b = { ...state.wdData };

      if (action.payload['tabstatus'] == 'all') {
        action.payload.data.forEach(element => {
          const updatedWdData = a.map(p => (element.id === p.id ? element : p));
          a = [...updatedWdData];
        });
      } else {
        action.payload.data.forEach(element => {
          let index = a.findIndex(item => item.id === element.id);
          a.splice(index, 1);
          a = [...a];
        });
      }
      action.payload.data.forEach(element => {
        if (element.latestStatus == 'disputed') {
          if (element.isEscalated == true) {
            b['disputedCount'] = +b['disputedCount'] + 1;
            b['lostCount'] = +b['lostCount'] - 1;
          } else {
            b['disputedCount'] = +b['disputedCount'] + 1;
            b['newCount'] = +b['newCount'] - 1;
          }
        } else if (element.latestStatus == 'not_disputed') {
          b['notDisputedCount'] = +b['notDisputedCount'] + 1;
          b['newCount'] = +b['newCount'] - 1;
        } else if (element.latestStatus == 'won') {
          b['wonCount'] = +b['wonCount'] + 1;
          b['disputedCount'] = +b['disputedCount'] - 1;
        } else if (element.latestStatus == 'lost') {
          b['lostCount'] = +b['lostCount'] + 1;
          b['disputedCount'] = +b['disputedCount'] - 1;
        }
      });

      return {
        ...state,
        wdData: {
          ...state.wdData,
          data: a,
          newCount: b['newCount'] || 0,
          disputedCount: b['disputedCount'] || 0,
          lostCount: b['lostCount'] || 0,
          notDisputedCount: b['notDisputedCount'] || 0,
          wonCount: b['wonCount'] || 0,
          escalatedCount: b['escalatedCount'] || 0
        },
        wdLoading: false,
        wdLoad: false,
        wdError: ''
      };
    }

    case wdAction.weightDiscrypancySaveError: {
      return { ...state, wdLoading: false, wdLoad: false, wdError: action['payload']['message'] };
    }

    default: {
      return state;
    }
  }
}

export const getWdList = (state: wdState) => state.wdData;
export const getLoaderstart = (state: wdState) => state.wdLoading;
export const getLoaderend = (state: wdState) => state.wdLoad;
export const getError = (state: wdState) => state.wdError;
export const getstate = (state: wdState) => state;
