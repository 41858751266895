<div class="dialog-header">
  <h2 mat-dialog-title>Save as template</h2>
  <button mat-dialog-close>
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </button>
</div>
<mat-dialog-content>
  <div class="dialog-body">
    <form [formGroup]="saveTemplateForm" (ngSubmit)="saveTemplate()">
      <div class="pad-section border-none">
        <div class="form-field">
          <label> Report name</label>
          <input placeholder="Enter report name" formControlName="name" type="text" />
        </div>
        <div class="form-field">
          <label> Description </label>
          <input placeholder="Enter description" formControlName="description" type="text" />
        </div>
      </div>
      <div class="btn-wrp pad-section border-none pad-t-n">
        <div class="align-left"></div>
        <div class="btn-align right" style="position: relative;">
          <div class="tool_hover">
            <button
              type="submit"
              [class.primary-btn-loader]="loader"
              [disabled]="!this.saveTemplateForm.valid"
              class="btn-export"
            >
              Save
            </button>
            <span *ngIf="!this.saveTemplateForm.valid" class="tool_tip"
              >Please fill both fields first to save the template</span
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
