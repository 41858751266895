<app-web-alert></app-web-alert>
<div class="new-version" *ngIf="showPopUp">
  <img class="refresh-ic" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/reload-web-ic.svg" />
  <div class="text-wrp">
    <h6>Updates Available</h6>
    <p>A new version of Eshopbox workspace is available now. Refresh to get the latest version.</p>
    <button class="btn" (click)="refresh()">Refresh now</button>
    <button class="under-line" (click)="remindLater()">Remind me later</button>
  </div>
  <img
    class="crs-ic"
    (click)="remindLater()"
    src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg"
  />
</div>
<div *ngIf="loading">
  <app-loading [loading]="loading"></app-loading>
</div>
<router-outlet></router-outlet>
