import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService, AuthService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeightDiscrypancyService {
  private accountSlug: string = '';
  private AuthorizationToken: string = '';
  private ApiUrl: string = '';
  private BASE_DOMAIN: string = environment.BASE_DOMAIN;

  private Acitivity_URL = environment.ACTIVITY_API_URL;
  constructor(private http: HttpClient, private authService: AuthService, private alertservice: AlertService) {
    this.accountSlug = this.authService.getAccountSlug();
    this.AuthorizationToken = this.authService.getAuthToken();
    this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;
    this.ApiUrl = 'https://' + this.accountSlug + '' + this.BASE_DOMAIN + '/shipping/api/v1/';
    // this.ApiUrl = 'https://' + this.accountSlug + '.' + this.ApiUrl;
  }

  getWeightDiscripancy(obj) {
    return this.http.get(this.ApiUrl + 'discrepancy', { params: obj });
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  downloadImage(url: string) {
    FileSaver.saveAs(url, this.makeid(10));
    // this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
    //     // Create a link element
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     const pieces = url.split(/[\s,]+/)
    //     const last = pieces[pieces.length - 1]
    //     link.download = last; // Set the download filename

    //     // Append link to the body, click it, and then remove it
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }, error => {
    //     console.error('Error downloading the image: ', error);
    // });
  }

  createImport(queryParams) {
    const apiUrl = environment.PLATFORM_API_URL + '/import-job';
    return this.http.post(apiUrl, queryParams);
  }

  putApi(obj) {
    return this.http.put(this.ApiUrl + 'discrepancy', obj);
  }

  alert(type, msg) {
    if (type == 'success') {
      this.alertservice.showSuccess(msg, 5000);
    } else {
      this.alertservice.showError(msg, 5000);
    }
  }

  createExportJob(requestBody) {
    const apiUrl = environment.PLATFORM_API_URL + '/export-job';
    return this.http.post(apiUrl, requestBody, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  activity(requestParams): Observable<any[]> {
    let param = { target: 'weight_discrepancy', targetId: requestParams };
    let activityData = this.http.get(this.Acitivity_URL, { params: param });
    return forkJoin([activityData]);
  }
}
