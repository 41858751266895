import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductList } from '../../../models/product-list.model';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss']
})
export class TableDataComponent implements OnInit {
  editPopOpen: boolean;
  deletePopOpen: boolean;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  PRODUCT_DATA: ProductList[] = [
    {
      checkbox: true,
      ID: 345637398,
      name: 'John Doe',
      description: 'This is a sample description. Use...',
      amount: 1000,
      color: 'Red, Blue, Green',
      file: 3,
      createdAt: '4 Months Ago',
      updatedAt: 'today',
      action: true
    },
    {
      checkbox: true,
      ID: 787675765,
      name: 'Clack',
      description: 'This is a sample description. Use...',
      amount: 1000,
      color: 'Blue, Red, Green',
      file: 5,
      createdAt: '5 Months Ago',
      updatedAt: 'today',
      action: true
    },
    {
      checkbox: true,
      ID: 234234244,
      name: 'Smith',
      description: 'This is a sample description. Use...',
      amount: 1000,
      color: 'Red, White, Green',
      file: 7,
      createdAt: '1 Months Ago',
      updatedAt: 'today',
      action: true
    },
    {
      checkbox: true,
      ID: 987879897,
      name: 'Carry',
      description: 'This is a sample description. Use...',
      amount: 1000,
      color: 'Red, Blue, Green',
      file: 2,
      createdAt: '10 Months Ago',
      updatedAt: 'today',
      action: true
    },
    {
      checkbox: true,
      ID: 978899973,
      name: 'Joy',
      description: 'This is a sample description. Use...',
      amount: 1000,
      color: 'Red, Orange, Green',
      file: 4,
      createdAt: '2 Months Ago',
      updatedAt: 'today',
      action: true
    },
    {
      checkbox: true,
      ID: 786867878,
      name: 'Elex',
      description: 'This is a sample description. Use...',
      amount: 1000,
      color: 'Red, Sky, Green',
      file: 1,
      createdAt: '7 Months Ago',
      updatedAt: 'today',
      action: true
    }
  ];

  displayedColumns = [
    'checkbox',
    'ID',
    'name',
    'description',
    'amount',
    'color',
    'file',
    'createdAt',
    'updatedAt',
    'action'
  ];
  dataSource = new MatTableDataSource(this.PRODUCT_DATA);

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openDialogEdit() {
    this.dialog.open(EditDialogComponent, {
      panelClass: 'edit-dialog'
    });
  }
  openDialogDelete() {
    this.dialog.open(DeleteDialogComponent, {
      panelClass: 'delete-dialog'
    });
  }
}
