<div class="innerpopup">
  <a class="close" (click)="onNoClick()"
    ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
  /></a>
  <h2>Are you sure?</h2>
  <p>
    You are about to delete 50 records. This can’t be <br />
    undone.
  </p>
  <div class="btn-block">
    <a class="cancle-btn"> Continue editing </a>
    <a class="delete-btn"> Discard </a>
  </div>
</div>
