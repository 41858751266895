import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class BuildingblockService {
  constructor(private http: HttpClient, private authService: AuthService, private snackbar: MatSnackBar) {
    // Get access token
    this.AuthorizationToken = this.authService.getAuthToken();
    this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;
  }

  private AuthorizationToken: string;
  buildingBlockUrl = 'buildingBlock';
  groupbuildingBlockUrl = 'group';
  BB_API_URL = environment.BUILDINGBLOCK_API_URL;
  BB_LIST = this.BB_API_URL + this.buildingBlockUrl + '?isCore=|IS|false';
  buildingBlocks = this.BB_API_URL + this.buildingBlockUrl;
  buildingBlock = this.BB_API_URL + this.buildingBlockUrl;

  groupbuildingBlock = this.BB_API_URL + this.groupbuildingBlockUrl;
  corebuildingBlock = this.BB_API_URL + this.buildingBlockUrl + '?isCore=|IS|true';
  public getUpdateBBDetails = new Subject();
  // private callSelectedFC = new Subject<any>();
  private callSelectedFC = new Subject<void>();
  private locationDataSubject = new BehaviorSubject<{ hasLocation: boolean; hasLocationAccess: boolean }>({
    hasLocation: false,
    hasLocationAccess: false
  });
  locationData$ = this.locationDataSubject.asObservable();

  setUpdatedBBDetails(data: any) {
    this.getUpdateBBDetails.next(data);
  }

  getBuildingBlock(handlebar): Observable<any> {
    return this.http.get(this.buildingBlock + '/' + handlebar, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getBuildingBlocks(): Observable<any> {
    return this.http.get(this.BB_LIST, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getAllBuildingBlocks(): Observable<any> {
    return this.http.get(this.buildingBlocks, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getAllBuildingBlocksAsync(): Promise<any> {
    return this.http
      .get(this.buildingBlocks, {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
      })
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getCustomGroupedBulidingBlocks(): Observable<any> {
    return this.http.get(this.groupbuildingBlock, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getCoreBuildingBlockDeatils(): Observable<any> {
    return this.http.get(this.corebuildingBlock, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getCustomGroupedBulidingBlocksAsync(): Promise<any> {
    return this.http
      .get(this.groupbuildingBlock, {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
      })
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getCoreBuildingBlockDeatilsAsync(): Promise<any> {
    return this.http
      .get(this.corebuildingBlock, {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
      })
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getRecords(handleBar, filters, filterOperator?): Observable<any> {
    if (filters.includes('expectedFulfillmentBy')) filters = filters + '&sortOrder=ASC&sortBy=expectedFulfillmentBy';
    let RECORD_LIST;
    if (filters === undefined) {
      RECORD_LIST = this.BB_API_URL + handleBar + '/record';
    } else {
      RECORD_LIST = this.BB_API_URL + handleBar + '/record?' + filters;
      // RECORD_LIST = this.BB_API_URL + handleBar + '/record?' + encodeURI(filters);
    }
    RECORD_LIST = RECORD_LIST.replace(/#/, '%23');
    if (!filterOperator) {
      filterOperator = 'AND';
    }
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.AuthorizationToken)
      .set('filterOperator', filterOperator);

    return this.http.get(RECORD_LIST, {
      headers: header
    });
  }

  getRecordsForSearchQuery(handleBar, search, filterOperator) {
    let RECORD_LIST;
    if (search !== undefined) {
      RECORD_LIST = this.BB_API_URL + handleBar + '/record' + '?' + search;
    }

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.AuthorizationToken)
      .set('filterOperator', filterOperator);

    return this.http.get(RECORD_LIST, {
      headers: header
    });
  }

  // getRecords(params): Observable<any> {
  //   let RECORD_LIST = this.BB_API_URL + params.handlebar + '/record';
  //   return this.http.get(RECORD_LIST, {
  //     headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
  //   });
  // }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCoreRecordsWithID(bbhandleBar, recordID) {
    if (recordID && recordID.match('/')) {
      recordID = encodeURIComponent(recordID);
    }
    let RECORD_LIST = this.BB_API_URL + bbhandleBar + '/record/' + encodeURIComponent(recordID);
    return this.http
      .get(RECORD_LIST, {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
      })
      .pipe(
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getRecordsWithID(bbhandleBar, recordID) {
    if (recordID && recordID.match('/')) {
      recordID = encodeURIComponent(recordID);
    }
    let RECORD_LIST = this.BB_API_URL + bbhandleBar + '/record/' + encodeURIComponent(recordID);
    return this.http.get(RECORD_LIST, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getFieldList(params) {
    let FIELDS_API_URL = this.BB_API_URL + params + '/field';
    return this.http.get(FIELDS_API_URL, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getFieldWithID(bbhandleBar, fieldHandleBar) {
    let FIELDS_API_URL = this.BB_API_URL + bbhandleBar + '/field/' + fieldHandleBar;
    // let FIELDS_API_URL = this.BB_API_URL + params + '/field';
    return this.http.get(FIELDS_API_URL, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getActionList(params) {
    let ACTIONS_API_URL = this.BB_API_URL + params + '/action';
    return this.http.get(ACTIONS_API_URL, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getBuildingBlockActions(handlebar): Observable<any> {
    let FIELDS_API_URL = this.BB_API_URL + handlebar + '/action';
    let data = this.http.get(FIELDS_API_URL, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
    return data;
  }

  getActionsList(bbhandleBar): Observable<any> {
    let ACTIONS_API_URL = this.BB_API_URL + bbhandleBar + '/action';
    return this.http.get(ACTIONS_API_URL, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  getBuildingBlockAction(buildingBlockHandlebar, actionHandleBar): Observable<any> {
    let FIELDS_API_URL = this.BB_API_URL + buildingBlockHandlebar + '/action/' + actionHandleBar;
    // let data = this.http.get("https://montecarlo.auperator.co/buildingblock/api/v1/test/action", {
    let data = this.http.get(FIELDS_API_URL, {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
    return data;
  }

  getWorkflowSettings(): Observable<any> {
    let url = this.BB_API_URL + 'workflowSettings';
    return this.http.get(url);
  }

  setCallSelectedFC(url): void {
    this.callSelectedFC.next(url);
  }

  getCallSelectedFC(): Observable<void> {
    return this.callSelectedFC.asObservable();
  }

  setLocationData(hasLocation: boolean, hasLocationAccess: boolean): void {
    this.locationDataSubject.next({ hasLocation, hasLocationAccess });
  }
}
