<ul class="select-list" style="margin-bottom: 22px;">
  <h4>
    <p *ngIf="item.accountSlug" class="hover-disabled">You don't have permission to view the customer details.</p>
    <mat-checkbox
      (change)="toggleParentEvent($event, item)"
      (click)="$event.stopPropagation()"
      [checked]="selectAllOption[item.key]"
      [indeterminate]="someComplete(item)"
      [disabled]="item.accountSlug"
    >
      {{
        templateSelected.jobType == 'product' && (templateList?.externalWarehouseIDToFacilityLabel)[item.key]
          ? (templateList?.externalWarehouseIDToFacilityLabel)[item.key]
          : item?.key
      }}
      <div class="mat-tip" *ngIf="templateList?.parentCheckboxInfo">
        <ng-container *ngIf="(templateList?.parentCheckboxInfo)[item?.key]">
          <img
            tooltip="{{ (templateList?.parentCheckboxInfo)[item?.key] }}"
            placement="top"
            tooltipWidth="200"
            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/export-info-ic.svg"
            class="export-info-icon"
          />
        </ng-container>
      </div>
    </mat-checkbox>
  </h4>
  <li *ngFor="let f of item.value">
    <p *ngIf="item.accountSlug || f.accountSlug" class="hover-disabled">
      You don't have permission to view the customer details.
    </p>
    <mat-checkbox
      [checked]="f?.checked"
      (change)="toggleSelectionEvent($event, f, item)"
      (click)="$event.stopPropagation()"
      [disabled]="item.accountSlug || f.accountSlug"
      *ngIf="f?.checked !== false || f?.key !== 'Status'"
    >
      {{
        ['Custom', 'Channel Listing Id'].includes(item?.key) && templateSelected.jobType == 'product'
          ? (templateList?.internalNameToLabel)[f?.key]
          : f?.key
      }}
      <div class="mat-tip" *ngIf="f?.info">
        <img
          tooltip="{{ f?.info }}"
          placement="top"
          tooltipWidth="200"
          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/export-info-ic.svg"
          class="export-info-icon"
        />
      </div>
    </mat-checkbox>
  </li>
</ul>
