import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { Location } from '@angular/common';
import {
  BREADCRUMBS,
  ESB_SIGNUP_URLS
} from 'src/app/auth/components/new-signup-checkout/constants/new-signup.constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  currenturl;
  breadcrumbs = BREADCRUMBS;
  esbSignupUrls = ESB_SIGNUP_URLS;
  constructor(private router: Router, private location: Location) {}

  ngOnInit() {
    this.router.events
      .pipe(
        startWith(new NavigationEnd(0, '/', '/')),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        if (event && event.url !== '/') {
          this.currenturl = event.url;
        } else {
          this.currenturl = this.router.url;
        }
      });
  }
}
