<div class="error-504-pg">
  <div class="txt">
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-504.png" />
    <h2>Oops! Something went wrong.</h2>
    <p>
      The server encountered an error and couldn’t <br />
      complete your request. Please try again after <b> 60 sec... </b>
    </p>
    <p class="note-txt">
      <b> Note : </b> Still facing same issue, write to us at <a href="mailto: care@eshopbox.com"> care@eshopbox.com</a>
    </p>
  </div>
</div>
