import { Injectable } from '@angular/core';

export interface TooltipOptions {
  placement?: string;
  autoPlacement?: boolean;
  contentType?: 'string' | 'html' | 'template';
  delay?: number;
  showDelay?: number;
  hideDelay?: number;
  hideDelayMobile?: number;
  hideDelayTouchscreen?: number;
  zIndex?: number;
  animationDuration?: number;
  animationDurationDefault?: number;
  trigger?: string;
  tooltipClass?: string;
  display?: boolean;
  displayMobile?: boolean;
  displayTouchscreen?: boolean;
  shadow?: boolean;
  theme?: string;
  offset?: number;
  width?: number;
  maxWidth?: number;
  id?: string | number;
  hideDelayAfterClick?: number;
  pointerEvents?: 'auto' | 'none';
  position?: {
    top: number;
    left: number;
  };
}

export const defaultOptions: TooltipOptions = {
  placement: 'bottom',
  autoPlacement: true,
  contentType: 'html',
  showDelay: 1400,
  hideDelay: 300,
  hideDelayMobile: 0,
  hideDelayTouchscreen: 0,
  zIndex: 0,
  animationDuration: 300,
  animationDurationDefault: 300,
  trigger: 'hover',
  tooltipClass: '',
  display: true,
  displayMobile: true,
  displayTouchscreen: true,
  shadow: true,
  theme: 'dark',
  offset: 8,
  maxWidth: 300,
  id: 1,
  hideDelayAfterClick: 2000,
  pointerEvents: 'auto'
};

export const backwardCompatibilityOptions = {
  hideDelayAfterClick: 'hideDelayAfterClick'
  // Add any backward compatibility keys here
};

@Injectable({
  providedIn: 'root'
})
export class TooltipOptionsService {
  options: TooltipOptions;

  constructor() {
    this.options = defaultOptions;
  }
}
