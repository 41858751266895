<div class="delete-draft-box">
  <h1 mat-dialog-title>
    <span class="delete-header">Delete template</span>
    <span class="close-icon" (click)="close()"
      ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg"
    /></span>
  </h1>
  <div mat-dialog-content>
    <p>
      Are you sure you want to delete this template ?
    </p>
    <div mat-dialog-actions>
      <button mat-button class="cancel" (click)="close()">No</button>
      <button mat-button class="delete" [mat-dialog-close]="true">Yes</button>
    </div>
  </div>
</div>
