import * as settingsActions from '../actions/settings.actions';
import { SettingsState } from '../state/settings.state';

export const initialState: SettingsState = {
  addFeeRuleLoaded: false,
  addFeeRuleLoading: false,
  addFeeRulerDetails: [],
  addBulkFeeRuleLoaded: false,
  addBulkFeeRuleLoading: false,
  addBulkFeeRuleDetails: [],
  addFeeCorrectionLoaded: false,
  addFeeCorrectionLoading: false,
  addFeeCorrectionDetails: [],
  getBulkFeeRuleLoaded: false,
  getBulkFeeRuleLoading: false,
  getBulkFeeRuleDetails: [],
  getCloneFeeRuleLoaded: false,
  getCloneFeeRuleLoading: false,
  getCloneFeeRuleDetails: [],
  getFeeRuleLoaded: false,
  getFeeRuleLoading: false,
  getFeeRuleDetails: [],
  editFeeRuleLoaded: false,
  editFeeRuleLoading: false,
  editFeeRuleDetails: [],
  getFeeListLoaded: false,
  getFeeListLoading: false,
  getFeeListDetails: [],
  getFeeDetailsLoaded: false,
  getFeeDetailsLoading: false,
  getFeeDetails: [],
  getPropertyDetailsLoaded: false,
  getPropertyDetailsLoading: false,
  getPropertyDetails: [],
  teams: {
    teamMembersData: null,
    isTeamMembersLoading: false,
    isTeamMembersLoaded: false
  },
  getEInvoicingStatusDetails: null,
  getEInvoicingStatusDetailsLoaded: false,
  getEInvoicingStatusDetailsLoading: false,
  workspaceSettings: {
    getWorkspaceSettingsLoaded: false,
    getWorkspaceSettingsLoading: false,
    getWorkspaceSettings: null,
    getError: ''
  },
  bankDetails: {
    data: null,
    loading: false
  },
  businessDetails: {
    data: null,
    loading: false
  },
  bankDetailsList: {
    data: null,
    loading: false
  },
  codPaymentTermsDetails: {
    data: null,
    loading: false
  }
};

export function SettingsReducer(state = initialState, action: settingsActions.SettingsActionsUnion): SettingsState {
  switch (action.type) {
    case settingsActions.SettingsActionTypes.ADD_FEE_RULE: {
      return {
        ...state,
        addFeeRuleLoaded: false,
        addFeeRuleLoading: true,
        addFeeRulerDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_FEE_RULE_SUCCESS: {
      return {
        ...state,
        addFeeRuleLoaded: true,
        addFeeRuleLoading: false,
        addFeeRulerDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_FEE_RULE_FAILURE: {
      return {
        ...state,
        addFeeRuleLoaded: false,
        addFeeRuleLoading: false,
        addFeeRulerDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_BULK_FEE_RULE: {
      return {
        ...state,
        addBulkFeeRuleLoaded: false,
        addBulkFeeRuleLoading: true,
        addBulkFeeRuleDetails: action.payload
      };
    }
    case settingsActions.SettingsActionTypes.RESET_ADD_BULK_FEE_RULE: {
      return {
        ...state,
        addBulkFeeRuleLoaded: false,
        addBulkFeeRuleLoading: false,
        addBulkFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_BULK_FEE_RULE_SUCCESS: {
      return {
        ...state,
        addBulkFeeRuleLoaded: true,
        addBulkFeeRuleLoading: false,
        addBulkFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_BULK_FEE_RULE_FAILURE: {
      return {
        ...state,
        addBulkFeeRuleLoaded: false,
        addBulkFeeRuleLoading: false,
        addBulkFeeRuleDetails: action.payload
      };
    }
    case settingsActions.SettingsActionTypes.ADD_FEE_CORRECTION: {
      return {
        ...state,

        addFeeCorrectionLoaded: false,
        addFeeCorrectionLoading: true,
        addFeeCorrectionDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_FEE_CORRECTION_SUCCESS: {
      return {
        ...state,

        addFeeCorrectionLoaded: true,
        addFeeCorrectionLoading: false,
        addFeeCorrectionDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.RESET_ADD_BULK_FEE_RULE_SUCCESS: {
      return {
        ...state,

        addFeeCorrectionLoaded: true,
        addFeeCorrectionLoading: false,
        addFeeCorrectionDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.ADD_FEE_CORRECTION_FAILURE: {
      return {
        ...state,

        addFeeCorrectionLoaded: false,
        addFeeCorrectionLoading: false,
        addFeeCorrectionDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_BULK_FEE_RULE: {
      return {
        ...state,
        getBulkFeeRuleLoaded: false,
        getBulkFeeRuleLoading: true,
        getBulkFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_BULK_FEE_RULE_SUCCESS: {
      return {
        ...state,
        getBulkFeeRuleLoaded: true,
        getBulkFeeRuleLoading: false,
        getBulkFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_BULK_FEE_RULE_FAILURE: {
      return {
        ...state,
        getBulkFeeRuleLoaded: false,
        getBulkFeeRuleLoading: false,
        getBulkFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.CLONE_FEE_RULE: {
      return {
        ...state,
        getCloneFeeRuleLoaded: false,
        getCloneFeeRuleLoading: true,
        getCloneFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.CLONE_FEE_RULE_SUCCESS: {
      return {
        ...state,
        getCloneFeeRuleLoaded: true,
        getCloneFeeRuleLoading: false,
        getCloneFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.CLONE_FEE_RULE_FAILURE: {
      return {
        ...state,
        getCloneFeeRuleLoaded: false,
        getCloneFeeRuleLoading: false,
        getCloneFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_RULE: {
      return {
        ...state,
        getFeeRuleLoaded: false,
        getFeeRuleLoading: true,
        getFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_RULE_SUCCESS: {
      return {
        ...state,
        getFeeRuleLoaded: true,
        getFeeRuleLoading: false,
        getFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_RULE_FAILURE: {
      return {
        ...state,
        getFeeRuleLoaded: false,
        getFeeRuleLoading: false,
        getFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.EDIT_FEE_RULE: {
      return {
        ...state,
        editFeeRuleLoaded: false,
        editFeeRuleLoading: true,
        editFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.EDIT_FEE_RULE_SUCCESS: {
      return {
        ...state,
        editFeeRuleLoaded: true,
        editFeeRuleLoading: false,
        editFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.EDIT_FEE_RULE_FAILURE: {
      return {
        ...state,
        editFeeRuleLoaded: false,
        editFeeRuleLoading: false,
        editFeeRuleDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_LIST: {
      return {
        ...state,
        getFeeListLoaded: false,
        getFeeListLoading: true,
        getFeeListDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_LIST_SUCCESS: {
      return {
        ...state,
        getFeeListLoaded: true,
        getFeeListLoading: false,
        getFeeListDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_LIST_FAILURE: {
      return {
        ...state,
        getFeeListLoaded: false,
        getFeeListLoading: false,
        getFeeListDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_DETAILS: {
      return {
        ...state,
        getFeeDetailsLoaded: false,
        getFeeDetailsLoading: true,
        getFeeDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_DETAILS_SUCCESS: {
      return {
        ...state,
        getFeeDetailsLoaded: true,
        getFeeDetailsLoading: false,
        getFeeDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_FEE_DETAILS_FAILURE: {
      return {
        ...state,
        getFeeDetailsLoaded: false,
        getFeeDetailsLoading: false,
        getFeeDetails: action.payload
      };
    }
    case settingsActions.SettingsActionTypes.GET_PROPERTY_DETAILS: {
      return {
        ...state,
        getPropertyDetailsLoaded: false,
        getPropertyDetailsLoading: true,
        getPropertyDetails: action.payload
      };
    }
    case settingsActions.SettingsActionTypes.GET_PROPERTY_DETAILS_SUCCESS: {
      return {
        ...state,
        getPropertyDetailsLoaded: true,
        getPropertyDetailsLoading: false,
        getPropertyDetails: action.payload
      };
    }
    case settingsActions.SettingsActionTypes.GET_PROPERTY_DETAILS_FAILURE: {
      return {
        ...state,
        getPropertyDetailsLoaded: false,
        getPropertyDetailsLoading: false,
        getPropertyDetails: action.payload
      };
    }

    case settingsActions.SettingsActionTypes.GET_TEAM_MEMBERS_DATA: {
      return {
        ...state,
        teams: {
          teamMembersData: null,
          isTeamMembersLoading: true,
          isTeamMembersLoaded: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_TEAM_MEMBERS_DATA_SUCCESS: {
      return {
        ...state,
        teams: {
          teamMembersData: action.payload,
          isTeamMembersLoading: false,
          isTeamMembersLoaded: true
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_TEAM_MEMBERS_DATA_FAILURE: {
      return {
        ...state,
        teams: {
          teamMembersData: action.payload,
          isTeamMembersLoading: false,
          isTeamMembersLoaded: true
        }
      };
    }

    case settingsActions.SettingsActionTypes.GET_EINVOICE_STATUS: {
      return {
        ...state,
        getEInvoicingStatusDetails: null,
        getEInvoicingStatusDetailsLoaded: true,
        getEInvoicingStatusDetailsLoading: false
      };
    }
    case settingsActions.SettingsActionTypes.GET_EINVOICE_STATUS_SUCCESS: {
      return {
        ...state,
        getEInvoicingStatusDetails: action.payload,
        getEInvoicingStatusDetailsLoaded: false,
        getEInvoicingStatusDetailsLoading: true
      };
    }
    case settingsActions.SettingsActionTypes.GET_EINVOICE_STATUS_FAILURE: {
      return {
        ...state,
        getEInvoicingStatusDetails: action.payload,
        getEInvoicingStatusDetailsLoaded: false,
        getEInvoicingStatusDetailsLoading: true
      };
    }

    case settingsActions.SettingsActionTypes.GET_BANK_DETAILS_LIST: {
      return {
        ...state,
        bankDetailsList: {
          data: null,
          loading: true
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BANK_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        bankDetailsList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BANK_DETAILS_LIST_FAILURE: {
      return {
        ...state,
        bankDetailsList: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BANK_DETAILS: {
      return {
        ...state,
        bankDetails: {
          data: null,
          loading: true
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BANK_DETAILS_SUCCESS: {
      return {
        ...state,
        bankDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BANK_DETAILS_FAILURE: {
      return {
        ...state,
        bankDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA: {
      return {
        ...state,
        workspaceSettings: {
          getWorkspaceSettingsLoading: true,
          getWorkspaceSettingsLoaded: false,
          getWorkspaceSettings: null,
          getError: ''
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA_SUCCESS: {
      return {
        ...state,
        workspaceSettings: {
          getWorkspaceSettingsLoading: false,
          getWorkspaceSettingsLoaded: true,
          getWorkspaceSettings: action.payload,
          getError: ''
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA_FAILURE: {
      let s = JSON.parse(JSON.stringify(state.workspaceSettings));
      s.getWorkspaceSettingsLoading = false;
      s.getWorkspaceSettingsLoaded = true;
      s.getError = 'error';
      return {
        ...state,
        workspaceSettings: s
      };
    }
    case settingsActions.SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA: {
      let s = JSON.parse(JSON.stringify(state.workspaceSettings));
      s.getWorkspaceSettingsLoading = true;
      s.getWorkspaceSettingsLoaded = false;
      s.getError = '';
      return {
        ...state,
        workspaceSettings: s
      };
    }
    case settingsActions.SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA_SUCCESS: {
      return {
        ...state,
        workspaceSettings: {
          getWorkspaceSettingsLoading: false,
          getWorkspaceSettingsLoaded: true,
          getWorkspaceSettings: action.payload,
          getError: ''
        }
      };
    }
    case settingsActions.SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA_FAILURE: {
      let s = JSON.parse(JSON.stringify(state.workspaceSettings));
      s.getWorkspaceSettingsLoading = false;
      s.getWorkspaceSettingsLoaded = true;
      s.getError = 'error';
      return {
        ...state,
        workspaceSettings: s
      };
    }
    case settingsActions.SettingsActionTypes.GET_BUSINESS_DETAILS: {
      return {
        ...state,
        businessDetails: {
          data: null,
          loading: true
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BUSINESS_DETAILS_SUCCESS: {
      return {
        ...state,
        businessDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_BUSINESS_DETAILS_FAILURE: {
      return {
        ...state,
        businessDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS: {
      return {
        ...state,
        codPaymentTermsDetails: {
          data: null,
          loading: true
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS_SUCCESS: {
      return {
        ...state,
        codPaymentTermsDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case settingsActions.SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS_FAILURE: {
      return {
        ...state,
        codPaymentTermsDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const getAddFeeRuleLoaded = (state: SettingsState) => state.addFeeRuleLoaded;
export const getAddFeeRuleLoading = (state: SettingsState) => state.addFeeRuleLoading;
export const getAddFeeRuleDetails = (state: SettingsState) => state.addFeeRulerDetails;
export const getFeeCorrectionLoaded = (state: SettingsState) => state.addFeeCorrectionLoaded;
export const getFeeCorrectionLoading = (state: SettingsState) => state.addFeeCorrectionLoading;
export const getFeeCorrectionDetails = (state: SettingsState) => state.addFeeCorrectionDetails;
export const getAddBulkFeeRuleLoaded = (state: SettingsState) => state.addBulkFeeRuleLoaded;
export const getAddBulkFeeRuleLoading = (state: SettingsState) => state.addBulkFeeRuleLoading;
export const getAddBulkFeeRuleDetails = (state: SettingsState) => state.addBulkFeeRuleDetails;
export const getBulkFeeRuleLoaded = (state: SettingsState) => state.getBulkFeeRuleLoaded;
export const getBulkFeeRuleLoading = (state: SettingsState) => state.getBulkFeeRuleLoading;
export const getBulkFeeRuleDetails = (state: SettingsState) => state.getBulkFeeRuleDetails;
export const getCloneFeeRuleLoaded = (state: SettingsState) => state.getCloneFeeRuleLoaded;
export const getCloneFeeRuleLoading = (state: SettingsState) => state.getCloneFeeRuleLoading;
export const getCloneFeeRuleDetails = (state: SettingsState) => state.getCloneFeeRuleDetails;
export const getFeeRuleLoaded = (state: SettingsState) => state.getFeeRuleLoaded;
export const getFeeRuleLoading = (state: SettingsState) => state.getFeeRuleLoading;
export const getFeeRuleDetails = (state: SettingsState) => state.getFeeRuleDetails;
export const getEditFeeRuleLoaded = (state: SettingsState) => state.editFeeRuleLoaded;
export const getEditFeeRuleLoading = (state: SettingsState) => state.editFeeRuleLoading;
export const getEditFeeRuleDetails = (state: SettingsState) => state.editFeeRuleDetails;
export const getFeeListLoaded = (state: SettingsState) => state.getFeeListLoaded;
export const getFeeListLoading = (state: SettingsState) => state.getFeeListLoading;
export const getFeeListDetails = (state: SettingsState) => state.getFeeListDetails;
export const getFeeDetailsLoaded = (state: SettingsState) => state.getFeeDetailsLoaded;
export const getFeeDetailsLoading = (state: SettingsState) => state.getFeeDetailsLoading;
export const getFeeDetails = (state: SettingsState) => state.getFeeDetails;
export const getPropertyDetailsLoaded = (state: SettingsState) => state.getPropertyDetailsLoaded;
export const getPropertyDetailsLoading = (state: SettingsState) => state.getPropertyDetailsLoading;
export const getPropertyDetails = (state: SettingsState) => state.getPropertyDetails;

export const getTeamMembersData = (state: SettingsState) => state.teams;

export const getEInvoicingStatusDetails = (state: SettingsState) => state.getEInvoicingStatusDetails;
export const getEInvoicingStatusDetailsLoaded = (state: SettingsState) => state.getEInvoicingStatusDetailsLoaded;
export const getEInvoicingStatusDetailsLoading = (state: SettingsState) => state.getEInvoicingStatusDetailsLoading;
export const getBankDetailsList = (state: SettingsState) => state.bankDetailsList;
export const getBankDetails = (state: SettingsState) => state.bankDetails;
export const getWorkspaceSettings = (state: SettingsState) => state.workspaceSettings;
export const getBusinessDetails = (state: SettingsState) => state.businessDetails;
export const getCodPaymentTermDetails = (state: SettingsState) => state.codPaymentTermsDetails;
