<div class="sorting">
  <div class="">
    <a
      ><span>Sort by<img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-sort.png"/></span>
      <ul class="sortinglist">
        <li *ngFor="let sortType of sortByList" (click)="onSortSelection(sortType.value)">
          <a>{{ sortType.label }}</a>
        </li>
      </ul>
    </a>
  </div>
</div>
