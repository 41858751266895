import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { switchMap, take, filter, map } from 'rxjs/operators';
import { getCurrentLoggedInUserData } from '../auth/reducers/auth.selectors';
import { GetCurrentLoggedInUserData } from '../auth/actions/auth.actions';
import { AuthService } from '../shared/services';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceDataResolver implements Resolve<any> {
  constructor(private store: Store<any>, private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store.pipe(
      select(getCurrentLoggedInUserData),
      take(1),
      switchMap(loggedInUserData => {
        if (loggedInUserData?.currentUser) {
          return of(loggedInUserData.currentUser);
        } else if (
          this.authService.getAuthToken() &&
          !loggedInUserData.currentUser &&
          !loggedInUserData.loading &&
          !loggedInUserData.loaded
        ) {
          this.store.dispatch(new GetCurrentLoggedInUserData());
          return this.store.pipe(
            select(getCurrentLoggedInUserData),
            filter(userData => !!userData?.currentUser),
            take(1),
            switchMap(userData => of(userData.currentUser))
          );
        }
      })
    );
  }
}
