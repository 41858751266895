import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { BuildingBlocksService } from 'src/app/building-blocks/service/building-blocks.service';
import { SharedService } from 'src/app/shared/services';
import { BuildingblockService } from 'src/app/shared/services/buildingblock.service';
import * as mime from 'mime';
import { isObject, isString } from 'src/app/records/utils/common-util';
import { getPutawayCompletedItems } from 'src/app/shared/pipes/get-putaway-completed-items.pipe';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-multi-value-widget',
  templateUrl: './multi-value-widget.component.html',
  styleUrls: ['./multi-value-widget.component.scss']
})
export class MultiValueWidgetComponent {
  fileName: string = '';
  isImage: boolean = false;
  isPdf: boolean = false;
  isExcel: boolean = false;
  isZip: boolean = false;
  isDoc: boolean = false;
  isFile: boolean = false;
  isSingleSelect: boolean = false;
  isMultiAttachImage: boolean = false;

  displayTitle: string = '';
  displayFields: Array<string> = [];

  referenceRecordKeys: Array<string> = [];

  isMultiSelectwithImage: boolean = false;
  isSingleSelectwithImage: boolean = false;

  fieldKeyandNameArray: Array<any> = [];
  keyAndNameArray: any = undefined;

  fieldAndFieldTypePairArray = {};

  aaaa: boolean = false;
  displayTitleAA: string = '->';

  displayTitleOfNested: string = '';

  multiValueFieldsArray: Array<string> = [];
  multiSelectFieldsArray: Array<string> = [];
  bbHandleBar: string = '';
  displayTitleAndFieldForNestedReference: any = {};
  singleSelectWithImage: any;
  fieldList: Array<any> = [];

  fileDataArray: Array<any> = [];
  fileDataObject = {};
  recordServiceCounter: number = 0;
  displayTitleWithDot: Array<any> = [];
  isLoading: boolean = false;
  isObject = isObject;
  putawayCompletedIts: any;
  isLoadingItems: boolean = false;

  // Mat Dialog box is used.
  constructor(
    public dialogRef: MatDialogRef<MultiValueWidgetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private buildingBlockService: BuildingblockService,
    private fileStackService: SharedService,
    private bbLocalStorageService: BuildingBlocksService,
    private getPutawayCompletedItems: getPutawayCompletedItems,
    private http: HttpClient,
    private sharedService: SharedService
  ) {
    this.bbHandleBar = data.data['buildingBlock'];
    this.displayTitleAndFieldForNestedReference = data.displayTitleAndFieldForNestedReference;

    if (typeof data.data === 'string') {
      this.isSingleSelect = true;
    }

    if (data.fieldType === 'ATTACHMENT' && !data.multiValue) {
      this.getFileMetaData(data.data, false);
    } else if (data.fieldType === 'ATTACHMENT' && data.multiValue) {
      this.getFileMetaData(data.data, true);
    } else if (data.fieldType === 'REFERENCE') {
      if (!data.multiValue) {
        this.referenceRecordKeys = Object.keys(data.data['record']);

        let dTTT = ({} = data['displayTitle']);
        this.displayTitle = data.displayTitle.displayTitle;
        this.displayFields = dTTT['displayFields'];
        this.getFieldServiceFunct(data.data['buildingBlock']);
      } else {
        if (this.data.data.length > 0) {
          let fieldAndFieldType = this.getFieldServiceFunct(data.data[0]['buildingBlock']);
        }
      }
    }

    if (
      data.fieldType === 'REFERENCE' &&
      data.displayTitle !== undefined &&
      (data.displayTitle.displayTitle !== undefined || data.displayTitle !== null)
    ) {
      this.displayTitle = data.displayTitle.displayTitle;
      // this.displayFields = data.isCore ? data.displayTitle.displayTitle : data.displayTitle.displayFields;
      this.displayFields = data.displayTitle.displayFields;
    }

    if (data.fieldType === 'MULTI_SELECT') {
      let obj = data.data[0];
      if (typeof obj === 'object') {
        this.isMultiSelectwithImage = true;
      }
    }

    if (data.fieldType === 'SINGLE_SELECT') {
      let obj = data.data;
      if (typeof obj === 'object') {
        this.isSingleSelectwithImage = true;
      }
    }

    if (data.isNested) {
      this.getFieldServiceFunct(data.data['buildingBlock']);
    }
    this.getDisplayTitleWithDot(data);
  }

  onCancel() {
    this.dialogRef.close(true);
  }

  getFileMetaData(data, isMultiValue) {
    if (!isMultiValue) {
      if (data.includes('filestackcontent')) {
        this.fileStackService.getFileMetaData(data.split('/').pop()).then(res => {
          this.fileName = res.filename;
          this.fileDataObject['fileName'] = res.fileName;
          this.fileDataObject['fileData'] = data;
          this.fileDataObject['fileType'] = res.mimetype;
          if (res.mimetype.includes('image')) {
            this.isImage = true;
          }
        });
      } else {
        // let mimetype;
        // let response = data.split('/').pop();
        // this.fileName = response;
        // const imageExt = response.includes('?')
        //   ? response
        //       .split('.')
        //       .pop()
        //       .split('?')[0]
        //   : response.split('.').pop();
        // mimetype = mime.getType(imageExt);
        let metaData: any = this.sharedService.getFileMetaDataCustom(data);
        this.fileName = metaData?.fileName;
        this.fileDataObject['fileName'] = metaData.fileName;
        this.fileDataObject['fileData'] = metaData.fileData;
        this.fileDataObject['fileType'] = metaData.fileType;
        if (metaData.fileType.includes('image')) {
          this.isImage = true;
        }
      }
    } else {
      data.forEach(ele => {
        var obj = {};
        if (ele.includes('filestackcontent') && !ele.includes('imagekit')) {
          this.fileStackService.getFileMetaData(ele.split('/').pop()).then(res => {
            obj['data'] = ele;
            obj['fileName'] = res.filename;
            obj['fileType'] = res.mimetype;
            this.fileDataArray.push(obj);
          });
        } else if (ele.includes('filestackcontent') && ele.includes('imagekit')) {
          ele = ele.split('https://ik.imagekit.io/eshopbox/workspace/')[1].split('?')[0];
          this.fileStackService.getFileMetaData(ele.split('/').pop()).then(res => {
            obj['data'] = ele;
            obj['fileName'] = res.filename;
            obj['fileType'] = res.mimetype;
            this.fileDataArray.push(obj);
          });
        } else {
          let metaData: any = this.sharedService.getFileMetaDataCustom(ele);
          obj['data'] = metaData.fileData;
          obj['fileName'] = metaData.fileName;
          obj['fileType'] = metaData.fileType;

          // obj['data'] = ele;
          // obj['fileName'] = ele.split('imagebucketeshopbox/')[1];
          // obj['fileType'] = 'image';
          this.fileDataArray.push(obj);
        }
      });
    }
  }

  ifAttachmentIsPDF(fileType: string) {
    return fileType.includes('pdf');
  }

  ifAttachmentIsImage(fileType) {
    return fileType.includes('image');
  }

  getFormattedDate(fieldKey, item) {
    if (fieldKey !== undefined) {
      if (fieldKey.includes(' at') || (this.data.isLookUp && this.data.isLookUp.includes(' at'))) {
        return moment(item).format('DD-MMM-YYYY hh:mm A');
      }
    }
    return moment(item).format('DD-MMM-YYYY');
  }

  downloadFile(data: any, fileName: string, fileType: string) {
    this.http.get(data, { responseType: 'blob' }).subscribe((response: Blob) => {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(response);
      FileSaver.saveAs(imageUrl, fileName);
    });

    // this.fileStackService.downloadFile(data).then((blob: any) => {
    //   const urlCreator = window.URL || window.webkitURL;
    //   const imageUrl = urlCreator.createObjectURL(blob);
    //   FileSaver.saveAs(imageUrl, fileName);
    // });
  }

  downloadMultipleFiles() {
    this.fileDataArray.forEach(file => {
      this.downloadFile(file.data, file.fileName, file.fileType);
      // const blob = new Blob([file.data], { type: file.fileType });
      // FileSaver.saveAs(blob, file.fileName);
    });
  }

  openNestedMultiWidget(data: any, fieldHandleBar: any, displayTitleObject) {
    this.isLoading = true;
    let aa = {};
    let det = {};
    let refSlug;
    let fieldNameForReferedDisplayTitle = {};

    let allFieldsBB = this.bbLocalStorageService.getAllFieldsOfBuildingBlock(data['buildingBlock']);
    allFieldsBB.forEach(d => {
      aa[d['handleBar']] = d['name'];
      if (d['fieldType'] === 'REFERENCE') {
        det[d['handleBar']] = {
          displayTitle: d['validations']['displayTitle'],
          displayFields: d['validations']['displayFields'],
          referenceSlug: d['validations']['referenceSlug']
        };

        let fieldDisplayTitle;
        if (!d['validations']['displayTitle'].includes('.')) {
          fieldDisplayTitle = this.bbLocalStorageService.getFieldWithID(
            d['validations']['displayTitle'],
            d['validations']['referenceSlug']
          );
        } else {
          let splitDisplayTitle = d['validations']['displayTitle'].split('.');
          let refFieldReferenceSlug = this.bbLocalStorageService.getFieldWithID(
            splitDisplayTitle[0],
            d['validations']['referenceSlug']
          )['validations']['referenceSlug'];
          fieldDisplayTitle = this.bbLocalStorageService.getFieldWithID(splitDisplayTitle[1], refFieldReferenceSlug);
        }

        fieldNameForReferedDisplayTitle[fieldDisplayTitle['handleBar']] = fieldDisplayTitle['name'];
      }

      if (d['fieldType'] === 'REFERENCE' && !d['hasMultipleValues']) {
        if (data['record'][d['handleBar']] && typeof data['record'][d['handleBar']] === 'string') {
          this.buildingBlockService
            .getRecordsWithID(d['validations']['referenceSlug'], data['record'][d['handleBar']])
            .subscribe(r => {
              data['record'][d['handleBar']] = r;
            });
        }
      }
    });

    let dT;

    if (displayTitleObject) {
      dT = displayTitleObject;
    } else {
      dT = {
        displayTitle: this.displayTitle,
        displayFields: this.displayFields
      };
    }

    let dialogRef = this.dialog.open(MultiValueWidgetComponent, {
      panelClass: 'delete-item-dialog',
      data: {
        data: data,
        columnName: '',
        multiSelect: false,
        multiValue: this.multiValueFieldsArray.includes(fieldHandleBar),
        columnNameHandleBar: fieldHandleBar,
        fieldType: 'REFERENCE',
        displayTitle: dT,
        fieldKeyValuePairArray: aa,
        isNested: true,
        displayTitleAndFieldForNestedReference: det,
        fieldNameForReferedDisplayTitle: fieldNameForReferedDisplayTitle
      },
      width: '420px'
    });
    this.isLoading = false;
  }

  getLevel2Record(bbhandleBar, fieldKey: string, recordID: string) {
    this.isLoading = true;
    let refSlug;
    let field = this.bbLocalStorageService.getFieldWithID(fieldKey, bbhandleBar);

    if (field['validations']['displayFields'].includes('.')) {
      var column = field['validations']['displayFields'].split('.');
      for (let j = 0; j < column.length; j++) {
        if (column[j]['fieldType'] == 'REFERENCE') {
          refSlug = column[j]['validations']['referenceSlug'];
        } else field['validations']['displayFields'] = column[j];
      }
    }
    if (field['validations']['displayTitle'].includes('.')) {
      var column = field['validations']['displayTitle'].split('.');
      for (let j = 0; j < column.length; j++) {
        if (column[j]['fieldType'] == 'REFERENCE') {
          refSlug = column[j]['validations']['referenceSlug'];
        } else field['validations']['displayTitle'] = column[j];
      }
    }
    refSlug = field['validations']['referenceSlug'];
    this.displayTitleOfNested = field['validations']['displayTitle'];

    let displayTitleObject = {};

    displayTitleObject['handleBar'] = fieldKey;
    displayTitleObject['referenceSlug'] = refSlug;
    displayTitleObject['displayTitle'] = this.displayTitleOfNested;
    displayTitleObject['displayFields'] = field['validations']['displayFields'];

    let bbID = this.bbLocalStorageService.getBuildingBlocksById(refSlug);
    this.displayTitleAA += bbID['displayTitle'];

    this.buildingBlockService.getRecordsWithID(refSlug, recordID).subscribe(data => {
      this.openNestedMultiWidget(data, fieldKey, displayTitleObject);
    });
  }

  openMultiValueWidget(data: any, columnName: string, columnNameHandleBar: string, fieldType: string) {
    this.isLoading = true;
    if (fieldType === 'REFERENCE') {
      this.buildingBlockService
        .getRecords(this.displayTitleAndFieldForNestedReference[columnNameHandleBar]['referenceSlug'], '')
        .subscribe(rec => {
          let recArray: Array<any> = [];
          data.forEach(d => {
            rec.data.forEach(element => {
              if (element['id'] === d) {
                recArray.push(element);
              }
            });
          });
          let dialogRef = this.dialog.open(MultiValueWidgetComponent, {
            panelClass: 'delete-item-dialog',
            data: {
              data: recArray,
              columnName: columnName,
              multiSelect: this.isMultiSelect(columnNameHandleBar),
              multiValue: this.isMultiValue(columnNameHandleBar),
              columnNameHandleBar: columnNameHandleBar,
              fieldType: fieldType,
              displayTitle: this.displayTitleAndFieldForNestedReference[columnNameHandleBar]
              // this.getDisplayTitle(columnNameHandleBar)
              // fieldNameForReferedDisplayTitle: fieldNameForReferedDisplayTitle,
              // fieldKeyValuePairArray: aa
            },
            width: '420px'
          });
          this.isLoading = false;
        });
    } else {
      let dialogRef = this.dialog.open(MultiValueWidgetComponent, {
        panelClass: 'delete-item-dialog',
        data: {
          data: data,
          columnName: columnName,
          multiSelect: this.isMultiSelect(columnNameHandleBar),
          multiValue: this.isMultiValue(columnNameHandleBar),
          columnNameHandleBar: columnNameHandleBar,
          fieldType: fieldType
          // displayTitle: this.getDisplayTitle(columnNameHandleBar),
          // fieldNameForReferedDisplayTitle: fieldNameForReferedDisplayTitle,
          // fieldKeyValuePairArray: aa
        },
        width: '420px'
      });
      this.isLoading = false;
    }
  }

  isMultiSelect(item): boolean {
    return this.multiSelectFieldsArray.includes(item);
  }

  isMultiValue(item): boolean {
    return this.multiValueFieldsArray.includes(item);
  }

  ifIsNested() {
    return this.data.isNested;
  }

  getFieldServiceFunct(data: any) {
    let fields = this.bbLocalStorageService.getAllFieldsOfBuildingBlock(data);
    this.fieldKeyandNameArray = fields;
    // this.keyAndNameArray = this.keyAndNameArrayFunc(this.fieldKeyandNameArray);
    this.fieldAndFieldTypePairArray = this.getFieldAndFieldTypePair(fields);
    this.getMultiValueFieldArray(fields);
    this.getMultiSelectFieldArray(fields);
  }

  getMultiValueFieldArray(fieldsArray) {
    fieldsArray.forEach(f => {
      if (f['hasMultipleValues'] && f['fieldType'] !== 'MULTI_SELECT') {
        this.multiValueFieldsArray.push(f['handleBar']);
      }
    });
  }

  getMultiSelectFieldArray(fieldsArray) {
    fieldsArray.forEach(f => {
      if (f['fieldType'] === 'MULTI_SELECT') {
        this.multiSelectFieldsArray.push(f['handleBar']);
      }
    });
  }

  getFieldAndFieldTypePair(fieldsArray) {
    // let fArr: Array<any> = [];
    let obj = {};
    fieldsArray.forEach(f => {
      obj[f['handleBar']] = f['fieldType'];
      // if (f['fieldType'] === 'ATTACHMENT' && this.data.data.record[f['handleBar']]) {
      //   this.getFileMetaData(
      //     this.data.data.record[f['handleBar']],
      //     typeof this.data.data.record[f['handleBar']] === 'object'
      //   );
      // }
      // obj['name'] = f['name'];
      // obj['fieldType'] = f['fieldType'];
      // if(f.fieldType == 'SINGLE_SELECT' || f.fieldType == 'MULTI_SELECT'){
      //   let isImageIncluded = false;
      //   if (f.advanceFieldType == 'LOOK_UP') {
      //     isImageIncluded = this.bbLocalStorageService.getFieldWithID(f.validations.lookUpFromField, f.validations.referenceSlug).validations.isImageIncluded;
      //   } else isImageIncluded = f.validations.isImageIncluded;
      //   obj['isImageIncluded'] = isImageIncluded;
      // }
      // fArr.push(obj);
    });

    return obj;
  }

  keyAndNameArrayFunc(data) {
    let aa = {};
    data.forEach(d => {
      aa[d['handleBar']] = d['name'];
    });
    return aa;
  }

  ifKeyAvailable(): boolean {
    if (this.fieldKeyandNameArray.length > 0) {
      return true;
    }
    return false;
  }

  // whatsTheFieldType(field: any) {
  //   let res = '';
  //   this.fieldAndFieldTypePairArray.forEach(f => {
  //     if (f['handleBar'] === field) {
  //       res = f['fieldType'];
  //     }
  //   });
  //   // if(res === 'REFERENCE') {
  //   // }
  //   return res;
  // }

  isSingleSelectWithImage(fieldHandleBar, data) {
    if (typeof data == 'object') {
      return true;
    }
    return false;
    /* for (let i = 0; i < this.fieldKeyandNameArray.length; i++) {
      if (this.fieldKeyandNameArray[i].handleBar == fieldHandleBar) {
        if (this.fieldKeyandNameArray[i].validations.isImageIncluded) {
          for (let j = 0; j < this.fieldKeyandNameArray[i].validations.optionsWithImages.length; j++) {
            if (this.fieldKeyandNameArray[i].validations.optionsWithImages[j]['id'] === id) {
              this.singleSelectWithImage = this.fieldKeyandNameArray[i].validations.optionsWithImages[j];
              return this.singleSelectWithImage;
            } else {
              return null;
            }
          }
        } else {
          return null;
        }
      }
    } */
  }

  isSingleSelectwithImage2(fieldType, data): boolean {
    if (fieldType === 'SINGLE_SELECT' && data && typeof data === 'object' && typeof data[0] !== 'object') {
      return true;
    } else if (fieldType === 'SINGLE_SELECT' && data && typeof data[0] === 'object') {
      return true;
    }
    return false;
  }

  // hasMultipleAttachments(data) {
  //   if (typeof data === 'object') {
  //     return true;
  //   }
  //   return false;
  // }

  // test1(data, f) {
  //   if (this.data['data']['record'][f]['record']) {
  //     return this.data['data']['record'][f]['record'][data];
  //   }
  // }

  refDisplayTitle(fieldHandlebar) {
    let val;
    let rec = this.data['data']['record'][fieldHandlebar];
    if (rec && typeof rec == 'object') {
      val = rec['record'] == null ? '-' : rec['record'];
    } else val = rec == null ? '-' : rec;
    return val[this.data['displayTitleAndFieldForNestedReference'][fieldHandlebar]['displayTitle']];
  }

  whatsTheFieldTypeReference(f) {
    let field = this.bbLocalStorageService.getFieldWithID(
      this.data.displayTitleAndFieldForNestedReference[f]['displayTitle'],
      this.data.displayTitleAndFieldForNestedReference[f]['referenceSlug']
    );
    if (field && field['fieldType']) return field['fieldType'];
  }

  async getDisplayTitleWithDot(data) {
    this.isLoadingItems = true;
    if (!Array.isArray(data['data'])) return;
    if (Array.isArray(data['data'])) {
      this.putawayCompletedIts = await Promise.all(
        data['data'].map(
          async (singleData): Promise<any> => {
            if (this.fieldAndFieldTypePairArray[this.displayTitle] !== 'ATTACHMENT') {
              let displayTitle = this.data['displayTitle']['displayTitle'];
              let recordId = singleData['record'][displayTitle.split('.')[0]];
              if (!this.displayTitle.includes('.')) {
                return { recordId, name: singleData['record'][this.displayTitle] };
              } else {
                let displayTitleWithDot = await this.helper(singleData);
                return { recordId, name: displayTitleWithDot };
              }
            }
          }
        )
      );
    } else this.helper(data['data']);

    this.isLoadingItems = false;
  }

  async helper(data) {
    let displayTitle = this.data['displayTitle']['displayTitle'];
    let referenceSlug = this.data['displayTitle']['referenceSlug'];
    let recordId = data['record'][displayTitle.split('.')[0]];
    let referenceField = this.bbLocalStorageService.getFieldWithID(displayTitle.split('.')[0], referenceSlug);
    let aa;
    await this.buildingBlockService
      .getRecordsWithID(referenceField['validations']['referenceSlug'], recordId)
      .toPromise()
      .then(rec => {
        aa = rec['record'][displayTitle.split('.')[1]];
      });
    this.displayTitleWithDot = aa;
    return this.displayTitleWithDot;
  }
}
