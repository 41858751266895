import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services';
import { DeleteScheduled } from 'src/app/auth/actions/auth.actions';
import { select, Store } from '@ngrx/store';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
@Component({
  selector: 'app-delete-scheduled',
  templateUrl: './delete-scheduled.component.html',
  styleUrls: ['./delete-scheduled.component.scss']
})
export class DeleteScheduledComponent implements OnInit {
  loaderDelete: boolean = false;
  deleteScheduleSubscription$: Subscription;
  constructor(
    private store: Store<{ auth }>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { data: any },
    private dialogRef: MatDialogRef<DeleteScheduledComponent>
  ) {}

  ngOnInit() {}

  /** @description Edit Template */
  public deleteSchedule() {
    this.loaderDelete = true;
    this.store.dispatch(new DeleteScheduled(this.data));
    this.deleteScheduleSubscription$ = this.store.pipe(select(fromSelector.deleteSchedule)).subscribe(response => {
      if (response && response['report']) {
        this.loaderDelete = false;
        this.alertService.showSuccess(`Your ${response['report']} Schedule Deleted Successfully`);
        this.dialogRef.close({ event: 'close', data: response });
      }
    });
  }

  ngOnDestroy() {
    if (this.deleteScheduleSubscription$) this.deleteScheduleSubscription$.unsubscribe();
  }
}
