import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { Subject } from 'rxjs';

import { DateModel } from '../models/date.model';
import { FilterMainModel } from '../models/filter-main.model';
import { LocationModel } from '../models/location.model';
import { MorefilterClass } from '../models/more-filter.model';
import { SalesChannelModel } from '../models/sales-channel.model';
import { FilterModel } from '../models/filter.model';
@Injectable({
  providedIn: 'root'
})
export class FilterHelperServiceNew {
  isCalledFromSidebar: boolean = true;
  resetMatPagination = new Subject();
  public currentRoute: string = '';
  public orderReceivedOn: string = '';
  public orderHandoverDoneTimestamp: string = '';
  public orderDeliveredTimestamp: string = '';
  public createdOnTimestamp: string = '';
  public receivedOnTimestamp: string = '';
  public completedOnTimestamp: string = '';
  public orderCancelledTimestamp: string = '';
  public salesChannel: string = '';
  public location: string = '';
  public firstRTSTimestamp: string = '';
  public invoicedOn: string = '';
  public lostmarkedOn: string = '';
  constructor(private router: Router) {
    this.currentRoute = this.router.url;
    this.orderReceivedOn = 'orderReceivedOn';
    this.orderHandoverDoneTimestamp = 'orderHandoverDoneTimestamp';
    this.orderDeliveredTimestamp = 'orderDeliveredTimestamp';
    this.createdOnTimestamp = 'returns.returnDetails.createdOnTimestamp';
    this.receivedOnTimestamp = 'returns.returnDetails.receivedOnTimestamp';
    this.completedOnTimestamp = 'returns.returnDetails.completedOnTimestamp';
    this.orderCancelledTimestamp = 'orderCancelledTimestamp';
    this.firstRTSTimestamp = 'orderReadyToShipTimestamp';
    this.invoicedOn = 'shipmentDetails.invoiceDate';
    this.lostmarkedOn = 'orderItemLostDetails.lostMarkedOnTimestamp';

    this.salesChannel = 'orderDetails.saleChannel';
    this.location = 'shipmentDetails.facilityCode';
  }

  /**@description - Method to Convert Main Filter Model into Filter String*/
  public manipulateData(filterFormData, moduleType) {
    let finalStirng: string = '';
    for (let filter in filterFormData) {
      switch (filter) {
        case 'location':
          finalStirng += this.convertLocationString(filterFormData[filter]);
          break;
        case 'salesChannel':
          finalStirng += this.convertSalesChannelString(filterFormData[filter]);
          break;
        case 'moreFilters':
          finalStirng += this.convertMoreFilterString(
            filterFormData[filter],
            moduleType,
            filterFormData.selectionValue
          );
          break;
        case 'date':
          finalStirng += this.convertDateData(filterFormData[filter], moduleType);
      }
    }
    if (finalStirng.endsWith('OR)')) return finalStirng.slice(0, finalStirng.lastIndexOf('OR')) + ')';
    else return finalStirng.slice(0, finalStirng.lastIndexOf('AND'));
  }

  /**@description - Method to Convert Main Date Filter Model into Date Filter String*/
  convertDateData(dateData, moduleType) {
    if (moduleType === 'Order-Return' || moduleType === 'all-orders') {
      let final: string = '';
      dateData.forEach(element => {
        if (Object.keys(element).length > 0) {
          final += `(${this.getAlgoliaDateFilters(
            moduleType,
            element.filterKey, //backendkey
            '',
            element.condition, //is between
            element.fromDate,
            element.toDate
          )})AND`;
        }
      });

      return final;
    } else {
      let final: string = '';
      if (Object.keys(dateData).length > 0) {
        final += `(${this.getAlgoliaDateFilters(
          moduleType,
          dateData.filterKey,
          '',
          dateData.filterCondition,
          dateData.fromDate,
          dateData.toDate
        )})AND`;
      }
      return final;
    }
  }

  /**@description Method To Convert More Filters to Filter String*/
  public convertMoreFilterString(filtersData, moduleType, selectionValue) {
    let final: string = '';
    let selectionVal = selectionValue ? (selectionValue === 'undefined' ? 'AND' : selectionValue) : 'AND';
    filtersData.forEach(y => {
      final +=
        '(' +
        this.forNotCondition(y['condition'], y['displayKey']['fieldType']) +
        ' ' +
        this.multipleValuesV2(
          y['filterValue'],
          y['displayKey']['fieldType'],
          y['displayKey']['key'],
          y['condition'],
          y['from'],
          y['to'],
          y['fromDate'],
          y['toDate'],
          moduleType
        ) +
        ')' +
        selectionVal;
    });
    if (selectionValue && selectionValue.toLowerCase() === 'or') return '(' + final + ')';
    else return final;
  }

  /**@description Method To Convert Sales Channel To Filter String*/
  public convertSalesChannelString(salesChannelData) {
    let final: string = '';
    for (let salesChannel in salesChannelData) {
      if (Array.isArray(salesChannelData[salesChannel]) && salesChannelData[salesChannel].length > 0) {
        const condition = salesChannel == 'isAvailableOn' ? 'is' : salesChannel == 'salesChannel' ? 'is' : 'is not';
        const salesChannelKey = salesChannel == 'salesChannel' ? this.salesChannel : 'availableOn';
        final +=
          '(' +
          this.forNotCondition(condition, 'string') +
          ' ' +
          this.forSalesChannel(condition, salesChannelData[salesChannel], salesChannelKey) +
          ')' +
          'AND';
      }
    }
    return final;
  }

  /**@description Helper Method To Convert Sales Channel To Filter String*/
  public forSalesChannel(condition, value, key) {
    let final: string = '';
    let index: any;
    if (condition == 'is') {
      value.forEach(x => (final += `${key}:'${x}' OR `));
      index = final.lastIndexOf('OR');
    } else {
      value.forEach(x => (final += `${key}:'${x}' OR NOT `));
      index = final.lastIndexOf('OR NOT');
    }
    return final.slice(0, index);
  }

  /**@description Method To Convert Location Filters To Filter String*/
  public convertLocationString(locationData) {
    let final: string = '';
    for (let location in locationData) {
      if (Array.isArray(locationData[location]) && locationData[location].length > 0) {
        switch (location) {
          case 'isInStock':
          case 'isOutOfStock':
            final += '' + this.forLocation(location, locationData[location]) + '' + 'AND';
            break;
          case 'hassellableInventory':
          case 'hasNonsellableInventory':
          case 'location':
            final += '(' + this.forLocation(location, locationData[location]) + ')' + 'AND';
            break;
        }
      }
    }
    return final;
  }

  /**@description Helper Method To Convert Location Filters To Filter String*/
  public forLocation(type, value) {
    let final: string = '';
    let index: any;
    switch (type) {
      case 'hassellableInventory':
        value.forEach(locationData => (final += `stock.sellableFC: '${locationData}' OR `));
        index = final.lastIndexOf('OR');
        break;
      case 'hasNonsellableInventory':
        value.forEach(locationData => (final += `stock.nonSellableFC: '${locationData}' OR `));
        index = final.lastIndexOf('OR');
        break;
      case 'isInStock':
        value.forEach(
          locationData =>
            (final += `(stock.SellableFC: '${locationData}' OR stock.nonSellableFC: '${locationData}' OR stock.inwardProgressFC: '${locationData}' OR stock.outwardProgressFC: '${locationData}') AND`)
        );
        index = final.lastIndexOf('AND');
        break;
      case 'isOutOfStock':
        value.forEach(
          locationData =>
            (final += `(NOT stock.SellableFC: '${locationData}' OR NOT stock.nonSellableFC: '${locationData}' OR NOT stock.inwardProgressFC: '${locationData}' OR NOT stock.outwardProgressFC: '${locationData}') AND`)
        );
        index = final.lastIndexOf('AND');
        break;
      case 'location':
        value.forEach(locationData => (final += `${this.location}: '${locationData}' OR `));
        index = final.lastIndexOf('OR');
        break;
    }
    return final.slice(0, index);
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  // public multipleValues(value, type, key, condition, from?, to?, fromDate?, toDate?, moduleType?) {
  //   let final: any = '';
  //   if (type.toLowerCase() === 'range') {
  //     final = `'${key}'${from ? ':' + from : ''}${this.forColonOrOperator(condition, type)}${to}`;
  //   } else if (type.toLowerCase() === 'daterange') {
  //     final = this.getAlgoliaDateFilters(moduleType, key, type, condition, fromDate, toDate);
  //   } else if (type.toLowerCase() === 'qtyrange') {
  //     if (condition != 'is between') final = `'${key}'${this.forColonOrOperator(condition, type)}${from}`;
  //     else final = this.getAlgoliaQuantityRangeFilters(key, condition, from, to);
  //   } else if (typeof value === 'string') {
  //     if ((moduleType == 'Order-Return' || moduleType == 'all-orders') && key == 'customerOrderNumber')
  //       final = `'${key}'${this.forColonOrOperator(
  //         condition,
  //         type
  //       )}${value})OR('vendorOrderNumber'${this.forColonOrOperator(condition, type)}${value}`;
  //     else final = `'${key}'${this.forColonOrOperator(condition, type)}${value}`;
  //   } else {
  //     let idx: any;
  //     if (condition == 'is') {
  //       value.forEach((x, i) => {
  //         if ((moduleType == 'Order-Return' || moduleType == 'all-orders') && key == 'orderStatus') {
  //           if (x.trim().toLowerCase() == 'fulfilled') {
  //             final += `'${key}':'${x}')OR('orderStatus':Shipped ${
  //               value.length > 1 && i != value.lenght - 1 ? ')' : ''
  //             } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
  //           } else if (
  //             x.trim().toLowerCase() === 'expected' ||
  //             x.trim().toLowerCase() === 'return in process' ||
  //             x.trim().toLowerCase() === 'complete'
  //           ) {
  //             final += `'returnStatus': '${x}' OR`;
  //             final += `'orderStatus': '${x}' OR`;
  //           } else {
  //             final += `'${key}':'${x}')OR('returnStatus':'${x}' ${
  //               value.length > 1 && i != value.length - 1 ? ')' : ''
  //             } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
  //           }
  //         } else if (
  //           (moduleType == 'Order-Return' || moduleType == 'all-orders') &&
  //           key == 'paymentDTO.status' &&
  //           x.trim().toLowerCase() == 'awaiting payment'
  //         ) {
  //           final += `'${key}':'${x}')AND(NOT 'orderStatus':NEW)AND(NOT 'orderStatus':PROCESSING ${
  //             value.length > 1 && i != value.lenght - 1 ? ')' : ''
  //           } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
  //         } else {
  //           final += `'${key}':'${x}' OR `;
  //         }
  //       });
  //       idx = final.lastIndexOf('OR');
  //     } else {
  //       value.forEach((x, i) => {
  //         if ((moduleType == 'Order-Return' || moduleType == 'all-orders') && key == 'orderStatus') {
  //           // final += `'${key}':'${x}' )OR(NOT 'returnStatus':'${x}' ${
  //           //   value.length > 1 && i != value.length - 1 ? ')' : ''
  //           // } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}NOT`;
  //           final += `'${key}':'${x}' ${value.length > 1 && i != value.length - 1 ? ')' : ''} AND ${
  //             value.length > 1 && i != value.length - 1 ? '(' : ''
  //           }NOT `;
  //         }
  //         // final += `'${key}':'${x}' OR NOT`;
  //         else final += `'${key}':'${x}' AND NOT `;
  //       });
  //       if ((moduleType == 'Order-Return' || moduleType == 'all-orders') && key == 'orderStatus') idx = final.lastIndexOf('AND NOT');
  //       else if ((moduleType == 'Order-Return' || moduleType == 'all-orders') && key != 'orderStatus') idx = final.lastIndexOf('AND NOT');
  //       else idx = final.lastIndexOf('OR NOT');
  //     }
  //     return final.slice(0, idx);
  //   }
  //   return final;
  // }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public multipleValuesV2(value, type, key, condition, from?, to?, fromDate?, toDate?, moduleType?) {
    let final: any = '';
    const alCondition = this.forElseCondition(condition);
    if (type.toLowerCase() === 'range') {
      final = `'${key}'${from ? ':' + from : ''}${this.forColonOrOperator(condition, type)}${to}`;
    } else if (type.toLowerCase() === 'daterange') {
      final = this.getAlgoliaDateFilters(moduleType, key, type, condition, fromDate, toDate);
    } else if (type.toLowerCase() === 'qtyrange') {
      if (condition != 'is between') final = `'${key}'${this.forColonOrOperator(condition, type)}${from}`;
      else final = this.getAlgoliaQuantityRangeFilters(key, condition, from, to);
    } else if (type.toLowerCase() === 'string') {
      if (moduleType == 'all-orders' && key == 'customerOrderNumber') {
        final = `'${key}'${this.forColonOrOperator(
          condition,
          type
        )}${value})OR('vendorOrderNumber'${this.forColonOrOperator(condition, type)}${value}`;
      } else if (condition == 'is') {
        final = `'${key}'${this.forColonOrOperator(condition, type)}${value}`;
      } else {
        final = `${alCondition} '${key}'${this.forColonOrOperator(condition, type)}${value}`;
      }
    } else {
      let idx: any;
      if (condition == 'is') {
        value.forEach((x, i) => {
          if (moduleType == 'Order-Return' && key == 'currentOrderStatus') {
            if (x.trim().toLowerCase() == 'fulfilled') {
              final += `'${key}':'${x}')OR('currentOrderStatus':Shipped ${
                value.length > 1 && i != value.lenght - 1 ? ')' : ''
              } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}`;
            } else if (
              x.trim().toLowerCase() === 'expected' ||
              x.trim().toLowerCase() === 'return in process' ||
              x.trim().toLowerCase() === 'complete'
            ) {
              final += `'currentOrderStatus': '${x}' OR`;
              final += `'currentOrderStatus': '${x}' OR`;
            } else {
              final += `'${key}':'${x}' ${value.length > 1 && i != value.length - 1 ? ')' : ''} OR ${
                value.length > 1 && i != value.length - 1 ? '(' : ''
              }`;
            }
          } else if (key == 'orderDetails.isCod') {
            let val: string;
            if (x == '0' || x == '1') {
              val = x;
            } else {
              val = x == 'Prepaid' ? '0' : '1';
            }
            final += `'${key}':'${val}' OR `;
          } else {
            final += `'${key}':'${x}' OR `;
          }
        });
        idx = final.lastIndexOf('OR');
      } else {
        value.forEach((x, i) => {
          if (moduleType == 'all-orders' && key == 'currentOrderStatus') {
            // final += `'${key}':'${x}' )OR(NOT 'returnStatus':'${x}' ${
            //   value.length > 1 && i != value.length - 1 ? ')' : ''
            // } OR ${value.length > 1 && i != value.length - 1 ? '(' : ''}NOT`;
            final += `${alCondition} '${key}':'${x}' ${value.length > 1 && i != value.length - 1 ? ')' : ''} AND ${
              value.length > 1 && i != value.length - 1 ? '(' : ''
            }NOT `;
          } else if (key == 'orderDetails.isCod') {
            let val: string;
            if (x == '0' || x == '1') {
              val = x;
            } else {
              val = x == 'Prepaid' ? '0' : '1';
            }
            final += `${alCondition} '${key}':'${val}' AND `;
          } else final += `${alCondition} '${key}':'${x}' AND `;
        });
        if (moduleType == 'all-orders') {
          idx = final.lastIndexOf('AND');
        } else idx = final.lastIndexOf('OR');
      }
      return final.slice(0, idx);
    }
    return final;
  }

  /**@description Method For Handle Date Filters*/
  public getAlgoliaDateFilters(moduleType, key, type, condition, fromDate?, toDate?) {
    switch (moduleType) {
      case 'Inventory':
      case 'Draft': {
        let appliedDate = condition == 'is' ? fromDate : toDate;
        let final = `${key}${this.forColonOrOperator(condition, type)}${new Date(fromDate)
          .setHours(0, 0, 0, 0)
          .valueOf()} TO ${new Date(appliedDate).setHours(23, 59, 59).valueOf()} `;
        return final;
      }
      case 'Order-Return':
      case 'all-orders': {
        let appliedDate = condition == 'is' ? fromDate : toDate;
        let final = `${key}${'>='}${new Date(fromDate).setHours(0, 0, 0, 0).valueOf()})AND(${key}${'<='}${new Date(
          appliedDate
        )
          .setHours(23, 59, 59, 999)
          .valueOf()}`;
        // if (condition === 'is between') return '(' + final + ')';
        // else return final;
        return final;
      }
    }
  }

  /**@description Method For Handle Quantity Range Filters*/
  public getAlgoliaQuantityRangeFilters(key, condition, from?, to?) {
    let final = `${key}${'>='}${from})AND(${key}${'<='}${to}`;
    if (condition === 'is between') return '(' + final + ')';
    else return final;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public condition(str, type) {
    let final;
    if (type.toLowerCase() === 'range') {
      final = this.forIfCondition(str);
    } else {
      final = this.forElseCondition(str);
    }
    return final;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forColonOrOperator(condition, type) {
    let operator;
    if (type.toLowerCase() === 'range') {
      operator = this.forIfCondition(condition);
    } else if (type.toLowerCase() === 'qtyrange') {
      operator = this.getOperatorBasedOnCondition(condition);
    } else {
      operator = ':';
    }
    return operator;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forElseCondition(str) {
    let forElse;
    switch (str) {
      case 'is':
        forElse = 'OR';
        break;
      case 'is not':
        forElse = 'NOT';
        break;
      case 'contains':
        forElse = 'OR';
        break;
      case 'doesnot contains':
        forElse = 'NOT';
        break;
      default:
        forElse = 'OR';
    }
    return forElse;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forNotCondition(condition, type) {
    let final;
    if (type.toLowerCase() === 'range' || type.toLowerCase() === 'daterange' || type.toLowerCase() === 'qtyrange') {
      final = '';
    } else {
      if (condition === 'is not') {
        final = 'NOT';
      } else {
        final = '';
      }
    }
    return final;
  }

  /**@description -Helper Method to Convert Get Operator based on Condition*/
  public getOperatorBasedOnCondition(str) {
    let forIf;
    switch (str) {
      case 'is':
        forIf = ':';
        break;
      case 'is not':
        forIf = '!=';
        break;
      case 'is greater than':
        forIf = '>';
        break;
      case 'is less than':
        forIf = '<';
        break;
      case 'is greater than or equal to':
        forIf = '>=';
        break;
      case 'is less than or equal to':
        forIf = '<=';
        break;
      default:
        forIf = ':';
    }
    return forIf;
  }

  /**@description -Helper Method to Convert Filter Object into Filter String*/
  public forIfCondition(str) {
    let forIf;
    switch (str) {
      case 'is':
        forIf = '=';
        break;
      case 'is not':
        forIf = '!=';
        break;
      case 'is greater than':
        forIf = '>';
        break;
      case 'is less than':
        forIf = '<';
        break;
      case 'is greater than or equal to':
        forIf = '>=';
        break;
      case 'is less than or equal to':
        forIf = '<=';
        break;
      case 'is between':
        forIf = ' TO ';
        break;
      default:
        forIf = ':';
    }
    return forIf;
  }

  /**@description - Method To Get Filter Condition based on Condition Provided in Query Params*/
  getFilterCondition(paramsFilterCondition) {
    let originalFilterCondition = '';
    switch (paramsFilterCondition) {
      case 'is':
        return (originalFilterCondition = 'is');
      case 'not':
        return (originalFilterCondition = 'is not');
      case 'greater':
        return (originalFilterCondition = 'is greater than');
      case 'less':
        return (originalFilterCondition = 'is less than');
      case 'greaterThan':
        return (originalFilterCondition = 'is greater than or equal to');
      case 'lessThan':
        return (originalFilterCondition = 'is less than or equal to');
      case 'between':
        return (originalFilterCondition = 'is between');
    }
  }

  /**@description - Method To Get Inventory Segments Query Params*/
  public getInventorySegmentsQueryParams(segmentName, segmentFilters) {
    let filterQuery: any = {
      selectedView: segmentName.replaceAll(' ', '$'),
      sortBy: 'product_service',
      page: 0,
      hitsPerPage: 50
    };
    return (filterQuery = { ...filterQuery, ...segmentFilters });
  }

  /**@description - Method To Get Drafts Segments Query Params*/
  public getDraftsSegmentsQueryParams(segmentName, segmentFilters) {
    let filterQuery: any = {
      moduleStatus: 'DRAFT',
      selectedView: segmentName.replaceAll(' ', '$'),
      sortBy: 'product_service',
      page: 0,
      hitsPerPage: 50
    };
    return (filterQuery = { ...filterQuery, ...segmentFilters });
  }

  /**@description - Method To Get Order Return Segments Query Params*/
  // public getOrderReturnSegmentsQueryParams(segmentName, segmentFilters) {
  //   let fromDate, createdOnfromDate;
  //   let toDate;
  //   let filterQuery: any = {
  //     selectedView: segmentName.replaceAll(' ', '$'),
  //     page: 0,
  //     perPage: 50,
  //     index: 'prod'
  //   };
  //   fromDate = new Date(
  //     moment()
  //       .subtract(29, 'days')
  //       .startOf('day')
  //       .valueOf()
  //   ).valueOf();
  //   toDate = new Date().setHours(23, 59, 59).valueOf();
  //   createdOnfromDate = new Date(
  //     moment()
  //       .subtract(180, 'days')
  //       .startOf('day')
  //       .valueOf()
  //   ).valueOf();

  //   switch (segmentName) {
  //     case 'All orders':
  //     case 'Fulfilled':
  //     case 'Pending to fulfill':
  //     case 'Cancelled':
  //       segmentFilters.createdInEshopbox = `last30days-${fromDate}TO${toDate}`;
  //       break;
  //     case 'All returns':
  //     case 'Returned':
  //     case 'Returning':
  //       segmentFilters.createdInEshopbox = `custom-${createdOnfromDate}TO${toDate}`;
  //       segmentFilters.returnCreatedOn = `last30days-${fromDate}TO${toDate}`;
  //       break;
  //     default:
  //       for (let  key in segmentFilters) {
  //         if (
  //           [
  //             'createdInEshopbox',
  //             'ordersFulfilledOn',
  //             'ordersDeliveredOn',
  //             'returnCreatedOn',
  //             'returnReceivedOn',
  //             'returnCompletedOn',
  //             'ordersCancelledOn',
  //             'firstRTSTimestamp'
  //           ].includes(key)
  //         ) {
  //           if (segmentFilters[key].split('-')[0] != 'custom')
  //             segmentFilters[key] = this.getDates(segmentFilters[key].split('-')[0]);
  //         }
  //       }
  //   }
  //   return (filterQuery = { ...filterQuery, ...segmentFilters });
  // }

  /**@description - Method To Get Order Return Segments Query Params*/
  public getOrderReturnSegmentsQueryParamsV2(segmentName, segmentFilters) {
    let fromDate, createdOnfromDate;
    let toDate;
    let filterQuery: any = {
      selectedView: segmentName.replaceAll(' ', '$'),
      page: 0,
      perPage: 50,
      index: 'prod'
    };
    fromDate = new Date(
      moment()
        .subtract(29, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();
    toDate = new Date().setHours(23, 59, 59).valueOf();
    createdOnfromDate = new Date(
      moment()
        .subtract(179, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();

    switch (segmentName) {
      case 'All orders':
      case 'Unfulfillable':
      case 'Pending to pack':
      case 'Ready to ship (RTS)':
      case 'Dispatched':
      case 'Delivered':
      case 'Cancelled':
        segmentFilters.createdInEshopbox = `last30days-${fromDate}TO${toDate}`;
        break;
      case 'All returns':
      case 'Returned':
      case 'Returning':
        // segmentFilters.createdInEshopbox = `custom-${createdOnfromDate}TO${toDate}`;
        segmentFilters.returnCreatedOn = `last30days-${fromDate}TO${toDate}`;
        break;
      case 'Lost':
        // segmentFilters.createdInEshopbox = `custom-${createdOnfromDate}TO${toDate}`;
        break;
      default:
        for (let key in segmentFilters) {
          if (
            [
              'createdInEshopbox',
              'ordersFulfilledOn',
              'ordersDeliveredOn',
              'returnCreatedOn',
              'returnReceivedOn',
              'returnCompletedOn',
              'ordersCancelledOn',
              'firstRTSTimestamp',
              'invoicedOn',
              'ordersLostMarkedOn'
            ].includes(key)
          ) {
            if (segmentFilters[key].split('-')[0] != 'custom')
              segmentFilters[key] = this.getDates(segmentFilters[key].split('-')[0]);
          }
        }
    }
    return (filterQuery = { ...filterQuery, ...segmentFilters });
  }

  getDates(value) {
    let fromDate, toDate;
    switch (value) {
      case 'today':
        fromDate = new Date();
        toDate = new Date();
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(6, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last90days':
        fromDate = new Date(
          moment()
            .subtract(89, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last180days':
        fromDate = new Date(
          moment()
            .subtract(179, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'thisweek':
        fromDate = new Date(
          moment()
            .startOf('week')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .valueOf()
        );
        break;
    }
    return (
      value +
      '-' +
      new Date(fromDate).setHours(0, 0, 0, 0).valueOf() +
      'TO' +
      new Date(toDate).setHours(23, 59, 59, 999)
    );
  }

  getFacilityLabel(fulfillmentChannels) {
    if (fulfillmentChannels) {
      let facilityLabel = '';
      if (fulfillmentChannels.length == 1) {
        fulfillmentChannels.forEach(channelData => {
          let index = channelData.warehouseName.indexOf('(');
          let finalLabel = channelData.warehouseName.slice(index - 1);
          facilityLabel += finalLabel;
        });
      }
      return facilityLabel;
    }
  }

  /**@description Method to Rename Filter Keys*/
  public modifyObjectKeys(filterObject, existingValue, newValue) {
    for (let filterKey in filterObject) {
      if (filterKey.includes('.') || filterKey.includes('$')) {
        let updatedKey = filterKey.replace(existingValue, newValue);
        filterObject[updatedKey] = filterObject[filterKey];
        delete filterObject[filterKey];
      }
    }
    return filterObject;
  }

  checkFilterObject(filterFormData) {
    if (
      Object.keys(filterFormData.moreFilters).length == 0 &&
      filterFormData.location.location.length == 0 &&
      filterFormData.salesChannel.salesChannel.length == 0 &&
      filterFormData.date.length == 1 &&
      filterFormData.date[0].filterCondition == 'createdInEshopbox'
    )
      return true;
    else return false;
  }

  /**@description - Method to get Display Value for Additional Filters*/
  public getFilterValue(filterValue, filterType, salesChannelData, enrolledFCData) {
    let finalValue: any = [];
    switch (filterType) {
      case 'salesChannel':
      case 'saleChannel':
        filterValue.forEach(filterData => {
          salesChannelData.forEach(salesChannel => {
            if (salesChannel.externalChannelId == filterData || salesChannel.channelLabel == filterData) {
              let facilityLabel = this.getFacilityLabel(salesChannel.fulfillmentChannels);
              let finalSalesChannel = `${salesChannel.channelLabel} ${facilityLabel ? facilityLabel : ''}`;
              finalValue.push(finalSalesChannel);
            }
          });
        });
        return finalValue;
      case 'location':
      case 'wareHouseCode':
        filterValue.forEach(filterData => {
          enrolledFCData.forEach(location => {
            if (location.externalWarehouseId == filterData) {
              finalValue.push(location.warehouseName);
            }
          });
        });
        return finalValue;
      default:
        return filterValue;
    }
  }

  getDateObject(queryParams, filterKey) {
    var dateData: DateModel = new DateModel();
    dateData.fromDate = new Date(+queryParams[filterKey].split('TO')[0].split('-')[1]);
    dateData.toDate = new Date(+queryParams[filterKey].split('TO')[1]);
    dateData.conditionType = queryParams[filterKey].split('-')[0];
    dateData.condition = 'is between';
    return dateData;
  }

  /**@description - Method To Convert Filter Query Params to Filter Object*/
  // public convertQueryParamForOrder(queryParams, filterArr) {
  //   var filterMain: FilterMainModel = new FilterMainModel();
  //   var filterModelData: MorefilterClass[] = [];
  //   var salesChannelData: SalesChannelModel = new SalesChannelModel();
  //   var locationData: LocationModel = new LocationModel();
  //   var dateData: DateModel = new DateModel();
  //   var needAttentionData = [];

  //   for (let filterKey in queryParams) {
  //     if (['isAvailableOn', 'isNotAvailableOn', 'salesChannel'].includes(filterKey)) {
  //       salesChannelData[filterKey] = queryParams[filterKey].split(',');
  //       salesChannelData.displayKey = 'Sales Channel';
  //       salesChannelData.condition = 'is';
  //     } else if (
  //       ['hassellableInventory', 'hasNonsellableInventory', 'isInStock', 'isOutOfStock', 'location'].includes(filterKey)
  //     ) {
  //       locationData[filterKey] = queryParams[filterKey].split(',');
  //       locationData.displayKey = 'Location';
  //       locationData.condition = 'is';
  //     } else if (filterKey == 'createdInEshopbox') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'orderDateDetailsDTO.orderReceivedOn';
  //       dateData.filterKey = this.orderReceivedOn; //order revamp
  //       dateData.displayKey = 'Order Created in Eshopbox';
  //       dateData.filterCondition = 'createdInEshopbox';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'ordersFulfilledOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'shipmentDTO.dispatchedOn';
  //       dateData.filterKey = this.orderHandoverDoneTimestamp; //order revamp
  //       dateData.displayKey = 'Order Fulfilled on';
  //       dateData.filterCondition = 'ordersFulfilledOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'ordersDeliveredOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'shipmentTrackingDTO.deliveredDate';
  //       dateData.filterKey = this.orderDeliveredTimestamp; //order revamp
  //       dateData.displayKey = 'Order Delivered on';
  //       dateData.filterCondition = 'ordersDeliveredOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'ordersCancelledOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'shipmentDTO.cancelledOn';
  //       dateData.filterKey = this.orderCancelledTimestamp; //order revamp
  //       dateData.displayKey = 'Order Cancelled on';
  //       dateData.filterCondition = 'ordersCancelledOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'returnReceivedOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'returnReceivedOn';
  //       dateData.filterKey = this.receivedOnTimestamp; //order revamp
  //       dateData.displayKey = 'Return received on';
  //       dateData.filterCondition = 'returnReceivedOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'returnCreatedOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'returnCreatedOn';
  //       dateData.filterKey = this.createdOnTimestamp; //order revamp
  //       dateData.displayKey = 'Return created on';
  //       dateData.filterCondition = 'returnCreatedOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'returnCompletedOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       // dateData.filterKey = 'returnCompletedOn';
  //       dateData.filterKey = this.completedOnTimestamp; //order revamp
  //       dateData.displayKey = 'Return completed on';
  //       dateData.filterCondition = 'returnCompletedOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'orderReadyToShipTimestamp') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       dateData.filterKey = this.firstRTSTimestamp; //order revamp
  //       dateData.displayKey = 'Order ready to ship(RTS) on';
  //       dateData.filterCondition = 'orderReadyToShipTimestamp';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'invoicedOn') {
  //       dateData = this.getDateObject(queryParams, filterKey);
  //       dateData.filterKey = this.invoicedOn; //order revamp
  //       dateData.displayKey = 'Order invoiced on';
  //       dateData.filterCondition = 'invoicedOn';
  //       filterMain.date.push(dateData);
  //     } else if (filterKey == 'selectionValue') {
  //       filterMain.selectionValue = queryParams[filterKey];
  //     } else if (filterKey == 'needAttentionKey') {
  //       needAttentionData.push(queryParams[filterKey]);
  //     } else {
  //       filterArr.forEach(filters => {
  //         let filterModel: MorefilterClass = new MorefilterClass();
  //         var originalFilterKey = filterKey;
  //         if (filterKey[filterKey.length - 1] === '-') {
  //           originalFilterKey = filterKey.split('-')[0];
  //         }
  //         if (originalFilterKey == filters['key']) {
  //           filterModel['displayKey'] = filters;
  //           filterModel.condition = this.getFilterCondition(queryParams[filterKey].split('-')[0]);
  //           if (
  //             filters.fieldType.toLowerCase() != 'range' &&
  //             filters.fieldType.toLowerCase() != 'daterange' &&
  //             filters.fieldType.toLowerCase() != 'qtyrange'
  //           ) {
  //             filterModel['filterValue'] =
  //               filters.fieldType.toLowerCase() != 'dropdown'
  //                 ? queryParams[filterKey].split(/-(.+)/)[1]
  //                 : queryParams[filterKey].split(/-(.+)/)[1].split(',');
  //             if (filters.fieldType.toLowerCase() == 'dropdown') {
  //               let a = [];
  //               filterModel['filterValue'].forEach(ele => {
  //                 if (ele.includes('|~')) {
  //                   a.push(JSON.parse(JSON.stringify(ele.replace(/\|~/gi, ','))));
  //                 } else {
  //                   a.push(ele);
  //                 }
  //               });
  //               filterModel['filterValue'] = JSON.parse(JSON.stringify(a));
  //             }
  //           } else if (filters.fieldType.toLowerCase() == 'range') {
  //             if (filterModel.condition != 'is between') {
  //               filterModel.to = queryParams[filterKey].split('-')[1];
  //             } else if (filterModel.condition == 'is between') {
  //               const fromfilterValue = queryParams[filterKey].split('TO')[0].split('-')[1];
  //               const tofilterValue = queryParams[filterKey].split('TO')[1];
  //               filterModel.from = fromfilterValue;
  //               filterModel.to = tofilterValue;
  //             }
  //           } else if (filters.fieldType.toLowerCase() == 'daterange') {
  //             if (filterModel.condition == 'is') {
  //               filterModel.fromDate = new Date(+queryParams[filterKey].split('-')[1]);
  //             } else {
  //               const fromfilterValue = new Date(+queryParams[filterKey].split('TO')[0].split('-')[1]);
  //               const tofilterValue = new Date(+queryParams[filterKey].split('TO')[1]);
  //               filterModel.fromDate = fromfilterValue;
  //               filterModel.toDate = tofilterValue;
  //             }
  //           } else if (filters.fieldType.toLowerCase() == 'qtyrange') {
  //             if (filterModel.condition != 'is between') {
  //               filterModel.from = queryParams[filterKey].split('-')[1];
  //             } else if (filterModel.condition == 'is between') {
  //               const fromfilterValue = queryParams[filterKey].split('TO')[0].split('-')[1];
  //               const tofilterValue = queryParams[filterKey].split('TO')[1];
  //               filterModel.from = fromfilterValue;
  //               filterModel.to = tofilterValue;
  //             }
  //           }
  //           filterModelData.push(filterModel);
  //         }
  //       });
  //     }
  //   }
  //   filterMain.moreFilters = filterModelData;
  //   filterMain.salesChannel = salesChannelData;
  //   filterMain.location = locationData;
  //   filterMain.needAttentionFilters.push(needAttentionData);
  //   return filterMain;
  // }

  convertQueryParamToFilterObject(queryParams, filterArr) {
    const filters = [];

    // Iterate over each key in queryParams
    Object.keys(queryParams).forEach(key => {
      // Find the corresponding filter object in filterArr
      const filter = filterArr.find(f => f.key === key);
      if (filter) {
        let filterValue;
        let filterCondition;
        const queryParamValue = queryParams[key];

        if (filter.fieldType === 'DateRange') {
          const [conditionType, dateRange] = queryParamValue.split('-');
          const [fromDate, toDate] = dateRange.split('TO');
          filterValue = {
            fromDate: new Date(Number(fromDate)),
            toDate: new Date(Number(toDate))
          };
          filter.filterCondition = toDate ? 'is between' : 'is';
          filters.push({
            key: filter.key,
            displayKey: filter.displayKey,
            condition: filter.condition,
            filterCondition: filter.filterCondition,
            fieldType: filter.fieldType,
            conditionType: conditionType,
            fromDate: filterValue.fromDate,
            toDate: filterValue.toDate
          });
        } else {
          // Extract the condition from the query parameter value
          const [condition, ...valueParts] = queryParamValue.split('-');
          // filterCondition = condition.startsWith('is not') ? 'is not' : 'is';
          const values = valueParts
            .join('-')
            .split(',')
            .map(value => value.trim());
          filterValue = values.length === 1 ? values[0] : values;

          // Ensure filterValue is an array if fieldType is Dropdown
          if (filter.fieldType === 'Dropdown' && !Array.isArray(filterValue)) {
            filterValue = [filterValue];
          }

          filters.push({
            key: filter.key,
            displayKey: filter.displayKey,
            condition: filter.condition,
            filterCondition: condition,
            filterValue: filterValue,
            fieldType: filter.fieldType
          });
        }
      }
    });

    return filters;
  }

  modifyArray(arr, low, arrayToSortNewLength, sortedArrayElement, arrayToSortLength) {
    if (arrayToSortNewLength >= low) {
      let mid = low + Math.floor((arrayToSortNewLength - low) / 2);
      if ((mid == 0 || sortedArrayElement > arr[mid - 1]) && arr[mid] == sortedArrayElement) return mid;
      if (sortedArrayElement > arr[mid])
        return this.modifyArray(arr, mid + 1, arrayToSortNewLength, sortedArrayElement, arrayToSortLength);
      return this.modifyArray(arr, low, mid - 1, sortedArrayElement, arrayToSortLength);
    }
    return -1;
  }

  sortAccording(arrayToSort, sortedArray, arrayToSortLength, sortedArrayLength) {
    let temp = [];
    let visited = [];
    for (let i = 0; i < arrayToSortLength; i++) {
      temp[i] = arrayToSort[i];
      visited[i] = 0;
    }
    temp.sort(function(first, second) {
      return first - second;
    });
    let index = 0;
    for (let i = 0; i < sortedArrayLength; i++) {
      let final = this.modifyArray(temp, 0, arrayToSortLength - 1, sortedArray[i], arrayToSortLength);
      if (final == -1) {
        continue;
      }
      for (let j = final; j < arrayToSortLength && temp[j] == sortedArray[i]; j++) {
        arrayToSort[index++] = temp[j];
        visited[j] = 1;
      }
    }
    for (let i = 0; i < arrayToSortLength; i++) {
      if (visited[i] == 0) arrayToSort[index++] = temp[i];
    }
  }

  /**@description - Method to Convert Main Filter Model into Filter String for untitled segment*/
  public manipulateDataForUntitledSegment(filterFormData, moduleType, type) {
    let finalStirng: string = '';
    for (let filter in filterFormData) {
      switch (filter) {
        case 'location':
          finalStirng += this.convertLocationString(filterFormData[filter]);
          break;
        case 'salesChannel':
          finalStirng += this.convertSalesChannelString(filterFormData[filter]);
          break;
        case 'moreFilters':
          finalStirng += this.convertMoreFilterString(
            filterFormData[filter],
            moduleType,
            filterFormData.selectionValue
          );
          break;
        case 'date':
          finalStirng += this.convertDateDataForUntitledSegment(filterFormData[filter], moduleType, type);
      }
    }
    if (finalStirng.endsWith('OR)')) return finalStirng.slice(0, finalStirng.lastIndexOf('OR')) + ')';
    else return finalStirng.slice(0, finalStirng.lastIndexOf('AND'));
  }

  /**@description - Method to Convert Main Date Filter Model into Date Filter String*/
  convertDateDataForUntitledSegment(dateData, moduleType, type) {
    if (moduleType === 'Order-Return' || moduleType === 'all-orders') {
      let final: string = '';
      dateData.forEach(element => {
        if (Object.keys(element).length > 0) {
          final += `(${this.getAlgoliaDateFiltersForUntitledSegment(
            moduleType,
            element.filterKey, //backendkey
            element.conditionType,
            element.condition, //is between
            element.fromDate,
            element.toDate,
            type
          )})AND`;
        }
      });

      return final;
    }
  }

  /**@description Method For Handle Date Filters*/
  public getAlgoliaDateFiltersForUntitledSegment(moduleType, key, conditionType, condition, fromDate?, toDate?, type?) {
    switch (moduleType) {
      case 'Order-Return': {
        let final = ``;
        let appliedDate;
        if (type) {
          if (conditionType != 'custom') final = `${key}${'='}${conditionType}`;
          else {
            appliedDate = condition == 'is' ? fromDate : toDate;
            final = `${key}${'>='}${new Date(fromDate).setHours(0, 0, 0, 0).valueOf()})AND(${key}${'<='}${new Date(
              appliedDate
            )
              .setHours(23, 59, 59, 999)
              .valueOf()}`;
          }
        } else {
          if (conditionType != 'custom') final = `${key}${'='}${conditionType}`;
          else {
          }
          return final;
        }
      }
    }
  }

  // New method used to convert "All orders" page filter to Algolia query
  convertToAlgoliaString(filtersData: any[]): string {
    let queryString: string = '';
    const selectionVal = 'AND';
    filtersData.forEach(y => {
      queryString +=
        '(' +
        this.forNotCondition(y['condition'], y['fieldType']) +
        ' ' +
        this.multipleValuesV2(
          y['filterValue'],
          y['fieldType'],
          y['key'],
          y['filterCondition'],
          null,
          null,
          y['fromDate'],
          y['toDate'],
          'all-orders'
        ) +
        ')' +
        selectionVal;
    });
    let queryStr;
    if (selectionVal && selectionVal.toLowerCase() === 'or') queryStr = '(' + queryString + ')';
    if (queryString.endsWith('OR)')) queryStr = queryString.slice(0, queryString.lastIndexOf('OR')) + ')';
    else queryStr = queryString.slice(0, queryString.lastIndexOf('AND'));
    queryStr = queryStr.replace(/NOT\s+NOT/g, 'NOT');
    return queryStr;
  }

  //Method to create a filter query for new added segment from filtersData
  createReturnNoScanFilterQuery(filterQuery, filtersData) {
    let queryStr = filterQuery;
    filtersData.forEach(y => {
      console.log(y);
      if (!filterQuery.includes(y.key)) {
        if (y.fieldType === 'DateRange') {
          queryStr += `&${y.key}=${y.conditionType}`;
        } else {
          if (y.fieldType === 'Dropdown') {
            queryStr += `&${y.key}=${y.filterCondition}-${
              y.filterValue.length > 1 ? y.filterValue.join(',') : y.filterValue[0]
            }`;
          }
        }
      }
    });
    return queryStr;
  }
}
