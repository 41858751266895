import { createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/install-app-list.reducer';
import { AppState } from 'src/app/app.state';
import { compareObjToTerm } from 'src/app/shared/utils/store-utils';

const getInstalledAppsListState = (state: AppState) => state.installedAppList;
//const getConnectionByIdState = (state: AppState) => state.connectionByDetail;

export const getInstalledAppsList = createSelector(getInstalledAppsListState, fromReducer.getInstalledAppsList);
export const getInstalledAppsListLoaded = createSelector(
  getInstalledAppsListState,
  fromReducer.getInstalledAppsListLoaded
);

export const getInstalledAppsListLoading = createSelector(
  getInstalledAppsListState,
  fromReducer.getInstalledAppsListLoading
);
export const getConnectionByIdDetail = createSelector(getInstalledAppsListState, fromReducer.getConnectionByIdDetail);
export const getConnectionByIdLoading = createSelector(getInstalledAppsListState, fromReducer.getConnectionByIdLoading);
export const getContentByIdDetail = createSelector(getInstalledAppsListState, fromReducer.getContentfulByIdDetail);
export const getStepListIdDetail = createSelector(getInstalledAppsListState, fromReducer.getStepListtDetail);
export const getOnDisconnectIdDetail = createSelector(getInstalledAppsListState, fromReducer.getDisconnectByIdDetail);

export const getInstalledAppsListDetail = createSelector(
  getInstalledAppsListState,
  fromReducer.getInstalledAppsListDetail
);

export const getInstalledAppsListDetailLoading = createSelector(
  getInstalledAppsListState,
  fromReducer.getInstalledAppsListDetailLoading
);

export const getInstalledAppsListSearch = createSelector(
  getInstalledAppsListState,
  fromReducer.getInstalledAppsListSearch
);

export const getUninstallStatus = createSelector(getInstalledAppsListState, fromReducer.getUninstallStatus);

export const getProgressSpinner = createSelector(getInstalledAppsListState, fromReducer.getProgressSpinner);

export const getFullfilmentCenterList = createSelector(getInstalledAppsListState, fromReducer.getFullfilmentCenterList);

export const getFullfilmentCenterListLoading = createSelector(
  getInstalledAppsListState,
  fromReducer.getFullfilmentCenterListLoading
);

export const getChannelLists = createSelector(getInstalledAppsListState, fromReducer.getChannelListById);

export const getChannelListsLoading = createSelector(getInstalledAppsListState, fromReducer.getChannelListByIdLoading);

export const getFieldPageLists = createSelector(getInstalledAppsListState, fromReducer.getFieldPageList);

export const getFieldPageListsLoading = createSelector(getInstalledAppsListState, fromReducer.getFieldPageListLoading);

export const getCatogryCountLists = createSelector(getInstalledAppsListState, fromReducer.getCatogryCountDetails);

export const getNykaaProductSyncLists = createSelector(
  getInstalledAppsListState,
  fromReducer.getnykaaProductSyncDetails
);
export const getNykaaProductSyncListsLoading = createSelector(
  getInstalledAppsListState,
  fromReducer.getnykaaProductSyncDetailsLoading
);

export const getCatogryCountListsLoading = createSelector(
  getInstalledAppsListState,
  fromReducer.getCatogryCountDetailsLoading
);

export const getFilteredInstalledAppsListSearch = createSelector(
  getInstalledAppsList,
  getInstalledAppsListSearch,
  (installedList, searchTerm) => {
    if (!installedList || !installedList.data) {
      return null;
    }
    if (!searchTerm) {
      return installedList.data;
    }
    //  let installAppListView = installedList.data;
    let applistview = installedList.data;

    let filteredData = applistview.filter(e => {
      return compareObjToTerm(e, searchTerm.trim(), [
        'createdAt',
        'accountSlug',
        'appInstallationLink',
        'businessName',
        'contactEmail',
        'description',
        'homePageUrl',
        'intendedAudience',
        'logoUrl',
        'status',
        'createdAt',
        'updatedAt'
      ]);
    });

    if (!filteredData.length) {
      return 'nodata';
    } else {
      return filteredData;
    }
  }
);
