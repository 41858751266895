import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { AuthService } from '../shared/services';
import { ROUTESDATA } from '../constants/routes';
import { isArray } from '../records/utils/common-util';
import { getCurrentLoggedInUserData } from '../auth/reducers/auth.selectors';
import { Store, select } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class RBACGuard implements CanActivateChild {
  NEWROUTES = ROUTESDATA;
  loggedInUserSubscription$: any;

  constructor(private auth: AuthService, private router: Router, private store: Store<{ auth }>) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      try {
        const { accountList, warehouseList } = await this.getAccountAndWarehouseList();
        const authorized = await this.authorizeUser(childRoute.queryParams['handleBar'], accountList, warehouseList);
        if (authorized) {
          resolve(true);
        }
        resolve(false);
      } catch (error) {
        this.router.navigate(['/access-denied/', 'data_not_loaded']);
        resolve(false);
      }
    });
  }

  async authorizeUser(handleBar: string, accountListData: any, fulfillmentCenterData: any): Promise<boolean | UrlTree> {
    const accountSlug = this.auth.getAccountSlug();

    return new Promise<boolean | UrlTree>((resolve, reject) => {
      if (
        (isArray(accountListData) || accountListData?.accountListLoaded) &&
        (isArray(fulfillmentCenterData) || fulfillmentCenterData?.fulfillmentCenterLoaded)
      ) {
        let accountList = accountListData;
        let fcList = fulfillmentCenterData;
        fcList = fcList?.filter(fc => fc.parentClientWorkspaceSlug && fc.parentClientWorkspaceSlug == accountSlug);
        if (fcList && fcList.length > 0) {
          accountList = accountList?.filter(
            x => x.parentClientWorkspaceSlug && x.parentClientWorkspaceSlug == accountSlug
          );
          if (accountList.length === 0) {
            const pathParam = window.location.pathname.includes('/building-blocks/self-fulfilment-settings')
              ? 'self_fulfilment_settings'
              : this.getQueryParam(handleBar);
            this.router.navigate(['/access-denied/', pathParam]);
            resolve(false);
          } else {
            this.loggedInUserSubscription$ = this.store.pipe(select(getCurrentLoggedInUserData)).subscribe(response => {
              if (response?.currentUser) {
                const flexAccountSlug: any = this.auth.getFlexSlug();
                const loggedInUser: any = response.currentUser;
                if (this.loggedInUserSubscription$) {
                  this.loggedInUserSubscription$.unsubscribe();
                }
                const currentURL = new URL(window.location.href);
                if (loggedInUser?.status !== '1') {
                  let workspaceName = localStorage.getItem('workspaceName');
                  this.router.navigate(['/access-denied/', `workspace_${workspaceName}`]);
                }
                const accountSlugFrmParam = currentURL.searchParams.get('location');
                const assets = ['shelves', 'toteForConsignment', 'pigeonHole', 'stagingArea'];
                if (accountSlugFrmParam && !loggedInUser.fc_workspace_permissions[accountSlugFrmParam]) {
                  this.router.navigate(['/access-denied/', `location_${accountSlugFrmParam}`]);
                  resolve(false);
                } else if (
                  this.auth.isMobileDevice() &&
                  handleBar &&
                  !this.isMobileRoute(handleBar) &&
                  (!assets.includes(handleBar) ||
                    (assets.includes(handleBar) &&
                      Object.keys(loggedInUser.fc_workspace_permissions).length != 0 &&
                      loggedInUser.fc_workspace_permissions[flexAccountSlug]['manage:assets'].isEnabled == '1'))
                ) {
                  this.router.navigate(['/login-using-desktop', 'assets']);
                  resolve(false);
                } else if (
                  assets.includes(handleBar) &&
                  Object.keys(loggedInUser.fc_workspace_permissions).length != 0 &&
                  loggedInUser.fc_workspace_permissions[flexAccountSlug]['manage:assets'].isEnabled == '1'
                ) {
                  resolve(true);
                } else if (
                  window.location.pathname === '/building-blocks/import/inventory' &&
                  accountSlug != 'eshop' &&
                  Object.keys(loggedInUser.fc_workspace_permissions).length != 0 &&
                  loggedInUser.fc_workspace_permissions[flexAccountSlug]['manage:inventory'].isEnabled == '1'
                ) {
                  resolve(true);
                } else if (
                  !assets.includes(handleBar) &&
                  window.location.pathname != '/building-blocks/import/inventory'
                ) {
                  resolve(true);
                } else {
                  const pathParam =
                    window.location.pathname === '/building-blocks/import/inventory'
                      ? 'update_inventory'
                      : this.getQueryParam(handleBar);
                  this.router.navigate(['/access-denied/', pathParam]);
                  resolve(false);
                }
              }
            });
          }
        } else if (!fcList || fcList.length == 0) {
          this.router.navigate(['/enabling-self-fulfilment/']);
          resolve(false);
        } else {
          this.router.navigate(['/access-denied/', 'something_went_wrong']);
          resolve(false);
        }
      } else {
        this.router.navigate(['/access-denied/', 'data_not_loaded']);
        resolve(false);
      }
    });
  }

  async getAccountAndWarehouseList(): Promise<{ accountList: any; warehouseList: any }> {
    return new Promise<{ accountList: any; warehouseList: any }>((resolve, reject) => {
      this.auth.getAccountAndWarehouseList().subscribe(([accountListData, fulfillmentCenterData]) => {
        if (
          (isArray(accountListData) || accountListData?.accountListLoaded) &&
          (isArray(fulfillmentCenterData) || fulfillmentCenterData?.fulfillmentCenterLoaded)
        ) {
          const accountList = isArray(accountListData) ? accountListData : accountListData?.accountListData?.data;
          const warehouseList = isArray(fulfillmentCenterData)
            ? fulfillmentCenterData
            : fulfillmentCenterData?.fulfillmentCenterData?.data;
          resolve({ accountList, warehouseList });
        }
        // else {
        //   reject('Data not loaded');
        // }
      });
    });
  }

  getQueryParam(handleBarValue): string {
    const nestedArray = Object.values(this.NEWROUTES);
    const allRoute = [].concat(...nestedArray);
    const currentRoute = allRoute.find(x => x.URL === handleBarValue);
    const queryParam = currentRoute?.scope ? currentRoute?.scope : handleBarValue;
    return queryParam;
  }

  isMobileRoute(handleBar): boolean {
    const accountSlug = this.auth.getAccountSlug();
    const nestedArray = Object.values(this.NEWROUTES);
    const allRoute = [].concat(...nestedArray);
    const mobileRoute = allRoute.filter(
      x => x.showInMobile && (x.visibileFor == 'all' || x.visibileFor == accountSlug)
    );
    const isMobileEnable = mobileRoute.find(x => x.URL === handleBar);
    return isMobileEnable && isMobileEnable['showInMobile'] ? isMobileEnable['showInMobile'] : false;
  }
}
