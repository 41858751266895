import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppInstallState } from './store/state/app-install.state';
import { GetAppScope, GetAppDetails } from './store/actions/app-install.action';
import * as fromSelector from './store/selectors/app-install.selector';
import { environment } from 'src/environments/environment';
import { API_ROUTE_LIST } from '../shared/constants/api-routes.constant';
import { AuthService } from '../shared/services';
import { AppInstallationService } from './service/app-install.service';
import { ROUTES } from '../constants/routes';
import { Title } from '@angular/platform-browser';
import { partnerCodeConfig } from '../apps-automation/models/app-id-config.model';

@Component({
  selector: 'app-app-installation',
  templateUrl: './app-installation.component.html',
  styleUrls: ['./app-installation.component.scss']
})
export class AppInstallationComponent implements OnInit {
  appName: string;
  appLogo: any;
  accounSlug: any;
  clientId: any;
  response_type: any;
  redirectUri: any;
  scope: any;
  systemState: any;
  scopeData: any = [];
  appData: any = [];
  scopeUrl: any;
  isScopeLoading: boolean;
  isScopeLoaded: boolean;
  isAppDetailsLoading: boolean;
  isAppDetailsLoaded: boolean;
  showDetailsIndex: number;
  queryParamsData: any;
  ROUTES = ROUTES;
  partnerCode: any;
  partnerValue = partnerCodeConfig;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{ appInstallState: AppInstallState }>,
    private authService: AuthService,
    private appInstallService: AppInstallationService,
    private titleService: Title
  ) {
    this.showDetailsIndex = -1;
    this.route.queryParams.subscribe(data => {
      if (data) {
        this.queryParamsData = data;
        this.response_type = data['response_type'];
        this.redirectUri = data['redirect_uri'];
        this.clientId = data['client_id'];
        this.store.dispatch(new GetAppDetails(this.clientId));
        this.scope = 'scope=' + data['scope'].split(' ').toString();
        this.scopeUrl = data['scope'].toString();
        this.systemState = data['state'];
      }
    });
  }

  ngOnInit() {
    this.store.pipe(select(fromSelector.getScopeData)).subscribe(res => {
      if (res) {
        this.scopeData = res['data'];
      }
    });

    this.store.pipe(select(fromSelector.getIsAppDetailsLoading)).subscribe(result => {
      this.isScopeLoading = result;
    });
    this.store.pipe(select(fromSelector.getIsAppDetailsLoaded)).subscribe(result => {
      this.isScopeLoaded = result;
    });

    this.store.pipe(select(fromSelector.getAppDetailsData)).subscribe(result => {
      if (result) {
        this.appName = result['title'];
        this.appLogo = result['logoUrl'];
        this.titleService.setTitle('Authorize app ' + this.appName + ' | Eshopbox');
        this.partnerCode = result['partnerCode'];
        // if (this.partnerCode !== undefined) {
        this.authorizeApp();
        // }
      }
    });

    this.store.pipe(select(fromSelector.getAppInstallConsentData)).subscribe(result => {
      this.isScopeLoading = result.loading;
      if (!!result && !!result.appInstallConsent) {
        window.location.href = result.appInstallConsent['redirectUri'];
      }
    });
    this.accounSlug = this.authService.getAccountSlug();
    this.store.dispatch(new GetAppScope(this.scope.toString()));
  }

  /**
   * Method To Show Hide Details Of App Scope
   * showHideDetails
   */
  public showHideDetails(index) {
    this.showDetailsIndex = index === this.showDetailsIndex ? -1 : index;
  }

  /**
   * Method To Redirect To Authorization Part on Click of Install
   * authorizeApp
   */
  public authorizeApp() {
    // this.router.navigate(['/public/app-redirecturl/' + this.clientId], {
    //   queryParams: { state: this.systemState, code: 'abc' }
    // });
    const url = `${API_ROUTE_LIST.APP_AUTHORIZE}`;

    this.isLoading = true;
    // this.store.dispatch(new GetAppInstallConsent({ url: url, params: this.queryParamsData }));
    this.appInstallService.getAppIn(url, this.queryParamsData).subscribe(data => {
      if (data && data['redirectUri']) {
        let url = data['redirectUri'];
        window.open(url, '_self');
        this.isLoading = false;
      }
      //  let url = new URL(data['redirectUri']);
      //   var state = url.searchParams.get('state');
      //   var code = url.searchParams.get('code');
      //   this.router.navigate(['/public/app-redirecturl/' + this.clientId], {
      //     queryParams: { state: state, code: code }
      //   });

      //window.open(uri,'_blank');
    });
    //  this.router.navigate(['/public/app-redirecturl/' + this.clientId]
  }

  dataPrivacy() {
    window.open('https://help.eshopbox.com/en/articles/4288688-eshopbox-privacy-policy', '_blank');
  }
}
