import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bad-gateway',
  templateUrl: './bad-gateway.component.html',
  styleUrls: ['./bad-gateway.component.scss']
})
export class BadGatewayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
