import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FilterMainModel } from '../models/filter-main.model';
import { FilterModel } from '../models/filter.model';
import { ActivatedRoute } from '@angular/router';
import { DataStoreService } from 'src/app/shared/services/data-store.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingBlockFilterHelperService {
  dateOptionsList: object[] = [
    {
      viewValue: 'Today',
      value: 'today'
    },
    {
      viewValue: 'Yesterday',
      value: 'yesterday'
    },
    {
      viewValue: 'Last 7 days',
      value: 'last7days'
    },
    {
      viewValue: 'Last 30 days',
      value: 'last30days'
    },
    {
      viewValue: 'Last 90 days',
      value: 'last90days'
    },
    {
      viewValue: 'This week',
      value: 'thisweek'
    },
    {
      viewValue: 'This month',
      value: 'thismonth'
    },
    {
      viewValue: 'Last month',
      value: 'lastmonth'
    }
  ];
  constructor(private route: ActivatedRoute, private dataStoreService: DataStoreService) {}

  /**@description - */
  public isSingleSelectWithImages(data, key): boolean {
    if (!data) return false;
    let aa = data.filter(element => {
      return element[key];
    });
    if (aa[0] && typeof aa[0][key][0] === 'object') {
      return true;
    }
    return false;
  }

  /**@description - */
  public isMultiSelectWithImages(data, key) {
    let aa = data?.filter(element => {
      return element[key];
    });
    if (typeof aa[0][key][0] === 'object') {
      return true;
    }
    return false;
  }

  /**@description - */
  public filterListBuildingBlocks(e, fieldsArray, type = 'general') {
    let formFiltersCopy = JSON.parse(JSON.stringify(e));
    let filterQuery: string = '';
    let filterObject: any = null;
    let filterObj: any;
    let filters = formFiltersCopy['filters'];

    let filtersCopy = JSON.parse(JSON.stringify(filters));
    // console.log('filtersCopy::>', filtersCopy);
    filtersCopy.forEach(ff => {
      filterQuery += this.generateFilterQuery(
        ff['filterKey']['key'],
        ff['filterCondition'],
        typeof ff['filterValue'] === 'string' ? ff['filterValue'].trim() : ff['filterValue'],
        ff['filterKey']['fieldType'],
        ff['from'],
        ff['to'],
        ff?.['time'],
        ff?.['fromTime'],
        ff?.['toTime'],
        fieldsArray,
        ff['selectedDateRange'],
        type
      );
      if (filterObj !== undefined) {
        if (filterObject === null) {
          filterObject = filterObj;
        } else {
          filterObject += filterObj;
        }
      }
    });
    return filterQuery.substring(0, filterQuery.length - 1);
  }

  /**@description - */
  public generateFilterQuery(
    key: string,
    condition: string,
    value: any,
    fieldType: string,
    from: any,
    to: any,
    time: any,
    fromTime: any,
    toTime: any,
    fieldsArray,
    selectedDateRange?: string,
    type?: string
  ) {
    let filterQuery: string = '';
    if (fieldType === 'BOOLEAN') {
      if (condition === 'is_true') {
        value = 'true';
      } else {
        value = 'false';
      }
      filterQuery += key + '=' + '|' + 'IS' + '|' + value;
    } else if (fieldType === 'SINGLE_SELECT' || fieldType === 'MULTI_SELECT') {
      for (let i = 0; i < fieldsArray.length; i++) {
        if (key === fieldsArray[i].handleBar) {
          if (fieldsArray[i].validations.isImageIncluded) {
            let optionWithImagesArray = fieldsArray[i].validations.optionsWithImages;
            for (let j = 0; j < value.length; j++) {
              for (let k = 0; k < optionWithImagesArray.length; k++) {
                if (optionWithImagesArray[k].value === value[j]) {
                  value[j] = optionWithImagesArray[k].id;
                }
              }
            }
          }
        }
      }
      let multiValString = '';
      if (value) {
        value.forEach(val => {
          multiValString += val + '|';
        });
      }
      multiValString = multiValString.substring(0, multiValString.length - 1);
      value = multiValString;
      filterQuery += key + '=' + '|' + condition.toUpperCase() + '|' + value;
    } else if (fieldType === 'DATE_PICKER') {
      if (value && typeof value == 'object') {
        value = value[0];
      }
      if (condition === 'is') {
        if (time && value) filterQuery += key + '=' + '|IS|' + this.timeCoversion(value, time);
        // filterQuery += key + '=' + '|IS|' + '2022-02-26T05:43:00.000Z';
        else if (value) {
          filterQuery += key + '=' + '|IS|' + moment(value).format('YYYY-MM-DD');
        }
      } else if (condition === 'is_not') {
        if (time && value) filterQuery += key + '=' + '|IS_NOT|' + this.timeCoversion(value, time);
        else if (value) {
          filterQuery += key + '=' + '|IS_NOT|' + moment(value).format('YYYY-MM-DD');
        }
      } else if (condition === 'is_greater_than') {
        if (time && value) filterQuery += key + '=' + this.timeCoversion(value, time) + '|TO|';
        else if (value) {
          filterQuery += key + '=' + moment(value).format('YYYY-MM-DD') + '|TO|';
        }
      } else if (condition === 'is_less_than') {
        if (time && value) filterQuery += key + '=' + '|TO|' + this.timeCoversion(value, time);
        else if (value) {
          filterQuery += key + '=' + '|TO|' + moment(value).format('YYYY-MM-DD');
        }
      } else if (condition === 'is_between') {
        let f: any;
        if (fromTime && from) f = this.timeCoversion(from, fromTime);
        else if (from) {
          from = this.dataStoreService.removeDatePrefix(from);
          f = moment(from).format('YYYY-MM-DD');
        }
        let t: any;
        if (toTime && to) t = this.timeCoversion(to, toTime);
        else if (to) {
          t = moment(to).format('YYYY-MM-DD');
        }
        let rangeList = [
          'today',
          'yesterday',
          'last7days',
          'last30days',
          'last90days',
          'thisweek',
          'thismonth',
          'lastmonth'
        ];
        if (selectedDateRange && rangeList.includes(selectedDateRange)) {
          const range = selectedDateRange + (type != 'saveSegment' ? '-' : '');
          filterQuery += type == 'saveSegment' ? key + '=' + range : key + '=' + range + f + '|TO|' + t;
        } else filterQuery += key + '=' + f + '|TO|' + t;
      } else {
        filterQuery += key + '=' + '|TO|' + moment(value).format('YYYY-MM-DD');
      }
    } else if (condition.toUpperCase() === 'IS_BETWEEN' && fieldType !== 'DATE_PICKER') {
      let f = from;
      let t = to;
      filterQuery += key + '=' + f + '|' + 'TO|' + t;
    } else if (condition.toUpperCase() === 'IS_GREATER_THAN' && fieldType !== 'DATE_PICKER') {
      filterQuery += key + '=' + value + '|' + 'TO' + '|';
    } else if (condition.toUpperCase() === 'IS_LESS_THAN' && fieldType !== 'DATE_PICKER') {
      filterQuery += key + '=' + '|' + 'TO' + '|' + value;
    } else if (condition.toUpperCase() === 'CONTAINS') {
      condition = 'LIKE';
      filterQuery += key + '=' + '|' + condition.toUpperCase() + '|' + value;
    } else if (condition.toUpperCase() == 'DOES_NOT_CONTAIN') {
      condition = 'NOT_LIKE';
      filterQuery += key + '=' + '|' + condition.toUpperCase() + '|' + value;
    } else {
      filterQuery += key + '=' + '|' + condition.toUpperCase() + '|' + value;
    }
    filterQuery += '&';
    return filterQuery;
  }
  public timeCoversion(value, time) {
    if (new Date(time).getFullYear()) {
      let datePipe = new DatePipe('en');
      let changedFormatTime = datePipe.transform(time, 'shortTime');
      // console.log('changedFormatTime::>', changedFormatTime);
      time = changedFormatTime;
    }
    if (!value) return;
    let userDate = moment.utc(value).format('YYYY-MM-DD');
    let fomatTime = new Date(value.split('T')[0].trim() + ' ' + time)
      .toTimeString()
      .split('GMT')[0]
      .trim();
    return userDate + 'T' + fomatTime + 'Z';
  }
  // Revisit to remove the clutter

  public convertBuildingBlocksQueryParamToFilterObject(queryParams, filterArr, fieldsArray, forExport) {
    var filterMain: FilterMainModel = new FilterMainModel();
    var filterModelData: FilterModel[] = [];
    let filterQueryArray = queryParams.split('&');
    filterQueryArray.forEach((filter: string) => {
      if (filter && !filter.includes('sortBy=') && !filter.includes('sortOrder=')) {
        filter = this.dataStoreService.replaceDateStringwithDate(filter, true);
        let filterModel: FilterModel = new FilterModel();
        let filterKey = filter.substring(0, filter.indexOf('='));
        filterModel.filterKey = filterKey;
        filterModel.filterCondition = filter
          .substring(filter.indexOf('|') + 1, filter.split('|', 2).join('|').length)
          .toLowerCase();
        if (filterModel.filterCondition === 'to' && !filterModel.filterKey.includes('.')) {
          let aa = filter.split('=')[1];
          let arr = aa.split('|TO|');
          if (arr[0] !== '' && arr[1] !== '') {
            filterModel.filterCondition = 'is_between';
            filterModel.from = arr[0];
            filterModel.to = arr[1];
          } else if (arr[0] === '' && arr[1] !== '') {
            filterModel.filterCondition = 'is_less_than';
            filterModel.filterValue = arr[1];
          } else {
            filterModel.filterCondition = 'is_greater_than';
            filterModel.filterValue = arr[0];
          }
          filterModel.filterKey = filterArr.filter(f => {
            return f['key'] === filterKey;
          })[0];
          if (!filterModel.filterKey && filterKey.includes('.')) {
            filterModel.filterKey = filterKey;
            // filterModel.filterKey = filterArr.map(f => {
            //   if(f.children) {
            //     Object.keys(f.children)
            //   }
            //   return f['key'] === filterKey;
            // })[0];
          }
        } else {
          let filterElements = filter.substring(filter.split('|', 2).join('|').length + 1, filter.length).split('|');
          filterModel.filterValue = [];
          if (!filterModel.filterKey.includes('.')) {
            filterModel.filterKey = filterArr.filter(f => {
              return f['key'] === filterKey;
            })[0];
            let filVal: any;
            if (filterModel.filterKey) {
              filVal = fieldsArray.filter(f => {
                return f['handleBar'] === filterModel.filterKey['key'];
              })[0];
            }
            var filterValueArray: any = [];
            if (filVal !== undefined && !forExport) {
              if (filVal['validations']['isImageIncluded']) {
                for (let i = 0; i < filterElements.length; i++) {
                  for (let j = 0; j < filVal['validations']['optionsWithImages'].length; j++) {
                    if (
                      filVal['validations']['optionsWithImages'][j]['id'].toString() === filterElements[i].toString()
                    ) {
                      filterValueArray.push(filVal['validations']['optionsWithImages'][j]['value']);
                    }
                  }
                }
                filterModel.filterValue = filterValueArray;
              } else {
                if (
                  filterModel.filterKey['fieldType'] == 'DATE_PICKER' &&
                  (filterModel.filterCondition == 'is' || filterModel.filterCondition == 'is_not')
                ) {
                  filterModel.filterValue = filterElements[0];
                } else {
                  filterModel.filterValue = filterElements;
                }
              }
            } else {
              if (
                filterModel.filterKey['fieldType'] == 'DATE_PICKER' &&
                (filterModel.filterCondition == 'is' || filterModel.filterCondition == 'is_not')
              ) {
                filterModel.filterValue = filterElements[0];
              } else {
                filterModel.filterValue = filterElements;
              }
            }
          } else {
            // Filters for Reference Field Type
            let bbDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
            let splitFields = filterModel.filterKey.split('.');
            let refField = null;
            let allFieldsArray = JSON.parse(JSON.stringify(fieldsArray));
            let filterArrRef = {};
            let refDisplay = '';
            for (let i = 0; i < splitFields.length; i++) {
              refField = allFieldsArray.filter(fil => {
                return fil['handleBar'] === splitFields[i];
              })[0];
              filterArrRef['displayKey'] = refDisplay;
              if (filterModel.filterCondition === 'to') {
                let aa = filter.split('=')[1];
                let arr = aa.split('|TO|');
                if (arr[0] !== '' && arr[1] !== '') {
                  filterModel.filterCondition = 'is_between';
                  filterModel.from = arr[0];
                  filterModel.to = arr[1];
                } else if (arr[0] === '' && arr[1] !== '') {
                  filterModel.filterCondition = 'is_less_than';
                  filterModel.filterValue = arr[1];
                } else {
                  filterModel.filterCondition = 'is_greater_than';
                  filterModel.filterValue = arr[0];
                }
              }
              if (refField && !refField['validations']['referenceSlug']) {
                break;
              }
              let selBB = bbDetails.filter(bb => {
                return bb['handleBar'] === refField['validations']['referenceSlug'];
              })[0];
              allFieldsArray = [];
              for (let j = 0; j < selBB['actions'].length; j++) {
                allFieldsArray.push(...selBB['actions'][j]['fields']);
              }
              let refObj = allFieldsArray.find(obj => obj.handleBar == filterKey.split('.')?.[1]);
              if (refObj?.['fieldType'] === 'SHORT_TEXT') {
                filterArrRef['condition'] = ['is', 'is_not', 'contains', 'does_not_contain'];
              } else if (refObj?.['fieldType'] === 'LONG_TEXT') {
                filterArrRef['condition'] = ['contains', 'does_not_contain'];
              } else if (refObj?.['fieldType'] === 'NUMBER') {
                filterArrRef['condition'] = ['is', 'is_not', 'is_greater_than', 'is_less_than', 'is_between'];
              } else if (refObj?.['fieldType'] === 'DateTime' || refObj?.['fieldType'] === 'DATE_PICKER') {
                filterArrRef['condition'] = ['is', 'is_not', 'is_greater_than', 'is_less_than', 'is_between'];
              } else if (refObj?.['fieldType'] === 'BOOLEAN') {
                filterArrRef['condition'] = ['is_true', 'is_false'];
              } else {
                filterArrRef['condition'] = ['is', 'is_not'];
              }
            }
            filterArrRef['fieldType'] = refField['fieldType'];
            if (refField !== undefined && !forExport) {
              filterArrRef['key'] = filterKey;
              filterModel.filterKey = filterArrRef;
              if (refField['validations']['isImageIncluded']) {
                for (let i = 0; i < filterElements.length; i++) {
                  for (let j = 0; j < refField['validations']['optionsWithImages'].length; j++) {
                    if (
                      refField['validations']['optionsWithImages'][j]['id'].toString() === filterElements[i].toString()
                    ) {
                      filterValueArray.push(refField['validations']['optionsWithImages'][j]['value']);
                    }
                  }
                }
                filterModel.filterValue = filterValueArray;
              } else if (
                (Array.isArray(filterModel.filterValue) && filterModel.filterValue.length == 0) ||
                (!Array.isArray(filterModel.filterValue) && !filterModel.filterValue)
              ) {
                filterModel.filterValue = filterElements;
              }
            } else {
              filterArrRef['key'] = filterModel.filterKey;
              filterModel.filterKey = filterArrRef;
              filterModel.filterValue = filterElements ? filterElements : filterModel.filterValue;
            }
          }
        }
        filterModelData.push(filterModel);
      }
    });
    filterMain.filters = filterModelData;
    return filterMain;
  }

  getBBHandleBar() {
    const handlerBar = this.route.snapshot.params['handleBar'];
    return handlerBar ? handlerBar : sessionStorage.getItem('handlerBar');
  }

  // Listing page date range filter default value
  getDefaultDateFilter(): any {
    return {
      fromDate: null,
      toDate: null,
      filterValue: null,
      conditionType: 'Select',
      showMultiselectWithFilterOption: false,
      isDateRangeOptionPanelOpen: false
    };
  }

  getDefaultDateFilteroption(): any {
    return {
      fromDate: null,
      toDate: null,
      filterValue: null,
      conditionType: 'Select',
      showMultiselectWithFilterOption: true,
      isDateRangeOptionPanelOpen: false
    };
  }

  // reusable method to stop propgation
  stopPropagationFunction(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  customDateRangeOut() {
    const documentBody = document.getElementsByTagName('body')[0];
    documentBody.classList.remove('overflow-date-range');
    documentBody.classList.remove('overflow-date-range-left');
  }

  toggleSaveEditPopup(
    e: MouseEvent,
    isEdit: boolean,
    segmentName: string,
    showForm: boolean
  ): { showForm: boolean; newSegmentName: string } {
    showForm = !showForm;
    const newSegmentName = isEdit ? segmentName : '';
    const savePopup = document.getElementsByTagName('BODY')[0];

    savePopup.classList.toggle('zindex');

    /* save popup set position */
    const box = e.target as HTMLElement;
    const overflowDiv = e.pageX + 390;
    const bodyWid = document.body.clientWidth;

    if (box.parentNode && box.parentNode instanceof HTMLElement) {
      const parent = box.parentNode as HTMLElement;
      if (overflowDiv > bodyWid) {
        parent.classList.add('pos-right-0');
      } else {
        parent.classList.remove('pos-right-0');
      }
    }

    return { showForm, newSegmentName };
  }

  convertToDate(dateOption: { value: string }): { fromDate: Date; toDate: Date } {
    let fromDate: Date;
    let toDate: Date;

    switch (dateOption.value) {
      case 'today':
        fromDate = new Date();
        toDate = new Date();
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .toDate()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .endOf('day')
            .toDate()
        );
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(6, 'days')
            .startOf('day')
            .toDate()
        );
        toDate = new Date();
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .toDate()
        );
        toDate = new Date();
        break;
      case 'last90days':
        fromDate = new Date(
          moment()
            .subtract(89, 'days')
            .startOf('day')
            .toDate()
        );
        toDate = new Date();
        break;
      case 'thisweek':
        fromDate = new Date(
          moment()
            .startOf('week')
            .toDate()
        );
        toDate = new Date();
        break;
      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .toDate()
        );
        toDate = new Date();
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .toDate()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .toDate()
        );
        break;
      default:
        throw new Error('Invalid date option');
    }

    return { fromDate, toDate };
  }

  getTimeDifference(start, end) {
    const sd = moment(start);
    const ed = moment(end);
    const includeToday = 1;
    return ed.diff(sd, 'days') + includeToday;
  }
}
