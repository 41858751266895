import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input() totalHits: number;
  @Input() perPage: number = 50;
  @Input() exportJob: any;
  @Input() hidePageSize?: boolean;
  @Input() pageIndex?: number;
  @Output() paginator = new EventEmitter<any>();
  removable = true;
  chiplistArr: any = [];
  @ViewChild('paginator', { static: true }) paginatorIndex: MatPaginator;
  private unSubscribeResetMat: Subscription;

  constructor(private filterHelperService: FilterHelperService) {}

  ngOnInit() {
    this.unSubscribeResetMat = this.filterHelperService.resetMatPagination.subscribe((pageIndex: number) => {
      this.paginatorIndex.pageIndex = pageIndex;
    });
  }

  paginate(event) {
    if (
      (event.pageIndex || typeof event.pageIndex == 'number') &&
      (event.pageSize || typeof event.pageSize == 'number')
    ) {
      this.paginator.emit(event);
    }
  }

  remove(chip): void {
    const index = this.chiplistArr.indexOf(chip);
    if (index >= 0) {
      this.chiplistArr.splice(index, 1);
    }
  }

  ngOnDestroy() {
    if (this.unSubscribeResetMat) this.unSubscribeResetMat.unsubscribe();
  }
}
