import { ActivityMainState } from '../state/activity-main.state';
import { ActivityMainActions, ActivityMainActionTypes } from '../actions/activity-main.action';

export const initialState: ActivityMainState = {
  loading: false,
  portalData: null
  // channelData: null
};

export function ActivityMainReducer(state = initialState, action: ActivityMainActions) {
  switch (action.type) {
    case ActivityMainActionTypes.SHOW_PROGRESS_SPINNER:
      return {
        ...state,
        loading: true
      };
    case ActivityMainActionTypes.HIDE_PROGRESS_SPINNER:
      return {
        ...state,
        loading: false
      };
    case ActivityMainActionTypes.GET_PORTAL:
      return {
        ...state
      };
    case ActivityMainActionTypes.GET_PORTAL_SUCCESS:
      return {
        ...state,
        portalData: action.payload
      };
    case ActivityMainActionTypes.GET_PORTAL_FAILURE:
      return {
        ...state,
        portalData: action.payload
      };
    // case ActivityMainActionTypes.GET_CHANNELS_LIST:
    //   return {
    //     ...state
    //   };
    // case ActivityMainActionTypes.GET_CHANNELS_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     channelData: action.payload
    //   };
    // case ActivityMainActionTypes.GET_CHANNELS_LIST_FAILURE:
    //   return {
    //     ...state,
    //     channelData: action.payload
    //   };
    default:
      return state;
  }
}

export const getActivityLoading = (state: ActivityMainState) => state.loading;
export const getPortalData = (state: ActivityMainState) => state.portalData;
// export const getChannelData = (state: ActivityMainState) => state.channelData;
