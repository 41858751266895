<div class="attach-file">
  <h4 *ngIf="!isMultiValue && columnName !== 'Attachment'">
    {{ columnName }}
    <!-- <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" /> -->
  </h4>

  <h4 *ngIf="isMultiValue">
    {{ columnName }} ({{ data.length }})
    <!-- <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" /> -->
  </h4>

  <h4 *ngIf="!isMultiValue && columnName === 'Attachment'">
    {{ columnName }} (1)
    <a>Download all</a>
    <!-- <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" /> -->
  </h4>
  <ul>
    <ng-container *ngIf="data && isMultiSelect && !isMultiValue">
      <ng-container *ngIf="!isSingleSelect">
        <span *ngFor="let item of data">
          <li>
            {{ item }}
          </li>
        </span>
      </ng-container>

      <ng-container *ngIf="isSingleSelect">
        <li>
          {{ data }}
        </li>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data && isMultiValue">
      <span *ngFor="let item of data">
        <li>
          {{ item }}
        </li>
      </span>
    </ng-container>

    <ng-container *ngIf="data && !isMultiValue && columnName === 'Attachment' && fileName !== ''">
      <li class="img-file">
        <span> <img src="{{ data }}" class="thumbnailImage" /> {{ fileName }} </span>
        <a (click)="downloadFile(data.data, fileName)">
          Download
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="columnName === 'Reference'">
      <!-- <div class="referenceDiv"> -->
      <li class="referenceHeader">{{ data['name'] }}</li>
      <li>ID : {{ data['id'] }}</li>
      <span *ngFor="let r of referenceRecordKeys">
        <li>{{ r }} : {{ data.record[r] }}</li>
      </span>
      <!-- </div> -->
    </ng-container>

    <!-- <li class="pdf-file">
      {{ fileName }} <br />
      <a (click)="downloadFile(data.data, fileName)"> Download </a>
    </li>
    <li class="zip-file">
      Listing (2).zip <br />
      <a (click)='downloadFile(data.data, fileName)'> Download </a>
    </li>
    <li class="img-file">
      iMac-4.jpg <br />
      <a (click)='downloadFile(data.data, fileName)'> Download </a>
    </li>
    <li class="xl-file">064a9b2c624074aafdsfdsfd34fffdf <br /><a> Download </a></li> -->
  </ul>
</div>
