import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ROUTESDATA } from 'src/app/constants/routes';
import { GetActivityToken } from 'src/app/auth/actions/auth.actions';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
import { User } from 'src/app/auth/auth.state';
import { AuthService, UserService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { BuildingblockService } from '../../shared/services/buildingblock.service';
import { FilterPusherService } from 'src/app/filter-widget-new/service/filter-pusher.service';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
import { BuildingBlocksService } from 'src/app/building-blocks/service/building-blocks.service';
import { environment } from 'src/environments/environment';
import { DataStoreService } from 'src/app/shared/services/data-store.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  // @ViewChild('searchValue', { static: false }) searchValueClear;
  ROUTESDATA = ROUTESDATA;
  NEWROUTES = [];
  userSubscription$: Subscription;
  user: User;
  buildingBlockDetails: any;
  buildingBlockName: string;
  selectedBuildingBlock: any;
  bbHandleBar: any;
  bbDetails: any;
  buildingBlocksName: any;
  flagForBB: boolean = false;
  workspaceStatus: any;
  workspaceName: string = '';
  customCollapsedHeight: string = '24px';
  customExpandedHeight: string = '24px';
  enterMouse: boolean;

  //loader for bb sidebar
  isBBSideBarLoader: boolean = false;
  flexAccountSlug: any;
  accountList = [];

  innerWidth: number = window.innerWidth;
  mobileWidth: number = 500;
  listInMobileView: any = [];
  selectedOptionFC: any;
  currentFacilityLabel: any;
  showDropdown: boolean;

  //new design auth section
  switchWorkspaceOpen: boolean = false;
  authOpen: boolean = false;
  allAccountList: any;
  clientWorkspaceWithFCList = [];
  searchFc: string;
  isDisplay: boolean;
  searchWorkspace: string;
  newTicketQueryParam = {
    status: '|is|New'
  };
  activeSearch: boolean = false;
  currentWorkspace: string;
  loadingWorkspace: boolean;
  collapsenav: boolean;
  fullwidth: boolean;
  expandnav: boolean;

  constructor(
    private store: Store<{ auth }>,
    public authService: AuthService,
    private router: Router,
    private buildingblockService: BuildingblockService,
    private filterPusherService: FilterPusherService,
    private FilterHelperService: FilterHelperService,
    private bbLocalStorageService: BuildingBlocksService,
    private userService: UserService,
    private dataStoreService: DataStoreService
  ) {
    this.workspaceStatus = localStorage.getItem('workspaceStatus');
    if (this.authService.getFlexSlug()) this.filterPusherService.getSegmentData('flex');
    this.getActivityToken();
    // if (this.authService.accountList.length > 0) {
    //   localStorage.setItem(
    //     'accountList',
    //     JSON.stringify(this.authService.accountList.filter(user => user['userAccountMappingStatus'] !== '2'))
    //   );
    // }
    // if (this.buildingBlockWorkspaces.includes(this.authService.getAccountSlug())) {
    if (this.authService.getFlexSlug() && window.location.pathname.includes('building-blocks')) {
      this.flagForBB = true;
      this.collapsemenu();
      const bbData = localStorage.getItem('BuildingBlockDetails');
      if (!bbData) this.setBuildingBlocksDetailsToLSArrangeSidebar();
      else {
        this.buildingBlockDetails = JSON.parse(bbData);
      }
    }
    this.isDisplay =
      environment.riskscore == false && environment.riskScoreSpace.includes(this.currentWorkspace)
        ? true
        : environment.riskscore == true
        ? true
        : false;
    // console.log(this.isDisplay,"true sidebar");
  }

  ngOnInit() {
    this.currentWorkspace = this.authService.getAccountSlug();
    this.selectedOptionFC = this.authService.getFlexSlug() ? this.authService.getFlexSlug() : '';
    this.workspaceName = this.authService.getAccountName();
    this.userSubscription$ = this.userService.getUserProfile().subscribe((result: any) => {
      if (result) {
        this.user = result;
      }
    });
    this.getCurrentWorkspaceFCs();
    this.onResize('$event');
    this.getMobileRoutes();
  }

  async getCurrentWorkspaceFCs() {
    const accountList: any = await this.authService.getAccountLists();
    this.setAccountDetails(accountList);
  }

  async setAccountDetails(accountList) {
    this.allAccountList = accountList;
    this.clientWorkspaceWithFCList = accountList.filter(
      acc => acc['userAccountMappingStatus'] !== '2' && (!acc['workspaceType'] || acc['workspaceType'] == 'client')
    );
    this.accountList = accountList?.filter(
      x => x.parentClientWorkspaceSlug && x.parentClientWorkspaceSlug == this.currentWorkspace
    );
    this.flexAccountSlug = this.authService.getFlexSlug();
    this.currentFacilityLabel =
      this.accountList?.find(acc => acc.accountSlug === this.flexAccountSlug)?.facilityLabel ?? null;
  }

  /**@description Method to Get Activity Token From Backend */
  public getActivityToken() {
    this.store.dispatch(new GetActivityToken());
    this.store.pipe(select(fromSelector.getActivityToken)).subscribe(token => {
      if (token && token['getStream']) {
        this.authService.setGetStreamToken(token['getStream']['getStreamToken']);
      }
    });
  }

  /**@description Get Product Filters Data and Store in Local Storage*/
  // public getCustomFields() {
  //   this.customfieldSubscription$ = this.productService.getCustomfield().subscribe(response => {
  //     if (response['data'].length > 0) {
  //       // console.log(response['data'].filter(k => ( k.isArchieved == true)),"savan");
  //       localStorage.setItem('customField', JSON.stringify(response['data'].filter(k => k.isArchieved != true)));
  //     }
  //   });
  // }

  public onLogout() {
    this.authService.logout();
  }

  // public checkRoute() {
  //   let route = window.location.pathname;
  //   if (route.includes('/building-blocks')) {
  //     return true;
  //   }
  //   return false;
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  public setBuildingBlocksDetailsToLSArrangeSidebar() {
    this.buildingblockService.getCoreBuildingBlockDeatils().subscribe(data => {
      if (data) {
        this.buildingblockService.getCustomGroupedBulidingBlocks().subscribe(groupdata => {
          // this.isBBSideBarLoader = true;
          // this.buildingBlockDetails = groupdata.data;
          this.makeBuildingBlockListSequence();
          this.storeBuildingBlockData(groupdata, data);
          this.buildingblockService.setUpdatedBBDetails(true);
        });
      }
    });
    // this.isBBSideBarLoader = false;
  }

  async selectedFC(flexAccountSlug) {
    // this.isBBSideBarLoader = true;
    if (this.selectedOptionFC == flexAccountSlug) return false;
    await this.authService.setFlexSlug(flexAccountSlug, true);
    // window.location.reload();
    localStorage.removeItem('BuildingBlockDetails');
    this.authService.loadInitialModule(false, true, flexAccountSlug);
  }

  public storeBuildingBlockData(groupdata, data) {
    let ar = [];
    groupdata.data.forEach(x => {
      if (x.buildingBlock != undefined && x.buildingBlock != null) {
        x.buildingBlock.forEach(y => ar.push(y));
      }
    });
    ar = [...ar, ...data.data];
    localStorage.setItem('BuildingBlockDetails', JSON.stringify(ar));
    this.bbLocalStorageService.buildingBlocksDetails = ar;
    this.buildingBlockDetails = ar;
  }
  public makeBuildingBlockListSequence() {
    if (this.buildingBlockDetails) {
      this.buildingBlockDetails.forEach((x, index) => {
        if (x.buildingBlock != undefined && x.buildingBlock != null)
          x.buildingBlock.forEach((y, index1) => {
            this.buildingBlockDetails[index]['buildingBlock'][index1].name =
              y.name.charAt(0).toUpperCase() + y.name.slice(1);
          });
      });

      this.buildingBlockDetails.sort((x, y) => {
        return x.sortKey - y.sortKey;
      });
    }
  }

  public getSelectedBuildingBlock(bbHandleBar) {
    let details;
    if (this.buildingBlockDetails) {
      details = this.buildingBlockDetails.filter(x => x.handleBar === bbHandleBar)[0];
    } else {
      const buildingBlockDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
      details = buildingBlockDetails.filter(x => x.handleBar === bbHandleBar)[0];
    }
    this.makeBuildingBlockListSequence();
    this.bbDetails = details;
    this.bbHandleBar = details.handleBar;
    this.flagForBB = true;
    this.FilterHelperService.isCalledFromSidebar = true;
    let selectSegment;
    var segmentsData = JSON.parse(localStorage.getItem('segmentsData'));

    var allBBSegments = segmentsData.filter(segment => {
      return segment[0] === 'BuildingBlocks';
    })[0];
    let allSegmentsforSelectedBuildingBlock: any = [];
    if (allBBSegments) {
      allSegmentsforSelectedBuildingBlock = allBBSegments[1].filter(segment => {
        return segment['buildingBlock'] === this.bbHandleBar;
      });
    }

    if (allSegmentsforSelectedBuildingBlock.length > 0) {
      selectSegment = allSegmentsforSelectedBuildingBlock.filter(
        seg => seg['buildingBlock'] === this.bbHandleBar && seg['filterQuery'] !== ''
      )[0]['filterQuery'];
      this.dataStoreService.navigateToRecordList(this.bbHandleBar, selectSegment);
    } else {
      this.dataStoreService.navigateToRecordList(this.bbHandleBar);
    }
    if (this.innerWidth < 900) {
      this.collapsemenu();
    }
  }

  // public checkin() {
  //   let flag = 0;
  //   if (localStorage.getItem('fullwidth') && localStorage.getItem('fullwidth') === 'true') {
  //     flag = 1;
  //   }
  //   console.count("checkin")
  //   return flag === 1 ? true : false;
  // }

  public toggleClass(baseUrl) {
    let toggle: boolean = false;
    if (this.router.url.includes(baseUrl)) {
      toggle = true;
    } else {
      toggle = false;
    }
    return toggle;
  }

  // public checkcollapsenav() {
  //   let flag = 0;
  //   if (localStorage.getItem('collapsenav') && localStorage.getItem('collapsenav') === 'true') {
  //     flag = 1;
  //   }
  //   return flag === 1 ? true : false;
  // }

  // public checkexpandnav() {
  //   let flag = 0;
  //   if (localStorage.getItem('expandnav') && localStorage.getItem('expandnav') === 'true') {
  //     flag = 1;
  //   }
  //   return flag === 1 ? true : false;
  // }

  public collapsemenu() {
    if (document.getElementsByClassName('filter-dropdown')[0] != undefined)
      document.getElementsByClassName('filter-dropdown')[0]['style'].display = '';
    this.collapsenav = false;
    this.fullwidth = true;
    this.expandnav = true;
    // localStorage.setItem('collapsenav', 'false');
    // localStorage.setItem('fullwidth', 'true');
    // localStorage.setItem('expandnav', 'true');
    document.body.classList.remove('overflow-hidden');
  }

  public expandmenu() {
    if (document.getElementsByClassName('filter-dropdown open').length > 0)
      document.getElementsByClassName('filter-dropdown open')[0]['style'].display = 'none';
    this.collapsenav = true;
    this.fullwidth = false;
    this.expandnav = false;
    // localStorage.setItem('collapsenav', 'true');
    // localStorage.setItem('fullwidth', 'false');
    // localStorage.setItem('expandnav', 'false');
  }

  ngOnDestroy() {
    if (this.userSubscription$) this.userSubscription$.unsubscribe();
  }

  async onWorkspaceSelection(workspace) {
    this.loadingWorkspace = true;
    let flexSlug: string;
    // For eshopbox user we'll get workspace permission when user select the workspace
    const isEshopOrOwner = this.authService.isEshopOrOwner();
    if (isEshopOrOwner) {
      try {
        flexSlug = this.checkFlexSlug(workspace.accountSlug);
        await this.authService.checkSessionEshopUser(workspace.accountSlug, flexSlug, true);
      } catch (error) {
        this.loadingWorkspace = false;
        return;
      }
    } else flexSlug = this.checkFlexSlug(workspace.accountSlug);
    this.authService.setWorkspace(workspace.accountSlug);
    this.authService.setWorkspaceName(workspace.workspace);
    this.authService.setIsBatching(workspace.isBatching);
    this.authService.loadInitialModule(environment.production, false, flexSlug);
  }

  checkFlexSlug(accountSlug) {
    const accountlist = this.allAccountList.filter(x => x.parentClientWorkspaceSlug == accountSlug);
    if (accountlist.length > 0) {
      this.authService.setFlexSlug(accountlist[0].accountSlug, false);
      return accountlist[0].accountSlug;
    } else {
      sessionStorage.removeItem('flexAccountSlug');
      return false;
    }
  }

  getMobileRoutes() {
    const nestedArray = Object.values(this.ROUTESDATA);
    const allRoute = [].concat(...nestedArray);
    this.NEWROUTES = allRoute.filter(
      x => x.showInMobile && (x.visibileFor == 'all' || x.visibileFor == this.currentWorkspace)
    );
  }
  focusInput(event) {
    this.activeSearch = true;
  }
}
