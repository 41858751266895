import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { metaReducers, initialReducerMap } from './app.reducer';
import { DatePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { ConsignmentService } from './shared/services/consignment.service';
import { NotificationComponent } from './components/notification/notification.component';
import { BuildingblockService } from './shared/services/buildingblock.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { fakeBackendProvider } from './shared/interceptors/httpconfig.interceptor';
import { AppMaterialModule } from './app-material/app-material.module';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { AlertComponent } from './components/alert/alert.component';
// import { InnerSidebarComponent } from './components/innersidebar/innersidebar.component';
import { SwitchWorkspaceComponent } from './components/switch-workspace/switch-workspace.component';
import { Configuration } from './constants/config';
import { tokenProvider } from './_interceptors/token.interceptor';
import { responseProvider } from './_interceptors/response.interceptor';
import { AuthEffects } from './auth/effects/auth.effects';
import { AuthReducer } from './auth/reducers/auth.reducer';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { HomeLayoutComponent } from './_layouts/home-layout/home-layout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SettingsService } from './shared/services/settings.service';
import { BadGatewayComponent } from './shared/component/bad-gateway/bad-gateway.component';
import { NgAisModule } from 'angular-instantsearch';
import { AppInstallationModule } from './app-installation/app-installation.module';
import { IntercomModule } from 'ng-intercom';
import { ActivityMainReducer } from './activity/activity-main/store/reducers/activity-main.reducer';
import { LoadingComponent } from './shared/loading/loading.component';
import { InstallAppsListReducer } from './apps-automation/store/reducers/install-app-list.reducer';
import { ProductReducer } from './products/store/reducers/product.reducer';
import { MAT_HAMMER_OPTIONS } from '@angular/material/core';
import { RecordsService } from './shared/services/records.service';
import { DataStoreService, DataStoreServicePopUp } from './shared/services/data-store.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CustomerPortalBackendService } from './shared/services/customer-portal-backend.service';
import { MobileScrollParallaxDirective } from './shared/directives/mobile-scroll-parallax.directive';
import { InstalledAppAuthorizationModule } from './app-installation/installed-app-authorization/installed-app-authorization.module';
import { SafePipeModule } from 'safe-pipe';
import { OrderReducer } from './orderV2/store/reducers/orders.reducers';
import { OrdersEffectsV2 } from './orderV2/store/effects/orders.effects';
import { DeleteScheduledComponent } from './components/delete-scheduled/delete-scheduled.component';
import { EditScheduledComponent } from './components/edit-scheduled/edit-scheduled.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProductEffects } from './products/store/effects/product.effect';
import { DraftService } from './records/services/draft.service';
import { InstallAppEffects } from './apps-automation/store/effects/install-app-list.effect';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { shippingModuleReducer } from './settings/components/shipping-mode/store/shipping-mode.reducer';
import { shippingModeEffects } from './settings/components/shipping-mode/store/shipping-mode.effects';

import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';
import { SharedModule } from './shared/shared.module';
import { SettingsReducer } from './settings/store/reducers/settings.reducer';
import { SettingsEffects } from './settings/store/effects/settings.effects';
import { LoginToDesktopComponent } from './auth/components/login-to-desktop/login-to-desktop.component';
import { SettingsSidebarComponent } from './components/settings-sidebar/settings-sidebar.component';
import { orderRoutingEffects } from './settings/components/order-routing/store/order-routing.effects';
import { ActivitiesLogComponent } from './activity/components/activities-log/activities-log.component';
import { ActivitiesDetailsPopupComponent } from './activity/components/activities-details-popup/activities-details-popup.component';
import { weightDiscrypancyEffects } from './settings/components/weight-discrepancy/pipe-service-store/store/weight-discrypancy.effects';

import { NewSegmentEffects } from './shared/component/segment-with-count/store/effects/segment.effects';
import { SegmentReducer } from './shared/component/segment-with-count/store/reducers/segment.reducers';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    TopHeaderComponent,
    AlertComponent,
    // InnerSidebarComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    BadGatewayComponent,
    SwitchWorkspaceComponent,
    LoadingComponent,
    NotificationComponent,
    FileManagerComponent,
    MobileScrollParallaxDirective,
    DeleteScheduledComponent,
    EditScheduledComponent,
    TopNavigationComponent,
    SearchFilterPipe,
    LoginToDesktopComponent,
    ActivitiesLogComponent,
    ActivitiesDetailsPopupComponent,
    SettingsSidebarComponent
  ],

  imports: [
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule,
    BrowserModule,
    CommonModule,
    AppInstallationModule,
    InstalledAppAuthorizationModule,
    AppMaterialModule,
    HttpClientModule,
    MatPaginatorModule,
    AppRoutingModule,
    NgAisModule.forRoot(),
    StoreModule.forFeature('installedAppList', InstallAppsListReducer),
    StoreModule.forRoot(initialReducerMap, { metaReducers }),
    StoreModule.forFeature('activityMain', ActivityMainReducer),
    StoreModule.forFeature('ordersState', OrderReducer),
    StoreModule.forFeature('auth', AuthReducer),
    StoreModule.forFeature('product', ProductReducer),
    StoreModule.forFeature('settings', SettingsReducer),
    StoreModule.forFeature('segment', SegmentReducer),
    // StoreModule.forFeature('shippingMode', shippingModuleReducer),
    EffectsModule.forRoot([
      AuthEffects,
      ProductEffects,
      OrdersEffectsV2,
      InstallAppEffects,
      shippingModeEffects,
      SettingsEffects,
      orderRoutingEffects,
      weightDiscrypancyEffects,
      NewSegmentEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IntercomModule.forRoot({
      appId: environment.intercom.app_id,
      updateOnRouterChange: true
    }),
    NgxMaskModule.forRoot(options),
    SafePipeModule,
    NgxMatSelectSearchModule,
    SharedModule
  ],
  providers: [
    ConsignmentService,
    SettingsService,
    BuildingblockService,
    CustomerPortalBackendService,
    RecordsService,
    Configuration,
    CookieService,
    DataStoreService,
    DataStoreServicePopUp,
    // { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    tokenProvider,
    responseProvider,
    DatePipe,
    {
      provide: MAT_HAMMER_OPTIONS,
      useValue: { cssProps: { userSelect: true } }
    },
    DraftService
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteScheduledComponent, EditScheduledComponent]
})
export class AppModule {}
