<div class="header-container">
  <div class="header-left-side">
    <!-- DASHBOARD START -->
    <a
      *ngIf="
        loggedInUserData?.role == 'owner' ||
          (loggedInUserData?.workspace_permissions &&
            loggedInUserData.workspace_permissions['manage:reports'].isEnabled == '1');
        else showLogo
      "
      [routerLink]="NEWROUTES['reports'][0].URL"
      class="brand-icon"
    >
      <img src="https://ik.imagekit.io/2gwij97w0o/eshopbox-logo.svg" />
    </a>
    <ng-template #showLogo>
      <a
        class="brand-icon"
        matTooltip="You do not have access to reports. Ask any of your team members to give the access."
        matTooltipClass="theme-tooltip nav-tooltip below"
      >
        <img src="https://ik.imagekit.io/2gwij97w0o/eshopbox-logo.svg" />
      </a>
    </ng-template>
    <!-- DASHBOARD END -->

    <!-- Inventory dropdown of header-->
    <ng-container>
      <button
        #button
        mat-button
        [matMenuTriggerFor]="inventory"
        #inventoryTrigger="matMenuTrigger"
        (mouseenter)="buttonEnter(inventoryTrigger)"
        (mouseleave)="buttonLeave(inventoryTrigger)"
        style="z-index:1050"
      >
        <span>Inventory</span>
        <img src="https://ik.imagekit.io/2gwij97w0o/Dropdown-icon.svg" />
      </button>
      <mat-menu
        #inventory="matMenu"
        direction="down"
        yPosition="below"
        class="mat-menu-label-one"
        [overlapTrigger]="false"
        [hasBackdrop]="false"
      >
        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(inventoryTrigger)" (scroll)="scrollHandler($event)">
          <ng-container
            *ngIf="
              loggedInUserData?.role == 'owner' ||
                (loggedInUserData?.workspace_permissions &&
                  loggedInUserData.workspace_permissions['manage:products'].isEnabled == '1');
              else disabledProduct
            "
          >
            <a [routerLink]="NEWROUTES['inventory'][0].URL" [queryParams]="NEWROUTES['inventory'][0].queryParams">
              <button mat-menu-item class="inner-button" [disableRipple]="true">
                <span class="dropdown-menu-container">
                  <span class="dropdown-menu-item">
                    {{ NEWROUTES['inventory'][0].name }}
                  </span>
                </span>
              </button>
            </a>
          </ng-container>
          <ng-template #disabledProduct>
            <ng-container
              *ngTemplateOutlet="noAccessTemplate; context: { $implicit: NEWROUTES['inventory'][0] }"
            ></ng-container>
          </ng-template>

          <!-- Consignments -->
          <ng-container *ngIf="showConsignmentDropdown">
            <button
              mat-menu-item
              class="inner-button"
              [disableRipple]="true"
              mat-menu-item
              #levelTwoTrigger="matMenuTrigger"
              [matMenuTriggerFor]="consignments"
            >
              <span class="dropdown-menu-container">
                <span class="dropdown-menu-item">
                  <span class="menu-text">Consignments</span>
                  <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504" />
                </span>
              </span>
            </button>
            <mat-menu
              #consignments="matMenu"
              class="mat-menu-label-two mat-menu-label-two-consignments"
              [overlapTrigger]="false"
              [hasBackdrop]="false"
            >
              <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(inventoryTrigger, levelTwoTrigger)">
                <li class="p-0" mat-menu-item>
                  <ng-container *ngFor="let item of NEWROUTES['inventory']">
                    <ng-container *ngIf="item.parent === 'Consignment'">
                      <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                    </ng-container>
                  </ng-container>
                </li>
              </span>
            </mat-menu>
          </ng-container>
          <!-- Manage inventory -->
          <li class="p-0" mat-menu-item>
            <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: MANAGE_INVENTORY }"></ng-container>
          </li>

          <!-- Remove inactive inventory -->
          <ng-container *ngIf="showRemoveInventoryDropdown">
            <button
              mat-menu-item
              class="inner-button"
              [disableRipple]="true"
              mat-menu-item
              #levelTwoTrigger="matMenuTrigger"
              [matMenuTriggerFor]="removeInventory"
            >
              <span class="dropdown-menu-container">
                <span class="dropdown-menu-item">
                  <span class="menu-text">Remove inactive inventory</span>
                  <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504" />
                </span>
              </span>
            </button>
            <mat-menu
              #removeInventory="matMenu"
              class="mat-menu-label-two mat-menu-label-two-remove-inactive-inventory"
              [overlapTrigger]="false"
              [hasBackdrop]="false"
            >
              <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(inventoryTrigger, levelTwoTrigger)">
                <li class="p-0" mat-menu-item>
                  <ng-container *ngFor="let item of NEWROUTES['inventory']">
                    <ng-container *ngIf="item.parent === 'Remove inactive inventory'">
                      <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                    </ng-container>
                  </ng-container>
                </li>
              </span>
            </mat-menu>
          </ng-container>
          <!-- Product verification -->
          <ng-container
            *ngIf="
              loggedInUserData?.role == 'owner' ||
                (loggedInUserData?.workspace_permissions &&
                  loggedInUserData.workspace_permissions['manage:products'].isEnabled == '1');
              else disabledProductVerification
            "
          >
            <a [routerLink]="NEWROUTES['inventory'][NEWROUTES.inventory.length - 1].URL">
              <button mat-menu-item class="inner-button" [disableRipple]="true">
                <span class="dropdown-menu-container">
                  <span class="dropdown-menu-item">
                    Product Verification
                  </span>
                </span>
              </button>
            </a>
          </ng-container>
          <ng-template #disabledProductVerification>
            <ng-container
              *ngTemplateOutlet="
                noAccessTemplate;
                context: { $implicit: NEWROUTES['inventory'][NEWROUTES.inventory.length - 1] }
              "
            ></ng-container>
          </ng-template>
        </span>
      </mat-menu>
    </ng-container>
    <!-- Inventory dropdown of header-->

    <!-- Orders dropdown of header-->
    <ng-container>
      <button
        #button
        mat-button
        [matMenuTriggerFor]="orders"
        #ordersTrigger="matMenuTrigger"
        (mouseenter)="buttonEnter(ordersTrigger)"
        (mouseleave)="buttonLeave(ordersTrigger)"
        style="z-index:1050"
      >
        <span>Orders</span>
        <img src="https://ik.imagekit.io/2gwij97w0o/Dropdown-icon.svg" />
      </button>
      <mat-menu
        #orders="matMenu"
        direction="down"
        yPosition="below"
        class="mat-menu-label-one"
        [overlapTrigger]="false"
        [hasBackdrop]="false"
      >
        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(ordersTrigger)" (scroll)="scrollHandler($event)">
          <ng-container
            *ngIf="
              loggedInUserData?.role == 'owner' ||
                (loggedInUserData?.workspace_permissions &&
                  loggedInUserData.workspace_permissions['manage:orders'].isEnabled == '1');
              else disabledorders
            "
          >
            <a [routerLink]="NEWROUTES['orders'][0].URL" [queryParams]="NEWROUTES['orders'][0].queryParams">
              <button mat-menu-item class="inner-button" [disableRipple]="true">
                <span class="dropdown-menu-container">
                  <span class="dropdown-menu-item">
                    {{ NEWROUTES['orders'][0].name }}
                  </span>
                </span>
              </button>
            </a>
          </ng-container>
          <ng-template #disabledorders>
            <ng-container
              *ngTemplateOutlet="noAccessTemplate; context: { $implicit: NEWROUTES['orders'][0] }"
            ></ng-container>
          </ng-template>
          <ng-container
            *ngIf="
              loggedInUserData?.role == 'owner' ||
                (loggedInUserData?.workspace_permissions &&
                  loggedInUserData.workspace_permissions['manage:bulk_orders'].isEnabled == '1');
              else disableb2bdorders
            "
          >
            <ng-container *ngIf="NEWROUTES['orders'][1].isVisible">
              <a
                [routerLink]="openNewB2bOrderComponent ? b2bRouteLink : NEWROUTES['orders'][1].URL"
                [queryParams]="openNewB2bOrderComponent ? b2bParams : NEWROUTES['orders'][1].queryParams"
              >
                <button mat-menu-item class="inner-button" [disableRipple]="true">
                  <span class="dropdown-menu-container">
                    <span class="dropdown-menu-item">
                      {{ NEWROUTES['orders'][1].name }}
                    </span>
                  </span>
                </button>
              </a></ng-container
            >
          </ng-container>
          <ng-container *ngIf="showSelfFulfiment">
            <button mat-menu-item class="inner-button inner-button-divider" [disableRipple]="true">
              <span class="dropdown-menu-divider-container">
                Self fulfilment
              </span>
            </button></ng-container
          >
          <ng-template #disableb2bdorders>
            <ng-container
              *ngTemplateOutlet="noAccessTemplate; context: { $implicit: NEWROUTES['orders'][1] }"
            ></ng-container>
          </ng-template>
          <!-- Process orders -->
          <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: CREATE_PICKLIST }"></ng-container>

          <!--Process returns -->
          <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: PROCESS_RETURN }"></ng-container>

          <!-- Process B2B orders -->
          <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: PROCESS_B2B_ORDERS }"></ng-container>
        </span>
      </mat-menu>
    </ng-container>
    <!-- Orders dropdown of header-->

    <!-- comment existing payment from -->
    <ng-container *ngIf="hideNewPaymentMenu.includes(currentWorkspace); else ShowPaymentDropdown">
      <ng-container
        *ngIf="
          loggedInUserData?.role == 'owner' ||
            (loggedInUserData?.workspace_permissions &&
              loggedInUserData.workspace_permissions['manage:payments'].isEnabled == '1');
          else disabledPayment
        "
      >
        <a
          class="nav-link"
          [routerLink]="NEWROUTES['payments'][0].URL"
          [queryParams]="NEWROUTES['payments'][0].queryParams"
        >
          <button class="navbar-list payments-link" mat-button [disableRipple]="true">
            {{ NEWROUTES['payments'][0].name }}
          </button>
        </a>
      </ng-container>

      <ng-template #disabledPayment>
        <a
          class="nav-link"
          matTooltip="You do not have access to payments. Ask any of your team members to give the access."
          matTooltipClass="theme-tooltip nav-tooltip below"
        >
          <button class="navbar-list" mat-button [disableRipple]="true">{{ NEWROUTES['payments'][0].name }}</button>
        </a>
      </ng-template>
    </ng-container>
    <!-- comment existing payment to -->

    <!-- Payment dropdown of header-->
    <ng-template #ShowPaymentDropdown>
      <button
        #button
        mat-button
        [matMenuTriggerFor]="payments"
        #paymentsTrigger="matMenuTrigger"
        (mouseenter)="buttonEnter(paymentsTrigger)"
        (mouseleave)="buttonLeave(paymentsTrigger)"
        style="z-index:1050"
      >
        <span>Payments</span>
        <img src="https://ik.imagekit.io/2gwij97w0o/Dropdown-icon.svg" />
      </button>

      <mat-menu
        #payments="matMenu"
        direction="down"
        yPosition="below"
        class="mat-menu-label-one"
        [overlapTrigger]="false"
        [hasBackdrop]="false"
      >
        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(paymentsTrigger)" (scroll)="scrollHandler($event)">
          <!-- Website -->
          <span (mouseenter)="menu2Leave(paymentsTrigger, levelTwoTrigger)" *ngIf="currentWorkspace == 'eshop'">
            <ng-container *ngFor="let item of NEWROUTES['finance']">
              <ng-container *ngIf="item.parent === 'Finance'">
                <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
              </ng-container>
            </ng-container>
          </span>
          <button
            mat-menu-item
            class="inner-button"
            [disableRipple]="true"
            #levelTwoTrigger="matMenuTrigger"
            [matMenuTriggerFor]="website"
          >
            <span class="dropdown-menu-container">
              <span class="dropdown-menu-item">
                <span class="menu-text">Website</span>
                <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504" />
              </span>
            </span>
          </button>
          <mat-menu
            #website="matMenu"
            class="mat-menu-label-two mat-menu-label-two-consignments"
            [overlapTrigger]="false"
            [hasBackdrop]="false"
          >
            <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(paymentsTrigger, levelTwoTrigger)">
              <li class="p-0" mat-menu-item>
                <ng-container>
                  <ng-container *ngFor="let item of NEWROUTES['payments']">
                    <ng-container *ngIf="item.parent === 'Website'">
                      <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </li>
            </span>
          </mat-menu>

          <!-- Marketplace -->
          <button
            mat-menu-item
            class="inner-button"
            [disableRipple]="true"
            #levelTwoTrigger="matMenuTrigger"
            [matMenuTriggerFor]="marketplace"
          >
            <span class="dropdown-menu-container">
              <span class="dropdown-menu-item">
                <span class="menu-text">Marketplace</span>
                <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504" />
              </span>
            </span>
          </button>
          <mat-menu
            #marketplace="matMenu"
            class="mat-menu-label-two mat-menu-label-two-consignments"
            [overlapTrigger]="false"
            [hasBackdrop]="false"
          >
            <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(paymentsTrigger, levelTwoTrigger)">
              <li class="p-0" mat-menu-item>
                <ng-container>
                  <ng-container *ngFor="let item of NEWROUTES['payments']">
                    <ng-container *ngIf="item.parent === 'Marketplace'">
                      <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </li>
            </span>
          </mat-menu>
        </span>
      </mat-menu>
    </ng-template>

    <!-- Payment dropdown of header-->

    <!-- Reports dropdown of header-->
    <ng-container>
      <button
        #button
        mat-button
        [matMenuTriggerFor]="reports"
        #reportsTrigger="matMenuTrigger"
        (mouseenter)="buttonEnter(reportsTrigger)"
        (mouseleave)="buttonLeave(reportsTrigger)"
        style="z-index:1050"
      >
        <span>Reports</span>
        <img src="https://ik.imagekit.io/2gwij97w0o/Dropdown-icon.svg" />
      </button>
      <mat-menu
        #reports="matMenu"
        direction="down"
        yPosition="below"
        class="mat-menu-label-one"
        [overlapTrigger]="false"
        [hasBackdrop]="false"
      >
        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(reportsTrigger)" (scroll)="scrollHandler($event)">
          <!-- Workspace reports -->
          <ng-container *ngFor="let item of workSpaceReportMenu">
            <ng-container *ngIf="item.parent === 'Reports' && item.name != 'Home'">
              <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
            </ng-container>
          </ng-container>
          <!-- Workspace reports -->

          <!-- Flex reports -->
          <ng-container *ngIf="currentWorkspace == 'eshop'">
            <button
              mat-menu-item
              class="inner-button"
              [disableRipple]="true"
              mat-menu-item
              #fulfilmentReportTrigger="matMenuTrigger"
              [matMenuTriggerFor]="processReturns"
            >
              <span class="dropdown-menu-container">
                <span class="dropdown-menu-item">
                  <span class="menu-text">Fulfilment reports</span>
                  <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504" />
                </span>
              </span>
            </button>
            <mat-menu
              #processReturns="matMenu"
              class="mat-menu-label-two mat-menu-label-two-fulfilment-reports"
              [overlapTrigger]="false"
              [hasBackdrop]="false"
            >
              <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger, fulfilmentReportTrigger)">
                <li class="p-0" mat-menu-item>
                  <!-- Inventory -->
                  <ng-container>
                    <button
                      mat-menu-item
                      class="inner-button"
                      [disableRipple]="true"
                      mat-menu-item
                      #inventoryTrigger="matMenuTrigger"
                      [matMenuTriggerFor]="inventoryReport"
                    >
                      <span class="dropdown-menu-container">
                        <span class="dropdown-menu-item">
                          <span class="menu-text"> Inventory report </span>
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504"
                          />
                        </span>
                      </span>
                    </button>

                    <mat-menu
                      #inventoryReport="matMenu"
                      class="mat-menu-label-three mat-menu-label-three-inventry"
                      [overlapTrigger]="false"
                      [hasBackdrop]="false"
                    >
                      <span
                        (mouseenter)="menu3enter()"
                        (mouseleave)="menu3Leave(reportsTrigger, fulfilmentReportTrigger, inventoryTrigger)"
                      >
                        <ng-container *ngFor="let item of NEWROUTES['reports']">
                          <ng-container *ngIf="item.parent === 'Inventory report'">
                            <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                          </ng-container>
                        </ng-container>
                      </span>
                    </mat-menu>
                  </ng-container>
                  <!-- Inventory -->
                  <!-- Orders -->
                  <ng-container>
                    <button
                      mat-menu-item
                      class="inner-button"
                      [disableRipple]="true"
                      mat-menu-item
                      #orderReportTrigger="matMenuTrigger"
                      [matMenuTriggerFor]="orderReport"
                    >
                      <span class="dropdown-menu-container">
                        <span class="dropdown-menu-item">
                          <span class="menu-text"> Order report </span>
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504"
                          />
                        </span>
                      </span>
                    </button>

                    <mat-menu
                      #orderReport="matMenu"
                      class="mat-menu-label-three mat-menu-label-three-orders"
                      [overlapTrigger]="false"
                      [hasBackdrop]="false"
                    >
                      <span
                        (mouseenter)="menu3enter()"
                        (mouseleave)="menu3Leave(reportsTrigger, fulfilmentReportTrigger, orderReportTrigger)"
                      >
                        <ng-container *ngFor="let item of NEWROUTES['reports']">
                          <ng-container *ngIf="item.parent === 'Order report'">
                            <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                          </ng-container>
                        </ng-container>
                      </span>
                    </mat-menu>
                  </ng-container>
                  <!-- Orders -->
                  <!-- Returns -->
                  <ng-container>
                    <button
                      mat-menu-item
                      class="inner-button"
                      [disableRipple]="true"
                      mat-menu-item
                      #returnReportTrigger="matMenuTrigger"
                      [matMenuTriggerFor]="returnReport"
                    >
                      <span class="dropdown-menu-container">
                        <span class="dropdown-menu-item">
                          <span class="menu-text"> Return report</span>
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504"
                          />
                        </span>
                      </span>
                    </button>

                    <mat-menu
                      #returnReport="matMenu"
                      class="mat-menu-label-three mat-menu-label-three-returns"
                      [overlapTrigger]="false"
                      [hasBackdrop]="false"
                    >
                      <span
                        (mouseenter)="menu3enter()"
                        (mouseleave)="menu3Leave(reportsTrigger, fulfilmentReportTrigger, returnReportTrigger)"
                      >
                        <ng-container *ngFor="let item of NEWROUTES['reports']">
                          <ng-container
                            *ngIf="
                              item.parent === 'Return report' &&
                              (item.showInLocation.length === 0 || item.showInLocation.includes(flexAccountSlug))
                            "
                          >
                            <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                          </ng-container>
                        </ng-container>
                      </span>
                    </mat-menu>
                  </ng-container>
                  <!-- Returns -->
                  <!-- Stock Transfer -->
                  <ng-container>
                    <button
                      mat-menu-item
                      class="inner-button"
                      [disableRipple]="true"
                      mat-menu-item
                      #stockTransferReportTrigger="matMenuTrigger"
                      [matMenuTriggerFor]="stockTransferReport"
                      *ngIf="showStockTransferReports.includes(flexAccountSlug)"
                    >
                      <span class="dropdown-menu-container">
                        <span class="dropdown-menu-item">
                          <span class="menu-text"> Stock transfer</span>
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504"
                          />
                        </span>
                      </span>
                    </button>

                    <mat-menu
                      #stockTransferReport="matMenu"
                      class="mat-menu-label-three mat-menu-label-three-returns"
                      [overlapTrigger]="false"
                      [hasBackdrop]="false"
                    >
                      <span
                        (mouseenter)="menu3enter()"
                        (mouseleave)="menu3Leave(reportsTrigger, fulfilmentReportTrigger, stockTransferReportTrigger)"
                      >
                        <ng-container *ngFor="let item of NEWROUTES['reports']">
                          <ng-container
                            *ngIf="
                              item.parent === 'Stock transfer' &&
                              (item.showInLocation.length === 0 || item.showInLocation.includes(flexAccountSlug))
                            "
                          >
                            <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
                          </ng-container>
                        </ng-container>
                      </span>
                    </mat-menu>
                  </ng-container>
                  <!-- Stock Transfer ends -->
                </li>
              </span>
            </mat-menu>
          </ng-container>
          <!-- Flex reports -->
        </span>
      </mat-menu>
    </ng-container>
    <!-- Reports dropdown of header-->

    <!-- Claims dropdown of header-->
    <ng-container>
      <button
        #button
        mat-button
        [matMenuTriggerFor]="claims"
        #claimsTrigger="matMenuTrigger"
        (mouseenter)="buttonEnter(claimsTrigger)"
        (mouseleave)="buttonLeave(claimsTrigger)"
        style="z-index:1050"
      >
        <span>Claims</span>
        <img src="https://ik.imagekit.io/2gwij97w0o/Dropdown-icon.svg" />
      </button>
      <mat-menu
        #claims="matMenu"
        direction="down"
        yPosition="below"
        class="mat-menu-label-one"
        [overlapTrigger]="false"
        [hasBackdrop]="false"
      >
        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(claimsTrigger)" (scroll)="scrollHandler($event)">
          <ng-container *ngFor="let item of NEWROUTES['claims']">
            <ng-container>
              <ng-container *ngTemplateOutlet="showMenuItem; context: { $implicit: item }"></ng-container>
            </ng-container>
          </ng-container>
        </span>
      </mat-menu>
    </ng-container>
    <!-- Claims dropdown of header-->
  </div>

  <!-- header-navigation-right-side-icons -->
  <div class="header-right-side">
    <!-- APPS START -->
    <div class="header-icons app-icon-otr">
      <ng-container
        *ngIf="
          loggedInUserData?.role == 'owner' ||
            (loggedInUserData?.workspace_permissions &&
              loggedInUserData.workspace_permissions['manage:apps'].isEnabled == '1');
          else disabledApp
        "
      >
        <a
          [matTooltip]="
            isAppDisconneted ? 'Go to Apps page to reconnect the disconnected apps' : NEWROUTES['apps'][0].name
          "
          matTooltipClass="theme-tooltip nav-tooltip below setting-tooltip"
          routerLinkActive="active"
          [routerLink]="NEWROUTES['apps'][0].URL"
          class="nav-item app"
          [queryParams]="NEWROUTES['apps'][0].queryParams"
        >
          <img src="https://ik.imagekit.io/2gwij97w0o/apps-outline-icon.svg?updatedAt=1677838011573" />
          <span *ngIf="isAppDisconneted" class="alert-apps"
            ><img src="https://ik.imagekit.io/2gwij97w0o/alert-triangle%20(1).svg?updatedAt=1715669467105"
          /></span>
        </a>
      </ng-container>
      <ng-template #disabledApp>
        <a
          matTooltip="You do not have access to apps. Ask any of your team members to give the access."
          matTooltipClass="theme-tooltip nav-tooltip below"
          class="nav-item app"
        >
          <img src="https://ik.imagekit.io/2gwij97w0o/apps-outline-icon.svg?updatedAt=1677838011573" />
        </a>
      </ng-template>
    </div>
    <!-- APPS END -->

    <!-- FILES START -->
    <div class="header-icons file-icon-nav" (mouseleave)="filesMenuClicked = false">
      <a class="nav-item file" id="files_report" (mouseover)="importmenu()">
        <img src="https://ik.imagekit.io/2gwij97w0o/files-outline-icon.svg?updatedAt=1677134978059" />
      </a>
      <div class="file-popup-container" style="position: relative;">
        <app-file-manager (filesMenuUnclicked)="filesMenuUnclicked($event)" [filesMenuClicked]="filesMenuClicked">
        </app-file-manager>
      </div>
      <app-notification></app-notification>
    </div>
    <!-- FILES END -->

    <!-- SETTINGS START -->
    <button
      class="header-icons settings-icon top-navigation-right-items"
      (click)="openSideMenu(itemToOpen)"
      matTooltipClass="theme-tooltip nav-tooltip setting-tooltip below"
      matTooltip="Settings"
    >
      <a class="nav-item setting">
        <img src="https://ik.imagekit.io/2gwij97w0o/setting-outline-icon.svg?updatedAt=1677134978078" />
      </a>
    </button>
    <!-- SETTINGS END -->

    <!-- HELP START -->
    <div class="header-icons help-icon top-navigation-right-items">
      <a (click)="openBot($event)" class="nav-item help">
        <img src="https://ik.imagekit.io/2gwij97w0o/help-outline-icon.svg?updatedAt=1677134978069" /> Help
      </a>
      <!-- <div class="navigation-menu-overlay-pane delay w310">
        <button (click)="openBot($event)" mat-menu-item class="navigation-menu-button help-menu" [disableRipple]="true">
          <a class="help-menu-item" href="https://help.eshopbox.com/" target="_blank">
            <img src="https://ik.imagekit.io/2gwij97w0o/book-open.svg" alt="" />
            Help Center
          </a>
        </button>
        <button mat-menu-item class="navigation-menu-button help-menu" [disableRipple]="true">
          <a class="help-menu-item" [routerLink]="['/tickets/view-all-tickets']" [queryParams]="{ status: '|is|New' }">
            <img src="https://ik.imagekit.io/2gwij97w0o/tickets.svg?updatedAt=1691478747707" alt="tickets" />Tickets</a
          >
        </button>
        <button mat-menu-item class="navigation-menu-button help-menu" [disableRipple]="true">
          <a class="help-menu-item" href=" https://docs.eshopbox.com/" target="_blank">
            <img src="https://ik.imagekit.io/2gwij97w0o/file-text.svg" alt="" />
            Developer docs
          </a>
        </button>
      </div> -->
    </div>
    <!-- HELP END -->
    <!-- Canny Changelog START -->
    <button
      id="canny-changelog"
      (mouseenter)="mouse(true)"
      (mouseleave)="mouse(false)"
      data-canny-changelog
      class="header-icons canny settings-icon top-navigation-right-items"
    >
      <a class="nav-item canny-item">
        <img src="https://ik.imagekit.io/2gwij97w0o/volume.svg?updatedAt=1697177369705" />
      </a>
    </button>
    <!-- Canny Changelog END -->
    <!-- USER START -->
    <div class="header-icons user-icon top-navigation-right-items" [ngClass]="!settingDropdownMenu ? ' open-menu' : ''">
      <a class="user-icon-top">
        <span>
          <i class="user">
            {{ user?.firstName ? (user?.firstName)[0] : '' }}{{ user?.lastName ? (user?.lastName)[0] : '' }}
          </i>
          <img src="https://ik.imagekit.io/2gwij97w0o/chevron-down.svg" />
        </span>
      </a>

      <!-- user-menu-overlay -->
      <div class="navigation-menu-overlay-pane">
        <ul class="proflemenu">
          <li class="nameblock">
            <div class="userimg">
              <i class="user">
                {{ user?.firstName ? (user?.firstName)[0] : (user?.email)[0]
                }}{{ user?.lastName ? (user?.lastName)[0] : '' }}
              </i>
            </div>
            <div class="username">
              <h2>
                {{ user?.firstName }} {{ user?.lastName }}
                <span> {{ workspaceName | titlecase }} </span>
              </h2>
            </div>
          </li>
          <li class="mat-menu-overlay-switch-workspace">
            <span class="inner-button-switch-workspace-single" *ngIf="clientWorkspaceList.length == 1">
              {{ workspaceName | titlecase }}
            </span>
            <button
              mat-menu-item
              class="inner-button-switch-workspace"
              *ngIf="clientWorkspaceList.length > 1"
              [disableRipple]="true"
              (keyup)="$event.preventDefault()"
              [ngClass]="workspaceSwitch ? 'open-active' : ''"
            >
              <span class="dropdown-menu-container-switch-workspace">
                <span class="dropdown-menu-item-switch-workspace">
                  <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504" />
                  <span class="dropdown-menu-item-switch-workspace-inner">{{ workspaceName | titlecase }}</span>
                </span>
              </span>
              <div class="submenu-switch-workspace-container">
                <ng-container *ngIf="clientWorkspaceList && clientWorkspaceList.length > 0; else loadingWorkspace">
                  <div class="form-box" *ngIf="clientWorkspaceList.length > 1">
                    <input
                      matInput
                      (click)="$event.stopPropagation()"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="searchWorkspace"
                      name="searchWorkspace"
                      autocomplete="off"
                      placeholder="Search workspace"
                    />
                  </div>
                  <div class="submenu-switch-workspace-container-inner">
                    <ng-container
                      *ngFor="
                        let workspace of clientWorkspaceList | searchFilter: searchWorkspace:'workspace';
                        let in = index
                      "
                    >
                      <ng-container *ngIf="in < 51">
                        <ng-container [ngSwitch]="workspace.status">
                          <!-- Account under review -->
                          <ng-container *ngSwitchCase="'0'">
                            <button
                              disabled
                              mat-menu-item
                              class="switch-workspace-button disabled"
                              [disableRipple]="true"
                            >
                              <span class="sw-text-container mr-left-52">
                                <span class="sw-name ellipsis-name">{{ workspace?.workspace }} </span
                                ><span class="user-status under-review">Under review</span>
                              </span>
                            </button>
                          </ng-container>

                          <!--Workspace is Inactive -->
                          <ng-container *ngSwitchCase="'2'">
                            <button
                              disabled
                              mat-menu-item
                              class="switch-workspace-button disabled"
                              [disableRipple]="true"
                            >
                              <span class="sw-text-container mr-left-52">
                                <span class="sw-name ellipsis-name">{{ workspace?.workspace }} </span
                                ><span class="user-status inactive">Inactive</span>
                              </span>
                            </button>
                          </ng-container>

                          <!--Workspace is Active -->
                          <ng-container *ngSwitchDefault>
                            <button
                              [disabled]="currentWorkspace == workspace.accountSlug"
                              (click)="onWorkspaceSelection(workspace)"
                              mat-menu-item
                              class="switch-workspace-button"
                              [disableRipple]="true"
                            >
                              <span class="sw-icon" *ngIf="currentWorkspace == workspace.accountSlug"></span>
                              <span
                                class="sw-text-container"
                                [ngClass]="{ 'mr-left-52': currentWorkspace !== workspace.accountSlug }"
                              >
                                <span class="sw-name">{{ workspace?.workspace }} </span>
                              </span>
                            </button>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #loadingWorkspace>
                  <div class="spiner-center">
                    <mat-spinner [diameter]="38" [strokeWidth]="3.5"></mat-spinner>
                  </div>
                </ng-template>
              </div>
            </button>
          </li>
          <!-- <li class="notification-settings">
            <a routerLink="/notification-setting">
              <img src="https://ik.imagekit.io/2gwij97w0o/bell.svg?updatedAt=1678706205841" />
              <span> Notification settings</span>
            </a>
          </li> -->
          <li class="policy">
            <a [routerLink]="NEWROUTES['activity'][0].URL">
              <img src="https://ik.imagekit.io/2gwij97w0o/Activity%20icon.svg?updatedAt=1725340489831" />
              <span>Activity</span>
            </a>
          </li>
          <li class="policy">
            <a href="https://help.eshopbox.com/en/collections/2441904-eshopbox-terms-and-policies" target="_blank">
              <img src="https://ik.imagekit.io/2gwij97w0o/file-text_icon.svg" />
              <span>Terms and Policies</span>
            </a>
          </li>
          <li (click)="redirectToCanny()" class="policy" id="feedback">
            <a>
              <img src="https://ik.imagekit.io/2gwij97w0o/message-square.svg?updatedAt=1686290963296" />
              <span>Suggest a feature</span>
            </a>
          </li>
          <!-- <li
            *ngIf="userEmail && userEmail.includes('@eshopbox.com')"
            class="bug-report"
            id="bug"
            (click)="submitIssue()"
          >
            <a>
              <img src="https://ik.imagekit.io/2gwij97w0o/report-bug_icon.svg" />
              <span> Report a bug</span>
            </a>
          </li> -->
          <li class="divider"></li>
          <li class="logout">
            <a (click)="onLogout()">
              <img src="https://ik.imagekit.io/2gwij97w0o/logout_icon.svg" />
              <span>Logout </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- USER END -->
  </div>
</div>

<ng-container *ngIf="isLoader">
  <div class="matloader top-navigation">
    <mat-spinner [diameter]="25" [strokeWidth]="2.2" class="centre-align"></mat-spinner>
    <span>Loading...</span>
  </div>
</ng-container>

<!-- CUSTOM TEMPLATE FOR ALL CASES OF RBAC AND SF LOCATION CREATED FOR REUSABILITY -->

<ng-template #showMenuItem let-item>
  <ng-container
    *ngIf="
      !item.hiddenInWorkspace.includes(currentWorkspace) &&
      (item.visibileFor.includes('all') || item.visibileFor.includes(currentWorkspace))
    "
  >
    <!-- WORKSPACE NAVIGATION ITEMS -->
    <ng-container *ngIf="item.urlType == 'WORKSPACE'">
      <ng-container *ngIf="item.hasAccess">
        <ng-container *ngTemplateOutlet="hasWSAccessTemplate; context: { $implicit: item }"></ng-container>
      </ng-container>
      <ng-container *ngIf="!item.hasAccess">
        <ng-container *ngTemplateOutlet="noAccessTemplate; context: { $implicit: item }"></ng-container>
      </ng-container>
    </ng-container>

    <!-- FLEX NAVIGATION ITEMS -->
    <ng-container *ngIf="item.urlType == 'FLEX'">
      <ng-container *ngIf="hasLocation">
        <ng-container *ngIf="hasLocationAccess && item.hasAccess">
          <ng-container *ngTemplateOutlet="hasSFAccessTemplate; context: { $implicit: item }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!hasLocationAccess || !item.hasAccess">
          <ng-container *ngTemplateOutlet="noAccessTemplate; context: { $implicit: item }"></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!hasLocation">
        <ng-container *ngTemplateOutlet="noSelfFulfilmentLocationTemplate; context: { $implicit: item }"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- HAS_ACCESS: If user has access to the workspace navigation -->
<ng-template #hasWSAccessTemplate let-routeData>
  <ng-container *ngIf="routeData.isVisible">
    <a [routerLink]="routeData.URL" [queryParams]="routeData.queryParams">
      <button mat-menu-item class="inner-button" [disableRipple]="true">
        <span class="dropdown-menu-container">
          <span class="dropdown-menu-item">{{ routeData.name }}</span>
        </span>
      </button>
    </a>
  </ng-container>
</ng-template>

<!-- HAS_ACCESS: If user has access to the self-fulfilment location -->
<ng-template #hasSFAccessTemplate let-routeData>
  <ng-container *ngIf="routeData.isVisible">
    <button mat-menu-item class="inner-button" [disableRipple]="true" (click)="selectedFC(routeData.URL)">
      <span class="dropdown-menu-container">
        <span class="dropdown-menu-item"> {{ routeData.name }}</span>
      </span>
    </button></ng-container
  >
</ng-template>

<!-- NO_SELF_FULFILMENT_LOCATION_IN_WORKSPACE:  If the current workspace have no location enabled for self fulfilment -->
<ng-template #noSelfFulfilmentLocationTemplate let-routeData>
  <ng-container *ngIf="routeData.isVisible">
    <button
      matTooltip="You do not have any location enabled for self fulfilment. To use this feature, you need to enable self fulfilment for a location."
      matTooltipPosition="after"
      [matTooltipClass]="
        routeData.scope == 'assets'
          ? 'theme-tooltip nav-tooltip before'
          : routeData.parent == 'Settings'
          ? 'theme-tooltip nav-tooltip before'
          : 'theme-tooltip nav-tooltip after'
      "
      mat-menu-item
      class="inner-button"
      [disableRipple]="true"
      [routerLink]="['/enabling-self-fulfilment']"
    >
      <span class="dropdown-menu-container">
        <span class="dropdown-menu-item">
          <span class="menu-text"> {{ routeData.name }}</span>
          <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/enable-icon.svg?updatedAt=1682936800414" class="mr5" />
        </span>
      </span></button
  ></ng-container>
</ng-template>

<!-- NO_SELF_FULFILMENT_LOCATION_ACCESS: If the current workspace have a location enabled for self fulfilment but the current user doesn’t have the access to that location -->
<ng-template #noAccessTemplate let-routeData>
  <ng-container *ngIf="routeData.isVisible">
    <button
      matTooltip="You do not have access to {{
        routeData.scopeLabel
      }}. Ask any of your team members to give the access."
      matTooltipPosition="after"
      [matTooltipClass]="
        routeData.scope == 'assets'
          ? 'theme-tooltip nav-tooltip before'
          : routeData.parent == 'Settings'
          ? 'theme-tooltip nav-tooltip before'
          : 'theme-tooltip nav-tooltip after'
      "
      mat-menu-item
      class="inner-button"
      [disableRipple]="true"
    >
      <span class="dropdown-menu-container">
        <span class="dropdown-menu-item disabled-button">
          <span class="menu-text"> {{ routeData.name }}</span>
          <img src="https://ik.imagekit.io/2gwij97w0o/top_nav/lock.svg?updatedAt=1683009560215" class="mr5" />
        </span>
      </span>
    </button>
  </ng-container>
</ng-template>

<!-- CUSTOM TEMPLATE FOR ALL CASES OF RBAC AND SF LOCATION CREATED FOR REUSABILITY -->
