import { createSelector } from '@ngrx/store';
import * as fromReducer from './weight-discrypancy.reducer';
// import { wdState } from  './weight-discrypancy.reducer';
import { AppState } from 'src/app/app.state';

export const getWdState = (state: AppState) => state.wdState;
export const getWdListALL = createSelector(getWdState, fromReducer.getWdList);
export const getLoader = createSelector(getWdState, fromReducer.getLoaderstart);
export const getLoad = createSelector(getWdState, fromReducer.getLoaderend);
export const getError = createSelector(getWdState, fromReducer.getError);
export const getstate = createSelector(getWdState, fromReducer.getstate);
export const getItemById = id =>
  createSelector(getWdListALL, allItems => {
    if (allItems) {
      return allItems.find(item => {
        return item.id === id;
      });
    } else {
      return {};
    }
  });
