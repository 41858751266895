import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as ActionList from '../actions/install-app-list.action';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChannelsService } from 'src/app/shared/services/channels.service';
import { InstalledAppListService } from 'src/app/apps-automation/service/installed-app-list.service';

@Injectable()
export class InstallAppEffects {
  constructor(
    private actions$: Actions,
    private installedAppListService: InstalledAppListService,
    private channelService: ChannelsService
  ) {}

  @Effect()
  getInstalledAppList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetInstallAppList>(ActionList.InstallAppListActionsTypes.GET_INSTALL_APP_LIST),
    mergeMap(action => {
      return this.installedAppListService.getInstalledAppList(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_INSTALL_APP_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_INSTALL_APP_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getConnectionByIdDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetConnectionByIdDetail>(ActionList.InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL),
    mergeMap(action => {
      return this.installedAppListService.getConnectionbyId(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL__SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL__FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
  //EFFECT CONTENTFULL API
  @Effect()
  getContentfullByIdDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetContentByIdDetail>(ActionList.InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL),
    mergeMap(action => {
      return this.installedAppListService.getContentFulDetails(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL__SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL__FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
  // EFFECT STEP LIST API
  @Effect()
  getStepListDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetStepListdDetail>(ActionList.InstallAppListActionsTypes.GET_STEPLIST_DETAIL),
    mergeMap(action => {
      return this.installedAppListService.getSteplist(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_STEPLIST_DETAIL__SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_STEPLIST_DETAIL__FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getCatogryCountDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetCatogryCountList>(ActionList.InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST),
    mergeMap(action => {
      return this.installedAppListService.getSyncJobCatlog(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getNykaaProductSyncDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetNykaaProductSync>(ActionList.InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC),
    mergeMap(action => {
      return this.installedAppListService.getNykaaProductSync().pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getInstalledAppListDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetInstallAppListDetail>(ActionList.InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL),
    mergeMap(action => {
      return this.installedAppListService.getInstalledAppListDetail(action.listId).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
  @Effect()
  getFullFilmentCenterList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetFullfilmnetCenterList>(ActionList.InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST),
    mergeMap(action => {
      // console.log(action.payload)
      return this.installedAppListService.getAllFullfilments().pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getChannelListById$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetChannelListById>(ActionList.InstallAppListActionsTypes.GET_CHANNEL_LIST),
    mergeMap(action => {
      //  console.log(action.payload)
      return this.installedAppListService.getChannelsbyId(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_CHANNEL_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_CHANNEL_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getFieldPageLIst$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetFieldPageList>(ActionList.InstallAppListActionsTypes.GET_FIELD_PAGE_LIST),
    mergeMap(action => {
      return this.installedAppListService.getFieldPage(action.payload).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_FIELD_PAGE_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_FIELD_PAGE_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
  @Effect()
  getDisconnectIdDetail$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetDisconnectByIdDetail>(ActionList.InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL),
    mergeMap(action => {
      return this.installedAppListService.onDiconnection(action.payload, action.payloadDetail).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  uninstallApp$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.UninstallApp>(ActionList.InstallAppListActionsTypes.UNINSTALL_APP),
    mergeMap(action => {
      return this.installedAppListService.uninstallApp(action.appInstallationId).pipe(
        map(data => ({
          type: ActionList.InstallAppListActionsTypes.UNINSTALL_APP_SUCCESS,
          payload: action.title
        })),
        catchError(error =>
          of({
            type: ActionList.InstallAppListActionsTypes.UNINSTALL_APP_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
}
