import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SocketService {
  PUSHER_SECRET_KEY = environment.pusher.PUSHER_SECRET_KEY;
  CHANNEL = environment.pusher_import.pusher_import_channel;
  EVENT = environment.pusher_import.pusher_import_event;
  pusher: any;
  channel: any;
  importJob: Subject<any> = new Subject<any>();

  constructor(private authService: AuthService) {
    this.on();
    this.listen();
  }

  on() {
    this.pusher = this.authService.pusher;
    this.channel = this.pusher.subscribe(this.CHANNEL);
  }

  listen() {
    this.channel.bind(this.EVENT, response => {
      this.importJob.next(response);
    });
  }

  getImportJobFeed(): Observable<any> {
    return this.importJob.asObservable();
  }
}
