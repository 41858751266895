import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'uninstall-dialog',
  templateUrl: './uninstall-dialog.component.html',
  styleUrls: ['./uninstall-dialog.component.scss']
})
export class UninstallDialogComponent implements OnInit {
  // @Input() appData: any;
  // @Input() showDelete = false;
  // @Input() isAutomationContent;
  isAutomationContent = true;
  showDelete = false;
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public appData: any) {}

  ngOnInit() {}
  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
