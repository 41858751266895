import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

interface QUERY_PARAMS_DATA {
  handlebar: string;
  fieldHandleBar: string;
  fieldType: string;
  fieldDetails: string;
  mode: 'ADD' | 'EDIT';
}

@Component({
  selector: 'app-field-details-form',
  templateUrl: './field-details-form.component.html',
  styleUrls: ['./field-details-form.component.scss', './form-styles.scss']
})
export class FieldDetailsFormComponent implements OnInit {
  public AuthorizationToken: string = null;
  public static readonly FIELD_TYPES: string[] = [
    'SHORT_TEXT',
    'LONG_TEXT',
    'NUMBER',
    'BOOLEAN',
    'DATE_PICKER',
    'ATTACHMENT',
    'REFERENCE',
    'SINGLE_SELECT',
    'MULTI_SELECT'
  ];
  public mode: 'ADD' | 'EDIT' = 'ADD';
  public buildingBlockHandleBar: string = null;
  public fieldDetailsData: any = null;
  public fieldTypeDisplay: string = null;
  private fieldType: string = null;
  public fieldDetails: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    handleBar: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_]*$')]),
    helpText: new FormControl(''),
    hasMultipleValue: new FormControl(false),
    options: new FormArray([new FormControl(''), new FormControl('')]),
    validations: new FormGroup({
      isRequired: new FormControl(false),
      isEditable: new FormControl(false),
      isHidden: new FormControl(false),
      isUnique: new FormControl(false),
      acceptSpecificPattern: new FormControl(false),
      patternType: new FormControl(''),
      format: new FormControl(''),
      hasCustomLength: new FormControl(''),
      minLength: new FormControl(''),
      maxLength: new FormControl('')
    })
  });
  public payLoad: any = {
    isCore: '|is|False'
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
    this.setInitialValues();
  }

  ngOnInit() {
    if (this.mode === 'EDIT') this.fieldDetails.get('handleBar').disable({ onlySelf: true });

    console.log('Validations', this.fieldDetails.get('validations'));

    this.fieldDetails.valueChanges.subscribe(fieldDetails => {
      this.addOrRemoveMultipleValuesFields(fieldDetails);
    });
  }

  setInitialValues() {
    this.AuthorizationToken = this.authService.getAuthToken();
    this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;

    this.activatedRoute.queryParams.subscribe((queryParamsData: QUERY_PARAMS_DATA) => {
      this.buildingBlockHandleBar = queryParamsData.handlebar;
      this.fieldDetailsData = JSON.parse(queryParamsData.fieldDetails);
      this.fieldType = queryParamsData.fieldType;
      this.fieldTypeDisplay = queryParamsData.fieldType.split('_').join(' ');
      this.fieldDetailsData ? (this.mode = 'EDIT') : (this.mode = 'ADD');
    });
  }

  addOrRemoveMultipleValuesFields(fieldDetails) {
    fieldDetails.hasMultipleValues
      ? this.fieldDetails.addControl('limitNumberOfValues', new FormControl(false))
      : this.fieldDetails.removeControl('limitNumberOfValues');

    fieldDetails.limitNumberOfValues
      ? this.fieldDetails.addControl('multipleValueRange', new FormControl(false, [Validators.required]))
      : this.fieldDetails.removeControl('multipleValueRange');

    if (fieldDetails.multipleValueRange === 'Between') {
      this.fieldDetails.addControl('multipleValueRangeMin', new FormControl('', [Validators.required]));
      this.fieldDetails.addControl('multipleValueRangeMax', new FormControl('', [Validators.required]));
    } else if (fieldDetails.multipleValueRange === 'Greater') {
      this.fieldDetails.removeControl('multipleRangeValueMax');
      if (this.fieldDetails.contains('multipleValueRangeMin'))
        this.fieldDetails.addControl('multipleValueRangeMin', new FormControl('', [Validators.required]));
    } else if (fieldDetails.multipleValueRange === 'Lesser') {
      this.fieldDetails.removeControl('multipleRangeValueMin');
      if (this.fieldDetails.contains('multipleValueRangeMax'))
        this.fieldDetails.addControl('multipleValueRangeMax', new FormControl('', [Validators.required]));
    }
  }

  setValidations() {
    switch (this.fieldType) {
      case 'SHORT_TEXT':
        if (this.fieldDetails.get('acceptSpecificPattern').value) {
          this.fieldDetails.get('patternType').setValidators([Validators.required]);
          this.fieldDetails.get('format').setValidators([Validators.required]);
        } else {
          this.fieldDetails.get('patternType').clearValidators();
          this.fieldDetails.get('format').clearValidators();
        }

        if (this.fieldDetails.get('hasCustomLength').value) {
          this.fieldDetails
            .get('minLength')
            .setValidators([Validators.required, Validators.min(0), Validators.max(256)]);
          this.fieldDetails
            .get('maxLength')
            .setValidators([Validators.required, Validators.min(1), Validators.max(256)]);
        } else {
          this.fieldDetails.get('minLength').clearValidators();
          this.fieldDetails.get('maxLength').clearValidators();
        }
    }
  }

  goBack() {
    this.router.navigate(['/building-blocks/field-setting'], {
      queryParams: { handleBar: this.buildingBlockHandleBar }
    });
  }

  ShowHideButton() {}
}
