/**
 * Sort by type list
 */
export const SORTBYTYPELIST: any = [
  { label: 'Newest to oldest', value: 'newest_to_oldest' },
  { label: 'Oldest to newest', value: 'oldest_to_newest' },
  { label: 'Pending to complete', value: 'pending_to_complete' },
  { label: 'Complete to pending', value: 'complete_to_pending' },
  { label: 'Qty high to low', value: 'qty_high_to_low' },
  { label: 'Qty low to high', value: 'qty_low_to_high' }
];

/**
 * Document type list
 */
export const DOCUMENTTYPELIST: any = [
  { label: 'Invoice', value: 'inv' },
  { label: 'Stock Transfer/ Challan', value: 'stc' }
];

/**
 * Pagination options
 */
export const PAGINATIONOPTIONLIST: number[] = [10, 25, 50, 100];

/**
 * Consignment default parameter list
 */
export const CONSIGNMENT_DEFAULT_PARAM_LIST: any = {
  page: 1,
  perPage: 50,
  // sort_by: 'updated_at',
  // sort_order: 'desc',
  type: 'inward'
};

/**
 * Recall status list
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export const RECALL_STATUS_LIST: any = [
  // { label: 'Pending', value: 'PENDING' },
  { label: 'Created', value: 'CREATED' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Packed', value: 'PACKED' },
  { label: 'Dispatched', value: 'DISPATCHED' },
  { label: 'Canceled', value: 'CANCELLED' }
];

export const REMOVAL_STATUS_LIST: any = [
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Packed', value: 'PACKED' },
  { label: 'Dispatched', value: 'DISPATCHED' }
];

/**
 * Sort by type list - Recall consignment
 */
export const RECALL_SORT_BY_TYPE_LIST: any = [
  { label: 'Newest to Oldest', value: 'dateDescending' },
  { label: 'Oldest to Newest', value: 'dateAscending' },
  { label: 'Created to Dispatched', value: 'statusAscending' },
  { label: 'Dispatched to Created', value: 'statusDescending' },
  { label: 'Qty high to low', value: 'qtyDescending' },
  { label: 'Qty low to high', value: 'qtyAscending' }
];

/**
 * Default query parameter list for Recall consignment listing page
 */
export const DEFAULT_RECALL_LIST_PARAMETERS: any = {
  page: 1,
  perPage: 50,
  type: 'recall',
  sortBy: 'dateDescending'
};
export const DEFAULT_RECALL_LIST_NON_SELLABLE_PARAMETERS: any = {
  page: 1,
  perPage: 50,
  type: 'recall',
  sortBy: 'dateDescending',
  inventoryType: 'nonSellable'
};

export const DEFAULT_REMOVALS_LIST_PARAMETERS: any = {
  page: 1,
  perPage: 50,
  type: 'recall',
  sortBy: 'dateDescending',
  inventoryType: 'nonsellable'
};

/**
 * Master recall inventory type list
 */
export const RECALL_INVENTORY_TYPE_LIST: any = [
  { label: 'Sellable', value: 'GOOD' },
  { label: 'Non sellable inwards', value: 'Non sellable inwards' },
  { label: 'Overage', value: 'Overage inwards' },
  { label: 'Non sellable returns', value: 'Non sellable returns' },
  // { label: 'Other brand returns', value: 'Incorrect returns (other brand)' },
  { label: 'Non sellable transfer items', value: 'non-sellable transfer items' },
  { label: 'Non sellable batch items', value: 'Non sellable batch items' }
];

/**
 * Custom date filter
 *
 * 1. 30 days selection
 * 2. 90 days selection
 */
export const CURRENT_DATE = new Date(new Date().setHours(0, 0, 0, 0));
export const DATE_PRE_30_DAYS = new Date(new Date().setDate(CURRENT_DATE.getDate() - 30));
export const DATE_PRE_90_DAYS = new Date(new Date().setDate(CURRENT_DATE.getDate() - 90));

export const CUSTOM_FILTER_30_DAYS_SELECTION = (d: Date): boolean => {
  const day = new Date(d);
  return day <= CURRENT_DATE && day > DATE_PRE_30_DAYS;
};
export const CUSTOM_FILTER_90_DAYS_SELECTION = (d: Date): boolean => {
  const day = new Date(d);
  return day <= CURRENT_DATE && day > DATE_PRE_90_DAYS;
};
