import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-upload-status',
  templateUrl: './upload-status.component.html',
  styleUrls: ['./upload-status.component.scss']
})
export class UploadStatusComponent {
  @Output() refresh = new EventEmitter();
  @Input() status: any;
  @Input() localFileInfo: any;

  callAPI() {
    this.refresh.emit(true);
  }
}
