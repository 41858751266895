<div class="otr-container-home-page">
  <ng-container *ngIf="showTopNav">
    <div class="header-top-popup-otr" *ngIf="isDisconnected && shouldShowNotification()">
      <div class="header-top_popup_innr">
        <div class="header-left-side">
          <span
            ><img src="https://ik.imagekit.io/2gwij97w0o/Packaging/alert-triangle.svg?updatedAt=1707909904407" alt=""
          /></span>
          <div class="heading-popup">
            <h2 *ngIf="isSingle === true">{{ appName }} is disconnected</h2>
            <h2 *ngIf="isSingle === false">Multiple Apps are disconnected with your workspace</h2>
            <p *ngIf="isSingle === true">
              Eshopbox is not able to connect with your {{ appName }} seller account. To avoid operational issues,
              please reconnect your app.
            </p>
            <p *ngIf="isSingle === false">
              Eshopbox is unable to connect with your multiple seller accounts. To avoid operational issues, go to Apps
              page and reconnect your apps. <u (click)="learnMoreReconnect()" class="reconnect-lrn">Learn more</u>
            </p>
          </div>
        </div>
        <div class="header-right-side">
          <a
            href
            class="connect_btn"
            [routerLink]="!isSingle ? ['/installed-app-list/view'] : ['/installed-app-list/detail/', appInstallationId]"
            [queryParams]="!isSingle ? { filter: 'allApps' } : { connectionId: connectionId }"
            >Reconnect</a
          >
          <a class="close" (click)="closeDisconnectNoti()"
            ><img
              src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_icon.svg?updatedAt=1626337817356"
          /></a>
        </div>
      </div>
    </div>
    <top-navigation (routesData)="setRoutesData($event)" (openSidebarMenu)="openSidebarMenu($event)"></top-navigation>

    <ng-container *ngIf="settingSidebar">
      <app-settings-sidebar></app-settings-sidebar>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showSidebar">
    <app-sidebar></app-sidebar>
  </ng-container>

  <div [ngClass]="{ setting_sidebar: settingSidebar }">
    <router-outlet></router-outlet>
  </div>
</div>
