import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersState } from 'src/app/orderV2/store/state/orders.state';
import { GetMemberListV2 } from 'src/app/orderV2/store/actions/orders.action';
import { ReplaySubject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromSelectorV2 from 'src/app/orderV2/store/selectors/orders.selectors';
import * as fromAppsSelector from 'src/app/apps-automation/store/selectors/install-app-list.selector';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { GetInstallAppList } from 'src/app/apps-automation/store/actions/install-app-list.action';
import { InstallAppListState } from 'src/app/apps-automation/store/state/install-app-list.state';
import { AuthService } from 'src/app/shared/services';
import { MatOption } from '@angular/material/core';
import { isArray } from 'rxjs/internal/util/isArray';

@Component({
  selector: 'app-order-schedule',
  templateUrl: './order-schedule.component.html',
  styleUrls: ['./order-schedule.component.scss']
})
export class OrderScheduleComponent implements OnInit {
  @Output() scheduleJob = new EventEmitter();
  @Input() jobType: any;
  loaderSchedule: boolean = false;
  scheduleForm: FormGroup;
  submitted: boolean = false;
  getMemberListSubscription$: Subscription;
  exportTemplateSubscription$: Subscription;
  searchSelectBox = new FormControl();
  public filteredKeyList: ReplaySubject<any> = new ReplaySubject<any>(1);
  membersList = [];
  selectedColsLength: any = 0;
  unselected: boolean = false;
  loader: boolean = false;
  appsLoaded: boolean = false;
  requestBody = {
    report: '',
    interval: {},
    dateRange: {},
    filter: {},
    reportDelivery: []
  };
  interval = {
    every: ['day', 'week', 'month'],
    week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    month: Array.from({ length: 29 }, (_, i) => i + 1),
    hour: Array.from({ length: 12 }, (_, i) => i + 1).map(res => (res < 10 ? '0' + res : res)),
    minutes: Array.from({ length: 60 }, (_, i) => i).map(res => (res < 10 ? '0' + res : res)),
    // minutes: ['00', '15', '30', '45'],
    format: ['AM', 'PM'],
    dateRange: [
      { key: 'None', value: 'none' },
      { key: 'Today', value: 'today' },
      { key: 'Previous day', value: 'yesterday' },
      { key: 'Last 7 Days', value: 'last_7_days' },
      { key: 'Last 30 Days', value: 'last_30_days' },
      { key: 'Last 90 Days', value: 'last_90_days' },
      { key: 'This month', value: 'this_month' },
      { key: 'Last month', value: 'last_month' }
    ],
    dateRangeCons: [
      { key: 'None', value: 'none' },
      { key: 'Today', value: 'today' },
      { key: 'Previous day', value: 'yesterday' },
      { key: 'Last 7 Days', value: 'last_7_days' },
      { key: 'Last 30 Days', value: 'last_30_days' },
      // Future scope
      // { key: 'Last 90 Days', value: 'last_90_days' },
      { key: 'This month', value: 'this_month' },
      { key: 'Last month', value: 'last_month' }
    ],
    paymentRecReportDateRange: [
      { key: 'Today', value: 'today' },
      { key: 'Yesterday', value: 'yesterday' },
      { key: 'Last 7 Days', value: 'last_7_days' },
      { key: 'Last 30 Days', value: 'last_30_days' },
      { key: 'Last 90 Days', value: 'last_90_days' },
      { key: 'This week', value: 'this_week' },
      { key: 'This month', value: 'this_month' },
      { key: 'Last month', value: 'last_month' }
    ]
  };
  activeFtpAppsConnections: any = [];
  isSendReportToFTP: boolean = false;
  public currentRoute: string = '';
  accountslug: string;
  enrolledFCData: any = [];
  filteredEnrolledFCData: any = [];
  portalData: any;
  @ViewChild('allSelected') private allSelected: MatOption;
  accountLocationSubscription$: Subscription;
  accountLists: any;
  isSelDateErr: boolean;

  constructor(
    private fb: FormBuilder,
    private store: Store<{ ordersState: OrdersState }>,
    private router: Router,
    private scroller: ViewportScroller,
    private authService: AuthService,
    private appsStore: Store<{ installedAppList: InstallAppListState }>
  ) {
    this.getFTPAppDetails();
  }

  ngOnInit() {
    this.accountslug = this.authService.getAccountSlug();
    this.createScheduleForm();
    this.getTeamMembers();
    this.searchSelectBox.valueChanges.subscribe(() => {
      this.filterKeys();
    });

    this.enrolledFCData = localStorage.fulfillmentCenter
      ? this.sortData(JSON.parse(localStorage.fulfillmentCenter), 'warehouseName')
      : [];
    this.filteredEnrolledFCData = [...this.enrolledFCData];
    this.portalData = localStorage.getItem('portalDetails') ? JSON.parse(localStorage.getItem('portalDetails')) : [];
    this.getAccountAndWarehouseData();
  }
  /**@description Method to Sort array Data Alphabetically Based on provided key */
  public sortData(data, sortingBasedOnKey: string) {
    return data.sort((a, b) => {
      return a[sortingBasedOnKey] > b[sortingBasedOnKey] ? 1 : -1;
    });
  }
  /** @description Create Schedule Form */
  public createScheduleForm() {
    this.scheduleForm = this.fb.group({
      reportName: ['', Validators.required],
      members: ['', Validators.required],
      every: ['day'],
      day: [''],
      hour: ['09'],
      minutes: ['00'],
      format: ['AM'],
      ftpConnectionId: ['', Validators.required],
      ftpDirectoryPath: ['', Validators.required],
      qcStatusCtrl: [''],
      locationFilterFormData: [],
      sellingParty: []
    });
    this.scheduleForm.get('ftpConnectionId').disable();
    this.scheduleForm.get('ftpDirectoryPath').disable();
  }

  /**@description Get FTP Server Details from Backend*/
  public getFTPAppDetails() {
    console.log('ftp');
    this.appsLoaded = true;
    const requestParams = { perPage: 10, page: 1, appType: 'FTP' };
    this.appsStore.dispatch(new GetInstallAppList(requestParams));
    this.appsStore.pipe(select(fromAppsSelector.getInstalledAppsList)).subscribe(response => {
      if (response) {
        this.appsLoaded = false;
        console.log('response', response);
        const ftpAppsData = response.data.length > 0 ? response.data : [];
        if (ftpAppsData.length > 0 && ftpAppsData[0].connections)
          this.activeFtpAppsConnections = ftpAppsData[0].connections.filter(apps => apps.isActive);
      }
    });
  }

  /**@description Method called when Export to Ftp checkbox is clicked*/
  public isCheckExportToFtp(isChecked) {
    this.isSendReportToFTP = isChecked;
    if (this.isSendReportToFTP) {
      this.scheduleForm.get('ftpConnectionId').enable();
      this.scheduleForm.get('ftpDirectoryPath').enable();
    } else {
      this.scheduleForm.get('ftpConnectionId').disable();
      this.scheduleForm.get('ftpDirectoryPath').disable();
    }
  }

  /** @description User Name Sorting */
  private filterKeys() {
    if (!this.membersList) return;
    let search = this.searchSelectBox.value;
    if (!search) {
      this.filteredKeyList.next(this.membersList);
      return;
    } else search = search.toLowerCase();
    this.filteredKeyList.next(
      this.membersList.filter(obj => {
        if (obj && obj.firstName && obj.lastName)
          return obj.firstName.toLowerCase().indexOf(search) > -1 || obj.lastName.toLowerCase().indexOf(search) > -1;
        else if (obj && obj.firstName) return obj.firstName.toLowerCase().indexOf(search) > -1;
        else if (obj && obj.lastName) return obj.lastName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  /** @description Get Date Request Body For Order Schedule*/
  public getDate(type, value) {
    if (value['value'] == 'none' && type in this.requestBody['dateRange']) {
      delete this.requestBody['dateRange'][type];
    } else if (value['value'] != 'none') {
      switch (type) {
        case 'orderDateDetailsDTO.orderDate':
          this.requestBody['dateRange']['orderDateDetailsDTO.orderDate'] = value['value'];
          break;
        case 'orderDateDetailsDTO.orderReceivedOn':
          this.requestBody['dateRange']['orderDateDetailsDTO.orderReceivedOn'] = value['value'];
          break;
        case 'invoiceDTO.invoiceDate':
          this.requestBody['dateRange']['invoiceDTO.invoiceDate'] = value['value'];
          break;
        case 'shipmentDTO.dispatchedOnFilter':
          this.requestBody['dateRange']['shipmentDTO.dispatchedOnFilter'] = value['value'];
          break;
        case 'ShipmentTracking.deliveredDate':
          this.requestBody['dateRange']['ShipmentTracking.deliveredDate'] = value['value'];
          break;
        case 'shipmentDTO.cancelledOn':
          this.requestBody['dateRange']['shipmentDTO.cancelledOn'] = value['value'];
          break;
        case 'returnReceivedOn':
          this.requestBody['dateRange']['returnReceivedOn'] = value['value'];
          break;
        case 'returnCompletedOn':
          this.requestBody['dateRange']['returnCompletedOn'] = value['value'];
          break;
        case 'shipmentDTO.readyToShipOn':
          this.requestBody['dateRange']['shipmentDTO.readyToShipOn'] = value['value'];
          break;
        case 'returnCreatedOn':
          this.requestBody['dateRange']['returnCreatedOn'] = value['value'];
          break;
        case 'updatedOrderDateOn':
          this.requestBody['dateRange']['orderLastUpdatedAtTimestamp'] = value['value'];
          break;
        //
        case 'orderReceivedOn':
          this.requestBody['dateRange']['orderReceivedOn'] = value['value'];
          break;
        case 'orderDetails.orderDate':
          this.requestBody['dateRange']['orderDetails.orderDate'] = value['value'];
          break;
        case 'shipmentDetails.invoiceDate':
          this.requestBody['dateRange']['shipmentDetails.invoiceDate'] = value['value'];
          break;
        case 'orderHandoverDoneTimestamp':
          this.requestBody['dateRange']['orderHandoverDoneTimestamp'] = value['value'];
          break;
        case 'orderDeliveredTimestamp':
          this.requestBody['dateRange']['orderDeliveredTimestamp'] = value['value'];
          break;
        case 'orderCancelledTimestamp':
          this.requestBody['dateRange']['orderCancelledTimestamp'] = value['value'];
          break;
        case 'returns.returnDetails.receivedOnTimestamp':
          this.requestBody['dateRange']['returns.returnDetails.receivedOnTimestamp'] = value['value'];
          break;
        case 'returns.returnDetails.completedOnTimestamp':
          this.requestBody['dateRange']['returns.returnDetails.completedOnTimestamp'] = value['value'];
          break;
        case 'orderReadyToShipTimestamp':
          this.requestBody['dateRange']['orderReadyToShipTimestamp'] = value['value'];
          break;
        case 'returns.returnDetails.createdOnTimestamp':
          this.requestBody['dateRange']['returns.returnDetails.createdOnTimestamp'] = value['value'];
          break;
        case 'orderDate':
          this.requestBody['dateRange']['orderDate'] = value['value'];
          break;
        case 'paymentOrderDate':
          this.requestBody['dateRange']['paymentOrderDate'] = value['value'];
          break;
        case 'orderLastUpdatedAtTimestamp':
          this.requestBody['dateRange']['orderLastUpdatedAtTimestamp'] = value['value'];
          break;
      }
    }
  }

  /** @description Get Date Request Body For Inward and Recall Consignment Schedule */
  public getDateCons(type, value) {
    if (value['value'] == 'none' && type in this.requestBody['dateRange']) delete this.requestBody['dateRange'][type];
    else if (value['value'] != 'none') {
      switch (type) {
        case 'createdOn':
          this.requestBody['dateRange']['createdOn'] = value['value'];
          break;
        // Future scope
        // case 'scheduleOn':
        //   this.requestBody['dateRange']['scheduleOn'] = value['value'];
        //   break;
        case 'scheduled_for':
          this.requestBody['dateRange']['scheduled_for'] = value['value'];
          break;
        case 'extCreatedAt':
          this.requestBody['dateRange']['extCreatedAt'] = value['value'];
          break;
        case 'inwardOn':
          this.requestBody['dateRange']['inwardOn'] = value['value'];
          break;
        case 'deliveredOn':
          this.requestBody['dateRange']['deliveredOn'] = value['value'];
          break;
        case 'completed_at':
          this.requestBody['dateRange']['completed_at'] = value['value'];
          break;
        case 'packed_On':
          this.requestBody['dateRange']['packed_On'] = value['value'];
          break;
        case 'dispatched_On':
          this.requestBody['dateRange']['dispatched_On'] = value['value'];
          break;
        case 'recall_On':
          this.requestBody['dateRange']['recall_On'] = value['value'];
          break;
      }
    }
  }

  /** @description Get Date Request Body For Product Schedule */
  public getDateProduct(type, value) {
    if (value['value'] == 'none' && type in this.requestBody['dateRange']) delete this.requestBody['dateRange'][type];
    else if (value['value'] != 'none') {
      switch (type) {
        case 'createdOn':
          this.requestBody['dateRange']['createdOn'] = value['value'];
          break;
        case 'updatedOn':
          this.requestBody['dateRange']['updatedOn'] = value['value'];
          break;
      }
    }
  }

  /** @description Member List API Call */
  public getTeamMembers() {
    this.loader = true;
    this.currentRoute = this.router.url;
    if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
      this.store.dispatch(new GetMemberListV2({}));
      this.getMemberListSubscription$ = this.store
        .pipe(select(fromSelectorV2.memberList))
        .subscribe((response: any) => {
          if (response) {
            this.loader = false;
            this.membersList = response.memberList ? response.memberList : response.data;
            if (this.membersList) {
              this.membersList.sort((a, b) => {
                if (a.firstName && b.firstName) {
                  if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
                  else if (b.firstName.toLowerCase() > a.firstName.toLowerCase()) return -1;
                  else return 0;
                }
              });
            }
            this.filteredKeyList.next(this.membersList);
          }
        });
    } else {
      this.store.dispatch(new GetMemberListV2({}));
      this.getMemberListSubscription$ = this.store.pipe(select(fromSelectorV2.memberList)).subscribe(response => {
        if (response) {
          this.loader = false;
          this.membersList = response.memberList;
          if (this.membersList) {
            this.membersList.sort((a, b) => {
              if (a.firstName && b.firstName) {
                if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
                else if (b.firstName.toLowerCase() > a.firstName.toLowerCase()) return -1;
                else return 0;
              }
            });
          }
          this.filteredKeyList.next(this.membersList);
        }
      });
    }
  }

  getSalesChannelFilterData(data) {
    this.requestBody['filter']['salesChannel'] = {
      condition: 'is',
      displayKey: data.salesChannel.length ? data.displayKey : '',
      value: data.salesChannel
    };
  }

  getPortalFilterData(data) {
    if (this.portalData.length == data.portal.length) {
      this.requestBody['filter']['portal'] = {
        condition: 'is',
        displayKey: 'Portal: all',
        value: ['all']
      };
    } else {
      this.requestBody['filter']['portal'] = {
        condition: 'is',
        displayKey: data.portal.length ? data.displayKey : '',
        value: data.portal
      };
    }
  }

  /** @description Schedule Report Request Body */
  public scheduleReport() {
    this.submitted = true;
    if (this.scheduleForm.valid) {
      let membersArr = [];
      this.scheduleForm.value.members.forEach(m => {
        membersArr.push(m.email);
      });

      if (this.scheduleForm.value.qcStatusCtrl) {
        this.requestBody['filter']['qcStatus'] = {
          condition: 'is',
          displayKey: 'QC Status: ' + this.scheduleForm.value.qcStatusCtrl.join(', '),
          value: this.scheduleForm.value.qcStatusCtrl
        };

        // this.requestBody['qcStatus'] = this.dateRangeForm.value.qcStatusCtrl.join(', ')
      }
      if (this.scheduleForm.value.locationFilterFormData) {
        this.requestBody['filter']['warehouses'] = {
          condition: 'is',
          displayKey: 'Warehouses: ' + this.scheduleForm.value.locationFilterFormData.join(', '),
          value: this.scheduleForm.value.locationFilterFormData.map(String)
        };
      }
      if (this.accountslug == 'eshop' && this.scheduleForm.value.sellingParty) {
        this.requestBody['filter']['sellingParty'] = {
          condition: 'is',
          displayKey: 'Selling party: ' + this.scheduleForm.value.sellingParty,
          value: this.scheduleForm.value.sellingParty
        };
      } else if (
        this.accountslug == 'eshop' &&
        this.jobType == 'draft_return_report' &&
        this.scheduleForm.value.sellingParty == ''
      ) {
        this.requestBody['filter']['sellingParty'] = {
          condition: 'is',
          displayKey: 'Selling party: ' + 'All',
          value: 'all'
        };
      } else {
        this.requestBody['filter']['sellingParty'] = {
          condition: 'is',
          displayKey: 'Selling party: ' + this.accountslug,
          value: this.accountslug
        };
      }

      this.requestBody['report'] = this.scheduleForm.value.reportName;
      this.requestBody['reportDelivery'] = membersArr;
      this.requestBody['interval']['every'] = this.scheduleForm.value.every.toLowerCase();
      if (this.requestBody['interval']['every'] === 'week') {
        this.requestBody['interval']['day'] = this.scheduleForm.value.day.toLowerCase();
      } else if (this.requestBody['interval']['every'] === 'month') {
        this.requestBody['interval']['day'] = this.scheduleForm.value.day;
      }
      if (this.isSendReportToFTP) {
        this.requestBody['ftpConnectionId'] = this.scheduleForm.value.ftpConnectionId;
        this.requestBody['ftpDirectoryPath'] = this.scheduleForm.value.ftpDirectoryPath;
      }
      let time = `${this.scheduleForm.value.hour}:${
        this.scheduleForm.value.minutes
      } ${this.scheduleForm.value.format.toUpperCase()}`;
      this.requestBody['interval']['time'] = time;
      // console.log("requestBody",this.requestBody)
      if (
        this.jobType == 'draft_return_report' &&
        !this.requestBody['dateRange']['returnCompletedOn'] &&
        !this.requestBody['dateRange']['returnReceivedOn']
      ) {
        this.isSelDateErr = true;
        this.loaderSchedule = false;
        setTimeout(() => {
          this.isSelDateErr = false;
        }, 5000);
        return;
      }
      this.loaderSchedule = true;
      this.scheduleJob.emit(this.requestBody);
      if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
        this.exportTemplateSubscription$ = this.store
          .pipe(select(fromSelectorV2.scheduleTemplateData))
          .subscribe(response => {
            if (response && response.scheduleTemplateData) {
              this.loaderSchedule = false;
            }
          });
      } else {
        this.exportTemplateSubscription$ = this.store
          .pipe(select(fromSelectorV2.scheduleTemplateData))
          .subscribe(response => {
            if (response && response.scheduleTemplateData) {
              this.loaderSchedule = false;
            }
          });
      }
    }
  }
  goUp() {
    this.scroller.scrollToAnchor('targetUp');
  }
  /** @description Remove Email */
  public onRemove(member) {
    const members = this.scheduleForm.value.members as string[];
    this.scheduleForm.value.members.forEach(m => {
      if (member.email == m.email) {
        const index = members.indexOf(m);
        members.splice(index, 1);
      }
    });
    this.scheduleForm.get('members').setValue(members);
  }

  toggleAllLoaction() {
    if (this.allSelected.selected) {
      this.scheduleForm.controls.locationFilterFormData.patchValue([
        ...this.filteredEnrolledFCData.map(item => item.warehouseId),
        0
      ]);
    } else {
      this.scheduleForm.controls.locationFilterFormData.patchValue([]);
    }
    // this.onSubmitDataForm();

    console.log(this.scheduleForm.value.locationFilterFormData);
  }
  displayLocation(check, data) {
    // console.log(check,data)
    if (check && data.length > 0) {
      let obj = this.filteredEnrolledFCData.find(val => val.warehouseId === data[0]);
      // console.log(obj)
      return obj.facilityLabel;
    }
  }

  getAccountAndWarehouseData() {
    this.accountLocationSubscription$ = this.authService
      .getAccountAndWarehouseList()
      // .subscribe(accountListData => {
      .subscribe(([accountListData, fulfillmentCenterData]) => {
        if (
          (isArray(accountListData) || accountListData?.accountListLoaded) &&
          (isArray(fulfillmentCenterData) || fulfillmentCenterData?.fulfillmentCenterLoaded)
        ) {
          const accountList = isArray(accountListData) ? accountListData : accountListData?.accountListData?.data;
          // this.accountLists = accountList;
          this.accountLists = accountList.filter(acc => acc['status'] == '1' && acc['warehouseId'] == null);
          console.log('this.accountLists', this.accountLists);
          if (this.accountLocationSubscription$) this.accountLocationSubscription$.unsubscribe();
        }
      });
  }

  /** @description Close error message */
  closeErr() {
    this.isSelDateErr = false;
  }

  ngOnDestroy() {
    if (this.accountLocationSubscription$) this.accountLocationSubscription$.unsubscribe();
    if (this.getMemberListSubscription$) this.getMemberListSubscription$.unsubscribe();
    if (this.exportTemplateSubscription$) this.exportTemplateSubscription$.unsubscribe();
  }
}
