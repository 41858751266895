import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-delete-popup',
  templateUrl: './confirmation-delete-popup.component.html',
  styleUrls: ['./confirmation-delete-popup.component.scss']
})
export class ConfirmationDeletePopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmationDeletePopupComponent>) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }
}
