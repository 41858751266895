import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPutawayCompletedItems'
})
export class getPutawayCompletedItems implements PipeTransform {
  transform(putawayCompletedIts: any, singleData: any, data: any): any {
    if (putawayCompletedIts && singleData && data) {
      let id;
      let displayTitle = data['displayTitle']['displayTitle'];
      if (
        singleData['record'][displayTitle.split('.')[0]] != null &&
        typeof singleData['record'][displayTitle.split('.')[0]] == 'object'
      ) {
        id = singleData['record'][displayTitle.split('.')[0]]['id'];
      } else {
        id = singleData['record'][displayTitle.split('.')[0]];
      }
      let putawayCompletedIt = putawayCompletedIts.find(val => val.recordId == id);
      if (putawayCompletedIt && putawayCompletedIt.name) return putawayCompletedIt.name;
    }
  }
}
