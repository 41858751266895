<div
  class="dropdown"
  *ngIf="(orderExport.details | json) != '{}'"
  [ngClass]="objectKeys(orderExport.details).length <= 2 ? 'dropdown-design' : ''"
>
  <ul>
    <li *ngFor="let filter of orderExport.details | keyvalue: originalOrder">
      <ng-container *ngIf="filter.key == 'saleChannel'">
        {{ filter.value.displayKey }}
        <span class="border-line">
          {{ filter.value.condition }}
        </span>
        <em
          [outerHTML]="
            filterHelperService.getFilterValue(filter.value.value, filter.key, salesChannelData, enrolledFCData)
              | spaceAfterComma
          "
        >
        </em>
      </ng-container>
      <ng-container *ngIf="filter.key == 'wareHouseCode'">
        {{ filter.value.displayKey }}
        <span class="border-line">
          {{ filter.value.condition }}
        </span>
        <em
          [outerHTML]="
            filterHelperService.getFilterValue(filter.value.value, filter.key, salesChannelData, enrolledFCData)
              | spaceAfterComma
          "
        >
        </em>
      </ng-container>
      <ng-container *ngIf="filter.key != 'saleChannel' && filter.key != 'wareHouseCode'">
        {{ filter.value.displayKey }}
        <span class="border-line">
          {{ filter.value.condition === 'is-between' ? 'is between' : filter.value.condition }}
        </span>
        <ng-container *ngIf="!['is-between', 'is between'].includes(filter.value.condition)">
          <ng-container *ngFor="let filterVal of filter.value.value">
            {{ filterVal }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="['is-between', 'is between'].includes(filter.value.condition)">
          {{ (filter?.value?.value)[0] | date: 'dd MMM, yyyy' }} -
          {{ (filter?.value?.value)[1] | date: 'dd MMM, yyyy' }}
        </ng-container>
      </ng-container>
    </li>
  </ul>
</div>
