<div *ngIf="isScopeLoading" class="web-loader main">
  <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
</div>
<div *ngIf="isLoading" class="web-loader main">
  <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
</div>
<section class="app-install-wrp">
  <div
    class="no-data"
    *ngIf="
      (appName == null || appLogo == null || accounSlug == null || clientId == null || systemState == null) &&
      !isScopeLoading
    "
  >
    <img
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg?updatedAt=1621857296536"
    />
    <p>
      Oops, Something Went Wrong
      <br />
      What happened?
      <br />
      Oauth error inavlid_request
      <br />
      What can I do?
      <br />
      <span class="bullet-point">Press the back button on your browser </span>
      <span class="bullet-point">Return to your admin dashboard</span>
    </p>
  </div>
  <!-- <ng-container *ngIf="partnerCode != undefined && partnerCode != partnerValue">
    <div>
      <div *ngIf="appName && appLogo && accounSlug && clientId && systemState">
        <div class="header-logo">
          <a
            ><img
              src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg?updatedAt=1621857296536"
          /></a>
        </div>
        <div class="app-header">
          <h2>You are about to install {{ appName }}</h2>
          <div class="aap-name">
            <div class="eshop-logo">
              <a
                ><img
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg?updatedAt=1621857296536"
              /></a>
              <h3>{{ accounSlug }}</h3>
            </div>
            <div class="connect-icon">
              <img
                src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-arrow.png?updatedAt=1621936347175"
              />
            </div>
            <div class="app-logo">
              <a><img src="{{ appLogo }}"/></a>
              <h3>{{ appName }}</h3>
            </div>
          </div>
        </div>
        <div class="app-detail">
          <div class="white-sec">
            <h2>{{ appName }} will be able to:</h2>
            <div *ngIf="!isScopeLoading">
              <div *ngFor="let scope of scopeData; let i = index" class="collaps">
                <h3>
                  <span class="title"> {{ scope.category }} </span>
                  <span class="action-btn">
                    <a (click)="showHideDetails(i)"> {{ i == showDetailsIndex ? 'Hide details' : 'View details' }}</a>
                  </span>
                </h3>
                <div *ngIf="i == showDetailsIndex" class="expend-detail">
                  <p *ngIf="scope.include != null">This includes: {{ scope.include }}</p>
                  <p *ngIf="scope.hint != null">{{ appName }} {{ scope.hint }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="gray-sec">
            <p>
              To erase your customers’ personal information from {{ appName }}, remove the app. After 48 hours, a
              request will be sent to {{ appName }} to erase this data.
              <a (click)="dataPrivacy()"> Learn more about data privacy.</a>
            </p>
          </div>
        </div>
        <div class="app-action-footer">
          <div class="left">
            <a class="btn cancle" [routerLink]="ROUTES.INSTALLED_APP_LIST"> Cancel </a>
          </div>
          <div class="right">
            <a class="btn install" (click)="authorizeApp()"> Install app </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container> -->
</section>
