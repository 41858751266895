import { OrdersActions, OrdersActionTypes } from '../actions/orders.action';
import { OrdersState } from '../state/orders.state';

export const initialState: OrdersState = {
  ordersData: {
    ordersListLoaded: false,
    ordersListLoading: false,
    ordersList: null
  },
  portalData: {
    portalsData: null
  },
  templateData: {
    templateDataLoaded: false,
    templateDataLoading: false,
    templateData: null
  },
  saveTemplateData: {
    saveTemplateLoaded: false,
    saveTemplateLoading: false,
    saveTemplate: null
  },
  editTemplateData: {
    editTemplateData: null
  },
  deleteTemplateData: {
    deleteTemplateData: null
  },
  exportTemplateData: {
    exportTemplateLoaded: false,
    exportTemplateLoading: false,
    exportTemplate: null
  },
  scheduleTemplateData: {
    scheduleTemplateData: null
  },
  memberList: {
    memberList: null
  },
  resolverLoader: false,
  import: {
    importData: null,
    isImportLoading: false,
    isImportLoaded: false
  },
  /* Temporary Change */
  documentStatus: null,
  documentStatusChecked: false,
  documentStatusChecking: false,

  createBulkReturn: null,
  creatingBulkReturn: false,
  createdBulkReturn: false,

  // order cancellation starts
  orderCancellation: {
    orderCancellationData: null,
    isOrderCancellationLoading: false,
    isOrderCancellationLoaded: false
  },
  customerPortalPoeSetting: {
    customerPortalPoeSettingData: null,
    isCustomerPortalPoeSettingLoading: false,
    isCustomerPortalPoeSettingLoaded: false
  }
  // order cancellation ends
};

export function OrderReducer(state = initialState, action: OrdersActions): OrdersState {
  switch (action.type) {
    case OrdersActionTypes.SHOW_PROGRESS_SPINNER:
      return {
        ...state,
        resolverLoader: true
      };

    case OrdersActionTypes.HIDE_PROGRESS_SPINNER:
      return {
        ...state,
        resolverLoader: false
      };

    case OrdersActionTypes.GET_ORDERS_LIST: {
      return {
        ...state,
        ordersData: {
          ordersListLoaded: false,
          ordersListLoading: true,
          ordersList: null
        }
      };
    }
    case OrdersActionTypes.GET_ORDERS_LIST_SUCCESS: {
      return {
        ...state,
        ordersData: {
          ordersListLoaded: true,
          ordersListLoading: false,
          ordersList: action.payload
        }
      };
    }
    case OrdersActionTypes.GET_ORDERS_LIST_FAILURE: {
      return {
        ...state,
        ordersData: {
          ordersListLoaded: true,
          ordersListLoading: false,
          ordersList: action.payload
        }
      };
    }

    case OrdersActionTypes.GET_PORTALS_LIST: {
      return {
        ...state,
        portalData: {
          portalsData: null
        }
      };
    }
    case OrdersActionTypes.GET_PORTALS_LIST_SUCCESS: {
      return {
        ...state,
        portalData: {
          portalsData: action.payload
        }
      };
    }
    case OrdersActionTypes.GET_PORTALS_LIST_FAILURE: {
      return {
        ...state,
        portalData: {
          portalsData: action.payload
        }
      };
    }
    case OrdersActionTypes.GET_TEMPLATE: {
      return {
        ...state,
        templateData: {
          templateDataLoaded: false,
          templateDataLoading: true,
          templateData: null
        }
      };
    }
    case OrdersActionTypes.GET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templateData: {
          templateDataLoaded: true,
          templateDataLoading: false,
          templateData: action.payload
        }
      };
    }
    case OrdersActionTypes.GET_TEMPLATE_FAILURE: {
      return {
        ...state,
        templateData: {
          templateDataLoaded: true,
          templateDataLoading: false,
          templateData: action.payload
        }
      };
    }
    case OrdersActionTypes.SAVE_TEMPLATE: {
      return {
        ...state,
        saveTemplateData: {
          saveTemplateLoaded: false,
          saveTemplateLoading: true,
          saveTemplate: null
        }
      };
    }
    case OrdersActionTypes.SAVE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        saveTemplateData: {
          saveTemplateLoaded: true,
          saveTemplateLoading: false,
          saveTemplate: action.payload
        }
      };
    }
    case OrdersActionTypes.SAVE_TEMPLATE_FAILURE: {
      return {
        ...state,
        saveTemplateData: {
          saveTemplateLoaded: true,
          saveTemplateLoading: false,
          saveTemplate: action.payload
        }
      };
    }
    case OrdersActionTypes.EDIT_TEMPLATE: {
      return {
        ...state,
        editTemplateData: {
          editTemplateData: null
        }
      };
    }
    case OrdersActionTypes.EDIT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        editTemplateData: {
          editTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.EDIT_TEMPLATE_FAILURE: {
      return {
        ...state,
        editTemplateData: {
          editTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.DELETE_TEMPLATE: {
      return {
        ...state,
        deleteTemplateData: {
          deleteTemplateData: null
        }
      };
    }
    case OrdersActionTypes.DELETE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        deleteTemplateData: {
          deleteTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.DELETE_TEMPLATE_FAILURE: {
      return {
        ...state,
        deleteTemplateData: {
          deleteTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.DELETE_TEMPLATE: {
      return {
        ...state,
        deleteTemplateData: {
          deleteTemplateData: null
        }
      };
    }
    case OrdersActionTypes.DELETE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        deleteTemplateData: {
          deleteTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.DELETE_TEMPLATE_FAILURE: {
      return {
        ...state,
        deleteTemplateData: {
          deleteTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.EXPORT_TEMPLATE: {
      return {
        ...state,
        exportTemplateData: {
          exportTemplateLoaded: false,
          exportTemplateLoading: true,
          exportTemplate: null
        }
      };
    }
    case OrdersActionTypes.EXPORT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        exportTemplateData: {
          exportTemplateLoaded: true,
          exportTemplateLoading: false,
          exportTemplate: action.payload
        }
      };
    }
    case OrdersActionTypes.EXPORT_TEMPLATE_FAILURE: {
      return {
        ...state,
        exportTemplateData: {
          exportTemplateLoaded: true,
          exportTemplateLoading: false,
          exportTemplate: action.payload
        }
      };
    }
    case OrdersActionTypes.SCHEDULE_REPORT: {
      return {
        ...state,
        scheduleTemplateData: {
          scheduleTemplateData: null
        }
      };
    }
    case OrdersActionTypes.SCHEDULE_REPORT_SUCCESS: {
      return {
        ...state,
        scheduleTemplateData: {
          scheduleTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.SCHEDULE_REPORT_FAILURE: {
      return {
        ...state,
        scheduleTemplateData: {
          scheduleTemplateData: action.payload
        }
      };
    }
    case OrdersActionTypes.GET_MEMBER_LIST: {
      return {
        ...state,
        memberList: {
          memberList: null
        }
      };
    }
    case OrdersActionTypes.GET_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: {
          memberList: action.payload
        }
      };
    }
    case OrdersActionTypes.GET_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        memberList: {
          memberList: action.payload
        }
      };
    }

    case OrdersActionTypes.CREATE_IMPORT: {
      return {
        ...state,
        import: {
          importData: null,
          isImportLoading: true,
          isImportLoaded: false
        }
      };
    }
    case OrdersActionTypes.CREATE_IMPORT_SUCCESS: {
      return {
        ...state,
        import: {
          importData: action.payload,
          isImportLoading: false,
          isImportLoaded: true
        }
      };
    }
    case OrdersActionTypes.CREATE_IMPORT_FAILURE: {
      return {
        ...state,
        import: {
          importData: action.payload,
          isImportLoading: false,
          isImportLoaded: true
        }
      };
    }

    /* Temporary Change*/
    case OrdersActionTypes.DOCUMENT_UPLOAD_STATUS:
      return {
        ...state,
        documentStatusChecking: true,
        documentStatusChecked: false,
        documentStatus: action.payload
      };
    case OrdersActionTypes.DOCUMENT_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        documentStatusChecking: true,
        documentStatusChecked: true,
        documentStatus: action.payload
      };
    case OrdersActionTypes.DOCUMENT_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        documentStatusChecked: false,
        documentStatusChecking: false
      };
    case OrdersActionTypes.CREATE_BULK_RETURN:
      return {
        ...state,
        creatingBulkReturn: true,
        createdBulkReturn: false
      };
    case OrdersActionTypes.CREATE_BULK_RETURN_SUCCESS:
      return {
        ...state,
        createBulkReturn: action.payload,
        creatingBulkReturn: true,
        createdBulkReturn: true
      };
    case OrdersActionTypes.CREATE_BULK_RETURN_FAILURE:
      return {
        ...state,
        createdBulkReturn: false,
        creatingBulkReturn: false
      };
    // order cancellation starts
    case OrdersActionTypes.CREATE_ORDER_CANCELLATION:
      return {
        ...state,
        orderCancellation: {
          orderCancellationData: null,
          isOrderCancellationLoading: true,
          isOrderCancellationLoaded: false
        }
      };
    case OrdersActionTypes.CREATE_ORDER_CANCELLATION_SUCCESS:
      return {
        ...state,
        orderCancellation: {
          orderCancellationData: action.payload,
          isOrderCancellationLoading: false,
          isOrderCancellationLoaded: true
        }
      };
    case OrdersActionTypes.CREATE_ORDER_CANCELLATION_FAILURE:
      return {
        ...state,
        orderCancellation: {
          orderCancellationData: action.payload,
          isOrderCancellationLoading: false,
          isOrderCancellationLoaded: true
        }
      };

    case OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS:
      return {
        ...state,
        customerPortalPoeSetting: {
          customerPortalPoeSettingData: null,
          isCustomerPortalPoeSettingLoading: true,
          isCustomerPortalPoeSettingLoaded: false
        }
      };
    case OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS_SUCCESS:
      return {
        ...state,
        customerPortalPoeSetting: {
          customerPortalPoeSettingData: action.payload,
          isCustomerPortalPoeSettingLoading: false,
          isCustomerPortalPoeSettingLoaded: true
        }
      };
    case OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS_FAILURE:
      return {
        ...state,
        customerPortalPoeSetting: {
          customerPortalPoeSettingData: action.payload,
          isCustomerPortalPoeSettingLoading: false,
          isCustomerPortalPoeSettingLoaded: true
        }
      };
    // order cancellation starts
    case OrdersActionTypes.UPDATE_ORDER_STATUS_CANCELLED:
      console.log(action, 'action');
      return {
        ...state,
        ordersData: updateOrderStatus(state.ordersData, action.payload)
      };

    case OrdersActionTypes.UPDATE_ORDER_CANCELLED:
      // console.log(action,"action");
      return {
        ...state,
        ordersData: updateOrder(state.ordersData, action.payload)
      };
    default:
      return state;
  }
}

export const getOrdersData = (state: OrdersState) => state.ordersData;
export const getOrdersList = (state: OrdersState) => state.ordersData.ordersList;
export const getPortalsData = (state: OrdersState) => state.portalData;
export const getResolverLoader = (state: OrdersState) => state.resolverLoader;
export const templateData = (state: OrdersState) => state.templateData;
export const saveTemplateData = (state: OrdersState) => state.saveTemplateData;
export const editTemplateData = (state: OrdersState) => state.editTemplateData;
export const deleteTemplateData = (state: OrdersState) => state.deleteTemplateData;
export const exportTemplateData = (state: OrdersState) => state.exportTemplateData;
export const memberList = (state: OrdersState) => state.memberList;
export const scheduleTemplateData = (state: OrdersState) => state.scheduleTemplateData;
export const getImportData = (state: OrdersState) => state.import;

/*Temporary Change*/
export const getDocumentStatus = (state: OrdersState) => state.documentStatus;
export const getDocumentStatusChecked = (state: OrdersState) => state.documentStatusChecked;
export const getDocumentStatusChecking = (state: OrdersState) => state.documentStatusChecking;
export const getCreatedBulkReturn = (state: OrdersState) => state.createBulkReturn;
export const getCreatingBulkReturnStatus = (state: OrdersState) => state.creatingBulkReturn;
export const getCreatedBulkReturnStatus = (state: OrdersState) => state.createdBulkReturn;

// order cancellation starts
export const getCreatedOrderCancellation = (state: OrdersState) => state.orderCancellation;
export const customerPortalPoeSetting = (state: OrdersState) => state.customerPortalPoeSetting;
// order cancellation ends

export const updateOrderStatusCancelled = (state: OrdersState) => state.ordersData;
export const updateOrderCancelled = (state: OrdersState) => state.ordersData;

function updateOrderStatus(ordersData, payload) {
  ordersData.ordersList.data.forEach(element => {
    if (element) {
      if (element.orderItemID === payload) {
        element.orderStatus = 'Cancelled';
        element.currentOrderStatus = 'Cancelled';
      }
    }
  });
  return {
    ordersListLoaded: true,
    ordersListLoading: false,
    ordersList: ordersData.ordersList
  };
}

function updateOrder(ordersData, payload) {
  ordersData.ordersList.data.forEach(element => {
    if (element) {
      if (element.orderItemID === payload.orderCancellationData.orderItemID) {
        element.orderStatus = payload.orderCancellationData.orderStatus;
        element.currentOrderStatus = payload.orderCancellationData.currentOrderStatus;
        element.orderCancelledTimestamp = payload.orderCancellationData.orderCancelledTimestamp;
        if (payload.orderCancellationData['cancellationDetails'] != null) {
          element['cancellationDetails'] = {
            reason: payload.orderCancellationData.cancellationDetails.reason,
            type: payload.orderCancellationData.cancellationDetails.type
          };
        }
      }
    }
  });
  return {
    ordersListLoaded: true,
    ordersListLoading: false,
    ordersList: ordersData.ordersList
  };
}
