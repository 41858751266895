// import { createReducer, on } from '@ngrx/store';
import { shippingActions } from './shipping-mode.actions';
import * as fromAction from './shipping-mode.actions';
// export const initialState;
export interface shippingState {
  ruleListData: any;
  ruleLoad: boolean;
  ruleError: string;
  ruleLoading: boolean;
}

export const initialState: shippingState = {
  ruleListData: [],
  ruleLoad: false,
  ruleError: '',
  ruleLoading: false
};

export function shippingModuleReducer(state = initialState, action: shippingActions): shippingState {
  switch (action.type) {
    case fromAction.ruleList: {
      return { ...state, ruleLoading: true, ruleListData: [], ruleError: '', ruleLoad: false };
    }

    case fromAction.ruleListSuccess: {
      return { ...state, ruleLoading: false, ruleListData: action['payload'], ruleLoad: true, ruleError: '' };
    }

    case fromAction.ruleListError: {
      return { ...state, ruleLoading: false, ruleListData: [], ruleLoad: true, ruleError: 'Please try again' };
    }

    case fromAction.ruleSave: {
      return { ...state, ruleLoading: true, ruleError: '' };
    }

    case fromAction.ruleSaveSuccess: {
      return { ...state, ruleLoading: false, ruleListData: action['payload'], ruleLoad: true, ruleError: '' };
    }

    case fromAction.ruleSaveError: {
      return { ...state, ruleLoading: false, ruleLoad: true, ruleError: action['payload']['message'] };
    }

    case fromAction.ruleDelete: {
      return { ...state, ruleLoading: true, ruleError: '' };
    }

    case fromAction.ruleDeleteSuccess: {
      return { ...state, ruleLoading: false, ruleListData: action['payload'], ruleLoad: true, ruleError: '' };
    }

    case fromAction.ruleDeleteError: {
      return { ...state, ruleLoading: false, ruleLoad: true, ruleError: 'Please try again' };
    }

    default: {
      return state;
    }
  }
}

export const getRuleList = (state: shippingState) => state.ruleListData;
export const getLoaderstart = (state: shippingState) => state.ruleLoading;
export const getError = (state: shippingState) => state.ruleError;
export const getstate = (state: shippingState) => state;
