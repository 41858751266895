import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { AuthService, SharedService, AlertService } from '../shared/services';
import { Store } from '@ngrx/store';
import { AuthActionTypes } from '../auth/actions/auth.actions';
import { isObject } from '../records/utils/common-util';

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private store: Store<{ auth }>
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // this.sharedService.showHideLoader(false);
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            console.log('interceptor error ', err);
            if (
              err.url.includes('salesChannelUnavailableInventory') ||
              err.url.includes('salesChannelInventory') ||
              err?.error?.error?.message.startsWith('UnexpectedItem:') ||
              err?.error?.error?.message.includes('unexpected item')
            ) {
              return;
            }
            // this.sharedService.showHideLoader(false);
            // const errorCode = err.error.error ? err.error.error.code : '';
            //const errorCode = err.error.error ? (err.error.error.message || '').split(':')[0] : '';
            // need to be discuss for this handling
            const errorCode = err.error.error
              ? (err.error.error.message || '').split(':')[0]
              : err.message
              ? err.status.toString()
              : '';
            let errorMsg;
            if (err) {
              if (err.error) {
                if (err.error['message']) errorMsg = err.error['message'];
                else if (err.error.error && err.error.error['message']) errorMsg = err.error.error['message'];
                else if (err.error.errors && !isObject(err.error.errors[0].message))
                  errorMsg = err.error.errors[0].message;
                else if (err.error.errors && err.error.errors[0].message.errors)
                  errorMsg = err.error.errors[0].message.errors;
                else if (err.url && err.url.includes('/api/auperator/orders/labels')) errorMsg = '';
                else errorMsg = err.message;
              }
              // else if(err.errors) {
              //   errorMsg = err.errors;
              // }
              else errorMsg = err.message;
            }
            if (!!errorCode) {
              this.handleError(
                errorCode,
                errorMsg
                // err.error.error
                //   ? err.error.error['message']
                //   : err.error.errors
                //   ? err.error.errors[0].message
                //   : err.error ? err.error.message : err.message
              );
            }
          }
        }
      )
    );
  }

  handleError(errorCode, errMsg: any) {
    if (errMsg === '') {
      this.alertService.showError('Please reach out to tech team to check the exact error');
      return;
    }
    if (!isObject(errMsg) && (errMsg?.includes('Invalid OTP') || errMsg?.includes('availability'))) {
      return;
    }

    if (
      errMsg == 'SKU or additional name is already assigned to a product.' ||
      errMsg.includes('channelList is empty')
    ) {
      // this.alertService.showError(
      //   'Entered seller sku is already assigned to another product. Make sure that the enetered SKU is unique.'
      // );
      return;
    }
    if (!isObject(errMsg) && isNaN(errorCode)) {
      // if (errorCode.includes('unique')) {
      //   return;
      // }
      this.alertService.showError(errMsg);
      return;
    }
    if (errMsg == 'Entered State and GST Number registered state does not seems to match') {
      return;
    }
    switch (errorCode) {
      case '400':
        errMsg = isObject(errMsg) ? JSON.stringify(errMsg) : errMsg;
        this.alertService.showError(errMsg);
        break;

      case '401':
        this.alertService.showError('Session has expired. Please login again.');
        setTimeout(() => {
          this.store.dispatch({ type: AuthActionTypes.LOGOUT });
        }, 2000);
        break;

      case '404':
        this.alertService.showError('Not Found');
        break;
      case '100':
      case '500':
      case '503':
        this.alertService.showError('Something went wrong, Please try again after some time.');
        break;

      case '201':
        this.alertService.showError('Invalid domain name');
        break;

      case '202':
        this.alertService.showError('Account does not exist');
        break;

      case '203':
        this.alertService.showError('User does not exist');
        break;

      case '105':
        // this.alertService.showError('Seems like this team member is already invited.');
        break;

      case '412':
        this.alertService.showError(errMsg);
        break;

      default:
        console.log('Error Message inside response interceptor for default case', errMsg);
        console.log('Error Code inside response interceptor for default case', errorCode);
        // if (!errMsg.includes('/api/v1/test')) this.alertService.showError(errMsg);
        // Commented for Fee Already Exists error message
        // var errorMsg = GLOBAL_ERROR_MESSAGE.filter(messageData => {
        //   if (messageData.code == errorCode) {
        //     return messageData.msg;
        //   }
        // });
        // if (errorMsg.length > 0) {
        //   console.log('errorMsgerrorMsg ', errorMsg[0].msg);
        //   this.alertService.showError(errorMsg[0].msg);
        //   console.log('errorCode   default ', errorCode);
        // }
        break;
    }
  }
}

export let responseProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ResponseInterceptor,
  multi: true
};
