import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameFromList'
})
export class GetNameFromList implements PipeTransform {
  transform(filterObj: any, list: any): any {
    for (let i = 0; i < list.length; i++) {
      if (list[i][filterObj.idName] == filterObj.value) {
        return list[i][filterObj.returnKey];
      }
    }
  }
}
