import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services';
import { Store, select } from '@ngrx/store';
import * as fromSelectorV2 from 'src/app/orderV2/store/selectors/orders.selectors';
import { Subscription } from 'rxjs';
import { SaveTemplateV2 } from 'src/app/orderV2/store/actions/orders.action';

@Component({
  selector: 'app-save-template-export',
  templateUrl: './save-template-export.component.html',
  styleUrls: ['./save-template-export.component.scss']
})
export class SaveTemplateExportComponent implements OnInit {
  currentRoute: any;
  saveTemplateForm: FormGroup;
  saveTemplateSubscription$: Subscription;
  getTemplateSubscription$: Subscription;
  loader: boolean = false;
  templateList: any;
  constructor(
    private store: Store<any>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { jobType: string; columnsExport: any },
    private dialogRef: MatDialogRef<SaveTemplateExportComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this.createSaveTemplateForm();
    this.currentRoute = this.router.url;
    if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
      this.getTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.templateData)).subscribe(response => {
        if (response && response.templateDataLoaded) {
          this.templateList = response.templateData;
        }
      });
    } else {
      this.getTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.templateData)).subscribe(response => {
        if (response && response.templateDataLoaded) {
          this.templateList = response.templateData;
        }
      });
    }
  }

  /** @description Save Template Form */
  public createSaveTemplateForm() {
    this.saveTemplateForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', [Validators.required]]
    });
  }

  /** @description Save Template */
  public saveTemplate() {
    this.loader = true;
    let body = {
      template: this.saveTemplateForm.value.name,
      description: this.saveTemplateForm.value.description,
      ...this.data
    };
    if (this.data.jobType == 'product') {
      let columns = [];
      for (let key of Object.keys(this.data.columnsExport)) {
        if (key in this.templateList.externalWarehouseIDToFacilityLabel) {
          this.data.columnsExport[key].forEach((val, index) => {
            this.data.columnsExport[key][index] = key + ' ' + val;
          });
        }
      }
      let cols = Object.values(this.data.columnsExport);
      for (let i = 0; i < cols.length; i++) {
        columns = columns.concat(cols[i]);
      }
      body['columns'] = columns;
    }

    if (this.currentRoute && this.currentRoute.includes('/order-V2/')) {
      this.store.dispatch(new SaveTemplateV2(body));
      this.saveTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.saveTemplateData)).subscribe(response => {
        if (response && response.saveTemplateLoaded && response.saveTemplate.error) this.loader = false;
        if (response && response.saveTemplateLoaded && response.saveTemplate && !response.saveTemplate.error) {
          this.loader = false;
          this.dialogRef.close({ event: 'close', data: response });
        }
      });
    } else {
      this.store.dispatch(new SaveTemplateV2(body));
      this.saveTemplateSubscription$ = this.store.pipe(select(fromSelectorV2.saveTemplateData)).subscribe(response => {
        if (response && response.saveTemplateLoaded && response.saveTemplate.error) this.loader = false;
        if (response && response.saveTemplateLoaded && response.saveTemplate && !response.saveTemplate.error) {
          this.loader = false;
          this.dialogRef.close({ event: 'close', data: response });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.saveTemplateSubscription$) this.saveTemplateSubscription$.unsubscribe();
    if (this.getTemplateSubscription$) this.getTemplateSubscription$.unsubscribe();
  }
}
