import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'skipPickingDisplayFieldName'
})
export class SkipPickingDisplayFieldNamePipe implements PipeTransform {
  transform(buildingBlockHandleBar: string, fieldName: string, pickingMethod: string): unknown {
    const fieldMappings = {
      'Order Item > SKU': 'SKU',
      'Order Item > EAN': 'EAN',
      'Order Item > ESIN': 'ESIN',
      // 'Expected handover on': 'Expected handover at',
      'Order items': 'Number of items',
      'Labels generated at': 'Bulk labels generated at',
      'Labels generated by': 'Bulk labels generated by'
    };
    if (buildingBlockHandleBar === 'shipment' && pickingMethod === 'skipPicking') {
      if (fieldMappings[fieldName]) return fieldMappings[fieldName];
    }
    return fieldName;
  }
}
