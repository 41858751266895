import { Injectable, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GetSegmentsData } from 'src/app/auth/actions/auth.actions';
import { AuthService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import * as fromSelector from '../../auth/reducers/auth.selectors';
import { FilterHelperServiceNew } from './filter-helper.service';
import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterPusherService {
  private CHANNEL = environment.pusher_segment.pusher_segment_channel;
  private EVENT = environment.pusher_segment.pusher_segment_event;
  private pusher: any;
  private currentRoute: string;
  private workSpaceSegmentsData: Map<any, any> = new Map();
  private segmentsSubscription$: Subscription = new Subscription();
  private fromDate = new Date(
    moment()
      .subtract(29, 'days')
      .startOf('day')
      .valueOf()
  ).valueOf();
  private toDate = new Date(
    moment()
      .endOf('day')
      .valueOf()
  ).valueOf();
  isSegmentsDataFetching: any;

  constructor(
    private store: Store<{ auth }>,
    private filterHelperService: FilterHelperServiceNew,
    private authService: AuthService,
    private router: Router
  ) {
    this.pusher = this.authService.pusher;
    this.currentRoute = this.router.url;
    let channel = this.pusher.subscribe(this.CHANNEL);
    channel.bind(this.EVENT, response => {
      for (let status in response) {
        if (status == 'SEGMENT CREATED' || status == 'SEGMENT UPDATED' || status == 'SEGMENT DELETED') {
          this.getSegmentData('workspace');
        }
      }
    });
  }

  /**@description - Method to Get Segments Data from Backend*/
  public async getSegmentData(accountType): Promise<void> {
    const pageSize = 500;

    return new Promise<void>((resolve, reject) => {
      const subscription = this.store.pipe(select(fromSelector.getSegmentsData)).subscribe(
        response => {
          if (response && response['data']) {
            let modifiedSegmentData = this.modifySegmentsData(response['data']);
            this.transformSegmentsData(modifiedSegmentData);
            localStorage.segmentsData = JSON.stringify(Array.from(this.workSpaceSegmentsData.entries()));
            // this.segmentsSubscription$.unsubscribe();
            if (subscription) subscription.unsubscribe();
            resolve(); // Resolve the promise
          } else {
            this.store.dispatch(new GetSegmentsData({ page: 1, per_page: pageSize, accountType }));
          }
        },
        error => {
          console.error('An error occurred:', error);
          if (subscription) subscription.unsubscribe();
          reject(error); // Reject the promise
        }
      );
    });
  }

  /**@description Method To Rename Filter Keys From Segments*/
  public modifySegmentsData(segmentsData) {
    segmentsData.map(segments => {
      let updatedFilters = this.filterHelperService.modifyObjectKeys(segments.filter, '$', '.');
      segments.filter = updatedFilters;
    });
    return segmentsData;
  }

  /**@description - Method to Tranform Segments Data into Map*/
  public transformSegmentsData(segmentsData) {
    this.workSpaceSegmentsData.clear();
    for (let segmnets of segmentsData) {
      let buildingBlock = segmnets.buildingBlock;
      if (['Inventory', 'Order-Return', 'Draft', 'Order-Return-V2'].includes(buildingBlock)) {
        if (this.workSpaceSegmentsData.has(buildingBlock)) {
          let particularbuildingBlockSegments = this.workSpaceSegmentsData.get(buildingBlock);
          particularbuildingBlockSegments.push(segmnets);
          this.workSpaceSegmentsData.set(buildingBlock, particularbuildingBlockSegments);
        } else {
          // debugger;
          let name =
            buildingBlock.toLowerCase() === 'order-return'
              ? 'All orders'
              : buildingBlock.toLowerCase() === 'order-return-v2'
              ? 'All orders'
              : 'All';
          // let filters =
          //   buildingBlock.toLowerCase() === 'order-return'
          //     ? { date: `last30days-${this.fromDate}TO${this.toDate}` }
          //     : {};
          let filters =
            buildingBlock.toLowerCase() === 'order-return' || buildingBlock.toLowerCase() === 'order-return-v2'
              ? { createdInEshopbox: `last30days-${this.fromDate}TO${this.toDate}` }
              : {};
          let particularbuildingBlockSegments = [
            {
              buildingBlock: segmnets.buildingBlock,
              filterQuery: '',
              filter: filters,
              isCore: true,
              name: name
            }
          ];
          particularbuildingBlockSegments.push(segmnets);
          this.workSpaceSegmentsData.set(buildingBlock, particularbuildingBlockSegments);
        }
      } else {
        let buildingBlocksSegments = this.workSpaceSegmentsData.get('BuildingBlocks')
          ? this.workSpaceSegmentsData.get('BuildingBlocks').filter(f => f['buildingBlock'] === buildingBlock)
          : [];
        if (this.workSpaceSegmentsData.has('BuildingBlocks') && buildingBlocksSegments.length > 0) {
          let bbSeg =
            this.workSpaceSegmentsData.get('BuildingBlocks') !== undefined
              ? this.workSpaceSegmentsData.get('BuildingBlocks')
              : [];
          bbSeg.push(segmnets);
          this.workSpaceSegmentsData.set('BuildingBlocks', bbSeg);
        } else {
          let bbSegmentAll = {
            buildingBlock: segmnets.buildingBlock,
            filterQuery: '',
            filter: {},
            isCore: true,
            name: 'All'
          };

          let bbSeg =
            this.workSpaceSegmentsData.get('BuildingBlocks') !== undefined
              ? this.workSpaceSegmentsData.get('BuildingBlocks')
              : [];
          bbSeg.push(bbSegmentAll);
          bbSeg.push(segmnets);
          this.workSpaceSegmentsData.set('BuildingBlocks', bbSeg);
        }
      }
    }
  }

  /**@description - Method to Tranform Segments Data into Map*/
  // public transformSegmentsDataV2(segmentsData){
  //   this.workSpaceSegmentsData.clear();
  //   for (let segmnets of segmentsData) {
  //     let buildingBlock = segmnets.buildingBlock;
  //     if (['Inventory', 'Draft', 'Order-Return-V2'].includes(buildingBlock)) {
  //       if (this.workSpaceSegmentsData.has(buildingBlock)) {
  //         let particularbuildingBlockSegments = this.workSpaceSegmentsData.get(buildingBlock);
  //         particularbuildingBlockSegments.push(segmnets);
  //         this.workSpaceSegmentsData.set(buildingBlock, particularbuildingBlockSegments);
  //       } else {
  //         let name = buildingBlock.toLowerCase() === 'order-return-v2' ? 'All orders' : 'All';
  //         // let filters =
  //         //   buildingBlock.toLowerCase() === 'order-return'
  //         //     ? { date: `last30days-${this.fromDate}TO${this.toDate}` }
  //         //     : {};
  //         let filters =
  //           buildingBlock.toLowerCase() === 'order-return-v2'
  //             ? { createdInEshopbox: `last30days-${this.fromDate}TO${this.toDate}` }
  //             : {};
  //         let particularbuildingBlockSegments = [
  //           {
  //             buildingBlock: segmnets.buildingBlock,
  //             filterQuery: '',
  //             filter: filters,
  //             isCore: true,
  //             name: name
  //           }
  //         ];
  //         particularbuildingBlockSegments.push(segmnets);
  //         this.workSpaceSegmentsData.set(buildingBlock, particularbuildingBlockSegments);
  //       }
  //     }
  //     else if(['Order-Return'].includes(buildingBlock)) {
  //     }
  //     else {
  //       let buildingBlocksSegments = this.workSpaceSegmentsData.get('BuildingBlocks')
  //         ? this.workSpaceSegmentsData.get('BuildingBlocks').filter(f => f['buildingBlock'] === buildingBlock)
  //         : [];
  //       if (this.workSpaceSegmentsData.has('BuildingBlocks') && buildingBlocksSegments.length > 0) {
  //         let bbSeg =
  //           this.workSpaceSegmentsData.get('BuildingBlocks') !== undefined
  //             ? this.workSpaceSegmentsData.get('BuildingBlocks')
  //             : [];
  //         bbSeg.push(segmnets);
  //         this.workSpaceSegmentsData.set('BuildingBlocks', bbSeg);
  //       } else {
  //         let bbSegmentAll = {
  //           buildingBlock: segmnets.buildingBlock,
  //           filterQuery: '',
  //           filter: {},
  //           isCore: true,
  //           name: 'All'
  //         };

  //         let bbSeg =
  //           this.workSpaceSegmentsData.get('BuildingBlocks') !== undefined
  //             ? this.workSpaceSegmentsData.get('BuildingBlocks')
  //             : [];
  //         bbSeg.push(bbSegmentAll);
  //         bbSeg.push(segmnets);
  //         this.workSpaceSegmentsData.set('BuildingBlocks', bbSeg);
  //       }
  //     }
  //   }
  // }
}
