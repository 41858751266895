<div class="dialog-header">
  <h2 mat-dialog-title>Delete this scheduled report?</h2>
  <button mat-dialog-close>
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </button>
</div>
<mat-dialog-content>
  <div class="dialog-body">
    <p>We'll stop emailing the scheduled export CSV file. You can create new schedule report any time you wish.</p>
  </div>
  <div class="dialog-foot">
    <button mat-dialog-close class="btn-cancel">Cancel</button>
    <button class="btn-export" [class.primary-btn-loader]="loaderDelete" (click)="deleteSchedule()">Delete</button>
  </div>
</mat-dialog-content>
