<!-- Segment, Smart segment and My view -->
<app-segments [queryFilterData]="queryFilterData" [buildingBlock]="buildingBlock"></app-segments>
<!-- Segment, Smart segment and My view -->

<!-- Filters and Chip -->
<!-- To use filter pass the filter array from parent component -->
<ng-container *ngIf="filtersArray && filtersArray.length > 0">
  <app-reusable-filter
    [queryFilterData]="queryFilterData"
    (emitFilterData)="emitFilterData($event)"
    [buildingBlock]="buildingBlock"
    [allFilters]="filtersArray"
    (emitSearchKey)="searchInput($event)"
  ></app-reusable-filter>
</ng-container>
<!-- Filters and Chip -->
