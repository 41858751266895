import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppInstallationService {
  private API_URL = environment.APP_INSTALL;
  private APP_DETAILS_URI = environment.APP_DETAILS_URI;
  constructor(private http: HttpClient) {}

  /**
   * Get Application Scope
   */
  getAppScope(requestParams) {
    return this.http.get(this.API_URL + '?' + requestParams);
  }

  /**
   * Get Application Details
   */
  getAppDetails(routeparams) {
    return this.http.get(this.APP_DETAILS_URI + routeparams);
  }

  /**
   * Get Application Details
   */
  getAppInstallConsent(response) {
    return this.http.get(response.url, { params: response.params });
  }

  getAppInstalledPage(routeparams, response) {
    return this.http.get('v1/public/app-redirecturl/' + routeparams, { params: response });
  }

  getAppAuthorizeUrl(cilentId) {
    return this.http.get('v1/public/app-url/' + cilentId);
  }
  getFieldPage(appID, token) {
    let header = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    return this.http.get('v1/appinstall/' + appID + '/fields', { headers: header });
  }

  getInstallAppDetails(appID, token) {
    let header = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    return this.http.get('v1/appinstall/' + appID, { headers: header });
  }

  saveInstallAppDetails(token, body) {
    const headers = { Authorization: 'Bearer ' + token };

    return this.http.post<any>('v1/connection/start ', body, { headers });
    //  this.postId = data.id;
  }
  getAppIn(url, params) {
    return this.http.get(url, { params: params });
  }
}
