import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetContentByIdDetail, UninstallApp } from '../store/actions/install-app-list.action';
import { Store, select } from '@ngrx/store';
import * as fromSelector from '../store/selectors/install-app-list.selector';
import { UninstallDialogComponent } from '../uninstall-dialog/uninstall-dialog.component';
import { InstallAppListState } from '../store/state/install-app-list.state';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class InstalledAppListService {
  private API_URL = environment.INSTALLED_APP_LIST;
  accountslug: string;
  private searchSource = new BehaviorSubject<any>(null);
  searchText = this.searchSource.asObservable();

  private processingIcon = new BehaviorSubject<any>(null);
  Isprocessing = this.processingIcon.asObservable();

  private newStepArray = new BehaviorSubject<any>(null);
  isnewStepArray = this.newStepArray.asObservable();

  private panelOpening = new BehaviorSubject<any>(null);
  IspanelOpening = this.panelOpening.asObservable();
  setAutomationStep = {
    firstBlock: '',
    secondBlock: ''
    // isAmazon: true
  };
  isLoading: any;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<{ installedAppList: InstallAppListState }>,
    private dialog: MatDialog
  ) {
    this.accountslug = this.authService.getAccountSlug();
  }
  event(data: any) {
    this.searchSource.next(data);
  }
  eventProcessing(data: any) {
    this.processingIcon.next(data);
  }
  eventStep(data: any) {
    this.newStepArray.next(data);
  }
  eventPanelOpen(data: any) {
    this.panelOpening.next(data);
  }
  /**
   * Get Application Scope
   */
  getInstalledAppList(requestParams) {
    if (requestParams) return this.http.get(this.API_URL, { params: requestParams });
    else return this.http.get(this.API_URL + '?page=1&perPage=100');
  }

  getInstalledAppListDetail(requestParams) {
    return this.http.get(this.API_URL + '/' + requestParams);
  }

  uninstallApp(appId) {
    return this.http.delete(this.API_URL + '/' + appId);
  }

  getFieldPage(appID) {
    return this.http.get('api/v1/appinstall/' + appID + '/fields');
  }

  saveInstallAppDetails(body) {
    return this.http.post('api/v1/connection/start ', body);
  }

  testConnectionDetails(body) {
    const url = 'v1/ftpTestConnection';
    return this.http.post(url, body);
  }

  getConnectionbyId(connectionId) {
    return this.http.get('api/v1/appinstall/connection/' + connectionId);
  }
  getAllFullfilments() {
    return this.http.get('v2/warehouse?page=1&perPage=200');
  }
  updateLocationFulfilmnts(channelId, body) {
    return this.http.put('api/v1/connection/channel/' + channelId, body);
  }

  // If connected with Eshopbox's Seller Account ("isSellerOfRecord": "1") instead of 'updateLocationFulfilmnts' this api will be used.
  sellerLocationFulfilment(channelId, connectionId, body) {
    return this.http.put('api/v1/connection/channel/' + channelId + '?connectionId=' + connectionId, body);
  }

  getChannelsbyId(channelID) {
    return this.http.get('api/v1/connection/channel/' + channelID);
  }
  getSteplist(connectionId) {
    return this.http.get('api/v1/appinstall/config/' + connectionId, { params: { page: '1', per_page: '50' } });
  }
  // getCatlogCount(channelId) {
  //   return this.http.get('v1/appinstall/catalogcount/' + channelId);
  // }
  getSyncJobCatlog(channelId) {
    return this.http.get('api/v1/appinstall/syncjob/latest/catalog/' + channelId);
  }

  stopSyncJob(jobId, stopdata) {
    return this.http.put('api/v1/appinstall/syncjob/' + jobId, stopdata);
  }
  createSyncJobCatlog(body) {
    return this.http.post('api/v1/appinstall/syncjob', body);
  }
  getNykaaProductSync() {
    return this.http.get(
      'https://custom-oms-integration-staging.el.r.appspot.com/_ah/api/esb/v1/nykaa/product/sync/cron'
    );
  }

  onsaveList(body) {
    return this.http.put('api/v1/appinstall/config/toggle', body);
  }
  onDiconnection(connectionId, body) {
    return this.http.put('api/v1/appinstall/connection/' + connectionId, body);
  }
  getContentFulDetails(contentFulId) {
    let token = 'hPpYHYym0eg2erZ5W3QwHCu3T0d38UZheTSP_h2a0Ns';
    let header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get('cdn.contentful.com/spaces/5vr74qnuzptb/environments/master/entries/' + contentFulId, {
      headers: header
    });
  }
  getMainStepListApi(connectionId) {
    return this.http.get('api/v1/appinstall/setup-steps/' + connectionId);
  }

  getShopifyFulfillmentLocation(connectId) {
    return this.http.get('api/v1/appinstall/connection/' + connectId + '/location');
  }

  putMainStepListApi(body) {
    return this.http.put('api/v1/appinstall/setup-steps', body);

    // http://{{workspace}}.auperator.co/api/v1/appinstall/setup-steps
  }
  updateNewConnectionName(channelId, body) {
    return this.http.put('api/v1/appinstall/connection/' + channelId, body);
  }

  // Get shopify shipping providers list
  getShippingProviders() {
    return this.http.get('api/v1/connection/shipping');
  }

  saveCustomApp(body) {
    return this.http.post('v1/customapp', body);
  }

  getCustomAppDetailByAppId(appId) {
    return this.http.get('/api/v1/app/' + appId);
  }

  updateAppintoSales(body) {
    return this.http.post('v1/appinstall/custom/saleschannel', body);
  }

  getAppintoSales(installationId) {
    return this.http.get('v1/appinstall/custom/saleschannel/' + installationId);
  }
  //ViewInformation popup
  showContentfull(contentFullID) {
    let isLoading;
    if (contentFullID !== null) {
      this.store.dispatch(new GetContentByIdDetail(contentFullID));
      const subscription = this.store.pipe(select(fromSelector.getContentByIdDetail)).subscribe(data => {
        if (data) {
          isLoading = false;
          this.dialog.open(UninstallDialogComponent, {
            width: '550px',
            data: data['fields']['pageDescription']
          });
          subscription.unsubscribe();
        }
      });
      const dispatchCompletionCallback = () => {
        if (!this.isLoading) {
          return;
        }
        setTimeout(dispatchCompletionCallback, 100);
      };
      dispatchCompletionCallback();
    }
    return isLoading;
  }
  getWhatsAppPhoneNumberId(token) {
    return this.http.get('api/v1/appinstall/whatsApp/accessTokenVerify?access_token=' + token);
  }
  wooTaxClass(body) {
    return this.http.post('/woocommerce/api/v1/taxClasses', body);
  }
  getCredential(body) {
    return this.http.post('api/v1/appinstall/connectionCredential', body);
  }
}
