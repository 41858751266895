import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as ActionList from '../actions/product.action';
import { ProductService } from '../../../shared/services/product.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChannelsService } from 'src/app/shared/services/channels.service';
import { InventoryBreakupService } from '../../components/stock/inventory-breakup.service';
@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private channelService: ChannelsService,
    private inventoryBreakupService: InventoryBreakupService
  ) {}

  @Effect()
  ProductList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetProductList>(ActionList.ProductActionsTypes.GET_PRODUCT_LIST),
    mergeMap(action => {
      return this.productService.getProductList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_PRODUCT_LIST_SUCCESS,
          payload: data
        })),
        catchError(() => of({ type: ActionList.ProductActionsTypes.GET_PRODUCT_LIST_FAILURE }))
      );
    })
  );

  @Effect()
  DraftProductList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetProductList>(ActionList.ProductActionsTypes.GET_DRAFT_PRODUCT_LIST),
    mergeMap(action => {
      return this.productService.getProductList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_DRAFT_PRODUCT_LIST_SUCCESS,
          payload: data
        })),
        catchError(() => of({ type: ActionList.ProductActionsTypes.GET_DRAFT_PRODUCT_LIST_FAILURE }))
      );
    })
  );

  @Effect()
  InventoryLastUpdate$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.InventoryLastUpdateAction>(ActionList.ProductActionsTypes.GET_LAST_INVENTORY_UPDATE),
    mergeMap(action => {
      return this.productService.inventoryLastUpdate(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_LAST_INVENTORY_UPDATE_SUCCCESS,
          payload: data
        })),
        catchError(err => of({ type: ActionList.ProductActionsTypes.GET_LAST_INVENTORY_UPDATE_FAILURE, payload: err }))
      );
    })
  );

  @Effect()
  InventoryLogsUpdate$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.InventoryUpdateLogsAction>(ActionList.ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS),
    mergeMap(action => {
      return this.productService.inventoryLogsUpdate(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS_SUCCCESS,
          payload: data
        })),
        catchError(err => of({ type: ActionList.ProductActionsTypes.GET_INVENTORY_UPDATE_LOGS_FAILURE, payload: err }))
      );
    })
  );

  @Effect()
  stockAgeing$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetStockAgeingAction>(ActionList.ProductActionsTypes.GET_STOCK_AGEING),
    mergeMap(action => {
      return this.productService.getStockAgeing(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_STOCK_AGEING_SUCCESS,
          payload: data
        })),
        catchError(error => of({ type: ActionList.ProductActionsTypes.GET_STOCK_AGEING_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  createProduct$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.CreateProduct>(ActionList.ProductActionsTypes.CREATE_PRODUCT),
    mergeMap(action => {
      return this.productService.createProduct(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.CREATE_PRODUCT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.CREATE_PRODUCT_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  createBulkProduct$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.CreateBulkProduct>(ActionList.ProductActionsTypes.CREATE_BULK_PRODUCT),
    mergeMap(action => {
      return this.productService.createBulkProduct(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.CREATE_BULK_PRODUCT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.CREATE_BULK_PRODUCT_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  editProduct$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.EditProduct>(ActionList.ProductActionsTypes.EDIT_PRODUCT),
    mergeMap(action => {
      return this.productService.editProduct(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.EDIT_PRODUCT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.EDIT_PRODUCT_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  skuCheck$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.SKUCheck>(ActionList.ProductActionsTypes.SKU_CHECK),
    mergeMap(action => {
      return this.productService.checkSKUExistOrNot(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.SKU_CHECK_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.SKU_CHECK_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  brands$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetBrand>(ActionList.ProductActionsTypes.GET_BRAND),
    mergeMap(action => {
      return this.productService.getBrandAndVertical(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_BRAND_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.GET_BRAND_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  propertyList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetPropertyList>(ActionList.ProductActionsTypes.GET_PROPERTY_LIST),
    mergeMap(action => {
      return this.productService.getPropertyList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_PROPERTY_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.GET_PROPERTY_LIST_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  bulkUploadStatus$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.SheetStatusCheck>(ActionList.ProductActionsTypes.DOCUMENT_UPLOAD_STATUS),
    mergeMap(action => {
      return this.productService.getBulkUploadDocumentStatus(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.DOCUMENT_UPLOAD_STATUS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.DOCUMENT_UPLOAD_STATUS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  // EXPORT JOB EFFECT

  @Effect()
  exportJob$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.ExportJob>(ActionList.ProductActionsTypes.EXPORT_JOB),
    mergeMap(action => {
      return this.productService.createExportJob(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.EXPORT_JOB_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.EXPORT_JOB_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  filters$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetFilters>(ActionList.ProductActionsTypes.GET_FILTERS),
    mergeMap(action => {
      return this.productService.getFilters().pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_FILTERS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.GET_FILTERS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  // INVENTORY DETAILS
  @Effect()
  inventoryDetails$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetInventory>(ActionList.ProductActionsTypes.GET_INVENTORY),
    mergeMap(action => {
      return this.productService.getInventoryDetails(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_INVENTORY_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.GET_INVENTORY_FAILURE,
            payload: error
          })
        )
      );
    })
  );
  // WARE HOUSE LIST
  @Effect()
  warehouseList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetWarehouseList>(ActionList.ProductActionsTypes.GET_WAREHOUSE),
    mergeMap(action => {
      return this.productService.getWarehouseList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_WAREHOUSE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.GET_WAREHOUSE_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  // CHANNEL LIST

  @Effect()
  channelList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.ChannelList>(ActionList.ProductActionsTypes.CHANNEL_LIST),
    mergeMap(action => {
      return this.productService.channelList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.CHANNEL_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.CHANNEL_LIST_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  // Product Availablity

  @Effect()
  productAvailability$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.ProductAvailability>(ActionList.ProductActionsTypes.PRODUCT_AVAILABILITY),
    mergeMap(action => {
      return this.productService.productAvailability(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.PRODUCT_AVAILABILITY_SUCCESS,
          payload: data
        })),
        catchError(error => {
          if (error) {
            if (error['error']) {
              if (error['error']['error']) {
                if (error['error']['error']['message']) {
                  if (error['error']['error']['message'].includes('availability')) {
                    return of({
                      type: ActionList.ProductActionsTypes.PRODUCT_AVAILABILITY_FAILURE,
                      payload: JSON.parse(error['error']['error']['message'])
                    });
                  } else {
                    return of({
                      type: ActionList.ProductActionsTypes.PRODUCT_AVAILABILITY_FAILURE,
                      payload: error['error']['error']['message']
                    });
                  }
                }
              }
              // else {
              //   return of({
              //     type: ActionList.ProductActionsTypes.PRODUCT_AVAILABILITY_FAILURE,
              //     payload: error['error']
              //   });
              // }
            }
          }
        })
      );
    })
  );

  @Effect()
  getPortalsList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetPortalsAction>(ActionList.ProductActionsTypes.GET_PORTALS_LIST),
    mergeMap(action => {
      return this.channelService.getPortalList().pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_PORTALS_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.GET_PORTALS_LIST_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getFromProductList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetFromProductAction>(ActionList.ProductActionsTypes.GET_FROM_PRODUCT),
    mergeMap((action: any) => {
      return this.productService.getProductList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_FROM_PRODUCT_SUCCESS,
          payload: data
        })),
        catchError(error => of({ type: ActionList.ProductActionsTypes.GET_FROM_PRODUCT_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getToProductList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetToProductAction>(ActionList.ProductActionsTypes.GET_TO_PRODUCT),
    mergeMap((action: any) => {
      return this.productService.getProductList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_TO_PRODUCT_SUCCESS,
          payload: data
        })),
        catchError(error => of({ type: ActionList.ProductActionsTypes.GET_TO_PRODUCT_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  getSuggestionProductList$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetToProductAction>(ActionList.ProductActionsTypes.GET_SUGGESTION_PRODUCT),
    mergeMap((action: any) => {
      return this.productService.getProductList(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_SUGGESTION_PRODUCT_SUCCESS,
          payload: data
        })),
        catchError(error => of({ type: ActionList.ProductActionsTypes.GET_SUGGESTION_PRODUCT_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  mergeProduct$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.MergeProductAction>(ActionList.ProductActionsTypes.MERGE_PRODUCTS),
    mergeMap((action: any) => {
      return this.productService.mergeProduct(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.MERGE_PRODUCTS_SUCCESS,
          payload: data
        })),
        catchError(error => of({ type: ActionList.ProductActionsTypes.MERGE_PRODUCTS_FAILURE, payload: error }))
      );
    })
  );

  @Effect()
  deleteDraftProduct$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.DeleteDraftProductAction>(ActionList.ProductActionsTypes.DELETE_DRAFT_PRODUCTS),
    mergeMap(action => {
      return this.productService.deleteDraftProduct(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.DELETE_DRAFT_PRODUCTS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: ActionList.ProductActionsTypes.DELETE_DRAFT_PRODUCTS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  getInventoryStocklist$: Observable<Action> = this.actions$.pipe(
    ofType<ActionList.GetInventorySTockList>(ActionList.ProductActionsTypes.GET_INVENTORY_LIST),
    mergeMap(action => {
      return this.inventoryBreakupService.getInventoryReport(action.payload).pipe(
        map(data => ({
          type: ActionList.ProductActionsTypes.GET_INVENTORY_LIST_SUCCESS,
          payload: data
        })),
        catchError(error => of({ type: ActionList.ProductActionsTypes.GET_INVENTORY_LIST_FAILURE, payload: error }))
      );
    })
  );
}
