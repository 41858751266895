export const PATTERNS = {
  SUB_DOMAIN: /(?:[\s.])([a-z0-9][a-z0-9-]+[a-z0-9]\.auperator\.co)/,
  SUB_DOMAIN_STRING: /^[a-z0-9_-]+$/,
  // tslint:disable-next-line:max-line-length
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_EXCLUDE_ESHOPBOX: /^(?!.*@eshopbox\.com)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^[1-9]{1}[0-9]{9}$/,
  PAN_CARD: /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}?$/,
  SLUG: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/,
  //   PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{3,}$/,
  PINCODE: /^[1-9][0-9]{5}$/,
  NUMBER: /^[0-9]*$/,
  STRING: /^[A-Za-z]/,
  URL: /\./,
  IFSC: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
  GSTIN: /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/
};

export const VALIDATION_MESSAGES = {
  firstName: [{ type: 'required', message: 'Enter First Name' }],
  lastName: [{ type: 'required', message: 'Enter Last Name' }],
  email: [
    { type: 'required', message: 'Enter an Email' },
    { type: 'pattern', message: 'Enter a valid Email address' }
  ],
  phoneNumber: [
    { type: 'required', message: 'Enter a Phone Number' },
    { type: 'pattern', message: 'Please enter 10 digit phone number.' }
  ],
  primaryPhone: [
    { type: 'required', message: 'Enter a Phone Number' },
    { type: 'pattern', message: 'Enter a valid Phone Number' }
  ],
  companyName: [{ type: 'required', message: 'Enter the name of the company' }],
  softwareName: [
    { type: 'required', message: 'Enter the name of the Software' },
    {
      type: 'pattern',
      message: 'Enter a valid domain'
    }
  ],
  soapApiUsername: [{ type: 'required', message: 'Enter API Username' }],

  confirmPassword: [
    { type: 'required', message: 'Enter a Confirm password' },
    { type: 'notEqual', message: 'The password does not match' }
  ],
  password: [
    { type: 'required', message: 'Enter a Password' },
    {
      type: 'minlength',
      message: 'The password length should be atleast 8 characters long'
    },
    {
      type: 'pattern',
      message: 'Kindly use combination of Alphanumeric and special characters'
    }
  ],

  soapApiPassword: [
    { type: 'required', message: 'Enter a API Password' },

    {
      type: 'pattern',
      message: 'Kindly use combination of Alphanumeric and special characters'
    }
  ],
  accountSlug: [
    { type: 'required', message: 'Enter a domain' },
    {
      type: 'alreadyExists',
      message: 'This domain is already taken'
    },
    {
      type: 'pattern',
      message: 'Enter a valid domain'
    }
  ],
  country: [{ type: 'required', message: 'Select a country' }],
  fcDetails: [{ type: 'required', message: 'Select the fulfillment center' }],
  fcTraceability: [{ type: 'required', message: 'Select inventory traceability' }],
  facilityCode: [{ type: 'required', message: 'Enter the Facility Code' }],
  externalWmsAccountDetails: [{ type: 'required', message: 'Select the Order Management Software' }],

  addressLine2: [{ type: 'required', message: 'Enter the Street Address' }],
  addressLine1: [{ type: 'required', message: 'Enter the Street Address' }],
  city: [{ type: 'required', message: 'Select a city' }],
  pincode: [
    { type: 'required', message: 'Enter a pincode' },
    { type: 'pattern', message: 'Enter a Valid Pincode' }
  ],
  state: [{ type: 'required', message: 'Select a state' }],
  accountNumber: [
    { type: 'required', message: 'Enter the Account Number' },
    { type: 'pattern', message: 'Enter a valid account number' }
  ],
  bankName: [{ type: 'required', message: 'Enter the Bank name' }],
  ifscCode: [
    { type: 'required', message: 'Enter the IFSC code' },
    { type: 'pattern', message: 'Enter a valid IFSC code' }
  ],
  branch: [{ type: 'required', message: 'Enter the Branch name' }],
  cancelledChequeCopy: [{ type: 'required', message: 'Upload cancelled cheque' }],
  panNumber: [
    { type: 'required', message: 'Enter the PAN number' },
    { type: 'pattern', message: 'Enter a valid PAN number' }
  ],
  pancardCopy: [{ type: 'required', message: 'Upload PAN card' }],
  gstCertificateFileName: [{ type: 'required', message: 'Upload GST Certificate' }],
  signatureFileName: [{ type: 'required', message: 'Upload Signature' }],
  companyLogoName: [{ type: 'required', message: 'Upload Company Logo' }],

  partyName: [{ type: 'required', message: 'Enter a name for the warehouse' }],
  integrationModel: [{ type: 'required', message: 'Please select the fulfillment model' }],
  channelName: [{ type: 'required', message: 'Enter Account Name' }],
  portalDetails: [{ type: 'required', message: 'Please select the Portal' }],
  fulfillmentChannels: [{ type: 'required', message: 'Please select the Fulfillment Channel' }],
  channelAccountType: [{ type: 'required', message: 'Please select the Seller Account' }],
  channelAccountDetails: [{ type: 'required', message: 'Please select the Channel' }],
  ledgerList: [{ type: 'required', message: 'Please select a Ledger' }],

  refPartyId: [{ type: 'required', message: 'Enter id for the warehouse' }],
  gstin: [
    { type: 'required', message: 'Enter a GSTIN' },
    { type: 'pattern', message: 'Enter a valid GSTIN' }
  ],
  brandName: [{ type: 'required', message: 'Enter the Brand name' }],
  webSiteLink: [{ type: 'pattern', message: 'Enter a valid url' }],
  verticals: [{ type: 'required', message: 'Select a category' }],
  brandLogoImage: [{ type: 'required', message: 'Upload the brand logo' }],
  brandStatus: [{ type: 'required', message: 'Select a status' }],
  role: [{ type: 'required', message: 'Select a role' }],
  groupName: [{ type: 'required', message: 'Enter the Group name' }],
  status: [{ type: 'required', message: 'Select a status' }],
  userGroups: [{ type: 'required', message: 'Select a Group' }],
  transferDate: [{ type: 'required', message: 'Select a Transfer Date' }],
  transferAmount: [
    { type: 'required', message: 'Enter the Fund Transfer amount.' },
    { type: 'pattern', message: 'Enter valid Fund Transfer amount.' }
  ],
  neftId: [
    { type: 'required', message: 'Enter Transaction Id' },
    {
      type: 'alreadyExists',
      message: 'This transaction id already exists.'
    }
  ],
  contactPerson: [{ type: 'required', message: 'Enter the contact person for the location.' }]
};

export const ALERT_MESSAGES = {
  NO_INTERNET_CONNECTION: 'Please Check Internet Connectivity',
  SOMETHING_WRONG: 'Something went wrong, please try again.',
  USER_ROLE_INVALID: 'User role is invalid',
  SLUG_NAME_EXIST: 'Domain <slug name> already exist.',
  UPLOAD_DOCUMENT_ALERT: 'Please upload the document',
  UPLOAD_SUCCESS: 'Acknowledgment slip submitted successfully.',
  NO_ENROLLED_ACCOUNT: 'No Enrolled Account',
  CHANNEL_SUBSCRIBED_SUCCESS: 'Your Sales channel addition request is under process.',
  CHANNEL_ACCOUNT_ADDED: 'Your seller account successfully added.',
  ALREADY_ENROLLED: 'The FC you’he selected is already added with Eshopbox Unicommerce account.',
  VERIFICATION_LINK_INVALID: 'Verification link is corrupted, Please try again.',
  DO_UPLOAD: 'Please Upload the documents.',
  ADDED_BRAND: 'Brand <brandName> Added Successfully',
  UPDATED_BRAND: 'Brand <brandName> Updated Successfully',
  INVITATION_SENT: 'Invitation to <email> sent successfully',
  UPDATED_MEMBER: 'Team member updated successfully',
  ADDED_WAREHOUSE: 'Location <warehouseName> Added Successfully ',
  ADDED_WAREHOUSE_FCENABLED: 'Your self fulfilment location <warehouseName> is added successfully.',
  UPDATED_WAREHOUSE: 'Location <warehouseName> Updated Successfully',
  USER_NOT_ACCEPTED: 'You donot have access to any account. You will be redirected to Signup',
  INACTIVE_USER: 'Your account is inactive, kindly contact care@eshopbox.com',
  DOMAIN_SUCCESS: 'Domain <domainName> has been assigned to your workspace.',
  WMS_ACCOUNT_ADDED: 'Software Account successfully added.',
  CONSIGNMENT_MODEL_SUCCESSS: 'Enable fulfillment center request submitted for <Facilitylabel>',
  BILLING_MODEL_SUCCESSS: 'Warehouse <WarehouseName> added successfully.',
  GPOUP_ADDED_SUCCESSFULLY: 'Group <groupName> added successfully',
  CHANNEL_UPDATED_SUCCESS: 'Fulfilment centre subscription request accepted successfully',
  NO_CHANNEL_CREATED: 'Sales channel not created for the selected portal',
  ADDED_TRANSACTIONRULES: 'Payment terms <transactionRuleName> added successfully',
  UPDATED_TRANSACTIONRULES: 'Payment terms <transactionRuleName> updated successfully',
  CANCEL_CONSIGNMENT: 'Consignment <consignmentNumber> successfully cancelled',
  CANCEL_ORDER: 'Order <consignmentNumber> successfully cancelled',
  UPDATE_CONSIGNMENT: 'Consignment <consignmentNumber> successfully updated',
  INVOICE_UPDATE: 'Label details are saved sucessfully',
  FAILED_TOKEN_UPDATE: 'Something went wrong, Failed to get updated token',
  UPDATE_ORDER: 'Order <consignmentNumber> successfully updated'
};

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const SORT_TYPE_INVOICES = [
  { label: 'Oldest to Newest (Fund Transfer Date)', value: 'asc' },
  { label: 'Newest to Oldest (Fund Transfer  Date)', value: 'desc' }
];

export const SORT_TYPE_EXPENSE = [
  { label: 'Oldest to Newest (Fund Transfer Date)', value: { value: 'invoiceDate', sortType: 'asc' } },
  { label: 'Newest to Oldest (Fund Transfer Date)', value: { value: 'invoiceDate', sortType: 'desc' } }
];
