<!--  Enable self fulfilment for a location -->
<section class="popup-page" *ngIf="pathParam === 'enabling-self-fulfilment'; else noAccess">
  <div class="page-inner">
    <div class="page-content page-access-denied">
      <div class="sub-header">
        <img
          class="mtop-4"
          src="https://ik.imagekit.io/2gwij97w0o/single_page/Upgrade-Filled.svg?updatedAt=1683023559937"
        />
        Enable self fulfilment for a location
      </div>
      <h1>Ship from your own location</h1>
      <p>
        Introducing Eshopbox self fulfilment. You can now process and ship orders from your own location. To get
        started, you need to add a location with self fulfilment enabled.
      </p>
      <button class="success" [routerLink]="['/fc/home']" [queryParams]="{ selectedTab: 'location' }">
        Enable self fulfilment
      </button>
      <br />
      <p>
        <a
          class="help-article"
          href="https://help.eshopbox.com/en/articles/4223805-manage-your-location"
          target="_blank"
        >
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/learn-more.svg" />
          Learn how to add a self fulfilment location</a
        >
      </p>
    </div>
    <img
      src="https://ik.imagekit.io/2gwij97w0o/single_page/enabling-self-fulfilment.svg?updatedAt=1683023392301"
      class="page-image"
    />
  </div>
</section>
<!--  Enable self fulfilment for a location -->

<!--  Access denied! -->
<ng-template #noAccess>
  <section class="popup-page" [ngClass]="{ 'without-header': workspace || location, 'mt-0': bbHandleBar }">
    <div class="page-inner">
      <div class="page-content page-access-denied">
        <div class="sub-header heading" *ngIf="!bbHandleBar">
          <img class="mtop-5" src="https://ik.imagekit.io/2gwij97w0o/single_page/lock-on.svg?updatedAt=1683024845092" />
          Access denied!
          <!-- <span *ngIf="workspace"> {{ workspace | strFormatter | sentenceCase }}</span> -->
        </div>
        <ng-container *ngIf="bbHandleBar">
          <span class="no_inventory">
            This feature is not supported for the location with <strong>no inventory management</strong>. You need to
            have a location with inventory management enabled to use this feature.
            <a href="https://help.eshopbox.com/en/articles/4223805-manage-your-location" target="_blank">
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/learn-more.svg" />
              Learn more</a
            >
          </span>
        </ng-container>
        <ng-container *ngIf="!bbHandleBar">
          <p>
            You do not have the required permission to access
            <strong *ngIf="!workspace">{{ pathParam | strFormatter | sentenceCase }}</strong>
            <strong *ngIf="workspace">{{ workspace | strFormatter }}</strong
            >.<br class="no-mobile" />
            Please contact your admin to obtain the necessary access.
          </p>
          <p>
            <a
              class="help-article"
              href="https://help.eshopbox.com/en/articles/8383615-adding-a-team-member-to-your-eshopbox-workspace"
              target="_blank"
            >
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/learn-more.svg" />
              Learn how to edit team permissions</a
            >
          </p>
          <br />
          <div class="multiple-button-container single-button" *ngIf="workspace">
            <button class="action-button" (click)="logOut()">
              Logout
            </button>
          </div>

          <div class="multiple-button-container" *ngIf="!workspace && isMobile">
            <button
              *ngIf="numberOfWorkspace && numberOfWorkspace > 1"
              (click)="switchWorkspace()"
              class="action-button switch-workspace-button"
            >
              Switch workspace
            </button>
            <button class="action-button" (click)="hasFlex ? goBack() : logOut()">
              {{ hasFlex ? 'Go back' : 'Logout' }}
            </button>
          </div>
        </ng-container>
      </div>
      <img src="https://ik.imagekit.io/2gwij97w0o/single_page/do-not-have-access-v3.svg" class="page-image" />
    </div>
  </section>
</ng-template>
<!--  Access denied! -->
