<div class="order-otr-return">
  <div class="order-innr-return order-full-scroll">
    <!-- <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="50" class="viewport" style="width:100%;"> -->
    <div class="scroll-div">
      <!-- If segment API reponse is pending | START -->
      <ng-container *ngIf="isSegmentLoading || allSegments.length == 0 || !groupedData">
        <div class="order-tabs" *ngFor="let item of [].constructor(3); let in = index">
          <h2>
            <ng-container
              *ngTemplateOutlet="
                skeletonThemeTemplate;
                context: { theme: { 'border-radius': '5', height: '12px', width: '40px', margin: '0px' } }
              "
            ></ng-container>
          </h2>
          <ul>
            <ng-container *ngFor="let item of [].constructor(in == 0 ? 3 : 4)">
              <li><ng-container *ngTemplateOutlet="segmentLoadingState"></ng-container></li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
      <!-- If segment API reponse is pending | END -->

      <!-- If segments are fetched -->
      <ng-container *ngIf="!isSegmentLoading && allSegments.length > 0 && groupedData">
        <div class="order-tabs" *ngFor="let group of groupedData | convertObjectToIterator">
          <h2>{{ group | sentenceCase }}</h2>
          <ul>
            <ng-container *ngFor="let segment of groupedData[group]; let in = index">
              <li *ngIf="segment?.filterQuery" class="segment-item">
                <a
                  [id]="segment?.id"
                  (click)="emitSegmentsData(segment, true)"
                  [ngClass]="{
                    active: segment?.id === activeSegmentId && activeSmartSegmentId && !searchQuery,
                    active_full_border: segment?.id === activeSegmentId && !activeSmartSegmentId
                  }"
                  [tooltip]="segment?.description"
                  [options]="TOOLTIP_CONFIG"
                  reusableTooltip
                >
                  <!-- If show count -->
                  <ng-container *ngIf="segment?.showCount">
                    <!-- Show count -->
                    <ng-container *ngIf="!isSegmentCountLoading || (segmentsCount && segmentsCount[segment?.id])">
                      <b>{{ segmentsCount[segment?.id] | number }}</b>
                    </ng-container>

                    <!-- Show count shimmer -->
                    <ng-container *ngIf="isSegmentCountLoading && (!segmentsCount || !segmentsCount[segment?.id])">
                      <b class="loader-icon">
                        <ng-container
                          *ngTemplateOutlet="
                            skeletonThemeTemplate;
                            context: { theme: { 'border-radius': '5', height: '12px', width: '40px', margin: '0px' } }
                          "
                        ></ng-container>
                      </b>
                    </ng-container>
                  </ng-container>
                  <!-- Icon -->
                  <span *ngIf="segment?.iconType && segment?.iconType === 'attention_icon.png'" class="alert-icon"
                    ><img
                      src="https://ik.imagekit.io/2gwij97w0o/alert-filed-bg.svg?updatedAt=1712219336617"
                      alt="need_attention"
                  /></span>

                  <!-- Segment name -->
                  <p>{{ segment?.name }}</p>
                </a>
              </li>
            </ng-container>

            <!-- DRAFT RETURN Special handling for "All orders" page || START -->
            <li *ngIf="buildingBlock == ALL_ORDERS && group == 'Returns'">
              <a
                (click)="openDraftReturnDialog()"
                class="tooltip-segment-hover"
                [tooltip]="DRAFT_RETURN_DESCRIPTION"
                [options]="TOOLTIP_CONFIG"
                reusableTooltip
              >
                <b
                  ><span
                    ><img
                      width="12px"
                      src="https://ik.imagekit.io/2gwij97w0o/external-link-bold.svg?updatedAt=1686293581910"
                      alt="redirect_btn"/></span
                ></b>
                <p>Draft returns</p>
              </a>
            </li>
            <!-- DRAFT RETURN Special handling for "All orders" page || END -->
          </ul>
        </div>
      </ng-container>
      <!-- If segments are fetched -->
      <!-- </div> -->
      <!-- </cdk-virtual-scroll-viewport> -->
      <!-- My view -->
      <div class="order-tabs view-totals">
        <ul>
          <li>
            <!-- My view loading state -->
            <ng-container *ngIf="isSegmentLoading; else showMyView">
              <ng-container *ngTemplateOutlet="segmentLoadingState"></ng-container>
            </ng-container>
            <!-- My view -->
            <ng-template #showMyView>
              <a (click)="loadMyView(false)" [ngClass]="{ active: activeSegmentId === 'myView' && !searchQuery }">
                <b>{{ myView?.count }}</b>
                <p>My views</p>
              </a>
            </ng-template>
            <!-- success message start  -->
            <div class="save-views-segment" *ngIf="newSegmentCreated">
              <span class="views-icon"
                ><img src="https://ik.imagekit.io/2gwij97w0o/file-save.svg?updatedAt=1715756699719" alt="file-save"
              /></span>
              <span class="view-text-successfully">View saved successfully under my views.</span>
              <img
                class="close-icon"
                (click)="newSegmentCreated = false"
                src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_icon.svg?updatedAt=1626337817356"
                alt="close popup icon"
              />
            </div>
            <!-- success message end  -->
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- when no my views start -->
  <div class="order-innr-return" *ngIf="activeSegmentId === 'myView' && myView?.count == 0">
    <p class="no-views">
      <span class="info-icon"
        ><img
          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg?updatedAt=1621935776916"
      /></span>
      To create a custom view, apply the required filters and save it as a view.
      <!-- Learn more -->
      <!-- <span class="learn-more-no-views">
        <a class="learnmore-heading" href="#" target="_blank"><img
            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/learn-more.svg" />
          Learn More
        </a>
      </span> -->
    </p>
  </div>
  <!-- when no my views end -->

  <!-- SMART SEGMENT | START -->

  <!-- SMART SEGMENT LOADER | START -->
  <div class="order-innr-return" *ngIf="!searchQuery && isSegmentLoading && activeSegmentId !== 'myView'">
    <div class="filter-chips-container">
      <div class="filter-form-inner chip-filter_loader">
        <ng-container *ngTemplateOutlet="smartSegmentLoaderTemplate; context: { count: 5 }"></ng-container>
        <ng-container *ngTemplateOutlet="smartSegmentLoaderTemplate; context: { count: 6 }"></ng-container>
        <ng-container *ngTemplateOutlet="smartSegmentLoaderTemplate; context: { count: 1 }"></ng-container>
      </div>
    </div>
  </div>

  <!-- SMART SEGMENT LOADER | END -->

  <div
    class="order-innr-return segment-space-new"
    *ngIf="
      !searchQuery &&
      !isSegmentLoading &&
      currentSmartSegments.length > 0 &&
      (activeSegmentId !== 'myView' || (activeSegmentId === 'myView' && myView?.count != 0))
    "
  >
    <div class="filter-chips-container">
      <div class="chip-element-heading">
        <ng-container *ngFor="let subGroup of groupedSmartSegment | convertObjectToIterator">
          <!-- Subgroup only for all orders page 'need attetion' segment | segmentId: 666166b7f83fab2f09ac5b7e -->
          <p *ngIf="activeSegmentId == '666166b7f83fab2f09ac5b7e' || activeSegmentId == '66cefe8759f39a4378f55deb'">
            {{ subGroup | sentenceCase }}
          </p>
          <div class="filter-form-inner filter-space-none">
            <ng-container *ngFor="let group of groupedSmartSegment[subGroup] | convertObjectToIterator">
              <ng-container *ngIf="groupedSmartSegment[subGroup][group].length > 0">
                <div class="element-filed chip-field chip-filter-wrap">
                  <ng-container
                    *ngFor="
                      let smartSeg of groupedSmartSegment[subGroup][group].slice(0, dataCount);
                      let in = index;
                      let first = first;
                      let last = last;
                      trackBy: trackByIndex
                    "
                  >
                    <button
                      *ngIf="first && group === 'needAttentionGroup'"
                      disableRipple
                      mat-stroked-button
                      class="form-button-filter-field attention-btn"
                      [ngClass]="{ 'first-chip': first, 'last-chip': last && first != last }"
                      disabled
                    >
                      <img
                        *ngIf="smartSeg?.iconType && smartSeg?.iconType === 'attention_icon.png'"
                        class="clear-chip"
                        src="https://ik.imagekit.io/2gwij97w0o/alert-filed-bg.svg?updatedAt=1712219336617"
                        alt="need_attention"
                      />
                    </button>
                    <button
                      (click)="emitSegmentsData(smartSeg, false)"
                      [id]="smartSeg?.id"
                      disableRipple
                      mat-stroked-button
                      class="form-button-filter-field"
                      [ngClass]="{
                        btn_bg_light: smartSeg?.id === activeSmartSegmentId && !searchQuery,
                        'first-chip': first,
                        'last-chip': last,
                        'attention-btn-added': first && group === 'needAttentionGroup',
                        'tooltip-segment-hover': smartSeg?.description
                      }"
                      [tooltip]="smartSeg?.description"
                      [options]="TOOLTIP_CONFIG"
                      reusableTooltip
                    >
                      <!-- If show count -->
                      <ng-container *ngIf="smartSeg?.showCount">
                        <!-- Show count -->
                        <span
                          class="condition-text"
                          *ngIf="!isSmartSegmentCountLoading || (segmentsCount && segmentsCount[smartSeg?.id])"
                        >
                          <span [ngClass]="{ total_count: segmentsCount[smartSeg?.id] }">{{
                            segmentsCount[smartSeg?.id]
                          }}</span>
                        </span>

                        <!-- Show count shimmer -->
                        <span
                          class="loader-icon loader-size-resp"
                          *ngIf="isSmartSegmentCountLoading && (!segmentsCount || !segmentsCount[smartSeg?.id])"
                        >
                          <ng-container
                            *ngTemplateOutlet="
                              skeletonThemeTemplate;
                              context: {
                                theme: {
                                  'border-radius': '5',
                                  height: '15px',
                                  width: '13px',
                                  margin: '0px',
                                  position: 'static'
                                }
                              }
                            "
                          ></ng-container>
                        </span>
                      </ng-container>
                      <span class="button-label-text"> {{ smartSeg?.name }}</span>
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>

            <div class="view-more-chip" *ngIf="activeSegmentId === 'myView' && myView?.count > defaultMyView">
              <a (click)="dataCount < myView?.count ? viewMore() : viewLess()">
                {{ dataCount < myView?.count ? 'View ' + (myView?.count - dataCount) + ' more' : 'View less' }}
                <span class="arrow-more-item">
                  <img
                    src="https://ik.imagekit.io/2gwij97w0o/right-arrow-light.svg?updatedAt=1712425125197"
                    alt="arrow-icon"
                  />
                  <!-- <img src="https://ik.imagekit.io/2gwij97w0o/arrow-down.svg?updatedAt=1715584424600" alt="arrow-down" /> -->
                </span>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- SMART SEGMENT | START -->
</div>

<ng-template #segmentLoadingState>
  <a>
    <b class="loader-icon">
      <ng-container
        *ngTemplateOutlet="
          skeletonThemeTemplate;
          context: { theme: { 'border-radius': '5', height: '12px', width: '35px', margin: '0px' } }
        "
      ></ng-container>
    </b>
    <p>
      <ng-container
        *ngTemplateOutlet="
          skeletonThemeTemplate;
          context: { theme: { 'border-radius': '5', height: '12px', width: '70px', margin: '0px' } }
        "
      ></ng-container>
    </p>
  </a>
</ng-template>

<ng-template #smartSegmentLoaderTemplate let-count="count">
  <div class="element-filed chip-field">
    <button disableRipple mat-stroked-button class="form-button-filter-field loader-btn">
      <span class="span-wrapper">
        <ng-container *ngFor="let item of [].constructor(count); let i = index">
          <ngx-skeleton-loader
            class="loader-segment-empty"
            [ngClass]="{ 'loader-one-item': count === 1 }"
          ></ngx-skeleton-loader>
        </ng-container>
      </span>
    </button>
  </div>
</ng-template>

<ng-template #skeletonThemeTemplate let-theme="theme">
  <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
</ng-template>
