<div class="app-paginator">
  <div class="paginator">
    <mat-paginator
      #paginator
      [length]="totalHits"
      [pageSize]="perPage"
      [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="paginate($event)"
      [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndex"
    >
    </mat-paginator>
  </div>
  <div class="serceh-tags" *ngIf="chiplistArr?.length != 0">
    <mat-form-field>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chiplistArr" [removable]="removable" (removed)="remove(chip)">
          <ng-container *ngIf="chip['date_flag'] && chip['date_flag'] == 'Secondary'; else displayPrimary">
            {{ chip['selected_state'] }} : {{ chip['from_date'] | date: 'dd/MM/yyyy' }} To
            {{ chip['to_date'] | date: 'dd/MM/yyyy' }}
          </ng-container>
          <ng-template #displayPrimary> {{ chip['selected_state'] }} : {{ chip['from_date'] }} </ng-template>
          <mat-icon matChipRemove *ngIf="removable"> x </mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>
  </div>
</div>
