import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkspaceDataResolver } from './workspaceData.resolver';
import { AuthService } from '../shared/services/auth.service';
import { ADMIN_EMAIL_ACCESS } from '../constants/routes';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceRoleGuard implements CanActivate {
  constructor(
    private workspaceDataResolver: WorkspaceDataResolver,
    private router: Router,
    private auth: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.workspaceDataResolver.resolve(next, state).pipe(
      map(currentuserData => {
        if (currentuserData?.status !== '1') {
          let workspaceName = localStorage.getItem('workspaceName');
          this.router.navigate(['/access-denied/', `workspace_${workspaceName}`]);
        }
        const scope = next.data.scope;
        const currentuserpermission = currentuserData.workspace_permissions
          ? currentuserData
          : currentuserData.userData;
        if (scope == 'packaging_admin') {
          if (ADMIN_EMAIL_ACCESS.includes(currentuserData.email)) {
            return true;
          } else this.router.navigate(['/access-denied/', scope]);
        }
        // for product verification
        if (scope == 'products' && next.queryParams?.isInStock) {
          return true;
        } else {
          const hasAccess =
            currentuserpermission &&
            ((currentuserpermission?.role && currentuserpermission?.role == 'owner') ||
              (currentuserpermission.workspace_permissions &&
                currentuserpermission.workspace_permissions[`manage:${scope}`].isEnabled == '1'));
          if (hasAccess) {
            if (this.auth.isMobileDevice()) {
              this.router.navigate(['/login-using-desktop', 'workspace']);
              return false;
            } else return true;
          } else this.router.navigate(['/access-denied/', scope]);
        }
      })
    );
  }
}
