import { AlertService } from './../../shared/services/alert.service';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BuildingBlocksService {
  buildingBlocksDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
  selectedBuildingBlock: any;
  actionsList = [];
  fieldsArray = [];
  itemBarcodeHandlebar = [
    'pickedItemBarcodes',
    'itemBarcodes',
    'itemBarcode',
    'itemBarcodeLookup',
    'itemBarcodeMissing',
    'itemBarcodeLookupOfIdentifiedItem'
  ];
  constructor(private alertService: AlertService) {}

  getBuildingBlocksById(bbHandleBar: string): any {
    this.buildingBlocksDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
    this.selectedBuildingBlock = this.buildingBlocksDetails.find(bb => {
      return bb.handleBar === bbHandleBar;
    });
    return this.selectedBuildingBlock
      ? this.selectedBuildingBlock
      : this.alertService.showError('Building block ' + bbHandleBar + ' doesn’t exist');
  }

  getAllBuildingBlocks() {
    return this.buildingBlocksDetails;
  }

  getAllActionsofBuildingBlock(bbHandleBar) {
    let actions = this.buildingBlocksDetails.find(bb => {
      return bb['handleBar'] === bbHandleBar;
    })['actions'];
    return actions;
  }

  getAllFieldsOfSelectedbuildingBlock() {
    this.actionsList = this.selectedBuildingBlock['actions'];
    this.actionsList.forEach(action => {
      this.fieldsArray.push(...action['fields']);
    });

    return this.fieldsArray;
  }

  getAllFieldsOfBuildingBlock(bbHandleBar) {
    let fieldsArray = [];
    this.buildingBlocksDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
    let actions = this.buildingBlocksDetails.find(bb => {
      return bb['handleBar'] === bbHandleBar;
    })['actions'];

    actions.forEach(action => {
      fieldsArray.push(...action['fields']);
    });

    return fieldsArray;
  }

  getFieldWithID(fieldHandleBar, bbHandleBar) {
    let fieldsArray = [];
    this.buildingBlocksDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
    let actions = this.buildingBlocksDetails.filter(bb => {
      return bb.handleBar === bbHandleBar;
    })[0]['actions'];

    actions.forEach(action => {
      fieldsArray.push(...action['fields']);
    });

    return fieldsArray.filter(field => {
      return field.handleBar === fieldHandleBar;
    })[0];
  }

  getActionWithID(actionHandleBar, bbHandleBar) {
    this.buildingBlocksDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
    let actions = this.buildingBlocksDetails.filter(bb => {
      return bb.handleBar === bbHandleBar;
    })[0]['actions'];

    return actions.filter(action => {
      return action.handleBar === actionHandleBar;
    })[0];
  }

  getFiltersArray(fields: Array<any>, type?) {
    var filterArr: Array<any> = [];
    fields.forEach(field => {
      let obj = {};
      obj['displayKey'] = field['name'];
      obj['fieldType'] = field['fieldType'];
      obj['key'] = field['handleBar'];
      if (field?.validations?.hasTime) obj['hasTime'] = field?.validations?.hasTime;
      // obj['buildingBlock'] = field['buildingBlock']
      if (field['fieldType'] === 'SHORT_TEXT') {
        obj['condition'] = ['is', 'is_not', 'contains', 'does_not_contain'];
      } else if (field['fieldType'] === 'LONG_TEXT') {
        obj['condition'] = ['contains', 'does_not_contain'];
      } else if (field['fieldType'] === 'NUMBER') {
        obj['condition'] = ['is', 'is_not', 'is_greater_than', 'is_less_than', 'is_between'];
      } else if (field['fieldType'] === 'DateTime' || field['fieldType'] === 'DATE_PICKER') {
        obj['condition'] =
          type === 'forExport'
            ? ['is', 'is_between']
            : ['is', 'is_not', 'is_greater_than', 'is_less_than', 'is_between'];
      } else if (field['fieldType'] === 'BOOLEAN') {
        obj['condition'] = ['is_true', 'is_false'];
      }
      // else if (field['fieldType'] === 'REFERENCE') {
      //   console.log("field=====>>>>>",field)
      //   let fieldsRef = [];
      // let refDetails = this.bbBlockDetails.find(obj => obj.handleBar == field?.['validations']?.['referenceSlug']);
      //   refDetails.actions.forEach(element => {
      //     element?.fields.forEach(obj => {
      //       if ((field?.['validations']?.['displayFields']).includes(obj.handleBar)) {
      //         fieldsRef.push(obj);
      //       }
      //     });
      //   });
      //   let childFieldObj = {};
      //   fieldsRef.forEach(ff => {
      //     childFieldObj[ff['handleBar']] = {
      //       displayKey: ff['buildingBlock'] + ' > ' + ff['name'],
      //       key: field['handleBar'] + '.' + ff['handleBar'],
      //       fieldType: ff['fieldType']
      //     };
      //     if (ff['fieldType'] === 'SHORT_TEXT') {
      //       childFieldObj[ff['handleBar']]['condition'] = ['is', 'is_not', 'contains', 'does_not_contain'];
      //     } else if (ff['fieldType'] === 'LONG_TEXT') {
      //       childFieldObj[ff['handleBar']]['condition'] = ['contains', 'does_not_contain'];
      //     } else if (ff['fieldType'] === 'NUMBER') {
      //       childFieldObj[ff['handleBar']]['condition'] = [
      //         'is',
      //         'is_not',
      //         'is_greater_than',
      //         'is_less_than',
      //         'is_between'
      //       ];
      //     } else if (ff['fieldType'] === 'DateTime' || ff['fieldType'] === 'DATE_PICKER') {
      //       childFieldObj[ff['handleBar']]['condition'] = [
      //         'is',
      //         'is_not',
      //         'is_greater_than',
      //         'is_less_than',
      //         'is_between'
      //       ];
      //     } else if (ff['fieldType'] === 'BOOLEAN') {
      //       childFieldObj[ff['handleBar']]['condition'] = ['is_true', 'is_false'];
      //     } else {
      //       childFieldObj[ff['handleBar']]['condition'] = ['is', 'is_not'];
      //     }
      //     obj = childFieldObj;
      //     filterArr.push(childFieldObj[ff['handleBar']]);
      //   });
      //   obj['children'];
      // }
      else {
        obj['condition'] = ['is', 'is_not'];
      }

      if (field['fieldType'] != 'REFERENCE') filterArr.push(obj);
    });
    let obj = {};
    obj['displayKey'] = 'ID';
    obj['fieldType'] = 'id';
    obj['key'] = 'id';
    obj['condition'] = ['is', 'is_not'];
    filterArr.push(obj);
    return filterArr;
  }
}
