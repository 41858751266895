import * as ld from 'lodash';
import * as moment from 'moment';
import * as fromSelector from '../segment-with-count/store/selectors/segment.selectors';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewSegmentService } from '../../services/segment-with-count/new-segment.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { BuildingBlockFilterHelperService } from 'src/app/filter-widget/service/filter-helper-BB.service';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
import { Store, select } from '@ngrx/store';
import { FilterHelperServiceNew } from 'src/app/filter-widget-new/service/filter-helper.service';
import { DeleteSegment, EditSegment, SaveSegment } from '../segment-with-count/store/actions/segment.actions';
import { SegmentModuleName } from '../../models/segment-with-count.model';

@Component({
  selector: 'app-reusable-filter',
  templateUrl: './reusable-filter.component.html',
  styleUrls: ['../../../filter-new/filter-new.component.scss']
})
export class ReusableFilterComponent implements OnInit {
  // Input properties
  @Input() allFilters: any;
  @Input() buildingBlock: string;

  // Output event
  @Output() emitSearchKey = new EventEmitter<any>();

  // Global variables
  filterBySearch: boolean;
  filtersData: {
    filterData: any;
    isCustomBuildingBlock: boolean;
    selectedSegment: any;
    module: any;
    algoliaString: string;
  };
  toggleMore: boolean = false;
  toggleDate: boolean = false;
  filterDataVeriable: string = '';
  filterDataVeriableChip: string = '';
  filterDataToShow = [];
  appliedMoreFilters = [];
  selectedField: string;
  isDateFilter: boolean = false;
  isChipOptionPanelOpen: any = [];
  openFilterToggle: boolean;
  addNewToggle: boolean;
  isOpenMoreFilterOptions: any = [];
  newActualFilter = [];
  appliedFilters: any[] = [];

  // Segment CRUD
  public showForm: boolean = false;

  // All filters type
  dateRangeFilters = [];
  primaryFilters = [];
  moreFilters = [];

  // Segment identifier key as buildingBlock
  currentSegmentData: any;
  currentSegmentData$: Subscription;

  currentRoute: string;
  searchQuery: any;
  isCreatedUntitled: boolean;
  segmentName: any;
  isFilterFromSegment: boolean;
  allSegments: any = [];
  isLoading: boolean;
  isLoaded: boolean;
  ALL_ORDERS = SegmentModuleName.ALL_ORDERS;
  RETURN_NO_SCAN = SegmentModuleName.RETURN_NO_SCAN;

  private _queryFilterData = new BehaviorSubject<any>(null);
  @Input()
  set queryFilterData(value) {
    this._queryFilterData.next(value);
  }
  get queryFilterData() {
    return this._queryFilterData.getValue();
  }

  @Output() emitFilterData = new EventEmitter<any>();
  private saveSegmentSubscription$: Subscription;

  constructor(
    private filterHelperService: FilterHelperService,
    private filterHelperServiceNew: FilterHelperServiceNew,
    private newSegmentService: NewSegmentService,
    private router: Router,
    private bbFilterHelperService: BuildingBlockFilterHelperService,
    private store: Store<{ segmentState }>
  ) {
    this.currentRoute = this.router.url;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchSegmentData();
    this.showDefaultFilters();
    this.getExistingFiltersOnReload();
  }

  // deafultFilter
  showDefaultFilters() {
    switch (this.buildingBlock) {
      case this.ALL_ORDERS:
        this.addCommonFilter('location&sales_channel', 'orderDetails.saleChannel', 'shipmentDetails.facilityCode');
        break;
      case this.RETURN_NO_SCAN:
        this.addCommonFilter('sales_channel', 'salesChannel');
        break;
    }
  }

  // Common filters by frontend line Sales channel and location
  addCommonFilter(type, sales_channel_key?: string, location_key?: string) {
    switch (type) {
      case 'sales_channel':
        this.addSalesChannelFilter(sales_channel_key);
        break;

      case 'location':
        this.addLocationFilter(location_key);
        break;

      case 'location&sales_channel':
        this.addSalesChannelFilter(sales_channel_key);
        this.addLocationFilter(location_key);
        break;
    }
  }

  async fetchSegmentData() {
    try {
      this.allSegments = await this.newSegmentService.getAllSegments();
    } catch (error) {
      console.error('Error fetching segments:', error);
    }
  }

  public getExistingFiltersOnReload() {
    this._queryFilterData.subscribe(async data => {
      if (data) {
        const existingFilterFormData = data;
        this.newActualFilter = [...existingFilterFormData.filterFormData];
        await this.getFilters();
        this.searchQuery = data['query'] ? data['query'] : '';
        this.segmentName = data.segmentName.includes('$')
          ? data.segmentName.replaceAll('$', ' ')
          : data.segmentName?.includes('IS|')
          ? data.segmentName.split('IS|')?.[1]
          : data.segmentName;

        this.filtersData = {
          filterData: existingFilterFormData.filterFormData,
          algoliaString: data['algoliaString'],
          isCustomBuildingBlock: false,
          selectedSegment: this.findSegmentBasedOnSegmentName(this.segmentName),
          module: this.buildingBlock
        };
        this.filterDataToShow = [];
        this.moreFilters.map(fil => {
          if (fil.filterCondition != 'is_between') {
            this.filterDataToShow.push({ filterCondition: fil.filterCondition, filterValue: fil.filterValue });
          } else {
            this.filterDataToShow.push({
              filterCondition: fil.filterCondition,
              fromDate: fil.fromDate,
              toDate: fil.toDate
            });
          }
        });
      }
    });
  }

  stopPropagationFunction(event) {
    this.filterHelperService.stopPropagation(event);
  }

  toggleDatePickerOpend() {
    this.toggleDate = true;
    this.filterHelperService.addZIndexClass();
  }
  toggleDatePickerClosed() {
    this.toggleDate = false;
    this.filterHelperService.removeZIndexClass();
  }
  toggleOpendChip(key) {
    this.filterDataVeriableChip = key;
    this.filterHelperService.addZIndexClass();
  }
  toggleClosedChip() {
    this.filterDataVeriableChip = '';
    this.filterHelperService.removeZIndexClass();
  }
  toggleOpend(key) {
    this.filterDataVeriable = key;
    this.filterHelperService.addZIndexClass();
  }
  toggleClosed() {
    this.filterDataVeriable = '';
    this.filterHelperService.removeZIndexClass();
    this.openFilterToggle = false;
  }
  toggleOpendMoreButtons(key) {
    this.filterDataVeriable = key;
    this.filterHelperService.addCSSClass('new-filter-dropdown');
  }
  toggleClosedMoreButtons() {
    this.filterDataVeriable = '';
    this.filterHelperService.removeCSSClass('new-filter-dropdown');
  }

  moreFilterMap() {
    this.moreFilters.map(fil => {
      if (fil.filterCondition != 'is between') {
        this.filterDataToShow.push({ filterCondition: fil.filterCondition, filterValue: fil.filterValue });
      } else {
        this.filterDataToShow.push({
          filterCondition: fil.filterCondition,
          fromDate: fil.fromDate,
          toDate: fil.toDate
        });
      }
    });
  }

  closeMoreFilterPanel() {
    this.toggleMore = !this.toggleMore;
    this.appliedMoreFilters = [];
    this.moreFilters.map((fil, index) => {
      if (!fil.filterValue && fil.filterCondition != 'is between') {
        this.filterDataToShow[index] = { filterCondition: null, filterValue: null, filterCleared: true };
      } else if (!fil.fromDate && !fil.toDate && fil.filterCondition == 'is between') {
        this.filterDataToShow[index] = { filterCondition: null, fromDate: null, toDate: null, filterCleared: true };
      }
    });
    this.isOpenMoreFilterOptions = new Array(this.moreFilters.length).fill(false);
    this.filterHelperService.removeCSSClass('hide-scroll');
  }

  // Get selected segment/smart segment filters
  getFilters() {
    this.currentSegmentData$ = this.newSegmentService.currentSegmentData.subscribe(data => {
      if (data) {
        this.currentSegmentData = data;
        this.mapFilters();
      } else {
        console.log('Test');
      }
    });
  }

  mapFilters() {
    // Clear the arrays
    this.dateRangeFilters = [];
    this.primaryFilters = [];
    this.moreFilters = [];

    // Get array of value from string
    const getValueArray = (value: string) => {
      value = value.split('-')[1];
      const cleanStatus = value.split(',').map(item => item.trim().replace(/^-/, ''));
      return cleanStatus;
    };

    // Remove "is not" condition if current filterQuery has that filter and type is "dropdown"
    this.newActualFilter = this.newActualFilter.map(item => {
      if (this.currentSegmentData?.filterQuery.includes(item.key) && item.fieldType === 'Dropdown') {
        return {
          ...item,
          condition: ['is']
        };
      }
      return item;
    });

    // Populate options for Dropdown filters in newActualFilter from preset filter value
    this.newActualFilter.forEach((item, index) => {
      if (item.fieldType == 'Dropdown' && !item.options) {
        const filter = this.getFilterByKey(item.key);
        if (filter) {
          // this.newActualFilter[index]['options'] = item.key != 'currentOrderStatus' ? filter.options : item.filterValue;
          this.newActualFilter[index]['options'] =
            this.currentSegmentData?.filterQuery.includes(item.key) &&
            this.currentSegmentData.filter[item.key] &&
            !this.currentSegmentData.filter[item.key].includes(',')
              ? item.filterValue
              : this.currentSegmentData.filter[item.key] && this.currentSegmentData.filter[item.key].includes(',')
              ? getValueArray(this.currentSegmentData.filter[item.key])
              : filter.options;
        }
      }
    });

    // Helper function to push valid filters
    const pushFilter = (filterArr, filterKey) => {
      const filter = this.getFilterByKey(filterKey);
      if (filter) {
        // Check if there's a matching item in newActualFilter
        const matchingItem = this.newActualFilter.find(item => item.key === filterKey);

        // Ensure Dropdown options are populated if necessary
        if (
          filter.fieldType === 'Dropdown' &&
          matchingItem &&
          matchingItem.options &&
          matchingItem.options.length > 0
        ) {
          if (!filter.options || filter.options.length === 0) {
            filter.options = matchingItem.options;
          }
        }

        // Merge additional properties based on fieldType
        if (matchingItem) {
          if (filter.fieldType === 'DateRange') {
            if (matchingItem.condition) {
              filter.condition = matchingItem.condition;
            }
            if (matchingItem.filterCondition) {
              filter.filterCondition = matchingItem.filterCondition;
            }
            if (matchingItem.conditionType) {
              filter.conditionType = matchingItem.conditionType;
            }
            if (matchingItem.fromDate) {
              filter.fromDate = matchingItem.fromDate;
            }
            if (matchingItem.toDate) {
              filter.toDate = matchingItem.toDate;
            }
          } else if (filter.fieldType === 'String' || filter.fieldType === 'Dropdown') {
            if (matchingItem.filterCondition) {
              filter.filterCondition = matchingItem.filterCondition;
            }
            if (matchingItem.filterValue) {
              filter.filterValue = matchingItem.filterValue;
            }
          }
        } else {
          filter.filterCondition = filter.fieldType === 'DateRange' ? 'is between' : filter.condition[0];
          if (filter.fieldType === 'DateRange') {
            if (filter.fromDate) delete filter.fromDate;
            if (filter.toDate) delete filter.toDate;
            if (filter.conditionType) delete filter.conditionType;
          } else {
            if (filter.filterValue) delete filter.filterValue;
          }
        }

        filterArr.push(filter);
      }
    };

    // Map and push filters for each type
    if (this.currentSegmentData.dateRangeFilters) {
      this.currentSegmentData.dateRangeFilters.forEach(value => {
        pushFilter(this.dateRangeFilters, value);
      });
    }

    if (this.currentSegmentData.primaryFilters) {
      this.currentSegmentData.primaryFilters.forEach(value => {
        pushFilter(this.primaryFilters, value);
      });
    }

    if (this.currentSegmentData.moreFilters) {
      this.currentSegmentData.moreFilters.forEach(value => {
        pushFilter(this.moreFilters, value);
      });
      this.isOpenMoreFilterOptions = new Array(this.moreFilters.length).fill(false);
    }
  }

  getFilterByKey(currentFilterKey) {
    return this.allFilters.find(x => x.key === currentFilterKey);
  }

  // Helper function to parse and sort localStorage data
  parseAndSort(key, sortKey) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data).sort((a, b) => a[sortKey].localeCompare(b[sortKey])) : [];
  }

  // Helper function to create a filter object
  createFilter(displayKey, key, options) {
    return {
      condition: ['is', 'is not'],
      displayKey,
      fieldType: 'Dropdown',
      key,
      options
    };
  }

  // Function to add Sales Channel filter
  addSalesChannelFilter(key) {
    let channels = this.parseAndSort('salesChannel', 'channelLabel');
    channels = channels.map(filter => ({ key: filter.externalChannelId, label: filter.channelLabel }));
    const saleChannel = this.createFilter('Sales channel', key, channels);
    this.allFilters.push(saleChannel);
  }

  // Function to add Location filter
  addLocationFilter(key) {
    let flLocations = this.parseAndSort('fulfillmentCenter', 'warehouseName');
    flLocations = flLocations.map(filter => ({ key: filter.externalWarehouseId, label: filter.warehouseName }));
    const location = this.createFilter('Location', key, flLocations);
    this.allFilters.push(location);
  }

  openFilter(key, filterIndex, fieldType?, fromChip?, isMoreFilter = false) {
    this.isDateFilter = false;
    if (fromChip) {
      this.isChipOptionPanelOpen[filterIndex] = !this.isChipOptionPanelOpen[filterIndex];
    }
    if (isMoreFilter) {
      this.isOpenMoreFilterOptions[filterIndex] = !this.isOpenMoreFilterOptions[filterIndex];
    }
    this.selectedField = key;
    this.openFilterToggle = !this.openFilterToggle;
    if (this.openFilterToggle) this.addNewToggle = false;
    if (this.selectedField && this.selectedField == key) {
      this.openFilterToggle = true;
    }
    if (fieldType === 'DateRange') this.isDateFilter = true;
  }

  /**@description - Method To Clear Input Value */
  public clearSearch() {
    this.emitSearchKey.emit('');
  }

  applyMoreFitlerQuery(event, i) {
    if (this.appliedMoreFilters.length > 0) {
      const existingFilterIndex = this.appliedMoreFilters.findIndex(filter => filter.key === event.filter[0].key);
      if (existingFilterIndex !== -1) {
        this.appliedMoreFilters[existingFilterIndex] = existingFilterIndex;
      } else {
        this.appliedMoreFilters.push(event.filter[0]);
      }
    } else this.appliedMoreFilters.push(event.filter[0]);
    this.moreFilters.map((fil, index) => {
      if (fil.key === event.filter[0].key) {
        if (event.filter[0].filterCondition != 'is between') {
          this.filterDataToShow[index] = {
            filterCondition: event.filter[0].filterCondition,
            filterValue: event.filter[0].filterValue
          };
        } else {
          this.filterDataToShow[index] = {
            filterCondition: event.filter[0].filterCondition,
            fromDate: event.filter[0].fromDate,
            toDate: event.filter[0].toDate
          };
        }
      }
    });
    this.isOpenMoreFilterOptions[i] = false;
  }

  async clearMoreFilterValue(event, index) {
    this.isOpenMoreFilterOptions[index] = false;
    this.moreFilters.map((filter, index) => {
      if (filter.key === event.key) {
        this.filterDataToShow[index] = { filterCondition: null, filterValue: null, filterCleared: true };
      }
    });
    if (!this.isFilterApplied(event.key)) return;
    this.updateAppliedFilter(event, true);
  }

  isFilterApplied(filterKey) {
    const filterIndex = this.newActualFilter.findIndex(x => x.key === filterKey);
    return filterIndex !== -1 ? true : false;
  }

  updateAppliedFilter(filterData, isClear = false) {
    const filterIndex = this.newActualFilter.findIndex(x => x.key === filterData.key);
    if (filterIndex == -1 && !isClear) {
      this.newActualFilter = [...this.newActualFilter, filterData];
    } else if (isClear) {
      this.newActualFilter.splice(filterIndex, 1);
    } else {
      this.newActualFilter[filterIndex].filterCondition = filterData.filterCondition;
      if (filterData.filterCondition === 'is between') {
        this.newActualFilter[filterIndex].fromDate = filterData.fromDate;
        this.newActualFilter[filterIndex].toDate = filterData.toDate;
        if (this.newActualFilter[filterIndex].filterValue) delete this.newActualFilter[filterIndex].filterValue;
      } else {
        this.newActualFilter[filterIndex].filterValue = filterData.filterValue;
        if (this.newActualFilter[filterIndex].fromDate) delete this.newActualFilter[filterIndex].fromDate;
        if (this.newActualFilter[filterIndex].toDate) delete this.newActualFilter[filterIndex].toDate;
      }
      if (filterData.fieldType === 'DateRange') {
        this.newActualFilter[filterIndex].showMultiselectWithFilterOption = filterData.showMultiselectWithFilterOption;
        this.newActualFilter[filterIndex].conditionType = filterData.conditionType;
      }
    }
  }

  openMoreFiltersPanel() {
    this.moreFilters.map((item, index) => {
      if (item.fromDate && item.toDate) {
        this.filterDataToShow[index] = {
          filterCondition: item.filterCondition,
          fromDate: item.fromDate,
          toDate: item.toDate
        };
      } else if (item.filterValue) {
        this.filterDataToShow[index] = { filterCondition: item.filterCondition, filterValue: item.filterValue };
      }
    });

    this.filterHelperService.addCSSClass('hide-scroll');
  }

  async applyFilterQuery(filterData, isDate) {
    if (!isDate && !filterData.isDateFilterFromChip) {
      this.updateAppliedFilter(filterData.filter[0]);
      for (let i = 0; i < this.appliedFilters.length; i++) {
        if (this.appliedFilters[i].key === filterData.filter[0].key) {
          this.appliedFilters[i].filterValue = filterData.filter[0].filterValue;
          this.appliedFilters[i].filterCondition = filterData.filter[0].filterCondition;
          break;
        }
      }
      for (let i = 0; i < this.moreFilters.length; i++) {
        if (this.moreFilters[i].key === filterData.filter[0].key) {
          this.moreFilters[i].filterCondition = filterData.filter[0].filterCondition;
          if (filterData.filter[0].filterCondition == 'is between') {
            this.moreFilters[i].fromDate = filterData.filter[0].fromDate;
            this.moreFilters[i].toDate = filterData.filter[0].toDate;
            delete this.moreFilters[i].filterValue;
          } else {
            this.moreFilters[i].filterValue = filterData.filter[0].filterValue;
            delete this.moreFilters[i].fromDate;
            delete this.moreFilters[i].fromDate;
          }
          break;
        }
      }
    } else {
      this.dateRangeFilters.forEach((fil, index) => {
        const item = filterData.filter.find(filterItem => filterItem.key === fil.key);
        const filterIndex = this.newActualFilter.findIndex(x => x.key === fil.key);
        if (filterIndex != -1 && item?.conditionType == 'Select') {
          this.updateAppliedFilter(item, true);
        }
        if (item) {
          fil.fromDate = item.fromDate;
          fil.toDate = item.toDate;
          fil.filterCondition = item.filterCondition;
          fil.showMultiselectWithFilterOption = item.showMultiselectWithFilterOption;
          fil.conditionType = 'custom';
          if (item.filterValue || (item.fromDate && item.toDate)) this.updateAppliedFilter(item);
        } else {
          const defaultDateFilter = this.bbFilterHelperService.getDefaultDateFilter();
          this.dateRangeFilters[index] = { ...fil, ...defaultDateFilter };
        }
      });
    }
    if (this.filterDataVeriableChip) this.toggleClosedChip();
    this.createFilterQuery();
  }

  async applyAllMoreFilters() {
    this.moreFilters.map(more => {
      this.appliedMoreFilters.map(applied => {
        if (more.key === applied.key) {
          more.filterCondition = applied.filterCondition;
          if (more.filterCondition != 'is between') {
            more.filterValue = applied.filterValue;
            this.updateAppliedFilter(applied);
          } else {
            more.fromDate = applied.fromDate;
            more.toDate = applied.toDate;
            if (more.fieldType == 'DateRange') {
              more.showMultiselectWithFilterOption = applied.showMultiselectWithFilterOption;
              more.conditionType = applied.conditionType;
            }
            delete more.filterValue;
            this.updateAppliedFilter(applied);
          }
        }
      });
    });
    this.moreFilters.map((item, index) => {
      if ((this.filterDataToShow[index] && this.filterDataToShow[index].filterCleared) || !item.filterValue) {
        delete item.filterValue;
        delete item.fromDate;
        delete item.toDate;
      }
    });
    this.toggleMore = !this.toggleMore;
    this.appliedMoreFilters = [];
    this.createFilterQuery();
    this.filterHelperService.removeCSSClass('hide-scroll');
  }

  openSaveEditPopup(e, segmentName, isEdit) {
    const result = this.bbFilterHelperService.toggleSaveEditPopup(e, isEdit, segmentName, this.showForm);
    this.showForm = result.showForm;
    this.segmentName = result.newSegmentName;
  }

  /**@description - Method To Navigate To Clicked Segment*/
  public onNavigate(segmentData) {
    if (segmentData.name != 'Untitled') this.searchQuery = '';
    switch (this.buildingBlock) {
      case 'all-orders': {
        this.searchQuery = '';
        this.router.navigate(['/order-V2/list'], {
          queryParams: this.newSegmentService.getSegmentQuery(segmentData.name, segmentData.filter)
        });
        this.deleteUntitledSegmentOnNavigate(segmentData);
        this.filterHelperService.resetMatPagination.next(0);
        break;
      }
    }
    if (this.saveSegmentSubscription$) this.saveSegmentSubscription$.unsubscribe();
  }

  async clearDateFilterQuery(event) {
    if (!this.isFilterApplied(event.filterData.key)) return;
    this.dateRangeFilters.map(fil => {
      event.filterData.map(item => {
        if (item.key === fil.key) {
          fil.fromDate = item.fromDate;
          fil.toDate = item.toDate;
          fil.showMultiselectWithFilterOption = item.showMultiselectWithFilterOption;
          fil.conditionType = item.conditionType;
          this.updateAppliedFilter(item, true);
        }
      });
    });
    this.createFilterQuery();
  }

  /**@description - Method to Get Segment Based on Segment Name*/
  private findSegmentBasedOnSegmentName(segmentName) {
    const selectedSegment = this.allSegments?.segment?.data.find(segment => segment.name == segmentName);
    return selectedSegment ? selectedSegment : null;
  }

  /**@description - Method To Create Query Params Object Based on Filter Form Data*/
  createQueryParamObject(filters) {
    const queryParamObject = {};

    filters.forEach(filter => {
      let value;
      if (filter.fieldType === 'DateRange') {
        value = `${filter.conditionType}-${new Date(filter.fromDate).setHours(23, 59, 59).valueOf()}TO${new Date(
          filter.toDate
        )
          .setHours(23, 59, 59)
          .valueOf()}`;
      } else if (Array.isArray(filter.filterValue)) {
        value = `${filter.filterCondition}-${filter.filterValue.join(',')}`;
      } else {
        value = `${filter.filterCondition}-${filter.filterValue}`;
      }

      queryParamObject[`${filter.key}`] = value;
    });

    return queryParamObject;
  }

  /**@description - Method To Create Query Param Condition Based on Filter Form Data Condition*/
  public createObjectBasedOnCondition(filterKey, filterCondtion, filterValue, selectionValue) {
    let queryParams = {};
    queryParams['selectionValue'] = `${selectionValue}`;
    if (filterCondtion == 'is') {
      queryParams[filterKey + '-is-'] = `${'is-'}${filterValue}`;
    } else if (filterCondtion == 'is not') {
      queryParams[filterKey + '-is not-'] = `${'not-'}${filterValue}`;
    } else if (filterCondtion == 'is greater than') {
      queryParams[filterKey + '-greater-'] = `${'greater-'}${filterValue}`;
    } else if (filterCondtion == 'is less than') {
      queryParams[filterKey + '-less-'] = `${'less-'}${filterValue}`;
    } else if (filterCondtion == 'is greater than or equal to') {
      queryParams[filterKey + '-greaterThan-'] = `${'greaterThan-'}${filterValue}`;
    } else if (filterCondtion == 'is less than or equal to') {
      queryParams[filterKey + '-lessThan-'] = `${'lessThan-'}${filterValue}`;
    } else if (filterCondtion == 'is between') {
      queryParams[filterKey + '-between-'] = `${'between-'}${filterValue}`;
    }
    return queryParams;
  }

  /**@description - Method To Create Algolia Query from Form Object*/
  public createFilterQuery() {
    this.emitFormData();
    document.querySelector('html').classList.remove('scroll-auto');
  }

  /**@description - Method To Emit Filter Form Data to Parent Component*/
  public emitFormData() {
    switch (this.buildingBlock) {
      case this.ALL_ORDERS:
        let queryString = this.createQueryParamObject(this.newActualFilter);
        this.emitFilterData.emit({
          filterQueryParams: queryString
        });
        break;
      case this.RETURN_NO_SCAN:
        let returnQueryString = this.createQueryParamObject(this.newActualFilter);
        this.emitFilterData.emit({
          filterQueryParams: returnQueryString
        });
        break;
    }
  }

  /** @description - Method To Delete Untitled Segments From Module Segments Data*/
  public deleteUntitledSegmentOnNavigate(segmentData) {
    if (this.isCreatedUntitled && segmentData.name != 'Untitled') {
      this.allSegments.pop();
      this.isCreatedUntitled = false;
    }
  }

  async clearAllFilters(clearAll) {
    this.moreFilters.map((filter, index) => {
      if (!this.currentSegmentData?.filterQuery || !this.currentSegmentData?.filterQuery.includes(filter.key)) {
        if (filter.filterValue) delete filter.filterValue;
        if (filter.fromDate) delete filter.fromDate;
        if (filter.toDate) delete filter.toDate;
        this.filterDataToShow[index] = { filterCondition: null, filterValue: null };
        const filterIndex = this.newActualFilter.findIndex(x => x.key === filter.key);
        if (filterIndex != -1) this.updateAppliedFilter(filter, true);
      }
    });

    if (clearAll) {
      this.primaryFilters.map(filter => {
        if (!this.currentSegmentData?.filterQuery || !this.currentSegmentData?.filterQuery.includes(filter.key)) {
          if (filter.filterValue) delete filter.filterValue;
          if (filter.fromDate) delete filter.fromDate;
          if (filter.toDate) delete filter.toDate;
          const filterIndex = this.newActualFilter.findIndex(x => x.key === filter.key);
          if (filterIndex != -1) this.updateAppliedFilter(filter, true);
        }
      });

      if (this.appliedFilters.length > 0) {
        this.appliedFilters.map(filter => {
          if (!this.currentSegmentData?.filterQuery || !this.currentSegmentData?.filterQuery.includes(filter.key)) {
            delete filter.filterValue;
          }
        });
      }

      // DATE RANGE
      const defaultDateFilter = this.bbFilterHelperService.getDefaultDateFilter();
      this.dateRangeFilters = this.dateRangeFilters.map(data => {
        if (this.showRemoveChip(data)) {
          this.updateAppliedFilter(data, true);
          return { ...data, ...defaultDateFilter };
        }
        return data;
      });
    } else {
      this.toggleMore = !this.toggleMore;
    }
    this.createFilterQuery();
    this.filterHelperService.removeCSSClass('hide-scroll');
  }

  async clearChip(filterToRemove) {
    for (let i = 0; i < this.appliedFilters.length; i++) {
      if (this.appliedFilters[i].key === filterToRemove.key) {
        delete this.appliedFilters[i].filterValue;
      }
    }
    for (let i = 0; i < this.moreFilters.length; i++) {
      if (this.moreFilters[i].key === filterToRemove.key && filterToRemove.filterCondition != 'is between') {
        delete this.moreFilters[i].filterValue;
        this.filterDataToShow[i] = { filterCondition: null, filterValue: null };
      } else if (this.moreFilters[i].key === filterToRemove.key && filterToRemove.filterCondition == 'is between') {
        delete this.moreFilters[i].fromDate;
        delete this.moreFilters[i].toDate;
        this.filterDataToShow[i] = { filterCondition: null, filterValue: null };
      }
    }

    if (filterToRemove.fieldType === 'dateRange') {
      this.dateRangeFilters = this.dateRangeFilters.map(data => {
        if (data.key === filterToRemove.key) {
          const defaultDateFilter = this.bbFilterHelperService.getDefaultDateFilter();
          return { ...data, ...defaultDateFilter };
        } else {
          return data;
        }
      });
    }

    this.updateAppliedFilter(filterToRemove, true);
    this.createFilterQuery();
  }

  onSaveSegment() {
    let fQuery: string, fFilter: any;
    switch (this.buildingBlock) {
      case this.ALL_ORDERS:
        fQuery = this.filterHelperServiceNew.convertToAlgoliaString(this.newActualFilter);
        fFilter = this.createQueryParamObject(this.newActualFilter);
        break;
      case this.RETURN_NO_SCAN:
        fQuery = this.filterHelperServiceNew.createReturnNoScanFilterQuery(
          this.currentSegmentData.filterQuery,
          this.newActualFilter
        );
        fFilter = this.createQueryParamObject(this.newActualFilter);
        break;
    }

    const currentActiveSegGroup = localStorage.getItem('currentActiveSegGroup');
    const newSegment = {
      primaryFilters: this.currentSegmentData.primaryFilters,
      dateRangeFilters: this.currentSegmentData.dateRangeFilters,
      moreFilters: this.currentSegmentData.moreFilters,
      showCount: this.currentSegmentData.showCount,
      name: this.segmentName,
      buildingBlock: this.currentSegmentData.buildingBlock,
      isCore: false,
      version: this.currentSegmentData.version,
      filterQuery: fQuery,
      filter: fFilter,
      group: currentActiveSegGroup || ''
    };

    // return console.log(payload);
    this.store.dispatch(new SaveSegment(newSegment));
    this.saveSegmentSubscription$ = this.store.pipe(select(fromSelector.getSavedSegmentDetails)).subscribe(response => {
      this.isLoading = response.isSaveSegmentLoading;
      this.isLoaded = response.isSaveSegmentLoaded;
      if (!response.isSaveSegmentLoading && response.isSaveSegmentLoaded) {
        this.newSegmentService.segmentEvent({ created: true, segment: response.savedSegmentData, action: 'create' });
        // this.filtersData.selectedSegment = response.savedSegmentData;
        this.segmentName = response.savedSegmentData.name;
        // this.onNavigate(response.savedSegmentData);
        this.showForm = !this.showForm;
      }
    });
  }

  onUpdateSegment(segmentName) {
    const updatedSegment = {
      id: this.filtersData.selectedSegment.id,
      name: segmentName
    };
    this.store.dispatch(new EditSegment(updatedSegment));
    this.store.pipe(select(fromSelector.getEditSegmentDetails)).subscribe(response => {
      this.isLoading = response.isEditSegmentLoading;
      this.isLoaded = response.isEditSegmentLoaded;
      if (!response.isEditSegmentLoading && response.isEditSegmentLoaded) {
        this.newSegmentService.segmentEvent({
          created: false,
          segment: response.modifiedSegmendData,
          action: 'update'
        });
        this.showForm = !this.showForm;
        this.onNavigate(response.modifiedSegmendData);
      }
    });
  }

  onDeleteSegment() {
    this.store.dispatch(new DeleteSegment(this.filtersData.selectedSegment.id));
    this.store.pipe(select(fromSelector.getDeleteSegmentDetails)).subscribe(response => {
      this.isLoading = response.isDeleteSegmentLoding;
      this.isLoaded = response.isDeleteSegmentLoaded;
      if (!response.isDeleteSegmentLoding && response.isDeleteSegmentLoaded) {
        this.newSegmentService.segmentEvent({
          created: false,
          segment: this.filtersData.selectedSegment,
          action: 'delete'
        });
        this.showForm = !this.showForm;
      }
    });
  }

  onCancelSave() {
    this.showForm = !this.showForm;
    this.segmentName = '';
    var x = document.getElementsByTagName('BODY')[0];
    x.classList.toggle('zindex');
  }

  showRemoveChip(filter): boolean {
    const isOnlyDateFilter = this.newActualFilter.filter(x => x.fieldType === 'DateRange');
    const isFilterInQuery = this.currentSegmentData?.filterQuery.includes(filter.key);
    const isDateRangeFilter = filter.fieldType === 'DateRange';

    if (!isFilterInQuery) {
      if (
        !isDateRangeFilter ||
        (isDateRangeFilter && isOnlyDateFilter.length > 1 && isOnlyDateFilter[0].key !== filter.key)
      ) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    if (this.currentSegmentData$) this.currentSegmentData$.unsubscribe();
  }
}
