import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSum',
  pure: false
})
export class GetSum implements PipeTransform {
  transform(attr: string, items: any[]): any {
    if (items != null) {
      return items.reduce((acc, sum) => {
        if (sum[attr]) {
          return acc + sum[attr];
        }
        return acc;
      }, 0);
    }
  }
}
