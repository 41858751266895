import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Product } from '../models/product.model';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private accountslug: string;
  // private facetsStatus = new BehaviorSubject<any>({});
  private totalHits = new BehaviorSubject<any>(0);
  _totalHits = this.totalHits.asObservable();
  // _facetsStatus = this.facetsStatus.asObservable();
  private al: any;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.accountslug = this.authService.getAccountSlug();
  }

  getProductList(params: any) {
    const qpms = { ...params };
    const url = `${params.sortBy}`;
    delete qpms.sortBy;
    // console.log('INSIDE SERVICE', qpms);
    // console.log('INSIDE SERVICE URL', url);
    return this.http.get(url, { params: qpms });
  }

  updateTotalHits(data) {
    this.totalHits.next(data);
  }

  getInventoryDetails(payload) {
    const url = 'v1/inventoryListing';
    return this.http.post(url, payload);
  }
  getWarehouseList(payload) {
    const url = 'v2/warehouse';
    return this.http.get(url, { params: payload }).pipe(
      map(res => {
        if (res) {
          const data = res['data'].filter(x => x.enrollmentStatus.toLowerCase() == 'enrolled');
          return { ...res, data };
        }
      })
    );
  }
  channelList(payload) {
    const url = 'v1/channel';
    return this.http.get(url, { params: payload });
  }

  productAvailability(payload) {
    const url = 'product-engine/api/v2/productListing';
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        if (error) {
          if (error['error']) {
            if (error['error']['error']) {
              if (error['error']['error']['message']) {
                if (error['error']['error']['message'].includes('availability')) {
                  return of(JSON.parse(error['error']['error']['message']));
                } else {
                  return of(error['error']['error']['message']);
                }
              }
            }
            // else {
            //   return of({
            //     type: ActionList.ProductActionsTypes.PRODUCT_AVAILABILITY_FAILURE,
            //     payload: error['error']
            //   });
            // }
          }
        }
      })
    );
  }

  createProduct(product: Product) {
    const apiUrl = 'product-engine/api/v1/products';
    return this.http.post(apiUrl, product);
  }

  createBulkProduct(payload) {
    const apiUrl = 'platform/api/v1/import-job';
    return this.http.post(apiUrl, payload);
  }

  editProduct(product: any) {
    const updatedProduct = { ...product };
    const oldSku = product.oldSku ? product.oldSku : product.sku;
    delete updatedProduct.oldSku;
    const apiUrl = `product-engine/api/v1/products/${oldSku}`;
    return this.http.put(encodeURI(apiUrl), updatedProduct);
  }
  productVerification(sku, payload, accountSlug?) {
    let url;
    if (accountSlug) {
      url = `product-engine/api/v1/products/${sku}?toggle=ON&accountSlugFc=${accountSlug}`;
    } else {
      url = `product-engine/api/v1/products/${sku}?toggle=ON`;
    }
    return this.http.put(url, payload);
  }
  getESINimages(esin) {
    const url = `product-engine/api/v1/products?fields=width_imageURL,dead_weight_imageURL,height_imageURL,length_imageURL&ids=${esin}`;
    return this.http.get(url);
  }

  adminVerification(esin, payload) {
    const url = `product-engine/api/v1/admin/weightverification?esin=${esin}`;
    return this.http.put(url, payload);
  }

  checkSKUExistOrNot(sku) {
    const al = `${env.algolia.product_index}?filters=(accountSlug:${this.accountslug})AND(sku:'${sku}')`;
    return this.http.get(al).pipe(
      map(res => {
        return res['hits'];
      })
    );
  }

  createExportJob(exportJobPayload) {
    return this.http.post('platform/api/v1/export-job', exportJobPayload);
  }

  getPropertyList(payload) {
    const url = 'platform/api/v1/property';
    return this.http.get(url, { params: payload }).pipe(
      map(x => {
        let final = x['data'].filter(y => {
          if (y['scope']) {
            if (y['scope'].includes('product') || y['scope'].includes('Product')) {
              return y;
            }
          }
        });
        return { ...x, data: final };
      })
    );
  }

  getBulkUploadDocumentStatus(payload) {
    const url = `platform/api/v1/import-job/${payload}`;
    return this.http.get(url);
  }

  getBrandAndVertical(params) {
    const url = 'v1/brand';
    return this.http.get(url, { params });
  }

  getFilters() {
    const filterUrl = 'product-engine/api/v1/key';
    return this.http.get(filterUrl);
  }

  getCustomfield() {
    const filterUrl = 'platform/api/v1/property?page=1&perPage=1000';
    return this.http.get(filterUrl);
  }

  getDropDownValues(facetsData) {
    // let accountSlug = localStorage.getItem('workspace');
    let accountSlug = this.authService.getAccountSlug();
    const dropdownValueUrl = `product_service?filters=(accountSlug:${accountSlug})&hitsPerPage=0&facets=${facetsData}`;
    return this.http.get(dropdownValueUrl);
  }
  getEsinValuesByOrder(esin) {
    // let accountSlug = localStorage.getItem('workspace');
    let accountSlug = this.authService.getAccountSlug();
    const esinValueUrl = `product_service?filters=${esin}`;
    return this.http.get(esinValueUrl);
  }
  mergeProduct(body) {
    const url = 'product-engine/api/v1/mergeProducts';
    return this.http.post(url, body);
  }

  getColumns() {
    const url = `product-engine/api/v1/getColumns`;
    return this.http.get(url);
  }

  deleteDraftProduct(payload) {
    const url = `product-engine/api/v1/products/${payload.type}`;
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: payload.data
    };
    return this.http.delete(url, options);
  }

  inventoryLastUpdate(payload) {
    const url = `product-engine/api/v1/channel-inventory/${payload.esin}`;
    return this.http.get(url, { params: { channelId: payload.params.join(',') } });
  }

  inventoryLogsUpdate(payload) {
    const url = `product-engine/api/v1/channel-inventory/logs/${payload.esin}`;
    return this.http.get(url, { params: payload.params });
  }
  getStockAgeing(params) {
    const url = `inventory/api/v2/esins/inventoryAge`;
    return this.http.get(url, { params });
  }

  syncJob(body) {
    const url = 'api/v1/appinstall/syncjob';
    return this.http.post(url, body);
  }

  stopSyncJob(id) {
    const body = { status: 'completed' };
    const url = `api/v1/appinstall/syncjob/${id}`;
    return this.http.put(url, body);
  }

  searchSalesChannelLogos(portalData, salesChannel, availableOn) {
    const result = [];

    // Loop through each record in availableOn
    availableOn.forEach(exId => {
      // Find matching record in salesChannel
      const matchedSale = salesChannel.find(sale => sale.externalChannelId === exId);

      if (matchedSale) {
        // Find matching record in portalData
        const matchedPortalData = portalData.find(portal => portal.id === matchedSale.portalId);

        if (matchedPortalData) {
          // If a match is found, push the result
          if (!result.includes(matchedPortalData.portalLogoUrl)) {
            result.push(matchedPortalData.portalLogoUrl);
          }
        }
      }
    });
    return result;
  }
}
