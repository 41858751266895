import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss']
})
export class SomethingWentWrongComponent {
  @Output() emitRetry = new EventEmitter<any>();

  retry() {
    this.emitRetry.emit();
  }
}
