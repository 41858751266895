import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  dummyAction,
  ruleDelete,
  ruleDeleteErrorAction,
  ruleDeleteSuccessAction,
  ruleList,
  ruleListErrorAction,
  ruleListSuccessAction,
  ruleSave,
  ruleSaveErrorAction,
  ruleSaveSuccessAction,
  orderroutingActions
} from './order-routing.actions';
import { getRuleListALL, getorderroutingState } from './order-routing.selector';
import { getRuleList } from './order-routing.reducer';
import { Store, createSelector, select } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrderRoutingService } from '../services/order-routing.service';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/app.state';

@Injectable()
export class orderRoutingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private orderRoutingService: OrderRoutingService
  ) {}
  @Effect()
  getList$: Observable<any> = this.actions$.pipe(
    ofType(ruleList),
    withLatestFrom(this.store.pipe(select(getRuleListALL))),
    mergeMap(([actions, list]) => {
      if (!list.length || (actions['payload'] != undefined && actions['payload']['force'])) {
        return this.orderRoutingService.getAllRules().pipe(
          switchMap((data: any) => {
            return [new ruleListSuccessAction(data.orderRoutingRules)];
          }),
          catchError((data: any) => {
            return [new ruleListErrorAction(data)];
          })
        );
      } else {
        return [new ruleListSuccessAction(list)];
      }
    })
  );

  @Effect()
  save$: Observable<any> = this.actions$.pipe(
    ofType(ruleSave),
    mergeMap(actions => {
      return this.orderRoutingService.saveRule(actions['payload']).pipe(
        switchMap((data: any) => {
          return [new ruleSaveSuccessAction(data.orderRoutingRules)];
        }),
        catchError((data: any) => {
          return [new ruleSaveErrorAction(data.error.error)];
        })
      );
    })
  );

  @Effect()
  delete$: Observable<any> = this.actions$.pipe(
    ofType(ruleDelete),
    mergeMap(actions => {
      return this.orderRoutingService.deleteRule(actions['payload']).pipe(
        switchMap((data: any) => {
          return [new ruleDeleteSuccessAction(data.orderRoutingRules)];
        }),
        catchError((data: any) => {
          return [new ruleDeleteErrorAction(data)];
        })
      );
    })
  );
}
