import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstalledAppAuthorizationComponent } from './installed-app-authorization.component';
import { StoreModule } from '@ngrx/store';
import { AppAuthorizationReducer } from './store/reducers/installed-app-authorization.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppAuthorizationEffects } from './store/effects/installed-app-authorization.effects';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('appAuthorization', AppAuthorizationReducer),
    MatProgressSpinnerModule,
    EffectsModule.forFeature([AppAuthorizationEffects])
  ],
  declarations: [InstalledAppAuthorizationComponent],
  exports: [InstalledAppAuthorizationComponent]
})
export class InstalledAppAuthorizationModule {}
