import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { FilterHelperServiceNew } from 'src/app/filter-widget-new/service/filter-helper.service';

@Component({
  selector: 'app-export-portal-filter',
  templateUrl: './export-portal-filter.component.html',
  styleUrls: ['./export-portal-filter.component.scss']
})
export class ExportPortalFilterComponent implements OnInit {
  @Output() getPortalData: EventEmitter<any> = new EventEmitter();
  portalFormData: any;
  portalData: any = [];
  portalForm: FormGroup;
  @ViewChild('allSelected') private allSelected: MatOption;

  constructor(public filterHelperService: FilterHelperServiceNew, private fb: FormBuilder) {}

  ngOnInit() {
    this.portalData = localStorage.getItem('portalDetails') ? JSON.parse(localStorage.getItem('portalDetails')) : [];
    this.portalForm = this.fb.group({
      portalData: ''
    });
  }

  displayOnHover(check, data) {
    if (check) {
      let obj = this.portalData.find(val => val.externalPortalId === data[0]);
      return obj.portalName;
    }
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.portalForm.controls.portalData.patchValue([...this.portalData.map(item => item.externalPortalId), 0]);
    } else {
      this.portalForm.controls.portalData.patchValue([]);
    }
    this.onSubmitDataForm();
  }

  /**@description - Method to Submit Portal Filter*/
  onSubmitDataForm() {
    let extPortalId = [];
    let displayDetails = [];
    this.portalForm.value.portalData.forEach(x => {
      extPortalId.push(x);
      let obj = this.portalData.find(obj => obj.externalPortalId == x);
      if (obj) displayDetails.push(obj.portalName);
    });
    if (extPortalId.includes(0)) {
      let index = extPortalId.indexOf(0);
      extPortalId.splice(index, 1);
    }
    this.portalFormData = {
      portal: extPortalId,
      displayKey: 'Portal: ' + displayDetails,
      condition: 'is'
    };
    this.getPortalData.emit(this.portalFormData);
  }

  toggleApplyButton(isSelected) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      this.onSubmitDataForm();
      return false;
    }
    if (this.portalForm.controls.portalData.value.length == this.portalData.length) this.allSelected.select();
    this.onSubmitDataForm();
  }
}
