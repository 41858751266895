<mat-form-field (click)="openFileInput()">
  <input readonly [(ngModel)]="fileUrl" matInput [placeholder]="placeholder" />
  <input
    type="file"
    #fileInput
    hidden
    (change)="onFileSelected($event)"
    [accept]="UPLOAD_FILE_CONSTANTS.SUPPORTED_FILE_TYPES.images"
  />
  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/upload-img.png" />
</mat-form-field>
