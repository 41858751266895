import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InventoryBreakupService {
  private apiUrl = 'v1/inventoryStatusBreakup';

  constructor(private http: HttpClient) {}
  getInventoryReport(skus) {
    const params = new HttpParams().set('skus', skus);
    return this.http.get(this.apiUrl, { params });
  }
}
