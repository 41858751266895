import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  // PUSHER_KEY = environment.pusher.PUSHER_KEY;
  // PUSHER_CLUSTER = environment.pusher.PUSHER_CLUSTER;
  CHANNEL = environment.pusher_export.pusher_export_channel;
  EVENT = environment.pusher_export.pusher_export_event;

  pusher: any;
  channel: any;
  downloadJob: Subject<any> = new Subject<any>();
  exportJobProcessing: Subject<any> = new Subject<any>();
  exportJobCompleted: Subject<any> = new Subject<any>();
  createdExportJob = new Set();

  constructor(private authService: AuthService) {
    // this.pusher = new Pusher(this.PUSHER_KEY, { cluster: this.PUSHER_CLUSTER });
    this.pusher = this.authService.pusher;
    this.channel = this.pusher.subscribe(this.CHANNEL);
    this.channel.bind(this.EVENT, response => {
      this.downloadJob.next(response);
      for (let status in response) {
        if (status == 'EXPORT JOB PROCESSING') {
          this.exportJobProcessing.next(response);
        } else if (status == 'EXPORT JOB COMPLETED') {
          this.exportJobCompleted.next(response);
        }
      }
    });
  }

  getDownloadJobFeed(): Observable<any> {
    return this.downloadJob.asObservable();
  }

  getExportJobProcessingFeed(): Observable<any> {
    return this.exportJobProcessing.asObservable();
  }

  getExportJobCompletedFeed(): Observable<any> {
    return this.exportJobCompleted.asObservable();
  }
}
