import { Component, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
// import * as filestack from 'filestack-js';
// import { PickerOptions } from 'filestack-js/build/main/lib/picker';
import { environment } from 'src/environments/environment';
import { AlertService, SharedService } from 'src/app/shared/services';
import * as UPLOAD_FILE_CONSTANTS from 'src/app/shared/constants/upload-files.constant';
// const client = filestack.init(environment.FILESTACK_APIKEY);
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnChanges {
  @Input() acceptedTypes: any[] = [];
  @Input() placeholder: any = 'Upload File';
  @Output() savedFileUrl = new EventEmitter();
  @Input() alreadyExistUrl: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileUrl: any;
  originPath: any;
  UPLOAD_FILE_CONSTANTS = UPLOAD_FILE_CONSTANTS;

  constructor(private sharedService: SharedService, private alertService: AlertService) {}

  ngOnChanges() {
    if (this.alreadyExistUrl) {
      this.fileUrl = this.alreadyExistUrl;
    }
  }

  // uploadFile() {
  //   const fileStackOption: PickerOptions = {
  //     maxFiles: 1,
  //     accept: this.acceptedTypes,
  //     uploadInBackground: false,
  //     startUploadingWhenMaxFilesReached: true,
  //     fromSources: ['local_file_system', 'imagesearch', 'googledrive', 'dropbox'],
  //     onOpen: () => {},
  //     dropPane: {},
  //     storeTo: {
  //       location: 'gcs',
  //       path: '/import_jobs/'
  //     },
  //     onUploadDone: (response: any) => {
  //       console.log('response from filestack', response);
  //       this.fileUrl = response.filesUploaded[0].url;
  //       this.originPath = response.filesUploaded[0].originalPath;
  //       this.savedFileUrl.emit(response.filesUploaded[0]);
  //     }
  //   };
  //   client.picker(fileStackOption).open();
  // }

  openFileInput() {
    // Triggers click event on the input element
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.sharedService
      .handleUploadFile(
        file,
        UPLOAD_FILE_CONSTANTS.SUPPORTED_FILE_TYPES.images,
        UPLOAD_FILE_CONSTANTS.BUCKET_NAMES.productCreate
      )
      .subscribe(
        (data: any) => {
          // if file uploaded successfully
          if (data) {
            this.fileUrl = data[file.name];
            this.originPath = file.name;
            //making response as filestack
            let FileData = {
              filename: file.name,
              mimetype: file.type,
              originalPath: file.name,
              url: data[file.name]
            };
            this.savedFileUrl.emit(FileData);
          }
        },
        error => {
          if (error) {
            this.alertService.showError(error);
          }
        }
      );
  }
  //   client
  //     .pick({
  //       maxFiles: 1,
  //       accept: this.acceptedTypes,
  //       uploadInBackground: false,
  //       startUploadingWhenMaxFilesReached: true,
  //       fromSources: ['local_file_system', 'imagesearch', 'googledrive', 'dropbox'],
  //       onOpen: () => {},
  //       rejectOnCancel: true,
  //       storeTo: {
  //         location: 'gcs',
  //         path: '/import_jobs/'
  //       }
  //     })
  //     .then(result => {
  //       this.fileUrl = result.filesUploaded[0].url;
  //       this.originPath = result.filesUploaded[0].originalPath;
  //       this.savedFileUrl.emit(result.filesUploaded[0]);
  //     })
  //     .catch(error => {
  //       console.log('File upload url');
  //       console.log('ERROR====>', error);
  //     });
  // }
}
