import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'LocationAccess'
})
export class FilterLocationAccessPipe implements PipeTransform {
  constructor() {}
  transform(items: any[], workspaceAccess: any) {
    // Check if items or permissions are not provided
    if (!items || !workspaceAccess) {
      return items;
    }

    // Filter items based on accountSlug presence in permissions keys
    return items.filter(item => {
      const accountSlug = item.warehouseWorkspaceSlug;
      // Check if the accountSlug is present in the keys of the accountPermissions object
      return accountSlug && workspaceAccess.hasOwnProperty(accountSlug);
    });
  }
}
