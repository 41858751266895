import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReusableDirective } from './reusable-tooltip.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipOptionsService } from './tooltip-options.service';
import { SanitizeHtmlPipe } from '../shared/pipes/sanitize-html.pipe';

@NgModule({
  declarations: [ReusableDirective, TooltipComponent, SanitizeHtmlPipe],
  imports: [CommonModule],
  exports: [ReusableDirective],
  providers: [TooltipOptionsService]
})
export class ReusableTooltipModule {}
