import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rich-tooltip',
  templateUrl: './rich-tooltip.component.html',
  styleUrls: ['./rich-tooltip.component.scss']
})
export class RichTooltipComponent {
  @Input() content: string = '';
  @Input() position: string = '';
  @Input() label: string = '';
  @Input() imgSrc: string = '';
  @Input() classNAME: string = '';
}
