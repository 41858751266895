import { DateModel } from './date.model';
import { FilterModel } from './filter.model';
import { LocationModel } from './location.model';
import { SalesChannelModel } from './sales-channel.model';

export class FilterMainModel {
  salesChannel: SalesChannelModel = new SalesChannelModel();
  location: LocationModel = new LocationModel();
  filters: FilterModel[] = [];
  date: DateModel = new DateModel();
}
