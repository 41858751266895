<ng-container>
  <div class="dialog-header">
    <h2 mat-dialog-title>Edit schedule</h2>
    <button mat-dialog-close>
      <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
    </button>
  </div>
  <mat-dialog-content>
    <div class="dialog-body">
      <form [formGroup]="scheduleForm">
        <div class="sehedule-form">
          <div class="form-field">
            <label> Report name</label>
            <input type="text" placeholder="Enter report name" formControlName="reportName" required />
          </div>
          <mat-error
            class="error-message"
            *ngIf="
              submitted &&
              scheduleForm.controls['reportName'].errors &&
              scheduleForm.controls['reportName'].errors.required
            "
          >
            This field is required
          </mat-error>
          <div class="form-field">
            <label> Interval </label>
            <mat-form-field class="select-team-member">
              <mat-label> Every {{ scheduleForm.value.every | titlecase }} </mat-label>
              <mat-select
                disableOptionCentering
                formControlName="every"
                (selectionChange)="resetDay()"
                panelClass="custom-select-ui edit-custom-ui"
              >
                <ng-container *ngFor="let e of interval.every">
                  <mat-option value="{{ e }}"> Every {{ e }} </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-field interval-wrp">
            <div class="time-wrap">
              <div
                class="set-day"
                *ngIf="
                  scheduleForm.value.every.toLowerCase() === 'week' ||
                  scheduleForm.value.every.toLowerCase() === 'month'
                "
              >
                <span class="label-txt"> Day </span>
                <mat-form-field class="select-team-member">
                  <mat-label> {{ scheduleForm.value.day ? (scheduleForm.value.day | titlecase) : 'Day' }} </mat-label>
                  <mat-select disableOptionCentering formControlName="day" panelClass="custom-select-ui edit-custom-ui">
                    <ng-container *ngIf="scheduleForm.value.every.toLowerCase() === 'week'">
                      <ng-container *ngFor="let e of interval.week">
                        <mat-option value="{{ e }}"> {{ e }} </mat-option>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="scheduleForm.value.every.toLowerCase() === 'month'">
                      <ng-container *ngFor="let e of interval.month">
                        <mat-option value="{{ e }}"> {{ e }} </mat-option>
                      </ng-container>
                      <mat-option value="lastDay">Last day</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mid-txt" *ngIf="scheduleForm.value.every.toLowerCase() != 'day'">
                <p>at</p>
              </div>
              <div class="hours">
                <span class="label-txt"> Hours </span>
                <mat-form-field class="select-team-member">
                  <mat-label> {{ scheduleForm.value.hour }} </mat-label>
                  <mat-select
                    disableOptionCentering
                    formControlName="hour"
                    panelClass="custom-select-ui edit-custom-ui"
                  >
                    <ng-container *ngFor="let h of interval.hour">
                      <mat-option value="{{ h }}"> {{ h }} </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mid-txt">
                <p>:</p>
              </div>
              <div class="min">
                <span class="label-txt"> Minutes </span>
                <mat-form-field class="select-team-member">
                  <mat-label> {{ scheduleForm.value.minutes }} </mat-label>
                  <mat-select
                    disableOptionCentering
                    formControlName="minutes"
                    panelClass="custom-select-ui edit-custom-ui"
                  >
                    <ng-container *ngFor="let m of interval.minutes">
                      <mat-option value="{{ m }}"> {{ m }} </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="periods">
                <span class="label-txt"> am/pm </span>
                <mat-form-field class="select-team-member">
                  <mat-label> {{ scheduleForm.value.format.toLowerCase() }} </mat-label>
                  <mat-select
                    disableOptionCentering
                    formControlName="format"
                    panelClass="custom-select-ui edit-custom-ui"
                  >
                    <ng-container *ngFor="let f of interval.format">
                      <mat-option value="{{ f }}"> {{ f.toLowerCase() }} </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-field date-rng">
            <label> Send this report to</label>
            <mat-form-field class="select-team-member multi-team-select">
              <mat-label>Select team member</mat-label>
              <mat-select
                disableOptionCentering
                formControlName="members"
                panelClass="custom-select-ui edit-custom-ui multi-sel"
                multiple
              >
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let m of scheduleForm.value.members" [removable]="true" (removed)="onRemove(m)">
                      {{ m?.firstName + ' ' + (m?.lastName != null ? m?.lastName : '') }}
                      <mat-icon matChipRemove>
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/chip-close.svg?updatedAt=1632382517887"
                        />
                      </mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="'no matching key found'"
                    [formControl]="searchSelectBox"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let m of filteredKeyList | async" [value]="m">
                  <span class="name"> {{ m?.firstName + ' ' + (m?.lastName != null ? m?.lastName : '') }} </span> ({{
                    m?.email
                  }})
                </mat-option>
              </mat-select>
              <mat-error
                class="error-message"
                *ngIf="
                  submitted &&
                  scheduleForm.controls['members'].errors &&
                  scheduleForm.controls['members'].errors.required
                "
              >
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <p>
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />We will
            start exporting data at the time you set. Once the export is complete you will receive email. Report will
            also be available in Files > Exports.
          </p>
        </div>
      </form>
    </div>
    <div class="dialog-foot">
      <button mat-dialog-close class="btn-cancel">Cancel</button>
      <button
        class="btn-export"
        [class.primary-btn-loader]="loaderEdit"
        [disabled]="!scheduleForm.valid || (scheduleForm.value.every.toLowerCase() != 'day' && !scheduleForm.value.day)"
        (click)="editSchedule()"
      >
        Update
      </button>
    </div>
  </mat-dialog-content>
</ng-container>
