import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app.state';
import * as fromReducer from '../reducers/orders.reducers';

const getOrderState = (state: AppState) => state.ordersState;

export const getOrdersData = createSelector(getOrderState, fromReducer.getOrdersData);
export const getOrdersList = createSelector(getOrderState, fromReducer.getOrdersList);
export const getPortalsData = createSelector(getOrderState, fromReducer.getPortalsData);
export const getResolverLoader = createSelector(getOrderState, fromReducer.getResolverLoader);
export const templateData = createSelector(getOrderState, fromReducer.templateData);
export const saveTemplateData = createSelector(getOrderState, fromReducer.saveTemplateData);
export const editTemplateData = createSelector(getOrderState, fromReducer.editTemplateData);
export const deleteTemplateData = createSelector(getOrderState, fromReducer.deleteTemplateData);
export const exportTemplateData = createSelector(getOrderState, fromReducer.exportTemplateData);
export const memberList = createSelector(getOrderState, fromReducer.memberList);
export const scheduleTemplateData = createSelector(getOrderState, fromReducer.scheduleTemplateData);
export const getImportData = createSelector(getOrderState, fromReducer.getImportData);

/*Temporary Change*/
export const documentStatus = createSelector(getOrderState, fromReducer.getDocumentStatus);
export const documentStatusChecked = createSelector(getOrderState, fromReducer.getDocumentStatusChecked);
export const documentStatusChecking = createSelector(getOrderState, fromReducer.getDocumentStatusChecking);
export const getCreatedBulkReturn = createSelector(getOrderState, fromReducer.getCreatedBulkReturn);
export const creatingBulkReturn = createSelector(getOrderState, fromReducer.getCreatingBulkReturnStatus);
export const createdBulkReturn = createSelector(getOrderState, fromReducer.getCreatedBulkReturnStatus);

// order cancellation starts
export const getCreatedOrderCancellation = createSelector(getOrderState, fromReducer.getCreatedOrderCancellation);
export const customerPortalPoeSetting = createSelector(getOrderState, fromReducer.customerPortalPoeSetting);
// order cancellation ends
