<section class="table-container">
  <mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="checkbox" sticky>
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox></mat-checkbox></th>
      <td mat-cell *matCellDef="let element"><mat-checkbox></mat-checkbox></td>
    </ng-container> -->

    <ng-container matColumnDef="ID">
      <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.ID }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.amount }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef>Color</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.color }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="file">
      <mat-header-cell *matHeaderCellDef>Files</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <i class="attachment">
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/attachment-ic.png" />
          {{ element.file }}
          <app-attach-files></app-attach-files>
        </i>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef>Created At</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span matTooltip="25-June-20 | 12:56 PM"> {{ element.createdAt }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <mat-header-cell *matHeaderCellDef>Updated At</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.updatedAt }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action" stickyEnd>
      <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="action">
          <button mat-raised-button matTooltip="Edit" (click)="openDialogEdit()">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/red-edit-icon.png" />
          </button>
          <button mat-raised-button matTooltip="Delete" (click)="openDialogDelete()">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/red-delete-icon.png" />
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" sticky></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</section>
