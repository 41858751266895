import { Component, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  @Output() dateEmitter = new EventEmitter<any>();
  showdropdown: boolean = false;
  createdDate: boolean = true;
  updatedDate: boolean = false;
  frmDate: any;
  toDate: any;
  dateText: any;
  errmsg: string = '';
  errFlag: boolean;
  state: any;
  dateArray: any = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This month', 'Last month'];
  createdAt: any = '';
  updatedAt: any = '';
  constructor() {}
  showDropDown() {
    this.showdropdown = true;
    this.createdDate = true;
    this.dateText = this.createdDate ? 'createdAt' : '';
  }
  createClicked() {
    this.createdDate = true;
    this.updatedDate = false;
    this.dateText = this.createdDate ? 'createdAt' : '';
  }
  updateClicked() {
    this.updatedDate = true;
    this.createdDate = false;
    this.dateText = this.updatedDate ? 'updatedAt' : '';
  }
  dateFilterHandler(textSelector, selectedDateValue) {
    const getDateField = {
      date_flag: 'Primary',
      selected_state: textSelector,
      selected_date: selectedDateValue
    };
    this.dateHandler(getDateField);
  }
  addEvent() {
    if (
      this.frmDate != '' ||
      this.frmDate != undefined ||
      this.frmDate != null ||
      this.toDate != '' ||
      this.toDate != undefined
    ) {
      if (this.frmDate > this.toDate) {
        this.errmsg = 'Valid date required.';
      } else {
        this.errmsg = '';
      }
    }
  }

  applyFilter(frmDate, toDate) {
    if (this.frmDate && this.toDate) {
      if (frmDate < toDate) {
        this.errFlag = true;
        this.errmsg = '';
        const getDate = {
          date_flag: 'Secondary',
          frmDate: frmDate,
          toDate: toDate,
          selected_state: this.dateText
        };
        this.dateHandler(getDate);
      } else {
        this.errFlag = false;
        this.errmsg = 'valid date required';
        return;
      }
    } else {
      this.errmsg = 'valid date required';
    }
  }
  showHide() {
    this.showdropdown = !this.showdropdown;
    if (this.showDropDown) {
      this.dateText = 'createdAt';
    } else {
      this.dateText = '';
    }
  }

  dateHandler(dateField) {
    this.showdropdown = false;
    let dateParam: { fromDate: any; toDate: any; selected_state: any; selected_date?: any };
    if (dateField.hasOwnProperty('date_flag') && dateField['date_flag'] == 'Primary') {
      switch (dateField.selected_date) {
        case 'Today':
          {
            dateParam = {
              fromDate: moment()
                .startOf('day')
                .valueOf(),
              toDate: Date.now(),
              selected_state: dateField.selected_state
            };
          }
          break;
        case 'Yesterday':
          {
            dateParam = {
              fromDate: moment()
                .subtract(1, 'days')
                .startOf('day')
                .toString()
                .valueOf(),
              toDate: moment()
                .subtract(1, 'days')
                .endOf('day')
                .toString()
                .valueOf(),
              selected_state: dateField.selected_state
            };
          }
          break;
        case 'Last 7 Days':
          {
            dateParam = {
              fromDate: moment()
                .subtract(7, 'days')
                .startOf('day')
                .toString()
                .valueOf(),
              toDate: Date.now(),
              selected_state: dateField.selected_state
            };
          }
          break;
        case 'Last 30 Days':
          {
            dateParam = {
              fromDate: moment()
                .subtract(30, 'days')
                .startOf('day')
                .toString()
                .valueOf(),
              toDate: Date.now(),
              selected_state: dateField.selected_state
            };
          }
          break;
        case 'This month':
          {
            dateParam = {
              fromDate: moment()
                .startOf('month')
                .valueOf(),
              toDate: Date.now(),
              selected_state: dateField.selected_state
            };
          }
          break;
        case 'Last month':
          {
            dateParam = {
              fromDate: moment()
                .subtract(1, 'months')
                .startOf('month')
                .valueOf(),
              toDate: moment()
                .subtract(1, 'months')
                .endOf('month')
                .valueOf(),
              selected_state: dateField.selected_state
            };
          }
          break;
        default:
          break;
      }
    } else {
      dateParam = {
        fromDate: moment(dateField.frmDate).valueOf(),
        toDate: moment(dateField.toDate).valueOf(),
        selected_state: dateField.selected_state
      };
    }
    dateParam = {
      fromDate: moment(dateParam.fromDate).valueOf(),
      toDate: moment(dateParam.toDate).valueOf(),
      selected_state: dateParam.selected_state,
      selected_date: dateField.selected_date
    };
    this.createdAt = dateParam.selected_state == 'createdAt' ? dateParam : this.createdAt;
    this.updatedAt = dateParam.selected_state == 'updatedAt' ? dateParam : this.updatedAt;
    this.dateEmitter.emit({ createdAt: this.createdAt, updatedAt: this.updatedAt });
  }
}
