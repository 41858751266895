<div class="downloadtemplete uploadedblock">
  <h2>Upload Status</h2>
  <div class="table">
    <ul>
      <li class="thead">
        <span>Uploads</span>
        <span>Created on</span>
        <span>Status</span>
      </li>
      <li class="tbody">
        <span class="sheet-name" *ngIf="status?.status == 1 || status?.status == 2">
          {{ localFileInfo?.filename }}
        </span>
        <span *ngIf="status?.status == 1"> </span>
        <span class="created-date" *ngIf="status?.status == 1">
          {{ status?.createdAt | date }}
        </span>
        <span class="file-all-status" *ngIf="status?.status == 1">
          <!-- <span>
            <i class="tooltip" data-hover-txt="Failed">
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-error.png" />
            </i>
            <b>{{ status?.failureCount }}</b>
            <a [href]="status?.failedFilePath" download>
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-download-red.png" height="15px" />
            </a>
          </span> -->
          <!-- <span>
            <i class="tooltip" data-hover-txt="Created in Active">
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-green-tick.png" height="15px" />
            </i>
            <b>{{ status?.additionalDetails?.active?.count }}</b>
            <a [href]="status?.additionalDetails?.active?.createdInActiveUrl" download>
              <img src="../../../../assets/img/ic-download-green.png" height="15px" />
            </a>
          </span> -->
          <!-- <span>
            <i class="tooltip" data-hover-txt="Updated">
              <img src="../../../../assets/img/blue-icon.png" height="15px" />
            </i>
            <b>{{ status?.additionalDetails?.updated?.count }}</b>
            <a [href]="status?.additionalDetails?.updated?.updatedUrl" download>
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-download-blue.png" height="15px" />
            </a>
          </span> -->
          <span>
            <i class="tooltip" data-hover-txt="Response Sheet">
              <img
                src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/draft-icon.png"
                height="15px"
              />
            </i>
            <!-- <b>{{ status?.additionalDetails?.draft?.count }}</b> -->
            <b>{{ +status?.successCount + +status?.failureCount }}</b>
            <a [href]="status?.additionalDetails" download>
              <img
                src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-download-yellow.png"
                height="15px"
              />
            </a>
          </span>
        </span>
        <span class="uploading-sheet" *ngIf="status?.status == 0">
          Uploading Sheet..
        </span>
        <span class="created-on" *ngIf="status?.status == 0">
          <!-- Created On :  {{ status?.createdAt | date }} -->
        </span>
        <span class="cleck-status" *ngIf="status?.status == 0">
          <img class="load-img" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/loader.gif" />
          <a class="refresh" (click)="callAPI()"> Refresh </a>
        </span>
        <span *ngIf="status?.status == 2"> </span>
        <span *ngIf="status?.status == 2">
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/ic-error.png" />
          {{ status?.fileReadFailureReason }}
        </span>
      </li>
      <li *ngIf="status?.status == 1">
        <span class="note-txt">
          <b>Note :</b> Products which are failed needs to be re-submitted for creation. Successfully processed products
          are created.
        </span>
      </li>
    </ul>
  </div>
  <div class="createconsigmentbtn">
    <div>
      <a [routerLink]="['/product/list']" [ngClass]="status?.status == 0 ? 'disabled' : ''" class="btnconsignment"
        >Next</a
      >
    </div>
  </div>
</div>
