import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  ADD_FEE_RULE = '[ADDFEES] ADD Fee Rule',
  ADD_FEE_RULE_SUCCESS = '[ADDFEES] ADD Fee Rule Success',
  ADD_FEE_RULE_FAILURE = '[ADDFEES] ADD Fee Rule Failure',
  ADD_BULK_FEE_RULE = '[ADDBULKFEES] ADD BULK Fee Rule',
  RESET_ADD_BULK_FEE_RULE_SUCCESS = '[RESETADDBULKFEES] RESET ADD BULK Fee Rule Success',
  RESET_ADD_BULK_FEE_RULE = '[RESETADDBULKFEES] RESET ADD BULK Fee Rule',
  ADD_BULK_FEE_RULE_SUCCESS = '[ADDBULKFEES] ADD BULK Fee Rule Success',
  ADD_BULK_FEE_RULE_FAILURE = '[ADDBULKFEES] ADD BULK Fee Rule Failure',
  ADD_FEE_CORRECTION = '[ADDFEESCORRECTION] ADD Fee Correction',
  ADD_FEE_CORRECTION_SUCCESS = '[ADDFEESCORRECTION] ADD Fee Correction Success',
  ADD_FEE_CORRECTION_FAILURE = '[ADDFEESCORRECTION] ADD Fee Correction Failure',
  GET_BULK_FEE_RULE = '[GETBULKFEES] GET BULK Fee Rule',
  GET_BULK_FEE_RULE_SUCCESS = '[GETBULKFEES] GET BULK Fee Rule Success',
  GET_BULK_FEE_RULE_FAILURE = '[GETBULKFEES] GET BULK Fee Rule Failure',
  CLONE_FEE_RULE = '[CLONEFEES] CLONE Fee Rule',
  CLONE_FEE_RULE_SUCCESS = '[CLONEFEES] CLONE Fee Rule Success',
  CLONE_FEE_RULE_FAILURE = '[CLONEFEES] CLONE Fee Rule Failure',
  GET_FEE_RULE = '[GETFEERULE] GET Fee Rule',
  GET_FEE_RULE_SUCCESS = '[GETFEERULE] GET Fee Rule Success',
  GET_FEE_RULE_FAILURE = '[GETFEERULE] GET Fee Rule Failure',
  EDIT_FEE_RULE = '[ADDFEES] EDIT Fee Rule',
  EDIT_FEE_RULE_SUCCESS = '[ADDFEES] EDIT Fee Rule Success',
  EDIT_FEE_RULE_FAILURE = '[ADDFEES] EDIT Fee Rule Failure',
  GET_FEE_LIST = '[GETFEELIST] GET Fee List',
  GET_FEE_LIST_SUCCESS = '[GETFEELIST] GET Fee List Success',
  GET_FEE_LIST_FAILURE = '[GETFEELIST] GET Fee List Failure',
  GET_FEE_DETAILS = '[GETFEEDETAILS] GET Fee Details',
  GET_FEE_DETAILS_SUCCESS = '[GETFEEDETAILS] GET Fee Details Success',
  GET_FEE_DETAILS_FAILURE = '[GETFEEDETAILS] GET Fee Details Failure',
  /**
   * @author Rishiraj Shukla
   */
  GET_PROPERTY_DETAILS = '[GETPROPERTDETAILS] GET Property Details',
  GET_PROPERTY_DETAILS_SUCCESS = '[GETPROPERTDETAILS] GET Property Details Success',
  GET_PROPERTY_DETAILS_FAILURE = '[GETPROPERTDETAILS] GET Property Details Failure',

  GET_TEAM_MEMBERS_DATA = '[TEAMS] Get Team Members Data',
  GET_TEAM_MEMBERS_DATA_SUCCESS = '[TEAMS] Get Team Members Data Success',
  GET_TEAM_MEMBERS_DATA_FAILURE = '[TEAMS] Get Team Members Data Failure',

  GET_EINVOICE_STATUS = '[E-Invoicing] Get status',
  GET_EINVOICE_STATUS_SUCCESS = '[E-Invoicing] Get status Success',
  GET_EINVOICE_STATUS_FAILURE = '[E-Invoicing] Get status Failure',
  // get settings API for workspace
  GET_WORKSPACE_SETTINGS_DATA = '[SETTINGS] Get Workspace Settings Data',
  GET_WORKSPACE_SETTINGS_DATA_SUCCESS = '[SETTINGS] Get Workspace Settings Data Success',
  GET_WORKSPACE_SETTINGS_DATA_FAILURE = '[SETTINGS] Get Workspace Settings Data Failure',

  // update settings API for workspace
  UPDATE_WORKSPACE_SETTINGS_DATA = '[SETTINGSUPDATE] Update Workspace Settings Data',
  UPDATE_WORKSPACE_SETTINGS_DATA_SUCCESS = '[SETTINGSUPDATE] Update Workspace Settings Data Success',
  UPDATE_WORKSPACE_SETTINGS_DATA_FAILURE = '[SETTINGSUPDATE] Update Workspace Settings Data Failure',
  GET_BANK_DETAILS_LIST = '[BANK DETAILS LIST] Get Bank Details List Data',
  GET_BANK_DETAILS_LIST_SUCCESS = '[BANK DETAILS LIST] Get Bank Details List Data Success',
  GET_BANK_DETAILS_LIST_FAILURE = '[BANK DETAILS LIST] Get Bank Details List Data Failure',

  GET_BANK_DETAILS = '[BANK DETAILS] Get Bank Details Data',
  GET_BANK_DETAILS_SUCCESS = '[BANK DETAILS] Get Bank Details Data Success',
  GET_BANK_DETAILS_FAILURE = '[BANK DETAILS] Get Bank Details Data Failure',

  GET_BUSINESS_DETAILS = '[BUSINESS DETAILS] Get Business Details Data',
  GET_BUSINESS_DETAILS_SUCCESS = '[BUSINESS DETAILS] Get Business Details Data Success',
  GET_BUSINESS_DETAILS_FAILURE = '[BUSINESS DETAILS] Get Business Details Data Failure',

  GET_COD_PAYMENT_TERM_DETAILS = '[COD PAYMENT TERM DETAILS] Get COD Payment Term Details Data',
  GET_COD_PAYMENT_TERM_DETAILS_SUCCESS = '[COD PAYMENT TERM DETAILS] Get COD Payment Term Details Data Success',
  GET_COD_PAYMENT_TERM_DETAILS_FAILURE = '[COD PAYMENT TERM DETAILS] Get COD Payment Term Data Failure'
}

export class AddFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.ADD_FEE_RULE;
  constructor(public payload: any) {}
}

export class AddFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.ADD_FEE_RULE_SUCCESS;

  constructor(public payload: any) {}
}

export class AddFeeRuleFailureAction implements Action {
  readonly type = SettingsActionTypes.ADD_FEE_RULE_FAILURE;

  constructor(public payload: any) {}
}

export class AddBulkFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.ADD_BULK_FEE_RULE;

  constructor(public payload: any) {}
}

export class ResetAddBulkFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.RESET_ADD_BULK_FEE_RULE;
  constructor(public payload: any) {}
}

export class ResetAddBulkFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.RESET_ADD_BULK_FEE_RULE_SUCCESS;
  constructor(public payload: any) {}
}

export class AddBulkFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.ADD_BULK_FEE_RULE_SUCCESS;

  constructor(public payload: any) {}
}

export class AddBulkFeeRuleFailureAction implements Action {
  readonly type = SettingsActionTypes.ADD_BULK_FEE_RULE_FAILURE;

  constructor(public payload: any) {}
}

export class AddFeeCorrectionAction implements Action {
  readonly type = SettingsActionTypes.ADD_FEE_CORRECTION;

  constructor(public payload: any) {}
}

export class AddFeeCorrectionSuccessAction implements Action {
  readonly type = SettingsActionTypes.ADD_FEE_CORRECTION_SUCCESS;

  constructor(public payload: any) {}
}

export class AddFeeCorrectionFailureAction implements Action {
  readonly type = SettingsActionTypes.ADD_FEE_CORRECTION_FAILURE;

  constructor(public payload: any) {}
}

export class GetBulkFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.GET_BULK_FEE_RULE;

  constructor(public payload: any) {}
}

export class GetBulkFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.GET_BULK_FEE_RULE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetBulkFeeRuleFailureAction implements Action {
  readonly type = SettingsActionTypes.GET_BULK_FEE_RULE_FAILURE;

  constructor(public payload: any) {}
}

export class CloneFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.CLONE_FEE_RULE;

  constructor(public payload: any) {}
}

export class CloneFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.CLONE_FEE_RULE_SUCCESS;

  constructor(public payload: any) {}
}

export class CloneFeeRuleFailureAction implements Action {
  readonly type = SettingsActionTypes.CLONE_FEE_RULE_FAILURE;

  constructor(public payload: any) {}
}

export class GetFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_RULE;

  constructor(public payload: any) {}
}

export class GetFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_RULE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetFeeRuleFailureAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_RULE_FAILURE;

  constructor(public payload: any) {}
}

export class EditFeeRuleAction implements Action {
  readonly type = SettingsActionTypes.EDIT_FEE_RULE;

  constructor(public payload: any) {}
}

export class EditFeeRuleSuccessAction implements Action {
  readonly type = SettingsActionTypes.EDIT_FEE_RULE_SUCCESS;

  constructor(public payload: any) {}
}

export class EditFeeRuleFailureAction implements Action {
  readonly type = SettingsActionTypes.EDIT_FEE_RULE_FAILURE;

  constructor(public payload: any) {}
}

export class GetFeeListAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_LIST;

  constructor(public payload: any) {}
}

export class GetFeeListSuccessAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class GetFeeListFailureAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_LIST_FAILURE;

  constructor(public payload: any) {}
}

export class GetFeeDetailsAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_DETAILS;

  constructor(public payload: any) {}
}

export class GetFeeDetailsSuccessAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_DETAILS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetFeeDetailsFailureAction implements Action {
  readonly type = SettingsActionTypes.GET_FEE_DETAILS_FAILURE;

  constructor(public payload: any) {}
}

export class GetPropertyDetailsAction implements Action {
  readonly type = SettingsActionTypes.GET_PROPERTY_DETAILS;
  constructor(public payload: any) {}
}

export class GetPropertyDetailsSuccessAction implements Action {
  readonly type = SettingsActionTypes.GET_PROPERTY_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPropertyDetailsFailureAction implements Action {
  readonly type = SettingsActionTypes.GET_PROPERTY_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetTeamMembersData implements Action {
  readonly type = SettingsActionTypes.GET_TEAM_MEMBERS_DATA;
  constructor() {}
}

export class GetTeamMembersDataSuccess implements Action {
  readonly type = SettingsActionTypes.GET_TEAM_MEMBERS_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTeamMembersDataFailure implements Action {
  readonly type = SettingsActionTypes.GET_TEAM_MEMBERS_DATA_FAILURE;
  constructor(public payload: any) {}
}

export class getEInvoicingStatus implements Action {
  readonly type = SettingsActionTypes.GET_EINVOICE_STATUS;
  constructor() {}
}

export class getEInvoicingStatusSuccess implements Action {
  readonly type = SettingsActionTypes.GET_EINVOICE_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export class getEInvoicingStatusFailure implements Action {
  readonly type = SettingsActionTypes.GET_EINVOICE_STATUS_FAILURE;
  constructor(public payload: any) {}
}

export class GetWorkspaceSettingsData implements Action {
  readonly type = SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA;
  constructor() {}
}
export class GetWorkspaceSettingsDataSuccess implements Action {
  readonly type = SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetWorkspaceSettingsDataFailure implements Action {
  readonly type = SettingsActionTypes.GET_WORKSPACE_SETTINGS_DATA_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateWorkspaceSettingsData implements Action {
  readonly type = SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA;
  constructor(public payload: any) {}
}
export class UpdateWorkspaceSettingsDataSuccess implements Action {
  readonly type = SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateWorkspaceSettingsDataFailure implements Action {
  readonly type = SettingsActionTypes.UPDATE_WORKSPACE_SETTINGS_DATA_FAILURE;
  constructor(public payload: any) {}
}

export class GetBankDetailsList implements Action {
  readonly type = SettingsActionTypes.GET_BANK_DETAILS_LIST;
  constructor() {}
}

export class GetBankDetailsListSuccess implements Action {
  readonly type = SettingsActionTypes.GET_BANK_DETAILS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetBankDetailsListFailure implements Action {
  readonly type = SettingsActionTypes.GET_BANK_DETAILS_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetBankDetails implements Action {
  readonly type = SettingsActionTypes.GET_BANK_DETAILS;
  constructor() {}
}

export class GetBankDetailsSuccess implements Action {
  readonly type = SettingsActionTypes.GET_BANK_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetBankDetailsFailure implements Action {
  readonly type = SettingsActionTypes.GET_BANK_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetBusinessDetails implements Action {
  readonly type = SettingsActionTypes.GET_BUSINESS_DETAILS;
  constructor() {}
}

export class GetBusinessDetailsSuccess implements Action {
  readonly type = SettingsActionTypes.GET_BUSINESS_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetBusinessDetailsFailure implements Action {
  readonly type = SettingsActionTypes.GET_BUSINESS_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetCodPaymentTermDetails implements Action {
  readonly type = SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS;
  constructor() {}
}

export class GetCodPaymentTermDetailsSuccess implements Action {
  readonly type = SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCodPaymentTermDetailsFailure implements Action {
  readonly type = SettingsActionTypes.GET_COD_PAYMENT_TERM_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type SettingsActionsUnion =
  | AddFeeRuleAction
  | AddFeeRuleSuccessAction
  | AddFeeRuleFailureAction
  | ResetAddBulkFeeRuleAction
  | AddBulkFeeRuleAction
  | AddBulkFeeRuleSuccessAction
  | AddBulkFeeRuleFailureAction
  | AddFeeCorrectionAction
  | AddFeeCorrectionSuccessAction
  | AddFeeCorrectionFailureAction
  | GetBulkFeeRuleAction
  | GetBulkFeeRuleSuccessAction
  | GetBulkFeeRuleFailureAction
  | CloneFeeRuleAction
  | CloneFeeRuleSuccessAction
  | CloneFeeRuleFailureAction
  | GetFeeRuleAction
  | GetFeeRuleSuccessAction
  | GetFeeRuleFailureAction
  | EditFeeRuleAction
  | EditFeeRuleSuccessAction
  | EditFeeRuleFailureAction
  | GetFeeListAction
  | GetFeeListSuccessAction
  | GetFeeListFailureAction
  | GetFeeDetailsAction
  | GetFeeDetailsSuccessAction
  | ResetAddBulkFeeRuleSuccessAction
  | GetFeeDetailsFailureAction
  | GetPropertyDetailsAction
  | GetPropertyDetailsSuccessAction
  | GetPropertyDetailsFailureAction
  | GetTeamMembersData
  | GetTeamMembersDataSuccess
  | GetTeamMembersDataFailure
  | getEInvoicingStatus
  | getEInvoicingStatusSuccess
  | getEInvoicingStatusFailure
  | GetWorkspaceSettingsData
  | GetWorkspaceSettingsDataSuccess
  | GetWorkspaceSettingsDataFailure
  | UpdateWorkspaceSettingsData
  | UpdateWorkspaceSettingsDataSuccess
  | UpdateWorkspaceSettingsDataFailure
  | GetBankDetailsList
  | GetBankDetailsListSuccess
  | GetBankDetailsListFailure
  | GetBankDetails
  | GetBankDetailsSuccess
  | GetBankDetailsFailure
  | GetBusinessDetails
  | GetBusinessDetailsSuccess
  | GetBusinessDetailsFailure
  | GetCodPaymentTermDetails
  | GetCodPaymentTermDetailsSuccess
  | GetCodPaymentTermDetailsFailure;
