<div class="browesr-pg">
  <div class="txt">
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo.svg" />
    <h2>Improve Your Experience</h2>
    <p>
      Sorry! We don’t support your browser at this moment. <br />
      For better Eshopbox web experience we recommend <br />
      you to use these web browsers.
    </p>
    <div class="browser-img">
      <a>
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/chrome-logo.png" />
        Google Chrome
      </a>
      <a>
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/fire-fox-logo.png" />
        Google Chrome
      </a>
    </div>
  </div>
</div>
