import { InstallAppListState } from '../state/install-app-list.state';
import { InstalledAppsActions, InstallAppListActionsTypes } from '../actions/install-app-list.action';

export const initialState: InstallAppListState = {
  installAppList: {
    loaded: false,
    loading: true,
    data: null,
    uninstall: {
      status: false,
      title: ''
    }
  },
  installAppListDetail: {
    loading: true,
    data: null
  },
  connectionByDetail: {
    loading: true,
    data: null
  },
  disconnectByIdDetail: {
    loading: true,
    data: null
  },
  fullfilmentCenterListDetail: {
    loading: true,
    data: null
  },
  ChannelListById: {
    loading: true,
    data: null
  },
  fieldPageList: {
    loading: true,
    data: null
  },
  getContentFulDetail: {
    loading: true,
    data: null
  },
  stepListDetail: {
    loading: true,
    data: null
  },
  catogryCount: {
    loading: true,
    data: null
  },
  nykaaProductSync: {
    loading: true,
    data: null
  },
  searchTerm: null,
  loading: false
};

export function InstallAppsListReducer(state = initialState, action: InstalledAppsActions) {
  switch (action.type) {
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST:
      return {
        ...state,
        installAppList: {
          loading: true,
          loaded: false,
          data: null,
          uninstall: {
            status: false
          }
        }
      };
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST_SUCCESS:
      return {
        ...state,
        installAppList: {
          loading: false,
          loaded: true,
          data: action.payload,
          uninstall: {
            status: false
          }
        }
      };
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST_FAILURE:
      return {
        ...state,
        installAppList: {
          loading: false,
          loaded: true,
          data: action.payload,
          uninstall: {
            status: false
          }
        }
      };
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL:
      return {
        ...state,
        installAppListDetail: {
          loading: false,
          data: null
        }
      };
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        installAppListDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST_DETAIL_FAILURE:
      return {
        ...state,
        installAppListDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_INSTALL_APP_LIST_SEARCH:
      return {
        ...state,
        searchTerm: action.payload
      };
    case InstallAppListActionsTypes.SHOW_PROGRESS_SPINNER:
      return {
        ...state,
        loading: true
      };
    case InstallAppListActionsTypes.HIDE_PROGRESS_SPINNER:
      return {
        ...state,
        loading: false
      };
    case InstallAppListActionsTypes.UNINSTALL_APP:
      return {
        ...state,
        installAppList: {
          loading: true,
          uninstall: {
            status: false,
            title: action.title
          }
        }
      };

    case InstallAppListActionsTypes.UNINSTALL_APP_SUCCESS:
      return {
        ...state,
        installAppList: {
          loading: false,
          uninstall: {
            status: true,
            title: action.payload
          }
        }
      };
    case InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL:
      return {
        ...state,
        connectionByDetail: {
          loading: false,
          data: null
        }
      };
    case InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL__SUCCESS:
      return {
        ...state,
        connectionByDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_CONNECTION_BY_ID_DETAIL__FAILURE:
      return {
        ...state,
        connectionByDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL:
      return {
        ...state,
        getContentFulDetail: {
          loading: false,
          data: null
        }
      };
    case InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL__SUCCESS:
      return {
        ...state,
        getContentFulDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_CONTENTFULL_BY_ID_DETAIL__FAILURE:
      return {
        ...state,
        getContentFulDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_STEPLIST_DETAIL:
      return {
        ...state,
        stepListDetail: {
          loading: false,
          data: null
        }
      };
    case InstallAppListActionsTypes.GET_STEPLIST_DETAIL__SUCCESS:
      return {
        ...state,
        stepListDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_STEPLIST_DETAIL__FAILURE:
      return {
        ...state,
        stepListDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST:
      return {
        ...state,
        fullfilmentCenterListDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_SUCCESS:
      return {
        ...state,
        fullfilmentCenterListDetail: {
          loading: false,
          data: action.payload
        }
      };

    case InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_FAILURE:
      return {
        ...state,
        fullfilmentCenterListDetail: {
          loading: false,
          data: action.payload
        }
      };

    case InstallAppListActionsTypes.GET_CHANNEL_LIST:
      return {
        ...state,
        ChannelListById: {
          loading: false
        }
      };
    case InstallAppListActionsTypes.GET_CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        ChannelListById: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_FULLFILMENT_CENTER_LIST_FAILURE:
      return {
        ...state,
        ChannelListById: {
          loading: false,
          data: action.payload
        }
      };

    case InstallAppListActionsTypes.GET_FIELD_PAGE_LIST:
      return {
        ...state,
        fieldPageList: {
          loading: false
        }
      };
    case InstallAppListActionsTypes.GET_FIELD_PAGE_LIST_SUCCESS:
      return {
        ...state,
        fieldPageList: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_FIELD_PAGE_LIST_FAILURE:
      return {
        ...state,
        fieldPageList: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL:
      return {
        ...state,
        disconnectByIdDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL_SUCCESS:
      return {
        ...state,
        disconnectByIdDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_DISCONNECT_BY_ID_DETAIL_FAILURE:
      return {
        ...state,
        disconnectByIdDetail: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST:
      return {
        ...state,
        catogryCount: {
          loading: false
        }
      };
    case InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST_SUCCESS:
      return {
        ...state,
        catogryCount: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_CATOGRY_COUNT_LIST_FAILURE:
      return {
        ...state,
        catogryCount: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC:
      return {
        ...state,
        nykaaProductSync: {
          loading: true,
          data: null
        }
      };
    case InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC_SUCCESS:
      return {
        ...state,
        nykaaProductSync: {
          loading: false,
          data: action.payload
        }
      };
    case InstallAppListActionsTypes.GET_NYKAA_PRODUCT_SYNC_FAILURE:
      return {
        ...state,
        nykaaProductSync: {
          loading: false,
          data: action.payload
        }
      };
    default: {
      return state;
    }
  }
}
export const getInstalledAppsList = (state: InstallAppListState) => state.installAppList.data;
export const getInstalledAppsListLoading = (state: InstallAppListState) => state.installAppList.loading;
export const getInstalledAppsListLoaded = (state: InstallAppListState) => state.installAppList.loaded;
export const getInstalledAppsListDetail = (state: InstallAppListState) => state.installAppListDetail;
export const getInstalledAppsListDetailLoading = (state: InstallAppListState) => state.installAppListDetail.loading;
export const getInstalledAppsListSearch = (state: InstallAppListState) => state.searchTerm;
export const getProgressSpinner = (state: InstallAppListState) => state.loading;
export const getUninstallStatus = (state: InstallAppListState) => state.installAppList.uninstall;
export const getConnectionByIdDetail = (state: InstallAppListState) => state.connectionByDetail.data;
export const getConnectionByIdLoading = (state: InstallAppListState) => state.connectionByDetail.loading;
export const getFullfilmentCenterList = (state: InstallAppListState) => state.fullfilmentCenterListDetail.data;
export const getFullfilmentCenterListLoading = (state: InstallAppListState) =>
  state.fullfilmentCenterListDetail.loading;
export const getChannelListById = (state: InstallAppListState) => state.ChannelListById.data;
export const getChannelListByIdLoading = (state: InstallAppListState) => state.ChannelListById.loading;
export const getFieldPageList = (state: InstallAppListState) => state.fieldPageList.data;
export const getFieldPageListLoading = (state: InstallAppListState) => state.fieldPageList.loading;
export const getContentfulByIdDetail = (state: InstallAppListState) => state.getContentFulDetail.data;
export const getContentfulByIdLoading = (state: InstallAppListState) => state.getContentFulDetail.loading;
export const getStepListtDetail = (state: InstallAppListState) => state.stepListDetail.data;
export const getStepListLoading = (state: InstallAppListState) => state.stepListDetail.loading;
export const getDisconnectByIdDetail = (state: InstallAppListState) => state.disconnectByIdDetail.data;
export const getDisconnectByIdDetailLoading = (state: InstallAppListState) => state.disconnectByIdDetail.loading;
export const getCatogryCountDetails = (state: InstallAppListState) => state.catogryCount.data;
export const getCatogryCountDetailsLoading = (state: InstallAppListState) => state.catogryCount.loading;
export const getnykaaProductSyncDetails = (state: InstallAppListState) => state.nykaaProductSync.data;
export const getnykaaProductSyncDetailsLoading = (state: InstallAppListState) => state.nykaaProductSync.loading;
