import * as bbActions from '../actions/buildingblock.actions';
import { BuildingBlockState } from '../state/buildingblock.state';

export const initialBBState: BuildingBlockState = {
  workFlowSettings: {
    getWorkflowSettingsLoaded: false,
    getWorkflowSettingsLoading: false,
    getWorkflowSettings: null
  }
};

export function BuildingBlockReducer(
  bbstate = initialBBState,
  action: bbActions.BuildingBlockActionsList
): BuildingBlockState {
  switch (action.type) {
    case bbActions.BuildingBlockActionsTypes.GET_BB_WORKFLOW_SETTINGS_DATA: {
      return {
        ...bbstate,
        workFlowSettings: {
          getWorkflowSettingsLoading: true,
          getWorkflowSettingsLoaded: false,
          getWorkflowSettings: null
        }
      };
    }
    case bbActions.BuildingBlockActionsTypes.GET_BB_WORKFLOW_SETTINGS_DATA_SUCCESS: {
      return {
        ...bbstate,
        workFlowSettings: {
          getWorkflowSettingsLoading: false,
          getWorkflowSettingsLoaded: true,
          getWorkflowSettings: action.payload
        }
      };
    }
    case bbActions.BuildingBlockActionsTypes.GET_BB_WORKFLOW_SETTINGS_DATA_FAILURE: {
      return {
        ...bbstate,
        workFlowSettings: {
          getWorkflowSettingsLoading: false,
          getWorkflowSettingsLoaded: false,
          getWorkflowSettings: action.payload
        }
      };
    }

    default: {
      return bbstate;
    }
  }
}
export const getWorkspaceSettings = (bbState: BuildingBlockState) => bbState.workFlowSettings;
