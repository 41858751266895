import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeLayoutComponent } from './_layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { LoggedInGuard } from './_guards/loggedIn.guard';
import { BadGatewayComponent } from './shared/component/bad-gateway/bad-gateway.component';
import { AppInstallationComponent } from './app-installation/app-installation.component';
import { InstallPageComponent } from './app-installation/install-app-page/install-app-page.component';
import { InstalledAppAuthorizationComponent } from './app-installation/installed-app-authorization/installed-app-authorization.component';
import { InstallAuthorizeUrlComponent } from './app-installation/app-install-authorize-url/app-install-authorize-url.component';
import { RBACGuard } from './_guards/role.guard';
import { NotAccessibleComponent } from './components/not-accessible/not-accessible.component';
import { PageNotFoundComponent } from './auth/components/page-not-found/page-not-found.component';
import { LoginToDesktopComponent } from './auth/components/login-to-desktop/login-to-desktop.component';
import { WorkspaceDataResolver } from './_guards/workspaceData.resolver';
import { WorkspaceRoleGuard } from './_guards/workspace-role.guard';
import { SomethingWentWrongComponent } from './components/something-went-wrong/something-went-wrong.component';
const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },

  {
    path: 'something-went-wrong',
    component: SomethingWentWrongComponent,
    data: { title: 'Something Went Wrong' }
  },

  {
    path: 'app/connection/redirecturl/:appId',
    component: InstalledAppAuthorizationComponent,
    data: { title: 'App Authorization - Eshopbox' }
  },
  {
    path: 'installation/authorize',
    component: AppInstallationComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'public/app-redirecturl/:cilentId',
    component: InstallPageComponent
    // canActivate: [LoggedInGuard]
  },
  {
    path: 'public/app-url/:cilentId',
    component: InstallAuthorizeUrlComponent
    // canActivate: [LoggedInGuard]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'registration',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'invite',
        loadChildren: () => import('./team-invitation/team-invitation.module').then(m => m.TeamInvitationModule)
      }
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      // {
      //   path: 'fdr-dashboard',
      //   canLoad: [LoggedInGuard],
      //   canActivate: [WorkspaceRoleGuard],
      //   resolve: { coreData: WorkspaceDataResolver },
      //   loadChildren: () => import('./fdr-dashboard/fdr-dashboard.module').then(m => m.FdrDashboardModule),
      //   data: { title: 'Failed Delivery Report Dashboard - Eshopbox', scope: 'reports' }
      // },
      {
        path: 'reports',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: { scope: 'reports' }
      },
      {
        path: 'reports',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'consignment',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./consignment/consignment.module').then(m => m.ConsignmentModule)
      },
      {
        path: 'all-b2b-orders',
        loadChildren: () => import('./new-b2b-orders/module/b2b-order.module').then(m => m.B2BOrdersModule)
      },
      {
        path: 'customer-portal',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./customer-portal/customer-portal.module').then(m => m.CustomerPortalModule),
        data: { scope: 'customer_portal' }
      },
      {
        path: 'notification-setting',
        canLoad: [LoggedInGuard],
        loadChildren: () =>
          import('./notification-setting/notification-setting.module').then(m => m.NotificationSettingModule)
      },
      {
        path: 'installed-app-list',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./apps-automation/apps-automation.module').then(m => m.InstalledAppListModule),
        data: { scope: 'apps' }
      },
      {
        path: 'product',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./products/product.module').then(m => m.ProductModule),
        data: { scope: 'products' }
      },
      {
        path: 'building-blocks',
        canLoad: [LoggedInGuard],
        canActivateChild: [RBACGuard],
        // resolve: { coreData: FlexDataResolver },
        loadChildren: () => import('./building-blocks/building-blocks.module').then(m => m.BuildingBlocksModule)
      },

      {
        path: 'fc',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./fc/fc.module').then(m => m.FcModule),
        data: { scope: 'locations' }
      },
      {
        path: 'channel',
        loadChildren: () => import('./channels/channels.module').then(m => m.ChannelsModule)
      },
      // {
      //   path: 'setup',
      //   canLoad: [LoggedInGuard],
      //   loadChildren: './setup/setup.module#SetupModule',
      //   data: { title: 'Getting started - Eshopbox' }
      // },
      {
        path: 'bad-gateway',
        component: BadGatewayComponent,
        data: { title: 'Bad Gateway' }
      },
      {
        path: 'settings',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'activities',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./activity/activity-main/activity-main.module').then(m => m.ActivityMainModule)
      },
      {
        path: 'reconciliation',
        canLoad: [LoggedInGuard],
        children: [
          {
            path: '',
            redirectTo: 'invoice',
            pathMatch: 'full'
          },
          {
            path: 'invoice',
            canLoad: [LoggedInGuard],
            canActivate: [WorkspaceRoleGuard],
            resolve: { coreData: WorkspaceDataResolver },
            data: { scope: 'payments' },
            loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoiceModule)
          },
          {
            path: 'payments',
            canLoad: [LoggedInGuard],
            canActivate: [WorkspaceRoleGuard],
            resolve: { coreData: WorkspaceDataResolver },
            data: { scope: 'payments' },
            loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
          },
          {
            path: 'expense',
            canLoad: [LoggedInGuard],
            canActivate: [WorkspaceRoleGuard],
            resolve: { coreData: WorkspaceDataResolver },
            data: { scope: 'payments' },
            loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoiceModule)
          }
        ]
      },
      {
        path: 'order-V2',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        data: { scope: 'orders' },
        loadChildren: () => import('./orderV2/order.module').then(m => m.OrderModule)
      },
      {
        path: 'dashboard',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        data: { title: 'Home - Eshopbox', scope: 'reports' }
      },
      {
        path: 'claims',
        canLoad: [LoggedInGuard],
        // canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule),
        data: { title: 'claims - Eshopbox', scope: 'claims' }
      },
      {
        path: 'create-return',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./records/records.module').then(m => m.RecordsModule),
        data: { title: 'Create Return Building Blocks - Eshopbox' }
      },
      {
        path: 'add-action',
        canLoad: [LoggedInGuard],
        canActivateChild: [RBACGuard],
        // resolve: { coreData: FlexDataResolver },
        loadChildren: () => import('./records/records.module').then(m => m.RecordsModule)
      },
      {
        path: 'report',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'enabling-self-fulfilment',
        component: NotAccessibleComponent,
        data: { title: 'Building - Block - Enable - Self - Fulfilment' }
      },
      {
        path: 'access-denied/:scope',
        component: NotAccessibleComponent,
        data: { title: 'Building - Block - Access - Denied' }
      },
      {
        path: 'tickets',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: 'tool/pincode',
        canLoad: [LoggedInGuard],
        loadChildren: () =>
          import('./tool/pincode-serviceability/pincode-serviceability.module').then(m => m.PincodeServiceabilityModule)
      },
      {
        path: 'process-no-scan-return',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./no-scan-return/no-scan-return.module').then(m => m.NoScanReturnModule)
      },
      {
        path: 'tool/incidents',
        canLoad: [LoggedInGuard],
        canActivate: [WorkspaceRoleGuard],
        resolve: { coreData: WorkspaceDataResolver },
        loadChildren: () =>
          import('./tool/incident-management/incident-management.module').then(m => m.incidentManagementModeModule),
        data: { title: 'Incidents - Eshopbox', scope: 'incident_management' }
      },
      {
        path: 'tool/ratecalculator',
        canLoad: [LoggedInGuard],
        loadChildren: () => import('./tool/rate-calculator/rate-calculator.module').then(m => m.RateCalculatorModule)
      }
    ]
  },
  { path: 'login-using-desktop/:type', component: LoginToDesktopComponent, data: { title: 'Login Using Desktop' } },
  { path: '**', component: PageNotFoundComponent, data: { title: 'Page Not Found' } }
  // { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
