import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-attach-files',
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.scss']
})
export class AttachFilesComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() columnName: string = '';
  @Input() isMultiSelect: boolean = false;
  @Input() isMultiValue: boolean = false;

  fileName: string = '';
  isImage: boolean = false;
  isPdf: boolean = false;
  isExcel: boolean = false;
  isZip: boolean = false;
  isDoc: boolean = false;
  isFile: boolean = false;
  isSingleSelect: boolean = false;

  referenceRecordKeys: Array<string> = [];

  constructor() {}

  ngOnInit() {
    console.log('In Attach');
  }

  ngOnChanges() {
    console.log(this.data);
    console.log(this.columnName);
    console.log(this.isMultiSelect);
    console.log(this.isMultiValue);

    if (typeof this.data === 'string') {
      this.isSingleSelect = true;
    }

    if (this.columnName === 'Attachment') {
      console.log(this.columnName);
      console.log(this.data);
      this.fileName = this.data.split('/').pop();
      console.log(this.fileName);
      let fileFormat = this.fileName.split('.').pop();
      if (fileFormat === 'jpg' || fileFormat === 'png' || fileFormat === 'jpeg') {
        console.log(fileFormat);
        this.isImage = true;
      } else if (fileFormat === 'pdf') {
      }
    }

    if (this.columnName === 'Reference') {
      console.log(this.data);
      this.referenceRecordKeys = Object.keys(this.data['record']);
      console.log(this.referenceRecordKeys);
    }
  }

  downloadFile(data: any, fileName: string) {
    console.log(data);
    console.log(fileName);

    const blob = new Blob([data], { type: 'image/png' });
    FileSaver.saveAs(blob, fileName);
  }

  onCancel() {
    // this.dialogRef.close(true);
  }
}
