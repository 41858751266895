import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timePipe'
})
export class TimePipe implements PipeTransform {
  transform(value: any): any {
    if (value && typeof value != 'object') {
      value = Array.isArray(value) ? value[0].replace('00Z', '00.000Z') : value.replace('00Z', '00.000Z');
      if (value.includes('T')) {
        let formatTime = value.split('T')[1].split(':');
        let time = formatTime[0] + '.' + formatTime[1];
        return value.split('T')[0] + ' ' + moment(time, ['HH.mm']).format('hh:mm A');
      }
    }
    // else {
    //   return false;
    // }
  }
}
