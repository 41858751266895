import { AppAuthorizationState } from '../state/installed-app-authorization.state';
import { AppAuthorizationActionTypes, AppAuthorizationActions } from '../actions/installed-app-authorization.action';

export const initialState: AppAuthorizationState = {
  appAuthorizationData: {},
  loading: true
};

export function AppAuthorizationReducer(state = initialState, action: AppAuthorizationActions) {
  switch (action.type) {
    case AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION:
      return {
        ...state,
        loading: true
      };
    case AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        appAuthorizationData: action.payload,
        loading: false
      };
    case AppAuthorizationActionTypes.CHECK_APP_AUTHORIZATION_FAILURE:
      return {
        ...state,
        appAuthorizationData: action.payload,
        loading: false
      };
    default:
      return state;
  }
}

export const getAppAuthorizationData = (state: AppAuthorizationState) => state.appAuthorizationData;
export const getLoading = (state: AppAuthorizationState) => state.loading;
