import { AppInstallState } from '../state/app-install.state';
import { AppInstallActions, AppInstallActionTypes } from '../actions/app-install.action';

export const initialState: AppInstallState = {
  scope: {
    scopeData: [],
    isScopeLoading: false,
    isScopeLoaded: false
  },
  appDetails: {
    appDetailsData: [],
    isAppDetailsLoading: false,
    isAppDetailsLoaded: false
  },
  appInstallConsent: {
    appInstallConsent: null,
    loading: true
  }
};

export function AppInstallReducer(state = initialState, action: AppInstallActions) {
  switch (action.type) {
    case AppInstallActionTypes.GET_APP_SCOPE:
      return {
        ...state,
        scope: {
          isScopeLoading: true,
          isScopeLoaded: false
        }
      };
    case AppInstallActionTypes.GET_APP_SCOPE_SUCCESS:
      return {
        ...state,
        scope: {
          isScopeLoading: false,
          isScopeLoaded: true,
          scopeData: action.payload
        }
      };
    case AppInstallActionTypes.GET_APP_SCOPE_FAILURE:
      return {
        ...state,
        scope: {
          isScopeLoading: false,
          isScopeLoaded: true,
          scopeData: action.payload
        }
      };
    case AppInstallActionTypes.GET_APP_DETAILS:
      return {
        ...state,
        appDetails: {
          isAppDetailsLoading: true,
          isAppDetailsLoaded: false
        }
      };
    case AppInstallActionTypes.GET_APP_DETAILS_SUCCESS:
      return {
        ...state,
        appDetails: {
          isAppDetailsLoading: false,
          isAppDetailsLoaded: true,
          appDetailsData: action.payload
        }
      };
    case AppInstallActionTypes.GET_APP_DETAILS_FAILURE:
      return {
        ...state,
        appDetails: {
          isAppDetailsLoading: false,
          isAppDetailsLoaded: true,
          appDetailsData: action.payload
        }
      };
    case AppInstallActionTypes.GET_APP_INSTALL_CONSENT:
      return {
        ...state,
        appInstallConsent: {
          loading: true,
          appInstallConsent: null
        }
      };
    case AppInstallActionTypes.GET_APP_INSTALL_CONSENT_SUCCESS:
      return {
        ...state,
        appInstallConsent: {
          loading: false,
          appInstallConsent: action.payload
        }
      };
    case AppInstallActionTypes.GET_APP_INSTALL_CONSENT_FAILURE:
      return {
        ...state,
        appInstallConsent: {
          loading: false,
          appInstallConsent: action.payload
        }
      };
    default:
      return state;
  }
}

export const getScopeData = (state: AppInstallState) => state.scope.scopeData;
export const getIsScopeLoading = (state: AppInstallState) => state.scope.isScopeLoading;
export const getIsScopeLoaded = (state: AppInstallState) => state.scope.isScopeLoaded;
export const getAppDetailsData = (state: AppInstallState) => state.appDetails.appDetailsData;
export const getIsAppDetailsLoading = (state: AppInstallState) => state.appDetails.isAppDetailsLoading;
export const getIsAppDetailsLoaded = (state: AppInstallState) => state.appDetails.isAppDetailsLoaded;
export const getAppInstallConsentData = (state: AppInstallState) => {
  return state.appInstallConsent;
};
