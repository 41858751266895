<!-- New headers start -->
<section class="flex-section">
  <div class="sub-menu">
    <div class="new-header-top">
      <!-- Left || Page title -->
      <div class="heading-learnmore">
        <h2 class="heading">
          <ng-content select="[left]"></ng-content>
        </h2>
        <!-- Learn more -->
        <a *ngIf="learnMore" class="learnmore-heading" [href]="learnMore.link" target="_blank"
          ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/learn-more.svg" />
          {{ learnMore.text }}
        </a>
      </div>
      <!-- Center || Search -->
      <div class="input-search">
        <ng-content select="[center]"></ng-content>
      </div>
      <!-- Right || Buttons -->
      <div class="new-header-btns">
        <ng-content select="[right]"></ng-content>
      </div>
    </div>
  </div>
</section>
<!-- New headers start -->
