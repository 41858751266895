import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Configuration } from 'src/app/constants/config';
import { ApiService, AuthService } from 'src/app/shared/services';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment as env } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OrderRoutingService {
  private accountSlug: string;
  private messageSource = new Subject<any>();
  private BASE_DOMAIN = env.BASE_DOMAIN;
  private AuthorizationToken: string;
  private accountslug: string;
  ApiUrl: string;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router,
    private API_URL: Configuration,
    private api: ApiService
  ) {
    this.AuthorizationToken = this.authService.getAuthToken();
    if (this.AuthorizationToken === undefined || this.AuthorizationToken == null) {
    }
    this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;
    // Get slug url
    this.accountslug = this.authService.getAccountSlug();
    if (this.accountslug === undefined || this.accountslug == null) {
    }
    this.ApiUrl = 'https://' + this.accountslug + '' + this.BASE_DOMAIN + '/shipping/api/v1/routingrules';
  }
  getRuleFields() {
    return this.http.get(this.ApiUrl + '/combination', {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  saveRule(data) {
    if (data.id) {
      return this.api.put(this.ApiUrl + '/' + data.id, data);
    } else {
      return this.http.post(this.ApiUrl + '', data, {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
      });
    }
  }

  getAllRules() {
    return this.http.get(this.ApiUrl + '', {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.AuthorizationToken)
    });
  }

  deleteRule(data) {
    return this.api.put(
      'https://' + this.accountslug + '' + this.BASE_DOMAIN + '/shipping/api/v1/routingrules/' + data.id,
      data
    );
  }

  getRuleById(id) {
    return this.api.get('https://' + this.accountslug + '' + this.BASE_DOMAIN + '/shipping/api/v1/rule/' + id);
  }

  public getMessage(): Observable<any> {
    return this.messageSource.asObservable();
  }

  public setMessage(message: any) {
    return this.messageSource.next(message);
  }

  public getLocation() {
    let completeWarehouseList = localStorage.warehouseList ? JSON.parse(localStorage.warehouseList) : [];
    return completeWarehouseList.filter(e => e.enrollmentStatus == 'ENROLLED');
  }

  public sortData(data, sortingBasedOnKey: string) {
    return data.sort((a, b) => {
      return a[sortingBasedOnKey] > b[sortingBasedOnKey] ? 1 : -1;
    });
  }
}
