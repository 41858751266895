<div class="dialog-header">
  <h2 mat-dialog-title>Edit</h2>
  <button mat-dialog-close>
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </button>
</div>
<mat-dialog-content>
  <div class="dialog-body">
    <form [formGroup]="editTemplateForm" (ngSubmit)="editTemplate()">
      <div class="pad-section border-none">
        <div class="form-field">
          <label> Report name</label>
          <input placeholder="Enter report name" formControlName="name" type="text" />
        </div>
        <div class="form-field">
          <label> Description </label>
          <input placeholder="Enter description" formControlName="description" type="text" />
        </div>
      </div>
      <div class="btn-wrp pad-section border-none pad-t-n">
        <div class="align-left">
          <a class="delete-tem" [class.loader]="loaderDelete" (click)="deleteConfirmationDialog()">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/trash.svg" /> Delete template
          </a>
        </div>
        <div class="btn-align right">
          <button class="btn-export" [class.primary-btn-loader]="loaderEdit" [disabled]="!this.editTemplateForm.valid">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
