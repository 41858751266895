import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Configuration } from '../../constants/config';
import { AlertService } from './alert.service';
import { ROUTES, ROUTESDATA } from '../../constants/routes';
import { ALERT_MESSAGES } from 'src/app/constants/constants';
import {
  AuthActionTypes,
  GetAccountList,
  GetCurrentLoggedInUserData,
  GetFulfillmentCenterData
} from 'src/app/auth/actions/auth.actions';
import { UserService } from './user.service';
import { ERROR_MESSAGES } from 'src/app/constants/error_code_messages';
import { FcActionTypes } from 'src/app/fc/store/actions/fc.action';
import { Subject, Subscription, combineLatest, of } from 'rxjs';
import { Auth0LockPasswordless } from 'auth0-lock';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ChannelsActionTypes } from 'src/app/channels/store/actions/channels.action';
import Pusher from 'pusher-js';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Platform } from '@angular/cdk/platform';
import {
  getAccountList,
  getCurrentLoggedInUserData,
  getFulfillmentCenterData
} from 'src/app/auth/reducers/auth.selectors';
import { isArray } from 'src/app/records/utils/common-util';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private messageSource = new BehaviorSubject(true);
  currentMessage = this.messageSource.asObservable();
  // Keys For Registring Pusher Connection
  PUSHER_KEY = environment.pusher.PUSHER_KEY;
  PUSHER_CLUSTER = environment.pusher.PUSHER_CLUSTER;
  pusher: any;
  accountList = [];
  publicSignupDisabled = `Sorry, the email address isn't registered. Please <a href="/auth/signup${
    window.location.search ? window.location.search : ''
  }"  target='_self'>sign up</a> or accept any pending invitations sent to your email. <a href='https://help.eshopbox.com/en/articles/4562779-i-can-t-log-in-what-should-i-do' target='_blank'>Learn more</a>`;
  private PLATFORM_API_URL = environment.PLATFORM_API_URL;
  private auth0 = new Auth0LockPasswordless(environment.AUTH.CLIENT_ID, environment.AUTH.DOMAIN, {
    oidcConformant: false,
    autoclose: true,
    closable: false,
    rememberLastLogin: false,
    allowSignup: false,
    container: 'hiw-login-container',
    configurationBaseUrl: 'https://cdn.auth0.com',
    // overrides: {
    //   __tenant: environment.AUTH.TENANT,
    //   __token_issuer: environment.AUTH.ISSUER
    // },
    auth: {
      autoParseHash: false,
      redirectUrl: environment.AUTH.REDIRECT,
      responseType: 'token id_token',
      audience: environment.AUTH.AUDIENCE,
      params: {
        scope: 'openid profile email'
      }
    },
    theme: {
      logo: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo.svg?updatedAt=1622016547919',
      primaryColor: '#31324F',
      displayName: 'Sign in'
    },
    prefill: {
      email: localStorage.getItem('shopifyEmail') || ''
    },
    languageDictionary: {
      emailInputPlaceholder: 'Email Address',
      title: 'Log in to Eshopbox workspace',
      codeInputPlaceholder: 'Enter your 6 digit code',
      submitLabel: 'Continue',
      signUpTerms: ``,
      passwordlessEmailInstructions: 'We’ll email you a magic code for a password-free sign in.',
      // passwordlessEmailCodeInstructions: `We've sent 6-digit code to %s. The code expires shortly, so please enter it soon.`,
      resendCodeAction: `Didn't get the code? Retry`,
      error: {
        passwordless: {
          'bad.connection': this.publicSignupDisabled
        }
      }
    },
    allowedConnections: ['email'],
    passwordlessMethod: 'code'
    // allowedConnections: ['Username-Password-Authentication'],
  });

  authSuccessUrl = '';
  authFailureUrl = 'auth/login';
  ACCESS_TOKEN = 'access_token';
  WORKSPACE_KEY = 'accountSlug';
  WAREHOUSE_KEY = 'flexAccountSlug';
  WORKSPACE_LENGTH = 'noOfAccounts';
  // WORKSPACE_LIST_KEY = 'listOfAccounts';
  REDIRECT_URL_KEY = 'redirectUrl';
  REFRESH_TOKEN = 'refresh_token';
  // access_token = new BehaviorSubject<any>(0);
  // updated_access_token = this.access_token.asObservable();
  WORKSPACE_NAME = 'workspaceName';
  private segmentsApiUrl = environment.PLATFORM_API_URL;
  workSpaceSideBar: any;
  private http: HttpClient;
  SHOW_ALERT_OR_NOTIFICATION = 'notification';
  // ACCOUNT_LIST = 'account_list';
  CURRENT_FC = 'current_fc';
  RISKSCORE_FLAG = 'riskscore_flag';
  RISKSCORE_HOURS = 'riskscore_hours';
  redirect: string = environment.canny.redirect;
  companyID: string = environment.canny.comapnyId;
  loggedInUserSubscription$: Subscription;
  ROUTESDATA = ROUTESDATA;
  IS_BATCHING = 'is_batching';
  isOtpPopupOpen: Subject<any> = new Subject<any>();
  constructor(
    private api: ApiService,
    private router: Router,
    private API_URL: Configuration,
    private cookieService: CookieService,
    private alertService: AlertService,
    private userService: UserService,
    private store: Store<{ auth; fc }>,
    private handler: HttpBackend,
    private platform: Platform,
    private route: ActivatedRoute
  ) {
    this.http = new HttpClient(handler);
    // Registering Pusher Connection For the Workspace
    this.pusher = new Pusher(this.PUSHER_KEY, { cluster: this.PUSHER_CLUSTER });
    this.pusher.connection.bind('state_change', function(states) {
      // states = {previous: 'oldState', current: 'newState'}
      console.log('pusher state', states);
    });
    this.pusher.connection.bind('error', function(error) {
      console.error('pusher connection error log', error);
    });
  }

  getOtpPopupOpen() {
    return this.isOtpPopupOpen.asObservable();
  }

  setOtpPopupOpen(val) {
    this.isOtpPopupOpen.next(val);
  }

  public handleAuthenticationWithHash(): void {
    this.auth0.resumeAuth(window.location.hash, (err, authResult) => {
      if (authResult && authResult.accessToken) {
        localStorage.removeItem('shopifyEmail');
        this.setAuth(authResult.accessToken);
        let expiresAt = authResult.expiresIn * 1000 + Date.now();
        const redirectUrl = this.getReturnUrl();
        const cannyRedirectLocalStorage = localStorage.getItem('cannyRedirect');
        if (cannyRedirectLocalStorage == 'true') {
          this.redirectToStoredUrl('canny');
        } else if (redirectUrl) {
          this.redirectToStoredUrl(redirectUrl, authResult.idTokenPayload.name);
          return;
        } else this.router.navigate(['/auth/signin-complete']);
        // this.router.navigate([this.authSuccessUrl]);
      } else if (err) {
        console.log('ERROR FROM AUTH', err);
        // alert(`Error: ${err.error}. Check the console for further details.`);
        console.log('ERROR FROM AUTH===>', err);
        if (err?.error && err?.errorDescription) {
          if (err?.errorDescription === 'Unable to configure verification page.') {
            this.alertService.showError('Please enable third party cookies', 10000);
          } else {
            this.alertService.showError(err.errorDescription, 10000);
          }
        } else this.SHOW_ALERT_OR_NOTIFICATION = 'alert';
        this.router.navigate([this.authFailureUrl]);
      }
    });
  }

  // checkSessionEshopUser(workspaceAS?) {
  //   if (this.getAuthToken()) {
  //     let accountSlug = workspaceAS ? workspaceAS : this.getAccountSlug();
  //     return new Promise((resolve, reject) => {
  //       this.auth0.checkSession({ selected_workspace: accountSlug }, (err, authResult) => {
  //         if (authResult) {
  //           this.setAuth(authResult.accessToken);
  //           resolve(true);
  //         } else if (err) {
  //           this.alertService.showError(err.description ? err.description : err.error, 3000);
  //           if (err.error === 'login_required') this.logout();
  //           reject(err);
  //         }
  //       });
  //     });
  //   }
  // }

  checkSessionEshopUser(workspaceAS = '', location?, isWorkspaceSwitch = false) {
    if (this.getAuthToken()) {
      const accountSlug = workspaceAS ? workspaceAS : this.getAccountSlug();
      const decodedToken = jwt_decode(this.getAuthToken());
      const currentFlexSlug = this.getFlexSlug();

      // Safely access existing accounts in the decoded token
      const existingAccounts = decodedToken['https://accounts'] || [];

      // Safely access existing locations in the decoded token
      const existingLocations =
        !isWorkspaceSwitch && decodedToken['https://warehouseWorkspaces']
          ? decodedToken['https://warehouseWorkspaces']
          : [];

      // Create a Set from existing locations
      const uniqueLocations = new Set(existingLocations);

      // Check if currentFlexSlug or location already exist in existingLocations
      if (
        existingAccounts.includes(accountSlug) &&
        currentFlexSlug &&
        uniqueLocations.has(currentFlexSlug) &&
        location &&
        uniqueLocations.has(location)
      ) {
        return; // Return if currentFlexSlug already exists
      }

      // Add location only if it is defined
      if (location) {
        uniqueLocations.add(location);
      }

      // Create a comma-separated string from unique locations
      let uniqueCommaSeparatedLocationString = Array.from(uniqueLocations).join(',');

      // If existingLocations is empty and location is not provided, but currentFlexSlug is present
      if (existingLocations.length === 0 && !location && currentFlexSlug) {
        uniqueCommaSeparatedLocationString = currentFlexSlug;
      }

      const params = {
        selected_workspace: accountSlug
      };

      // Add selected_warehouse_workspaces to params only if certain conditions are met
      if (currentFlexSlug || location || existingLocations.length > 0) {
        params['selected_warehouse_workspaces'] = uniqueCommaSeparatedLocationString;
      }

      return new Promise((resolve, reject) => {
        this.auth0.checkSession(params, (err, authResult) => {
          if (authResult) {
            this.setAuth(authResult.accessToken);
            resolve(true);
          } else if (err) {
            this.alertService.showError(err.description ? err.description : err.error, 3000);
            if (err.error === 'login_required') this.logout();
            reject(err);
          }
        });
      });
    }
  }

  async checkSession() {
    if (!this.checkauthincookies() && this.getRefreshToken()) {
      await this.renewToken();
      return;
    }
    if (this.getAuthToken()) {
      return new Promise((resolve, reject) => {
        this.auth0.checkSession({}, (err, authResult) => {
          if (authResult) {
            this.setAuth(authResult.accessToken);
            resolve(authResult);
          } else if (err) {
            console.log('err', err);
            reject(err);
          }
        });
      });
    }
  }

  // renewToken() {
  //   const url = `api/v2/access_token`;
  //   const body = {
  //     refreshToken: this.getRefreshToken()
  //   };
  //   return this.api.post(url, body).pipe(
  //     tap(res => {
  //       if (res['accessToken']) {
  //         this.setAuth(res['accessToken']);
  //       }
  //     })
  //   );
  // }

  renewToken() {
    const url = `api/v2/access_token`;
    const body = {
      refreshToken: this.getRefreshToken()
    };

    return new Promise((resolve, reject) => {
      this.api.post(url, body).subscribe(
        res => {
          if (res['accessToken']) {
            this.setAuth(res['accessToken']);
            resolve(res);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /*** @description - Method To Get Get Stream Token To Access Get Stream Data*/
  public getActivityToken() {
    const TOKEN_API_URL = environment.ACTIVITY_TOKEN;
    return this.api.get(TOKEN_API_URL);
  }

  /*** @description - Method To Set Activity Token To Cookies*/
  public setGetStreamToken(getStreamToken) {
    // Set Activity token in storage When user selects Workspace
    this.cookieService.set('getStream_token', getStreamToken, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }

  public setIsBatching(IsBatching) {
    // Set Activity token in storage When user selects Workspace
    this.cookieService.set(this.IS_BATCHING, IsBatching, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }

  /*** @description - Method To Fetch Get Stream Workspace Token From Cookies*/
  public getIsBatching() {
    if (this.cookieService.get(this.IS_BATCHING) && this.cookieService.get(this.IS_BATCHING) !== '') {
      return this.cookieService.get(this.IS_BATCHING);
    } else {
      return null;
    }
  }

  /*** @description - Method To Fetch Get Stream Workspace Token From Cookies*/
  public fetchGetStreamToken() {
    if (this.cookieService.get('getStream_token') && this.cookieService.get('getStream_token') !== '') {
      return this.cookieService.get('getStream_token');
    } else {
      return null;
    }
  }

  /*** @description - Method To Get FulfillmentCenter Data From Backend*/
  public getFulfillmentCentreData() {
    return this.api.get(`${this.API_URL.CONFIG_URL.GET_FULFILLMENT_CENTERS}`);
  }

  /*** @description - Method To Get Sales Channel Data From Backend*/
  public getSalesChannelData() {
    return this.api.get(`${this.API_URL.CONFIG_URL.GET_SALES_CHANNELS}`);
  }

  /*** @description - Method To Get Segments Data From Backend*/
  public getSegmentsData(payload) {
    if (payload.accountType == 'flex') {
      const flexAccountSlug = this.getFlexSlug();
      const base = `https://${flexAccountSlug}${environment.BASE_DOMAIN}`;
      const URL = `${base}/${this.segmentsApiUrl}/segment`;
      const queryParams = { page: payload.page, per_page: payload.per_page };
      let header = new HttpHeaders().set('Authorization', 'Bearer ' + this.getAuthToken());
      return this.http.get(URL, { headers: header, params: queryParams });
    } else {
      const API_URL = this.segmentsApiUrl + '/segment';
      const queryParams = { page: payload.page, per_page: payload.per_page };
      return this.api.get(API_URL, { params: queryParams });
    }
  }

  redirectToStoredUrl(redirectUrl, email?) {
    this.getWorkspaceList().subscribe(
      res => {
        if (res) {
          if (redirectUrl != 'canny') {
            const redirectUrlSlug = redirectUrl.split('//')[1].split('.')[0];
            const workspace = this.checkUserHasAccessToAccount(redirectUrlSlug, res['data']);
            if (workspace) {
              this.onWorkspaceSelection(workspace, res['data'], 'normal', email);
            } else {
              this.router.navigate(['/auth/signin-complete']);
            }
          } else {
            const workspace = this.checkUserHasAccessToAccount(res['data'][0].accountSlug, res['data']);
            this.onWorkspaceSelection(workspace, res['data'], 'canny');
          }
        }
      },
      error => {
        const errorCode = error.error.error ? (error.error.error.message || '').split(':')[0] : '';
        if (!!ERROR_MESSAGES.GET_ACCOUNT_LIST[errorCode]) {
          this.alertService.showError(ERROR_MESSAGES.GET_ACCOUNT_LIST[errorCode]);
        }
      }
    );
  }

  onWorkspaceSelection(workspace, workspaceList, type, email?) {
    this.setNumberOfWorkspace(workspaceList.length);
    const slugArray = workspaceList.map(item => item.accountSlug);
    // this.setListOfAccounts(slugArray);
    this.setWorkspace(workspace.accountSlug);
    if (type != 'canny') {
      switch (workspace.userAccountMappingStatus) {
        case '0':
          this.userService.acceptInvite(workspace.userAccountMappingId).subscribe(res => {
            if (res) {
              if (email.includes('@eshopbox.com')) {
                this.router.navigate(['/auth/signin-complete']);
              } else this.loadInitialModule(true, false);
              // window.location.href = 'https://' + workspace.accountSlug + environment.BASE_DOMAIN;
            }
          }); // accept invite directly if invitation pending
          break;

        case '1':
          if (email.includes('@eshopbox.com')) {
            this.router.navigate(['/auth/signin-complete']);
          } else this.loadInitialModule(true, false);
          break;

        case '2':
          //logout if status inactive
          this.alertService.showError(ALERT_MESSAGES.INACTIVE_USER);
          setTimeout(() => {
            this.store.dispatch({ type: AuthActionTypes.LOGOUT });
          }, 3000);
          break;

        default:
          this.alertService.showError(ALERT_MESSAGES.INACTIVE_USER);
          setTimeout(() => {
            this.store.dispatch({ type: AuthActionTypes.LOGOUT });
          }, 3000);
          break;
      }
    } else {
      this.redirectToCannyInternally('canny');
    }
  }

  /**
   *
   * returns workspace if user has access to return url slug else null
   *
   * @author Saher Shaukat
   * @param accountSlug slug from redirect url
   * @param accountList list of workspaces user has access to
   */
  checkUserHasAccessToAccount(accountSlug, accountList) {
    if (!!accountList.length) {
      for (let i = 0; i < accountList.length; i++) {
        if (accountList[i].accountSlug.toLowerCase() === accountSlug.toLowerCase()) {
          return accountList[i];
        }
      }
    }
    return null;
  }

  signUp(user) {
    return this.api.post(`${this.API_URL.CONFIG_URL.SIGNUP}`, user);
  }
  signupViaInvite(user) {
    return this.api.post(`${this.API_URL.CONFIG_URL.SIGNUP}?acceptInvite=true`, user);
  }

  validateOtp(body) {
    const url = 'v2/user/otp/validate';
    return this.api.post(url, body);
  }

  createAccount(body) {
    return this.api.post(`${this.API_URL.CONFIG_URL.CREATE_ACCOUNT}`, body).pipe(
      map(res => {
        return res;
      }),
      catchError(error => of(error))
    );
  }

  createAccountSlug(payload) {
    const url = `${this.API_URL.CONFIG_URL.CREATE_ACCOUNT}/${payload.id}`;
    return this.api.put(url, payload.body);
  }

  getWorkspaceList() {
    // this.checkSession()
    return this.api.get(`${this.API_URL.CONFIG_URL.ACCOUNT_LIST}`);
  }

  /**@description Do not remove this commented code as this is in  under review */

  // private extractData(res: Response) {
  //   let body = res;
  //   return body || {};
  // }

  // // checking for account api multiple call
  // /**
  //  * @description Account api call from sidebar component
  //  */
  // getWorkSpaceListForSideBarBB() {
  //   if (this.workSpaceSideBar) {
  //     this.workSpaceSideBar = null;
  //     var promise = new Promise<void>((resolve, reject) => {
  //       resolve();
  //     });
  //     return promise;
  //     // return ;
  //   }

  //   const url = `${this.API_URL.CONFIG_URL.ACCOUNT_LIST}`;
  //   this.workSpaceSideBar = this.http
  //     .get(url)
  //     .pipe(
  //       map(this.extractData),
  //       tap(data => {})
  //     )
  //     .toPromise();
  //   return this.workSpaceSideBar;
  // }

  login() {
    this.auth0.show();
    setTimeout(() => {
      const continueBtn = document.getElementById('1-submit');
      if (continueBtn) {
        continueBtn.addEventListener('click', this.getEmail.bind(this));
      }
    }, 800);
  }

  getEmail() {
    const loginField = document.getElementById('1-email') as HTMLInputElement;
    if (loginField) {
      localStorage.setItem('shopifyEmail', loginField.value);
    }
  }

  forgotPassword(email) {
    this.auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email: email
      },
      function(err, res) {
        if (err) {
          return alert('Something went wrong: ' + err.message);
        }
        return alert(res);
      }
    );
  }

  /**
   * @author Saher Shaukat
   * @description sets user authentication token
   * @param {string} accessToken authentication token
   */
  setAuth(accessToken) {
    this.cookieService.set(this.ACCESS_TOKEN, accessToken, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
    // this.cookieService.set(this.ACCESS_TOKEN, accessToken, 3, '/', environment.COOKIE_DOMAIN, true, 'Lax');
  }

  removeAuthToken() {
    // Remove token from storage
    this.cookieService.delete(this.ACCESS_TOKEN);
  }

  /**
   * @author Saher Shaukat
   * @description returns user authentication token
   * @return {string} null if token not present
   */
  getAuthToken() {
    if (this.cookieService.get(this.ACCESS_TOKEN) && this.cookieService.get(this.ACCESS_TOKEN) !== '') {
      return this.cookieService.get(this.ACCESS_TOKEN);
    } else {
      return null;
    }
  }

  getRefreshToken() {
    if (this.cookieService.get(this.REFRESH_TOKEN) && this.cookieService.get(this.REFRESH_TOKEN) !== '') {
      return this.cookieService.get(this.REFRESH_TOKEN);
    } else {
      return null;
    }
  }

  /**
   * @author Saher Shaukat
   * @description sets user's workspace
   * @param {string} workspace user's current workspace
   */
  setWorkspace(workspace) {
    // Set accountSlug in storage
    this.cookieService.set(this.WORKSPACE_KEY, workspace, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
    // this.cookieService.set(this.WORKSPACE_KEY, workspace, 3, '/', environment.COOKIE_DOMAIN, true, 'Lax');
  }

  // Set FLEX accountSlug in cookies
  async setFlexSlug(flexAccountSlug, updateToken) {
    if (updateToken) {
      const isEshopOrOwner = this.isEshopOrOwner();
      if (isEshopOrOwner) await this.checkSessionEshopUser('', flexAccountSlug);
      sessionStorage.setItem(this.WAREHOUSE_KEY, flexAccountSlug);
    } else sessionStorage.setItem(this.WAREHOUSE_KEY, flexAccountSlug);
  }

  setRefreshToken(refreshToken) {
    // Set accountSlug in storage
    this.cookieService.set(this.REFRESH_TOKEN, refreshToken, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
    // this.cookieService.set(this.WORKSPACE_KEY, workspace, 3, '/', environment.COOKIE_DOMAIN, true, 'Lax');
  }
  setWorkspaceName(workspaceName) {
    this.cookieService.set(this.WORKSPACE_NAME, workspaceName, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }
  setNumberOfWorkspace(length) {
    // Set no. of accounts in storage
    this.cookieService.set(this.WORKSPACE_LENGTH, length, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
    // this.cookieService.set(this.WORKSPACE_LENGTH, length, 3, '/', environment.COOKIE_DOMAIN, true, 'Lax');
  }

  setReturnUrl(url) {
    // Set return url in storage to redirect to after login
    this.cookieService.set(this.REDIRECT_URL_KEY, url, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
    // this.cookieService.set(this.REDIRECT_URL_KEY, url, 3, '/', environment.COOKIE_DOMAIN, true, 'Lax');
  }

  setRiskscore(flag) {
    this.cookieService.set(this.RISKSCORE_FLAG, flag, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }

  getRiskscore() {
    if (this.cookieService.get(this.RISKSCORE_FLAG) && this.cookieService.get(this.RISKSCORE_FLAG) !== '') {
      return this.cookieService.get(this.RISKSCORE_FLAG);
    } else {
      return null;
    }
  }

  setRiskscoreHours(flag) {
    this.cookieService.set(this.RISKSCORE_HOURS, flag, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }

  getRiskscoreHours() {
    if (this.cookieService.get(this.RISKSCORE_HOURS) && this.cookieService.get(this.RISKSCORE_HOURS) !== '') {
      return this.cookieService.get(this.RISKSCORE_HOURS);
    } else {
      return null;
    }
  }

  /**
   * @author Saher Shaukat
   * @description returns user's workspace count
   * @return {number} null if no of workspaces not present
   */
  getNumberOfWorkspaces() {
    if (this.cookieService.get(this.WORKSPACE_LENGTH) && this.cookieService.get(this.WORKSPACE_LENGTH) !== '') {
      return +this.cookieService.get(this.WORKSPACE_LENGTH);
    } else {
      return null;
    }
  }

  // setListOfAccounts(accountList) {
  //   // Set list of accounts in storage
  //   this.cookieService.set(
  //     this.WORKSPACE_LIST_KEY,
  //     JSON.stringify(accountList),
  //     3,
  //     '/',
  //     environment.COOKIE_DOMAIN,
  //     false,
  //     'Lax'
  //   );

  //   // this.cookieService.set(this.WORKSPACE_LIST_KEY, accountList, 3, '/', environment.COOKIE_DOMAIN, true, 'Lax');
  // }

  /**
   * @author Saher Shaukat
   * @description returns user's workspace count
   * @return {number} null if no of workspaces not present
   */
  getReturnUrl() {
    if (this.cookieService.get(this.REDIRECT_URL_KEY) && this.cookieService.get(this.REDIRECT_URL_KEY) !== '') {
      return this.cookieService.get(this.REDIRECT_URL_KEY);
    } else {
      return null;
    }
  }

  /**
   * @author Saher Shaukat
   * @description returns user's workspace count
   * @return {number} null if no of workspaces not present
   */
  // getListOfAccounts() {
  //   if (this.cookieService.get(this.WORKSPACE_LIST_KEY) && this.cookieService.get(this.WORKSPACE_LIST_KEY) !== '') {
  //     return JSON.parse(this.cookieService.get(this.WORKSPACE_LIST_KEY));
  //   } else {
  //     return null;
  //   }
  // }

  deleteItemFromCookie(key) {
    this.cookieService.delete(key, '/', environment.COOKIE_DOMAIN);
  }

  /**
   * @author Saher Shaukat
   * @description returns user's current workspace
   * @return {string} null if workspace not present
   */
  getAccountSlug() {
    if (this.cookieService.get(this.WORKSPACE_KEY) && this.cookieService.get(this.WORKSPACE_KEY) !== '') {
      return this.cookieService.get(this.WORKSPACE_KEY);
    } else {
      return null;
    }
  }

  getFlexSlug() {
    const accountSlugFrmParam = this.route.snapshot.queryParams['location'];
    const accountSlugFrmSession = sessionStorage.getItem(this.WAREHOUSE_KEY);

    if (accountSlugFrmParam) {
      return accountSlugFrmParam;
    } else if (accountSlugFrmSession) {
      return accountSlugFrmSession;
    } else {
      this.store.pipe(select(getAccountList)).subscribe(response => {
        if (response?.accountListData) {
          const accountSlug = this.getAccountSlug();
          const accountList = response.accountListData.data.find(
            acc => acc['userAccountMappingStatus'] !== '2' && acc.parentClientWorkspaceSlug === accountSlug
          );
          if (accountList) {
            const flexAccountSlug = accountList.accountSlug;
            this.setFlexSlug(flexAccountSlug, false);
            return flexAccountSlug;
          } else {
            return null;
          }
        }
      });
    }
  }

  getAccountName() {
    if (this.cookieService.get(this.WORKSPACE_NAME) && this.cookieService.get(this.WORKSPACE_NAME) !== '') {
      return this.cookieService.get(this.WORKSPACE_NAME);
    } else {
      return null;
    }
  }
  /**
   * @author Saher Shaukat
   * @description returns whether user is logged in or not
   * @return {Boolean} true if user is logged in else false
   */
  get isLoggedIn() {
    if (this.getAuthToken() == null) {
      return false;
    }
    return true;
  }

  /**
   * @author Saher Shaukat
   * @description This does a refresh and redirects back to homepage
   */
  logout() {
    this.cookieService.deleteAll('/', environment.COOKIE_DOMAIN);
    sessionStorage.removeItem('flexAccountSlug');
    this.clearLocalStorage();
    !environment.production
      ? this.router.navigate([ROUTES.LOGIN])
      : (window.location.href = 'https://' + environment.DOMAIN);
  }

  clearLocalData() {
    // const returnUrl = this.getReturnUrl();
    // this.cookieService.deleteAll('/', environment.COOKIE_DOMAIN);
    this.cookieService.delete(this.ACCESS_TOKEN);
    this.cookieService.delete(this.WORKSPACE_KEY);
    this.cookieService.delete(this.WORKSPACE_LENGTH);
    // this.cookieService.delete(this.WORKSPACE_LIST_KEY);
    this.cookieService.delete(this.REFRESH_TOKEN);
    this.clearLocalStorage();
    // if (returnUrl) {
    //   this.setReturnUrl(returnUrl);
    // }
  }
  clearLocalStorage() {
    const cannyRedirect = localStorage.getItem('cannyRedirect');
    const cannyChangelogSeen = localStorage.getItem('canny-changelog-seen');
    const cannyChangelogRead = localStorage.getItem('canny-changelog-read');
    localStorage.clear();
    localStorage.setItem('canny-changelog-seen', cannyChangelogSeen);
    localStorage.setItem('canny-changelog-read', cannyChangelogRead);
    if (cannyRedirect === 'true') {
      localStorage.setItem('cannyRedirect', cannyRedirect);
    }
  }

  switchWorkspace() {
    this.store.dispatch({ type: FcActionTypes.RESET_STATE });
    this.store.dispatch({ type: ChannelsActionTypes.RESET_STATE });
    if (this.getNumberOfWorkspaces() && this.getNumberOfWorkspaces() > 1) {
      !environment.production
        ? this.router.navigate([ROUTES.ON_SIGNIN_COMPLETE])
        : (window.location.href = 'https://' + environment.DOMAIN + ROUTES.ON_SIGNIN_COMPLETE);
    }
  }

  redirectToCreateAccount() {
    this.store.dispatch({ type: FcActionTypes.RESET_STATE });
    this.store.dispatch({ type: ChannelsActionTypes.RESET_STATE });
    // if (this.getNumberOfWorkspaces() && this.getNumberOfWorkspaces() > 1) {
    !environment.production
      ? this.router.navigate([ROUTES.CREATE_ACCOUNT], { queryParams: { fromLogin: true } })
      : (window.location.href = 'https://' + environment.DOMAIN + ROUTES.CREATE_ACCOUNT + '?fromLogin=true');
    // localStorage.setItem('fromLogin', 'true');
    // }
  }

  inviteTeamMembers(body) {
    return this.api.post('v1/user-account-mapping', body);
  }

  resendOtp(email) {
    const url = `${this.API_URL.CONFIG_URL.SIGNUP}?type=resend`;
    return this.api.post(url, email);
  }

  // Shopify complete connection
  shopifyCompleteConnection(body: any) {
    const headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.getAuthToken()}`)
    };
    return this.http.post(
      'https://' + body.accountSlug + environment.BASE_DOMAIN + '/api/v1/connection/complete',
      body,
      headers
    );
  }

  // Get decoded value of query param encoded (refDetails)
  getDecodedValue(decodeUrl, value) {
    let decodedvalue =
      decodeUrl.search(value) >= 0
        ? decodeUrl
            .split(value + '=')
            .pop()
            .split('&')[0]
        : '';
    return decodedvalue;
  }

  // Redirect shopify store url to shopify /installed-app-list/detail page
  redirectShopifyUser(accountSlug, installationId, queryParams) {
    this.setWorkspace(accountSlug);
    const accountlist = this.accountList.filter(x => x.parentClientWorkspaceSlug == accountSlug);
    if (accountlist.length > 0) this.setFlexSlug(accountlist[0].accountSlug, false);
    window.location.href =
      'https://' +
      accountSlug +
      environment.BASE_DOMAIN +
      '/installed-app-list/detail/' +
      installationId +
      `?${queryParams}`;
  }

  getSubscriptionPlanDetails(subscriptionPlanId) {
    return this.api.get(`${this.PLATFORM_API_URL}/plan_detail?itemPriceId=${subscriptionPlanId}`);
  }

  getPlans() {
    let url = `https://${this.getAccountSlug()}${environment.BASE_DOMAIN}/${
      this.API_URL.CONFIG_URL.SUBSCRIPTIONS_BASE_URL
    }/plansDetails`;
    return this.api.get(url);
  }

  getEstimate(estimationPayload) {
    let url = `https://${this.getAccountSlug()}${environment.BASE_DOMAIN}/${
      this.API_URL.CONFIG_URL.SUBSCRIPTIONS_BASE_URL
    }/compute`;
    return this.api.post(url, estimationPayload);
  }

  toHostedSubscription(hostedSubscriptionPayload) {
    let url = `https://${this.getAccountSlug()}${environment.BASE_DOMAIN}/${
      this.API_URL.CONFIG_URL.SUBSCRIPTIONS_BASE_URL
    }/hostedSubscription`;
    return this.api.post(url, hostedSubscriptionPayload);
  }

  getExistingSubscriptions() {
    let url = `https://${this.getAccountSlug()}${environment.BASE_DOMAIN}/${
      this.API_URL.CONFIG_URL.SUBSCRIPTIONS_BASE_URL
    }/subscription`;
    return this.api.get(url);
  }

  getCustomer() {
    let url = `https://${this.getAccountSlug()}${environment.BASE_DOMAIN}/${
      this.API_URL.CONFIG_URL.SUBSCRIPTIONS_BASE_URL
    }/customer`;
    return this.api.get(url);
  }

  updateCustomer(customerDetails) {
    let url = `https://${this.getAccountSlug()}${environment.BASE_DOMAIN}/${
      this.API_URL.CONFIG_URL.SUBSCRIPTIONS_BASE_URL
    }/customers`;
    return this.api.put(url, customerDetails);
  }

  makePayment(requestBody) {
    return this.api.post(`${this.PLATFORM_API_URL}/workspace-payments`, requestBody);
  }

  getStateData() {
    return this.api.get('v1/state');
  }

  getCityData() {
    return this.api.get('v1/city');
  }

  authorizePayment(requestParam) {
    return this.api.get(`${this.PLATFORM_API_URL}/workspace-payment/orderId/${requestParam}`);
  }

  updateProfileDetails(requestData) {
    return this.api.put('v1/user/profile', requestData);
  }
  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }
  // batch setting connection
  batchsetting(payload) {
    // const url = 'https://eshopbox-portal-dev.el.r.appspot.com/_ah/api/esb/v1/batch/account';
    // return this.http.put(url, payload);

    const headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('proxyhost', `${this.getAccountSlug()}`)
        .set('Authorization', `Bearer ${this.getAuthToken()}`)
    };
    return this.http.put(`${environment.batchsettingAPI}`, payload, headers);
    // return this.http.put('https://eshopbox-portal-dev.el.r.appspot.com/_ah/api/esb/v1/batch/account', payload, headers);
  }

  // GET LIST OF ACCOUNTS
  async getAccountLists(): Promise<any> {
    return new Promise(resolve => {
      this.store.pipe(select(getAccountList)).subscribe(response => {
        if (response?.accountListData) {
          const accountList = response.accountListData.data.filter(user => user['userAccountMappingStatus'] !== '2');
          resolve(accountList);
        } else {
          if (!response || !response.accountListLoading) {
            this.store.dispatch(new GetAccountList());
          }
        }
      });
    });
  }

  isMobileDevice() {
    if (this.platform.isBrowser) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|opera mini|iemobile|wpdesktop/i.test(userAgent);
      // const isTablet = /ipad|android|tablet|playbook|silk/i.test(userAgent);
      return isMobile;
    } else {
      const innerWidth = window.innerWidth;
      const mobileWidth: number = 500;
      return innerWidth <= mobileWidth ? true : false;
    }
  }
  // Risk Score setting connection
  riskScoresetting(payload) {
    const headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.getAuthToken()}`)
    };
    return this.api.put('v1/batch/account', payload, headers);
  }

  // getAccountList() {
  //   if (this.cookieService.get(this.ACCOUNT_LIST) && this.cookieService.get(this.ACCOUNT_LIST) !== '') {
  //     return this.cookieService.get(this.ACCOUNT_LIST);
  //   } else {
  //     return null;
  //   }
  // }

  // setAccountList(accountList) {
  //   this.cookieService.set(this.ACCOUNT_LIST, accountList, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  // }

  isFlex() {
    const accountSlug = this.getAccountSlug();
    const accountList: any = this.getAccountLists();
    if (accountList) {
      return accountList.includes(accountSlug);
    } else return false;
  }

  //Canny Redirection
  getSsoToken() {
    const headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.getAuthToken()}`)
    };
    const url = 'https://' + environment.DOMAIN + '/' + this.PLATFORM_API_URL + '/canny/user/info';
    return this.http.get(url, headers);
  }
  redirectToCanny(ssoToken) {
    return (
      'https://canny.io/api/redirects/sso?companyID=' +
      this.companyID +
      '&ssoToken=' +
      ssoToken +
      '&redirect=' +
      this.redirect
    );
  }
  redirectToCannyInternally(type: string) {
    if (type !== 'workspace') {
      this.getSsoToken().subscribe((res: any) => {
        if (res && res.jwtToken) {
          let jwt: any = res;
          if (this.isLoggedIn) {
            var redirectURL = this.redirectToCanny(jwt.jwtToken);
            localStorage.removeItem('cannyRedirect');
            window.location.href = redirectURL;
          }
        }
      });
    } else {
      this.getSsoToken().subscribe((res: any) => {
        if (res && res.jwtToken) {
          let jwt: any = res;
          var redirectURL = `https://canny.io/api/redirects/sso?companyID=${this.companyID}&ssoToken=${jwt.jwtToken}&redirect=${this.redirect}`;
          window.open(redirectURL, '_blank');
        }
      });
    }
  }

  getAccountAndWarehouseList() {
    this.getLoggedInUserData();
    return combineLatest([this.store.select(getAccountList), this.store.select(getFulfillmentCenterData)]).pipe(
      take(1),
      switchMap(([accountList, fulfillmentCenter]) => {
        const accountListData = accountList;
        const fulfillmentCenterData = fulfillmentCenter;

        if (!accountListData?.accountListData?.data && !accountListData.accountListLoading) {
          this.store.dispatch(new GetAccountList());
        }
        if (!fulfillmentCenterData?.fulfillmentCenterData?.data && !fulfillmentCenterData.fulfillmentCenterLoading) {
          this.store.dispatch(new GetFulfillmentCenterData());
        }

        const accountList$ = accountListData?.accountListData?.data
          ? of(accountListData?.accountListData?.data)
          : this.store.select(getAccountList);
        const fulfillmentCenterData$ = fulfillmentCenterData?.fulfillmentCenterData?.data
          ? of(fulfillmentCenterData?.fulfillmentCenterData?.data)
          : this.store.select(getFulfillmentCenterData);

        return combineLatest([accountList$, fulfillmentCenterData$]).pipe(
          tap(([accountListData, fulfillmentCenterData]) => {
            if (
              (isArray(accountListData) || accountListData?.accountListLoaded) &&
              (isArray(fulfillmentCenterData) || fulfillmentCenterData?.fulfillmentCenterLoaded)
            ) {
              const accountList = isArray(accountListData) ? accountListData : accountListData?.accountListData?.data;
              const warehouseListRaw = isArray(fulfillmentCenterData)
                ? fulfillmentCenterData
                : fulfillmentCenterData?.fulfillmentCenterData?.data;
              const warehouseList = warehouseListRaw.filter(
                data =>
                  data['enrollmentStatus'] == 'ENROLLED' &&
                  data['facilityStatus'] == '1' &&
                  data['status'] != 2 &&
                  data['warehouseWorkspaceSlug']
              );
              const currentWorkspaceData = accountList.find(acc => acc.accountSlug === this.getAccountSlug());
              if (currentWorkspaceData) {
                if (currentWorkspaceData.isBatching) this.setIsBatching(currentWorkspaceData.isBatching);
                if (currentWorkspaceData.isRiskScoreEnabled) this.setRiskscore(currentWorkspaceData.isRiskScoreEnabled);
                if (currentWorkspaceData.releaseHighRiskOrderAfter)
                  this.setRiskscoreHours(currentWorkspaceData.releaseHighRiskOrderAfter);
              }
              return [accountList, warehouseList];
            }
          })
        );
      }),
      catchError(() => combineLatest([of(null), of(null)]))
    );
  }

  // GET LOGGEDIN USER DATA
  getLoggedInUserData() {
    this.loggedInUserSubscription$ = this.store.pipe(select(getCurrentLoggedInUserData)).subscribe(
      response => {
        if (response?.currentUser && isNaN(response?.currentUser)) {
          // if (this.loggedInUserSubscription$) this.loggedInUserSubscription$.unsubscribe();
          if (response?.currentUser?.role) localStorage.setItem('role', response?.currentUser?.role);
          return true;
        } else {
          if (!response.currentUser && !response.loading && !response.loaded) {
            this.store.dispatch(new GetCurrentLoggedInUserData());
          }
        }
      },
      error => {
        if (error.error) {
          const errorCode = error.error.error ? (error.error.error.message || '').split(':')[0] : '';
          if (!!ERROR_MESSAGES.GET_ACCOUNT_LIST[errorCode]) {
            this.alertService.showError(ERROR_MESSAGES.GET_ACCOUNT_LIST[errorCode]);
          }
        }
        return false;
      }
    );
  }

  async loadInitialModule(isInitial: boolean, isReload?, flexAccountSlug?) {
    const flexSlug = flexAccountSlug ? flexAccountSlug : this.getFlexSlug();
    let redirectUrl = this.getReturnUrl();
    if (redirectUrl && !window.location.host.includes('localhost')) {
      const match = redirectUrl.match(/\.(co|com)(\/.*)/);
      const pathAfterDomain = match[2];
      this.deleteItemFromCookie(this.REDIRECT_URL_KEY);
      const redirectFullUrl = 'https://' + this.getAccountSlug() + environment.BASE_DOMAIN + pathAfterDomain;
      window.location.href = redirectFullUrl;
    }
    if (window.location.host.includes('localhost')) {
      if (window.location.href.includes('location=') || this.isMobileDevice()) {
        this.router.navigate(['/building-blocks/view-record-list'], {
          queryParams: { handleBar: 'shipment', location: flexAccountSlug }
        });
        return;
      } else {
        this.router.navigate(['/dashboard/overview']);
        return;
      }
    }
    const switchLocation = window.location.pathname == '/login-using-desktop/skipPicking' ? true : false;
    if (isInitial && switchLocation && this.isMobileDevice()) {
      this.router.navigate(['/building-blocks/view-record-list'], {
        queryParams: { handleBar: 'picklist', location: flexAccountSlug }
      });
    }
    if (!isInitial && isReload && flexSlug) {
      let search = window.location.search;
      if (search && search.includes('location=')) {
        const pattern = /(\blocation=)([^&]*)/;
        search = search.replace(pattern, '$1' + flexSlug);
      }
      // Remove the 'filter' parameter from the URL
      search = search.replace(/([&?]filter=[^&]*)/g, '');

      const pathFromURL = search ? window.location.pathname + search : window.location.pathname;
      const redirectURL = 'https://' + this.getAccountSlug() + environment.BASE_DOMAIN + pathFromURL;
      window.location.href = redirectURL;
      return;
    }
    this.loggedInUserSubscription$ = this.store.pipe(select(getCurrentLoggedInUserData)).subscribe(response => {
      if (response?.currentUser) {
        const loggedInUser = response.currentUser;
        if (this.loggedInUserSubscription$) {
          this.loggedInUserSubscription$.unsubscribe();
        }
        if (loggedInUser.workspace_permissions['manage:reports'].isEnabled == '1' && !this.isMobileDevice()) {
          const URL: any = this.ROUTESDATA.reports[0].URL;
          if (isInitial) {
            window.location.href = 'https://' + this.getAccountSlug() + environment.BASE_DOMAIN + URL;
          } else {
            this.router.navigate([URL]);
          }
          return;
        }
        const enabledModule = this.findEnabledModule(loggedInUser);
        if (enabledModule) {
          let moduleName: string;
          let routeData: any;
          if (enabledModule.module && !this.isMobileDevice()) {
            moduleName = enabledModule.module;
            routeData = this.findRouteURL(moduleName);
          } else {
            const isMobile = this.isMobileDevice();
            if (isMobile) {
              moduleName = this.ROUTESDATA['orders'][3].scope;
              routeData = this.ROUTESDATA['orders'][3];
            } else {
              moduleName = this.ROUTESDATA['orders'][2].scope;
              routeData = this.ROUTESDATA['orders'][2];
            }
          }
          if (routeData) {
            const queryParams =
              routeData.queryParams && routeData.urlType == 'WORKSPACE'
                ? routeData.queryParams
                : routeData.urlType == 'FLEX'
                ? { handleBar: routeData.URL, location: flexSlug }
                : '';
            const URL = routeData.urlType == 'FLEX' ? '/building-blocks/view-record-list' : routeData.URL;
            if (isInitial) {
              if (queryParams) {
                const params = new URLSearchParams(queryParams);
                const url =
                  'https://' + this.getAccountSlug() + environment.BASE_DOMAIN + URL + '?' + params.toString();
                window.location.href = url;
              } else {
                const url = 'https://' + this.getAccountSlug() + environment.BASE_DOMAIN + URL;
                window.location.href = url;
              }
            } else {
              if (queryParams) this.router.navigate([URL], { queryParams: queryParams });
              else this.router.navigate([URL]);
            }
          } else {
            this.router.navigate(['/access-denied/', moduleName]);
          }
        } else {
          this.router.navigate(['/access-denied/', 'something_went_wrong']);
        }
      } else if (!response.currentUser && !response.loading && !response.loaded) {
        this.store.dispatch(new GetCurrentLoggedInUserData());
      }
    });
  }

  // Helper function to find the first enabled module
  findEnabledModule(data: any): any | null {
    let isEnabled = false;
    for (const key in data['workspace_permissions']) {
      isEnabled = data['workspace_permissions'][key].isEnabled == '1';
      if (isEnabled) return data['workspace_permissions'][key];
    }
    if (!isEnabled) return Object.keys(data.fc_workspace_permissions).length != 0 ? true : false;
  }

  // Helper function to find the route URL based on the module name
  findRouteURL(moduleName: string): string | null {
    for (const key in this.ROUTESDATA) {
      if (Array.isArray(this.ROUTESDATA[key])) {
        const module = this.ROUTESDATA[key].find((entry: any) => entry.scope === moduleName);
        if (module && module.URL) {
          return module;
        }
      }
    }
    return null;
  }

  gotoPicklistBB() {
    this.router.navigate(['/building-blocks/view-record-list'], {
      queryParams: { handleBar: 'picklist', location: this.getFlexSlug() }
    });
  }

  checkauthincookies() {
    if (this.cookieService.get('auth0') && this.cookieService.get('auth0') !== '') {
      return this.cookieService.get('auth0');
    } else {
      return null;
    }
  }

  // Check if user is an eshopbox user
  isEshopUser() {
    const email = localStorage.getItem('email');
    return email ? email.includes('@eshopbox.com') : false;
  }

  // Check if user is an owner
  isOwner() {
    const role = localStorage.getItem('role');
    return role ? role === 'owner' : false;
  }

  // Check if user is either an eshopbox user or an owner
  isEshopOrOwner() {
    return this.isEshopUser() || this.isOwner();
  }
}
