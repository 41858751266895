import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent implements OnInit {
  @Input() learnMore: any;

  constructor() {}

  ngOnInit(): void {}
}
