import * as consignmentActions from '../actions/consignment.actions';
import { ConsignmentState } from '../consignment.state';

export const initialState: ConsignmentState = {
  consignmentlistloaded: false,
  consignmentlistloading: false,
  consignmentlistfailed: false,
  consignmentlist: [],
  consignmentdetailsloaded: false,
  consignmentdetailsloading: false,
  consignmentdetail: null,
  grn: null,
  grnloading: false,
  grnloaded: false,
  wl: null,
  pl: null,
  wlloaded: false,
  wlloading: false,
  plloaded: false,
  plloading: false,
  created: false,
  creating: false,
  createdconsignment: null,
  scheduled: false,
  scheduling: false,
  scheduledconsignment: null,
  cancelled: false,
  cancelling: false,
  cancelfailure: false,
  timeslotsloading: false,
  timeslotsloaded: true,
  timeslots: null,
  creategrnreport: null,
  creatinggrnreport: false,
  createdgrnreport: false,
  getgrnreport: null,
  loadinggrnreport: false,
  loadedgrnreport: false,
  loadingWarehouseList: false,
  loadedWarehouseList: false,
  failedWarehouseList: false,
  warehouseList: [],

  // all warehouses
  allWarehouseList: [],
  warehousesLoaded: false,
  warehousesLoading: false,

  // Create recall consignment
  createRecallConsignment: null,
  createRecallConsignmentFailure: null,
  creatingRecallConsignment: false,
  createdRecallConsignment: false,

  // Get recall consignment
  recallConsignment: null,
  recallConsignmentFailure: null,
  loadingRecallConsignment: false,
  loadedRecallConsignment: false,

  // Get recall consignment
  recallTimeslots: null,
  recallTimeslotsFailure: null,
  recallTimeslotsLoading: false,
  recallTimeslotsLoaded: false,

  // Recall GRN
  recallGRN: null,
  recallGRNFailure: null,
  recallGRNLoading: false,
  recallGRNLoaded: false,

  // Create recall consignment export job
  createRecallConsignmentExportJob: null,
  createRecallConsignmentExportJobError: null,
  creatingRecallConsignmentExportJob: false,
  createdRecallConsignmentExportJob: false
};

export function ConsignmentReducer(
  state = initialState,
  action: consignmentActions.ConsignmentActionsUnion
): ConsignmentState {
  switch (action.type) {
    case consignmentActions.ConsignmentActionTypes.GET_CONSIGNMENT_DETAILS: {
      return {
        ...state,
        consignmentdetailsloaded: false,
        consignmentdetailsloading: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_CONSIGNMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        consignmentdetailsloaded: true,
        consignmentdetailsloading: false,
        consignmentdetail: action.payload['data'][0]
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_CONSIGNMENT_DETAILS_FAILURE: {
      return {
        ...state,
        consignmentdetailsloaded: false,
        consignmentdetailsloading: false,
        consignmentdetail: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_GRN_DETAILS: {
      return {
        ...state,
        grnloaded: false,
        grnloading: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_GRN_DETAILS_SUCCESS: {
      return {
        ...state,
        grnloaded: true,
        grnloading: false,
        grn: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_GRN_DETAILS_FAILURE: {
      return {
        ...state,
        grnloaded: false,
        grnloading: false,
        grn: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.FETCH_PARTYLIST: {
      return {
        ...state,
        plloaded: false,
        plloading: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.FETCH_PARTYLIST_SUCCESS: {
      return {
        ...state,
        plloaded: true,
        plloading: false,
        pl: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.FETCH_PARTYLIST_FAILURE: {
      return {
        ...state,
        plloaded: false,
        plloading: false,
        pl: null
      };
    }

    case consignmentActions.ConsignmentActionTypes.FETCH_WAREHOUSE: {
      return {
        ...state,
        wlloaded: false,
        wlloading: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.FETCH_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        wlloaded: true,
        wlloading: false,
        wl: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.FETCH_WAREHOUSE_FAILURE: {
      return {
        ...state,
        wlloaded: false,
        wlloading: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_CONSIGNMENT: {
      return {
        ...state,
        created: false,
        creating: true,
        createdconsignment: null
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_CONSIGNMENT_SUCCESS: {
      return {
        ...state,
        created: true,
        creating: false,
        createdconsignment: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_CONSIGNMENT_FAILURE: {
      return {
        ...state,
        created: false,
        creating: false,
        createdconsignment: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.SCHEDULE_CONSIGNMENT: {
      return {
        ...state,
        scheduled: false,
        scheduling: true,
        createdconsignment: null
      };
    }

    case consignmentActions.ConsignmentActionTypes.SCHEDULE_CONSIGNMENT_SUCCESS: {
      return {
        ...state,
        scheduled: true,
        scheduling: false,
        createdconsignment: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.SCHEDULE_CONSIGNMENT_FAILURE: {
      return {
        ...state,
        scheduled: false,
        scheduling: false,
        createdconsignment: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.FILTER_CONSIGNMENTS: {
      return {
        ...state,
        consignmentlistloaded: false,
        consignmentlistloading: true,
        consignmentlistfailed: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.FILTER_CONSIGNMENTS_SUCCESS: {
      return {
        ...state,
        consignmentlistloaded: true,
        consignmentlistloading: false,
        consignmentlist: action.payload,
        consignmentlistfailed: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.FILTER_CONSIGNMENTS_FAILURE: {
      return {
        ...state,
        consignmentlistloaded: false,
        consignmentlistloading: false,
        consignmentlist: action.payload,
        consignmentlistfailed: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.CANCEL_CONSIGNMENT: {
      return {
        ...state,
        cancelling: true,
        cancelled: false,
        cancelfailure: {
          isFailed: false,
          response: false
        }
      };
    }

    case consignmentActions.ConsignmentActionTypes.CANCEL_CONSIGNMENT_SUCCESS: {
      return {
        ...state,
        cancelled: true,
        cancelling: false,
        cancelfailure: {
          isFailed: false,
          response: false
        }
      };
    }

    case consignmentActions.ConsignmentActionTypes.CANCEL_CONSIGNMENT_FAILURE: {
      return {
        ...state,
        cancelled: false,
        cancelling: false,
        cancelfailure: {
          isFailed: true,
          response: action.payload
        }
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_TIMESLOTS: {
      return {
        ...state,
        timeslotsloading: true,
        timeslotsloaded: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_TIMESLOTS_SUCCESS: {
      return {
        ...state,
        timeslotsloading: false,
        timeslotsloaded: true,
        timeslots: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_TIMESLOTS_FAILURE: {
      return {
        ...state,
        timeslotsloading: false,
        timeslotsloaded: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_GRN_REPORT: {
      return {
        ...state,
        creatinggrnreport: true,
        createdgrnreport: false,
        creategrnreport: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_GRN_REPORT_SUCCESS: {
      return {
        ...state,
        creatinggrnreport: false,
        createdgrnreport: true,
        creategrnreport: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_GRN_REPORT_FAILURE: {
      return {
        ...state,
        creatinggrnreport: false,
        creategrnreport: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_GRN_REPORT: {
      return {
        ...state,
        loadinggrnreport: true,
        loadedgrnreport: false,
        getgrnreport: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_GRN_REPORT_SUCCESS: {
      return {
        ...state,
        loadinggrnreport: false,
        loadedgrnreport: true,
        getgrnreport: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_GRN_REPORT_FAILURE: {
      return {
        ...state,
        loadinggrnreport: false,
        getgrnreport: action.payload
      };
    }

    /**
     * ENROLLED Warehouse list
     */
    case consignmentActions.ConsignmentActionTypes.GET_WAREHOUSE_LIST: {
      return {
        ...state,
        loadedWarehouseList: false,
        loadingWarehouseList: true,
        failedWarehouseList: false
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_WAREHOUSE_LIST_FAILURE: {
      return {
        ...state,
        warehouseList: [],
        loadedWarehouseList: false,
        loadingWarehouseList: false,
        failedWarehouseList: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_WAREHOUSE_LIST_SUCCESS: {
      return {
        ...state,
        warehouseList: action.payload,
        loadedWarehouseList: true,
        loadingWarehouseList: false,
        failedWarehouseList: false
      };
    }

    /**
     * GET ALL Warehouse list
     */
    case consignmentActions.ConsignmentActionTypes.GET_ALL_WAREHOUSE: {
      return {
        ...state,
        warehousesLoading: true,
        warehousesLoaded: false
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_ALL_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        allWarehouseList: action.payload,
        warehousesLoading: false,
        warehousesLoaded: true
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_ALL_WAREHOUSE_FAILURE: {
      return {
        ...state,
        allWarehouseList: [],
        warehousesLoading: false,
        warehousesLoaded: false
      };
    }

    /**
     * Create recall consignment
     */
    case consignmentActions.ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT: {
      return {
        ...state,
        createRecallConsignment: null,
        createRecallConsignmentFailure: null,
        creatingRecallConsignment: true,
        createdRecallConsignment: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_SUCCESS: {
      return {
        ...state,
        createRecallConsignment: action.payload,
        createRecallConsignmentFailure: null,
        creatingRecallConsignment: false,
        createdRecallConsignment: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_FAILURE: {
      return {
        ...state,
        createRecallConsignment: null,
        createRecallConsignmentFailure: action.payload,
        creatingRecallConsignment: false,
        createdRecallConsignment: false
      };
    }

    /**
     * Update recall consignment
     */
    case consignmentActions.ConsignmentActionTypes.UPDATE_RECALL_CONSIGNMENT: {
      return {
        ...state,
        createRecallConsignment: null,
        createRecallConsignmentFailure: null,
        creatingRecallConsignment: true,
        createdRecallConsignment: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.UPDATE_RECALL_CONSIGNMENT_SUCCESS: {
      return {
        ...state,
        createRecallConsignment: action.payload,
        createRecallConsignmentFailure: null,
        creatingRecallConsignment: false,
        createdRecallConsignment: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.UPDATE_RECALL_CONSIGNMENT_FAILURE: {
      return {
        ...state,
        createRecallConsignment: null,
        createRecallConsignmentFailure: action.payload,
        creatingRecallConsignment: false,
        createdRecallConsignment: false
      };
    }

    /**
     * Get recall consignment
     */
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_DETAILS: {
      return {
        ...state,
        recallConsignment: null,
        recallConsignmentFailure: null,
        loadingRecallConsignment: true,
        loadedRecallConsignment: false
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        recallConsignment: action.payload,
        recallConsignmentFailure: null,
        loadingRecallConsignment: false,
        loadedRecallConsignment: true
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_DETAILS_FAILURE: {
      return {
        ...state,
        recallConsignment: null,
        recallConsignmentFailure: action.payload,
        loadingRecallConsignment: false,
        loadedRecallConsignment: false
      };
    }

    /**
     * Get recall consignment list
     */
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_LIST: {
      return {
        ...state,
        recallConsignment: null,
        recallConsignmentFailure: null,
        loadingRecallConsignment: true,
        loadedRecallConsignment: false
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_LIST_SUCCESS: {
      return {
        ...state,
        recallConsignment: action.payload,
        recallConsignmentFailure: null,
        loadingRecallConsignment: false,
        loadedRecallConsignment: true
      };
    }
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_LIST_FAILURE: {
      return {
        ...state,
        recallConsignment: null,
        recallConsignmentFailure: action.payload,
        loadingRecallConsignment: false,
        loadedRecallConsignment: false
      };
    }

    /**
     * Get Recall Timeslots
     */
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_TIMESLOTS: {
      return {
        ...state,
        recallTimeslotsLoading: true,
        recallTimeslotsLoaded: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_RECALL_TIMESLOTS_SUCCESS: {
      return {
        ...state,
        recallTimeslotsLoading: false,
        recallTimeslotsLoaded: true,
        recallTimeslots: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_RECALL_TIMESLOTS_FAILURE: {
      return {
        ...state,
        recallTimeslotsLoading: false,
        recallTimeslotsLoaded: false
      };
    }

    // RECALL GRN REPORT
    case consignmentActions.ConsignmentActionTypes.GET_RECALL_GRN_REPORT: {
      return {
        ...state,
        recallGRNLoading: true,
        recallGRNLoaded: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_RECALL_GRN_REPORT_SUCCESS: {
      return {
        ...state,
        recallGRNLoading: false,
        recallGRNLoaded: true,
        recallGRN: action.payload
      };
    }

    case consignmentActions.ConsignmentActionTypes.GET_RECALL_GRN_REPORT_FAILURE: {
      return {
        ...state,
        recallGRNLoading: false,
        recallGRNLoaded: true,
        recallGRNFailure: action.payload
      };
    }

    // RECALL Create Export Job
    case consignmentActions.ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_EXPORT_JOB: {
      return {
        ...state,
        createRecallConsignmentExportJob: null,
        createRecallConsignmentExportJobError: null,
        creatingRecallConsignmentExportJob: true,
        createdRecallConsignmentExportJob: false
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_EXPORT_JOB_SUCCESS: {
      return {
        ...state,
        createRecallConsignmentExportJob: action.payload,
        createRecallConsignmentExportJobError: null,
        creatingRecallConsignmentExportJob: false,
        createdRecallConsignmentExportJob: true
      };
    }

    case consignmentActions.ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_EXPORT_JOB_FAILURE: {
      return {
        ...state,
        createRecallConsignmentExportJob: null,
        createRecallConsignmentExportJobError: action.payload,
        creatingRecallConsignmentExportJob: false,
        createdRecallConsignmentExportJob: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getallconsignments = (state: ConsignmentState) => state.consignmentlist;
export const getconloading = (state: ConsignmentState) => state.consignmentlistloading;
export const getconloaded = (state: ConsignmentState) => state.consignmentlistloaded;
export const getconfail = (state: ConsignmentState) => state.consignmentlistfailed;

export const getdetails = (state: ConsignmentState) => state.consignmentdetail;
export const getdetailsloading = (state: ConsignmentState) => state.consignmentdetailsloading;
export const getdetailsloaded = (state: ConsignmentState) => state.consignmentdetailsloaded;

export const getgrn = (state: ConsignmentState) => state.grn;
export const getgrnloading = (state: ConsignmentState) => state.grnloading;
export const getgrnloaded = (state: ConsignmentState) => state.grnloaded;

export const getpl = (state: ConsignmentState) => state.pl;
export const getplloading = (state: ConsignmentState) => state.plloading;
export const getplloaded = (state: ConsignmentState) => state.plloaded;

//this is billing party below:
export const getwl = (state: ConsignmentState) => state.wl;
export const getwlloading = (state: ConsignmentState) => state.wlloading;
export const getwlloaded = (state: ConsignmentState) => state.wlloaded;

export const created = (state: ConsignmentState) => state.created;
export const creating = (state: ConsignmentState) => state.creating;
export const createdconsignment = (state: ConsignmentState) => state.createdconsignment;

export const scheduled = (state: ConsignmentState) => state.scheduled;
export const scheduling = (state: ConsignmentState) => state.scheduling;
export const scheduledconsignment = (state: ConsignmentState) => state.scheduledconsignment;

export const cancelled = (state: ConsignmentState) => state.cancelled;
export const cancelling = (state: ConsignmentState) => state.cancelling;
export const cancelFailure = (state: ConsignmentState) => state.cancelfailure;

export const timeslot = (state: ConsignmentState) => state.timeslots;
export const timeslotloading = (state: ConsignmentState) => state.timeslotsloading;
export const timeslotloaded = (state: ConsignmentState) => state.timeslotsloaded;

export const creategrnreport = (state: ConsignmentState) => state.creategrnreport;
export const creatinggrnreport = (state: ConsignmentState) => state.creatinggrnreport;
export const createdgrnreport = (state: ConsignmentState) => state.createdgrnreport;

export const getgrnreport = (state: ConsignmentState) => state.getgrnreport;
export const loadinggrnreport = (state: ConsignmentState) => state.loadinggrnreport;
export const loadedgrnreport = (state: ConsignmentState) => state.loadedgrnreport;

// export const wh = (state: ConsignmentState) => state.wh;
// export const whing = (state: ConsignmentState) => state.whing;
// export const whed = (state: ConsignmentState) => state.whed;

// For ENROLLED Warehouses
export const loadedWarehouseList = (state: ConsignmentState) => state.loadedWarehouseList;
export const loadingWarehouseList = (state: ConsignmentState) => state.loadingWarehouseList;
export const failedWarehouseList = (state: ConsignmentState) => state.failedWarehouseList;
export const warehouseList = (state: ConsignmentState) => state.warehouseList;

// For ALL Warehouses
export const getWarehousesLoaded = (state: ConsignmentState) => state.warehousesLoaded;
export const getWarehousesLoading = (state: ConsignmentState) => state.warehousesLoading;
export const getWarehouses = (state: ConsignmentState) => state.allWarehouseList;

/**
 * Create Recall Consignment
 *
 * @param state
 */
export const createRecallConsignment = (state: ConsignmentState) => state.createRecallConsignment;
export const createRecallConsignmentFailure = (state: ConsignmentState) => state.createRecallConsignmentFailure;
export const creatingRecallConsignment = (state: ConsignmentState) => state.creatingRecallConsignment;
export const createdRecallConsignment = (state: ConsignmentState) => state.createdRecallConsignment;

/**
 * Get Recall Consignment
 *
 * @param state
 */
export const recallConsignment = (state: ConsignmentState) => state.recallConsignment;
export const recallConsignmentFailure = (state: ConsignmentState) => state.recallConsignmentFailure;
export const loadingRecallConsignment = (state: ConsignmentState) => state.loadingRecallConsignment;
export const loadedRecallConsignment = (state: ConsignmentState) => state.loadedRecallConsignment;

/**
 * Get Recall Consignment Timeslots
 *
 * @param state
 */
export const recallTimeSlots = (state: ConsignmentState) => state.recallTimeslots;
export const recallTimeslotsFailure = (state: ConsignmentState) => state.recallTimeslotsFailure;
export const recallTimeslotsLoading = (state: ConsignmentState) => state.recallTimeslotsLoading;
export const recallTimeslotsLoaded = (state: ConsignmentState) => state.recallTimeslotsLoaded;

/**
 * Get Recall Consignment GRN Report
 *
 * @param state
 */
export const recallGRN = (state: ConsignmentState) => state.recallGRN;
export const recallGRNFailure = (state: ConsignmentState) => state.recallGRNFailure;
export const recallGRNLoading = (state: ConsignmentState) => state.recallGRNLoading;
export const recallGRNLoaded = (state: ConsignmentState) => state.recallGRNLoaded;

/**
 * Create Recall Consignment Export Job
 *
 * @param state
 */
export const createRecallConsignmentExportJob = (state: ConsignmentState) => state.createRecallConsignmentExportJob;
export const createRecallConsignmentExportJobError = (state: ConsignmentState) =>
  state.createRecallConsignmentExportJobError;
export const creatingRecallConsignmentExportJob = (state: ConsignmentState) => state.creatingRecallConsignmentExportJob;
export const createdRecallConsignmentExportJob = (state: ConsignmentState) => state.createdRecallConsignmentExportJob;
