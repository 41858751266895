import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-info-icon',
  templateUrl: './schedule-info-icon.component.html',
  styleUrls: ['../order-schedule/order-schedule.component.scss']
})
export class ScheduleInfoIconComponent implements OnInit {
  @Input() intervalKey;
  constructor() {}

  ngOnInit(): void {}
}
