<section class="desktop-view-dialog">
  <img
    src="https://ik.imagekit.io/2gwij97w0o/monitor.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1678107280490"
    class="desktop-icon"
  />
  <h2 class="heading">Login using desktop</h2>
  <p class="sub-heading">
    For optimal performance, we recommend <br />
    logging in <strong>via desktop</strong>
    <ng-container *ngIf="type === 'skipPicking' && accountList?.length > 1">
      or <strong> switch location</strong>
    </ng-container>
  </p>

  <div *ngIf="type === 'skipPicking' && accountList?.length > 1" class="choose-workspace-2 nb-choose-workspace">
    <div class="left-workspace">
      <a class="eshop-logo"
        ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg" alt=""
      /></a>
      <div class="workspace">
        <div class="select-fullfilment">
          <ul class="display-on-mob mb-choose-center-swich-location">
            <li>
              <ng-container *ngIf="accountList?.length > 1">
                <a
                  (click)="showDropdown = !showDropdown"
                  class="select-item"
                  [ngClass]="showDropdown ? 'rotate-icon' : ''"
                >
                  <span>
                    {{ currentFacilityLabel }}
                  </span>
                </a>
                <div
                  (click)="showDropdown = !showDropdown"
                  class="searchoverlay mb-searchoverlay"
                  [ngClass]="showDropdown ? 'visible' : ''"
                ></div>
                <ul [class.show-dropdown]="showDropdown">
                  <div class="header-switch">
                    <h2>Switch location</h2>
                    <button class="close-switch" (click)="showDropdown = !showDropdown"></button>
                  </div>
                  <div class="search-location-swich-location">
                    <input
                      type="text"
                      [(ngModel)]="searchFc"
                      name="searchLocation"
                      placeholder="Search location"
                      class="switch-location-input"
                      *ngIf="accountList.length > 3"
                    />
                  </div>
                  <div class="search-account-list">
                    <ng-container *ngFor="let w of accountList | searchFilter: searchFc:'searchFC'">
                      <li
                        (click)="selectedFC(w.accountSlug)"
                        [class.active]="w?.facilityLabel === currentFacilityLabel"
                      >
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg"
                          alt=""
                        />
                        <p>
                          {{ w?.facilityLabel }}
                        </p>
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/red-tick-icon.svg"
                          class="mb-tick-icon"
                          alt=""
                        />
                      </li>
                    </ng-container>
                  </div>
                </ul>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="multiple-button-container">
    <button
      *ngIf="numberOfWorkspace && numberOfWorkspace > 1"
      (click)="switchWorkspace()"
      class="action-button switch-workspace-button"
    >
      Switch workspace
    </button>
    <button class="action-button" (click)="hasFlex ? goBack() : logOut()">
      {{ hasFlex ? 'Go back' : 'Logout' }}
    </button>
  </div>
</section>
