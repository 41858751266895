import { Component, Output, EventEmitter, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-date-range-filter',
  templateUrl: './custom-date-range-filter.component.html',
  styleUrls: ['./custom-date-range-filter.component.scss']
})
export class CustomDateRangeFilterComponent implements OnInit {
  @Input() selectedFilter;
  @Input() isRequiredFutureDate;
  selectedStartDate: any;
  selectedEndDate: any;
  maxDate: any = new Date();
  minDate: any = new Date(
    moment()
      .subtract(180, 'days')
      .startOf('day')
      .valueOf()
  );
  @Output() getCustomDates = new EventEmitter();
  notValidStartDate: Boolean = false;
  notValid: Boolean = false;
  currentRoute: string;
  @ViewChild('hideDiv', { read: ElementRef, static: true }) hideDiv: ElementRef;

  constructor(private el: ElementRef, private router: Router) {}

  ngOnChanges() {
    this.selectedStartDate = '';
    this.selectedEndDate = '';
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    if (this.isRequiredFutureDate) {
      this.maxDate = new Date(
        moment()
          .add(14, 'days')
          .startOf('day')
          .valueOf()
      );
    }
  }

  /**@description- Method Called on Selection of From Date*/
  public onSelectFromDate(event) {
    this.selectedStartDate = event;
    if (this.selectedEndDate) this.checkDates();
  }

  /**@description - Method Called on Selection of To Date*/
  public onSelectToDate(event) {
    this.selectedEndDate = event;
    this.checkDates();
  }

  /**@description - Method Called on Selection of To Date To Validate Dates*/
  public checkDates() {
    this.notValid = false;
    this.notValidStartDate = false;
    const diffTime = Math.abs(this.selectedEndDate - this.selectedStartDate);
    if (this.selectedStartDate > this.selectedEndDate) this.notValidStartDate = true;
    else if (Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 180) this.notValid = true;
  }

  /**@description - Method Called on Apply*/
  public onSubmitDates() {
    if (this.selectedStartDate && this.selectedEndDate) {
      this.hideDivOnButtonClick();
      this.getCustomDates.emit({
        fromDate: this.selectedStartDate,
        toDate: this.selectedEndDate
      });
    }
    // this.getRefrence.close();
  }

  /**@description - Method To Hide Component*/
  public hideDivOnButtonClick() {
    this.hideDiv.nativeElement.classList.add('hide-component');
    setTimeout(() => {
      this.hideDiv.nativeElement.classList.remove('hide-component');
    }, 1000);
  }
}
