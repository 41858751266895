import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-filtered-chips',
  templateUrl: './filtered-chips.component.html',
  styleUrls: ['./filtered-chips.component.scss']
})
export class FilteredChipsComponent implements OnChanges {
  @Output() removeFilter = new EventEmitter<any>();
  @Input() formFilters: any;

  ngOnChanges() {
    console.log(this.formFilters);
  }

  removeFilterAt(i, obj) {
    this.removeFilter.emit({ index: i, obj });
  }
}
