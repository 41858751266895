import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder: any = 'Search Product';
  @Input() filterList = [];
  @Input() filterData: any;
  @Input() fieldList: any[];
  @Input() filtersFor: '';
  @Input() singleSelectObjectArray: Array<any> = [];
  @Input() multiSelectObjectArray: Array<any> = [];
  @Input() filterName;
  private _removeFilterAt = new BehaviorSubject<any>({});

  @Input()
  set removeFilterAt(value) {
    this._removeFilterAt.next(value);
  }
  get removeFilterAt() {
    return this._removeFilterAt.getValue();
  }
  @Input() showFilter = true;
  @Output() emitSearchKey = new EventEmitter<any>();
  @Output() emitFilterForm = new EventEmitter<any>();
  @Output() emitFilterFormObj = new EventEmitter<any>();
  @Output() emitFilters = new EventEmitter<any>();

  filterMenu: boolean = false;
  query: string = null;
  filterForm: FormGroup;
  focusInput: boolean = false;
  selectedFilterKey: string = '';

  filterObj: any = {};
  chkIdx: any;
  searchSelectBox = new FormControl();
  private _onDestroy = new Subject<void>();
  public filteredKeyList: ReplaySubject<any> = new ReplaySubject<any>(1);
  refNestedSelect = new FormControl();

  @ViewChild('selectedOption', { static: false }) selectedOption: MatSelect;

  get formControl() {
    return this.filterForm.get('filters') as FormArray;
  }

  showFiltersForReferenceFields: boolean = true;
  selectedFieldType:
    | 'SHORT_TEXT'
    | 'LONG_TEXT'
    | 'BOOLEAN'
    | 'NUMBER'
    | 'DATE_PICKER'
    | 'ATTACHMENT'
    | 'REFERENCE'
    | 'SINGLE_SELECT'
    | 'MULTI_SELECT' = null;
  filterQuery: any = {};
  fieldTypeMap: string[] = [];
  referenceFieldKeys: Array<string> = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      filters: this.fb.array([])
    });
    let filtersFormArray = this.filterForm.controls.filters as FormArray;

    if (this.filterData) {
      Object.keys(this.filterData).forEach(condition => {
        Object.keys(this.filterData[condition]).forEach(field => {
          if (condition !== 'is_between') {
            for (let i = 0; i < this.filterData[condition][field].length; i++) {
              filtersFormArray.push(
                this.createFilters(field, condition, this.filterData[condition][field][i], null, null)
              );
              let fieldObj = this.fieldList.filter(x => x.handleBar === field);
              this.fieldTypeMap.push(fieldObj[0].fieldType);
            }
          } else {
            filtersFormArray.push(
              this.createFilters(
                field,
                condition,
                null,
                this.filterData[condition][field][0],
                this.filterData[condition][field][1]
              )
            );
            let fieldObj = this.fieldList.filter(x => x.handleBar === field);
            this.fieldTypeMap.push(fieldObj[0].fieldType);
          }
        });
      });
      console.log('INIJIJN');
      this.printSelectedFilters();
    } else {
      filtersFormArray.push(this.createFilters(null));
    }
    this.searchSelectBox.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterKeys();
    });
    this.formFilterQuery();
    this._removeFilterAt.subscribe(data => {
      if (data) {
        this.removeItem(data.index);
        this.submitForm(this.filterForm.value);
      }
    });
  }

  ngOnChanges(c) {
    if (this.filterList) {
      this.referenceFieldKeys = Object.keys(this.filterList);
      this.filteredKeyList.next(this.filterList.slice());
    }

    if (this.singleSelectObjectArray) {
    }
  }

  private filterKeys() {
    if (!this.filterList) {
      return;
    }
    let search = this.searchSelectBox.value;
    if (!search) {
      this.filteredKeyList.next(this.filterList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredKeyList.next(this.filterList.filter(obj => obj.displayKey.toLowerCase().indexOf(search) > -1));
  }

  createFilters(field, condition?, value?, from?, to?): FormGroup {
    return this.fb.group({
      field: [field ? field : null, Validators.compose([Validators.required])],
      condition: [condition ? condition : null, Validators.compose([Validators.required])],
      value: [value ? value : null, Validators.compose([])],
      from: [from ? from : null, Validators.compose([])],
      to: [to ? to : null, Validators.compose([])]
    });
  }

  addMoreItem() {
    this.formControl.push(this.createFilters(null));
  }

  removeItem(idx) {
    this.formControl.removeAt(idx);
    this.fieldTypeMap.splice(idx, 1);
  }

  submitForm(body) {
    console.log(body);
    this.emitFilterFormObj.emit(body.filters);
    const finalStr = this.manipulateData(body.filters);
    this.emitFilterForm.emit(finalStr);
    this.filterMenu = false;
  }

  manipulateData(data) {
    let a: string = '';
    data.forEach(y => {
      a +=
        '(' +
        this.forNotCondition(y['condition'], y['field']['fieldType']) +
        ' ' +
        this.multipleValues(
          y['value'],
          y['field']['fieldType'],
          y['field']['key'],
          y['condition'],
          y['from'],
          y['to']
        ) +
        ')' +
        'AND';
    });
    const idx = a.lastIndexOf('AND');
    return a.slice(0, idx);
  }

  multipleValues(value, type, key, condition, from?, to?) {
    let final: any = '';
    if (
      type.toLowerCase() === 'range' ||
      (condition.toLowerCase() === 'is_between' && type.toLowerCase() !== 'daterange')
    ) {
      final = `${key}${from ? ':' + from : ''}${this.forColonOrOperator(condition, type)}${to}`;
    } else if (type.toLowerCase() === 'daterange') {
      final = `'${key}'${this.forColonOrOperator(condition, type)}${new Date(value).getTime()}`;
    } else if (typeof value === 'string') {
      final = `'${key}'${this.forColonOrOperator(condition, type)}'${value}'`;
    } else {
      let idx: any;
      if (condition == 'is') {
        if (value.length > 0) {
          value.forEach(x => {
            final += `${key}:'${x}' OR `;
          });
        } else {
          final += `${key}:'${value}' OR `;
        }

        idx = final.lastIndexOf('OR');
      } else {
        if (value !== undefined && value.length > 0) {
          value.forEach(x => {
            final += `${key}:'${x}' AND NOT `;
          });
        }
        idx = final.lastIndexOf('AND NOT');
      }
      return final.slice(0, idx);
    }
    return final;
  }

  forNotCondition(condition, type) {
    let final;
    if (
      type.toLowerCase() === 'range' ||
      type.toLowerCase() === 'daterange' ||
      condition.toLowerCase() === 'is_between'
    ) {
      final = '';
    } else {
      if (condition === 'is_not') {
        final = 'NOT';
      } else {
        final = '';
      }
    }
    return final;
  }

  condition(str, type) {
    let final;
    if (type.toLowerCase() === 'range' || type.toLowerCase() === 'daterange') {
      final = this.forIfCondition(str);
    } else {
      final = this.forElseCondition(str);
    }
    this.chkIdx = final;
    return final;
  }

  forIfCondition(str) {
    let forIf;
    switch (str) {
      case 'is':
        forIf = '=';
        break;
      case 'is_not':
        forIf = '!=';
        break;
      case 'is greater than':
        forIf = '>';
        break;
      case 'is less than':
        forIf = '<';
        break;
      case 'is greater than or equal to':
        forIf = '>=';
        break;
      case 'is less than or equal to':
        forIf = '<=';
        break;
      case 'is between':
        forIf = ' TO ';
        break;
      default:
        forIf = ':';
    }
    return forIf;
  }

  forElseCondition(str) {
    let forElse;
    switch (str) {
      case 'is':
        forElse = 'OR';
        break;
      case 'is_not':
        forElse = 'NOT';
        break;
      case 'contains':
        forElse = 'OR';
        break;
      case 'doesnot contains':
        forElse = 'NOT';
        break;
      default:
        forElse = 'OR';
    }
    return forElse;
  }

  forColonOrOperator(condition, type) {
    let operator;
    if (type.toLowerCase() === 'range' || type.toLowerCase() === 'daterange') {
      operator = this.forIfCondition(condition);
    } else {
      operator = ':';
    }
    return operator;
  }

  searchList(key) {
    this.emitSearchKey.emit(key);
  }

  fieldChange(event) {
    this.filterObj[event.key] = event;
    this.selectedFilterKey = event.key;
  }

  valueCheck(event, option, i) {
    this.selectedFieldType = option.fieldType;

    switch (option.fieldType) {
      case 'SHORT_TEXT':
      case 'LONG_TEXT':
      case 'NUMBER':
      case 'BOOLEAN':
      case 'DATE_PICKER':
      case 'REFERENCE':
      case 'SINGLE_SELECT':
      case 'MULTI_SELECT':
        this.showFiltersForReferenceFields = true;
        break;

      case 'LONG_TEXT':
      case 'ATTACHMENT':
        this.showFiltersForReferenceFields = false;
        break;

      default:
        this.showFiltersForReferenceFields = true;
        break;
    }

    this.fieldTypeMap[i] = option.fieldType;
  }

  clearValues() {
    this.formControl.reset('value');
    for (let i = 1; i < this.formControl.value.length; i++) {
      this.formControl.removeAt(i);
    }
  }

  formFilterQuery() {
    const filters = this.formControl.value;
    for (let i = 0; i < filters.length; i++) {
      let condition = filters[i].condition;
      let field = filters[i].field;
      let value = filters[i].value;
      let from = filters[i].from;
      let to = filters[i].to;

      if (condition !== 'is_between') {
        //check for condition and key in data model
        if (this.filterQuery[condition] && this.filterQuery[condition][field]) {
          this.filterQuery[condition][field].push(value);
        } else {
          this.filterQuery[condition] = {
            [field]: []
          };
          this.filterQuery[condition][field].push(value);
        }
      } else {
        if (this.filterQuery[condition] && this.filterQuery[condition][field]) {
          this.filterQuery[condition][field] = [from, to];
        } else {
          this.filterQuery[condition] = {
            [field]: [from, to]
          };
        }
      }
    }
  }

  printSelectedFilters() {
    let selectedFilterString: any[] = [];
    let filtersFormArray = this.filterForm.value.filters;
    let conditionString: string = null;
    let eachString: string = null;
    for (let i = 0; i < filtersFormArray.length; i++) {
      let fieldObj = this.fieldList.filter(x => x.handleBar === filtersFormArray[i].field);
      conditionString = filtersFormArray[i].condition.split('_').join(' ');
      if (filtersFormArray[i].condition !== 'is_between') {
        eachString = '' + fieldObj[0].name + ' ' + conditionString + ' ' + filtersFormArray[i].value + '';
      } else {
        eachString =
          '' +
          fieldObj[0].name +
          ' ' +
          conditionString +
          ' ' +
          filtersFormArray[i].from +
          ' and ' +
          filtersFormArray[i].to +
          '';
      }
      selectedFilterString.push(eachString);
    }
    selectedFilterString.join('; ');
    this.filterMenu = false;
    this.query = selectedFilterString.join('; ');
    this.formFilterQuery();
    this.emitFilters.emit(this.filterQuery);
  }

  conditionChange(event, i) {
    const fg = this.formControl.controls[i] as FormGroup;
    const fcValue = fg.controls.value as FormControl;
    const fcFrom = fg.controls.from as FormControl;
    const fcTo = fg.controls.to as FormControl;
    if (event === 'in_between') {
      fcValue.setValue(null);
    } else {
      fcFrom.setValue(null);
      fcTo.setValue(null);
    }
  }

  getSingleSelectValueArray(data, key) {
    // console.log(data);
    // console.log(key);
    // console.log(data[Object.keys(data)[0]])

    let array: Array<any> = [];
    let valueArray: Array<any> = [];
    let valueObj = {};

    let aa = data.filter(element => {
      return element[key];
    });

    if (typeof aa[0][key][0] === 'object') {
      aa[0][key].forEach(element => {
        let val: string = '';
        val = element['value'];
        valueArray.push(val);
      });
      valueObj[key] = valueArray;
      array.push(valueObj);
      // data = array;
    }

    // console.log(valueArray);
    return aa[0][key];
  }

  isSingleSelectWithImages(data, key): boolean {
    if (!data) return false;
    let aa = data.filter(element => {
      return element[key];
    });

    if (typeof aa[0][key][0] === 'object') {
      return true;
    }
    return false;
  }

  isMultiSelectWithImages(data, key) {
    let aa = data.filter(element => {
      return element[key];
    });

    if (typeof aa[0][key][0] === 'object') {
      return true;
    }
    return false;
  }

  getMultiSelectValueArray(data, key) {
    let array: Array<any> = [];
    let valueArray: Array<any> = [];
    let valueObj = {};

    let aa = data.filter(element => {
      return element[key];
    });

    if (typeof aa[0][key][0] === 'object') {
      aa[0][key].forEach(element => {
        let val: string = '';
        val = element['value'];
        valueArray.push(val);
      });
      valueObj[key] = valueArray;
      array.push(valueObj);
      // data = array;
    }

    // console.log(valueArray);
    return aa[0][key];
  }

  isProductsFilters(): boolean {
    if (new String(this.filtersFor).valueOf() === 'products') {
      return true;
    }
    return false;
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    // this.showValuesBasedOnActivityModule = false;
  }
}
