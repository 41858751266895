<ng-container *ngIf="autoClose">
  <div class="export-succ" [class.error-with-link]="type == 'error-link'" id="alert_div">
    <img
      *ngIf="type == 'error' || type == 'error-link'"
      class="error-ic"
      src="https://ik.imagekit.io/2gwij97w0o/alert-triangle-icon.svg"
    />
    <img
      *ngIf="type == 'warning'"
      class="error-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
    />
    <img
      *ngIf="type == 'success'"
      class="check-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/check-circle.svg"
    />
    {{ message }}
    <a *ngIf="type == 'error-link'" [href]="link" target="_blank">Learn more</a>
    <img
      *ngIf="showCross"
      (click)="closeNotification()"
      class="cross-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>
