import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeSegment'
})
export class ActiveSegmentPipe implements PipeTransform {
  transform(segment: any, filterBySearch: boolean): unknown {
    let toggle: boolean = false;
    let url = window.location.search;
    if (!url) return toggle;
    url = url.split('filter=')[1];
    if (filterBySearch) {
      toggle = false;
    } else if (url !== undefined) {
      if (url.indexOf('&filterOperator=OR') !== -1) {
        url = url.substring(0, url.indexOf('&filterOperator=OR'));
        url = 'OR(' + url + ')';
      }
      url = url
        .replace(/%3D/g, '=')
        .replace(/%7C/g, '|')
        .replace(/%26/g, '&')
        .replace(/%20/g, ' ');
      if (decodeURIComponent(url) === segment.filterQuery) {
        toggle = true;
      } else {
        if (segment.name === 'Untitled') {
          toggle = true;
        } else {
          toggle = false;
        }
      }
    } else {
      if (segment.name === 'All') {
        toggle = true;
        return toggle;
      }
    }
    return toggle;
  }
}
