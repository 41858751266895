import { Action } from '@ngrx/store';
import { Invoices, ExpensesList } from '../invoice.state';

export enum InvoiceActionTypes {
  GET_INVOICE_LIST = '[Invoice Page] Get Invoice List',
  GET_INVOICE_LIST_SUCCESS = '[Fetch invoices success] Get Invoice List Success',
  GET_INVOICE_LIST_FAILURE = '[Fetch invoices failure] Get Invoice List Failure',
  GET_INVOICE_DETAIL = '[Invoice Page] Get Invoice detail',
  GET_INVOICE_DETAIL_SUCCESS = '[Fetch invoice detail success] Get Invoice detail Success',
  GET_INVOICE_DETAIL_FAILURE = '[Fetch invoice detail failure] Get Invoice detail Failure',
  GET_EXPENSES_LIST = '[Expenses Page] Get Expenses List',
  GET_EXPENSES_LIST_SUCCESS = '[Fetch Expenses success] Get Expense List Success',
  GET_EXPENSES_LIST_FAILURE = '[Fetch Expenses failure] Get Expense List Failure'
}

export class GetInvoicesAction implements Action {
  readonly type = InvoiceActionTypes.GET_INVOICE_LIST;
  constructor(public payload: {}) {}
}

export class GetInvoicesSuccessAction implements Action {
  readonly type = InvoiceActionTypes.GET_INVOICE_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class GetInvoicesFailureAction implements Action {
  readonly type = InvoiceActionTypes.GET_INVOICE_LIST_FAILURE;
}

export class GetInvoiceDetailAction implements Action {
  readonly type = InvoiceActionTypes.GET_INVOICE_DETAIL;
  constructor(public payload: {}) {}
}

export class GetInvoiceDetailSuccessAction implements Action {
  readonly type = InvoiceActionTypes.GET_INVOICE_DETAIL_SUCCESS;

  constructor(public payload: any) {}
}

export class GetInvoiceDetailFailureAction implements Action {
  readonly type = InvoiceActionTypes.GET_INVOICE_DETAIL_FAILURE;
}

export class GetExpensesAction implements Action {
  readonly type = InvoiceActionTypes.GET_EXPENSES_LIST;
  constructor(public payload: {}) {}
}

export class GetExpensesSuccessAction implements Action {
  readonly type = InvoiceActionTypes.GET_EXPENSES_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class GetExpensesFailureAction implements Action {
  readonly type = InvoiceActionTypes.GET_EXPENSES_LIST_FAILURE;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type InvoiceUnion =
  | GetInvoicesAction
  | GetInvoicesSuccessAction
  | GetInvoicesFailureAction
  | GetExpensesAction
  | GetExpensesSuccessAction
  | GetExpensesFailureAction
  | GetInvoiceDetailAction
  | GetInvoiceDetailSuccessAction
  | GetInvoiceDetailFailureAction;
