import { Action } from '@ngrx/store';
export enum FcActionTypes {
  GET_FC_LIST = '[FC Page] Get FC Details',
  GET_FC_LIST_SUCCESS = '[FC success] Get FC Success',
  GET_FC_LIST_FAILURE = '[FC failure] Get FC Failure',
  GET_EXTERNAL_WMS_LIST = '[FC Page] Get External WMS Account Details',
  GET_EXTERNAL_WMS_LIST_SUCCESS = '[FC success] Get External WMS Account Details Success',
  GET_EXTERNAL_WMS_FAILURE = '[FC failure] Get External WMS Account Details Failure',
  GET_WAREHOUSE_DETAILS_BY_ID = '[FC Page] Get Warehouse Details By Id',
  GET_WAREHOUSE_DETAILS_BY_ID_SUCCESS = '[FC success] Get Warehouse Details By Id Success',
  GET_WAREHOUSE_DETAILS_BY_ID_FAILURE = '[FC failure] Get Warehouse Details By Id Failure',
  SAVE_EDIT_WAREHOUSE_DETAILS = '[FC Page] Save And Edit Warehouse Details',
  SAVE_EDIT_WAREHOUSE_DETAILS_SUCCESS = '[FC success] Save And Edit Warehouse Details Success',
  SAVE_EDIT_WAREHOUSE_DETAILS_FAILURE = '[FC failure] Save And Edit Warehouse Details Failure',
  RESET_SAVE_EDIT_WAREHOUSE_STATE = '[FC Page] Reset Save And Edit Warehouse State',
  CREATE_SUBSCRIPTION = '[FC Page] Create Subscription',
  CREATE_SUBSCRIPTION_SUCCESS = '[FC success] Create Subscription Success',
  CREATE_SUBSCRIPTION_FAILURE = '[FC failure] Create Subscription Failure',
  EDIT_SUBSCRIPTION = '[FC Page] Edit Subscription Details',
  EDIT_SUBSCRIPTION_SUCCESS = '[FC success] Edit Subscription Details Success',
  EDIT_SUBSCRIPTION_FAILURE = '[FC failure] Edit Subscription Details Failure',
  SEARCH_PINCODE_DETAILS = '[FC Page] Search Pincode Details',
  SEARCH_PINCODE_DETAILS_SUCCESS = '[FC success] Search Pincode Details Success',
  SEARCH_PINCODE_DETAILS_FAILURE = '[FC failure] Search Pincode Details Failure',
  GET_STATE_LIST = '[FC Page] Get State List',
  GET_STATE_LIST_SUCCESS = '[FC success] Get State List Success',
  GET_STATE_LIST_FAILURE = '[FC failure] Get State List Failure',
  GET_CITY_LIST = '[FC Page] Get City List',
  GET_CITY_LIST_SUCCESS = '[FC success] Get City List Success',
  GET_CITY_LIST_FAILURE = '[FC failure] Get City List Failure',
  RESET_STATE = '[FC Module] Reset state to initial values'
}
export class GetFcAction implements Action {
  readonly type = FcActionTypes.GET_FC_LIST;
}

export class GetFcSuccessAction implements Action {
  readonly type = FcActionTypes.GET_FC_LIST_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetFcFailureAction implements Action {
  readonly type = FcActionTypes.GET_FC_LIST_FAILURE;
}

export class GetExternalWmsListAction implements Action {
  readonly type = FcActionTypes.GET_EXTERNAL_WMS_LIST;
}

export class GetExternalWmsListSuccessAction implements Action {
  readonly type = FcActionTypes.GET_EXTERNAL_WMS_LIST_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetExternalWmsListFailureAction implements Action {
  readonly type = FcActionTypes.GET_EXTERNAL_WMS_FAILURE;
}

export class ResetFcStateAction implements Action {
  readonly type = FcActionTypes.RESET_STATE;
}

export class GetWarehouseById implements Action {
  readonly type = FcActionTypes.GET_WAREHOUSE_DETAILS_BY_ID;
  constructor(public payload: any) {}
}

export class GetWarehouseByIdSuccess implements Action {
  readonly type = FcActionTypes.GET_WAREHOUSE_DETAILS_BY_ID_SUCCESS;
  constructor(public payload: any) {}
}

export class GetWarehouseByIdFailure implements Action {
  readonly type = FcActionTypes.GET_WAREHOUSE_DETAILS_BY_ID_FAILURE;
  constructor(public payload: any) {}
}

export class SaveEditWarehouseDetails implements Action {
  readonly type = FcActionTypes.SAVE_EDIT_WAREHOUSE_DETAILS;
  constructor(public payload1: any, public payload2: any) {}
}

export class SaveEditWarehouseDetailsSuccess implements Action {
  readonly type = FcActionTypes.SAVE_EDIT_WAREHOUSE_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveEditWarehouseDetailsFailure implements Action {
  readonly type = FcActionTypes.SAVE_EDIT_WAREHOUSE_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class ResetSaveEditWarehouseStateAction implements Action {
  readonly type = FcActionTypes.RESET_SAVE_EDIT_WAREHOUSE_STATE;
}
export class CreateSubscription implements Action {
  readonly type = FcActionTypes.CREATE_SUBSCRIPTION;
  constructor(public payload: any) {}
}

export class CreateSubscriptionSuccess implements Action {
  readonly type = FcActionTypes.CREATE_SUBSCRIPTION_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateSubscriptionFailure implements Action {
  readonly type = FcActionTypes.CREATE_SUBSCRIPTION_FAILURE;
  constructor(public payload: any) {}
}

export class EditSubscription implements Action {
  readonly type = FcActionTypes.EDIT_SUBSCRIPTION;
  constructor(public payload1: any, public payload2: any) {}
}

export class EditSubscriptionSuccess implements Action {
  readonly type = FcActionTypes.EDIT_SUBSCRIPTION_SUCCESS;
  constructor(public payload: any) {}
}

export class EditSubscriptionFailure implements Action {
  readonly type = FcActionTypes.EDIT_SUBSCRIPTION_FAILURE;
  constructor(public payload: any) {}
}

export class SearchPincodeDetails implements Action {
  readonly type = FcActionTypes.SEARCH_PINCODE_DETAILS;
  constructor(public payload: any) {}
}

export class SearchPincodeDetailsSuccess implements Action {
  readonly type = FcActionTypes.SEARCH_PINCODE_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class SearchPincodeDetailsFailure implements Action {
  readonly type = FcActionTypes.SEARCH_PINCODE_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetCityList implements Action {
  readonly type = FcActionTypes.GET_CITY_LIST;
  constructor(public payload1: any, public payload2: any) {}
}

export class GetCityListSuccess implements Action {
  readonly type = FcActionTypes.GET_CITY_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCityListFailure implements Action {
  readonly type = FcActionTypes.GET_CITY_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetStateList implements Action {
  readonly type = FcActionTypes.GET_STATE_LIST;
  constructor(public payload: any) {}
}

export class GetStateListSuccess implements Action {
  readonly type = FcActionTypes.GET_STATE_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetStateListFailure implements Action {
  readonly type = FcActionTypes.GET_STATE_LIST_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type FcUnion =
  | GetFcAction
  | GetFcSuccessAction
  | GetFcFailureAction
  | GetExternalWmsListAction
  | GetExternalWmsListSuccessAction
  | GetExternalWmsListFailureAction
  | GetWarehouseById
  | GetWarehouseByIdSuccess
  | GetWarehouseByIdFailure
  | SaveEditWarehouseDetails
  | SaveEditWarehouseDetailsSuccess
  | SaveEditWarehouseDetailsFailure
  | ResetSaveEditWarehouseStateAction
  | CreateSubscription
  | CreateSubscriptionSuccess
  | CreateSubscriptionFailure
  | EditSubscription
  | EditSubscriptionSuccess
  | EditSubscriptionFailure
  | SearchPincodeDetails
  | SearchPincodeDetailsSuccess
  | SearchPincodeDetailsFailure
  | GetCityList
  | GetCityListSuccess
  | GetCityListFailure
  | GetStateList
  | GetStateListSuccess
  | GetStateListFailure
  | ResetFcStateAction;
