import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app.state';
import * as fromReducer from '../reducers/settings.reducer';

const getSettingsState = (state: AppState) => state.settings;

export const getAddFeeRuleLoaded = createSelector(getSettingsState, fromReducer.getAddFeeRuleLoaded);

export const getAddFeeRuleLoading = createSelector(getSettingsState, fromReducer.getAddFeeRuleLoading);

export const getAddFeeRuleDetails = createSelector(getSettingsState, fromReducer.getAddFeeRuleDetails);

export const getAddFeeCorrectionLoaded = createSelector(getSettingsState, fromReducer.getFeeCorrectionLoaded);

export const getAddFeeCorrectionLoading = createSelector(getSettingsState, fromReducer.getFeeCorrectionLoading);

export const getAddFeeCorrectionDetails = createSelector(getSettingsState, fromReducer.getFeeCorrectionDetails);

export const getAddBulkFeeRuleLoaded = createSelector(getSettingsState, fromReducer.getAddBulkFeeRuleLoaded);

export const getAddBulkFeeRuleLoading = createSelector(getSettingsState, fromReducer.getAddBulkFeeRuleLoading);

export const getAddBulkFeeRuleDetails = createSelector(getSettingsState, fromReducer.getAddBulkFeeRuleDetails);

export const getBulkFeeRuleDetailsLoaded = createSelector(getSettingsState, fromReducer.getBulkFeeRuleLoaded);

export const getBulkFeeRuleDetailsLoading = createSelector(getSettingsState, fromReducer.getBulkFeeRuleLoading);

export const getBulkFeeRuleDetails = createSelector(getSettingsState, fromReducer.getBulkFeeRuleDetails);

export const getCloneFeeRuleDetailsLoaded = createSelector(getSettingsState, fromReducer.getCloneFeeRuleLoaded);

export const getCloneFeeRuleDetailsLoading = createSelector(getSettingsState, fromReducer.getCloneFeeRuleLoading);

export const getCloneFeeRuleDetails = createSelector(getSettingsState, fromReducer.getCloneFeeRuleDetails);

export const getFeeRuleLoaded = createSelector(getSettingsState, fromReducer.getFeeRuleLoaded);

export const getFeeRuleLoading = createSelector(getSettingsState, fromReducer.getFeeRuleLoading);

export const getFeeRuleDetails = createSelector(getSettingsState, fromReducer.getFeeRuleDetails);

export const getEditFeeRuleLoaded = createSelector(getSettingsState, fromReducer.getEditFeeRuleLoaded);

export const getEditFeeRuleLoading = createSelector(getSettingsState, fromReducer.getEditFeeRuleLoading);

export const getEditFeeRuleDetails = createSelector(getSettingsState, fromReducer.getEditFeeRuleDetails);

export const getFeeListLoaded = createSelector(getSettingsState, fromReducer.getFeeListLoaded);

export const getFeeListLoading = createSelector(getSettingsState, fromReducer.getFeeListLoading);

export const getFeeListDetails = createSelector(getSettingsState, fromReducer.getFeeListDetails);

export const getFeeDetailsLoaded = createSelector(getSettingsState, fromReducer.getFeeDetailsLoaded);

export const getFeeDetailsLoading = createSelector(getSettingsState, fromReducer.getFeeDetailsLoading);

export const getFeeDetails = createSelector(getSettingsState, fromReducer.getFeeDetails);

export const getPropertyDetailsLoaded = createSelector(getSettingsState, fromReducer.getPropertyDetailsLoaded);

export const getPropertyDetailsLoading = createSelector(getSettingsState, fromReducer.getPropertyDetailsLoading);

export const getPropertyDetails = createSelector(getSettingsState, fromReducer.getPropertyDetails);
export const getTeamMembersData = createSelector(getSettingsState, fromReducer.getTeamMembersData);
export const getEInvoicingStatusDetails = createSelector(getSettingsState, fromReducer.getEInvoicingStatusDetails);
export const getEInvoicingStatusDetailsLoaded = createSelector(
  getSettingsState,
  fromReducer.getEInvoicingStatusDetailsLoaded
);
export const getEInvoicingStatusDetailsLoading = createSelector(
  getSettingsState,
  fromReducer.getEInvoicingStatusDetailsLoading
);
export const getWorkspaceSettings = createSelector(getSettingsState, fromReducer.getWorkspaceSettings);
export const getBankDetailsList = createSelector(getSettingsState, fromReducer.getBankDetailsList);
export const getBankDetails = createSelector(getSettingsState, fromReducer.getBankDetails);
export const getBusinessDetails = createSelector(getSettingsState, fromReducer.getBusinessDetails);
export const getCodPaymentTermDetails = createSelector(getSettingsState, fromReducer.getCodPaymentTermDetails);
