<div class="web-loader loader_back" *ngIf="isLoading">
  <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
</div>
<div class="dialog-ui">
  <h2 mat-dialog-title *ngIf="(data.multiValue || data.multiSelect) && data.fieldType !== 'ATTACHMENT'">
    {{ data.columnName }} ({{ data.data.length }})
    <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </h2>

  <h2 mat-dialog-title *ngIf="!data.multiValue && !data.multiSelect && data.fieldType !== 'ATTACHMENT'">
    <!-- {{ data.columnName }} (1)  -->
    Details
    <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </h2>
  <h2 mat-dialog-title *ngIf="!data.multiValue && data.fieldType === 'ATTACHMENT'">
    {{ data.columnName }} (1)
    <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
  </h2>

  <h2 mat-dialog-title *ngIf="data.multiValue && data.fieldType === 'ATTACHMENT'">
    {{ data.columnName }} ({{ data.data.length }})
    <img (click)="onCancel()" src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
    <a class="attachmentDownload" (click)="downloadMultipleFiles()">Download all</a>
  </h2>

  <mat-dialog-content>
    <ng-container *ngIf="data && data.multiSelect && !isMultiSelectwithImage">
      <div class="multiValueDiv">
        <ng-container *ngIf="!isSingleSelect">
          <ul>
            <li *ngFor="let item of data.data">
              {{ item }}
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="isSingleSelect">
          <ul>
            <li>{{ data.data }}</li>
          </ul>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="data && data.multiSelect && isMultiSelectwithImage">
      <div class="multiValueDiv single-img">
        <ng-container *ngIf="!isSingleSelect">
          <ul>
            <li *ngFor="let item of data.data">
              <img src="{{ item.image }}" class="thumbnailImage" />
              {{ item.value }}
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="isSingleSelect">
          <ul>
            <li>{{ data.data }}</li>
          </ul>
        </ng-container>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        data &&
        data.multiValue &&
        data.fieldType === 'SINGLE_SELECT' &&
        isSingleSelectwithImage2(data.fieldType, data.data)
      "
    >
      <div class="multiValueDiv single-img">
        <ul>
          <li *ngFor="let item of data.data">
            <ng-container *ngIf="isSingleSelectWithImage(data.fieldType, item)">
              <img src="{{ item.image }}" class="thumbnailImage" />
              {{ item.value }}
            </ng-container>
            <ng-container *ngIf="!isSingleSelectWithImage(data.fieldType, item)">
              {{ item }}
            </ng-container>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        data &&
        data.multiValue &&
        data.fieldType !== 'ATTACHMENT' &&
        data.fieldType !== 'REFERENCE' &&
        data.fieldType !== 'DATE_PICKER' &&
        !isSingleSelectwithImage2(data.fieldType, data.data)
      "
    >
      <div class="multiValueDiv">
        <ul>
          <li *ngFor="let item of data.data">
            {{ item }}
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container
      *ngIf="data.fieldType === 'REFERENCE' && !data.multiValue && (ifIsNested() === undefined || !ifIsNested())"
    >
      <div class="referenceDiv multi-list">
        <ul>
          <span *ngFor="let r of referenceRecordKeys">
            <span
              *ngIf="!multiValueFieldsArray.includes(r) && fieldAndFieldTypePairArray[r] === 'REFERENCE'"
              class="refFieldNested"
              matTooltip="Click to view"
              matTooltipClass="tooltipClass"
            >
              <li (click)="getLevel2Record(data.data['buildingBlock'], r, data.data['record'][r]['id'])">
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                <span class="multiValContent">
                  {{ refDisplayTitle(r) }}
                </span>
              </li>
            </span>

            <span *ngIf="fieldAndFieldTypePairArray[r] === 'DATE_PICKER' && !multiValueFieldsArray.includes(r)">
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                <span class="referenceHeader">
                  {{ getFormattedDate(r, data.data.record[r]) }}
                </span>
              </li>
            </span>

            <span *ngIf="multiValueFieldsArray.includes(r) && fieldAndFieldTypePairArray[r] !== 'REFERENCE'">
              <li *ngIf="r != 'itemBarcodes'">
                <span class="refAllData">
                  {{ data.fieldKeyValuePairArray[r] }} ({{ data.data.record[r].length }})
                </span>
                <span
                  class="multiValContent"
                  (click)="
                    openMultiValueWidget(
                      data.data.record[r],
                      data.fieldKeyValuePairArray[r],
                      r,
                      fieldAndFieldTypePairArray[r]
                    )
                  "
                  matTooltip="Click to view"
                  matTooltipClass="tooltipClass"
                >
                  {{ data.data.record[r].length }}
                </span>
              </li>
            </span>

            <span
              *ngIf="
                fieldAndFieldTypePairArray[r] === 'MULTI_SELECT' && !isMultiSelectwithImageFunc(data.data.record[r][0])
              "
            >
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }}</span>
                <span
                  class=""
                  (click)="
                    openMultiValueWidget(
                      data.data.record[r],
                      data.fieldKeyValuePairArray[r],
                      r,
                      fieldAndFieldTypePairArray[r]
                    )
                  "
                  matTooltip="Click to view"
                  matTooltipClass="tooltipClass"
                >
                  <!-- <span class="txt-center" matTooltip="Click to view" matTooltipClass="tooltipClass"> -->
                  <div class="multiValueItem">{{ data.data.record[r][0] }}</div>
                  <ng-container *ngIf="data.data.record[r].length > 1">
                    <div class="multiValueItem">{{ data.data.record[r][1] }}</div>
                  </ng-container>

                  <ng-container *ngIf="data.data.record[r].length > 2">
                    <div class="multiValueItem muti_img">+{{ data.data.record[r].length - 2 }}</div>
                  </ng-container>
                  <!-- </span> -->
                </span>
              </li>
            </span>

            <span
              *ngIf="
                fieldAndFieldTypePairArray[r] === 'MULTI_SELECT' && isMultiSelectwithImageFunc(data.data.record[r][0])
              "
            >
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }}</span>
                <span
                  class=""
                  (click)="
                    openMultiValueWidget(
                      data.data.record[r],
                      data.fieldKeyValuePairArray[r],
                      r,
                      fieldAndFieldTypePairArray[r]
                    )
                  "
                  matTooltip="Click to view"
                  matTooltipClass="tooltipClass"
                >
                  <div class="multiSelectImageDiv">
                    <img class="multiSelectImage" src="{{ data.data.record[r][0].image }}" />
                    {{ data.data.record[r][0].value }}
                  </div>
                  <ng-container *ngIf="data.data.record[r].length > 1">
                    <div class="multiSelectImageDiv">
                      <img class="multiSelectImage" src="{{ data.data.record[r][1].image }}" />
                      {{ data.data.record[r][1].value }}
                    </div>
                  </ng-container>

                  <ng-container *ngIf="data.data.record[r].length > 2">
                    <div class="multiValueItem">+{{ data.data.record[r].length - 2 }}</div>
                  </ng-container>
                </span>
              </li>
            </span>

            <span *ngIf="multiValueFieldsArray.includes(r) && fieldAndFieldTypePairArray[r] === 'REFERENCE'">
              <li>
                <span class="refAllData">
                  {{ data.fieldKeyValuePairArray[r] }} ({{ data.data.record[r].length }})
                </span>
                <span
                  class="multiValContent"
                  (click)="
                    openMultiValueWidget(
                      data.data.record[r],
                      data.fieldKeyValuePairArray[r],
                      r,
                      fieldAndFieldTypePairArray[r]
                    )
                  "
                  matTooltip="Click to view"
                  matTooltipClass="tooltipClass"
                >
                  {{ data.data.record[r].length }}
                </span>
              </li>
            </span>

            <span *ngIf="fieldAndFieldTypePairArray[r] === 'BOOLEAN'">
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                <span class="referenceHeader">
                  <span *ngIf="data.data.record[r]">
                    YES
                  </span>
                  <span *ngIf="!data.data.record[r]">
                    NO
                  </span>
                </span>
              </li>
            </span>

            <span *ngIf="fieldAndFieldTypePairArray[r] === 'ATTACHMENT'">
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                <div class="multiValueDiv attach-file mrg-li-0">
                  <ul>
                    <li>
                      <span class="img">
                        <img src="{{ data.data.record[r] }}" class="thumbnailImage" />
                        <a class="attachmentDownload" href="{{ data.data.record[r] }}" target="_blank"
                          ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/eye-icon.svg"
                        /></a>
                      </span>
                      <span class="text">
                        <p>{{ fileName }}</p>
                        <a class="attachmentDownload" (click)="downloadFile(data.data.record[r], fileName)">Download</a>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </span>

            <span *ngIf="fieldAndFieldTypePairArray[r] === 'SINGLE_SELECT'">
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                <ng-container *ngIf="isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r])">
                  <div class="multiValueItem">
                    <img class="multiSelectImage" src="{{ data.data.record[r].image }}" />
                    {{ data.data.record[r].value }}
                  </div>
                </ng-container>

                <ng-container *ngIf="!isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r])">
                  <span class="referenceHeader">
                    {{ data.data.record[r] }}
                  </span>
                </ng-container>
              </li>
            </span>

            <span
              *ngIf="
                fieldAndFieldTypePairArray[r] !== 'DATE_PICKER' &&
                fieldAndFieldTypePairArray[r] !== 'MULTI_SELECT' &&
                fieldAndFieldTypePairArray[r] !== 'BOOLEAN' &&
                fieldAndFieldTypePairArray[r] !== 'ATTACHMENT' &&
                !multiValueFieldsArray.includes(r) &&
                fieldAndFieldTypePairArray[r] !== 'REFERENCE' &&
                fieldAndFieldTypePairArray[r] !== 'SINGLE_SELECT' &&
                r != 'itemBarcode'
              "
            >
              <li>
                <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                <span class="referenceHeader">
                  {{ data.data.record[r] }}
                </span>
              </li>
            </span>
          </span>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="data.fieldType === 'REFERENCE' && ifIsNested()">
      <div class="referenceNestedDiv">
        <ul>
          <span *ngIf="data.fieldKeyValuePairArray[data['displayTitle']] != undefined">
            <li>
              <span class="refAllData">
                {{ data.fieldKeyValuePairArray[data['displayTitle']['displayTitle']] }}
              </span>
              <span class="referenceHeader">
                <li
                  class="refData"
                  *ngIf="
                    fieldAndFieldTypePairArray[data['displayTitle']['displayTitle']] !== 'ATTACHMENT';
                    else attachmentDisplayTitle
                  "
                >
                  <ng-container
                    *ngIf="
                      data.data.record != 'itemBarcode' &&
                      data.data.record != 'itemBarcodes' &&
                      data.data.record != 'pickedItemBarcodes'
                    "
                  >
                    {{ data.data.record[data['displayTitle']['displayTitle']] }}
                  </ng-container>
                </li>
                <ng-template #attachmentDisplayTitle>
                  <img
                    src="{{ data.data.record[data['displayTitleAndFieldForNestedReference']['displayTitle']] }}"
                    class="thumbnailImage"
                  />
                </ng-template>
              </span>
            </li>
          </span>
        </ul>

        <ul>
          <ng-container *ngIf="ifKeyAvailable()">
            <ng-container *ngFor="let r of referenceRecordKeys">
              <ng-container
                *ngIf="
                  multiValueFieldsArray.includes(r) &&
                  fieldAndFieldTypePairArray[r] !== 'REFERENCE' &&
                  fieldAndFieldTypePairArray[r] !== 'ATTACHMENT' &&
                  fieldAndFieldTypePairArray[r] !== 'DATE_PICKER' &&
                  fieldAndFieldTypePairArray[r] !== 'MULTI_SELECT' &&
                  fieldAndFieldTypePairArray[r] !== 'SINGLE_SELECT'
                "
              >
                <li
                  *ngIf="
                    r != 'itemBarcodes' &&
                    r != 'pickedItemBarcodes' &&
                    r != 'itemBarcode' &&
                    r != 'itemBarcodeLookupOfIdentifiedItem' &&
                    r != 'itemBarcodeLookup'
                  "
                >
                  <span class="refAllData">
                    {{ data.fieldKeyValuePairArray[r] }} ({{ data.data.record[r]?.length }})
                  </span>
                  <span
                    class="multiValContent"
                    (click)="
                      openMultiValueWidget(
                        data.data.record[r],
                        data.fieldKeyValuePairArray[r],
                        r,
                        fieldAndFieldTypePairArray[r]
                      )
                    "
                    matTooltipClass="theme-tooltip after"
                    matTooltipPosition="after"
                    matTooltip="Click to view"
                  >
                    {{ data.data.record[r]?.length }}
                  </span>
                </li>
              </ng-container>

              <ng-container
                *ngIf="
                  fieldAndFieldTypePairArray[r] === 'MULTI_SELECT' &&
                  !isMultiSelectwithImageFunc(data.data.record[r][0])
                "
              >
                <li>
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }}</span>
                  <span
                    class=""
                    (click)="
                      openMultiValueWidget(
                        data.data.record[r],
                        data.fieldKeyValuePairArray[r],
                        r,
                        fieldAndFieldTypePairArray[r]
                      )
                    "
                    matTooltipClass="theme-tooltip after"
                    matTooltipPosition="after"
                    matTooltip="Click to view"
                  >
                    <div class="multiValueItem">{{ data.data.record[r][0] }}</div>
                    <ng-container *ngIf="data.data.record[r].length > 1">
                      <div class="multiValueItem">{{ data.data.record[r][1] }}</div>
                    </ng-container>

                    <ng-container *ngIf="data.data.record[r].length > 2">
                      <div class="multiValueItem">+{{ data.data.record[r].length - 2 }}</div>
                    </ng-container>
                  </span>
                </li>
              </ng-container>

              <ng-container
                *ngIf="
                  fieldAndFieldTypePairArray[r] === 'MULTI_SELECT' && isMultiSelectwithImageFunc(data.data.record[r][0])
                "
              >
                <li>
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }}</span>
                  <span
                    class=""
                    (click)="
                      openMultiValueWidget(
                        data.data.record[r],
                        data.fieldKeyValuePairArray[r],
                        r,
                        fieldAndFieldTypePairArray[r]
                      )
                    "
                    matTooltipClass="theme-tooltip after"
                    matTooltipPosition="after"
                    matTooltip="Click to view"
                  >
                    <div class="multiSelectImageDiv">
                      <img class="multiSelectImage" src="{{ data.data.record[r][0].image }}" />
                      {{ data.data.record[r][0].value }}
                    </div>
                    <ng-container *ngIf="data.data.record[r].length > 1">
                      <div class="multiSelectImageDiv">
                        <img class="multiSelectImage" src="{{ data.data.record[r][1].image }}" />
                        {{ data.data.record[r][1].value }}
                      </div>
                    </ng-container>

                    <ng-container *ngIf="data.data.record[r].length > 2">
                      <div class="multiValueItem">+{{ data.data.record[r].length - 2 }}</div>
                    </ng-container>
                  </span>
                </li>
              </ng-container>

              <ng-container *ngIf="fieldAndFieldTypePairArray[r] === 'ATTACHMENT'">
                <li>
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                  <div class="multiValueDiv attach-file mrg-li-0">
                    <ul>
                      <li>
                        <span class="img">
                          <img src="{{ data.data.record[r] }}" class="thumbnailImage" />
                          <a class="attachmentDownload" href="{{ data.data.record[r] }}" target="_blank"
                            ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/eye-icon.svg"
                          /></a>
                        </span>
                        <span class="text">
                          <p>{{ fileName }}</p>
                          <a class="attachmentDownload" (click)="downloadFile(data.data.record[r], fileName)"
                            >Download</a
                          >
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ng-container>

              <ng-container *ngIf="fieldAndFieldTypePairArray[r] === 'DATE_PICKER'">
                <li>
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                  <span class="referenceHeader"> {{ getFormattedDate(r, data.data.record[r]) }} </span>
                </li>
              </ng-container>

              <ng-container *ngIf="fieldAndFieldTypePairArray[r] === 'SINGLE_SELECT'">
                <li *ngIf="data && data.data && data.data.record[r] && data.data.record[r][0]">
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                  <ng-container *ngIf="isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r][0])">
                    <ng-container
                      *ngIf="isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r][0])"
                    >
                      <div class="multiValueItem">
                        <img class="multiSelectImage" src="{{ data.data.record[r][0].image }}" />
                        {{ data.data.record[r][0].value }}
                      </div>
                    </ng-container>
                    <span
                      class="referenceHeader"
                      *ngIf="!isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r][0])"
                    >
                      {{ data.data.record[r][0] }}
                    </span>
                  </ng-container>

                  <ng-container
                    *ngIf="!isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r][0])"
                  >
                    <ng-container *ngIf="isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r])">
                      <div class="multiValueItem" *ngIf="data.data.record[r].image">
                        <img class="multiSelectImage" src="{{ data.data.record[r].image }}" />
                        {{ data.data.record[r].value }}
                      </div>
                      <span class="referenceHeader" *ngIf="!data.data.record[r].image">
                        {{ data.data.record[r] }}
                      </span>
                    </ng-container>
                    <span
                      class="referenceHeader"
                      *ngIf="!isSingleSelectwithImage2(fieldAndFieldTypePairArray[r], data.data.record[r])"
                    >
                      {{ data.data.record[r] }}
                    </span>
                  </ng-container>
                </li>
              </ng-container>

              <ng-container
                *ngIf="
                  fieldAndFieldTypePairArray[r] !== 'REFERENCE' &&
                  fieldAndFieldTypePairArray[r] !== 'ATTACHMENT' &&
                  fieldAndFieldTypePairArray[r] !== 'DATE_PICKER' &&
                  fieldAndFieldTypePairArray[r] !== 'MULTI_VALUE' &&
                  fieldAndFieldTypePairArray[r] !== 'MULTI_SELECT' &&
                  !multiValueFieldsArray.includes(r) &&
                  fieldAndFieldTypePairArray[r] !== 'SINGLE_SELECT' &&
                  data.fieldKeyValuePairArray[r] != 'Item barcode'
                "
              >
                <li>
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                  <span class="referenceHeader">
                    {{ data.data.record[r] }}
                  </span>
                </li>
              </ng-container>

              <ng-container *ngIf="!multiValueFieldsArray.includes(r) && fieldAndFieldTypePairArray[r] === 'REFERENCE'">
                <li
                  *ngIf="r != 'itemBarcode'"
                  (click)="getLevel2Record(data.data['buildingBlock'], r, data.data['record'][r]['id'])"
                >
                  <span class="refAllData"> {{ data.fieldKeyValuePairArray[r] }} </span>
                  <span class="multiValContent">
                    <ng-container *ngIf="fieldAndFieldTypePairArray[r] === 'ATTACHMENT'">
                      <img
                        src="{{
                          (data?.data?.record?.r?.record)[data?.displayTitleAndFieldForNestedReference?.r?.displayTitle]
                        }}"
                        class="thumbnailImage"
                      />
                    </ng-container>
                    <ng-container *ngIf="fieldAndFieldTypePairArray[r] === 'REFERENCE'">
                      <ng-container *ngIf="whatsTheFieldTypeReference(r) == 'ATTACHMENT'">
                        <img
                          src="{{ test1([data.displayTitleAndFieldForNestedReference[r]['displayTitle']], r) }}"
                          class="thumbnailImage"
                        />
                      </ng-container>
                      <span *ngIf="whatsTheFieldTypeReference(r) != 'ATTACHMENT'">
                        {{ refDisplayTitle(r) }}
                      </span>
                      <!-- {{ test1([data.displayTitleAndFieldForNestedReference[r]['displayTitle']], r) }} -->
                    </ng-container>
                  </span>
                </li>
              </ng-container>

              <ng-container *ngIf="multiValueFieldsArray.includes(r) && fieldAndFieldTypePairArray[r] === 'REFERENCE'">
                <li>
                  <span class="refAllData">
                    {{ data.fieldKeyValuePairArray[r] }} ({{ data.data.record[r].length }})
                  </span>
                  <span
                    class="multiValContent"
                    (click)="
                      openMultiValueWidget(
                        data.data.record[r],
                        data.fieldKeyValuePairArray[r],
                        r,
                        fieldAndFieldTypePairArray[r]
                      )
                    "
                    matTooltipClass="theme-tooltip after"
                    matTooltipPosition="after"
                    matTooltip="Click to view"
                  >
                    {{ data.data.record[r].length }}
                  </span>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="data.fieldType === 'REFERENCE' && data.multiValue">
      <div class="referenceDiv list-item">
        <ng-container *ngFor="let singleData of data['data']">
          <ul
            (click)="openNestedMultiWidget(singleData, data['columnNameHandleBar'], singleData['record'][displayTitle])"
            matTooltip="Click to view details"
            matTooltipClass="tooltipClass"
          >
            <div class="web-putaway" *ngIf="isLoadingItems">
              <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
            </div>
            <li
              class="referenceHeader"
              *ngIf="fieldAndFieldTypePairArray[displayTitle] !== 'ATTACHMENT'; else attachmentDisplayTitle"
            >
              <ng-container *ngIf="!displayTitle.includes('.'); else displayTitleWithDot">
                <ng-container
                  *ngIf="
                    !singleData['record']['itemBarcode'] && !singleData['record']['itemBarcodeLookupOfIdentifiedItem']
                  "
                >
                  {{ singleData['record'][displayTitle] }}
                </ng-container>
              </ng-container>
              <ng-template #displayTitleWithDot>
                <ng-container *ngIf="putawayCompletedIts && putawayCompletedIts.length">
                  {{ putawayCompletedIts | getPutawayCompletedItems: singleData:data }}
                </ng-container>
              </ng-template>
            </li>
            <ng-template #attachmentDisplayTitle>
              <img src="{{ singleData.record[displayTitle] }}" class="thumbnailImage" />
            </ng-template>
            <span *ngFor="let d of displayFields">
              <li class="refData" *ngIf="fieldAndFieldTypePairArray[d] !== 'ATTACHMENT'; else attachmentDisplayTitle">
                <ng-container
                  *ngIf="
                    d != 'itemBarcode' &&
                    d != 'itemBarcodes' &&
                    d != 'pickedItemBarcodes' &&
                    d != 'itemBarcodeLookupOfIdentifiedItem' &&
                    d != displayTitle
                  "
                >
                  {{ singleData['record'][d] }}
                </ng-container>
              </li>
              <ng-template #attachmentDisplayTitle>
                <img src="{{ singleData['record'][d] }}" class="thumbnailImage" />
              </ng-template>
            </span>
          </ul>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="data.fieldType === 'DATE_PICKER' && data.multiValue">
      <div class="multiValueDiv">
        <ul>
          <li *ngFor="let item of data.data">
            {{ getFormattedDate('', item) }}
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="!data.multiValue && data && data.fieldType === 'ATTACHMENT' && fileName !== ''">
      <div class="multiValueDiv attach-file">
        <ul>
          <li>
            <ng-container *ngIf="ifAttachmentIsImage(fileDataObject.fileType)">
              <span class="img">
                <img src="{{ data.data }}" class="thumbnailImage" />
                <a class="attachmentDownload" href="{{ data.data }}" target="_blank"
                  ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/eye-icon.svg"
                /></a>
              </span>
              <span class="text">
                <p>{{ fileName }}</p>
                <a
                  class="attachmentDownload"
                  (click)="downloadFile(fileDataObject.fileData, fileName, fileDataObject.fileType)"
                  >Download</a
                >
              </span>
            </ng-container>

            <ng-container *ngIf="ifAttachmentIsPDF(fileDataObject.fileType)">
              <span class="img">
                <img
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/any-filetype-icon.png"
                  class="thumbnailImage"
                />
                <a class="attachmentDownload" href="{{ data.data }}" target="_blank"
                  ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/eye-icon.svg"
                /></a>
              </span>
              <span class="text">
                <p>{{ fileName }}</p>
                <a
                  class="attachmentDownload"
                  (click)="downloadFile(fileDataObject.fileData, fileName, fileDataObject.fileType)"
                  >Download</a
                >
              </span>
            </ng-container>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="data.multiValue && data && data.fieldType === 'ATTACHMENT'">
      <div class="multiValueDiv attach-file">
        <ul>
          <ng-container *ngIf="fileDataArray">
            <ng-container *ngFor="let fileData of fileDataArray">
              <li>
                <ng-container *ngIf="ifAttachmentIsImage(fileData.fileType)">
                  <span class="img">
                    <img src="{{ fileData.data }}" class="thumbnailImage" />
                    <a class="attachmentDownload" href="{{ fileData.data }}" target="_blank"
                      ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/eye-icon.svg"
                    /></a>
                  </span>
                  <span class="text">
                    <p>{{ fileData.fileName }}</p>
                    <a
                      class="attachmentDownload"
                      (click)="downloadFile(fileData.data, fileData.fileName, fileData.fileType)"
                      >Download</a
                    >
                  </span>
                </ng-container>

                <ng-container *ngIf="ifAttachmentIsPDF(fileData.fileType)">
                  <span class="img">
                    <img
                      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/any-filetype-icon.png"
                      class="thumbnailImage"
                    />
                    <a class="attachmentDownload" href="{{ fileData.data }}" target="_blank"
                      ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/eye-icon.svg"
                    /></a>
                  </span>
                  <span class="text">
                    <p>{{ fileData.fileName }}</p>
                    <a
                      class="attachmentDownload"
                      (click)="downloadFile(fileData.data, fileData.fileName, fileData.fileType)"
                      >Download</a
                    >
                  </span>
                </ng-container>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </mat-dialog-content>
</div>
