import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Configuration } from 'src/app/constants/config';
import { ApiService, AuthService } from 'src/app/shared/services';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment as env } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ShippingModeService {
  private accountSlug: string;
  private messageSource = new Subject<any>();
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router,
    private API_URL: Configuration,
    private api: ApiService
  ) {
    this.accountSlug = this.authService.getAccountSlug();
    // this.AuthorizationToken = this.authService.getAuthToken();
    // if (this.AuthorizationToken === undefined || this.AuthorizationToken == null) {
    //   this.errorHandlingService.logoutOnError('Session timed out');
    // }
    // this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;

    // // Get slug url
    // this.accountslug = this.authService.getAccountSlug();
    // if (this.accountslug === undefined || this.accountslug == null) {
    //   // TODO:: redirect
    // }
    // this.ApiUrl = 'https://' + this.accountslug + '.' + this.ApiUrl;
    // this.PaymentURL = 'https://' + this.accountslug + this.PaymentURL;
  }
  getRuleFields() {
    return this.api.get(`v1/ruleCombination`);
  }

  saveRule(data) {
    if (data.id) {
      return this.api.put(`v1/rule`, data);
    } else {
      return this.api.post(`v1/rule`, data);
    }
  }

  getAllRules() {
    return this.api.get(`v1/rules`);
  }

  deleteRule(id) {
    return this.api.delete(`v1/rule/` + id);
  }

  getRuleById(id) {
    return this.api.get(`v1/rule/` + id);
  }

  public getMessage(): Observable<any> {
    return this.messageSource.asObservable();
  }

  public setMessage(message: any) {
    return this.messageSource.next(message);
  }
}
