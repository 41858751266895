import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ld from 'lodash';
import { BuildingBlockFilterHelperService } from 'src/app/filter-widget/service/filter-helper-BB.service';
import * as moment from 'moment';
import { DataStoreService } from 'src/app/shared/services/data-store.service';

@Component({
  selector: 'app-reusable-filter-options',
  templateUrl: './reusable-filter-options.component.html',
  styleUrls: ['../../../../filter-new/components/filter-options-panel/filter-options-panel.component.scss']
})
export class ReusableFilterOptionsComponent implements OnInit {
  @Input() currentFilterData;
  @Input() isMoreFilters: boolean;
  @Input() isDateFilter: boolean;
  @Input() isFilterFromChip: string;
  @Input() currentSegmentQuery: string;
  @Input() filterType: 'DATE_FILTER' | 'PRIMARY_FILTER' | 'MORE_FILTER' | 'CHIPS';
  @Output() applyFilterData = new EventEmitter<any>();
  @Output() clearFilterData = new EventEmitter<any>();
  @Output() applyMoreFilterData = new EventEmitter<any>();

  selectedCondition: string;
  appliedFilter: any[] = [];
  dateToDisabled: boolean = true;
  selectedStartDate: any;
  selectedEndDate: any;
  searchTextSelect: string;
  newSelection = new SelectionModel<any>(true, []);
  filterOptions: { key: string; checked: boolean; label?: string }[] = [];
  dateOptionsList: object[] = [];
  customDateRange: boolean;
  fromDatePlaceholder: any = 'Start date';
  toDatePlaceholder: any = 'End date';
  timeDifference: any;
  filterOptionsList: any = [];

  constructor(
    private bbFilterHelperService: BuildingBlockFilterHelperService,
    private dataStoreService: DataStoreService
  ) {
    this.dateOptionsList = this.bbFilterHelperService.dateOptionsList;
  }

  ngOnInit(): void {
    this.currentFilterData = ld.cloneDeep(this.currentFilterData);
    if (this.isDateFilter) {
      // Check if this.currentFilterData is not an array, wrap it in an array
      const filterDataToPush = Array.isArray(this.currentFilterData)
        ? this.currentFilterData
        : [this.currentFilterData];
      // Push the filter data to the appliedFilter array
      this.appliedFilter.push(filterDataToPush);
      this.mapDateFilter();
    } else {
      this.appliedFilter.push(this.currentFilterData);
      this.selectedCondition = this.currentFilterData?.filterCondition
        ? this.currentFilterData?.filterCondition
        : this.currentFilterData?.condition[0];
      this.mapCheckedStatus();
    }
  }

  mapDateFilter() {
    if (this.isDateFilter) {
      if (!Array.isArray(this.currentFilterData)) this.currentFilterData = [this.currentFilterData];
      this.filterOptionsList = this.currentFilterData;
      this.filterOptionsList.map(async field => {
        if (!this.isFilterFromChip || field.key === this.isFilterFromChip) {
          field.showMultiselectWithFilterOption = field.showMultiselectWithFilterOption
            ? field.showMultiselectWithFilterOption
            : field?.fromDate
            ? true
            : false;
          field.conditionType = field?.conditionType
            ? field?.conditionType
            : field.filterCondition == 'is between' &&
              field?.fromDate &&
              !field.fromDate.includes('T') &&
              !field?.toDate?.includes('T')
            ? `${field.fromDate} - ${field.toDate}`
            : 'Select';
          field.isDateRangeOptionPanelOpen = false;
        }
      });
    }
  }
  mapCheckedStatus() {
    if (
      this.currentFilterData.fieldType === 'Dropdown' &&
      this.currentFilterData.options &&
      this.currentFilterData.filterValue?.length > 0
    ) {
      // Map options to include checked status based on filterValue
      this.currentFilterData.options = this.currentFilterData.options
        .map(option => ({
          key: option?.key ? option.key : option,
          label: option?.label ? option.label : null,
          checked: this.currentFilterData?.filterValue?.includes(option?.key ? option.key : option)
        }))
        .sort((a, b) => {
          if (a.checked === b.checked) {
            return 0; // No change in order if both are the same
          }
          return a.checked ? -1 : 1; // Checked items first
        });

      // Check if all options are selected
      this.currentFilterData.allSelected =
        this.currentFilterData.filterValue.length === this.currentFilterData.options.length;
    } else if (
      this.currentFilterData.fieldType === 'Dropdown' &&
      (!this.currentFilterData.filterValue || this.currentFilterData.filterValue.length === 0)
    ) {
      // Set all options to unchecked if filterValue is empty
      this.currentFilterData.options = this.currentFilterData.options.map(option => {
        return { key: option?.key ? option.key : option, label: option?.label ? option.label : null, checked: false };
      });

      // Reset allSelected flag
      this.currentFilterData.allSelected = false;
    }
    if (this.currentFilterData.fieldType !== 'String') {
      this.filterOptions = ld.cloneDeep(this.currentFilterData.options);
    }
  }

  // Apply filter
  onApplyFilter() {
    const filter = ld.cloneDeep(this.appliedFilter);
    if (this.isDateFilter && !this.isMoreFilters) {
      this.applyFilterData.emit({ filter: filter[0], isDateFilterFromChip: this.isFilterFromChip });
      if (this.filterType === 'PRIMARY_FILTER') {
        this.closeOptionOnApply('flex-primary-filter', this.appliedFilter[0][0]?.key);
      } else {
        this.closeOptionOnApply('flex-date-filter');
      }
      return;
    }
    if (this.isMoreFilters) {
      if (this.isDateFilter) {
        this.applyMoreFilterData.emit({ filter: filter[0], isDateFilterFromChip: this.isFilterFromChip });
        this.closeOptionOnApply('flex-moreFilter-filter', this.appliedFilter[0][0].key);
      } else {
        this.applyMoreFilterData.emit({ filter: filter });
        this.closeOptionOnApply('flex-moreFilter-filter', this.appliedFilter[0].key);
      }
    } else {
      if (
        !this.isDateFilter &&
        this.currentFilterData.fieldType != 'BOOLEAN' &&
        (this.currentFilterData.filterValue === undefined || this.currentFilterData.filterValue.length == 0) &&
        (!this.currentFilterData.fromDate || !this.currentFilterData.toDate)
      ) {
        this.onClearFilter(this.currentFilterData);
        return;
      }
      this.closeOptionOnApply('flex-primary-filter', this.appliedFilter[0].key);
      this.applyFilterData.emit({ filter: filter });
    }
  }

  closeOptionOnApply(openedFilter, field?) {
    if (!this.isFilterFromChip) document.getElementById(`${openedFilter}${field ? field : ''}`).click();
  }

  conditionChanged(filter, condition) {
    this.currentFilterData.filterCondition = condition;
    this.dateToDisabled =
      filter.fieldType == 'DateRange' && condition == 'is between' && this.selectedStartDate ? false : true;
  }

  selectedColumns(optionChecked, name) {
    if (optionChecked) {
      if (!this.currentFilterData['filterValue'] || this.currentFilterData['filterValue'].length == 0)
        this.currentFilterData['filterValue'] = [];
      if (!this.currentFilterData['filterValue'].includes(name)) {
        this.currentFilterData['filterValue'].push(name);
      }
      if (this.currentFilterData['filterValue'].length === this.filterOptions.length) {
        this.currentFilterData.allSelected = true;
      }
      this.newSelection.select(name);
    } else {
      const indx = this.currentFilterData.filterValue.findIndex(x => x == name);
      if (this.currentFilterData['filterValue'].length === 1 && indx === 0) {
        this.currentFilterData['filterValue'] = [];
      } else {
        this.currentFilterData['filterValue'].splice(indx, 1);
      }
      this.currentFilterData.allSelected
        ? (this.currentFilterData.allSelected = false)
        : (this.currentFilterData.allSelected = this.currentFilterData.allSelected);
      this.newSelection.deselect(name);
    }
  }

  // Master toggle / Select all
  masterToggle(selectAllToggleValue) {
    this.currentFilterData['filterValue'] = [];
    if (selectAllToggleValue) {
      this.filterOptions.map(op => {
        op.checked = true;
        this.currentFilterData['filterValue'].push(op.key);
      });
    } else {
      this.filterOptions.map(op => (op.checked = false));
    }
  }

  selectDateField(event, dateoption) {
    event.stopPropagation();
    if (dateoption.showMultiselectWithFilterOption) {
      const defaultDateFilter = this.bbFilterHelperService.getDefaultDateFilteroption();
      this.appliedFilter[0].map(data => {
        if (data.key === dateoption.key) {
          Object.assign(data, defaultDateFilter);
        }
      });
    }
  }

  onClearFilter(filterData?) {
    //parent component:
    if (this.currentFilterData.fieldType === 'Dropdown') {
      filterData.allSelected = false;
      filterData.filterValue = '';
      this.filterOptions.forEach(item => {
        item.checked = false;
      });
      this.newSelection.selected.filter(item => {
        if (this.filterOptions.includes(item)) {
          this.newSelection.deselect(item);
        }
      });
    } else if (this.isDateFilter) {
      this.appliedFilter[0].map(data => {
        data.fromDate = null;
        data.toDate = null;
        (data.conditionType = 'Select'), (data.isDateRangeOptionPanelOpen = false);
        data.showMultiselectWithFilterOption = false;
      });
    } else if (
      (this.currentFilterData.fieldType === 'Number' || this.currentFilterData.fieldType === 'String') &&
      filterData.filterCondition != 'is between'
    ) {
      this.currentFilterData.filterValue = '';
      filterData.filterValue = '';
    } else if (
      (this.currentFilterData.fieldType === 'Number' || this.currentFilterData.fieldType === 'String') &&
      filterData.filterCondition == 'is between'
    ) {
      this.currentFilterData.fromDate = '';
      this.currentFilterData.toDate = '';
      filterData.fromDate = '';
      filterData.toDate = '';
    }
  }

  stopPropagationFunction(event) {
    this.bbFilterHelperService.stopPropagationFunction(event);
  }

  toggleRangeOptionPanel(option) {
    this.filterOptionsList.map(op => {
      if (op.key === option.key) {
        op.isDateRangeOptionPanelOpen = !op.isDateRangeOptionPanelOpen;
      }
    });
  }

  customDateRangeOut() {
    this.customDateRange = false;
    this.bbFilterHelperService.customDateRangeOut();
  }

  onChangeCondition(event, dateOption, option, index) {
    event.stopPropagation();
    let filterValue: any;
    this.filterOptionsList[index].conditionType = dateOption.value;
    this.filterOptionsList[index].isDateRangeOptionPanelOpen = false;
    this.filterOptionsList[index].showMultiselectWithFilterOption = true;
    this.filterOptionsList[index].filterCondition = 'is between';
    const dates = this.bbFilterHelperService.convertToDate(dateOption);
    this.mapFilterObject(option, dates.fromDate, dates.toDate, filterValue);
    // this.moveDateFilterValues();
  }

  mapFilterObject(option, fromDate, toDate, filterValue?) {
    this.appliedFilter[0].map(data => {
      if (data.key === option.key) {
        if (data.filterCondition == 'is between') {
          delete data.filterValue;
          data.fromDate = fromDate;
          data.toDate = toDate;
        } else {
          data.filterValue = filterValue;
          delete data.fromDate;
          delete data.toDate;
        }
      }
    });
  }

  customDateRangeIn(currentField, event) {
    const crntFltr = this.filterOptionsList.find(x => x.key == currentField);
    this.selectedCondition = crntFltr.filterCondition;
    if (crntFltr.filterCondition != 'is between' && crntFltr.filterValue) {
      this.fromDatePlaceholder = this.selectedStartDate = this.changeDateFormat(crntFltr.filterValue);
    } else if (crntFltr.fromDate && crntFltr.toDate) {
      this.dateToDisabled = false;
      this.fromDatePlaceholder = this.selectedStartDate = this.changeDateFormat(crntFltr.fromDate);
      this.toDatePlaceholder = this.selectedEndDate = this.changeDateFormat(crntFltr.toDate);
      this.getTimeDifference(crntFltr.fromDate, crntFltr.toDate);
    } else {
      this.setCalendarDefault();
    }
    this.customDateRange = true;
    const documentBody = document.getElementsByTagName('body')[0];

    let mousePos = { x: event.clientX, y: event.clientY };

    const halfScreenWidth = document.body.clientWidth / 2;

    if (halfScreenWidth > mousePos.x) {
      documentBody.classList.add('overflow-date-range');
    } else {
      documentBody.classList.add('overflow-date-range-left');
    }
  }

  getTimeDifference(start, end) {
    if (start && end) {
      this.timeDifference = this.bbFilterHelperService.getTimeDifference(start, end);
    }
  }

  onSelectToDate(event) {
    if (this.dateToDisabled) {
      return;
    }
    this.selectedEndDate = event;
    this.toDatePlaceholder = this.changeDateFormat(this.selectedEndDate);
    this.getTimeDifference(this.selectedStartDate, this.selectedEndDate);
  }

  onSelectFromDate(event) {
    this.selectedStartDate = event;
    this.fromDatePlaceholder = this.changeDateFormat(this.selectedStartDate);
    this.getTimeDifference(this.selectedStartDate, this.selectedEndDate);
    this.dateToDisabled = false;
  }

  changeDateFormat(inputdate) {
    const formattedDate = moment(inputdate).format('DD/MM/YYYY');
    return formattedDate;
  }

  mapFilterObjectCustom(option) {
    option.isDateRangeOptionPanelOpen = false;
    this.appliedFilter[0].map(data => {
      if (data.key === option.key) {
        option.filterCondition = this.selectedCondition;
        if (option.filterCondition === 'is between') {
          data.fromDate = this.selectedStartDate;
          data.toDate = this.selectedEndDate;
          delete data.filterValue;
        } else {
          data.filterValue = this.selectedStartDate;
          delete data.fromDate;
          delete data.toDate;
        }
      }
    });
    this.customDateRange = false;
    // option.conditionType =
    //   !option.filterValue && this.selectedStartDate && this.selectedEndDate
    //     ? this.changeDateFormat(this.selectedStartDate) + ' - ' + this.changeDateFormat(this.selectedEndDate)
    //     : this.changeDateFormat(this.selectedStartDate);
    option.conditionType = 'custom';
    let p = document.getElementsByTagName('body')[0];
    p.classList.remove('overflow-date-range');
  }

  resetCalendar(index) {
    this.filterOptionsList[index].fromDate = '';
    this.filterOptionsList[index].toDate = '';
    this.filterOptionsList[index].conditionType = '';
    this.setCalendarDefault();
  }

  setCalendarDefault() {
    this.fromDatePlaceholder = 'Start date';
    this.selectedStartDate = null;
    this.toDatePlaceholder = 'End date';
    this.selectedEndDate = null;
    this.dateToDisabled = true;
    this.timeDifference = '';
  }

  ngOnDestroy() {
    this.appliedFilter = this.currentFilterData = [];
  }
}
