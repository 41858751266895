import { Injectable } from '@angular/core';
import { AppInstallationService } from '../../service/app-install.service';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { GetAppScope, AppInstallActionTypes, GetAppDetails, GetAppInstallConsent } from '../actions/app-install.action';
import { mergeMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class AppInstallEffects {
  constructor(private appInstallService: AppInstallationService, private actions$: Actions) {}

  @Effect()
  getAppScope$: Observable<Action> = this.actions$.pipe(
    ofType<GetAppScope>(AppInstallActionTypes.GET_APP_SCOPE),
    mergeMap(action => {
      return this.appInstallService.getAppScope(action.payload).pipe(
        map(data => ({
          type: AppInstallActionTypes.GET_APP_SCOPE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AppInstallActionTypes.GET_APP_SCOPE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getAppDetail$: Observable<Action> = this.actions$.pipe(
    ofType<GetAppDetails>(AppInstallActionTypes.GET_APP_DETAILS),
    mergeMap(action => {
      return this.appInstallService.getAppDetails(action.payload).pipe(
        map(data => ({
          type: AppInstallActionTypes.GET_APP_DETAILS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AppInstallActionTypes.GET_APP_DETAILS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getAppInstallConsent$: Observable<Action> = this.actions$.pipe(
    ofType<GetAppInstallConsent>(AppInstallActionTypes.GET_APP_INSTALL_CONSENT),
    mergeMap(action => {
      return this.appInstallService.getAppInstallConsent(action.payload).pipe(
        map(data => ({
          type: AppInstallActionTypes.GET_APP_INSTALL_CONSENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AppInstallActionTypes.GET_APP_INSTALL_CONSENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
}
