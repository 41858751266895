import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeric'
})
export class NumericPipe implements PipeTransform {
  transform(value: any, compare?: any): any {
    if (value) {
      return true;
      // if (value < 0) {
      //   return false;
      // }
      // if (typeof value === 'string') {
      //   return false;
      // }
    } else {
      return false;
    }
  }
}
