import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberTransformOrdinals'
})
export class NumberTransformOrdinalsPipe implements PipeTransform {
  transform(value: number): any {
    if (!value) {
      return null;
    }
    if (value > 3 && value < 21) {
      return value + 'th';
    }
    switch (value % 10) {
      case 1:
        return value + 'st';
      case 2:
        return value + 'nd';
      case 3:
        return value + 'rd';
      default:
        return value + 'th';
    }
  }
}
