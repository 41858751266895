<div class="chip-input">
  <div class="chip-input__chips-list">
    <div *ngFor="let chip of chips" class="chip-input__chip">
      <span class="chip-input__chip__text">{{ chip }}</span>
      <img
        *ngIf="chip != 'COD' && chip != 'Cash on Delivery' && chip != 'Pay on Delivery'"
        class="chip-input__chip__remove"
        height="7px"
        width="7px"
        (click)="removeChip(chip)"
        src="https://ik.imagekit.io/2gwij97w0o/cross-Icon.svg?updatedAt=1718523138688"
      />
    </div>
    <input
      class="chip-input__input"
      [(ngModel)]="inputText"
      (keydown.enter)="$event.preventDefault(); addChip()"
      [placeholder]="chips.length ? '' : ''"
    />
  </div>
  <textarea class="chip-input__hidden-textarea" rows="1" [value]="chips.join(', ')" readonly></textarea>
</div>
