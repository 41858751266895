<form class="getdate-rang-form" [formGroup]="orderCreatedRangeForm">
  <div class="pick-date">
    <div class="col-6">
      <label> Start date </label>
      <mat-form-field class="select-team-member datepic">
        <input
          matInput
          [matDatepicker]="picker"
          (keypress)="numberOnly($event)"
          placeholder="MM/DD/YYYY"
          [max]="max"
          formControlName="startDate"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-6">
      <label> End date </label>
      <mat-form-field class="select-team-member datepic">
        <input
          matInput
          [matDatepicker]="picker2"
          (keypress)="numberOnly($event)"
          placeholder="MM/DD/YYYY"
          [max]="max"
          formControlName="endDate"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-error class="error-message" *ngIf="orderCreatedRangeForm.hasError('notValid')">
      Please select only 180 days
    </mat-error>
    <mat-error class="error-message" *ngIf="orderCreatedRangeForm.hasError('notValidStartDate')">
      Start date cannot be greater than end date
    </mat-error>
  </div>
</form>
