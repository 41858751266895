<button class="pop-close-btn" (click)="dialog.closeAll()">
  <img
    src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg?updatedAt=1621936944471"
  />
</button>

<ng-container *ngIf="isAutomationContent; else showstaticContent">
  <div class="pop-content">
    <div class="mat-dialog-content">
      <p class="new-order" [innerHTML]="appData | safe: 'html'"></p>
    </div>
  </div>
</ng-container>

<ng-template #showstaticContent>
  <div class="pop-content">
    <h1 mat-dialog-title class="pop-title">{{ appData }}</h1>
  </div>
  <div class="pop-content" *ngIf="appData.secondBlock">
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <p>{{ appData.secondBlock }}</p>
    </div>
  </div>
  <div class="pop-content request" *ngIf="appData.thirdBlock || appData.contact || appData.website">
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <p *ngIf="appData.thirdBlock">{{ appData.thirdBlock }}</p>
      <p *ngIf="appData.contact">{{ appData.contact }}</p>
      <p *ngIf="appData.website">{{ appData.website }}</p>
    </div>
  </div>
</ng-template>
