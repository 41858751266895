import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstalledAppAuthorizationService {
  constructor(private http: HttpClient) {}

  checkAppAuthorization(redirectUrl) {
    let queryParams = { redirectUrl: redirectUrl };
    return this.http.post('api/v1/connection', queryParams);
  }
}
