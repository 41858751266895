import { Action } from '@ngrx/store';

export const weightDiscrypancyList = '[weightDiscrypancyList component] list';
export const weightDiscrypancyListSuccess = '[weightDiscrypancyList component] success';
export const weightDiscrypancyListError = '[weightDiscrypancyList component] Error';
export const weightDiscrypancySave = '[createweightDiscrypancy component] save';
export const weightDiscrypancySaveSuccess = '[createweightDiscrypancy component] save success';
export const weightDiscrypancySaveError = '[createweightDiscrypancy component] Error';
export const dummyAction = '[dummy Action]';

export class weightDiscrypancyListRequestAction implements Action {
  readonly type = weightDiscrypancyList;
  constructor(readonly payload: any) {}
}

export class weightDiscrypancyListSuccessAction implements Action {
  readonly type = weightDiscrypancyListSuccess;
  constructor(readonly payload: any) {}
}

export class weightDiscrypancyListErrorAction implements Action {
  readonly type = weightDiscrypancyListError;
  constructor(readonly payload: any) {}
}

export class weightDiscrypancySaveAction implements Action {
  readonly type = weightDiscrypancySave;
  constructor(readonly payload: any) {}
}

export class weightDiscrypancySaveSuccessAction implements Action {
  readonly type = weightDiscrypancySaveSuccess;
  constructor(readonly payload: any) {}
}
export class weightDiscrypancySaveErrorAction implements Action {
  readonly type = weightDiscrypancySaveError;
  constructor(readonly payload: any) {}
}

export type weightDiscrypancyActions =
  | weightDiscrypancyListRequestAction
  | weightDiscrypancyListSuccessAction
  | weightDiscrypancySaveAction
  | weightDiscrypancySaveSuccessAction
  | weightDiscrypancyListErrorAction
  | weightDiscrypancySaveErrorAction;
