<!-- <div class="topbar">
  <div class="logo">
    <a routerLink="">
      <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/logo-icon.svg" />
    </a>
  </div>
  <a class="cross-btn" *ngIf="showCloseIcon">
    <img
      (click)="onLogout()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg?updatedAt=1621936944471"
    />
  </a>

  <div class="logout" *ngIf="authService.isLoggedIn && !!userInitials">
    <a class="">
      <!-- <span class="name">{{ userInitials | uppercase }}</span>
      <span class="logouttext" (click)="onLogout()">Log Out</span></a
    >
  </div>
</div> -->

<div
  *ngIf="
    !currenturl.includes('signin-complete') && currenturl !== '/auth/otp-validation' && !currenturl.includes('invite')
  "
  class="topbar topbar-new"
  [class.add-bgrd]="notOtpOrSignupPage"
  [class.add-blr]="isOtpPopupOpen"
>
  <div class="topbar-content">
    <div class="logo">
      <img
        src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/Eshopbox-logo.svg?updatedAt=1679318061411"
        alt=""
      />
    </div>
    <a *ngIf="notOtpOrSignupPage" class="logout-btn" (click)="onLogout()">Log Out</a>
  </div>
  <ng-container *ngIf="notOtpOrSignupPage">
    <div class="breadcrumb"><app-breadcrumb></app-breadcrumb></div>
  </ng-container>
</div>
