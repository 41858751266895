<div *ngIf="isThemeLight" class="tooltip-arrow"></div>

<div [ngSwitch]="options['contentType']">
  <ng-container *ngSwitchCase="'template'">
    <ng-container *ngTemplateOutlet="value"></ng-container>
  </ng-container>

  <div *ngSwitchCase="'string'">
    {{ value }}
  </div>

  <div *ngSwitchCase="'html'">
    <div [innerHTML]="value | safeHTML"></div>
  </div>
</div>
