import { Pipe, PipeTransform } from '@angular/core';
import * as JsBarcode from 'jsbarcode';

@Pipe({
  name: 'JSBarcode'
})
export class JSBarcodePipe implements PipeTransform {
  transform(barcodeText?: string, id?: string, width?: number, height?: number, displayValue?: boolean): any {
    JsBarcode(`#${id}`, `${barcodeText}`, {
      width: width,
      height: height,
      displayValue: displayValue
    });
  }
}
