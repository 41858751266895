<div class="applid-filters">
  <ng-container *ngFor="let f of formFilters; let i = index">
    <div class="chip-wrp">
      <span>
        <i class="remove-chip" (click)="removeFilterAt(i, f)">
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" />
        </i>
        {{ f?.field?.displayKey ? f?.field?.displayKey : f?.field?.key }}
      </span>
      <span class="condition"> {{ f?.condition }} </span>
      <span> {{ f?.value ? f?.value : f?.from }} {{ f?.from && f?.to ? '-' : '' }} {{ f?.to ? f?.to : '' }} </span>
    </div>
  </ng-container>
  <ng-container *ngIf="formFilters.length > 0">
    <span class="clear-all">
      <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" /> Clear All
    </span>
    <div class="save-segment">
      <span (click)="showSaveForm = !showSaveForm"
        ><img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/segment-icon.svg" /> Save as
        Segment
      </span>
      <div class="searchoverlay" [ngClass]="showSaveForm ? 'visible' : ''" (click)="showSaveForm = !showSaveForm"></div>
      <div class="save-form" *ngIf="showSaveForm">
        <h4>Save as</h4>
        <input type="text" placeholder="Enter segment name" />
        <p>This segment will be saved as a new view.</p>
        <div class="action-btn">
          <a class="btn no-icon"> Cancel </a>
          <a class="btn primary-btn no-icon"> Save </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
