<div class="breadcrumb-container">
  <nav>
    <ul>
      <li *ngFor="let brc of breadcrumbs">
        <a
          [routerLink]="brc.url"
          [class.active]="currenturl.includes(brc.url)"
          [class.disable-brc]="currenturl === esbSignupUrls.plans && brc.url === esbSignupUrls.billingDetails"
          >{{ brc.label }}</a
        >
      </li>
    </ul>
  </nav>
</div>
