/**
 * Sort by type list
 */
export const SORTBYTYPELIST: any = [
  { label: 'Created On: Oldest to Newest', value: 'createdOnAsc_product_service' },
  { label: 'Created On: Newest to Oldest', value: 'createdOnDesc_product_service' },
  { label: 'Updated On: Oldest to Newest', value: 'updatedOnAsc_product_service' },
  { label: 'Updated On: Newest to Oldest', value: 'updatedOnDesc_product_service' }
];

export const PRODUCTS_DEFAULT_PARAMS_LIST = {
  selectedView: 'All',
  sortBy: 'product_service',
  page: 0,
  hitsPerPage: 50
};

export const DRAFTS_PRODUCTS_DEFAULT_PARAMS_LIST = {
  selectedView: 'All',
  sortBy: 'product_service',
  page: 0,
  hitsPerPage: 50,
  moduleStatus: 'DRAFT'
};
