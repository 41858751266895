import * as invoiceActions from '../actions/invoice.actions';
import { InvoiceState } from '../invoice.state';

export const initialState: InvoiceState = {
  invoicesLoaded: false,
  invoicesLoading: false,
  invoicesData: null,
  expensesLoaded: false,
  expensesLoading: false,
  expensesData: null
};

export function InvoiceReducer(state = initialState, action: invoiceActions.InvoiceUnion): InvoiceState {
  switch (action.type) {
    case invoiceActions.InvoiceActionTypes.GET_INVOICE_LIST: {
      return {
        ...state,
        invoicesLoaded: false,
        invoicesLoading: true
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_INVOICE_LIST_SUCCESS: {
      return {
        ...state,
        invoicesLoaded: true,
        invoicesLoading: false,
        invoicesData: action.payload
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_INVOICE_LIST_FAILURE: {
      return {
        ...state,
        invoicesLoaded: true,
        invoicesLoading: false
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_INVOICE_DETAIL: {
      return {
        ...state,
        invoicesLoaded: false,
        invoicesLoading: true
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_INVOICE_DETAIL_SUCCESS: {
      return {
        ...state,
        invoicesLoaded: true,
        invoicesLoading: false,
        invoicesData: action.payload
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_INVOICE_DETAIL_FAILURE: {
      return {
        ...state,
        invoicesLoaded: true,
        invoicesLoading: false
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_EXPENSES_LIST: {
      return {
        ...state,
        expensesLoaded: false,
        expensesLoading: true
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_EXPENSES_LIST_SUCCESS: {
      return {
        ...state,
        expensesLoaded: true,
        expensesLoading: false,
        expensesData: action.payload
      };
    }

    case invoiceActions.InvoiceActionTypes.GET_EXPENSES_LIST_FAILURE: {
      return {
        ...state,
        expensesLoaded: true,
        expensesLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getInvoiceLoaded = (state: InvoiceState) => state.invoicesLoaded;
export const getInvoiceLoading = (state: InvoiceState) => state.invoicesLoading;
export const getInvoices = (state: InvoiceState) => state.invoicesData;
export const getExpensesLoaded = (state: InvoiceState) => state.expensesLoaded;
export const getExpensesLoading = (state: InvoiceState) => state.expensesLoading;
export const getExpenses = (state: InvoiceState) => state.expensesData;
