import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent {
  @Input() sortByList = [];
  @Output() sortBy = new EventEmitter<string>();
  onSortSelection(type) {
    this.sortBy.emit(type);
  }
}
