<div class="search-filter">
  <div
    class="search-field"
    [ngClass]="
      (focusInput ? 'up-before' : 'down-before') +
      ' ' +
      (query ? 'up-before' : 'down-before') +
      ' ' +
      (filterName && filterName == 'installedApp' ? 'dynamic-input' : '')
    "
  >
    <input
      type="search"
      (focus)="focusInput = true"
      (focusout)="focusInput = false"
      [(ngModel)]="query"
      (ngModelChange)="searchList($event)"
      [placeholder]="placeholder"
    />
    <span
      class="filter-btn"
      *ngIf="showFilter"
      (click)="filterMenu = !filterMenu"
      [ngClass]="focusInput ? 'hide' : 'show'"
    >
      Filter
    </span>
  </div>
  <div class="searchoverlay" [ngClass]="filterMenu ? 'visible' : ''" (click)="filterMenu = !filterMenu"></div>

  <ng-container *ngIf="isProductsFilters(); then refFilters; else otherFilters"></ng-container>

  <!-- New filter template for reference field filters -->
  <ng-template #refFilters>
    <div class="filter-dropdown" [ngClass]="filterMenu ? 'open' : ''">
      <form [formGroup]="filterForm" (ngSubmit)="submitForm(filterForm.value)">
        <div formArrayName="filters" *ngFor="let filter of formControl.controls; let i = index">
          <div [formGroupName]="i">
            <div class="inpt-row">
              <div class="inpt-col">
                <!-- <div class="select-ui">
                  <select
                    #selectedOption
                    formControlName="field"
                    placeholder="Field"
                    (ngModelChange)="fieldChange($event)"
                    required
                  >
                    <option *ngFor="let f of filterList" [ngValue]="f">
                      {{ f.displayKey }}
                    </option>
                  </select>
                  <label> Field </label>
                </div> -->
                <!-- <mat-form-field>
                  <input type="text" 
                         placeholder="Field"
                         matInput
                         [formControl]="myControl"
                         formControlName="field"
                         [matAutocomplete]="auto"
                         (ngModelChange)="fieldChange($event)"
                         #selectedOption>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async;let j = index" [value]="option">
                      {{option.displayKey}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field> -->
                <mat-form-field>
                  <mat-select
                    formControlName="field"
                    (ngModelChange)="fieldChange($event)"
                    placeholder="Key"
                    #selectedOption
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="'no matching key found'"
                        [formControl]="searchSelectBox"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let option of filteredKeyList | async" [value]="option">
                      {{ option.displayKey }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <ng-container *ngIf="filterList">
                <div class="inpt-col">
                  <mat-form-field>
                    <mat-label>Condition</mat-label>
                    <mat-select formControlName="condition" #condition>
                      <ng-container *ngFor="let c of filterObj[selectedOption?.value?.key]?.condition">
                        <mat-option [value]="c">{{ c }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="inpt-col">
                  <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'string'">
                    <mat-form-field>
                      <mat-label>Enter Value</mat-label>
                      <input matInput placeholder="" formControlName="value" />
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'range'">
                    <ng-container *ngIf="condition.value == 'is between'">
                      <div class="select-rang">
                        <input type="number" formControlName="from" placeholder="From" #from />
                        <input type="number" formControlName="to" placeholder="To" #to />
                        <label>Select Range</label>
                      </div>
                      <ng-container *ngIf="+from.value > +to.value">
                        <mat-error>From value can not be greater than to value.</mat-error>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="condition.value != 'is between'">
                      <mat-form-field>
                        <mat-label>Enter Value</mat-label>
                        <input matInput type="number" formControlName="to" />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'dropdown'">
                    <mat-form-field>
                      <mat-label>Enter Value</mat-label>
                      <mat-select formControlName="value" [multiple]="!showValuesBasedOnActivityModule">
                        <ng-container *ngFor="let v of filterObj[selectedOption?.value?.key]?.value | keyvalue">
                          <mat-option *ngIf="!showValuesBasedOnActivityModule; else basedOnActivity" [value]="v.key">
                            {{ v.key }}
                          </mat-option>
                          <ng-template #basedOnActivity>
                            <mat-option [value]="v.key">
                              {{ v.value | titlecase }}
                            </mat-option>
                          </ng-template>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'daterange'">
                    <mat-form-field>
                      <input formControlName="value" matInput [matDatepicker]="picker" placeholder="Choose a date" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </div>
              </ng-container>
              <span class="remove-items" *ngIf="i != 0" (click)="removeItem(i)" title="Remove"> - </span>
            </div>
          </div>
        </div>
        <div class="add-fileds">
          <span (click)="addMoreItem()">
            Add more filter
          </span>
        </div>
        <div class="form-submit">
          <div class="btn-wrp">
            <div (click)="clearValues()" class="clear-filter">
              Clear Values
            </div>
            <button type="submit" [disabled]="!filterForm.valid" [ngClass]="filterForm.valid ? 'active' : 'inactive'">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <!-- older template which has been wrapped with ng-template -->
  <ng-template #otherFilters>
    <div class="filter-dropdown" [ngClass]="filterMenu ? 'open' : ''">
      <form [formGroup]="filterForm" (ngSubmit)="submitForm(filterForm.value)">
        <div formArrayName="filters" *ngFor="let filter of formControl.controls; let i = index">
          <div [formGroupName]="i">
            <div class="inpt-row">
              <div class="inpt-col">
                <mat-form-field>
                  <mat-select
                    formControlName="field"
                    (ngModelChange)="fieldChange($event)"
                    placeholder="Key"
                    #selectedOption
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="'no matching key found'"
                        [formControl]="searchSelectBox"
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let option of filterList" [value]="option">
                      <ng-container *ngIf="option.fieldType !== 'ATTACHMENT' && option.fieldType !== 'LONG_TEXT'">
                        {{ option.displayKey }}
                      </ng-container>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <ng-container *ngIf="filterList">
                <div class="inpt-col">
                  <mat-form-field>
                    <mat-label>Condition</mat-label>
                    <mat-select formControlName="condition" #condition>
                      <ng-container *ngFor="let c of filterObj[selectedOption?.value?.key]?.condition">
                        <mat-option [value]="c">{{ c }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="inpt-col">
                  <ng-container
                    *ngIf="
                      filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'string' ||
                      filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'short_text' ||
                      filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'long_text' ||
                      filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'number' ||
                      filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'id'
                    "
                  >
                    <ng-container *ngIf="condition.value == 'is_between'">
                      <div class="select-rang">
                        <input type="number" formControlName="from" placeholder="From" #from />
                        <input type="number" formControlName="to" placeholder="To" #to />
                        <label>Select Range</label>
                      </div>
                      <!-- <ng-container *ngIf="+from.value > +to.value">
                      <mat-error>From value can not be greater than to value.</mat-error>
                    </ng-container> -->

                      <!-- <mat-form-field>
                      <mat-label>Enter Value</mat-label>
                      <input matInput placeholder="Enter From Value" formControlName="from" />
                      <input matInput placeholder="Enter TO Value" formControlName="to" />
                    </mat-form-field> -->
                    </ng-container>

                    <ng-container *ngIf="condition.value !== 'is_between'">
                      <mat-form-field>
                        <mat-label>Enter Value</mat-label>
                        <input matInput placeholder="" formControlName="value" />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'single_select'"
                  >
                    <mat-form-field>
                      <mat-label>Select Value</mat-label>
                      <mat-select formControlName="value" multiple>
                        <ng-container
                          *ngIf="
                            isSingleSelectWithImages(
                              singleSelectObjectArray,
                              filterObj[selectedOption?.value?.key]?.key
                            )
                          "
                        >
                          <ng-container
                            *ngFor="
                              let c of getSingleSelectValueArray(
                                singleSelectObjectArray,
                                filterObj[selectedOption?.value?.key]?.key
                              )
                            "
                          >
                            <!-- <ng-container *ngFor="let item of c"> -->
                            <mat-option [value]="c['value']">{{ c['value'] }}</mat-option>
                            <!-- </ng-container> -->
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngIf="
                            !isSingleSelectWithImages(
                              singleSelectObjectArray,
                              filterObj[selectedOption?.value?.key]?.key
                            )
                          "
                        >
                          <ng-container *ngFor="let c of singleSelectObjectArray">
                            <ng-container *ngFor="let item of c[filterObj[selectedOption?.value?.key]?.key]">
                              <mat-option [value]="item">{{ item }}</mat-option>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <!-- <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'reference'">
                    <mat-form-field>
                      <mat-label>Select Value</mat-label>
                      <mat-select formControlName="value">
                        <ng-container *ngFor="let c of singleSelectObjectArray">
                          <ng-container *ngFor="let item of c[filterObj[selectedOption?.value?.key]?.key]">
                            <mat-option [value]="item">{{ item }}</mat-option>
                          </ng-container>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </ng-container> -->

                  <ng-container
                    *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'multi_select'"
                  >
                    <mat-form-field>
                      <mat-label>Select Multi Value</mat-label>
                      <mat-select formControlName="value" multiple>
                        <ng-container
                          *ngIf="
                            isMultiSelectWithImages(multiSelectObjectArray, filterObj[selectedOption?.value?.key]?.key)
                          "
                        >
                          <ng-container
                            *ngFor="
                              let c of getMultiSelectValueArray(
                                multiSelectObjectArray,
                                filterObj[selectedOption?.value?.key]?.key
                              )
                            "
                          >
                            <!-- <ng-container *ngFor="let item of c"> -->
                            <mat-option [value]="c['value']">{{ c['value'] }}</mat-option>
                            <!-- </ng-container> -->
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngIf="
                            !isMultiSelectWithImages(multiSelectObjectArray, filterObj[selectedOption?.value?.key]?.key)
                          "
                        >
                          <ng-container *ngFor="let c of multiSelectObjectArray">
                            <!-- <ng-container *ngFor="let item of c[filterObj[selectedOption?.value?.key]?.key]"> -->
                            <mat-option
                              *ngFor="let item of c[filterObj[selectedOption?.value?.key]?.key]"
                              [value]="item"
                              >{{ item }}</mat-option
                            >
                          </ng-container>
                        </ng-container>

                        <!-- </ng-container> -->
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'boolean'">
                    <!-- <mat-form-field disabled>
                      <mat-label >Enter Value</mat-label>
                      <input matInput placeholder="" formControlName="value" disabled />
                    </mat-form-field> -->
                  </ng-container>

                  <!-- <ng-container *ngIf="condition.value == 'is_between'">
                      <div class="select-rang">
                        <input type="number" formControlName="from" placeholder="From" #from />
                        <input type="number" formControlName="to" placeholder="To" #to />
                        <label>Select Range</label>
                      </div>
                      <ng-container *ngIf="+from.value > +to.value">
                        <mat-error>From value can not be greater than to value.</mat-error>
                      </ng-container>
                    </ng-container> -->

                  <!-- <ng-container *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'dropdown'">
                    <mat-form-field>
                      <mat-label>Enter Value</mat-label>
                      <mat-select formControlName="value" multiple>
                        <ng-container *ngFor="let v of filterObj[selectedOption?.value?.key]?.value | keyvalue">
                          <mat-option *ngIf="!showValuesBasedOnActivityModule; else basedOnActivity" [value]="v.key">
                            {{ v.key }}
                          </mat-option>
                          <ng-template #basedOnActivity>
                            <mat-option [value]="v.key">
                              {{ v.value | titlecase }}
                            </mat-option>
                          </ng-template>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </ng-container> -->
                  <ng-container
                    *ngIf="filterObj[selectedOption?.value?.key]?.fieldType?.toLowerCase() === 'date_picker'"
                  >
                    <ng-container *ngIf="condition.value !== 'is_between'">
                      <mat-form-field>
                        <input formControlName="value" matInput [matDatepicker]="picker" placeholder="Choose a date" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="condition.value === 'is_between'">
                      <div class="in-between-wrap">
                        <mat-form-field class="in-between-fields">
                          <input
                            formControlName="from"
                            class="filter-value"
                            matInput
                            [matDatepicker]="pickerFrom"
                            placeholder="Choose a date"
                            style="
                            border-bottom: none !important;
                            margin: unset !important;
                            padding: unset !important;"
                          />
                          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                          <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="in-between-fields">
                          <input
                            formControlName="to"
                            class="filter-value"
                            matInput
                            [matDatepicker]="pickerTo"
                            placeholder="Choose a date"
                            style="
                            border-bottom: none !important;
                            margin: unset !important;
                            padding: unset !important;"
                          />
                          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                          <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>

              <span class="remove-items" *ngIf="i != 0" (click)="removeItem(i)" title="Remove"> - </span>
            </div>
          </div>
        </div>
        <div class="add-fileds">
          <span (click)="addMoreItem()">
            Add more filter
          </span>
        </div>
        <div class="form-submit">
          <div class="btn-wrp">
            <div (click)="clearValues()" class="clear-filter">
              Clear Values
            </div>
            <button type="submit" [disabled]="!filterForm.valid" [ngClass]="filterForm.valid ? 'active' : 'inactive'">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
