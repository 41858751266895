<div class="openblock consignmentblock">
  <div class="inventorycsvupload-wrp">
    <div class="uploadsheet-wrp">
      <div class="addcsv">
        <div class="addfile">
          <ng-container *ngIf="!uploadedFileDetail">
            <input
              type="file"
              #fileInput
              style="display: none;"
              [accept]="UPLOAD_FILE_CONSTANTS.SUPPORTED_FILE_TYPES.csvsXls"
              (change)="onFileSelected($event)"
            />
            <div class="uploadfile" (click)="openFileInput()">
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/add-csv.png" />
              <input
                type="file"
                #fileInput
                style="display: none;"
                [accept]="UPLOAD_FILE_CONSTANTS.SUPPORTED_FILE_TYPES.csvsXls"
                (change)="onFileSelected($event)"
              />
              Add CSV
            </div>
          </ng-container>
          <ng-container *ngIf="uploadedFileDetail && step === 2">
            <div class="addedcsv">
              <span class="img-wrp">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/file-upload.png" />
              </span>
              <span class="content">
                <b> {{ uploadedFileDetail?.filename }}</b>
                <a (click)="deleteFile()">
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" /> </a
              ></span>
            </div>
          </ng-container>
          <ng-container *ngIf="error === 'noSheet'">
            <span class="fileuploaderror">Please Add a file to Upload it</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
