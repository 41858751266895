<div class="order-export">
  <div class="export-header">
    <div class="left">
      <h1>Export {{ data.type == 'no-scan-return' ? 'reports' : 'orders' }}</h1>
    </div>
    <div class="right">
      <button (click)="closeDialog()">
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-svg.svg" />
      </button>
    </div>
  </div>
  <ng-container *ngIf="loader">
    <div class="web-loader">
      <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
    </div>
  </ng-container>
  <div class="export-body" *ngIf="!loader">
    <div
      class="body-container"
      [ngClass]="isFilterScreen && selectedData?.jobType == 'bq_sale_order_payments_report' ? 'body-margin-below' : ''"
    >
      <ng-container *ngIf="isReportExportStep">
        <div class="step first-step">
          <div class="step-block active">
            <h2>Which report do you want to export?</h2>
            <mat-button-toggle-group class="custom-style-btn full-wid-button" [formControl]="reportExport">
              <div *ngIf="data.type == 'no-scan-return'" class="underline">
                <mat-button-toggle
                  *ngIf="data.type == 'no-scan-return' && data.isDraft && data.isDraft == 'is-Yes'"
                  (change)="onReturnSelect()"
                >
                  <h6>
                    Draft returns matching your filter
                  </h6>
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="data.type == 'no-scan-return' && data.isDraft !== 'is-Yes'"
                  (change)="onReturnSelect()"
                >
                  <h6>
                    Returns matching your filter
                  </h6>
                </mat-button-toggle>
              </div>
              <ng-container *ngIf="templateList && templateList.data">
                <ng-container *ngFor="let data of templateList.data">
                  <div
                    class="button-wrp-rel flex-none"
                    [ngClass]="
                      data?.jobType == 'sale_order_report_v2' && data?.template == 'Sales report' ? 'addcheckbox' : ''
                    "
                  >
                    <mat-button-toggle value="{{ data?.template }}" (change)="onReportExportChange(data)">
                      <h6>
                        {{ data?.template }}
                      </h6>
                      <span class="order-count" *ngIf="data.template != 'Draft returns'">
                        {{ data?.description }}
                      </span>
                      <span class="order-count" *ngIf="data.template == 'Draft returns'">
                        {{ data?.description }}
                        <a
                          class="color-red"
                          href="https://help.eshopbox.com/en/articles/6152273-handling-draft-returns"
                          target="_blank"
                          >Learn more about draft returns</a
                        >
                      </span>
                    </mat-button-toggle>
                    <ng-container>
                      <mat-checkbox
                        class="vkorder-checkbox"
                        *ngIf="data?.jobType == 'sale_order_report_v2' && data?.template == 'Sales report'"
                        [(ngModel)]="isChecked"
                        (change)="toggleCheckbox($event, data)"
                      >
                        Split the virtual kit orders into its component
                        <span class="info-block">
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
                          />
                          <span class="checkbox-info"
                            >If selected, the export of the sales order report will display component-level details for
                            virtual kit orders.</span
                          >
                        </span>
                      </mat-checkbox>
                    </ng-container>
                    <!-- <span
                      class="learn-more"
                      *ngIf="['draft_return_report', 'incorrect_return_item_report'].includes(data?.jobType)"
                    >
                      <a
                        class="learnmore-display"
                        href="https://help.eshopbox.com/en/articles/5327235-export-inward-consignment-report"
                        target="_blank"
                      >
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
                        />

                        <span>
                          Learn more
                        </span>
                      </a>
                    </span> -->
                    <ng-container>
                      <a *ngIf="data?.isUniversal != 1" class="edit-btn" (click)="orderEdit(data)">
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/edit-deail-red-icon.svg"
                        />
                        Edit
                      </a>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </mat-button-toggle-group>
          </div>
          <div class="step-block">
            <h2>Which orders do you want to export?</h2>
          </div>
          <div class="step-block">
            <h2>What filters do you want to apply?</h2>
          </div>
          <div class="step-block">
            <h2>Which fields do you want in report?</h2>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isOrderExportStep">
        <div class="step first-step">
          <div class="step-block done" (click)="activateReportExportStep()">
            <h2>{{ reportExport.value }}</h2>
            <a class="chenge"> Change </a>
          </div>
          <div class="step-block active">
            <h2>Which orders would you like to export?</h2>
            <mat-button-toggle-group class="custom-style-btn" [formControl]="orderExport" value="All">
              <ng-container *ngFor="let orderExport of data.filterType">
                <ng-container *ngIf="accountslug == 'eshop'; else showdatelist">
                  <mat-button-toggle
                    value="{{ orderExport?.name }}"
                    (change)="onOrderExportChange($event.value)"
                    [disabled]="orderExport?.count === 0"
                    style="max-width: 100%"
                    *ngIf="orderExport?.name != 'All orders'"
                  >
                    <h6>
                      {{ orderExport?.name }}
                      <div *ngIf="orderExport.name === 'Orders matching your filters'" class="filter-dropdown">
                        <div class="order-matching-dropdown">
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
                          />
                          <app-order-matching [orderExport]="orderExport"></app-order-matching>
                        </div>
                      </div>
                    </h6>
                    <span class="order-count" *ngIf="orderExport?.count >= 0">
                      {{ orderExport?.count | number }} results
                    </span>
                  </mat-button-toggle>
                </ng-container>
                <ng-template #showdatelist>
                  <mat-button-toggle
                    value="{{ orderExport?.name }}"
                    (change)="onOrderExportChange($event.value)"
                    [disabled]="orderExport?.count === 0"
                    style="max-width: 100%"
                  >
                    <h6>
                      {{ orderExport?.name }}
                      <div *ngIf="orderExport.name === 'Orders matching your filters'" class="filter-dropdown">
                        <div class="order-matching-dropdown">
                          <img
                            src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
                          />
                          <app-order-matching [orderExport]="orderExport"></app-order-matching>
                        </div>
                      </div>
                    </h6>
                    <span class="order-count" *ngIf="orderExport?.count >= 0">
                      {{ orderExport?.count | number }} results
                    </span>
                  </mat-button-toggle>
                </ng-template>
              </ng-container>
            </mat-button-toggle-group>
          </div>
          <div class="step-block">
            <h2>What filters do you want to apply?</h2>
          </div>
          <div class="step-block">
            <h2>Which fields do you want in report?</h2>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isNotClrDtFilters">
        <div class="step first-step" [ngClass]="isOrderCreatedRang ? '' : 'hideBlock'">
          <ng-container *ngIf="externalNavigation; else deafultReportStep">
            <div class="step-block">
              <h2 class="color-gray">{{ reportExport.value }}</h2>
            </div>
          </ng-container>
          <ng-template #deafultReportStep>
            <div class="step-block done" (click)="activateReportExportStep()">
              <h2>{{ reportExport.value }}</h2>
              <a class="chenge"> Change </a>
            </div>
          </ng-template>
          <ng-container *ngIf="['sale_order_report_v2', 'payments'].includes(jobType)">
            <div class="step-block done" (click)="activateOrderExportStep()">
              <h2>{{ orderExport.value }}</h2>
              <a class="chenge"> Change </a>
            </div>
          </ng-container>
          <div class="step-block active filter-scroll">
            <h2 [ngClass]="['draft_return_report'].includes(jobType) ? 'filter-option' : ''">
              What filters do you want to apply?
            </h2>
            <p *ngIf="!['all_returns_virtual_kit', 'all_draft_return'].includes(jobType)" class="field-report">
              You are required to select any one of the date field.
            </p>
            <app-export-date-range-filters
              [jobType]="jobType"
              (selDateFilterEvt)="selDateFilterEvt($event)"
              class="filter-date-width"
            >
            </app-export-date-range-filters>
          </div>
          <div class="step-block">
            <h2>Which fields do you want in report?</h2>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isSelectFields">
        <div class="step first-step">
          <div class="step-block done" (click)="activateReportExportStep()">
            <h2>{{ reportExport.value }}</h2>
            <a class="chenge"> Change </a>
          </div>
          <ng-container *ngIf="['sale_order_report_v2', 'payments'].includes(jobType)">
            <div class="step-block done" (click)="activateOrderExportStep()">
              <h2 class="d-flex">
                {{ orderExport.value }}
                <ng-container *ngFor="let orderExports of data.filterType">
                  <ng-container
                    *ngIf="
                      orderExport.value === 'Orders matching your filters' &&
                      orderExports.name === 'Orders matching your filters' &&
                      (orderExports.details | json) != '{}'
                    "
                  >
                    <div class="filter-dropdown">
                      <div class="order-matching-dropdown">
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
                        />
                        <app-order-matching [orderExport]="orderExports"></app-order-matching>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </h2>
              <a class="chenge"> Change </a>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              orderExport.value === 'All orders' ||
              objectKeys(selDateFilters).length ||
              (objectKeys(selDateFilters).length == 0 && jobType == 'draft_return_report')
            "
          >
            <div class="step-block done">
              <div
                class="view-more"
                *ngIf="objectKeys(selDateFilters).length == 0 && jobType == 'draft_return_report'; else elFilter"
              >
                <h2 (click)="activateOrderRange()">Filters: None</h2>
              </div>
              <ng-template #elFilter>
                <div class="view-more">
                  <ng-container *ngFor="let data of selDateFilters | keyvalue: originalOrder; let i = index">
                    <ng-container *ngIf="i == 0">
                      <h2 (click)="activateOrderRange()">{{ data.value.displayKey }}</h2>
                    </ng-container>
                  </ng-container>
                  <div class="more-order" *ngIf="objectKeys(selDateFilters).length >= 2">
                    <a>+{{ objectKeys(selDateFilters).length - 1 }} more</a>
                    <ul>
                      <li *ngFor="let data of selDateFilters | keyvalue: originalOrder; let i = index">
                        <span>{{ data.value.displayKey.split(': ')[0] }}</span>
                        <span class="val">{{ data.value.displayKey.split(': ')[1] }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-template>
              <a class="chenge" (click)="activateOrderRange()"> Change </a>
            </div>
          </ng-container>
          <div
            class="step-block done"
            *ngIf="orderCreatedRangeForm && orderCreatedRangeForm.valid"
            (click)="activateOrderRange()"
          >
            <h2>
              From {{ orderCreatedRangeForm.value.startDate | date: 'd-LLL-yy' }} to
              {{ orderCreatedRangeForm.value.endDate | date: 'd-LLL-yy' }}
            </h2>
            <a class="chenge"> Change </a>
          </div>
          <div
            class="step-block active"
            [ngClass]="
              ['draft_return_report', 'incorrect_return_item_report'].includes(jobType) ? 'draft-wid' : 'max-wid-inc'
            "
          >
            <h2>Which fields do you want in export?</h2>
            <div class="select-wrapper">
              <app-export-columns
                [templateSelected]="templateSelected"
                [selectAllOption]="selectAllOption"
                [columnSelected]="columnSelected"
                (checkBoxStatus)="checkBoxStatusEvent($event)"
              ></app-export-columns>
              <div class="foot">
                <p>
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/red-file-ic.svg" />
                  <a (click)="saveTemplate()"> Save template</a> to use same fields later.
                </p>
              </div>
            </div>
            <div class="btn-wrp">
              <button class="btn primary-btn no-icon" (click)="exportOrder()">Export CSV</button>
              <button
                *ngIf="
                  (orderExport.value &&
                    orderExport.value != 'All orders' &&
                    selectedData?.jobType != 'bq_sale_order_payments_report') ||
                  selectedData?.jobType != 'all_returns_virtual_kit'
                "
                (click)="scheduleStep()"
                class="btn no-icon"
              >
                Schedule report
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isSelectFieldsDirect">
        <div class="step first-step">
          <div class="step-block done">
            <h2 *ngIf="data.type == 'no-scan-return' && data.isDraft && data.isDraft == 'is-Yes'">
              Draft returns matching your filter
            </h2>
            <h2 *ngIf="data.type == 'no-scan-return' && data.isDraft !== 'is-Yes'">Returns matching your filter</h2>
          </div>
          <div
            class="step-block active"
            [ngClass]="
              ['draft_return_report', 'incorrect_return_item_report'].includes(jobType) ? 'draft-wid' : 'max-wid-inc'
            "
          >
            <h2>Which fields do you want in export?</h2>
            <div class="select-wrapper">
              <app-export-columns
                [templateSelected]="templateSelected"
                [selectAllOption]="selectAllOption"
                [columnSelected]="columnSelected"
                (checkBoxStatus)="checkBoxStatusEvent($event)"
              ></app-export-columns>
              <div class="foot">
                <p>
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/red-file-ic.svg" />
                  <a (click)="saveTemplate()"> Save template</a> to use same fields later.
                </p>
              </div>
            </div>
            <div class="btn-wrp">
              <button class="btn primary-btn no-icon" (click)="exportOrder()">Export CSV</button>
              <button
                *ngIf="
                  (orderExport.value &&
                    orderExport.value != 'All orders' &&
                    selectedData?.jobType != 'bq_sale_order_payments_report') ||
                  selectedData?.jobType != 'all_returns_virtual_kit'
                "
                (click)="scheduleStep()"
                class="btn no-icon"
              >
                Schedule report
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isScheduleReport">
        <div class="step first-step">
          <div class="step-block done" (click)="activateReportExportStep()">
            <h2>{{ reportExport.value }}</h2>
            <a class="chenge"> Change </a>
          </div>
          <ng-container *ngIf="['sale_order_report_v2', 'payments'].includes(jobType)">
            <div class="step-block done" (click)="activateOrderExportStep()">
              <h2 class="d-flex">
                {{ orderExport.value }}
                <ng-container *ngFor="let orderExports of data.filterType">
                  <ng-container
                    *ngIf="
                      orderExport.value === 'Orders matching your filters' &&
                      orderExports.name === 'Orders matching your filters' &&
                      (orderExports.details | json) != '{}'
                    "
                  >
                    <div class="filter-dropdown">
                      <div class="order-matching-dropdown">
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg"
                        />
                        <app-order-matching [orderExport]="orderExports"></app-order-matching>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </h2>
              <a class="chenge"> Change </a>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              orderExport.value === 'All orders' ||
              objectKeys(selDateFilters).length ||
              (objectKeys(selDateFilters).length == 0 && jobType == 'draft_return_report')
            "
          >
            <div class="step-block done">
              <div
                class="view-more"
                *ngIf="objectKeys(selDateFilters).length == 0 && jobType == 'draft_return_report'; else elFilter"
              >
                <h2 (click)="activateOrderRange()">Filters: None</h2>
              </div>
              <ng-template #elFilter>
                <div class="view-more">
                  <ng-container *ngFor="let data of selDateFilters | keyvalue: originalOrder; let i = index">
                    <ng-container *ngIf="i == 0">
                      <h2 (click)="activateOrderRange()">{{ data.value.displayKey }}</h2>
                    </ng-container>
                  </ng-container>
                  <div class="more-order" *ngIf="objectKeys(selDateFilters).length >= 2">
                    <a>+{{ objectKeys(selDateFilters).length - 1 }} more</a>
                    <ul>
                      <li *ngFor="let data of selDateFilters | keyvalue: originalOrder; let i = index">
                        <span>{{ data.value.displayKey.split(': ')[0] }}</span>
                        <span class="val">{{ data.value.displayKey.split(': ')[1] }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-template>

              <a class="chenge" (click)="activateOrderRange()"> Change </a>
            </div>
          </ng-container>
          <div
            class="step-block done"
            *ngIf="orderCreatedRangeForm && orderCreatedRangeForm.valid"
            (click)="activateOrderRange()"
          >
            <h2>
              From {{ orderCreatedRangeForm.value.startDate | date: 'd-LLL-yy' }} to
              {{ orderCreatedRangeForm.value.endDate | date: 'd-LLL-yy' }}
            </h2>
            <a class="chenge"> Change </a>
          </div>
          <div id="gotoTop2" class="step-block done scroll-mrgn-tp" (click)="activateColumns()">
            <h2>{{ selectedColsLength() }} columns selected for export</h2>
            <a class="chenge"> Change </a>
          </div>
          <app-order-schedule [jobType]="jobType" (scheduleJob)="scheduleApiCall($event)"> </app-order-schedule>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="isTemplateSaved">
  <div class="export-succ">
    <img
      class="check-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/check-circle-green.svg"
    />
    Your template is successfully saved
    <img
      class="cross-ic"
      (click)="closeTemplateSaved()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>

<ng-container *ngIf="isTemplateDeleted">
  <div class="export-succ">
    <img
      class="check-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/check-circle-green.svg"
    />
    Template deleted
    <img
      class="cross-ic"
      (click)="closeTemplateSaved(true)"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>

<ng-container *ngIf="isSelDateErr">
  <div class="export-succ">
    <img
      class="check-ic"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/alert-triangle.svg"
    />
    Atleast one date range filter is mandatory
    <img
      class="cross-ic"
      (click)="closeErr()"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
    />
  </div>
</ng-container>
