// CHECKS IF VALUE IS NULL OR UNDEFINED
export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

// CHECKS IF TYPE IS OBJECT
export function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

// CHECKS IF TYPE IS STRING
export function isString(value: any): boolean {
  return typeof value === 'string' && value !== null;
}

// CHECKS IF TYPE IS ARRAY
export function isArray(value: any): boolean {
  return Array.isArray(value);
}

// DELAY RESPONSE FOR SOME TIME
export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// MATCH EXACT STRING
export function matchExact(r: RegExp, str: string) {
  var match = str.match(r);
  return match && str === match[0];
}

// CHECKS REFERENCE KEY VALUE EXIST
export function CheckForReferenceKeyValueExist(arr, val) {
  if (val != undefined && arr != undefined) {
    if (!isNullOrUndefined(arr[val])) return arr[val];
    else if (!isNullOrUndefined(arr['record']) && !isNullOrUndefined(arr['record'][val])) return arr['record'][val];
    else return '-';
  }
}

/**
 * @description used for get detais if device is mobile or not (less then 900px)
 */
export function isMobileWidth() {
  return window.innerWidth < 901;
}

export function isEmptyObject(obj: object): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}
