<div
  *ngIf="intervalKey === 'Last 7 Days' || intervalKey === 'Last 30 Days' || intervalKey === 'Last 90 Days'"
  class="filter-dropdown "
>
  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
  <div class="dropdown ">
    <p>
      {{
        intervalKey === 'Last 7 Days'
          ? 'This includes data for the schedule day/time and the previous 6 days'
          : intervalKey === 'Last 30 Days'
          ? 'This includes data for the schedule day/time and the previous 29 days'
          : 'This includes data for the schedule day/time and the previous 89 days'
      }}
    </p>
  </div>
</div>
