import { Action } from '@ngrx/store';

export enum ConsignmentActionTypes {
  GET_CONSIGNMENT_DETAILS = '[Consignment]  Get CON Details',
  GET_CONSIGNMENT_DETAILS_SUCCESS = '[Consignment] Get Details Success',
  GET_CONSIGNMENT_DETAILS_FAILURE = '[Consignment] Get Details Failure',
  GET_GRN_DETAILS = '[Consignment]  Get GRN Details',
  GET_GRN_DETAILS_SUCCESS = '[Consignment] Get GRN Details Success',
  GET_GRN_DETAILS_FAILURE = '[Consignment] Get GRN Details Failure',
  FILTER_CONSIGNMENTS = '[Consignment] Filter List',
  FILTER_CONSIGNMENTS_FAILURE = '[Consignment] Filter List Failure',
  FILTER_CONSIGNMENTS_SUCCESS = '[Consignment] Filter List Success',
  SORT_CONSIGNMENTS = '[Consignment] Sort Consignments',
  SORT_CONSIGNMENTS_FAILURE = '[Consignment] Sort Consignments Failure',
  SORT_CONSIGNMENTS_SUCCESS = '[Consignment] Sort Consignments Success',
  // Fetch only active suppliers
  FETCH_PARTYLIST_FAILURE = '[Consignment] Fetch party List Failure',
  FETCH_PARTYLIST_SUCCESS = '[Consignment] Fetch Party Success',
  FETCH_PARTYLIST = '[Consignment]  Fetch Party List',

  // Fetch only billing
  FETCH_WAREHOUSE_FAILURE = '[Consignment] Fetch Warehouse List Failure',
  FETCH_WAREHOUSE_SUCCESS = '[Consignment] Fetch Warehouse Success',
  FETCH_WAREHOUSE = '[Consignment]  Fetch Warehouse List',

  CREATE_CONSIGNMENT = '[Consignment] Create Consignment',
  CREATE_CONSIGNMENT_FAILURE = '[Consignment] Create Consignment Failure',
  CREATE_CONSIGNMENT_SUCCESS = '[Consignment] Create Consignment Success',
  SCHEDULE_CONSIGNMENT = '[Consignment] Schedule Consignment',
  SCHEDULE_CONSIGNMENT_FAILURE = '[Consignment] Schedule Consignment Failure',
  SCHEDULE_CONSIGNMENT_SUCCESS = '[Consignment] Schedule Consignment Success',
  OPEN_SIDEBAR = '[Sidebar] Open',
  CLOSE_SIDEBAR = '[Sidebar] Close',
  CANCEL_CONSIGNMENT = '[Consignment] Cancel',
  CANCEL_CONSIGNMENT_SUCCESS = '[Consignment] Cancel Success',
  CANCEL_CONSIGNMENT_FAILURE = '[Consignment] Cancel Failure',
  GET_TIMESLOTS = '[Consignment] Get Available Timeslots',
  GET_TIMESLOTS_SUCCESS = '[Consignment] Get Available Timeslots Success',
  GET_TIMESLOTS_FAILURE = '[Consignment] Get Available Timeslots Failure',
  CREATE_GRN_REPORT = '[Consignment] Create GRN Report',
  CREATE_GRN_REPORT_SUCCESS = '[Consignment] Create GRN Report Success',
  CREATE_GRN_REPORT_FAILURE = '[Consignment] Create GRN Report Failure',
  GET_GRN_REPORT = '[Consignment] Get GRN Report',
  GET_GRN_REPORT_SUCCESS = '[Consignment] GET GRN Report Success',
  GET_GRN_REPORT_FAILURE = '[Consignment] GET GRN Report Failure',

  // Fetches only ENROLLED warehouses
  GET_WAREHOUSE_LIST = '[Consignment] GET Warehouse List',
  GET_WAREHOUSE_LIST_SUCCESS = '[Consignment] GET Warehouse List Success',
  GET_WAREHOUSE_LIST_FAILURE = '[Consignment] GET Warehouse List Failure',

  // Fetch ALL Warehouse
  GET_ALL_WAREHOUSE = '[Warehouse/Party API] GET ALL Warehouse List',
  GET_ALL_WAREHOUSE_SUCCESS = '[Warehouse/Party API] GET ALL Warehouse List Success',
  GET_ALL_WAREHOUSE_FAILURE = '[Warehouse/Party API] GET ALL Warehouse List Failure',

  // Recall create consignment
  CREATE_RECALL_CONSIGNMENT = '[Recall Consignment] Create Recall Consignment',
  CREATE_RECALL_CONSIGNMENT_SUCCESS = '[Recall Consignment] Create Recall Consignment Success',
  CREATE_RECALL_CONSIGNMENT_FAILURE = '[Recall Consignment] Create Recall Consignment Failure',

  // Recall update consignment
  UPDATE_RECALL_CONSIGNMENT = '[Recall Consignment] Update Recall Consignment',
  UPDATE_RECALL_CONSIGNMENT_SUCCESS = '[Recall Consignment] Update Recall Consignment Success',
  UPDATE_RECALL_CONSIGNMENT_FAILURE = '[Recall Consignment] Update Recall Consignment Failure',

  // Recall consignment details
  GET_RECALL_CONSIGNMENT_DETAILS = '[Recall Consignment] Get Recall Consignment details',
  GET_RECALL_CONSIGNMENT_DETAILS_SUCCESS = '[Recall Consignment] Get Recall Consignment details Success',
  GET_RECALL_CONSIGNMENT_DETAILS_FAILURE = '[Recall Consignment] Get Recall Consignment details Failure',

  // Recall consignment list
  GET_RECALL_CONSIGNMENT_LIST = '[Recall Consignment] Get Recall Consignment list',
  GET_RECALL_CONSIGNMENT_LIST_SUCCESS = '[Recall Consignment] Get Recall Consignment list Success',
  GET_RECALL_CONSIGNMENT_LIST_FAILURE = '[Recall Consignment] Get Recall Consignment list Failure',

  // Recall get available timeslots
  GET_RECALL_TIMESLOTS = '[Recall Consignment] Get Available Timeslots',
  GET_RECALL_TIMESLOTS_SUCCESS = '[Recall Consignment] Get Available Timeslots Success',
  GET_RECALL_TIMESLOTS_FAILURE = '[Recall Consignment] Get Available Timeslots Failure',

  GET_RECALL_GRN_REPORT = '[Recall] Get Recall GRN Report',
  GET_RECALL_GRN_REPORT_SUCCESS = '[Recall] GET Recall GRN Report Success',
  GET_RECALL_GRN_REPORT_FAILURE = '[Recall] GET Recall GRN Report Failure',

  // Recall create export job
  CREATE_RECALL_CONSIGNMENT_EXPORT_JOB = '[Recall] Create Recall Consignment Export Job',
  CREATE_RECALL_CONSIGNMENT_EXPORT_JOB_SUCCESS = '[Recall] Create Recall Consignment Export Job Success',
  CREATE_RECALL_CONSIGNMENT_EXPORT_JOB_FAILURE = '[Recall] Create Recall Consignment Export Job Failure'
}

export class GetConsignmentDetails implements Action {
  readonly type = ConsignmentActionTypes.GET_CONSIGNMENT_DETAILS;
  constructor(public payload: any) {}
}

export class GetConsignmentDetailsSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_CONSIGNMENT_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetConsignmentDetailsFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_CONSIGNMENT_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetGRNDetails implements Action {
  readonly type = ConsignmentActionTypes.GET_GRN_DETAILS;
  constructor(public payload: any) {}
}

export class GetGRNDetailsSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_GRN_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetGRNDetailsFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_GRN_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class fetchPartyList implements Action {
  readonly type = ConsignmentActionTypes.FETCH_PARTYLIST;
}

export class fetchPartyListSuccess implements Action {
  readonly type = ConsignmentActionTypes.FETCH_PARTYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class fetchPartyListFailure implements Action {
  readonly type = ConsignmentActionTypes.FETCH_PARTYLIST_FAILURE;
  constructor(public payload: any) {}
}

/**
 * GET ENROLLED WAREHOUSES
 */
export class fetchWarehouseList implements Action {
  readonly type = ConsignmentActionTypes.FETCH_WAREHOUSE;
}

export class fetchWarehouseListSuccess implements Action {
  readonly type = ConsignmentActionTypes.FETCH_WAREHOUSE_SUCCESS;
  constructor(public payload: any) {}
}

export class fetchWarehouseListFailure implements Action {
  readonly type = ConsignmentActionTypes.FETCH_WAREHOUSE_FAILURE;
  constructor(public payload: any) {}
}

/**
 * GET ALL WAREHOUSES
 */
export class FetchAllWarehouse implements Action {
  readonly type = ConsignmentActionTypes.GET_ALL_WAREHOUSE;
  constructor(public payload?: any) {}
}

export class FetchAllWarehouseSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_ALL_WAREHOUSE_SUCCESS;
  constructor(public payload: any) {}
}

export class FetchAllWarehouseFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_ALL_WAREHOUSE_FAILURE;
  constructor(public payload: any) {}
}

export class CreateConsignment implements Action {
  readonly type = ConsignmentActionTypes.CREATE_CONSIGNMENT;
  constructor(public payload: any) {}
}

export class CreateConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.CREATE_CONSIGNMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateConsignmentFailure implements Action {
  readonly type = ConsignmentActionTypes.CREATE_CONSIGNMENT_FAILURE;
  constructor(public payload: any) {}
}

export class ScheduleConsignment implements Action {
  readonly type = ConsignmentActionTypes.SCHEDULE_CONSIGNMENT;
  constructor(public payload: any) {}
}

export class ScheduleConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.SCHEDULE_CONSIGNMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class ScheduleConsignmentFailure implements Action {
  readonly type = ConsignmentActionTypes.SCHEDULE_CONSIGNMENT_FAILURE;
  constructor(public payload: any) {}
}

export class OpenSidebar implements Action {
  readonly type = ConsignmentActionTypes.OPEN_SIDEBAR;
  constructor(public payload: any) {}
}

export class CloseSidebar implements Action {
  readonly type = ConsignmentActionTypes.CLOSE_SIDEBAR;
  constructor(public payload: any) {}
}

export class FilterConsignments implements Action {
  readonly type = ConsignmentActionTypes.FILTER_CONSIGNMENTS;
  constructor(public payload: any) {}
}

export class FilterConsignmentsSuccess implements Action {
  readonly type = ConsignmentActionTypes.FILTER_CONSIGNMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class FilterConsignmentsFailure implements Action {
  readonly type = ConsignmentActionTypes.FILTER_CONSIGNMENTS_FAILURE;
  constructor(public payload: any) {}
}

export class CancelConsignment implements Action {
  readonly type = ConsignmentActionTypes.CANCEL_CONSIGNMENT;
  constructor(public payload: any) {}
}

export class CancelConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.CANCEL_CONSIGNMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class CancelConsignmentFailure implements Action {
  readonly type = ConsignmentActionTypes.CANCEL_CONSIGNMENT_FAILURE;
  constructor(public payload: any) {}
}

export class GetTimeslots implements Action {
  readonly type = ConsignmentActionTypes.GET_TIMESLOTS;
  constructor(public payload: any) {}
}

export class GetTimeslotsSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_TIMESLOTS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTimeslotsFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_TIMESLOTS_FAILURE;
  constructor(public payload: any) {}
}

export class CreateGRNReport implements Action {
  readonly type = ConsignmentActionTypes.CREATE_GRN_REPORT;
  constructor(public payload: any) {}
}

export class CreateGRNReportSuccess implements Action {
  readonly type = ConsignmentActionTypes.CREATE_GRN_REPORT_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateGRNReportFailure implements Action {
  readonly type = ConsignmentActionTypes.CREATE_GRN_REPORT_FAILURE;
  constructor(public payload: any) {}
}

export class GetGRNReport implements Action {
  readonly type = ConsignmentActionTypes.GET_GRN_REPORT;
  constructor(public payload: any) {}
}

export class GetGRNReportSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_GRN_REPORT_SUCCESS;
  constructor(public payload: any) {}
}

export class GetGRNReportFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_GRN_REPORT_FAILURE;
  constructor(public payload: any) {}
}

export class GetWarehouseListFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_WAREHOUSE_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class GetWarehouseListSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_WAREHOUSE_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class GetWarehouseList implements Action {
  readonly type = ConsignmentActionTypes.GET_WAREHOUSE_LIST;
}

// export class Warehouse implements Action {
//   readonly type = ConsignmentActionTypes.WAREHOUSE;
// }

// export class WarehouseSuccess implements Action {
//   readonly type = ConsignmentActionTypes.WAREHOUSE_SUCCESS;
//   constructor(public payload: any) {}
// }

// export class WarehouseFail implements Action {
//   readonly type = ConsignmentActionTypes.WAREHOUSE_FAIL;
//   constructor(public payload: any) {}
// }

/**
 * Recall create consignment
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class CreateRecallConsignment implements Action {
  readonly type = ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT;
  constructor(public payload: any) {}
}
export class CreateRecallConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class CreateRecallConsignmentFailure implements Action {
  readonly type = ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Recall update consignment
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class UpdateRecallConsignment implements Action {
  readonly type = ConsignmentActionTypes.UPDATE_RECALL_CONSIGNMENT;
  constructor(public payload: any) {}
}
export class UpdateRecallConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.UPDATE_RECALL_CONSIGNMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateRecallConsignmentFailure implements Action {
  readonly type = ConsignmentActionTypes.UPDATE_RECALL_CONSIGNMENT_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Get recall consignment details
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class GetRecallConsignmentDetails implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_DETAILS;
  constructor(public payload: any) {}
}
export class GetRecallConsignmentDetailsSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetRecallConsignmentDetailsFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Get recall consignment list
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class GetRecallConsignmentList implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_LIST;
  constructor(public payload: any) {}
}
export class GetRecallConsignmentListSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_LIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetRecallConsignmentListFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_CONSIGNMENT_LIST_FAILURE;
  constructor(public payload: any) {}
}
export class GetRecallTimeslots implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_TIMESLOTS;
  constructor(public payload: any) {}
}

export class GetRecallTimeslotsSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_TIMESLOTS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetRecallTimeslotsFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_TIMESLOTS_FAILURE;
  constructor(public payload: any) {}
}

//GRN for Recall
export class GetRecallGRNReport implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_GRN_REPORT;
  constructor(public payload: any) {}
}

export class GetRecallGRNReportSuccess implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_GRN_REPORT_SUCCESS;
  constructor(public payload: any) {}
}

export class GetRecallGRNReportFailure implements Action {
  readonly type = ConsignmentActionTypes.GET_RECALL_GRN_REPORT_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Export Job
 */
export class CreateRecallConsignmentExportJob implements Action {
  readonly type = ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_EXPORT_JOB;
  constructor(public payload: any) {}
}

export class CreateRecallConsignmentExportJobSuccess implements Action {
  readonly type = ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_EXPORT_JOB_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateRecallConsignmentExportJobFailure implements Action {
  readonly type = ConsignmentActionTypes.CREATE_RECALL_CONSIGNMENT_EXPORT_JOB_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type ConsignmentActionsUnion =
  | GetConsignmentDetails
  | GetConsignmentDetailsSuccess
  | GetConsignmentDetailsFailure
  | fetchPartyList
  | fetchPartyListSuccess
  | fetchPartyListFailure
  | fetchWarehouseList
  | fetchWarehouseListSuccess
  | fetchWarehouseListFailure
  | GetGRNDetails
  | GetGRNDetailsSuccess
  | GetGRNDetailsFailure
  | CreateConsignment
  | CreateConsignmentSuccess
  | CreateConsignmentFailure
  | ScheduleConsignment
  | ScheduleConsignmentSuccess
  | ScheduleConsignmentFailure
  | OpenSidebar
  | CloseSidebar
  | FilterConsignments
  | FilterConsignmentsSuccess
  | FilterConsignmentsFailure
  | CancelConsignment
  | CancelConsignmentSuccess
  | CancelConsignmentFailure
  | GetTimeslots
  | GetTimeslotsSuccess
  | GetTimeslotsFailure
  | CreateGRNReport
  | CreateGRNReportSuccess
  | CreateGRNReportFailure
  | GetGRNReport
  | GetGRNReportSuccess
  | GetGRNReportFailure
  | GetWarehouseListSuccess
  | GetWarehouseListFailure
  | GetWarehouseList
  | CreateRecallConsignment
  | CreateRecallConsignmentSuccess
  | CreateRecallConsignmentFailure
  | UpdateRecallConsignment
  | UpdateRecallConsignmentSuccess
  | UpdateRecallConsignmentFailure
  | GetRecallConsignmentDetails
  | GetRecallConsignmentDetailsSuccess
  | GetRecallConsignmentDetailsFailure
  | GetRecallConsignmentList
  | GetRecallConsignmentListSuccess
  | GetRecallConsignmentListFailure
  | GetRecallTimeslots
  | GetRecallTimeslotsSuccess
  | GetRecallTimeslotsFailure
  | FetchAllWarehouse
  | FetchAllWarehouseSuccess
  | FetchAllWarehouseFailure
  | GetRecallGRNReport
  | GetRecallGRNReportFailure
  | GetRecallGRNReportSuccess
  | CreateRecallConsignmentExportJob
  | CreateRecallConsignmentExportJobSuccess
  | CreateRecallConsignmentExportJobFailure;
