import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppInstallationService } from '../service/app-install.service';
import { AuthService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-install-auhtorize-url',
  templateUrl: './app-install-authorize-url.component.html'
  // styleUrls: ['./install-app-page.component.scss']
})
export class InstallAuthorizeUrlComponent implements OnInit {
  cilentId: any;
  isLoading: boolean;
  accountSlug: any;

  constructor(
    private route: ActivatedRoute,
    private appInstallationService: AppInstallationService,
    private authService: AuthService
  ) {
    this.route.params.subscribe((data: Params) => {
      if (data) {
        this.cilentId = data.cilentId;
      }
    });
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.cilentId !== null && this.cilentId !== '' && this.cilentId !== undefined) {
      this.appInstallationService.getAppAuthorizeUrl(this.cilentId).subscribe(data => {
        this.accountSlug = this.authService.getAccountSlug();
        let url = data['authorizeUrl'];
        if (this.accountSlug !== null) {
          let urlNew;
          if (environment.BASE_DOMAIN == '.myeshopbox.com') {
            urlNew = url.replace('https://auth.myeshopbox', 'https://' + this.accountSlug + '.myeshopbox');
          } else {
            urlNew = url.replace('https://auth.auperator', 'https://' + this.accountSlug + '.auperator');
          }
          window.open(urlNew, '_self');
        } else {
          window.open(url, '_self');
        }

        this.isLoading = false;
      });
    }
  }
}
