import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageKit',
  pure: false
})
export class ImageKitPipe implements PipeTransform {
  transform(
    imageUrl: any,
    prefix: any = 'https://ik.imagekit.io/eshopbox/workspace/',
    suffix: any = 'tr=w-300,h-300,cm-pad_resize'
  ): any {
    // These domain image will be fetched directly from the source
    let urlObj: any;
    if (!imageUrl || imageUrl == 'https://' || imageUrl === null) {
      return this.defaultImage();
    }
    if (imageUrl) urlObj = new URL(imageUrl);
    const exemptedDomain = ['admintcns.wforwoman.com', 'media.wforwoman.com'];
    if (imageUrl && urlObj && exemptedDomain.includes(urlObj.hostname)) return imageUrl;
    let finalImage: any = '';
    if (imageUrl != undefined && imageUrl != null && imageUrl != '') {
      let decodedUrl = decodeURI(imageUrl);
      if (decodedUrl.includes('ik.imagekit.io')) return decodedUrl;
      if (decodedUrl.includes('?dl=0')) {
        decodedUrl = decodedUrl.replace('?dl=0', '?dl=1');
      }
      finalImage = prefix + decodedUrl + (decodedUrl.includes('?') ? '&' : '?') + suffix;
    } else {
      finalImage = this.defaultImage();
    }
    return finalImage;
  }

  defaultImage() {
    return 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/alt-img.png?updatedAt=1621954767891';
  }
}
