import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { AppInstallReducer } from './store/reducers/app-install.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppInstallEffects } from './store/effects/app-install.effect';
import { AppInstallationComponent } from './app-installation.component';
import { InstallPageComponent } from './install-app-page/install-app-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstallAuthorizeUrlComponent } from './app-install-authorize-url/app-install-authorize-url.component';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('appInstall', AppInstallReducer),
    MatProgressSpinnerModule,
    EffectsModule.forFeature([AppInstallEffects]),
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule
  ],
  declarations: [AppInstallationComponent, InstallPageComponent, InstallAuthorizeUrlComponent],
  exports: [AppInstallationComponent, InstallPageComponent, InstallAuthorizeUrlComponent]
})
export class AppInstallationModule {}
