import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent {
  @Input() sortList: any;
  @Output() sortbyKey = new EventEmitter();
  showShortingList: boolean = false;
  sort(value) {
    this.sortbyKey.emit(value);
  }
  clickShort() {
    this.showShortingList = !this.showShortingList;
  }
}
