<div class="filter-dropdown">
  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
  <div class="dropdown" *ngIf="(productExport.details | json) != '{}'">
    <ng-container *ngFor="let filter of productExport.details | keyvalue">
      <ng-container *ngIf="filter?.value?.displayKey == 'Sales Channel'">
        <span class="tag">
          {{ filter.value.displayKey }} <i> {{ filter.value.condition }} </i>
          <em
            [outerHTML]="
              filterHelperService.getFilterValue(filter.value.value, 'saleChannel', salesChannelData, enrolledFCData)
                | spaceAfterComma
            "
          >
          </em>
        </span>
      </ng-container>
      <ng-container *ngIf="filter?.value?.displayKey == 'Location'">
        <span class="tag">
          {{ filter.value.displayKey }} <i> {{ filter.value.condition }} </i>
          <em
            [outerHTML]="
              filterHelperService.getFilterValue(filter.value.value, 'wareHouseCode', salesChannelData, enrolledFCData)
                | spaceAfterComma
            "
          >
          </em>
        </span>
      </ng-container>
      <ng-container *ngIf="filter?.value?.displayKey != 'Sales Channel' && filter?.value?.displayKey != 'Location'">
        <span class="tag">
          {{ filter.value.displayKey }} <i> {{ filter.value.condition }} </i>
          <ng-container *ngFor="let filterVal of filter.value.value">
            <em>{{ filterVal }} </em>
          </ng-container>
        </span>
      </ng-container>
    </ng-container>
  </div>
</div>
