import { Action } from '@ngrx/store';
import { Segment } from 'src/app/shared/models/segment-with-count.model';

export enum SegmentActionTypes {
  GET_NEW_SEGMENTS_DATA = '[SEGMENT] Get Segment Data',
  GET_NEW_SEGMENTS_DATA_SUCCESS = '[SEGMENT] Get Segment Data Success',
  GET_NEW_SEGMENTS_DATA_FAILURE = '[SEGMENT] Get Segment Data Failure',

  SAVE_SEGMENT = '[SEGMENT] Save Segment',
  SAVE_SEGMENT_SUCCESS = '[SEGMENT] Save Segment Success',
  SAVE_SEGMENT_FAILURE = '[SEGMENT] Save Segment Failure',

  EDIT_SEGMENT = '[SEGMENT] Edit Segment',
  EDIT_SEGMENT_SUCCESS = '[SEGMENT] Edit Segment Success',
  EDIT_SEGMENT_FAILURE = '[SEGMENT] Edit Segment Failure',

  DELETE_SEGMENT = '[SEGMENT] Delete Segment',
  DELETE_SEGMENT_SUCCESS = '[SEGMENT] Delete Segment Success',
  DELETE_SEGMENT_FAILURE = '[SEGMENT] Delete Segment Failure',

  SAVE_MODULE_SEGMENTS = '[SEGMENT] Save Module Segment',
  UPDATE_MODULE_SEGMENTS = '[SEGMENT] Update Module Segment',
  DELETE_MODULE_SEGMENTS = '[SEGMENT] Delete Module Segment',
  ADD_NEW_MODULE_SEGMENTS = '[SEGMENT] Add New Module Segment',

  GET_FILTERS_DATA = '[SEGMENT] Get Filters Data',
  GET_FILTERS_DATA_SUCCESS = '[SEGMENT] Get Filters Data Success',
  GET_FILTERS_DATA_FAILURE = '[SEGMENT] Get Filters Data Failure',

  GET_NO_SCAN_RETURN_COUNT_DATA = '[SEGMENT] Get Return Count Data',
  GET_NO_SCAN_RETURN_COUNT_DATA_SUCCESS = '[SEGMENT] Get Return Count Data Success',
  GET_NO_SCAN_RETURN_COUNT_DATA_FAILURE = '[SEGMENT] Get Return Count Data Failure',

  GET_ALL_ORDERS_COUNT_DATA = '[SEGMENT] Get Orders Count Data',
  GET_ALL_ORDERS_COUNT_DATA_SUCCESS = '[SEGMENT] Get Orders Count Data Success',
  GET_ALL_ORDERS_COUNT_DATA_FAILURE = '[SEGMENT] Get Orders Count Data Failure',

  GET_NO_SCAN_RETURN_FILTERS = '[SEGMENT] Get No scan return Filters Data',
  GET_NO_SCAN_RETURN_FILTERS_SUCCESS = '[SEGMENT] Get No scan return Filters Data Success',
  GET_NO_SCAN_RETURN_FILTERS_FAILURE = '[SEGMENT] Get No scan return Filters Data Failure'
}

export class GetNewSegmentsData implements Action {
  readonly type = SegmentActionTypes.GET_NEW_SEGMENTS_DATA;
  constructor() {}
}

export class GetNewSegmentsDataSuccess implements Action {
  readonly type = SegmentActionTypes.GET_NEW_SEGMENTS_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class GetNewSegmentsDataFailure implements Action {
  readonly type = SegmentActionTypes.GET_NEW_SEGMENTS_DATA_FAILURE;
  constructor() {}
}

export class GetAllOrderSegmentCount implements Action {
  readonly type = SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA;
  constructor(public payload: any) {}
}

export class GetAllOrderSegmentCountSuccess implements Action {
  readonly type = SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA_SUCCESS;
  constructor(public payload: { filterQuery: any; operation: 'replace' | 'add'; countData: any }) {}
}

export class GetAllOrderSegmentCountFailure implements Action {
  readonly type = SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA_FAILURE;
  constructor(public payload: any) {}
}

export class SaveSegment implements Action {
  readonly type = SegmentActionTypes.SAVE_SEGMENT;
  constructor(public payload: any) {}
}

export class SaveSegmentSuccess implements Action {
  readonly type = SegmentActionTypes.SAVE_SEGMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class SaveSegmentFailure implements Action {
  readonly type = SegmentActionTypes.SAVE_SEGMENT_FAILURE;
  constructor(public payload: any) {}
}

export class EditSegment implements Action {
  readonly type = SegmentActionTypes.EDIT_SEGMENT;
  constructor(public payload: any) {}
}
export class EditSegmentSuccess implements Action {
  readonly type = SegmentActionTypes.EDIT_SEGMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class EditSegmentFailure implements Action {
  readonly type = SegmentActionTypes.EDIT_SEGMENT_FAILURE;
  constructor(public payload: any) {}
}
export class DeleteSegment implements Action {
  readonly type = SegmentActionTypes.DELETE_SEGMENT;
  constructor(public payload: any) {}
}
export class DeleteSegmentSuccess implements Action {
  readonly type = SegmentActionTypes.DELETE_SEGMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteSegmentFailure implements Action {
  readonly type = SegmentActionTypes.DELETE_SEGMENT_FAILURE;
  constructor(public payload: any) {}
}

export class GetNoScanReturnFiltersData implements Action {
  readonly type = SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS;
  constructor() {}
}
export class GetNoScanReturnFiltersSuccess implements Action {
  readonly type = SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetNoScanReturnFiltersFailure implements Action {
  readonly type = SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS_FAILURE;
  constructor(public payload: any) {}
}

export class GetNoScanReturnSegmentCount implements Action {
  readonly type = SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA;
  constructor(public payload: any) {}
}

export class GetNoScanReturnSegmentCountSuccess implements Action {
  readonly type = SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA_SUCCESS;
  constructor(public payload: { filterQuery: any; operation: 'replace' | 'add'; countData: any }) {}
}

export class GetNoScanReturnSegmentCountFailure implements Action {
  readonly type = SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA_FAILURE;
  constructor(public payload: any) {}
}

export type SegmentUnion =
  | GetNewSegmentsData
  | GetNewSegmentsDataSuccess
  | GetNewSegmentsDataFailure
  | GetAllOrderSegmentCount
  | GetAllOrderSegmentCountSuccess
  | GetAllOrderSegmentCountFailure
  | SaveSegment
  | SaveSegmentSuccess
  | SaveSegmentFailure
  | EditSegment
  | EditSegmentSuccess
  | EditSegmentFailure
  | DeleteSegment
  | DeleteSegmentSuccess
  | DeleteSegmentFailure
  | GetNoScanReturnFiltersData
  | GetNoScanReturnFiltersSuccess
  | GetNoScanReturnFiltersFailure
  | GetNoScanReturnSegmentCount
  | GetNoScanReturnSegmentCountSuccess
  | GetNoScanReturnSegmentCountFailure;
