<div *ngIf="!data.isSubmitBtnClick" class="header_pop_new">
  <h2 mat-dialog-title>
    {{ data.title }}
  </h2>
  <img
    (click)="closeModel(true)"
    mat-dialog-close
    src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
  />
</div>
<mat-divider></mat-divider>
<div class="dialog-body" *ngIf="!data.isSubmitBtnClick">
  <span [innerHTML]="data.description1"></span>
  <span *ngIf="data.description2" [innerHTML]="data.description2"></span>
  <div class="button-wrp">
    <button *ngIf="data.discardBtn.isShow" (click)="cancelDiscardOK('discard')" class="cancel-btn">
      <img
        src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/trash-delete.svg?updatedAt=1636974555139"
      />
      {{ data.discardBtn.Text }}
    </button>

    <button *ngIf="data.holdBtn.isShow" (click)="holdResume()" class="save-button hold_btn">
      <img
        src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/pause.svg?updatedAt=1636974555362"
      />{{ data.holdBtn.Text }}
    </button>
    <button *ngIf="data.cancelBtn.isShow" (click)="cancelDiscardOK('cancel')" class="cancel-btn">
      {{ data.cancelBtn.Text }}
    </button>
    <button *ngIf="data.resumeBtn.isShow" (click)="holdResume()" class="save-button">
      <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/play.svg?updatedAt=1637068331022" />
      {{ data.resumeBtn.Text }}
    </button>
    <button *ngIf="data.okBtn.isShow" (click)="cancelDiscardOK('ok')" class="cancel-btn ok_btn_action">
      {{ data.okBtn.Text }}
    </button>
    <button
      *ngIf="data.discardTrueBtn.isShow && data.discardTrueBtn.discardTrue"
      (click)="discard()"
      class="save-button discard-red"
    >
      <img src="https://ik.imagekit.io/2gwij97w0o/trash-2.svg?updatedAt=1637044471587" />
      {{ data.discardTrueBtn.Text }}
    </button>
  </div>
</div>

<div *ngIf="data.isSubmitBtnClick" class="progress-load">
  <div class="cantainer-inner">
    <div class="authloading-container">
      <div class="authloading"></div>
    </div>
    <span [innerHTML]="data.description1"></span>
  </div>
</div>
