<ng-container *ngIf="faileddeliverycheck">
  <div class="downloadtemplete failed-download">
    <h2>
      1. Select failed delivery action
      <a
        class="l-more"
        target="_blank"
        href="https://help.eshopbox.com/en/articles/6783701-manage-failed-delivery-orders"
        >Learn more</a
      >
    </h2>
    <div class="table-block">
      <p>Select the failed delivery action you want to take for the orders</p>
      <div class="input_div">
        <form [formGroup]="FailedDeliveryForm">
          <mat-form-field>
            <mat-label>Select failed delivery action</mat-label>
            <mat-select
              formControlName="failedDeliveryaction"
              panelClass="custom-select-ui filter-option single-select"
            >
              <mat-option class="option_list" (onSelectionChange)="deliveryevent($event)" value="delivery_reattempt"
                >Re-attempt delivery</mat-option
              >
              <mat-option class="option_list" (onSelectionChange)="deliveryevent($event)" value="rto"
                >Initiate return to origin (RTO)</mat-option
              >
              <mat-option class="option_list" (onSelectionChange)="deliveryevent($event)" value="oda_update"
                >Update pickup address for out of delivery orders</mat-option
              >
              <!-- <mat-option class="option_list" value="ODO">Update pickup address for out of delivery orders</mat-option> -->
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <!-- {{FailedDeliveryForm.value.failedDeliveryaction}} -->
      <div class="table">
        <ul>
          <ng-container *ngIf="FailedDeliveryForm.value.failedDeliveryaction">
            <li class="thead">
              <ng-container *ngFor="let headerData of headers">
                <span>{{ headerData }}</span>
              </ng-container>
            </li>
            <ul>
              <ng-container
                *ngIf="
                  FailedDeliveryForm.value.failedDeliveryaction == 'rto' ||
                  FailedDeliveryForm.value.failedDeliveryaction == 'delivery_reattempt' ||
                  FailedDeliveryForm.value.failedDeliveryaction == 'oda_update'
                "
              >
                <li>
                  <span>Forward tracking ID </span>
                  <span>Enter the tracking number used to track the order mentioned on the shipping label</span>
                </li>
              </ng-container>
              <ng-container *ngIf="FailedDeliveryForm.value.failedDeliveryaction == 'delivery_reattempt'">
                <li>
                  <span>Phone number</span>
                  <span
                    >Enter the updated phone number on which the courier partner can connect with the customer.</span
                  >
                </li>
                <li>
                  <span>Address</span>
                  <span
                    >Enter the updated delivery address of the customer. You can only update the address and cannot
                    update the pincode.</span
                  >
                </li>
                <li>
                  <span>Landmark</span>
                  <span
                    >Enter the landmark. This will help the courier partner to easily identify the delivery
                    address.</span
                  >
                </li>
                <li>
                  <span>Additional Delivery Notes</span>
                  <span>Enter additional delivery notes.</span>
                </li>
              </ng-container>

              <ng-container *ngIf="FailedDeliveryForm.value.failedDeliveryaction == 'oda_update'">
                <li>
                  <span>Pick up address</span>
                  <span
                    >Enter the address of the courier partner hub from where the customer can pick up the order.</span
                  >
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </ul>
      </div>
      <div class="field-text">
        <p>
          When using this template to create your own import file, make sure to remove all the sample data before saving
          the file as .csv.
        </p>
      </div>
      <div class="downloadbtnblock">
        <div class="col-left">
          <ng-container *ngIf="accountBatchingTemplate">
            <a [href]="accountBatchingTemplate" download>
              Download template
              <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
            </a>
          </ng-container>

          <ng-container *ngIf="!accountBatchingTemplate">
            <ng-container>
              <a [href]="templateLinkNew" [class.disable]="!templateLinkNew" download>
                Download template
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!faileddeliverycheck">
  <div class="downloadtemplete">
    <h2>
      1. Download template
      <a *ngIf="href" class="l-more" target="_blank" [href]="href">Learn more</a>
    </h2>
    <div class="table" [ngClass]="{ returns: downloadFor == 'order-import' }">
      <ul>
        <li class="thead">
          <ng-container *ngFor="let headerData of headers">
            <span>{{ headerData }}</span>
          </ng-container>
        </li>
        <ul>
          <ng-container *ngFor="let bodyData of body">
            <li>
              <span>{{ bodyData.field }}</span>
              <span>{{ bodyData.description }}</span>
            </li>
          </ng-container>
        </ul>
      </ul>
    </div>
    <div class="field-text" *ngIf="downloadFor !== 'order-import' && !packaging && downloadFor != 'default'">
      <p>
        Want to store more information specific to your business? <br />
        <a (click)="showPopUp()"> Create a custom field </a> and it will be added in product template.
      </p>
    </div>
    <div class="field-text" *ngIf="packaging || downloadFor == 'default'">
      <p>
        When using this template to create your own import file, make sure to remove all the sample data before saving
        the file as .csv.
      </p>
    </div>
    <!-- <div class="field-text" *ngIf="downloadFor !== 'order-import'">
    <p>
      When using this template to create your own import file, make sure to remove all the sample data before saving the
      file as .csv.
    </p>
  </div> -->

    <div class="downloadbtnblock">
      <div class="col-left">
        <ng-container *ngIf="merge_product; else otherTypes">
          <a [href]="templateLink" download>
            Download template
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
          </a>
        </ng-container>
        <ng-template #otherTypes>
          <ng-container *ngIf="accountBatchingTemplate">
            <ng-container *ngIf="packaging">
              <a (click)="download()" [class.disable]="!templateLinkLoader">
                Download template
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
              </a>
            </ng-container>
            <ng-container *ngIf="virtualkitproduct && !packaging">
              <a (click)="download()" [class.disable]="!templateLinkLoader || templateLink == undefined">
                Download template
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
              </a>
            </ng-container>
            <ng-container *ngIf="!virtualkitproduct && !packaging">
              <a (click)="download()" [class.disable]="!templateLinkLoader || templateLink == undefined">
                <!-- <a [href]="accountBatchingTemplate" download> -->
                Download template
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
              </a>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!accountBatchingTemplate">
            <ng-container *ngIf="isArray()">
              <a (click)="download()" [class.disable]="!templateLinkLoader || templateLink == undefined">
                Download template
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
              </a>
            </ng-container>
            <ng-container *ngIf="!isArray()">
              <a [href]="templateLink" download>
                Download template
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-icon-red2.svg" />
              </a>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
