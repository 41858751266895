interface StatusColor {
  status: string;
  chipColor: string;
}

export const statusColors: StatusColor[] = [
  // { status: 'CREATED', chipColor: '#d7ecff' },
  // { status: 'PICKING', chipColor: '#fff6d3' },
  // { status: 'PICKED', chipColor: '#fff6d3' },
  // { status: 'PACKED', chipColor: '#fff6d3' },
  // { status: 'MANIFESTED', chipColor: '#fff6d3' },
  // { status: 'DISPATCHED', chipColor: '#cff0dc' },
  // { status: 'REJECTED', chipColor: '#fdeaea' },
  // { status: 'HOLD', chipColor: '#fdeaea' },
  // { status: 'SIDELINED', chipColor: '#fdeaea' },
  // { status: 'SIDELINED ON PACK', chipColor: '#fdeaea' },
  // { status: 'SIDELINED ON HANDOVER', chipColor: '#fdeaea' },
  { status: 'Processing', chipColor: '#D7ECFF' }, //blue
  { status: 'Ready to ship', chipColor: '#D7ECFF' },
  { status: 'Handover done', chipColor: '#CFF0DC' }, //green
  { status: 'Label printed', chipColor: '#CFF0DC' },
  { status: 'Cancelled', chipColor: '#FDEAEA' }, //red
  { status: 'On hold', chipColor: '#FDEAEA' },
  { status: 'Failed to generate', chipColor: '#FDEAEA' },
  { status: 'Lable printed', chipColor: '#CFF0DC' },
  { status: 'Label not printed', chipColor: '#FDEAEA' },
  { status: 'Manifested', chipColor: '#FFF6D3' }, //yellow
  { status: 'Generating', chipColor: '#FFF6D3' },
  { status: 'Fail to handover', chipColor: '#FDEAEA' },
  //return status
  { status: 'Quarantined', chipColor: '#f6eed1' }, //yellow
  { status: 'Damaged', chipColor: '#f9d9d7' }, //red
  { status: 'Lost', chipColor: '#f7d0ae' }, //orange
  { status: 'Restocked', chipColor: '#d7fee6' },
  { status: 'Awaiting pickup', chipColor: '#f6eed1' }, //yellow
  { status: 'On the way', chipColor: '#d7eeff' }, //blue
  { status: 'Return requested', chipColor: '#d7eeff' }, //blue
  { status: 'Delivered', chipColor: '#d7fee6' }, //green
  { status: 'Awaiting approval', chipColor: '#D7ECFF' } //blue
];

export const fieldsHandlbar = ['displayStatus', 'manifestStatus', 'labelStatus'];
