export const VALIDATION_MESSAGES = {
  firstName: [{ type: 'required', message: 'Required' }],
  lastName: [{ type: 'required', message: 'Required' }],
  email: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid email address' }
  ],
  phone: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid mobile number.' }
  ],
  companyName: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Company name cannot include special characters' }
  ],
  productCategory: [{ type: 'required', message: 'Required' }],
  shipOrderQty: [{ type: 'required', message: 'Required' }],
  address: [
    { type: 'required', message: 'Required' },
    { type: 'minlength', message: 'Enter a valid address' }
  ],
  pincode: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid pincode' }
  ],
  gstin: [{ type: 'required', message: 'Required' }],
  gstNumber: [{ type: 'required', message: 'Required' }],
  referralCode: [{ message: 'Entered referral code is invalid. Please try again' }]
};

export const PATTERNS = {
  SUB_DOMAIN: /(?:[\s.])([a-z0-9][a-z0-9-]+[a-z0-9]\.auperator\.co)/,
  SUB_DOMAIN_STRING: /^[a-z0-9_-]+$/,
  // tslint:disable-next-line:max-line-length
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_EXCLUDE_ESHOPBOX: /^(?!.*@eshopbox\.com)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^[6-9]{1}[0-9]{9}$/,
  PAN_CARD: /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}?$/,
  SLUG: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/,
  PINCODE: /^[1-9][0-9]{5}$/,
  NUMBER: /^[0-9]*$/,
  STRING: /^[A-Za-z]/,
  URL: /\./,
  IFSC: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
  GSTIN: /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
  DISALLOW_SPECIAL_CHARACTERS: '^[a-zA-Z0-9-. ]*$'
};

export const SHIP_ORDER_QUANTITY_OPTIONS = [
  { label: 'Less than 1000 items', value: 'less than 1000', id: 'label1' },
  { label: '1000 to 3000 items', value: '1000 to 3000 items', id: 'label2' },
  { label: '3000 to 10000 items', value: '3000 to 10000 items', id: 'label3' },
  { label: '10000 to 25000 items', value: '10000 to 25000 items', id: 'label4' },
  { label: 'more than 25000 items', value: 'more than 25000 items', id: 'label5' },
  { label: 'I am just getting started', value: 'new', id: 'label6' }
];

export const PRODUCT_CATEGORY_OPTIONS = [
  {
    id: 'img1',
    label: 'Apparel, Footwear & Accessories',
    value: 'apparel, foorwear & accessories',
    icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/shorts-tshirts.svg',
    checked: false
  },
  {
    id: 'img2',
    label: 'Health & Personal Care',
    value: 'Health & personal care',
    icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/beg.svg?updatedAt=1621946165965',
    checked: false
  },
  {
    id: 'img3',
    label: 'Beauty & Cosmetics',
    value: 'beauty & cosmetics',
    icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/beauty.svg',
    checked: false
  },
  {
    id: 'img4',
    label: 'Consumer Electronics',
    value: 'Consumer electronic',
    icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/elecronic.svg',
    checked: false
  },
  {
    id: 'img5',
    label: 'Home Furnishings',
    value: 'home furnishings',
    icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/furnishing.svg',
    checked: false
  },
  {
    id: 'img6',
    label: 'Others',
    value: 'others',
    icon: 'https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/other.svg',
    checked: false
  }
];

export const CAROUSEL_IMG_LIST = [
  'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/default-carousel.png?updatedAt=1714391016390',
  'https://assets-global.website-files.com/5ef15d131a03d2a52d8075ad/61e688cd4d9e2e9ecc02d5cd_Img%203-p-800.jpeg',
  'https://assets-global.website-files.com/5ef15d131a03d2a52d8075ad/61e56acd35ed35ead79a7367_Group%209531-2-p-800.jpeg'
];

export const BREADCRUMBS = [
  { label: 'Pick a plan', url: '/auth/plans' },
  { label: 'Make payment', url: '/auth/billing-details' }
];

export const ESB_SIGNUP_URLS = {
  signup: '/auth/signup',
  plans: '/auth/plans',
  billingDetails: '/auth/billing-details'
};

export const WORDS_TO_REMOVE_FROM_COMPANY_NAME = [
  'Pvt',
  'Pvt.',
  'Limited',
  'Private',
  'Ltd',
  'Ltd.',
  'LLP',
  'ltd',
  'ltd.'
];

export const ICONS_LIST = {
  couponIcon:
    'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/iconamoon_discount-light.svg?updatedAt=1720529285632',
  truckIcon: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/truck.svg?updatedAt=1720784227047',
  shippingIcon: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/shipping_icon.svg?updatedAt=1720784289380',
  fulfillmentIcon: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/fulfillment.svg?updatedAt=1720784327961',
  multiChannelSync:
    'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/multi_channel_sync.svg?updatedAt=1720784378744',
  wms: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/wms.svg?updatedAt=1720784431014',
  paymentReconciliation:
    'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/payment_reconcilation.svg?updatedAt=1720784486875',
  externalIcon: 'https://ik.imagekit.io/2gwij97w0o/external_grey.svg?updatedAt=1709633345428',
  alertIcon: 'https://ik.imagekit.io/2gwij97w0o/alert-triangle%20(1).svg?updatedAt=1715669467105'
};

export const REDIRECTION_LINKS = {
  termsAndConditions: 'https://help.eshopbox.com/en/collections/2441904-eshopbox-terms-and-policies',
  serviceAgreementTerms: 'https://help.eshopbox.com/en/articles/4321435-eshopbox-terms-of-service-agreement',
  privacyPolicy: 'https://help.eshopbox.com/en/articles/4288688-eshopbox-privacy-policy'
};
