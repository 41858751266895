import { Action } from '@ngrx/store';

export enum BuildingBlockActionsTypes {
  // get settings API for workspace
  GET_BB_WORKFLOW_SETTINGS_DATA = '[BBSETTINGS] Get Workspace Settings Data',
  GET_BB_WORKFLOW_SETTINGS_DATA_SUCCESS = '[BBSETTINGS] Get Workspace Settings Data Success',
  GET_BB_WORKFLOW_SETTINGS_DATA_FAILURE = '[BBSETTINGS] Get Workspace Settings Data Failure'
}

export class GetWorkflowSettingsData implements Action {
  readonly type = BuildingBlockActionsTypes.GET_BB_WORKFLOW_SETTINGS_DATA;
  constructor() {}
}

export class GetWorkflowSettingsDataSuccess implements Action {
  readonly type = BuildingBlockActionsTypes.GET_BB_WORKFLOW_SETTINGS_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class GetWorkflowSettingsDataFailure implements Action {
  readonly type = BuildingBlockActionsTypes.GET_BB_WORKFLOW_SETTINGS_DATA_FAILURE;
  constructor(public payload: any) {}
}

export type BuildingBlockActionsList =
  | GetWorkflowSettingsData
  | GetWorkflowSettingsDataSuccess
  | GetWorkflowSettingsDataFailure;
