<section class="setting-page-main">
  <div class="page-top-space"></div>
  <div class="setting-otr">
    <div class="setting-innr">
      <div class="setting-left-menu">
        <div class="setting-header">
          <div class="pg-heading">
            <h2>Settings</h2>
          </div>
          <div class="setting-input-wrapper">
            <input name="search" placeholder="Search" type="search" [(ngModel)]="searchsetting" name="searchsetting" />
            <span *ngIf="searchsetting">
              <img
                src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg?updatedAt=1621919969860"
                alt="cross"
                class="cross-icon"
                (click)="removeSearch()"
              />
            </span>
          </div>
        </div>
        <div class="menu-scroll">
          <ng-container *ngIf="(SETTINGROUTES['setting'] | searchFilter: searchsetting:'item').length === 0">
            <div class="no-search">
              <span class="icon-search-found"
                ><img
                  src="https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/not-found.svg?updatedAt=1704451723227"
                  alt=""
              /></span>
              <p>No results found</p>
              <span>Please try different keyword</span>
            </div>
          </ng-container>
          <div
            class="menu-items-otr"
            *ngFor="let item of SETTINGROUTES['setting'] | searchFilter: searchsetting:'item'; let in = index"
          >
            <div class="heading" *ngIf="in == 0 || item.subParent != SETTINGROUTES['setting'][in - 1].subParent">
              <h2>{{ item?.subParent }}</h2>
            </div>
            <div class="mennu-items-inner">
              <ul>
                <ng-container *ngIf="item.urlType == 'WORKSPACE' && !item.hiddenInWorkspace.includes(accountSlug)">
                  <ng-container *ngIf="item.hasAccess">
                    <ng-container *ngTemplateOutlet="hasWSAccessTemplate"></ng-container>
                  </ng-container>
                  <ng-container *ngIf="!item.hasAccess">
                    <ng-container *ngTemplateOutlet="noAccessTemplate"></ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="item.urlType == 'FLEX' && !item.hiddenInWorkspace.includes(accountSlug)">
                  <ng-container *ngIf="hasLocation">
                    <ng-container *ngIf="hasLocationAccess && item.hasAccess">
                      <ng-container *ngTemplateOutlet="hasSFAccessTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!hasLocationAccess || !item.hasAccess">
                      <ng-container *ngTemplateOutlet="noAccessTemplate"></ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!hasLocation">
                    <ng-container *ngTemplateOutlet="noSelfFulfilmentLocationTemplate"></ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #hasWSAccessTemplate>
                  <ng-container *ngIf="item.isVisible">
                    <li
                      [routerLink]="[item.URL]"
                      [queryParams]="item.queryParams"
                      (click)="onClickTab()"
                      [id]="item.name"
                      class="hover-item"
                      [routerLinkActiveOptions]="
                        item.name === 'Customer portal' ? { exact: true } : { queryParams: 'ignored' }
                      "
                      routerLinkActive="active-link-bg"
                      *ngIf="
                        item.name != 'Packaging verification' ||
                        (item.name == 'Packaging verification' && accountSlug == 'eshop' && isAdminPackaging) ||
                        item.name != 'Weight waiver' ||
                        (item.name == 'Weight waiver' && accountSlug == 'eshop' && iswaiverEmail)
                      "
                    >
                      <span class="lft-items">
                        <span class="icon-item"
                          ><img
                            [src]="[
                              item?.icon
                                ? item?.icon
                                : 'https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/custom-fields.svg?updatedAt=1704279414733'
                            ]"
                            alt="menu-icon"
                        /></span>
                        <span class="menu-title">{{ item?.name }}</span>
                      </span>
                      <span class="right_icon"
                        ><img
                          src="https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/left-arrow.svg?updatedAt=1704348868461"
                          alt="menu-icon"
                        />
                      </span></li
                  ></ng-container>
                </ng-template>

                <ng-template #hasSFAccessTemplate>
                  <ng-container *ngIf="item.isVisible">
                    <li
                      [id]="item.name"
                      [ngClass]="{ 'active-link-bg': currentRoute == item.name }"
                      (click)="selectedFC(item.URL, item.name)"
                      class="hover-item"
                    >
                      <span class="lft-items">
                        <span class="icon-item"><img [src]="[item?.icon]" alt="menu-icon"/></span>
                        <span class="menu-title">{{ item?.name }}</span>
                      </span>
                      <span class="right_icon"
                        ><img
                          src="https://ik.imagekit.io/2gwij97w0o/top_nav/setting_leftmenu_icon/left-arrow.svg?updatedAt=1704348868461"
                          alt="menu-icon"
                        />
                      </span></li
                  ></ng-container>
                </ng-template>

                <ng-template #noAccessTemplate>
                  <ng-container *ngIf="item.isVisible">
                    <li
                      [id]="item.name"
                      class="no-location"
                      matTooltipClass="theme-tooltip after"
                      matTooltipPosition="after"
                      matTooltip="You do not have access to {{
                        item?.name
                      }}. Ask any of your team members to give the access."
                    >
                      <span class="lft-items">
                        <span class="icon-item"><img [src]="[item?.icon]" alt="menu-icon"/></span>
                        <span class="menu-title">{{ item?.name }}</span>
                      </span>
                      <span class="right_icon icon_visible">
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/top_nav/lock.svg?updatedAt=1683009560215"
                          alt="lock-icon"
                        />
                      </span></li
                  ></ng-container>
                </ng-template>

                <ng-template #noSelfFulfilmentLocationTemplate>
                  <ng-container *ngIf="item.isVisible">
                    <li
                      [id]="item.name"
                      matTooltipClass="theme-tooltip after"
                      matTooltipPosition="after"
                      matTooltip="You do not have any location enabled for self fulfilment. To use this feature, you need to enable self fulfilment for a location."
                      [routerLink]="['/enabling-self-fulfilment']"
                      routerLinkActive="active-link-bg"
                      (click)="onClickTab()"
                      class="hover-item location-enabled"
                    >
                      <span class="lft-items">
                        <span class="icon-item"><img [src]="[item?.icon]" alt="menu-icon"/></span>
                        <span class="menu-title">{{ item?.name }}</span>
                      </span>
                      <span class="right_icon icon_visible">
                        <img
                          src="https://ik.imagekit.io/2gwij97w0o/top_nav/enable-icon.svg?updatedAt=1682936800414"
                          alt="menu-icon"
                        />
                      </span></li
                  ></ng-container>
                </ng-template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
