import { createSelector } from '@ngrx/store';
import * as fromReducer from './shipping-mode.reducer';
import { AppState } from 'src/app/app.state';

// const getShippingState = (state: AppState) => state.shippingState;
// export const getRuleListALL = createSelector(getShippingState,fromReducer.getRuleList);

export const getShippingState = (state: AppState) => state.shippingState;
export const getRuleListALL = createSelector(getShippingState, fromReducer.getRuleList);
export const getLoader = createSelector(getShippingState, fromReducer.getLoaderstart);
export const getError = createSelector(getShippingState, fromReducer.getError);
export const getstate = createSelector(getShippingState, fromReducer.getstate);
export const getItemById = id =>
  createSelector(getRuleListALL, allItems => {
    // debugger;
    if (allItems) {
      return allItems.find(item => {
        return item.id === id;
      });
    } else {
      return {};
    }
  });

// export const getRuleListALL = createSelector(getShippingState,(state:fromReducer.shippingState)=>state.ruleListData)
