import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs-compat/Subscription';
import { getCurrentLoggedInUserData } from 'src/app/auth/reducers/auth.selectors';
import { ADMIN_EMAIL_ACCESS, ROUTESDATA } from 'src/app/constants/routes';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-not-accessible',
  templateUrl: './not-accessible.component.html',
  styleUrls: ['./not-accessible.component.scss']
})
export class NotAccessibleComponent implements OnInit {
  pathParam: any;
  routeSubscription: any;
  ROUTESDATA = ROUTESDATA;
  workspace: any;
  isMobile: boolean;
  numberOfWorkspace: number;
  hasFlex: any;
  location: any;
  @Input() bbHandleBar: any;
  loggedInUserSubscription$: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private store: Store<{ auth }>
  ) {
    if (this.bbHandleBar) return;
    this.routeSubscription = this.route.url.subscribe(() => {
      const currentModule = this.findRouteURL(this.route.snapshot.params['scope']);
      this.pathParam =
        this.route.snapshot.routeConfig.path == 'enabling-self-fulfilment'
          ? 'enabling-self-fulfilment'
          : currentModule.scopeLabel
          ? currentModule.scopeLabel
          : currentModule;
      if (this.pathParam && this.pathParam.includes('workspace_'))
        this.workspace = this.pathParam.replace('workspace_', '');
      if (this.pathParam && this.pathParam.includes('location_'))
        this.location = this.pathParam.replace('location_', '');
    });
  }
  ngOnInit() {
    this.isMobile = this.authService.isMobileDevice();
    this.numberOfWorkspace = this.authService.getNumberOfWorkspaces();
    this.hasFlex = this.authService.getFlexSlug();
    if (this.route.snapshot.params['scope'] !== 'packaging_admin') {
      this.currentPermisson();
    }
  }

  findRouteURL(scope: string): any | null {
    for (const key in this.ROUTESDATA) {
      if (Array.isArray(this.ROUTESDATA[key])) {
        const module = this.ROUTESDATA[key].find((entry: any) => entry.scope === scope);
        if (module) {
          return module;
        }
      }
    }
    return scope;
  }

  logOut() {
    this.authService.logout();
  }

  switchWorkspace() {
    this.authService.switchWorkspace();
  }

  goBack() {
    this.authService.gotoPicklistBB();
  }
  expandmenu() {
    if (document.getElementsByClassName('filter-dropdown open').length > 0)
      document.getElementsByClassName('filter-dropdown open')[0]['style'].display = 'none';
    localStorage.setItem('collapsenav', 'true');
    localStorage.setItem('fullwidth', 'false');
    localStorage.setItem('expandnav', 'false');
  }

  checkexpandnav() {
    let flag = 0;
    if (localStorage.getItem('expandnav') && localStorage.getItem('expandnav') === 'true') {
      flag = 1;
    }
    return flag === 1 ? true : false;
  }

  async currentPermisson() {
    const currentModule: any = this.findRouteURL(this.route.snapshot.params['scope']);
    if (currentModule) {
      this.loggedInUserSubscription$ = this.store.pipe(select(getCurrentLoggedInUserData)).subscribe(response => {
        if (response?.currentUser) {
          if (response?.currentUser['workspace_permissions'][`manage:${currentModule.scope}`]?.isEnabled == '1') {
            this.router.navigate([currentModule.URL], { queryParams: currentModule.queryParams });
            if (this.loggedInUserSubscription$) this.loggedInUserSubscription$.unsubscribe();
          } else if (response?.currentUser['status'] == '1') {
            let workspaceSlug = this.authService.getAccountSlug();
            this.authService.loadInitialModule(true, false, workspaceSlug);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    if (this.loggedInUserSubscription$) this.loggedInUserSubscription$.unsubscribe();
  }
}
