import { AppState } from 'src/app/app.state';
import { createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/app-install.reducer';

const getAppInstallState = (state: AppState) => state.appInstallState;

export const getScopeData = createSelector(getAppInstallState, fromReducer.getScopeData);
export const getIsScopeLoading = createSelector(getAppInstallState, fromReducer.getIsScopeLoading);
export const getIsScopeLoaded = createSelector(getAppInstallState, fromReducer.getIsScopeLoaded);
export const getAppDetailsData = createSelector(getAppInstallState, fromReducer.getAppDetailsData);
export const getIsAppDetailsLoading = createSelector(getAppInstallState, fromReducer.getIsAppDetailsLoading);
export const getIsAppDetailsLoaded = createSelector(getAppInstallState, fromReducer.getIsAppDetailsLoaded);
export const getAppInstallConsentData = createSelector(getAppInstallState, fromReducer.getAppInstallConsentData);
